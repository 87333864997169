import times from 'lodash/times';
import each from 'lodash/each';

import { formatTime } from 'lib/utils';

const useTimeIncrements = () => {
  //  Times are in half-hour increments starting at 12:00 AM and ending at 11:30 PM
  let timeIncrements = [];
  times(24, (hour) => {
    each(['00', '30'], (halfHour) => {
      timeIncrements.push(formatTime(hour + ':' + halfHour));
    });
  });

  return timeIncrements;
};

export default useTimeIncrements;
