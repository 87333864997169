import { MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  '@keyframes rotateLeg': {
    from: { transform: 'rotate(0deg)', transformOrigin: '50% 50% 0' },
    to: { transform: 'rotate(45deg)', transformOrigin: '55% 40% 0' },
  },
  '@keyframes fadeIn': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  '@keyframes fadeOut': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  '@keyframes chickenJump': {
    from: { transform: 'rotate(0deg)', transformOrigin: '50% 50% 0' },
    to: { transform: 'rotate(180deg)', transformOrigin: '50% 50% 0' },
  },

  chicken: {
    width: '10em',
    height: '10em',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    chicken: {
      width: '5em',
      height: '5em',
    },
  },
  chickenDead: {
    animationDelay: '1.2s',
    animation: '.75s $chickenJump forwards',
    animationIterationCount: 1,
  },
  chickenEye: {
    opacity: 0,
  },
  chickenDeadEye: {
    animation: '0.2s $fadeOut',
    animationIterationCount: 2,
  },
  chickenAliveEye: {
    animationDelay: '.2s',
    animation: '0.2s $fadeIn',
    animationIterationCount: 2,
  },
  chickenLeg: {
    animationDelay: '1s',
    animation: '0.5s $rotateLeg',
    animationIterationCount: 1,
  },
};
