import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import reverse from 'lodash/reverse';

import {
  fetchStorageSlots,
  resetStorageSlots,
  setCycleCount,
  cycleCountDeactivation,
  setUnknownLocation,
} from 'redux/storageSlot/actions';
import { setTopBarContent } from 'redux/topBar/actions';

import StorageArea from './StorageArea';

const mapStateToProps = (state, ownProps) => {
  const slots =
    ownProps.sortState === 'desc'
      ? reverse(state.storageSlots.storageSlots)
      : state.storageSlots.storageSlots;

  return {
    fetching: state.storageSlots.fetching,
    failed: state.storageSlots.failed,
    storageSlots: slots,
    storageAreas: state.storageAreas.results,
    actionResult: state.storageSlots.actionResult,
  };
};

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onFetchStorageSlots: fetchStorageSlots,
  onResetStorageSlots: resetStorageSlots,
  onSetCycleCount: setCycleCount,
  onSetIssuedToProduction: cycleCountDeactivation,
  onSetUnknownLocation: setUnknownLocation,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StorageArea)
);
