import { makeApiRequest } from 'lib/api';
import { QA_PORTION_MEALS } from 'lib/constants';

import QaPortionMeals from './services';

export const fetchMealsByFacilityAndMenuId = (packingFacilityId, menu) => (
  dispatch
) => {
  const menuId = menu.id;

  dispatch(
    makeApiRequest({
      type: QA_PORTION_MEALS,
      method: QaPortionMeals.byFacility,
      params: { packingFacilityId, menuId },
    })
  );
};
