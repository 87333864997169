import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';

import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

export const getProductionZoneItems = async ({ locationId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/item_management/production_zone_items?location_id=${locationId}`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

export const updateProductionZoneItem = async ({ updateParams }) => {
  const endpoint = `${API_BASE_URI}/ops/item_management/production_zone_items`;
  const transformedParams = mapKeys(updateParams, (_value, key) => {
    return snakeCase(key);
  });

  const response = await fetch(endpoint, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(transformedParams),
  });
  return await handleResponse(response);
};

export const changeover = async ({ locationId }) => {
  const endpoint = `${API_BASE_URI}/ops/item_management/changeover/?location_id=${locationId}`;

  const response = await fetch(endpoint, {
    ...options,
    method: 'PUT',
  });
  return await handleResponse(response);
};

export const print = async ({ productionZoneItemId }) => {
  const endpoint = `${API_BASE_URI}/ops/item_management/print_license_plate/?pzi_id=${productionZoneItemId}`;
  const response = await fetch(endpoint, { ...options, method: 'POST' });
  return await handleResponse(response);
};
