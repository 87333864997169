import * as Sentry from '@sentry/react';
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  NavLink,
} from 'react-router-dom';

import WarehouseReceiving from './WarehouseReceiving';
import WarehouseQA from './WarehouseQA';
import WarehouseAudit from './WarehouseAudit';
import WarehouseSummary from './WarehouseSummary';
import AuditExpandCollapse from './WarehouseAudit/AuditExpandCollapse';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const ReceivingLandingPage = ({ classes, onFetchPrinters }) => {
  useEffect(() => {
    onFetchPrinters();
  }, [onFetchPrinters]);

  return (
    <Fragment>
      <div className={classes.linkContainer}>
        <div>
          <NavLink
            to="/receiving/warehouse"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Warehouse
          </NavLink>
          <NavLink
            to="/receiving/fsqa"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            FSQA
          </NavLink>
          <NavLink
            to="/receiving/summary"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Summary
          </NavLink>
          <NavLink
            to="/receiving/audit"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Audit
          </NavLink>
        </div>
        <Switch>
          <Route
            exact
            path="/receiving/audit"
            render={() => <AuditExpandCollapse />}
          />
        </Switch>
      </div>
      <Switch>
        <Route
          exact
          path="/receiving/warehouse"
          render={() => <WarehouseReceiving />}
        />
        <Route exact path="/receiving/fsqa" component={WarehouseQA} />
        <Route exact path="/receiving/summary" component={WarehouseSummary} />
        <Route exact path="/receiving/audit" component={WarehouseAudit} />
      </Switch>
    </Fragment>
  );
};

ReceivingLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onFetchPrinters: PropTypes.func.isRequired,
};

export default ReceivingLandingPage;
