import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';

import { qaStyles } from 'components/shared';
import { usePrevious } from 'lib/custom_hooks';

import ErrorReport from './ErrorReport';

const Confirmation = ({
  classes,
  totalCheckCount,
  labelErrorCount,
  packagingErrorCount,
  ingredientErrorCount,
  preliminaryCheckId,
  updating,
  updateFailed,
  history,
  onUpdatePreliminaryCheck,
}) => {
  const prevUpdate = usePrevious(updating);

  const [labelCorrectiveAction, setLabelCorrectiveAction] = useState('');
  const [otherCorrectiveAction, setOtherCorrectiveAction] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');

  const anyErrors =
    labelErrorCount + packagingErrorCount + ingredientErrorCount > 0;
  const otherErrorCount = ingredientErrorCount + packagingErrorCount;

  const needsLabelCorrectiveAction =
    labelErrorCount > 0 && labelCorrectiveAction;
  const needsOtherCorrectiveAction =
    packagingErrorCount + ingredientErrorCount > 0 && otherCorrectiveAction;

  useEffect(() => {
    if (!updating && prevUpdate && !updateFailed) {
      history.push('/qa/meal_bagging/');
    }
  }, [history, prevUpdate, updating, updateFailed]);

  const submitBatch = (correctiveActions) => {
    onUpdatePreliminaryCheck(correctiveActions, preliminaryCheckId);
  };

  const isFormComplete =
    (needsLabelCorrectiveAction && needsOtherCorrectiveAction) ||
    (labelErrorCount === 0 && needsOtherCorrectiveAction) ||
    (otherErrorCount === 0 && needsLabelCorrectiveAction) ||
    !anyErrors;

  return (
    <div className={classes.container}>
      <h3 className={classes.heading}>You checked:</h3>
      <h2 className={classes.greenBiggerText}>
        {totalCheckCount} {totalCheckCount === 1 ? 'Bag' : 'Bags'}
      </h2>
      <h3 className={classes.heading}>You reported:</h3>
      <ErrorReport
        classes={classes}
        anyErrors={anyErrors}
        labelErrorCount={labelErrorCount}
        packagingErrorCount={packagingErrorCount}
        ingredientErrorCount={ingredientErrorCount}
      />
      {labelErrorCount > 0 && (
        <>
          <h3 className={classes.headingMoreTopMargin}>
            What corrective action(s) was taken to resolve issues with{' '}
            <span className={classes.boldText}>Meal Bag Labels</span>:
          </h3>
          <TextField
            id="labelTextField"
            label="Enter your comments here"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            value={labelCorrectiveAction}
            onChange={(e) => setLabelCorrectiveAction(e.target.value)}
          />
        </>
      )}
      {packagingErrorCount + ingredientErrorCount > 0 && (
        <>
          <h3 className={classes.headingMoreTopMargin}>
            What corrective action(s) was taken to resolve the{' '}
            <span className={classes.boldText}>Other Errors</span>:
          </h3>
          <TextField
            id="packagingOrIngredientTextField"
            label="Enter your comments here"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            value={otherCorrectiveAction}
            onChange={(e) => setOtherCorrectiveAction(e.target.value)}
          />
        </>
      )}

      <h3 className={classes.headingMoreTopMargin}>Additional Comments:</h3>
      <TextField
        id="additionalComments"
        label="Enter your comments here"
        multiline
        fullWidth
        rows={4}
        variant="outlined"
        value={additionalComments}
        onChange={(e) => setAdditionalComments(e.target.value)}
      />
      <Button
        id="submitBatch"
        fullWidth
        onClick={() => {
          submitBatch({
            labelCorrectiveAction,
            otherCorrectiveAction,
            additionalComments,
          });
        }}
        className={classes.startCheckButton}
        disabled={!isFormComplete}
      >
        Submit Batch
      </Button>
    </div>
  );
};

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  totalCheckCount: PropTypes.number.isRequired,
  labelErrorCount: PropTypes.number.isRequired,
  packagingErrorCount: PropTypes.number.isRequired,
  ingredientErrorCount: PropTypes.number.isRequired,
  preliminaryCheckId: PropTypes.number.isRequired,
  updating: PropTypes.bool.isRequired,
  updateFailed: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onUpdatePreliminaryCheck: PropTypes.func.isRequired,
};

export default withStyles(qaStyles)(Confirmation);
