import {
  GREY_100,
  GREY_200,
  WHITE,
  BLACK,
  GREY_500,
  HC_GREEN,
  BLUE,
  NEGATIVE_RED,
} from 'lib/constants';
import theme from 'lib/theme';

const centered = {
  display: 'flex',
  justifyContent: 'center',
};

const listItemText = {
  textAlign: 'left',
  color: BLACK,
  [theme.breakpoints.down('md')]: {
    padding: '2px 5px',
  },
};

export default {
  root: {
    width: '100%',
    backgroundColor: WHITE,
    position: 'relative',
    overflow: 'auto',
    textAlign: 'left',
    fontSize: '14px',
  },
  listItem: {
    color: BLACK,
    padding: '16px 0px 16px 16px',
    width: '100%',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subheader: {
    backgroundColor: GREY_500,
    color: WHITE,
    lineHeight: '40px',
  },
  header: {
    backgroundColor: WHITE,
    color: BLACK,
    zIndex: 2,
    padding: '0px 10px',
    borderBottom: `1px solid ${GREY_100}`,
    display: 'flex',
  },
  odd: {
    backgroundColor: GREY_100,
  },
  storageAreaPosition: {
    ...listItemText,
    width: '5%',
    fontWeight: 800,
  },
  storageAreaPositionHeader: {
    ...listItemText,
    width: '5%',
    display: 'flex',
  },
  storageAreaLot: {
    ...listItemText,
    width: '10%',
  },
  storageAreaItem: {
    ...listItemText,
    width: '14%',
    textAlign: 'left',
  },
  storageAreaBrand: {
    ...listItemText,
    width: '8%',
  },
  storageAreaQuantityAndUOM: {
    ...listItemText,
    width: '14%',
  },
  storageAreaProdDateCode: {
    ...listItemText,
    width: '20%',
  },
  storageAreaReceivedAt: {
    ...listItemText,
    width: '8%',
  },
  sorryContainer: {
    ...centered,
    padding: '5em 1em',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px',
  },
  listItemLink: {
    padding: '15px 18px',
    borderTop: `1px solid ${GREY_200}`,
  },
  checkedItem: {
    borderLeft: `8px solid ${HC_GREEN}`,
    paddingLeft: '10px',
  },
  swipeableArea: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${GREY_200}`,
  },
  swipeRightArea: {
    fontSize: '15px',
    padding: '16px 50px',
    fontWeight: 'bold',
    backgroundColor: HC_GREEN,
    width: '50%',
    height: '100%',
    color: WHITE,
    boxSizing: 'border-box',
  },
  backgroundButton: {
    height: '100%',
    width: '135px',
    borderRadius: 'unset',
    color: WHITE,
  },
  buttonBlue: {
    backgroundColor: BLUE,
  },
  buttonRed: {
    backgroundColor: NEGATIVE_RED,
  },
  sortSlotIcon: {
    paddingTop: '10px',
  },
};
