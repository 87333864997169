import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchMissingSlotPallets,
  resetMissingSlotPallets,
} from 'redux/pallets/actions';
import { setTopBarContent } from 'redux/topBar/actions';

import InvestigationArea from './InvestigationArea';

const mapStateToProps = (state, ownProps) => {
  const storageAreaId = ownProps.match.params.id;
  const storageArea = state.storageAreas.results.find(
    (area) => area.id.toString() === storageAreaId
  );

  return {
    missingPallets: state.pallets.inventoryApp.missingPallets,
    fetching: state.pallets.inventoryApp.fetchingMissingPallets,
    storageArea: storageArea,
    history: ownProps.history,
  };
};

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onFetchPallets: fetchMissingSlotPallets,
  onResetMissingPallets: resetMissingSlotPallets,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvestigationArea)
);
