import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getIngredients,
  getProductionProgressForIngredient,
  getProductionProgressForCustomizeIt,
} from 'redux/ingredients/actions';
import { postLabelInventory } from 'redux/productionInventories/labelInventory/actions';

import PortioningLabeling from './PortioningLabeling';

const mapDispatchToProps = {
  onGetIngredients: getIngredients,
  getProductionProgressForIngredient: getProductionProgressForIngredient,
  onPostLabelInventory: postLabelInventory,
  onGetProductionProgressForCustomizeIt: getProductionProgressForCustomizeIt,
};

const mapStateToProps = (state) => ({
  printers: state.printers.printers,
  ingredients: state.ingredients.ingredients,
  ingredientInventory: state.ingredientInventory,
  labelInventory: state.labelInventory,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortioningLabeling));
