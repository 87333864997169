const gridItemRoot = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '10.5px',
};

export default {
  gridItemAlignLeft: {
    ...gridItemRoot,
    textAlign: 'left',
  },
  gridItemAlignCenter: {
    ...gridItemRoot,
    textAlign: 'center',
  },
  gridItemAlignRight: {
    ...gridItemRoot,
    textAlign: 'right',
  },
  activityHeading: {
    ...gridItemRoot,
    textAlign: 'left',
    '& p': {
      padding: '0',
      margin: '0',
    },
    '& span': {
      display: 'inline-block',
      height: '1px',
      width: '100%',
      backgroundColor: 'black',
      paddingBottom: '0',
    },
  },
};
