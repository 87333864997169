import {
  TABLET_SCREEN_OR_SMALLER,
  WHITE,
  DARK_HC_GREEN,
  HC_GREEN,
} from 'lib/constants';

export default {
  icon: {
    color: WHITE,
  },
  button: {
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    boxShadow: '0 2px 2px hsl(0deg 0% 0% / 0.4)',
    margin: '0 auto',
    height: '38px',
    width: '38px',
  },
  container: {
    margin: '0 6px',
  },
  [`@media ${TABLET_SCREEN_OR_SMALLER}`]: {
    container: { display: 'flex', alignContent: 'center' },
  },
};
