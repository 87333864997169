import { pusher } from 'services/pusher';
import { updatePodStatus } from 'redux/podStatus/actions';

const subscribeToPodStatuses = (packingFacilityId) => {
  const channel = pusher.subscribe(`podStatus_${packingFacilityId}`);
  return (dispatch) => {
    channel.bind('updatePod', (podStatus) => {
      dispatch(updatePodStatus(podStatus));
    });
  };
};

const unsubscribeToPodStatuses = (packingFacilityId) => {
  pusher.unsubscribe(`podStatus_${packingFacilityId}`);
};

export { subscribeToPodStatuses, unsubscribeToPodStatuses };
