import { HC_GREEN, GREY_700, WHITE } from 'lib/constants';

export default {
  button: {
    backgroundColor: GREY_700,
    borderRadius: '4px',
    padding: '8px 20px',
    color: WHITE,
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    width: '41px',
    '&:hover': {
      backgroundColor: HC_GREEN,
    },
  },
  active: {
    backgroundColor: HC_GREEN,
  },
};
