import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { BORDER_GREY } from 'lib/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

const IngredientMealsDetails = ({ ingredient }) => {
  const classes = useStyles();

  const listItemTitles = [];

  return (
    <List sx={{ padding: 0 }}>
      {ingredient.meals.map((meal, index) => {
        const { mealLetter, name, id, submissions, daysSlacking } = meal;
        const subheader = `${mealLetter} ${name} - ${id}`;
        const displayTitle = listItemTitles.includes(subheader);

        listItemTitles.push(subheader);

        return (
          <Grid key={`${ingredient.id}-${id}-${index + 0}`} classes={classes}>
            <ListItem sx={{ padding: 0 }}>
              <ListItemText
                sx={{ margin: 0 }}
                primary={
                  !displayTitle && (
                    <>
                      <Grid
                        display="flex"
                        sx={{
                          borderTop: `1px solid ${BORDER_GREY}`,
                          paddingTop: 2,
                          paddingLeft: 2,
                          paddingRight: 2,
                        }}
                      >
                        <Typography
                          sx={{ display: 'inline' }}
                          className={classes.subheader}
                          component="strong"
                          variant="body"
                          color="text.primary"
                        >
                          {subheader}
                        </Typography>
                      </Grid>
                    </>
                  )
                }
                secondary={
                  <Typography
                    sx={{
                      marginLeft: 2,
                      marginRight: 2,
                      paddingBottom: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'block',
                        borderTop: displayTitle
                          ? `1px solid ${BORDER_GREY}`
                          : 'none',
                        paddingTop: 2,
                      }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      Submissions: {submissions.toLocaleString()}
                    </Typography>
                    <Typography
                      sx={{ display: 'block', paddingTop: 1 }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      Days Slacking: {daysSlacking}
                    </Typography>
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
        );
      })}
    </List>
  );
};

IngredientMealsDetails.propTypes = {
  ingredient: PropTypes.object.isRequired,
};

export default IngredientMealsDetails;
