import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import {
  ThumbsDownIcon,
  ThumbsUpIcon,
  CircledCheckmark,
} from 'components/icons';
import { ERROR_RED, SUCCESS_GREEN, WHITE } from 'lib/constants';

import styles from './styles';

const PortioningDialog = ({
  classes,
  numberErrors,
  checkCount,
  open,
  handleClose,
  history,
}) => {
  let numberSuccess = checkCount - numberErrors;
  const MAX_NUMBER_OF_CHECKS = 20;

  const goHome = () => {
    history.push({
      pathname: '/qa/portioning',
      state: {
        snackBarActivated: true,
        checkCount: checkCount,
      },
    });
  };

  let cancelButton;
  if (numberErrors > 0 && checkCount === MAX_NUMBER_OF_CHECKS) {
    cancelButton = '';
  } else {
    cancelButton = (
      <Button
        onClick={handleClose}
        className={classes.cancelButton}
        color="primary"
      >
        Cancel
      </Button>
    );
  }

  return (
    <div>
      <Dialog
        classes={classes.dialogBox}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{ root: classes.header }}
          className={classes.header}
        >
          <CircledCheckmark className={classes.checkmark} color={WHITE} />
          {`${checkCount} ${
            checkCount === 1 ? 'Check Completed' : 'Checks Completed'
          }`}{' '}
        </DialogTitle>
        <DialogContent className={classes.IconAlign}>
          <ThumbsUpIcon color={SUCCESS_GREEN} className={classes.thumbIcon} />{' '}
          <DialogContentText className={classes.textContent}>
            {`${numberSuccess} ${
              numberSuccess === 1 ? 'Portion' : 'Portions'
            } Passed`}
          </DialogContentText>
        </DialogContent>
        <DialogContent className={classes.IconAlign}>
          <ThumbsDownIcon color={ERROR_RED} className={classes.thumbIcon} />{' '}
          <DialogContentText className={classes.textContent}>
            {`${numberErrors} ${
              numberErrors === 1 ? 'Portion' : 'Portions'
            } Failed`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelButton}
          <Button
            onClick={goHome}
            className={classes.submitButton}
            color="primary"
            autoFocus
            data-testid="portioning-dialog-submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PortioningDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  checkCount: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  numberErrors: PropTypes.number.isRequired,
};

export default withStyles(styles)(PortioningDialog);
