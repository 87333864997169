import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import find from 'lodash/find';
import get from 'lodash/get';
import classnames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';

import { TABLET_SCREEN } from 'lib/constants';
import { Loading } from 'components/shared';

import { NO_ISSUES } from './formUtils';

const IngredientQuestions = ({
  ingredientAssignments,
  problematicIngredients,
  problematicIngredientErrors,
  onChangeProblematicIngredients,
  onChangeIngredientErrors,
  classes,
}) => {
  const isTabletScreen = useMediaQuery(TABLET_SCREEN);
  const INGREDIENT_ISSUES = [
    'Missing',
    'Under Portioned',
    'Over Portioned',
    'Packaging Other',
    'Expired',
  ];

  const truncateIngredientName = (ingredientName) => {
    const maxCharacterLength = 56;

    return ingredientName.length > maxCharacterLength && isTabletScreen
      ? ingredientName.slice(0, maxCharacterLength) + '...'
      : ingredientName;
  };

  return (
    <>
      <h3 className={classes.heading}>
        Are there issues with the following ingredients?
      </h3>
      {ingredientAssignments.length > 0 && (
        <ToggleButtonGroup
          className={
            isTabletScreen ? classes.threeButtonGroup : classes.fourButtonGroup
          }
          size="large"
          value={problematicIngredients}
          onChange={(_, value) => onChangeProblematicIngredients(value)}
        >
          {ingredientAssignments.map((problematicIngredient) => (
            <ToggleButton
              key={problematicIngredient.ingredientId}
              className={classnames([
                classes.buttonOne,
                classes.button,
                classes.ingredientToggleButton,
              ])}
              value={problematicIngredient.ingredientId}
            >
              {`${truncateIngredientName(problematicIngredient.name)} - ${
                problematicIngredient.quantity
              } ${problematicIngredient.unitOfMeasure}`}
            </ToggleButton>
          ))}
          <ToggleButton
            key={NO_ISSUES}
            value={NO_ISSUES}
            className={classes.ingredientToggleButton}
          >
            {NO_ISSUES}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {ingredientAssignments.length > 0 ? (
        ingredientAssignments
          .filter((ia) => problematicIngredients.includes(ia.ingredientId))
          .map((i) => {
            return (
              <div key={i.ingredientId}>
                <h3
                  className={classes.heading}
                >{`What is wrong with the ${i.name}?`}</h3>
                <ToggleButtonGroup
                  className={classes.fiveButtonGroup}
                  size="large"
                  value={
                    get(
                      find(problematicIngredientErrors, {
                        ingredientId: i.ingredientId,
                      }),
                      'errors'
                    ) || []
                  }
                  onChange={(_, value) => onChangeIngredientErrors(i, value)}
                >
                  {INGREDIENT_ISSUES.map((ingredientIssue) => (
                    <ToggleButton
                      key={ingredientIssue}
                      className={classnames([
                        classes.buttonOne,
                        classes.button,
                      ])}
                      value={ingredientIssue}
                    >
                      {ingredientIssue}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            );
          })
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </>
  );
};

IngredientQuestions.propTypes = {
  problematicIngredients: PropTypes.array.isRequired,
  problematicIngredientErrors: PropTypes.array.isRequired,
  ingredientAssignments: PropTypes.array.isRequired,
  onChangeProblematicIngredients: PropTypes.func.isRequired,
  onChangeIngredientErrors: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default IngredientQuestions;
