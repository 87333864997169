import {
  MENUS_UPDATED,
  MENU_SELECTED,
  MENUS_REQUEST,
  MENUS_SUCCESS,
} from 'lib/constants';

const initialState = {
  results: [],
  selectedMenu: {},
  fetching: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case MENUS_UPDATED:
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    case MENU_SELECTED:
      return {
        ...state,
        selectedMenu: action.payload.menu,
      };
    case MENUS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case MENUS_SUCCESS:
      return {
        ...state,
        results: action.response.menus,
        fetching: false,
      };
    default:
      return state;
  }
}
