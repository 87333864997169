import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deactivatePallet } from 'redux/pallets/actions';

import PalletDetailsDeleteButton from './PalletDetailsDeleteButton';

const mapStateToProps = (state) => ({
  deactivating: state.pallets.inventoryApp.deactivating,
  deactivationFailed: state.pallets.inventoryApp.deactivationFailed,
});

const mapDispatchToProps = {
  onPalletDeactivate: deactivatePallet,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PalletDetailsDeleteButton)
);
