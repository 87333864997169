import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Redirect } from 'react-router-dom';

import { postQaMealBagCheck } from 'redux/qa/mealBagChecks/actions';
import { qaCheckFormStyles, MealBaggingBanner } from 'components/shared';
import { setTopBarContent } from 'redux/topBar/actions';
import { getIngredientAssignmentsForMenuSelection } from 'redux/ingredientAssignments/productionInventory/actions';
import { BACK_BUTTON, QA_APP } from 'lib/constants';
import { usePrevious } from 'lib/custom_hooks';

import QaCheckForm from './QaCheckForm';

const QaCheckFormContainer = ({
  ingredientAssignments,
  qaMealBagChecks,
  selectedMenu,
  onSetTopBarContent,
  onPostQaMealBagCheck,
  onGetIngredientAssignments,
  location,
  classes,
}) => {
  const { meal, preliminaryCheckId } = location.state;
  const checkNumber = qaMealBagChecks.length + 1;
  const previousCheckNumber = usePrevious(checkNumber);
  const isLastQaCheckSubmitted = qaMealBagChecks.length === previousCheckNumber;
  const [redirectToConfirmation, setRedirectToConfirmation] = useState(false);
  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: QA_APP,
      text: `${meal.letterAssignment}: ${meal.title}`,
    });
  }, [meal.letterAssignment, meal.title, onSetTopBarContent]);

  const showCompoundIngredients = true;
  useEffect(() => {
    onGetIngredientAssignments(
      meal.ingredientAssignmentIds,
      selectedMenu.id,
      showCompoundIngredients
    );
  }, [
    meal.ingredientAssignmentIds,
    onGetIngredientAssignments,
    selectedMenu.id,
  ]);

  if (redirectToConfirmation && isLastQaCheckSubmitted) {
    return (
      <Redirect
        to={{
          pathname: `/qa/meal_bagging/preliminary-checks/${preliminaryCheckId}/confirmation`,
          state: { meal, preliminaryCheckId },
        }}
      />
    );
  } else if (isLastQaCheckSubmitted) {
    return (
      <Redirect
        to={{
          pathname: `/qa/meal_bagging/preliminary-checks/${preliminaryCheckId}/checks`,
          // If we move `meal` to be selected from the redux store we won't have to manage
          // passing it through location.state!
          state: { meal, preliminaryCheckId },
        }}
      />
    );
  } else {
    return (
      <>
        {meal.banner && <MealBaggingBanner text={meal.banner} />}

        <QaCheckForm
          {...{
            meal,
            ingredientAssignments,
            preliminaryCheckId,
            onPostQaMealBagCheck,
            onSetTopBarContent,
            setRedirectToConfirmation,
            classes,
          }}
        />
      </>
    );
  }
};

QaCheckFormContainer.propTypes = {
  qaMealBagChecks: PropTypes.array.isRequired,
  selectedMenu: PropTypes.shape({ id: PropTypes.number }).isRequired,
  ingredientAssignments: PropTypes.array.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  onPostQaMealBagCheck: PropTypes.func.isRequired,
  onGetIngredientAssignments: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  qaMealBagChecks: state.qaMealBagChecks.checks,
  selectedMenu: state.menus.selectedMenu,
  ingredientAssignments:
    state.ingredientAssignments.productionInventory.ingredientAssignments,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onPostQaMealBagCheck: postQaMealBagCheck,
  onGetIngredientAssignments: getIngredientAssignmentsForMenuSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(qaCheckFormStyles)(QaCheckFormContainer));
