export const FIVE_MINUTE_INTERVAL = 300000;
export const OVERVIEW = 'overview';
export const COMPOUND_INGREDIENTS = 'compound-ingredients';
export const MEALS = 'meals';
export const MEAL_ITEM_TYPE = 'meal';
export const INGREDIENT_ITEM_TYPE = 'ingredient';
export const SMALL_PROGRESS_BAR_PIXEL_COUNT = 275;
export const ASSEMBLY_BAR_PIXEL_COUNT = 250;
export const LARGE_PROGRESS_BAR_PIXEL_COUNT = 350;
export const COMPOUND_INGREDIENTS_STEP_ORDER = [
  'completed',
  'inProgress',
  'onHold',
  'notStarted',
];
export const ASSEMBLY_STEP_ORDER = ['assembled', 'readyForAssembly', 'toGoal'];
