import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { RTH_PORTIONING, FIVE_MINUTE_INTERVAL } from 'lib/constants';

import ExpandAndCollapsibleButtons from '../ExpandAndCollapsibleButtons';
import MenuNavigation from '../MenuNavigation';
import styles from './styles';
import IngredientsForPortioning from '../IngredientsForPortioning';

const useStyles = makeStyles(styles);

const Portioning = () => {
  const classes = useStyles();
  const [expandAll, setExpandAll] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const { menuId } = useParams();

  useEffect(() => {
    const interval = setInterval(() => {
      location.reload();
    }, FIVE_MINUTE_INTERVAL);
    return () => clearInterval(interval);
  });

  return (
    <>
      <MenuNavigation path={RTH_PORTIONING} />
      <div className={classes.container}>
        <div className={classes.selectControls}>
          <div />
          <ExpandAndCollapsibleButtons
            setExpandAll={setExpandAll}
            setTrigger={setTrigger}
            trigger={trigger}
          />
        </div>
        {menuId && (
          <IngredientsForPortioning expandAll={expandAll} trigger={trigger} />
        )}
      </div>
    </>
  );
};

export default Portioning;
