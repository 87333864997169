import { STORAGE_AREAS_REQUEST, STORAGE_AREAS_SUCCESS } from 'lib/constants';

const initialState = {
  fetching: false,
  results: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case STORAGE_AREAS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case STORAGE_AREAS_SUCCESS:
      return {
        ...state,
        fetching: false,
        results: action.response.storageAreas,
      };
    default:
      return state;
  }
}
