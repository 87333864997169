import {
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR,
  SUMMARY_ITEM_UPDATE_REQUEST,
  SUMMARY_ITEM_UPDATE_SUCCESS,
  SUMMARY_ITEM_UPDATE_ERROR,
  WMS_ITEM_RECEIPT_CREATE_SUCCESS,
} from 'lib/constants';
import { sortItems, insertUpdatedOrderItem } from 'lib/redux_utils';

export const initialState = {
  fetching: false,
  failed: false,
  updating: false,
  updateFailed: false,
  netsuiteOrders: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        netsuiteOrders: sortItems(action.response.netsuiteOrders),
      };
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case SUMMARY_ITEM_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
        updateFailed: false,
      };
    case SUMMARY_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        netsuiteOrders: insertUpdatedOrderItem(
          action.response.netsuiteOrderItem,
          state.netsuiteOrders
        ),
      };
    case WMS_ITEM_RECEIPT_CREATE_SUCCESS:
      return {
        ...state,
        netsuiteOrders: insertUpdatedOrderItem(
          action.response.netsuiteOrderItem,
          state.netsuiteOrders
        ),
      };
    case SUMMARY_ITEM_UPDATE_ERROR:
      return {
        ...state,
        updating: false,
        updateFailed: true,
      };
    default:
      return state;
  }
}
