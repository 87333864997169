export const formatPalletInfo = (pallet) => {
  let hasLocation = Boolean(pallet?.location);

  return hasLocation ? `${pallet?.id} @ ${pallet?.location}` : pallet?.id;
};

export const sortHistoricalDates = (a, b) => {
  const dateA = new Date(a?.formattedDropOffTime || a?.cancelledAtTime);
  const dateB = new Date(b?.formattedDropOffTime || b?.cancelledAtTime);
  return dateB - dateA;
};
