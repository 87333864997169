import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const TeamMembers = ({ classes, teamMembers }) => {
  return teamMembers.map((member, index) => {
    return (
      <ListItem
        className={classNames({
          [classes.grey]: index % 2 === 0,
        })}
        key={`${member.name}-${member.type}`}
      >
        <ListItemIcon>
          <span
            className={classNames(classes.circleIcon, {
              [classes.productionIconColor]: member.type === 'production_lead',
              [classes.qaIconColor]: member.type === 'quality_assurance',
            })}
          />
        </ListItemIcon>
        <ListItemText primary={member.name} />
      </ListItem>
    );
  });
};

TeamMembers.propTypes = {
  classes: PropTypes.object.isRequired,
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

TeamMembers.defaultProps = {
  lastQaCheckSubmittedAt: undefined,
  teamMembers: null,
};

export default withStyles(styles)(TeamMembers);
