import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';

import ContentModal from 'components/shared/ContentModal';

const AssignAndCloseButton = ({
  isAssigned,
  onAssignPutback,
  onClosePutback,
  disabled,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const closeModal = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <Box data-testid="assign-and-close-button" sx={{ width: '100%' }}>
      {isAssigned ? (
        <Button
          variant="outlined"
          onClick={() => setConfirmationModalOpen(true)}
          color="error"
          disabled={disabled}
          fullWidth
        >
          Close Request
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onAssignPutback}
          disabled={disabled}
          fullWidth
        >
          Assign To Me
        </Button>
      )}
      <ContentModal
        open={confirmationModalOpen}
        onClose={closeModal}
        title="Close Request"
        data-testid="close-request-confirmation-modal"
      >
        Are you sure you want to close this request?
        <DialogActions>
          <Button onClick={closeModal} color="neutral">
            Cancel
          </Button>
          <Button onClick={onClosePutback} variant="outlined">
            Close Request
          </Button>
        </DialogActions>
      </ContentModal>
    </Box>
  );
};

AssignAndCloseButton.propTypes = {
  isAssigned: PropTypes.bool.isRequired,
  onAssignPutback: PropTypes.func.isRequired,
  onClosePutback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AssignAndCloseButton.defaultProps = {
  disabled: false,
};

export default AssignAndCloseButton;
