import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

import { CHILLING_MACHINES } from 'lib/constants';
import { StatefulButton } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const buttonText = {
  DEFAULT: 'CONFIRM',
  SUCCESS: 'Success!',
  LOADING: 'SAVING...',
  ERROR: 'ERROR - Try Again',
};

const FailureForm = ({
  closeForm,
  showEquipment,
  isFailed,
  isFetching,
  maxCount,
  onSubmit,
}) => {
  const classes = useStyles();
  const [allFailed, setAllFailed] = useState(false);
  const [numberFailed, setNumberFailed] = useState('');
  const [machineryIdentifier, setMachineryIdentifier] = useState('');

  const resetForm = () => {
    setNumberFailed('');
    setAllFailed(false);
    closeForm();
  };

  const handleAllFailed = (checked) => {
    setAllFailed(checked);

    if (checked) {
      setNumberFailed(maxCount);
    } else {
      setNumberFailed('');
    }
  };

  const failedGreaterThanCount = numberFailed > maxCount;

  const invalid = () => {
    if (showEquipment) {
      return !numberFailed || failedGreaterThanCount || !machineryIdentifier;
    } else {
      return !numberFailed || failedGreaterThanCount;
    }
  };

  const submitInfo = {
    count: numberFailed,
    ...(machineryIdentifier ? { machineryIdentifier } : {}),
  };

  return (
    <>
      <hr className={classes.formDivider} />
      <div className={classes.formContent}>
        <div className={classes.formHeader}>
          <div className={classes.formHeaderText}>Record Failure</div>
          <Button
            className={classes.cancelButton}
            variant="text"
            color="error"
            onClick={resetForm}
          >
            Cancel
          </Button>
        </div>
        <div className={classes.formBody}>
          How many recipes failed?
          <FormControl variant="standard" fullWidth>
            <FormControlLabel
              control={<Checkbox checked={allFailed} />}
              label="Mark all failed"
              onChange={(e) => handleAllFailed(e.target.checked)}
              className={classes.checkboxField}
              data-testid="fail-all-checkbox"
            />
          </FormControl>
          <TextField
            label="Number of Failed Recipes"
            type="number"
            inputProps={{
              min: '1',
              step: '1',
              max: maxCount,
              'data-testid': 'number-failed-input',
            }}
            disabled={allFailed}
            value={numberFailed}
            onChange={(e) => {
              setNumberFailed(e.target.value);
            }}
            fullWidth
            error={failedGreaterThanCount}
            helperText={
              failedGreaterThanCount
                ? 'Number of failed recipes cannot exceed total count'
                : ''
            }
          />
          {showEquipment && (
            <>
              <div className={classes.formLabelText}>
                Which equipment number was used?
              </div>
              <Select
                className={classes.dropdown}
                defaultValue=""
                displayEmpty
                onChange={(e) => setMachineryIdentifier(e.target.value)}
                value={machineryIdentifier}
                data-testid="select-machinery-identifier"
                fullWidth
              >
                {CHILLING_MACHINES.map((identifier) => (
                  <MenuItem value={identifier} key={identifier}>
                    Chiller {identifier}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          <StatefulButton
            classes={{ root: classes.formSubmitButton }}
            type="submit"
            disabled={invalid()}
            loading={isFetching}
            failed={isFailed}
            buttonTextOptions={buttonText}
            onClick={() => onSubmit(submitInfo)}
          />
        </div>
      </div>
    </>
  );
};

FailureForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  maxCount: PropTypes.number.isRequired,
  closeForm: PropTypes.func.isRequired,
  showEquipment: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFailed: PropTypes.bool,
};

FailureForm.defaultProps = {
  isFetching: false,
  isFailed: false,
};

export default FailureForm;
