import {
  HC_GREEN,
  WHITE,
  DARK_HC_GREEN,
  ERROR_RED,
  DARK_RED,
  GREY_200,
} from 'lib/constants';

import { infoLabel, infoValue } from '../../FsqaReviewForms/styles';

const button = {
  height: '55px',
  width: '80px',
  marginRight: '10px',
  borderRadius: '5px !important',
  border: `1px solid ${GREY_200} !important`,
  color: 'black',
};

export default {
  infoLabel,
  infoValue,
  button,
  yesButton: {
    ...button,
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
  noButton: {
    ...button,
    '&.Mui-selected': {
      backgroundColor: ERROR_RED,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_RED,
      },
    },
  },
  textField: {
    margin: '0px',
  },
  errorText: {
    margin: '-15px 0 15px 0',
    color: ERROR_RED,
  },
};
