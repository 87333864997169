import theme from 'lib/theme';
import { GREY_650 } from 'lib/constants';

const gridItemRoot = {
  lineHeight: '36px',
};

export default {
  gridItemAlignLeft: {
    ...gridItemRoot,
    textAlign: 'left',
  },
  dataCellText: {
    padding: '0 4px',
  },
  ingredientName: {
    border: '0 none',
    padding: '0',
    cursor: 'pointer',
    background: 'transparent',
  },
  typography: {
    margin: theme.spacing(2),
    width: '200px',
    display: 'block',
  },
  popoverIngredientValue: {
    color: '#595959',
  },
  prePortioned: {
    color: GREY_650,
  },
};
