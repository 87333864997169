import { useCallback, useEffect } from 'react';
import compact from 'lodash/compact';

import { numberValidToDecimalPlace } from 'lib/utils';

export const useValidator = (valid, validationCallback) => {
  useEffect(() => {
    if (valid) {
      validationCallback(true);
    } else {
      validationCallback(false);
    }
  }, [valid, validationCallback]);
};

export const hasValue = (value) =>
  value !== null && value !== undefined && value.toString().length > 0;

export const hasValues = (values) => values.every(hasValue);

export const hasListValues = (values, numberRequired) => {
  if (values === undefined) {
    return false;
  }

  return compact(values).length === numberRequired;
};

export const useValidatorCallback = (field, callback) => {
  return useCallback(
    (value) => {
      callback({ field, value });
    },
    [callback, field]
  );
};

export const validationReducer = (state, action = {}) => {
  return { ...state, [action.field]: action.value };
};

export const onInputListUpdate = (
  event,
  index,
  values,
  numberDecimals,
  numberOfTemperaturesRequired,
  updateCallback,
  negativeTempsAllowed = false
) => {
  const requestedValue = event.target.value;
  const validInput =
    requestedValue === '' ||
    numberValidToDecimalPlace(
      requestedValue,
      numberDecimals,
      negativeTempsAllowed
    ) ||
    requestedValue === '-';

  if (validInput) {
    const newList =
      values.length === 0
        ? Array(numberOfTemperaturesRequired).fill('')
        : values.slice();
    newList[index] = requestedValue;

    updateCallback(newList);
  }
};
