import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

export const getMealsTrackerProgress = async (menuId) => {
  const endpoint = `${API_BASE_URI}/ops/rth_progress_tracker/menus/${menuId}/meals`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};

export const getMealsForAssemblyTrackerProgress = async (menuId) => {
  const endpoint = `${API_BASE_URI}/ops/rth_progress_tracker/menus/${menuId}/assembly`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};
