import { useEffect, useState } from 'react';

/*
useControllableProp

Intended to be used in reusable components that can either be controlled via a prop or
uncontrolled via local state. Value should be the prop you're interested in (that may not be there).
Updater updates that value (if it is present). DefaultValue initializes the value if it is uncontrolled.
Taken from https://react.christmas/2019/10
*/
const useControllableProp = ({ value, updater, defaultValue }) => {
  const isControlled = value !== undefined;
  const [internalValue, setInternalValue] = useState(
    isControlled ? value : defaultValue
  );
  const currentValue = isControlled ? value : internalValue;
  const currentUpdater = isControlled ? updater : (e) => setInternalValue(e);

  useEffect(() => {
    if (isControlled) {
      setInternalValue(value);
    }
  }, [isControlled, value]);

  return [currentValue, currentUpdater];
};

export default useControllableProp;
