import React from 'react';
import PropTypes from 'prop-types';

const ProgressIcon = (props) => {
  const { className = 'ProgressIcon' } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
        <g fill="#009F32" fillRule="evenodd">
          <path d="M70.9468317 19.6075933h-60.240198C8.10762376 19.6075933 6 17.3775267 6 14.6267933c0-2.75 2.10762376-4.98006663 4.7066337-4.98006663h60.240198c2.5990099 0 4.7066336 2.23006663 4.7066336 4.98006663 0 2.7507334-2.1076237 4.9808-4.7066336 4.9808m-8.6813328 35.7725934H10.7073284c-2.59943181 0-4.70739772-2.2293334-4.70739772-4.9800667 0-2.7507333 2.10796591-4.9808 4.70739772-4.9808h51.5581705c2.5994318 0 4.7073977 2.2300667 4.7073977 4.9808 0 2.7507333-2.1079659 4.9800667-4.7073977 4.9800667" />
          <path
            d="M56.2664191 37H10.7328839C8.11937859 37 6 34.7611721 6 32.0003681 6 29.2388279 8.11937859 27 10.7328839 27h45.5335352C58.8806214 27 61 29.2388279 61 32.0003681 61 34.7611721 58.8806214 37 56.2664191 37zM41.144339 70.9996429H10.604c-2.54237288 0-4.6040678-2.1714286-4.6040678-4.8507143S8.06162712 61.2982143 10.604 61.2982143h30.540339c2.5423729 0 4.6040678 2.1714286 4.6040678 4.8507143s-2.0616949 4.8507143-4.6040678 4.8507143z"
            opacity=".39709779"
          />
        </g>
      </svg>
    </div>
  );
};

ProgressIcon.propTypes = {
  className: PropTypes.string,
};

ProgressIcon.defaultProps = {
  className: 'ProgressIcon',
};

export default ProgressIcon;
