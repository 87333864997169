import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import withStyles from '@mui/styles/withStyles';
import orderBy from 'lodash/orderBy';

import { POSITIVE_GREEN, NEGATIVE_RED, BLACK, WHITE } from 'lib/constants';

import styles from '../styles';
import {
  getPace,
  isCurrentHour,
  incrementActiveHour,
  convertTime24To12,
} from './utils';

const HOURLY_TARGET = 1;
const PACE_LINE_HEIGHT = 0.005;

export const barColor = (percentOfTarget) => {
  return percentOfTarget >= HOURLY_TARGET ? POSITIVE_GREEN : NEGATIVE_RED;
};

export const barColorForPace = (pace, percentOfTarget) => {
  return percentOfTarget >= pace ? POSITIVE_GREEN : NEGATIVE_RED;
};

const barChartHeight = (numActivities) => {
  return numActivities === 1 ? 600 : 300;
};

const HourlyProductionChart = ({ activities, classes }) => {
  const [paceInterval, setPaceInterval] = useState(getPace());
  const [activeHour, setActiveHour] = useState({
    hour: '12PM',
    index: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setPaceInterval(getPace());
    }, 60000);
    return () => clearInterval(interval);
  });

  const renderCells = (activity) => {
    return activity.hourlyOutput.map((entry, index) => {
      if (
        isCurrentHour(entry.hour, activity.paceLineHour) &&
        activeHour.hour !== entry.hour
      ) {
        setActiveHour({ hour: entry.hour, index });
      } else if (isCurrentHour(entry.hour)) {
        return (
          <Cell
            key={entry.hour}
            fill={barColorForPace(paceInterval, entry.percentOfTarget)}
          />
        );
      } else {
        return <Cell key={entry.hour} fill={barColor(entry.percentOfTarget)} />;
      }
    });
  };

  return (
    <div className={classes.hourlyProductionContainer}>
      {orderBy(activities, 'lastProductionSubmittedAt', 'desc')
        .filter(
          (activity) =>
            activity.hourlyOutput && activity.hourlyOutput.length > 0
        )
        .map((activity) => (
          <ResponsiveContainer
            width="100%"
            height={barChartHeight(activities.length)}
            key={activity.name}
          >
            <BarChart
              data={activity.hourlyOutput}
              barCategoryGap={10}
              margin={{ top: 10, right: 80, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="hour" tickLine={false} axisLine={false} />
              <YAxis
                label={{
                  value: activity.name,
                  angle: -90,
                  width: 200,
                }}
                tickLine={false}
                axisLine={false}
                tick={false}
              />
              <Bar dataKey="percentOfTarget" radius={[5, 5, 5, 5]}>
                <LabelList
                  dataKey="total"
                  position="insideBottom"
                  fill={WHITE}
                />
                {renderCells(activity)}
              </Bar>
              <ReferenceLine
                y={HOURLY_TARGET}
                isFront
                stroke={BLACK}
                strokeWidth={1.75}
                label={{
                  position: 'right',
                  value: 'Target',
                  fill: BLACK,
                }}
                alwaysShow
              />
              <ReferenceArea
                x1={convertTime24To12(activity.paceLineHour)}
                x2={incrementActiveHour(
                  convertTime24To12(activity.paceLineHour),
                  activeHour.index,
                  activity.hourlyOutput.length
                )}
                y1={paceInterval}
                y2={paceInterval + PACE_LINE_HEIGHT}
                isFront
                label={{
                  position: 'right',
                  value: 'Pace',
                  fill: BLACK,
                }}
                fill={BLACK}
                ifOverflow="extendDomain"
                alwaysShow
              />
            </BarChart>
          </ResponsiveContainer>
        ))}
    </div>
  );
};

HourlyProductionChart.propTypes = {
  activities: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

HourlyProductionChart.defaultProps = {
  activities: [],
};

export default withStyles(styles)(HourlyProductionChart);
