import {
  HC_GREEN,
  WHITE,
  DARK_HC_GREEN,
  ERROR_RED,
  DARK_RED,
} from 'lib/constants';

import { infoLabel, infoValue } from '../FsqaReviewForms/styles';

const buttonDefault = {
  height: '55px',
  marginRight: '10px',
};

export default {
  infoLabel,
  infoValue,
  button: {
    width: '80px',
    ...buttonDefault,
  },
  buttonWide: {
    width: '150px',
    ...buttonDefault,
  },
  allergenButton: {
    height: '68px',
    width: '190px',
    marginBottom: '7px',
    marginRight: '7px',
    textTransform: 'none',
    fontFamily: 'Muli',
    fontSize: '16px',
  },
  selectedNo: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_RED,
    },
  },
  selectedYes: {
    backgroundColor: HC_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  selectAllergens: {
    fontSize: 14,
    margin: '20px 0 10px',
    textTransform: 'uppercase',
  },
  allergenOptionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',

    justifyContent: 'flex-start',
  },
  selected: {
    backgroundColor: HC_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: HC_GREEN,
    },
  },
};
