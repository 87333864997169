import {
  WHITE,
  GREY_200,
  SUCCESS_GREEN,
  DARK_HC_GREEN,
  GREY_500,
  GREY_750,
} from 'lib/constants';

export default {
  MuiDialogTitleRoot: {
    backgroundColor: SUCCESS_GREEN,
  },
  header: {
    backgroundColor: SUCCESS_GREEN,
    display: 'flex',
    alignItems: 'center',
    fontSize: '22px',
    letterSpacing: '0.25px',
    fontFamily: 'Muli',
    width: '500px',
    color: WHITE,
    marginBottom: '25px',
  },
  IconAlign: {
    display: 'flex',
  },
  checkmark: {
    width: '30px',
    marginRight: '15px',
  },
  textContent: {
    marginLeft: '10px',
    fontFamily: 'Muli',
    color: GREY_750,
    marginBottom: '0px',
  },
  cancelButton: {
    marginTop: '25px',
    padding: '10px 50px 10px 50px',
    '&:first-child': {
      '&:hover': {
        backgroundColor: GREY_200,
      },
      color: GREY_500,
      border: `1px solid ${GREY_200}`,
      backgroundColor: WHITE,
    },
  },
  sameRow: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  submitButton: {
    backgroundColor: SUCCESS_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    marginTop: '25px',
    color: `${WHITE} !important`,
    padding: '10px 50px 10px 50px',
  },
};
