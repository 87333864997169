import React from 'react';
import PropTypes from 'prop-types';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';

import { sortByLabelOrder } from 'lib/viewHelpers';

import IngredientProgressRow from './IngredientProgressRow';

const IngredientPanelDetails = ({ ingredientAssignments, classes }) => {
  return (
    <AccordionDetails className={classes.panelDetailsRoot}>
      <Grid container>
        <Grid
          container
          className={classnames(classes.gridContainer, classes.tableHeader)}
        >
          <Grid item xs={6}>
            Meals
          </Grid>
          <Grid
            item
            xs={2}
            className={classnames(classes.textRight, classes.cell)}
          >
            Left
          </Grid>
          <Grid
            item
            xs={2}
            className={classnames(classes.textRight, classes.cell)}
          >
            Completed
          </Grid>
          <Grid
            item
            xs={2}
            className={classnames(classes.textRight, classes.cell)}
          >
            Required
          </Grid>
        </Grid>

        {sortByLabelOrder(ingredientAssignments).map((ingredientAssignment) => {
          return (
            <IngredientProgressRow
              key={ingredientAssignment.id}
              labelOrder={ingredientAssignment.labelOrder}
              mealTitle={ingredientAssignment.mealTitle}
              outstandingCount={ingredientAssignment.portioning_remaining}
              completedCount={ingredientAssignment.portioning_completed_count}
              goalCount={
                ingredientAssignment.portioning_completed_count +
                ingredientAssignment.portioning_remaining
              }
              classes={classes}
            />
          );
        })}
      </Grid>
    </AccordionDetails>
  );
};

IngredientPanelDetails.propTypes = {
  ingredientAssignments: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default IngredientPanelDetails;
