import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CircledCheckmark from '../../icons/CircledCheckmark';

const LabelPrinting = ({ classes }) => {
  return (
    <div className={classes.container}>
      <div className={classes.checkmark}>
        <CircledCheckmark />
      </div>
      <div>Label is Printing</div>
      <Link className={classes.link} to="/box-ray/print-new-label">
        <div className={classes.scanAnotherBox}>Scan Another Box</div>
      </Link>
    </div>
  );
};

LabelPrinting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LabelPrinting;
