import React from 'react';

import DetailsHeader from './DetailsHeader';

const OverviewDetails = (props) => {
  return (
    <div className="OverviewDetails">
      <DetailsHeader
        {...props}
        secondaryHeader
        customColumnHeader1="% done"
        customColumnHeader2="# left"
      />
    </div>
  );
};

export default OverviewDetails;
