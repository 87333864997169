import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ENTER_KEY_CODE, PHONE_SCREEN, TABLET_SCREEN } from 'lib/constants';
import { useHiddenNativeKeyboard } from 'lib/custom_hooks';

import styles from './styles';

const LocationInput = ({
  classes,
  onGetDepths,
  fetchFailed,
  locationInputElement,
  locationNumber,
  showDepths,
  setLocationNumber,
  history,
  activePallet,
  shouldRedirect,
  onHoldPallet,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const tabletScreen = useMediaQuery(TABLET_SCREEN);
  const hideLocationKeyboard = useHiddenNativeKeyboard(locationInputElement);
  const getDepthsOrRedirect = (scannedValue) => {
    if (
      shouldRedirect &&
      phoneScreen &&
      scannedValue.includes('0'.repeat(20))
    ) {
      history.push(`/inventory-manager/pallets/${parseInt(scannedValue, 10)}`);
    } else if (activePallet || onHoldPallet) {
      onGetDepths(scannedValue);
    }
  };
  const onLocationInputChange = (e) => {
    if (phoneScreen && !showDepths && e.target.value.length > 1) {
      getDepthsOrRedirect(e.target.value);
    } else if (!phoneScreen && !tabletScreen) {
      setLocationNumber(e.target.value);
    }
  };
  const onLocationInputKeydown = (e) => {
    if (
      !tabletScreen &&
      e.keyCode === ENTER_KEY_CODE &&
      locationNumber.length
    ) {
      getDepthsOrRedirect(locationNumber);
    }
  };
  return (
    <div className={classes.scanningArea} data-testid="location-input">
      <TextField
        data-testid="scan-location-input"
        onChange={onLocationInputChange}
        onKeyDown={onLocationInputKeydown}
        className={classNames(classes.inputRoot, {
          [classes.palletNotFound]: fetchFailed,
        })}
        InputProps={{ classes: { input: classes.input } }}
        inputRef={locationInputElement}
        onFocus={hideLocationKeyboard}
        variant="outlined"
        label="Location ID"
        id="locationId"
        value={locationNumber}
      />
    </div>
  );
};

LocationInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onGetDepths: PropTypes.func.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  setLocationNumber: PropTypes.func.isRequired,
  locationInputElement: PropTypes.object.isRequired,
  locationNumber: PropTypes.string.isRequired,
  showDepths: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  activePallet: PropTypes.bool,
  shouldRedirect: PropTypes.bool,
  onHoldPallet: PropTypes.bool,
};

LocationInput.defaultProps = {
  activePallet: false,
  shouldRedirect: true,
  onHoldPallet: false,
};

export default withStyles(styles)(LocationInput);
