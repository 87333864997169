import {
  PRODUCTION_PLANS_POST_REQUEST,
  PRODUCTION_PLANS_POST_SUCCESS,
  PRODUCTION_PLANS_POST_ERROR,
  PRODUCTION_PLANS_REQUEST,
  PRODUCTION_PLANS_SUCCESS,
  PRODUCTION_PLANS_ERROR,
  PRODUCTION_PLANS_LEAD_POST_REQUEST,
  PRODUCTION_PLANS_LEAD_POST_SUCCESS,
  PRODUCTION_PLANS_LEAD_POST_ERROR,
} from 'lib/constants';

export const initialState = {
  results: [],
  posting: false,
  errors: [],
  fetching: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case PRODUCTION_PLANS_POST_REQUEST:
      return {
        ...state,
        posting: true,
      };
    case PRODUCTION_PLANS_POST_SUCCESS:
      return {
        ...state,
        results: action.response.productionPlans,
        posting: false,
      };
    case PRODUCTION_PLANS_POST_ERROR:
      return {
        ...state,
        posting: false,
        errors: state.errors.concat(action.response),
      };
    case PRODUCTION_PLANS_REQUEST:
      return {
        ...state,
        results: [],
        fetching: true,
      };
    case PRODUCTION_PLANS_SUCCESS:
      return {
        ...state,
        results: action.response.productionPlans,
        fetching: false,
      };
    case PRODUCTION_PLANS_ERROR:
      return {
        ...state,
        fetching: false,
        errors: state.errors.concat(action.response),
      };
    case PRODUCTION_PLANS_LEAD_POST_REQUEST:
      return {
        ...state,
        fetching: true,
        results: [],
      };
    case PRODUCTION_PLANS_LEAD_POST_SUCCESS:
      return {
        ...state,
        results: action.response.productionPlans,
        fetching: false,
      };
    case PRODUCTION_PLANS_LEAD_POST_ERROR:
      return {
        ...state,
        posting: false,
        errors: state.errors.concat(action.response),
      };
    default:
      return state;
  }
}
