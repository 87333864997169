import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { selectItemMoveRequest } from 'redux/itemMoveRequests/selectors';
import { fetchPallet, resetSelectedPallet } from 'redux/pallets/actions';
import { setTopBarContent } from 'redux/topBar/actions';

import styles from './styles';
import ManageSearchPallet from './ManageSearchPallet';

const mapStateToProps = (state, ownProps) => {
  return {
    pallet: state.pallets.inventoryApp.selectedPallet,
    palletId: ownProps.match.params.palletId,
    fetchFailed: state.pallets.inventoryApp.fetchFailed,
    history: ownProps.history,
    itemMoveRequest: selectItemMoveRequest(
      state,
      ownProps.match.params.requestId
    ),
  };
};

const mapDispatchToProps = {
  onResetSelectedPallet: resetSelectedPallet,
  onFetchPallet: fetchPallet,
  onSetTopBarContent: setTopBarContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManageSearchPallet));
