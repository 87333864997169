import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useActivityIngredients } from 'lib/custom_hooks';
import {
  deleteBin,
  fetchActivityIngredientProgressesSilent,
  submitBins,
  searchBins,
  updateBin,
} from 'redux/productionProgress/rth/actions';

const initialState = {
  bins: [],
  error: null,
  fetching: false,
};

const useBins = () => {
  const dispatch = useDispatch();
  const { activity, ingredientId } = useParams();
  const { getMealIdsByIngredientId } = useActivityIngredients();

  const [{ bins, fetching, error }, setState] = useState(initialState);

  const reloadIngredientProgresses = async () => {
    const mealIds = getMealIdsByIngredientId(ingredientId);

    await dispatch(
      fetchActivityIngredientProgressesSilent({
        activity: activity,
        ingredientId: ingredientId,
        mealIds,
      })
    );
  };

  const onDecant = async (binData, sublotId) => {
    const formData = {
      bins: binData.map((bin) => ({
        weightInLbs: bin.weightInLbs,
        binAssignmentsAttributes: [{ sublotId }],
      })),
    };

    setState({ bins: [], error: null, fetching: true });
    const response = await dispatch(submitBins(formData));

    if (response.ok) {
      setState({ bins: response.bins, error: null, fetching: false });
      reloadIngredientProgresses();
    } else {
      setState({ bins: [], error: response.error, fetching: false });
    }

    return response;
  };

  const onDeleteBin = async (binId) => {
    setState({ bins, error: null, fetching: true });
    const response = await dispatch(deleteBin(binId));

    if (response.ok) {
      setState({ bins, error: null, fetching: false });
      reloadIngredientProgresses();
    } else {
      setState({ bins, error: response.error, fetching: false });
    }

    return response;
  };

  const onSearch = async (searchTerm) => {
    setState({ bins, error: null, fetching: true });
    const response = await dispatch(searchBins(searchTerm));

    if (response.ok) {
      setState({ bins: response.bins, error: null, fetching: false });
    } else {
      setState({ bins: [], error: response.error, fetching: false });
    }

    return response;
  };

  const onUpdateBin = async ({ binId, binParams }) => {
    setState({ bins, error: null, fetching: true });
    const response = await dispatch(updateBin({ binId, binParams }));

    if (response.ok) {
      const newBins = [
        ...bins.filter((bin) => !bin.id === binId),
        response.bin,
      ];
      setState({ bins: newBins, error: null, fetching: false });
      reloadIngredientProgresses();
    } else {
      setState({ bins, error: response.error, fetching: false });
    }

    return response;
  };

  const resetBins = () => {
    setState(initialState);
  };

  return {
    bins,
    error,
    fetching,
    onDecant,
    onDeleteBin,
    onSearch,
    onUpdateBin,
    resetBins,
  };
};

export default useBins;
