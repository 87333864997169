import {
  BLACK,
  DARK_HC_GREEN,
  GREY_200,
  HC_GREEN,
  WHITE,
} from '../../../lib/constants';

export default {
  submitButton: {
    color: `${WHITE} !important`,
    margin: 'auto',
    width: '100%',
    height: '50px',
    backgroundColor: HC_GREEN,
    flex: 1,
    '&:disabled': {
      color: `${BLACK} !important`,
      backgroundColor: GREY_200,
    },
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
};
