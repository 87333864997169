import { GREY_200 } from 'lib/constants';

export default {
  dataRows: {
    borderTop: `1px solid ${GREY_200}`,
    display: 'flex',
    alignItems: 'center',
  },
  strongText: {
    fontWeight: 'bold',
  },
  row: {
    minHeight: '40px',
  },
  poContainer: {
    marginLeft: '15px',
  },
};
