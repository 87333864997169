import { makeApiRequest } from 'lib/api';
import {
  POD_STATUSES_UPDATED,
  POD_STATUSES,
  POD_STATUS,
  SELECTED_POD_UPDATED,
} from 'lib/constants';

import PodStatuses from './services';

export const requestPodStatuses = (params) =>
  makeApiRequest({
    type: POD_STATUSES,
    method: PodStatuses.getPodStatuses,
    params: params,
  });

export const requestPodStatus = (params) =>
  makeApiRequest({
    type: POD_STATUS,
    method: PodStatuses.getPodStatus,
    params: params,
  });

export const updatePodStatus = (podStatus) => {
  return {
    type: POD_STATUSES_UPDATED,
    payload: { podStatus },
  };
};

export const updateSelectedPod = (podStatus) => {
  return {
    type: SELECTED_POD_UPDATED,
    payload: { podStatus },
  };
};
