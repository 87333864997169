import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import capitalize from 'lodash/capitalize';

import { qaCheckFormStyles } from 'components/shared';

const IngredientInfo = ({ classes, ingredient }) => {
  return (
    <div className={classes.ingredientContainer}>
      <h1 className={classes.ingredientTitle}>{ingredient.name}</h1>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItem}>
          <span className={classes.greyText}>Brand:</span>
          <span
            className={classnames(classes.floatRight, classes.ingredientInfo)}
          >
            {capitalize(ingredient.brand)}
          </span>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <span className={classes.greyText}>Package Type:</span>
          <span
            className={classnames(classes.floatRight, classes.ingredientInfo)}
          >
            {capitalize(ingredient.packagingType.replace(/_/g, ' '))}
          </span>
        </Grid>
        <Grid
          item
          xs={6}
          className={classnames(classes.gridItemBackground, classes.gridItem)}
        >
          <span className={classes.greyText}>QTY/UoM:</span>
          <span
            className={classnames(classes.floatRight, classes.ingredientInfo)}
          >
            {ingredient.quantity} {ingredient.unitOfMeasure.replace(/_/g, ' ')}
          </span>
        </Grid>
        <Grid
          item
          xs={6}
          className={classnames(classes.gridItemBackground, classes.gridItem)}
        >
          <span className={classes.greyText}>Portion Method:</span>
          <span
            className={classnames(classes.floatRight, classes.ingredientInfo)}
          >
            {capitalize(ingredient.portionMethod.replace(/_/g, ' '))}
          </span>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <span className={classes.greyText}>Weight Tolerance:</span>
          <span
            className={classnames(classes.floatRight, classes.ingredientInfo)}
          >
            {`${ingredient.initialTestWeights.lowTolerance} -
            ${ingredient.initialTestWeights.highTolerance}`}
          </span>
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <span className={classes.greyText}>Allergens:</span>
          <span
            className={classnames(classes.floatRight, classes.ingredientInfo)}
          >
            {ingredient.allergens.join(', ')}
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

IngredientInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredient: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.number,
    unitOfMeasure: PropTypes.string,
    packagingType: PropTypes.string,
    portionMethod: PropTypes.string,
    brand: PropTypes.string,
    allergens: PropTypes.array,
    initialTestWeights: PropTypes.object,
  }).isRequired,
};

export default withStyles(qaCheckFormStyles)(IngredientInfo);
