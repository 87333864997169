export default {
  legendItem: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '9px 6px',
    fontSize: '13px',
    whiteSpace: 'nowrap',
  },
  legend: {
    padding: '20px',
    minWidth: '785px',
  },
  oneLiner: {
    whiteSpace: 'nowrap',
  },
};
