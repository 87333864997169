import {
  WHITE,
  GREY_200,
  GREY_600,
  GREY_800,
  HC_GREEN,
  DARK_HC_GREEN,
} from 'lib/constants';

export default {
  centered: {
    margin: '50px auto',
    textAlign: 'center',
  },
  card: {
    margin: '5px',
  },
  header: {
    backgroundColor: GREY_800,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    marginTop: '5px',
    borderRadius: '5px 5px 0px 0px',
  },
  ingName: {
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  lightGrey: {
    color: GREY_600,
  },
  textBold: {
    fontWeight: 'bold',
  },
  line: {
    color: GREY_200,
    opacity: '40%',
  },
  upperCard: {
    color: GREY_800,
    fontWeight: 'bold',
    padding: '5px 10px',
  },
  circle: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    border: '1px black solid',
    verticalAlign: 'middle',
    margin: '0px 5px',
  },
  qtyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '20px',
  },
  qtyBold: {
    fontWeight: 'bold',
  },
  qtyGrey: {
    marginLeft: '7px',
    color: GREY_600,
  },
  editButton: {
    fontSize: '16px',
    textAlign: 'center',
    margin: '20px 0px 5px',
    color: HC_GREEN,
    border: `1px solid ${HC_GREEN}`,
    backgroundColor: WHITE,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      color: WHITE,
    },
    width: '100%',
  },
  requestButton: {
    fontSize: '16px',
    textAlign: 'center',
    margin: '0px 0px 20px',
    color: WHITE,
    border: `1px solid ${HC_GREEN}`,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      color: WHITE,
    },
    width: '100%',
  },
};
