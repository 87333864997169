import * as Sentry from '@sentry/react';
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  NavLink,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Confetti from 'react-confetti';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  HOME_BUTTON,
  QR_CODE,
  WAREHOUSE_MOVES_CONTENT,
  BACK_BUTTON,
  PHONE_SCREEN,
} from 'lib/constants';
import { SuccessScreen, Snackbar } from 'components/shared';

import OpenRequests from './OpenRequests';
import ViewOpenRequest from './ViewRequest/ViewOpenRequest';
import ViewInProgressRequest from './ViewRequest/ViewInProgressRequest';
import ViewPallet from './ViewPallet';
import InProgressRequests from './InProgressRequests';
import ViewSearchPallet from './ViewSearchPallet';
import ManageSearchPallet from './ManageSearchPallet';
import ItemPutback from './ItemPutback';
import ItemPutbackQueue from './ItemPutbackQueue';
import FollowUp from './FollowUp';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const WarehouseMoves = ({
  location,
  history,
  classes,
  onSetTopBarContent,
  onFetchItemMoveRequests,
  onSubscribeToItemMoveRequests,
  onUnsubscribeToItemMoveRequests,
  onFetchPrinters,
  submitting,
  submissionFailed,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);

  const isRequestPage = /requests/.test(location.pathname);
  const isPalletPage = /pallet/.test(location.pathname);
  const isPutbacksPage = /putbacks\/+/.test(location.pathname);
  const isChildPage = isRequestPage || isPalletPage || isPutbacksPage;

  const [showQRIcon, setShowQRIcon] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const { scrollHeight, scrollWidth } = document.body;
  const { fetching, failed } = useSelector(
    (state) => state.guidedPutback.palletsPrintRequestStatus
  );

  const inProgressText = phoneScreen ? 'In Progress' : 'Moves In Progress';

  // We need to check the location.pathname to determine if we need to reset the showing of QR Icon,
  // without this check, the state will persist when pressing the back button in the `ViewOpenRequest` page.
  useEffect(() => {
    if (!isRequestPage) {
      setShowQRIcon(true);
    }
  }, [location.pathname, isRequestPage]);

  useEffect(() => {
    if (location.pathname === '/warehouse_moves') {
      history.push('/warehouse_moves/open');
    }
  }, [history, location.pathname]);

  useEffect(() => {
    onFetchPrinters();
  }, [onFetchPrinters]);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: isChildPage ? BACK_BUTTON : HOME_BUTTON,
      middleContent: isPalletPage || !showQRIcon ? null : QR_CODE,
      rightContent: WAREHOUSE_MOVES_CONTENT,
    });
  }, [
    isChildPage,
    isPalletPage,
    location.pathname,
    onSetTopBarContent,
    showQRIcon,
  ]);

  useEffect(() => {
    onFetchItemMoveRequests();
  }, [onFetchItemMoveRequests]);

  useEffect(() => {
    onSubscribeToItemMoveRequests();
    return () => {
      onUnsubscribeToItemMoveRequests();
    };
  }, [onSubscribeToItemMoveRequests, onUnsubscribeToItemMoveRequests]);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 5000); // 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);
  return (
    <Fragment>
      <SuccessScreen loading={submitting} failed={submissionFailed} />
      {showConfetti && (
        <Confetti
          width={scrollWidth}
          height={scrollHeight}
          style={{ zIndex: 6 }}
        />
      )}

      {/* Dont want headers showing up for the request page */}
      {!isChildPage && (
        <Grid container className={classes.navLinksContainer}>
          <Grid item xs={4} className={classes.navLinkContainer}>
            <NavLink
              to="/warehouse_moves/open"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Open Requests
            </NavLink>
          </Grid>
          <Grid item xs={4} className={classes.navLinkContainer}>
            <NavLink
              to="/warehouse_moves/in_progress"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              {inProgressText}
            </NavLink>
          </Grid>
          <Grid item xs={4} className={classes.navLinkContainer}>
            <NavLink
              to="/warehouse_moves/putbacks"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Putbacks
            </NavLink>
          </Grid>
        </Grid>
      )}
      <div className={classes.appContainer}>
        <Switch>
          <Route
            exact
            path="/warehouse_moves/open"
            render={() => <OpenRequests />}
          />
          <Route
            exact
            path="/warehouse_moves/in_progress"
            render={() => <InProgressRequests />}
          />
          <Route
            exact
            path="/warehouse_moves/putbacks"
            render={(routingProps) => <ItemPutbackQueue {...routingProps} />}
          />
          <Route
            exact
            path="/warehouse_moves/putbacks/new/:childPalletId"
            render={(routingProps) => <ItemPutback {...routingProps} />}
          />
          <Route
            exact
            path="/warehouse_moves/putbacks/in_progress/:putbackId"
            render={(routingProps) => <ItemPutback {...routingProps} />}
          />

          <Route
            exact
            path="/warehouse_moves/requests/open/:requestId"
            render={(routingProps) => (
              <ViewOpenRequest
                {...routingProps}
                setShowQRIcon={setShowQRIcon}
              />
            )}
          />
          <Route
            exact
            path="/warehouse_moves/requests/in_progress/:requestId"
            render={(routingProps) => (
              <ViewInProgressRequest
                {...routingProps}
                successConfettiCallback={setShowConfetti}
              />
            )}
          />

          <Route
            exact
            path="/warehouse_moves/pallet_search/:palletId"
            render={(routingProps) => <ViewSearchPallet {...routingProps} />}
          />
          <Route
            exact
            path="/warehouse_moves/pallet_search/:palletId/manage/:requestId"
            render={(routingProps) => <ManageSearchPallet {...routingProps} />}
          />
          <Route
            path={[
              '/warehouse_moves/pallets/:palletId',
              '/warehouse_moves/requests/:requestId/pallet/:palletId',
            ]}
            render={(routingProps) => <ViewPallet {...routingProps} />}
          />
          <Route
            exact
            path="/warehouse_moves/requests/:requestId/follow_up"
            render={(routingProps) => <FollowUp {...routingProps} />}
          />
        </Switch>
      </div>
      <Snackbar loading={fetching} failed={failed} />
    </Fragment>
  );
};

WarehouseMoves.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  onFetchItemMoveRequests: PropTypes.func.isRequired,
  onSubscribeToItemMoveRequests: PropTypes.func.isRequired,
  onUnsubscribeToItemMoveRequests: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submissionFailed: PropTypes.bool.isRequired,
};

export default WarehouseMoves;
