import * as Sentry from '@sentry/react';
import React, { Component } from 'react';
import { Switch, Route as ReactRouterDOMRoute } from 'react-router-dom';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';
import isNumber from 'lodash/isNumber';

import { LabelLetterBox } from 'components/shared';
import { percentProgress, formatNumber } from 'lib/utils';

import OverviewDetails from '../OverviewDetails';
import LabelingDetails from '../LabelingDetails';
import BaggingDetails from '../BaggingDetails';
import PortioningDetails from '../PortioningDetails';
import ProgressSummary from '../ProgressSummary';
import InventoryCheck from '../InventoryCheck';
import styles from './styles';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

class MealItem extends Component {
  static hasOverviewDetails(ingredient_assignments) {
    return typeof ingredient_assignments === 'undefined'
      ? false
      : !!ingredient_assignments.length ||
          window.location.href.match(/bagging/);
  }

  static progressColor(percentProgressCompleted, classes, percentTarget) {
    if (
      percentProgressCompleted >= 100 ||
      percentProgressCompleted - percentTarget > 5
    ) {
      return classes.aheadOfTarget;
    } else if (percentTarget - percentProgressCompleted > 5) {
      return classes.belowTarget;
    } else {
      return classes.onTarget;
    }
  }

  static remainingCountText(remainingCount) {
    const remainingCountAbs = formatNumber(Math.abs(remainingCount));
    const formattedNumber = remainingCountAbs === '-' ? 0 : remainingCountAbs;

    return remainingCount >= 0
      ? `${formattedNumber} To Goal`
      : `${formattedNumber} Over`;
  }

  static filteredIngredientAssignments(ingredient_assignments) {
    if (window.location.pathname.includes('labeling')) {
      return ingredient_assignments.filter((ia) =>
        isNumber(ia.labeling_percent)
      );
    } else if (window.location.pathname.includes('portioning')) {
      return ingredient_assignments.filter(
        (ia) => ia.method !== 'no portion method'
      );
    } else {
      return ingredient_assignments;
    }
  }

  progressBar(summaryParams) {
    const { goalCount, remainingCount, endOfShiftTarget } = summaryParams;
    if (goalCount > 0) {
      return (
        <ProgressSummary
          percentProgress={percentProgress(goalCount, remainingCount)}
          goalCount={goalCount}
          remainingCountText={this.constructor.remainingCountText(
            remainingCount
          )}
          classes={this.props.classes}
          progressColor={this.constructor.progressColor(
            percentProgress(goalCount, remainingCount),
            this.props.classes,
            endOfShiftTarget
          )}
          endOfShiftPercentTarget={endOfShiftTarget}
        />
      );
    } else {
      return null;
    }
  }

  expandMoreIcon(ingredient_assignments, meal) {
    return this.constructor.hasOverviewDetails(ingredient_assignments) ? (
      <ExpandMoreIcon className={meal.classes.expandMore} />
    ) : (
      <div className={meal.classes.expandMore} />
    );
  }

  render() {
    const { ...meal } = this.props;

    let { ingredient_assignments, expanded, toggleAction } = this.props;
    ingredient_assignments = this.constructor.filteredIngredientAssignments(
      ingredient_assignments
    );

    return (
      <div className={meal.classes.mealItem}>
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={this.expandMoreIcon(ingredient_assignments, meal)}
            onClick={() =>
              ingredient_assignments.length > 0 ||
              window.location.href.match(/bagging/)
                ? // TODO: Constantize this
                  toggleAction({ type: 'meal', id: meal.id })
                : null
            }
          >
            <Grid container spacing={0} style={{ paddingRight: 0 }}>
              <Grid item xs={1}>
                <LabelLetterBox
                  letter={meal.label_order}
                  empty={!meal.label_order}
                />
              </Grid>
              <Grid className={meal.classes.titleRoot} item xs={3}>
                <span className={meal.classes.emphasizedText}>
                  {meal.title}
                </span>
              </Grid>
              <Switch>
                <Route
                  exact
                  path="/progress_tracker/menu/:menuId/overview"
                  render={() => {
                    const summaryParams = {
                      goalCount: meal.goal_count,
                      remainingCount: meal.remaining_count,
                      endOfShiftTarget: meal.end_of_shift_percent_target,
                    };
                    return this.progressBar(summaryParams);
                  }}
                />
                <Route
                  exact
                  path="/progress_tracker/menu/:menuId/labeling"
                  render={() => {
                    const summaryParams = {
                      goalCount: meal.labelingGoalCount,
                      remainingCount: meal.remainingLabelingCount,
                      endOfShiftTarget: meal.labelingEndOfShiftTarget,
                    };
                    return this.progressBar(summaryParams);
                  }}
                />
                <Route
                  exact
                  path="/progress_tracker/menu/:menuId/portioning"
                  render={() => {
                    const summaryParams = {
                      goalCount: meal.portioningGoalCount,
                      remainingCount: meal.remainingPortioningCount,
                      endOfShiftTarget: meal.portioningEndOfShiftTarget,
                    };
                    return this.progressBar(summaryParams);
                  }}
                />
                <Route
                  exact
                  path="/progress_tracker/menu/:menuId/bagging"
                  render={() => {
                    const summaryParams = {
                      goalCount: meal.goal_count,
                      remainingCount: meal.remaining_count,
                      endOfShiftTarget: meal.end_of_shift_percent_target,
                    };
                    return this.progressBar(summaryParams);
                  }}
                />
              </Switch>
              <Route
                exact
                path="/progress_tracker/menu/:menuId/overview"
                render={() => (
                  <Grid item>
                    <InventoryCheck meal={meal} />
                  </Grid>
                )}
              />
            </Grid>
          </AccordionSummary>

          {this.constructor.hasOverviewDetails(ingredient_assignments) ? (
            <Switch>
              <Route
                exact
                path="/progress_tracker/menu/:menuId/overview"
                render={() => (
                  <OverviewDetails
                    ingredient_assignments={ingredient_assignments}
                  />
                )}
              />
              <Route
                exact
                path="/progress_tracker/menu/:menuId/labeling"
                render={() => (
                  <LabelingDetails
                    ingredient_assignments={ingredient_assignments}
                    remainingCount={meal.remaining_count}
                  />
                )}
              />
              <Route
                exact
                path="/progress_tracker/menu/:menuId/portioning"
                render={() => (
                  <PortioningDetails
                    ingredient_assignments={ingredient_assignments}
                    remainingCount={meal.remaining_count}
                  />
                )}
              />
              <Route
                exact
                path="/progress_tracker/menu/:menuId/bagging"
                render={() => (
                  <BaggingDetails
                    daySplitGoals={meal.day_split_goals}
                    mealId={meal.id}
                  />
                )}
              />
            </Switch>
          ) : null}
        </Accordion>
      </div>
    );
  }
}

MealItem.propTypes = {
  classes: PropTypes.object.isRequired,
  goal_count: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  ingredient_assignments: PropTypes.array.isRequired,
  label_order: PropTypes.string,
  remaining_count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  end_of_shift_percent_target: PropTypes.number,
  expanded: PropTypes.bool,
  toggleAction: PropTypes.func,
  day_split_goals: PropTypes.array,
  labelingGoalCount: PropTypes.number,
  remainingLabelingCount: PropTypes.number,
  portioningGoalCount: PropTypes.number,
  remainingPortioningCount: PropTypes.number,
  labelingEndOfShiftTarget: PropTypes.number,
  portioningEndOfShiftTarget: PropTypes.number,
};

MealItem.defaultProps = {
  label_order: undefined,
  end_of_shift_percent_target: undefined,
  expanded: undefined,
  toggleAction: undefined,
  day_split_goals: undefined,
  labelingGoalCount: undefined,
  remainingLabelingCount: undefined,
  portioningGoalCount: undefined,
  remainingPortioningCount: undefined,
  labelingEndOfShiftTarget: undefined,
  portioningEndOfShiftTarget: undefined,
};

export default withStyles(styles)(MealItem);
