import {
  apiRequestConstant,
  apiSuccessConstant,
  apiErrorConstant,
  API_BASE_URI,
} from 'lib/constants';

const apiRequest = ({ type, params }) => ({
  type: apiRequestConstant(type),
  requestType: type,
  params,
});

const apiSuccess = ({ type, params, response }) => ({
  type: apiSuccessConstant(type),
  requestType: type,
  response,
  params,
});

const apiError = ({ type, params, response, error }) => ({
  type: apiErrorConstant(type),
  requestType: type,
  params,
  response,
  error: { ...error, type },
});

const redirectToAdminLogin = () => {
  window.location = `${API_BASE_URI}/`;
};

const redirectToRootLogin = () => {
  window.location = '/';
};

export const makeApiRequest = ({ type, method, params }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(apiRequest({ type, params }));
      const response = await method(params);
      if (response.status === 401) {
        redirectToAdminLogin();
      } else if (response.status === 403) {
        redirectToRootLogin();
      } else if (response.ok) {
        dispatch(apiSuccess({ type, params, response }));
      } else {
        console.error(new Error(`API Error ${type} ${response.status}`), {
          type,
          params,
          response,
        });
        dispatch(apiError({ type, params, response }));
      }
      return response;
    } catch (error) {
      console.error(error, { type, params, state: getState() });
      dispatch(apiError({ type, params, error }));
      return { ok: false };
    }
  };
};
