import { HC_GREEN, WHITE } from 'lib/constants';

export default {
  successScreen: {
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: HC_GREEN,
    color: WHITE,
    height: '100vh',
    width: '100%',
    paddingTop: '23vh',
    fontSize: '32px',
    transition: 'visibility 0.15s, opacity 0.15s linear',
    opacity: 0,
    visibility: 'hidden',
  },
  checkmark: {
    width: '120px',
    marginTop: '25px',
  },
  showSuccessScreen: {
    visibility: 'visible',
    opacity: 1,
  },
};
