import { GREY_200 } from 'lib/constants';

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 20px',
    borderBottom: `1px solid ${GREY_200}`,
    fontWeight: 'bold',
    fontSize: 20,
  },
  bodySection: {
    overflowY: 'auto',
    height: 'calc(100vh - 85px)',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: 20,
    height: 20,
    margin: '0 auto',
  },
  noScroll: {
    overflowY: 'hidden',
  },
  infoLabel: {
    fontSize: 14,
  },
  infoValue: {
    fontSize: 20,
    margin: '5px 0px 20px',
    marginInlineStart: 0,
  },
};
