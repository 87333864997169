import { connect } from 'react-redux';

import { addValueToFsqaReview } from 'redux/wmsFsqaReview/actions';
import {
  SELECTED_ALLERGENS,
  EXPECTED_ALLERGENS,
  TRUE_ALLERGENS_CORRECT,
  INTOLERANCES_CORRECT,
} from 'lib/constants';

import GroupedAllergensContainer from './GroupedAllergensContainer';

const mapStateToProps = (state) => ({
  groupedAllergens: state.pallets.qaApp.groupedAllergens || {},
  allAllergens: state.pallets.qaApp.allAllergens,
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectedAllergens: (allergens) =>
    dispatch(
      addValueToFsqaReview(ownProps.pallet.id, SELECTED_ALLERGENS, allergens)
    ),
  updateExpectedAllergens: (expectedAllergens) =>
    dispatch(
      addValueToFsqaReview(
        ownProps.pallet.id,
        EXPECTED_ALLERGENS,
        expectedAllergens
      )
    ),
  updateAllergensCorrect: (allergensListed) =>
    dispatch(
      addValueToFsqaReview(
        ownProps.pallet.id,
        TRUE_ALLERGENS_CORRECT,
        allergensListed
      )
    ),
  updateIntolerancesCorrect: (intolerancesListed) =>
    dispatch(
      addValueToFsqaReview(
        ownProps.pallet.id,
        INTOLERANCES_CORRECT,
        intolerancesListed
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupedAllergensContainer);
