import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

import { ENTER_KEY_CODE, PHONE_SCREEN } from 'lib/constants';
import { useHiddenNativeKeyboard, useScanner } from 'lib/custom_hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const PalletScanTextField = ({ canScan, onScan, clearAfterScan }) => {
  const classes = useStyles();
  const inputElement = useRef(null);
  const phoneScreen = useMediaQuery(PHONE_SCREEN);

  const [palletNumber, setPalletNumber] = useState('');

  useScanner(inputElement, onScan, canScan);

  const handleScan = (palletNum) => {
    onScan(palletNum);
    if (clearAfterScan) setPalletNumber('');
  };

  const onInputChange = (e) => {
    let val = e.target.value;
    if (val.includes('0'.repeat(20))) {
      handleScan(val.replace(/^0+/, ''));
    } else if (!phoneScreen) {
      setPalletNumber(val);
    }
  };

  const onInputKeydown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE && palletNumber.length) {
      handleScan(palletNumber);
    }
  };
  const hideKeyboard = useHiddenNativeKeyboard(inputElement);

  return (
    <TextField
      data-testid="pallet-scan-text-field"
      variant="outlined"
      label="Pallet ID"
      value={palletNumber}
      onChange={onInputChange}
      onKeyDown={onInputKeydown}
      classes={{ root: classes.inputRoot }}
      InputProps={{ classes: { input: classes.input } }}
      inputRef={inputElement}
      onFocus={hideKeyboard}
    />
  );
};

PalletScanTextField.propTypes = {
  canScan: PropTypes.bool,
  onScan: PropTypes.func,
  clearAfterScan: PropTypes.bool,
};

PalletScanTextField.defaultProps = {
  canScan: true,
  onScan: null,
  clearAfterScan: false,
};

export default PalletScanTextField;
