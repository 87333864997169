import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';

import { QRCodeIcon } from 'components/icons';
import { getDepths } from 'redux/storageSlot/actions';
import {
  ENTER_KEY_CODE,
  PHONE_SCREEN,
  TABLET_SCREEN,
  SLOT_REGEX,
} from 'lib/constants';
import { useHiddenNativeKeyboard, useScanner } from 'lib/custom_hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const LocationInput = ({
  palletId,
  locationNumber,
  setLocationNumber,
  isActivePallet,
  isOnHoldPallet,
  canScan,
  iconMessage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { packing_facility_id } = useSelector((state) => state.session);
  const locationInputElement = useRef(null);

  const onGetDepths = (scannedValue) => {
    if (scannedValue.match(SLOT_REGEX)) {
      dispatch(getDepths(scannedValue, packing_facility_id, palletId));
    }
  };

  useScanner(locationInputElement, onGetDepths, canScan);

  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const tabletScreen = useMediaQuery(TABLET_SCREEN);
  const hideLocationKeyboard = useHiddenNativeKeyboard(locationInputElement);

  const getDepthsFromScan = (scannedValue) => {
    if (isActivePallet || isOnHoldPallet) {
      onGetDepths(scannedValue);
    }
  };

  const onLocationInputChange = (e) => {
    if (phoneScreen && e.target.value.length > 1) {
      getDepthsFromScan(e.target.value);
    } else if (!phoneScreen && !tabletScreen) {
      setLocationNumber(e.target.value);
    }
  };

  const onLocationInputKeydown = (e) => {
    if (
      !tabletScreen &&
      e.keyCode === ENTER_KEY_CODE &&
      locationNumber.length
    ) {
      getDepthsFromScan(locationNumber);
    }
  };

  return (
    <>
      <div className={classes.qrCodeAndMessage}>
        <QRCodeIcon className={classes.icon} />
        {iconMessage}
      </div>
      <div className={classes.scanningArea} data-testid="location-input">
        <TextField
          data-testid="scan-location-input"
          onChange={onLocationInputChange}
          onKeyDown={onLocationInputKeydown}
          className={classes.inputRoot}
          InputProps={{ classes: { input: classes.input } }}
          inputRef={locationInputElement}
          onFocus={hideLocationKeyboard}
          variant="outlined"
          label="Location ID"
          id="locationId"
          value={locationNumber}
        />
      </div>
    </>
  );
};

LocationInput.propTypes = {
  palletId: PropTypes.number.isRequired,
  setLocationNumber: PropTypes.func.isRequired,
  locationNumber: PropTypes.string,
  isActivePallet: PropTypes.bool,
  isOnHoldPallet: PropTypes.bool,
  canScan: PropTypes.bool.isRequired,
  iconMessage: PropTypes.string,
};

LocationInput.defaultProps = {
  isActivePallet: false,
  isOnHoldPallet: false,
  iconMessage: '',
  locationNumber: '',
};

export default LocationInput;
