import { makeApiRequest } from 'lib/api';
import {
  ITEM_MOVE_REQUESTS_GET,
  ITEM_MOVE_REQUEST_PUT,
  ITEM_MOVE_REQUEST_POST,
  ITEM_MOVE_REQUEST_UPDATE,
  UPDATE_MOVE_REQUEST_FILTERS,
  HISTORICAL_ITEM_MOVE_REQUESTS_GET,
  ITEM_MOVE_REQUESTS_QUERY,
  ITEM_MOVE_REQUESTS_CLEAR_SEARCH,
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET,
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET,
  ITEM_MOVE_REQUEST_CLEAR_SUGGESTED_PALLET,
  PRINT_CHILD_LICENSE_PLATE_POST,
} from 'lib/constants';

import ItemMoveRequests from './services';

export const getItemMoveRequests = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUESTS_GET,
        method: ItemMoveRequests.getItemMoveRequests,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};

export const fetchHistoricals = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: HISTORICAL_ITEM_MOVE_REQUESTS_GET,
        method: ItemMoveRequests.fetchHistoricals,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          numberRecordsRequested:
            getState().itemMoveRequests.itemMoveRequests.filter(
              (r) => r.droppedOff || r.cancelled
            ).length + 10,
        },
      })
    );
  };
};

export const fetchRecentMoveRequests = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUESTS_GET,
        method: ItemMoveRequests.fetchRecentMoveRequests,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};

export const submitItemMoveRequest = (palletId, newQuantity, fullPallet) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUEST_POST,
        method: ItemMoveRequests.createItemMoveRequest,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          palletId,
          newQuantity,
          fullPallet,
        },
      })
    );
  };
};

export const submitMoveRequests = (
  requests,
  locationName,
  menuId,
  menuType,
  mealId
) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUEST_POST,
        method: ItemMoveRequests.createItemMoveRequests,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          requests,
          locationName,
          menuId,
          menuType,
          mealId,
        },
      })
    );
  };
};

export const updateItemMoveRequest = (
  requestId,
  moveType,
  changedAttributes
) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUEST_PUT,
        method: ItemMoveRequests.putItemMoveRequest,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          requestId,
          moveType,
          changedAttributes,
        },
      })
    );
  };
};

export const printChildLicensePlate = (
  requestId,
  numLabels,
  storageSlotId = null
) => {
  return async (dispatch, getState) => {
    return await dispatch(
      makeApiRequest({
        type: PRINT_CHILD_LICENSE_PLATE_POST,
        method: ItemMoveRequests.printChildLicensePlate,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          requestId,
          numLabels,
          storageSlotId,
        },
      })
    );
  };
};

export const itemMoveRequestReceiver = (itemMoveRequest) => {
  return {
    type: ITEM_MOVE_REQUEST_UPDATE,
    payload: { itemMoveRequest },
  };
};

export const updateMoveRequestFilters = (filters) => ({
  type: UPDATE_MOVE_REQUEST_FILTERS,
  filters,
});

export const fetchItemsFromQuery = (query) => async (dispatch, getState) => {
  const { session } = getState();

  return await dispatch(
    makeApiRequest({
      type: ITEM_MOVE_REQUESTS_QUERY,
      method: ItemMoveRequests.getFromQuery,
      params: {
        packingFacilityId: session.packing_facility_id,
        searchQuery: query,
      },
    })
  );
};

export const clearSearchResults = () => ({
  type: ITEM_MOVE_REQUESTS_CLEAR_SEARCH,
});

export const getItemMoveRequestCaseConversion = (
  itemMoveRequestId,
  palletId
) => {
  return async (dispatch, getState) => {
    const { session } = getState();

    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUEST_CASE_CONVERSION_GET,
        method: ItemMoveRequests.getCaseConversion,
        params: {
          packingFacilityId: session.packing_facility_id,
          itemMoveRequestId,
          palletId,
        },
      })
    );
  };
};

export const fetchSuggestedPallet = (requestId) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET,
        method: ItemMoveRequests.fetchSuggestedPallet,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          requestId,
        },
      })
    );
  };
};

export const clearSuggestedPallet = () => ({
  type: ITEM_MOVE_REQUEST_CLEAR_SUGGESTED_PALLET,
});
