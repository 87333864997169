import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { selectMenu, fetchMenusAndSelectFirst } from 'redux/menu/actions';
import { updateSelectedFacility } from 'redux/session/actions';

import ProgressTrackerContent from './ProgressTrackerContent';

const ProgressTrackerContentContainer = ({
  session,
  menus,
  meals,
  onFetchMenusAndSelectFirst,
  onMenuSelected,
  onSelectedFacilityUpdated,
}) => {
  useEffect(() => {
    const showExtendedMenus = true;
    onFetchMenusAndSelectFirst(showExtendedMenus);
  }, []);

  return (
    <ProgressTrackerContent
      session={session}
      menus={menus}
      meals={meals}
      location={location}
      onMenuSelected={onMenuSelected}
      onSelectedFacilityUpdated={onSelectedFacilityUpdated}
    />
  );
};

ProgressTrackerContentContainer.propTypes = {
  session: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
  meals: PropTypes.object.isRequired,
  onFetchMenusAndSelectFirst: PropTypes.func.isRequired,
  onMenuSelected: PropTypes.func.isRequired,
  onSelectedFacilityUpdated: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  meals: state.meals,
  session: state.session,
});

const mapDispatchToProps = {
  onMenuSelected: selectMenu,
  onSelectedFacilityUpdated: updateSelectedFacility,
  onFetchMenusAndSelectFirst: fetchMenusAndSelectFirst,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgressTrackerContentContainer)
);
