/* For detailed info on incoporating this hook check out the hookUsage doc! */

import { useState, useCallback, useMemo } from 'react';
import { debounceSearchRender } from 'mui-datatables';

const tableActions = [
  'filterChange',
  'resetFilters',
  'changePage',
  'changeRowsPerPage',
  'sort',
  'search',
];

// helper for formatting filter data correctly
// filter data comes in as an array of filter strings, which need to matched to the corresponding column name
const formatStateToQueryParamsObject = ({
  page,
  rowsPerPage,
  sortOrder,
  searchText,
  filterList,
  columns,
}) => {
  const formatedFilters = filterList.reduce(
    (formattedFiltersObject, filter, index) => {
      if (!filter.length) return formattedFiltersObject;

      const filterId = columns[index].name;
      const filterValue = filter[0];

      return {
        ...formattedFiltersObject,
        [`filter:${filterId}`]: filterValue,
      };
    },
    {}
  );

  return {
    page,
    rows_per_page: rowsPerPage,
    sort_column: sortOrder.name,
    sort_direction: sortOrder.direction,
    ...(searchText && { search_query: searchText }),
    // filter info is the form { "filter:<column_name>": "<filter_condition>" }
    ...(Object.keys(formatedFilters).length && { ...formatedFilters }),
  };
};

const useServerSideMuiDataTable = (onTableChange, tableOptions) => {
  const [dynamicOptions, setDynamicOptions] = useState({
    rowsPerPage: tableOptions.rowsPerPage,
    sortOrder: tableOptions.sortOrder,
  });

  const tableChangeCallback = useCallback((tableState) => {
    onTableChange(formatStateToQueryParamsObject(tableState));
    setDynamicOptions({
      rowsPerPage: tableState.rowsPerPage,
      sortOrder: tableState.sortOrder,
    });
  });

  const staticOptions = useMemo(() => ({
    serverSide: true,
    onTableInit: (action, tableState) => tableChangeCallback(tableState),
    onTableChange: (action, tableState) =>
      tableActions.includes(action) && tableChangeCallback(tableState),
    customSearchRender: debounceSearchRender(500),
  }));

  return {
    serverSideTableOptions: {
      ...tableOptions,
      ...staticOptions,
      ...dynamicOptions,
    },
  };
};

export default useServerSideMuiDataTable;
