import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import withStyles from '@mui/styles/withStyles';

import { sortByLabelOrder, sortByLetterAssignment } from 'lib/viewHelpers';

import MealBagInventoryRow from '../MealBagInventoryRow';
import ProteinInventoryRow from '../ProteinInventoryRow';
import styles from './styles';
import { sharedStyles } from '../styles';

const mergedStyles = { ...styles, ...sharedStyles };

const BoxingInventoryTable = ({
  boxingInventory,
  classes,
  menus,
  session,
  onUpdateSelectedShipDateAndFetch,
}) => (
  <div>
    <div className={classes.inventoryTable}>
      <div className={classes.shipDateDropdown}>
        <FormControl variant="standard" className={classes.formControl}>
          <Select
            variant="standard"
            value={boxingInventory.ship_date_value}
            onChange={(event) =>
              onUpdateSelectedShipDateAndFetch(
                menus.selectedMenu.id,
                session.selectedPackingFacilityId,
                event.target.value
              )
            }
          >
            {boxingInventory.results.available_ship_dates.map((date) => {
              return (
                <MenuItem value={date.value} key={date.value}>
                  {date.pretty}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Ship Date</FormHelperText>
        </FormControl>
      </div>
      <h3 className={classes.inventoryHeader}>Proteins</h3>
      {boxingInventory.results.proteins.length > 0 ? (
        <Grid container spacing={0} style={{ paddingRight: 0 }}>
          <Grid className={classes.tableHeader} item xs={1} />
          <Grid className={classes.tableHeader} item xs={5}>
            Name
          </Grid>
          <Grid className={classes.tableHeader} item xs={2}>
            Required
          </Grid>
        </Grid>
      ) : (
        <div className={classes.errorMessage}>
          Protein information has yet to be entered. The data should be
          available later in the afternoon
        </div>
      )}

      {sortByLetterAssignment(boxingInventory.results.proteins).map(
        (protein) => {
          return (
            <ProteinInventoryRow
              key={`${protein.letter_assignment}_${protein.title}`}
              protein={protein}
            />
          );
        }
      )}
    </div>

    <div className={classes.inventoryTable}>
      <h3 className={classes.inventoryHeader}>Meal Bags</h3>
      <Grid container spacing={0} style={{ paddingRight: 0 }}>
        <Grid className={classes.tableHeader} item xs={1} />
        <Grid className={classes.tableHeader} item xs={5}>
          Name
        </Grid>
        <Grid className={classes.tableHeader} item xs={2}>
          Required
        </Grid>
        <Grid className={classes.tableHeader} item xs={2}>
          On Hand
        </Grid>
        <Grid className={classes.tableHeader} item xs={2}>
          Difference
        </Grid>
      </Grid>

      {sortByLabelOrder(boxingInventory.results.meal_bags).map((mealBag) => (
        <MealBagInventoryRow key={mealBag.id} mealBag={mealBag} />
      ))}
      {sortByLabelOrder(
        boxingInventory.results.serialized_ingredient_pickables
      ).map((pickable) => (
        <MealBagInventoryRow
          key={`Ingredient${pickable.id}`}
          mealBag={pickable}
        />
      ))}
    </div>
  </div>
);

BoxingInventoryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
  session: PropTypes.object,
  boxingInventory: PropTypes.object.isRequired,
  onUpdateSelectedShipDateAndFetch: PropTypes.func.isRequired,
};

BoxingInventoryTable.defaultProps = {
  session: undefined,
};

export default withStyles(mergedStyles)(BoxingInventoryTable);
