import theme from 'lib/theme';

export default {
  card: {
    position: 'relative',
    maxWidth: '345px',
    margin: '10px',
    height: '210px',
    [theme.breakpoints.down('md')]: {
      height: '238px',
    },
  },
  media: {
    height: '140px',
    opacity: '0.7',
  },
  cardMediaContent: {
    position: 'relative',
    textAlign: 'center',
  },
  content: {
    padding: '2px',
    textAlign: 'center',
    fontSize: '14px',
  },
  letterAssignment: {
    fontSize: '60px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '30px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
};
