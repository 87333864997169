import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import styles from '../styles';

const useStyles = makeStyles(styles);

const ItemCell = ({ item }) => {
  const classes = useStyles();

  const summary = (i) => {
    const { id, brand, expiration_date } = i;

    return brand
      ? `${id} - ${brand} - Exp: ${expiration_date}`
      : `${id} - Exp: ${expiration_date}`;
  };

  return (
    <>
      <div className={classes.itemName}>{item.name}</div>
      <div>{summary(item)}</div>
    </>
  );
};

ItemCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemCell;
