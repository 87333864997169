import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import PreliminaryCheck from './PreliminaryCheck';
import Confirmation from './Confirmation';
import MealGrid from './MealGrid';
import QaCheckForm from './QaCheckForm';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const QaMealBagging = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={['/qa/meal_bagging', '/qa/meal_bagging/menu/:menu_id']}
          render={(routingProps) => <MealGrid {...routingProps} />}
        />
        <Route
          exact
          path="/qa/meal_bagging/preliminary-checks/:meal_id"
          render={(routingProps) => <PreliminaryCheck {...routingProps} />}
        />
        <Route
          exact
          path="/qa/meal_bagging/preliminary-checks/:preliminary_check_id/checks"
          component={(routingProps) => <QaCheckForm {...routingProps} />}
        />
        <Route
          exact
          path="/qa/meal_bagging/preliminary-checks/:preliminary_check_id/confirmation"
          render={(routingProps) => <Confirmation {...routingProps} />}
        />
      </Switch>
    </Fragment>
  );
};

export default QaMealBagging;
