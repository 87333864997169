import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

import qaApp from './qaApp/services';
import receivingApp from './receivingApp/services';
import inventoryApp from './inventoryApp/services';

const printPallets = async ({ pallets, printer, firstPrint }) => {
  const response = await fetch(`${API_BASE_URI}/ops/warehouse/pallets/print`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      pallets,
      printer,
      first_print: firstPrint,
    }),
  });

  return await handleResponse(response);
};

export default {
  printPallets,
  qaApp,
  receivingApp,
  inventoryApp,
};
