import { DARK_HC_GREEN, GREY_200 } from 'lib/constants';

const buttonDisabled = {
  backgroundColor: GREY_200,
};

export default {
  button: {
    height: '50px',
    marginTop: 20,
    boxShadow: 'none',
    borderRadius: '0px 4px 4px 0px',
    backgroundColor: DARK_HC_GREEN,
    '&$buttonDisabled': buttonDisabled,
  },
  menu: {
    padding: '10px',
    fontFamily: 'Muli',
    maxWidth: '300px',
  },
  formControlLabel: {
    fontFamily: 'Muli',
  },
  title: {
    marginBottom: '10px',
  },
  buttonDisabled,
};
