import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import {
  ITEM_MANAGER_AUDIT,
  ITEM_MANAGER_NOT_LESS_THAN_ZERO,
  ITEM_MANAGER_QUANTITY_REQUIRED,
} from 'lib/constants';

import FormDrawer from '../formComponents/FormDrawer';
import CaseQuantity from '../formComponents/CaseQuantity';
import SubmitButtonWrapper from '../formComponents/SubmitButtonWrapper';

const AuditForm = ({ productionZoneItem, buttonStyles }) => {
  const [open, toggleOpen] = useState(false);
  return (
    <>
      <Button
        className={buttonStyles}
        variant="contained"
        onClick={() => toggleOpen(true)}
      >
        Audit
      </Button>
      <FormDrawer
        open={open}
        toggleOpen={toggleOpen}
        headerText="Audit Item"
        productionZoneItem={productionZoneItem}
      >
        <CaseQuantity
          promptText={`How many items are in ${productionZoneItem.zoneName}?`}
          initialQuantity={productionZoneItem.quantity}
        />
        <SubmitButtonWrapper
          buttonText="Update"
          onSuccess={toggleOpen}
          moveType={ITEM_MANAGER_AUDIT}
          validationSelections={[
            ITEM_MANAGER_NOT_LESS_THAN_ZERO,
            ITEM_MANAGER_QUANTITY_REQUIRED,
          ]}
        />
      </FormDrawer>
    </>
  );
};

AuditForm.defaultProps = {
  buttonStyles: '',
};

AuditForm.propTypes = {
  productionZoneItem: PropTypes.object.isRequired,
  buttonStyles: PropTypes.string,
};
export default AuditForm;
