import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import { CHANGE_DROP } from 'lib/constants';
import { CloseIcon } from 'components/icons';
import { StatefulButton } from 'components/shared';

const buttonText = {
  LOADING: 'Updating...',
  SUCCESS: 'Success',
  ERROR: 'There was an error updating',
  DEFAULT: 'Change Drop Location',
};

const ChangeDropDialog = ({
  podLocations,
  showChangeDrop,
  setChangeDropNextStep,
  rowData,
  onUpdateItemMoveRequest,
  updating,
  updatingFailed,
  classes,
}) => {
  const currentDropLocation = rowData.dropOffLocation;
  const [dropLocation, setDropLocation] = useState(currentDropLocation);

  const closeDialog = () => {
    setChangeDropNextStep(false);
    setDropLocation(currentDropLocation);
  };

  const getStorageSlotId = () => {
    const podObj = podLocations.filter(
      (pod) => pod.description === dropLocation
    );
    return podObj[0].storageSlotId;
  };

  const updateDropLocation = () => {
    onUpdateItemMoveRequest(rowData.id, CHANGE_DROP, {
      drop_off_location_id: getStorageSlotId(),
    });
  };

  return (
    <Dialog
      open={showChangeDrop}
      onClose={closeDialog}
      className={classes.root}
      data-testid="change-drop-dialog"
    >
      <Button className={classes.closeButton} onClick={closeDialog}>
        <CloseIcon className={classes.icon} />
      </Button>
      <div className={classes.text}>Select a new Drop Location</div>

      <ToggleButtonGroup
        exclusive
        value={dropLocation}
        className={classes.toggleButtonGroup}
      >
        {podLocations.map((location) => (
          <ToggleButton
            key={location.id}
            value={location.description}
            className={classes.reasonButton}
            onClick={() => setDropLocation(location.description)}
          >
            {location.description}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <StatefulButton
        buttonTextOptions={buttonText}
        successCallback={closeDialog}
        loading={updating}
        failed={updatingFailed}
        disabled={dropLocation === currentDropLocation}
        type="button"
        id="submitButton"
        data-testid="submit-button"
        classes={{ root: classes.submitChangeDropButton }}
        onClick={() => updateDropLocation()}
      />
    </Dialog>
  );
};

ChangeDropDialog.propTypes = {
  podLocations: PropTypes.array.isRequired,
  showChangeDrop: PropTypes.bool.isRequired,
  setChangeDropNextStep: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  onUpdateItemMoveRequest: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ChangeDropDialog;
