import React from 'react';
import PropTypes from 'prop-types';

const TruckIcon = ({ className, classes }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 69.15">
        <g className={classes.lowOpacity}>
          <polygon
            className={classes.green}
            points="13.7 36.01 27.97 21.62 33.46 21.62 33.46 36.01 13.7 36.01"
          />
        </g>
        <circle className={classes.green} cx="106.07" cy="58.03" r="11.12" />
        <circle className={classes.white} cx="106.07" cy="58.03" r="5.15" />
        <path
          className={classes.green}
          d="M39.69,0V11.5H22.44L0,35.33v25.2H6.15a2.5,2.5,0,1,0,0-5H5V37.31L24.6,16.5H39.69v39H34a11.12,11.12,0,1,0,0,5H89.13a2.5,2.5,0,0,0,0-5H44.69V5H122V55.53h-5.1a11.12,11.12,0,1,0,0,5H127V0ZM23.21,63.18A5.15,5.15,0,1,1,28.36,58,5.15,5.15,0,0,1,23.21,63.18Zm82.86,0A5.15,5.15,0,1,1,111.22,58,5.14,5.14,0,0,1,106.07,63.18Z"
        />
        <g className={classes.lowOpacity}>
          <circle className={classes.green} cx="106.07" cy="58.03" r="5.15" />
        </g>
        <g className={classes.lowOpacity}>
          <circle className={classes.green} cx="23.21" cy="58.03" r="5.15" />
        </g>
      </svg>
    </div>
  );
};

TruckIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

TruckIcon.defaultProps = {
  className: 'TruckIcon',
};

export default TruckIcon;
