import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import withStyles from '@mui/styles/withStyles';

import { LabelLetterBox } from 'components/shared';
import { formatNumber } from 'lib/utils';

import { rowStyles } from '../styles';

const ProteinInventoryRow = (props) => {
  const { classes, protein } = props;
  const { letter_assignment, title, total_proteins_yet_to_be_boxed } = protein;

  return (
    <Accordion expanded={false} key={letter_assignment}>
      <AccordionSummary>
        <Grid
          container
          spacing={0}
          style={{ paddingRight: 0 }}
          data-testid="protein-inventory-row"
        >
          <Grid item xs={1}>
            <LabelLetterBox
              letter={letter_assignment}
              empty={!letter_assignment}
            />
          </Grid>
          <Grid className={classes.titleRoot} item xs={5}>
            <span className={classes.emphasizedText}>{title}</span>
          </Grid>
          <Grid className={classes.titleRoot} item xs={2}>
            <span>{formatNumber(total_proteins_yet_to_be_boxed)}</span>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
};

ProteinInventoryRow.propTypes = {
  classes: PropTypes.object.isRequired,
  protein: PropTypes.shape({
    letter_assignment: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
  }).isRequired,
};

export default withStyles(rowStyles)(ProteinInventoryRow);
