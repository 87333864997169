import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const updateCountListAssignment = async ({ countListAssignmentId, cla }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_list_assignments/${countListAssignmentId}`,
    {
      ...options,
      method: 'PATCH',
      body: JSON.stringify({
        count_list_assignment: cla,
      }),
    }
  );

  return await handleResponse(response);
};

export default {
  updateCountListAssignment,
};
