import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {
  fetchPallet,
  resetSelectedPallet,
  updatePallet,
} from 'redux/pallets/actions';
import { getDepths, resetStorageSlots } from 'redux/storageSlot/actions';

import ViewSearchPallet from './ViewSearchPallet';
import styles from './styles';

const mapStateToProps = (state, ownProps) => {
  return {
    palletId: ownProps.match.params.palletId,
    pallet: state.pallets.inventoryApp.selectedPallet,
    fetching: state.pallets.inventoryApp.fetching,
    fetchFailed: state.pallets.inventoryApp.fetchFailed,
    palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
    updating: state.pallets.inventoryApp.updating,
    updatingFailed: state.pallets.inventoryApp.updateFailed,
    packingFacilityId: state.session.packing_facility_id,
    depthsFailed: state.storageSlots.failed,
    depthsLoading: state.storageSlots.loading,
    depthOptions: state.itemMoveRequests.depthOptions,
    history: ownProps.history,
  };
};

const mapDispatchToProps = {
  onResetSelectedPallet: resetSelectedPallet,
  onFetchPallet: fetchPallet,
  onPalletUpdate: updatePallet,
  onGetDepths: getDepths,
  onResetDepths: resetStorageSlots,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewSearchPallet));
