import theme from 'lib/theme';
import { BETWEEN_L_AND_XL } from 'lib/constants';

export default {
  root: {
    fontFamily: 'Muli',
  },
  podWrapper: {
    position: 'relative',
    display: 'inline',
  },
  podDescription: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.88em',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9em',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1em',
    },
  },
  podSvg: {
    [theme.breakpoints.down('md')]: {
      width: '88px',
      height: '68.8px',
    },
    [theme.breakpoints.up('md')]: {
      width: '93.5px',
      height: '73.1px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '110px',
      height: '86px',
    },
    [BETWEEN_L_AND_XL]: {
      width: '90.2px',
      height: '63.468px',
    },
  },
  podTitle: {
    marginTop: '5px',
    marginBottom: '7px',
    fontSize: '.95em',
  },
  timeClockIcon: {
    position: 'absolute',
    bottom: '7.2px',
    [theme.breakpoints.down('md')]: {
      right: '2px',
    },
    [theme.breakpoints.up('md')]: {
      right: '1px',
    },
    [theme.breakpoints.up('xl')]: {
      right: '3px',
      bottom: '7px',
    },
    [BETWEEN_L_AND_XL]: {
      right: '3.6px',
      bottom: '6px',
    },
  },
  eyeIcon: {
    position: 'absolute',
    bottom: '52px',
    [theme.breakpoints.down('md')]: {
      right: '2.1px',
    },
    [theme.breakpoints.up('md')]: {
      right: '2px',
      bottom: '54px',
    },
    [theme.breakpoints.up('xl')]: {
      right: '5px',
      bottom: '67px',
    },
    [BETWEEN_L_AND_XL]: {
      right: '5px',
      bottom: '48px',
    },
  },
  warningIcon: {
    position: 'absolute',
    bottom: '30.4px',
    [theme.breakpoints.down('md')]: {
      right: '1px',
    },
    [theme.breakpoints.up('md')]: {
      right: '1px',
      bottom: '33px',
    },
    [theme.breakpoints.up('xl')]: {
      right: '3px',
      bottom: '38px',
    },
    [BETWEEN_L_AND_XL]: {
      right: '3.6px',
      bottom: '28px',
    },
  },
};
