import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

import ContentModal from 'components/shared/ContentModal';

import EditProductDate from '../EditProductDate';
import PalletNotPresent from '../PalletNotPresent';
import DeactivatePallet from '../DeactivatePallet';
import styles from './styles';

const EDIT_PRODUCT_DATE_VIEW = 'editProductDateView';
const PALLET_NOT_PRESENT_VIEW = 'palletNotPresentView';
const DEACTIVATE_VIEW = 'deactivateView';

const useStyles = makeStyles(styles);

const PalletEditModal = ({
  isOpen,
  closeModal,
  pallet,
  setProductDateType,
  productDateType,
  setProductDate,
  productDate,
  updatePalletProductDate,
  setPalletToUnknownLocation,
  deactivatePallet,
}) => {
  const [contentView, setContentView] = useState(EDIT_PRODUCT_DATE_VIEW);
  const classes = useStyles();

  const onSelection = (e) => {
    setContentView(e.currentTarget.id);
  };

  return (
    <ContentModal
      open={isOpen}
      onClose={closeModal}
      title={pallet.id}
      subTitle={pallet.storageSlot}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              id={EDIT_PRODUCT_DATE_VIEW}
              className={classnames(classes.button, {
                selected: contentView === EDIT_PRODUCT_DATE_VIEW,
              })}
              onClick={onSelection}
            >
              EDIT PRODUCT DATE DETAILS
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              id={PALLET_NOT_PRESENT_VIEW}
              className={classnames(classes.button, {
                selected: contentView === PALLET_NOT_PRESENT_VIEW,
              })}
              onClick={onSelection}
            >
              PALLET NOT PRESENT
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              id={DEACTIVATE_VIEW}
              className={classnames(classes.button, {
                selected: contentView === DEACTIVATE_VIEW,
              })}
              onClick={onSelection}
            >
              DEACTIVATE PALLET
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <div>
          {contentView === EDIT_PRODUCT_DATE_VIEW && (
            <EditProductDate
              pallet={pallet}
              setProductDateType={setProductDateType}
              productDateType={productDateType}
              setProductDate={setProductDate}
              productDate={productDate}
              updatePalletProductDate={updatePalletProductDate}
            />
          )}
          {contentView === PALLET_NOT_PRESENT_VIEW && (
            <PalletNotPresent
              setPalletToUnknownLocation={setPalletToUnknownLocation}
            />
          )}
          {contentView === DEACTIVATE_VIEW && (
            <DeactivatePallet deactivatePallet={deactivatePallet} />
          )}
        </div>
      </Box>
    </ContentModal>
  );
};

PalletEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  pallet: PropTypes.shape({
    id: PropTypes.number,
    storageArea: PropTypes.string,
  }).isRequired,
  setProductDateType: PropTypes.func.isRequired,
  productDateType: PropTypes.string.isRequired,
  setProductDate: PropTypes.func.isRequired,
  productDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  updatePalletProductDate: PropTypes.func.isRequired,
  setPalletToUnknownLocation: PropTypes.func,
  deactivatePallet: PropTypes.func,
};

PalletEditModal.defaultProps = {
  productDate: '',
  setPalletToUnknownLocation: () => {},
  deactivatePallet: () => {},
};

export default PalletEditModal;
