import React from 'react';
import PropTypes from 'prop-types';

import MenuButton from './MenuButton';

const MenuButtons = ({ menus, className, onMenuSelected }) => {
  return menus.results.map((menu) => (
    <li key={menu.id} className={className}>
      <MenuButton
        {...menu}
        selectedMenu={menus.selectedMenu}
        onMenuSelected={(event) => onMenuSelected(menu, event)}
      />
    </li>
  ));
};

MenuButtons.propTypes = {
  menus: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onMenuSelected: PropTypes.func.isRequired,
};

export default MenuButtons;
