import { alpha } from '@mui/material/styles';

import { useTimeIncrements } from 'lib/custom_hooks';
import {
  GREY_100,
  GREY_200,
  GREY_800,
  BLACK,
  WHITE,
  HC_GREEN,
  BLUE,
  ERROR_RED,
  ORANGE_YELLOW,
} from 'lib/constants';

export const MAX_GRID_LENGTH = 15;
export const MAX_GRID_WIDTH = 48;
export const HOUR_COL_SPAN = 2;
const timeIncrements = useTimeIncrements();

export const getColumnNumber = (hour) => {
  return timeIncrements.indexOf(hour) + 1;
};

export const getTimeMarkerPercentWidth = (currentTime) => {
  return parseInt((currentTime.getMinutes() / 60) * 100);
};

export const timeIncrementPosition = (col) => {
  return {
    gridColumn: `col ${col}`,
    gridRow: `row 1/${MAX_GRID_LENGTH}`,
  };
};

export const gridPosition = (
  gridColumn,
  gridRow,
  gridColspan = 1,
  width = null
) => {
  const gridStyle = {
    gridColumn: `col ${gridColumn}/ span ${gridColspan}`,
    gridRow: `row ${gridRow}`,
  };

  if (width) {
    gridStyle['width'] = width;
  }

  return gridStyle;
};

const appointment = {
  borderRadius: '5px',
  color: GREY_800,
  margin: '0 1px 1px 0',
  fontSize: '12px',
  zIndex: '50',
  padding: '2px 0 2px 2px',
};

export const apptBlockStatusStyle = (status, startTimeInZone, currentTime) => {
  const unassociatedLate = {
    ...appointment,
    border: `1px solid ${ERROR_RED}`,
    backgroundColor: alpha(ERROR_RED, 0.2),
  };

  const unassociatedFuture = {
    ...appointment,
    border: `1px solid ${GREY_800}`,
    backgroundColor: WHITE,
  };

  const completed = {
    ...appointment,
    border: `1px solid ${HC_GREEN}`,
    backgroundColor: alpha(HC_GREEN, 0.2),
  };

  const incomplete = {
    ...appointment,
    border: `1px solid ${ORANGE_YELLOW}`,
    backgroundColor: alpha(ORANGE_YELLOW, 0.2),
  };

  if (status === 'Unassociated') {
    if (startTimeInZone < currentTime) {
      return unassociatedLate;
    } else {
      return unassociatedFuture;
    }
  } else if (status === 'Completed') {
    return completed;
  } else {
    return incomplete;
  }
};

const timeCellStyles = {
  backgroundColor: WHITE,
  '&:nth-of-type(even)': {
    borderRight: `1px solid ${GREY_200}`,
  },
  '&:nth-of-type(odd)': {
    borderRight: `1px dashed ${GREY_200}`,
  },
  '&.shaded': {
    backgroundColor: GREY_100,
  },
};

export default {
  root: {
    display: 'grid',
    gridTemplateColumns: `repeat(${MAX_GRID_WIDTH}, [col] 80px )`,
    gridTemplateRows: `[row] 38px repeat(${MAX_GRID_LENGTH}, [row] 60px)`,
    color: '#444',
    width: 'auto',
    height: '565px',
    overflowX: 'scroll',
    overflowY: 'scroll',
    borderTop: `1px solid ${BLACK}`,
  },
  timeBox: {
    ...timeCellStyles,
  },
  times: {
    position: 'sticky',
    top: 0,
    fontWeight: 'bolder',
    color: GREY_800,
    fontSize: '12px',
    padding: '5px',
    zIndex: '200',
    borderBottom: `1px solid ${GREY_200}`,
    ...timeCellStyles,
  },
  rows: {
    borderBottom: `1px solid ${GREY_200}`,
    backgroundColor: 'none',
  },
  timeMarker: {
    borderRight: `2px solid ${BLUE}`,
    fontSize: '12px',
    zIndex: '150',
  },
  appointmentHeader: {
    margin: '2px',
    fontWeight: 'bolder',
    fontSize: '12px',
  },
  appointmentDetails: {
    margin: '2px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: '1rem',
  },
};
