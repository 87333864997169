import theme from 'lib/theme';
import { WHITE, GREY_150 } from 'lib/constants';

const dataCellRoot = {
  padding: '0 4px',
  fontSize: '13px',
  height: '100%',
  backgroundColor: WHITE,
};

const gridItemRoot = {
  lineHeight: '36px',
};

export default {
  gridItemAlignLeft: {
    ...gridItemRoot,
    textAlign: 'left',
  },
  gridItemAlignCenter: {
    ...gridItemRoot,
    textAlign: 'center',
  },
  gridItemAlignRight: {
    ...gridItemRoot,
    textAlign: 'right',
  },
  dataCell: {
    ...dataCellRoot,
  },
  dataCellLargerFont: {
    ...dataCellRoot,
    fontSize: '16px',
    textDecoration: 'inherit',
    color: 'black',
  },
  dataCellMarginRight: {
    ...dataCellRoot,
    marginRight: '-2px',
  },
  dataCellMarginLeft: {
    ...dataCellRoot,
    marginLeft: '2px',
  },
  onTarget: {
    color: '#8c8c8c',
  },
  belowTarget: {
    color: '#e2544d',
  },
  aheadOfTarget: {
    color: '#669550',
  },
  typography: {
    margin: theme.spacing(2),
    width: '200px',
    display: 'block',
  },
  popoverIngredientValue: {
    color: '#595959',
  },
  ingredientName: {
    border: '0 none',
    cursor: 'pointer',
    backgroundColor: 'inherit',
  },
  gridContainer: {
    '&:nth-child(even) .zebra div': {
      backgroundColor: GREY_150,
    },
  },
};
