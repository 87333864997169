import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import FilterIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';

import { DROP_LIST_FILTER_OPTIONS } from 'lib/constants';

export const FilterSelect = ({
  'data-testid': dataTestId,
  classes,
  label,
  value,
  onChange,
  clearOptionText,
  options,
}) => {
  return (
    <FormControl variant="standard" className={classes.form}>
      <InputLabel>{label}</InputLabel>
      <Select
        variant="standard"
        data-testid={dataTestId}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value="">
          <em>{clearOptionText}</em>
        </MenuItem>
        {options.map((item) => (
          <MenuItem
            className={classes.menuItem}
            key={item.id}
            value={item.id}
            classes={{ selected: classes.selected }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterSelect.propTypes = {
  'data-testid': PropTypes.string,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  clearOptionText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

FilterSelect.defaultProps = {
  value: '',
  clearOptionText: 'None',
  'data-testid': 'filter-select',
};

const MoveQueueFilter = ({
  classes,
  storageAreas,
  storageAreaFilter,
  dropListFilter,
  onFetchStorageAreas,
  updateMoveRequestFilters,
}) => {
  useEffect(() => {
    onFetchStorageAreas();
  }, [onFetchStorageAreas]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStorageArea, setSelectedStorageArea] = useState(
    storageAreaFilter
  );
  const [selectedDropList, setSelectedDropList] = useState(dropListFilter);

  const onClearFiltersClick = () => {
    updateMoveRequestFilters({
      pickUpArea: '',
      dropList: '',
    });
    setSelectedStorageArea('');
    setSelectedDropList('');
  };

  const onSubmit = () => {
    updateMoveRequestFilters({
      pickUpArea: selectedStorageArea,
      dropList: selectedDropList,
    });
    setDialogOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        data-testid="open-filters-icon"
        className={classes.iconButton}
        color="inherit"
        onClick={() => setDialogOpen(true)}
        size="large"
      >
        <Badge
          variant="dot"
          classes={{ badge: classes.badge }}
          invisible={!storageAreaFilter && !dropListFilter}
        >
          <FilterIcon className={classes.icon} />
        </Badge>
      </IconButton>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <div data-testid="move-queue-filters" className={classes.dialogContent}>
          <div className={classes.header}>
            <span>Filters</span>
            <IconButton
              data-testid="close-filters-icon"
              className={classes.closeIcon}
              color="inherit"
              onClick={() => setDialogOpen(false)}
              size="large"
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </div>
          <FilterSelect
            data-testid="area-select"
            classes={classes}
            label="Pick Up Area"
            value={selectedStorageArea}
            onChange={setSelectedStorageArea}
            clearOptionText="All Areas"
            options={storageAreas}
          />
          <FilterSelect
            data-testid="drop-list-select"
            classes={classes}
            label="Drop List"
            value={selectedDropList}
            onChange={setSelectedDropList}
            clearOptionText="All Requests"
            options={DROP_LIST_FILTER_OPTIONS}
          />
          <div className={classes.buttonContainer}>
            <Button
              data-testid="clear-filters-button"
              className={classes.button}
              variant="contained"
              onClick={onClearFiltersClick}
            >
              Clear Filters
            </Button>
            <Button
              data-testid="set-filters-button"
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={
                storageAreaFilter === selectedStorageArea &&
                dropListFilter === selectedDropList
              }
            >
              Set Filters
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

MoveQueueFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  storageAreas: PropTypes.array.isRequired,
  storageAreaFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dropListFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onFetchStorageAreas: PropTypes.func.isRequired,
  updateMoveRequestFilters: PropTypes.func.isRequired,
};

MoveQueueFilter.defaultProps = {
  storageAreaFilter: '',
  dropListFilter: '',
};

export default MoveQueueFilter;
