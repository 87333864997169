import { BLACK, GREY_650 } from 'lib/constants';

export default {
  tableHeaderCell: {
    color: BLACK,
    fontSize: '16px',
  },
  tableCellDesc: {
    color: BLACK,
  },
  tableCellData: {
    color: GREY_650,
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
