import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  updateSelectedShipDateAndFetch,
  requestBoxingInventory,
} from 'redux/boxingInventory/actions';
import { isCurrentMenu } from 'lib/utils';

import BoxingInventory from './BoxingInventory';

const BoxingInventoryContainer = ({
  menus,
  session,
  boxingInventory,
  onRequestBoxingInventory,
  onUpdateSelectedShipDateAndFetch,
}) => {
  useEffect(() => {
    if (isCurrentMenu(menus)) {
      const facilityId =
        session.selectedPackingFacilityId || session.packing_facility_id;
      const menuId = menus.selectedMenu.id || menus.results[0].id;
      const shipDate = session.selectedShipDate;
      onRequestBoxingInventory({
        menuId,
        facilityId,
        shipDate,
      });
    }
  }, [
    onRequestBoxingInventory,
    menus,
    session.selectedPackingFacilityId,
    session.packing_facility_id,
    session.selectedShipDate,
  ]);

  return (
    <BoxingInventory
      menus={menus}
      session={session}
      boxingInventory={boxingInventory}
      onUpdateSelectedShipDateAndFetch={onUpdateSelectedShipDateAndFetch}
    />
  );
};

BoxingInventoryContainer.propTypes = {
  session: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
  boxingInventory: PropTypes.object.isRequired,
  onRequestBoxingInventory: PropTypes.func.isRequired,
  onUpdateSelectedShipDateAndFetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  boxingInventory: state.boxingInventory,
  menus: state.menus,
});

const mapDispatchToProps = {
  onRequestBoxingInventory: requestBoxingInventory,
  onUpdateSelectedShipDateAndFetch: updateSelectedShipDateAndFetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoxingInventoryContainer);
