import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { pluralize } from 'lib/utils';
import { StatefulButton } from 'components/shared';
import { manageProductionZoneItem } from 'redux/itemManager/actions';
import {
  ITEM_MANAGER_AVAILABLE_MOVE_TYPES,
  ITEM_MANAGER_WASTE_MOVE,
  ITEM_MANAGER_NOT_LESS_THAN_ZERO,
  ITEM_MANAGER_GREATER_THAN_ZERO,
  ITEM_MANAGER_QUANTITY_REQUIRED,
  ITEM_MANAGER_NEW_LOCATION_REQUIRED,
  ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY,
  ITEM_MANAGER_VALIDATION_OPTIONS,
} from 'lib/constants';

import { ItemManagerFormContext } from '../../ItemManagerFormProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const SubmitButtonWrapper = ({
  onSuccess,
  buttonText,
  moveType,
  validationSelections,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const { state } = useContext(ItemManagerFormContext);
  const { locationId } = useParams();
  const { updateLoading, updateError } = useSelector(
    (reduxState) => reduxState.itemManager
  );

  const activeMoveType = state.overrideMoveType
    ? state.overrideMoveType
    : moveType;

  useEffect(() => {
    if (
      validationSelections.includes(ITEM_MANAGER_GREATER_THAN_ZERO) &&
      state.quantity === 0
    ) {
      setButtonDisabled(true);
      setErrorMessage('Quantity must be greater than zero');
    } else if (
      validationSelections.includes(ITEM_MANAGER_QUANTITY_REQUIRED) &&
      (state.quantity === undefined || state.quantity.length === 0)
    ) {
      setButtonDisabled(true);
      setErrorMessage('Quantity is required');
    } else if (
      validationSelections.includes(ITEM_MANAGER_NOT_LESS_THAN_ZERO) &&
      state.quantity < 0
    ) {
      setButtonDisabled(true);
      setErrorMessage('Quantity cannot be less than zero');
    } else if (
      validationSelections.includes(ITEM_MANAGER_NEW_LOCATION_REQUIRED) &&
      !state.newLocation &&
      activeMoveType != ITEM_MANAGER_WASTE_MOVE
    ) {
      setButtonDisabled(true);
      setErrorMessage('New location is required');
    } else if (
      validationSelections.includes(
        ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY
      ) &&
      state.productionZoneItem?.quantity < state.quantity
    ) {
      setButtonDisabled(true);
      setErrorMessage(
        `Quantity must be less than or equal to available quantity (${state.productionZoneItem?.quantity})`
      );
    } else {
      setButtonDisabled(false);
      setErrorMessage('');
    }
  }, [state, activeMoveType]);

  const text = {
    DEFAULT: state.quantity
      ? `${buttonText} ${pluralize(state.quantity, 'Case')}`
      : buttonText,
    SUCCESS: 'Success!',
    LOADING: 'Saving...',
    ERROR: 'Error - Try Again',
  };

  const handleSubmit = async () => {
    const response = await dispatch(
      manageProductionZoneItem({
        updateParams: {
          id: state.productionZoneItem.id,
          quantity: state.quantity,
          moveType: activeMoveType,
          destinationZoneId: state.newLocation,
          locationId: locationId,
        },
      })
    );

    if (response.ok) {
      onSuccess();
    }
  };

  return (
    <div className={classes.formFooter}>
      <Divider />
      <div className={classes.parentContainer}>
        <div className={classes.childContainer}>
          <div className={classes.errorMessage}>{errorMessage}</div>
        </div>
        <div className={classes.childContainer}>
          <StatefulButton
            classes={{ root: classes.button }}
            type="submit"
            disabled={buttonDisabled}
            loading={updateLoading}
            failed={updateError}
            buttonTextOptions={text}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

SubmitButtonWrapper.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  moveType: PropTypes.oneOf(ITEM_MANAGER_AVAILABLE_MOVE_TYPES).isRequired,
  validationSelections: PropTypes.arrayOf(
    PropTypes.oneOf(ITEM_MANAGER_VALIDATION_OPTIONS)
  ).isRequired,
};

SubmitButtonWrapper.defaultProps = {
  buttonText: 'Submit',
};

export default SubmitButtonWrapper;
