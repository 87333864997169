import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import { Snackbar } from 'components/shared';
import { SNACK_BAR_DURATION } from 'lib/constants';
import MealList from 'components/MealList';

import MealBagInventoryForm from './MealBagInventoryForm';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const MealBagging = ({
  podLocations,
  printers,
  mealBagInventory,
  onPostMealBagInventory,
  onGetIngredientAssignments,
  selectedMenu,
  posting,
  errors,
  location,
}) => {
  const { printing, printingError } = mealBagInventory;

  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={[
            '/production_inventory/meal_bagging/menu/:menuId',
            '/production_inventory/meal_bagging/menu',
          ]}
          render={(routingProps) => (
            <MealList {...routingProps} showNumberLeft />
          )}
        />
        <Route
          exact
          path="/production_inventory/meal_bagging/menu/:menuId/menu_selection/:menuSelectionId"
          render={() => (
            <MealBagInventoryForm
              menuSelection={location.state.menuSelection}
              podLocations={podLocations}
              printers={printers}
              onPostMealBagInventory={onPostMealBagInventory}
              mealBagInventory={mealBagInventory}
              selectedMenu={selectedMenu}
              posting={posting}
              errors={errors}
              onGetIngredientAssignments={onGetIngredientAssignments}
            />
          )}
        />
      </Switch>
      <Snackbar
        loading={posting}
        failed={Boolean(errors)}
        messaging={{
          loading: 'Saving Meal Bag Inventory...',
          failure:
            (errors && errors[0]) || 'Error Submitting Meal Bag Inventory',
          success: 'Meal Bag Inventory Success!',
        }}
        autoHideDuration={SNACK_BAR_DURATION}
      />
      <Snackbar
        loading={printing}
        failed={Boolean(printingError)}
        messaging={{
          loading: 'Printing...',
          failure: 'Error Printing',
          success: 'Print Success!',
        }}
        autoHideDuration={SNACK_BAR_DURATION}
      />
    </Fragment>
  );
};

MealBagging.propTypes = {};

export default MealBagging;
