import React from 'react';

const indexForData = {
  lotId: 0,
  uom: 4,
  productDateType: 6,
};

export const columns = () => {
  return [
    {
      name: 'id',
      label: 'Lot #',
      options: {
        sort: false,
      },
    },
    {
      name: 'itemName',
      label: 'Item',
      options: {
        style: { width: '33%' },
      },
    },
    { name: 'brand', label: 'Brand', options: { sort: false } },
    {
      name: 'quantity',
      label: 'Qty',
      options: {
        customBodyRender: (value, tableMeta) => {
          return `${value} @ ${tableMeta.rowData[indexForData.uom]}`;
        },
        sort: false,
      },
    },
    {
      name: 'uom',
      options: {
        display: false,
      },
    },
    {
      name: 'productDate',
      label: 'PDate',
      options: {
        customBodyRender: (value, tableMeta) => {
          let rowData = tableMeta.rowData[indexForData.productDateType];
          return (
            <>
              <div>{rowData}</div>
              <div>{value}</div>
            </>
          );
        },
        sort: false,
      },
    },
    { name: 'productDateType', options: { display: false } },
    { name: 'duration', label: 'Duration' },
  ];
};

export const options = (history, tableState) => {
  return {
    selectableRows: 'none',
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 50, 100],
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    responsive: 'standard',
    ...tableState.get(),
    onTableChange: (action, updatedTableState) => {
      tableState.set(updatedTableState);
    },
    onRowClick: (rowData) => {
      history.push({
        pathname: `/inventory-manager/pallets/${rowData[indexForData.lotId]}`,
      });
    },
  };
};
