import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { pluralize } from 'lib/utils';
import { Loading, ReprintPalletDialog } from 'components/shared';
import { palletTableStyles } from 'lib/dataTableTheme';

import { columns, options } from './dataTableConfig';
import styles from './styles';

const getColumnsWithButtons = (
  printPallet,
  handleDialogClick,
  nonDeletableIds,
  handleReprintClick,
  setSelectedPalletForReprint,
  classes
) => [
  ...columns,
  {
    label: 'Reprint',
    name: 'id',
    options: {
      customBodyRender: (id) => {
        const result = (
          <IconButton
            key={id}
            aria-label="Reprint"
            color="inherit"
            onClick={() => {
              handleReprintClick(), setSelectedPalletForReprint(id);
            }}
            size="large"
          >
            <PrintIcon />
          </IconButton>
        );
        return result;
      },
    },
  },
  {
    label: 'Delete',
    name: 'id',
    options: {
      customBodyRender: (id) => {
        const isDisabled = nonDeletableIds.includes(id);
        const result = (
          <IconButton
            key={id}
            aria-label="Delete"
            color="inherit"
            className={isDisabled ? classes.isDisabled : ''}
            onClick={() => handleDialogClick(id)}
            disabled={isDisabled}
            size="large"
          >
            <DeleteForeverIcon color="error" />
          </IconButton>
        );

        return result;
      },
    },
  },
];

const PalletActivityLog = ({
  pallets,
  netsuiteOrderItemId,
  printPallet,
  deletePallet,
  loading,
  classes,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showPrinterOptions, setShowPrinterOptions] = useState(false);
  const [selectedPalletForReprint, setSelectedPalletForReprint] = useState();
  const [palletForIdDeletion, setPalletForDeletion] = useState();

  const nonDeletableIds = useMemo(
    () =>
      pallets
        .filter((pallet) => pallet.deletable === false)
        .map((pallet) => pallet.id),
    [pallets]
  );

  options.customToolbar = () => {
    return pluralize(pallets.length, 'Pallet');
  };

  const handleDialogClick = (palletId) => {
    setDialogOpen(true);
    setPalletForDeletion(palletId);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setPalletForDeletion(null);
  };

  const requestPalletDeletion = () => {
    handleDialogClose();
    deletePallet(palletForIdDeletion, netsuiteOrderItemId);
  };

  const handleReprintClick = () => {
    setShowPrinterOptions(true);
  };

  const columnsWithButtons = useMemo(
    () =>
      getColumnsWithButtons(
        printPallet,
        handleDialogClick,
        nonDeletableIds,
        handleReprintClick,
        setSelectedPalletForReprint,
        classes
      ),
    [printPallet, nonDeletableIds, classes]
  );

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={palletTableStyles}>
        <Dialog
          data-testid="delete-dialog"
          open={dialogOpen}
          onClose={handleDialogClose}
        >
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete lot {palletForIdDeletion}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={requestPalletDeletion} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <ReprintPalletDialog
          showPrinterOptions={showPrinterOptions}
          selectedPalletId={selectedPalletForReprint}
          setShowPrinterOptions={setShowPrinterOptions}
        />
        <MUIDataTable
          title="Pallet Activity Log"
          data={pallets}
          columns={columnsWithButtons}
          options={options}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

PalletActivityLog.propTypes = {
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      measuringUnit: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  netsuiteOrderItemId: PropTypes.number.isRequired,
  deletePallet: PropTypes.func.isRequired,
  printPallet: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PalletActivityLog);
