import intersection from 'lodash/intersection';
import difference from 'lodash/difference';

const requiredFields = [
  'Date',
  'Packing Facility',
  'Shift',
  'Menu ID',
  'Meal ID',
  'Activity',
  'Amount to Complete',
  'Target Rate',
];

export const headerRows = [
  ...requiredFields,
  'Ingredient',
  'Hours',
  'People',
  'Pod Location',
];

export const headerError = (data) => {
  const diff = difference(headerRows, data[0].meta.fields);

  if (diff.length > 0) {
    return {
      message: `CSV is missing ${diff
        .map((field) => field)
        .join(', ')} column(s)`,
    };
  }
};

export const requiredFieldErrors = (data) => {
  let fieldErrors = [];

  data.forEach((row, index) => {
    requiredFields.forEach((field) => {
      const hasValue = row.data[field].length > 0;
      if (!hasValue) {
        const error = {
          message: `Row ${index + 2} does not have a value for ${field}`,
        };

        const currentField = fieldErrors.find((err) => err.column === field);
        if (!currentField) {
          fieldErrors.push(error);
        }
      }
    });
  });
  return fieldErrors;
};

export const nonProductionMealError = (data, menuSelections) => {
  let mealError;
  const mealIds = menuSelections.map((ms) => ms.mealId);

  data.forEach((row) => {
    const mealId = parseInt(row.data['Meal ID']);

    if (!mealIds.includes(mealId)) {
      mealError = {
        message: `Meal ID: ${mealId} is not on the 3 upcoming menus`,
      };
      return;
    }
  });
  return mealError;
};

export const ingredientForMealError = (data) => {
  let mealError;
  const mealBaggingActivities = data.filter(
    (row) => row.data['Activity'] === 'Meal Bagging'
  );

  mealBaggingActivities.forEach((row, index) => {
    const mealId = parseInt(row.data['Meal ID']);

    if (row.data['Ingredient']) {
      mealError = {
        message: `Row ${
          index + 2
        } - Meal Bagging for Meal ID: ${mealId} should not have Ingredient`,
      };
      return;
    }
  });
  return mealError;
};

const ingredientIsForMeal = (
  ingredientAssignmentIds,
  menuSelectionIngredientIds
) => {
  return (
    intersection(ingredientAssignmentIds, menuSelectionIngredientIds).length > 0
  );
};

export const nonProductionIngredientError = (
  data,
  menuSelections,
  ingredients
) => {
  let ingredientError;
  const productionIngActivities = data.filter(
    (row) => row.data['Activity'] !== 'Meal Bagging'
  );

  productionIngActivities.forEach((row, index) => {
    const mealId = parseInt(row.data['Meal ID']);
    const ingredientId = row.data['Ingredient'];
    const menuSelection = menuSelections.find(
      (ms) => ms.mealId === parseInt(mealId)
    );
    const ingredientForRow = ingredients.find(
      (i) => i.ingredientId === parseInt(ingredientId)
    );

    if (
      ingredientForRow === undefined ||
      !ingredientIsForMeal(
        ingredientForRow.ingredientAssignmentIds,
        menuSelection.ingredientAssignmentIds
      )
    ) {
      ingredientError = {
        message: `Row ${
          index + 2
        } - Ingredient ID: ${ingredientId} is not portionable/labelable for Meal: ${mealId}`,
      };
      return;
    }
  });

  return ingredientError;
};
