import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';
import { fetchQAPallets, resetScannedPalletId } from 'redux/pallets/actions';
import styles from 'components/WarehouseReceivingApps/WarehouseQA/styles';
import { TIME_RANGE_SECONDS } from 'lib/constants/warehouseConstants';

import WarehouseQA from './WarehouseQA';

const WarehouseQAContainer = ({
  groupedIngredientsPallets,
  groupedPackagingPallets,
  onFetchPallets,
  classes,
  fetching,
  onResetScannedPallet,
  scannedPalletId,
}) => {
  useEffect(() => {
    onFetchPallets(TIME_RANGE_SECONDS);
  }, [onFetchPallets]);
  if (fetching) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <WarehouseQA
      groupedIngredientsPallets={groupedIngredientsPallets}
      groupedPackagingPallets={groupedPackagingPallets}
      onResetScannedPallet={onResetScannedPallet}
      scannedPalletId={scannedPalletId}
    />
  );
};

WarehouseQAContainer.propTypes = {
  onFetchPallets: PropTypes.func.isRequired,
  onResetScannedPallet: PropTypes.func.isRequired,
  groupedIngredientsPallets: PropTypes.array.isRequired,
  groupedPackagingPallets: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  scannedPalletId: PropTypes.number,
};

WarehouseQAContainer.defaultProps = {
  scannedPalletId: null,
};

const mapStateToProps = (state) => ({
  groupedIngredientsPallets: state.pallets.qaApp.groupedIngredientsPallets,
  groupedPackagingPallets: state.pallets.qaApp.groupedPackagingPallets,
  fetching: state.pallets.qaApp.fetching,
  scannedPalletId: state.pallets.qaApp.scannedPalletId,
});

const mapDispatchToProps = {
  onFetchPallets: fetchQAPallets,
  onResetScannedPallet: resetScannedPalletId,
};

export { WarehouseQAContainer as WarehouseQAContainerTest };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WarehouseQAContainer));
