import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import withStyles from '@mui/styles/withStyles';

import { Loading, UploaderUserInterface } from 'components/shared';

import styles from './styles';
import {
  headerError,
  requiredFieldErrors,
  nonProductionMealError,
  nonProductionIngredientError,
  ingredientForMealError,
} from './csvValidationHelper';
import { reducer, initialState, ADD_ERROR, RESET_ERRORS } from './formUtils';

const UploaderInterface = ({
  menuSelections,
  ingredients,
  posting,
  postingErrors,
  onGetMenuSelections,
  onGetIngredients,
  onPostProductionPlans,
  classes,
}) => {
  const [validationState, dispatch] = useReducer(reducer, initialState);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    onGetMenuSelections();
  }, [onGetMenuSelections]);

  useEffect(() => {
    onGetIngredients();
  }, [onGetIngredients]);

  const addError = (message) => {
    dispatch({ type: ADD_ERROR, message: message });
  };

  const validateAndSubmitData = (fileData) => {
    dispatch({ type: RESET_ERRORS });
    setIsValidating(true);

    const headerMissingError = headerError(fileData);
    if (headerMissingError) {
      return addError(headerMissingError.message);
    }
    const fieldErrors = requiredFieldErrors(fileData);
    if (!isEmpty(fieldErrors)) {
      return addError(fieldErrors.map((err) => err.message));
    }
    const ingredientMealError = ingredientForMealError(fileData);
    if (ingredientMealError) {
      return addError(ingredientMealError.message);
    }
    const mealError = nonProductionMealError(fileData, menuSelections);
    if (mealError) {
      return addError(mealError.message);
    }
    const ingredientError = nonProductionIngredientError(
      fileData,
      menuSelections,
      ingredients
    );
    if (ingredientError) {
      return addError(ingredientError.message);
    }

    onPostProductionPlans(fileData);
  };

  const onDrop = (fileData) => {
    validateAndSubmitData(fileData);
    setIsValidating(false);
  };

  const postingErrorList = () => {
    return postingErrors.length > 0 ? (
      <ul>
        {postingErrors.map((error) => {
          return Object.keys(error).map((key) => {
            return <li key={key}>{`${key}: ${error[key]}`}</li>;
          });
        })}
      </ul>
    ) : null;
  };
  if (isEmpty(menuSelections) || isEmpty(ingredients)) {
    return <Loading className={classes.centeredLoading} />;
  } else {
    return (
      <UploaderUserInterface
        isValidating={isValidating}
        onDrop={onDrop}
        posting={posting}
        postingErrors={postingErrors}
        postingErrorList={postingErrorList}
        validationState={validationState}
        uploadName="Production Sheet"
      />
    );
  }
};

UploaderInterface.propTypes = {
  menuSelections: PropTypes.array.isRequired,
  ingredients: PropTypes.array.isRequired,
  onGetMenuSelections: PropTypes.func.isRequired,
  onGetIngredients: PropTypes.func.isRequired,
  onPostProductionPlans: PropTypes.func.isRequired,
};
export default withStyles(styles)(UploaderInterface);
