import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BLACK, GREY_400 } from 'lib/constants';

const CloseIcon = ({ className }) => {
  const [hovered, onHover] = useState(false);

  return (
    <div
      className={className}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128.57 128.57"
        width="100%"
        height="100%"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="X">
            <path
              data-testid="close-icon-path"
              fill={hovered ? GREY_400 : BLACK}
              d="M74.18,64.28,126.52,12a7,7,0,0,0-9.9-9.9L64.28,54.38,12,2.05A7,7,0,0,0,2.05,12L54.38,64.28,2.05,116.62a7,7,0,0,0,9.9,9.9L64.28,74.18l52.34,52.34a7,7,0,0,0,9.9-9.9Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

CloseIcon.propTypes = {
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: 'CloseIcon',
};

export default CloseIcon;
