import { createTheme } from '@mui/material/styles';

import theme from 'lib/theme';
import buttonGroupTheme from 'lib/buttonGroupTheme';

const formTheme = createTheme({
  ...theme,
  components: {
    ...buttonGroupTheme.components,
    MuiPaper: {
      styleOverrides: {
        root: {
          margin: '10px 0',
          padding: '10px',
        },
      },
    },
  },
});
export default formTheme;
