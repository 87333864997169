import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setTopBarContent } from 'redux/topBar/actions';

import styles from './styles';
import BoxRay from './BoxRay';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(BoxRay)));
