import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { fetchStorageAreas } from 'redux/storageArea/actions';
import { updateMoveRequestFilters } from 'redux/itemMoveRequests/actions';

import styles from './styles';
import MoveQueueFilter from './MoveQueueFilter';

const mapStateToProps = (state) => ({
  storageAreas: state.storageAreas.results,
  storageAreaFilter: state.itemMoveRequests.filters.pickUpArea,
  dropListFilter: state.itemMoveRequests.filters.dropList,
});

const mapDispatchToProps = {
  onFetchStorageAreas: fetchStorageAreas,
  updateMoveRequestFilters: updateMoveRequestFilters,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MoveQueueFilter));
