import { makeApiRequest } from 'lib/api';
import {
  INVENTORY_PALLET_RESET_SELECTED,
  INVENTORY_PALLET_GET,
  INVENTORY_PALLET_PUT,
  INVENTORY_PALLET_DEACTIVATE,
  INVENTORY_PALLET_HOLD,
  INVENTORY_PALLET_QUERY,
  INVENTORY_PALLETS_TOGGLE_SEARCH,
  INVENTORY_PALLETS_CLEAR_SEARCH,
  INVENTORY_PALLETS_STORE_SEARCH,
  INVENTORY_PALLET_REACTIVATE,
  ACTIVE,
  ON_HOLD,
  DEACTIVATED,
  FOUND_LOST_PALLET,
  MISSING_SLOT_PALLETS,
  RESET_MISSING_SLOT_PALLETS,
} from 'lib/constants';

import Pallets from '../services';

export const fetchPallet = (palletId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_GET,
        method: Pallets.inventoryApp.getPallet,
        params: { palletId },
      })
    );
  };
};

export const updatePallet = (palletId, changedPalletAttributes) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_PUT,
        method: Pallets.inventoryApp.putPallet,
        params: { palletId, changedPalletAttributes },
      })
    );
  };
};

export const resetSelectedPallet = () => ({
  type: INVENTORY_PALLET_RESET_SELECTED,
});

export const deactivatePallet = (palletId, deletionType, deletionReason) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_DEACTIVATE,
        method: Pallets.inventoryApp.putPallet,
        params: {
          palletId,
          changedPalletAttributes: {
            status: DEACTIVATED,
            status_type: deletionType,
            status_reason: deletionReason,
          },
        },
      })
    );
  };
};

export const holdPallet = (palletId, holdType, holdReason) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_HOLD,
        method: Pallets.inventoryApp.putPallet,
        params: {
          palletId,
          changedPalletAttributes: {
            status: ON_HOLD,
            status_type: holdType,
            status_reason: holdReason,
          },
        },
      })
    );
  };
};

export const makeUnknownLocPalletToActive = (palletId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_REACTIVATE,
        method: Pallets.inventoryApp.putPallet,
        params: {
          palletId,
          changedPalletAttributes: {
            status: ACTIVE,
            status_type: FOUND_LOST_PALLET,
            change_reason_type: FOUND_LOST_PALLET,
          },
        },
      })
    );
  };
};

export const reactivatePallet = (palletId, changeReasonType) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_REACTIVATE,
        method: Pallets.inventoryApp.putPallet,
        params: {
          palletId,
          changedPalletAttributes: {
            status: ACTIVE,
            change_reason_type: changeReasonType,
          },
        },
      })
    );
  };
};

export const fetchPalletsFromQuery = () => async (dispatch, getState) => {
  const { pallets, session } = getState();
  return await dispatch(
    makeApiRequest({
      type: INVENTORY_PALLET_QUERY,
      method: Pallets.inventoryApp.getFromQuery,
      params: {
        packingFacilityId: session.packing_facility_id,
        searchQuery: pallets.inventoryApp.queryTerm,
      },
    })
  );
};

export const fetchMissingSlotPallets = (storageAreaId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MISSING_SLOT_PALLETS,
        method: Pallets.inventoryApp.getMissingSlotPallets,
        params: { storageAreaId },
      })
    );
  };
};

export const togglePalletSearchDrawer = () => ({
  type: INVENTORY_PALLETS_TOGGLE_SEARCH,
});
export const clearResultsFromQuery = () => ({
  type: INVENTORY_PALLETS_CLEAR_SEARCH,
});
export const storeSearchQuery = (searchQuery) => {
  return {
    type: INVENTORY_PALLETS_STORE_SEARCH,
    payload: { searchQuery },
  };
};
export const resetMissingSlotPallets = () => ({
  type: RESET_MISSING_SLOT_PALLETS,
});
