import { GREY_200, WHITE, DARK_RED, GREY_100 } from 'lib/constants';

import { spanOne, spanTwo, grid } from '../styles';

export default {
  palletSpanTwo: {
    ...spanTwo,
    alignItems: 'flex-start',
  },
  palletSpanOne: {
    ...spanOne,
    alignItems: 'flex-start',
  },
  palletSummary: {
    ...grid,
    padding: '15px 1.5%',
    borderTop: `1px solid ${GREY_200}`,
    '&:last-child': {
      borderRadius: '0px 0px 10px 10px',
    },
  },
  white: {
    background: WHITE,
  },
  grey: {
    background: GREY_100,
  },
  differentUOM: {
    color: DARK_RED,
  },
};
