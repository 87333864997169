import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';

import { PHONE_SCREEN } from 'lib/constants';
import { Loading } from 'components/shared';

import PalletHistoryItem from '../PalletHistoryItem';
import styles from './styles';

const ActivityLog = ({
  data,
  open,
  onClose,
  classes,
  loading,
  fetchFailed,
  onExited,
}) => {
  const smallScreen = useMediaQuery(PHONE_SCREEN);

  const renderContent = () => {
    if (loading) {
      return (
        <div className={classes.centeredContent}>
          <Loading />
        </div>
      );
    } else if (fetchFailed) {
      return (
        <div className={classes.centeredContent}>
          Unable to retrieve pallet histories... Try again
        </div>
      );
    } else if (data.length === 0) {
      return (
        <div className={classes.centeredContent}>
          This pallet has no activity
        </div>
      );
    } else {
      return data.map((row, index) => (
        <PalletHistoryItem
          key={row.id}
          grey={index % 2 === 0}
          palletHistory={row}
        />
      ));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={smallScreen}
      TransitionProps={{
        onExited,
      }}
    >
      <List>
        <div className={classes.justifyHeader}>
          <header className={classes.header}>Activity Log </header>
          <IconButton
            onClick={onClose}
            classes={{ root: classes.iconButton }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          className={classNames({
            [classes.contentContainer]: true,
            [classes.largeScreen]: !smallScreen,
          })}
        >
          {renderContent()}
        </div>
      </List>
    </Dialog>
  );
};

ActivityLog.propTypes = {
  data: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  onExited: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActivityLog);
