import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import {
  Router,
  Route as ReactRouterDOMRoute,
  Switch,
  Redirect,
} from 'react-router-dom';

import ItemManager from 'components/ItemManager';
import RthProductionSubmissions from 'components/RthProductionSubmissions';
import CycleCounting from 'components/CycleCounting';
import WarehouseMoves from 'components/WarehouseMoves';
import BoxRay from 'components/BoxRay';
import DockScheduler from 'components/DockScheduler';
import WarehouseRequests from 'components/WarehouseRequests';
import ProductionSubmissions from 'components/ProductionSubmissions';
import ProgressOverview from 'components/ProgressTracker/ProgressOverview';
import ProgressTracker from 'components/ProgressTracker';
import RthProgressTracker from 'components/RthProgressTracker';
import RealtimeDashboard from 'components/RealtimeDashboard';
import TopBar from 'components/TopBar';
import WarehouseReceivingApps from 'components/WarehouseReceivingApps';
import WarehouseInventory from 'components/WarehouseInventory';
import QaPortioning from 'components/QaPortioning';
import QaMealBagging from 'components/QaMealBagging';
import AppsLandingPage from 'components/AppsLandingPage';
import PodDetails from 'components/RealtimeDashboard/PodDetails';
import ProductionInventory from 'components/ProductionInventory';
import ProductionSheets from 'components/ProductionSheets';
import { RTH_PROGRESS_TRACKER_HOME } from 'lib/constants';

import Viewport from './Viewport';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);
const history = createBrowserHistory();
Sentry.addIntegration(
  Sentry.reactRouterV5BrowserTracingIntegration({ history })
);

const App = ({ classes }) => (
  <Router history={history}>
    <div className={classes.root} data-testid="root-app-page">
      <TopBar />
      <Viewport>
        <Switch>
          <Route exact path="/" component={AppsLandingPage} />
          <Route
            path="/progress_tracker/overview"
            component={ProgressOverview}
          />
          <Route path="/progress_tracker" component={ProgressTracker} />
          <Route
            path={RTH_PROGRESS_TRACKER_HOME}
            component={RthProgressTracker}
          />
          <Route
            exact
            path="/realtime_dashboard"
            component={RealtimeDashboard}
          />
          <Route
            exact
            path="/realtime_dashboard/pod/:slug"
            component={PodDetails}
          />
          <Route
            exact
            path="/production_submissions"
            component={ProductionSubmissions}
          />
          <Route path="/receiving" component={WarehouseReceivingApps} />
          <Route path="/production_inventory" component={ProductionInventory} />
          <Route path="/production_sheets" component={ProductionSheets} />
          <Route path="/item-manager" component={ItemManager} />
          <Route
            path="/rth-production-submissions"
            component={RthProductionSubmissions}
          />
          <Route path="/warehouse_requests" component={WarehouseRequests} />
          <Route path="/inventory-manager" component={WarehouseInventory} />
          <Route path="/qa/portioning" component={QaPortioning} />
          <Route path="/qa/meal_bagging" component={QaMealBagging} />
          <Route path="/box-ray" component={BoxRay} />
          <Route path="/dock_scheduler" component={DockScheduler} />
          <Route path="/warehouse_moves" component={WarehouseMoves} />
          <Route path="/cycle-counting" component={CycleCounting} />
          {/* redirect all non matches to landing page */}
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Viewport>
    </div>
  </Router>
);

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default App;
