import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import IngredientDetails from '../Ingredient/IngredientDetails';
import styles from './styles';

const useStyles = makeStyles(styles);

const MealDetails = ({ meal }) => {
  const classes = useStyles();

  return meal.ingredients.map((ingredient) => (
    <div key={ingredient.id} className={classes.ingredientDetailsContainer}>
      <p className={classes.title}>{`${ingredient.name} - ${ingredient.id}`}</p>
      <IngredientDetails ingredient={ingredient} />
    </div>
  ));
};

MealDetails.propTypes = {
  meal: PropTypes.object.isRequired,
};

export default MealDetails;
