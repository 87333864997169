import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import { StatefulButton, DepthOptionsSelector } from 'components/shared';

const buttonText = {
  DEFAULT: 'Complete Move',
  SUCCESS: 'Success!',
  LOADING: 'Saving',
  ERROR: 'Failed to Update Request',
};

const DepthSelection = ({
  classes,
  history,
  locationBaseName,
  depthOptions,
  selectedDepthId,
  setSelectedDepth,
  onResetDepths,
  onCancel,
  updating,
  updatingFailed,
  onSubmit,
  setLocationNumber,
  callbackLocation,
}) => {
  const resetDepths = () => {
    setLocationNumber('');
    setSelectedDepth({});
    onResetDepths();
  };

  const cancel = () => {
    resetDepths();
    onCancel();
  };

  const submitMove = () => {
    onSubmit(selectedDepthId);
  };

  const successCallback = () => {
    resetDepths();
    history.push(callbackLocation);
  };

  return (
    <div data-testid="depth-selection">
      <Card className={classes.card}>
        <div className={classes.header}>
          <span>Select Storage Position</span>
          <span>{locationBaseName}</span>
        </div>
        <DepthOptionsSelector
          selectedDepthOptionId={selectedDepthId}
          setSelectedDepthOption={setSelectedDepth}
          depthOptions={depthOptions}
        />
      </Card>
      <div className={classes.actionsContainer}>
        <Button classes={{ root: classes.cancelButton }} onClick={cancel}>
          Cancel
        </Button>
        <StatefulButton
          disabled={!selectedDepthId}
          buttonTextOptions={buttonText}
          loading={updating}
          failed={updatingFailed}
          type="button"
          onClick={submitMove}
          resetToNormal={false}
          successCallback={successCallback}
          classes={{
            disabled: classes.disabledButton,
            root: classes.submitButton,
          }}
        />
      </div>
    </div>
  );
};

DepthSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  locationBaseName: PropTypes.string.isRequired,
  depthOptions: PropTypes.array.isRequired,
  setSelectedDepth: PropTypes.func.isRequired,
  selectedDepthId: PropTypes.number,
  onResetDepths: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setLocationNumber: PropTypes.func.isRequired,
  callbackLocation: PropTypes.string,
};

DepthSelection.defaultProps = {
  selectedDepthId: null,
  onCancel: () => {},
  callbackLocation: '',
};

export default DepthSelection;
