import sumBy from 'lodash/sumBy';

import { percentProgress } from 'lib/utils/number';

export const formatOverviewCardData = (meals, customizeItIngredients = []) => {
  const mealsAndCustomizeItIng = meals.concat(customizeItIngredients);
  const portioningCompletedCount =
    sumBy(meals, (meal) => meal.portioningGoalCount) -
    sumBy(meals, (meal) => meal.remainingPortioningCount) +
    sumBy(customizeItIngredients, (i) => i.portioning_completed_count);
  const portioningGoalCount = sumBy(
    mealsAndCustomizeItIng,
    (i) => i.portioningGoalCount
  );
  const portioningRemainingCount =
    sumBy(meals, (meal) => meal.remainingPortioningCount) +
    sumBy(customizeItIngredients, (i) => i.remainingPortioningGoalCount);

  const portionPercentCompleted = percentProgress(
    portioningGoalCount,
    portioningRemainingCount,
    true
  );

  const labelingPercentCompleted = percentProgress(
    sumBy(meals, (meal) => meal.labelingGoalCount),
    sumBy(meals, (meal) => meal.remainingLabelingCount),
    true
  );

  const mealBaggingPercentCompleted = percentProgress(
    sumBy(meals, (meal) => meal.goal_count),
    sumBy(meals, (meal) => meal.remaining_count),
    true
  );

  return [
    {
      activityName: 'Labeling',
      goalCount: sumBy(meals, (meal) => meal.labelingGoalCount),
      completedCount: sumBy(
        meals,
        (meal) => meal.labelingGoalCount - meal.remainingLabelingCount
      ),
      target: meals[0].end_of_shift_label_target,
      percentCompleted: Math.round(labelingPercentCompleted),
    },
    {
      activityName: 'Portioning',
      goalCount: portioningGoalCount,
      completedCount: portioningCompletedCount,
      target: meals[0].end_of_shift_portioning_target,
      percentCompleted: Math.round(portionPercentCompleted),
    },
    {
      activityName: 'Meal Bagging',
      goalCount: sumBy(meals, (meal) => meal.goal_count),
      completedCount: sumBy(meals, (meal) => meal.submission_count),
      target: meals[0].end_of_shift_percent_target,
      percentCompleted: Math.round(mealBaggingPercentCompleted),
    },
  ];
};
