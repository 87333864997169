import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import withStyles from '@mui/styles/withStyles';
import MenuIcon from '@mui/icons-material/Menu';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import classnames from 'classnames';

import { Loading } from 'components/shared';

import styles from './styles';

const MealDataCard = ({ menuSelection, ingredientAssignments, classes }) => {
  const [showImage, setShowImage] = useState(true);

  const isLabelRequired = (labelRequired) => {
    if (labelRequired) {
      return <CheckBoxIcon />;
    }
  };

  const cardContent = () => {
    if (!menuSelection || !ingredientAssignments) {
      return (
        <div className={classes.centeredLoading}>
          <Loading />
        </div>
      );
    } else if (showImage && menuSelection.imageUrl) {
      return (
        <CardMedia image={menuSelection.imageUrl} className={classes.media} />
      );
    } else {
      return (
        <Table classes={{ root: classes.table }} size="small">
          <TableHead>
            <TableRow>
              <TableCell classes={{ head: classes.metaDataTableHeader }}>
                Ingredient
              </TableCell>
              <TableCell classes={{ head: classes.metaDataTableHeader }}>
                Brand
              </TableCell>
              <TableCell classes={{ head: classes.metaDataTableHeader }}>
                Qty/Uom
              </TableCell>
              <TableCell classes={{ head: classes.metaDataTableHeader }}>
                Tolerance
              </TableCell>
              <TableCell classes={{ head: classes.metaDataTableHeader }}>
                Pkg Type
              </TableCell>
              <TableCell classes={{ head: classes.metaDataTableHeader }}>
                Label
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredientAssignments.map((ia) => {
              return (
                <TableRow key={ia.id}>
                  <TableCell>{ia.name}</TableCell>
                  <TableCell>{ia.brand}</TableCell>
                  <TableCell>
                    {ia.quantity} / {ia.unitOfMeasure}
                  </TableCell>
                  <TableCell>
                    {ia.lowTolerance} - {ia.highTolerance} {ia.unitOfMeasure}
                  </TableCell>
                  <TableCell>{ia.packagingType}</TableCell>
                  <TableCell>{isLabelRequired(ia.labelRequired)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }
  };

  return (
    <Card className={classes.mealDataCard}>
      <MenuIcon
        className={classnames(classes.menuIcon, {
          [classes.hidden]: !menuSelection.imageUrl,
        })}
        onClick={() => setShowImage(!showImage)}
      />
      {cardContent()}
    </Card>
  );
};

MealDataCard.propTypes = {
  classes: PropTypes.object.isRequired,
  menuSelection: PropTypes.shape({ imageUrl: PropTypes.string }).isRequired,
  ingredientAssignments: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  ingredientAssignments:
    state.ingredientAssignments.productionInventory.ingredientAssignments,
});

export default withStyles(styles)(connect(mapStateToProps)(MealDataCard));
