import { WHITE, HC_GREEN, GREY_200, ERROR_RED } from 'lib/constants';

export default {
  numberOnHold: {
    color: ERROR_RED,
  },
  completeButton: {
    backgroundColor: HC_GREEN,
    color: WHITE,
    fontSize: '85%',
    height: '56px',
    width: '100%',
    '&:disabled': {
      backgroundColor: GREY_200,
    },
  },
};
