import { GREY_700, HC_GREEN } from 'lib/constants';

export default {
  header: {
    marginBottom: '10px',
  },
  arrowBack: {
    verticalAlign: 'bottom',
  },
  title: {
    fontFamily: 'Muli',
    fontWeight: '500',
    lineHeight: '1.8',
    fontSize: '24px',
    color: GREY_700,
    verticalAlign: 'super',
  },
  toggleAllButtonGroup: {
    float: 'right',
  },
  controls: {
    height: '60px',
  },
  lastUpdated: {
    float: 'right',
    marginBottom: '24px',
    marginTop: '12px',
    fontSize: '0.87em',
  },
  link: {
    padding: '0px 20px 5px 20px',
    textDecoration: 'none',
    color: '#000000',
  },
  active: {
    color: HC_GREEN,
    borderBottom: `4px solid ${HC_GREEN}`,
  },
  activityLinks: {
    paddingBottom: '22px',
    paddingTop: '10px',
    display: 'inline-block',
  },
  subNavLinks: {
    margin: '8px',
  },
  progressTextWrapper: {
    marginTop: '2px',
    marginLeft: '10px',
  },
};
