import { GREY_200, GREY_700, WHITE } from 'lib/constants';

export default {
  message: {
    textAlign: 'center',
    margin: '35px 0px',
  },
  button: {
    margin: '0px 5px',
    width: 'calc(100% - 4px)',
  },
  trucksContainer: {
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    margin: '35px 2px 0px 2px',
  },
  truckRow: {
    display: 'flex',
    padding: '5px 16px',
    minHeight: '35px',
    borderTop: `1px solid ${GREY_200}`,
    alignItems: 'center',
    cursor: 'pointer',
  },
  header: {
    background: GREY_700,
    color: WHITE,
    borderRadius: '4px 4px 0px 0px',
  },
  vendor: {
    flex: '2',
  },
  truckName: {
    flex: '1',
  },
};
