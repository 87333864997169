import { useState, useCallback, useEffect } from 'react';
import orderBy from 'lodash/orderBy';

// search helper
const searchPallets = (regex, groupPallets) =>
  groupPallets.filter(
    (pallet) =>
      pallet.item.match(regex) ||
      pallet.vendorName.match(regex) ||
      pallet.netsuiteOrderNumber.match(regex) ||
      pallet.id.toString().match(regex) ||
      pallet.brand?.match(regex)
  );

// first apply search terms, then sort
const usePalletSearchAndSort = (pallets) => {
  const [searchedPallets, setSearchedPallets] = useState(pallets);
  const [sortedPallets, setSortedPallets] = useState(searchedPallets);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortStatus, setSortStatus] = useState({
    sortColumn: 'item',
    sortBy: 'asc',
  });

  const onSortClick = useCallback(
    (sortColumnName) => {
      setSortStatus({
        sortColumn: sortColumnName,
        sortBy:
          sortStatus.sortColumn === sortColumnName &&
          sortStatus.sortBy === 'asc'
            ? 'desc'
            : 'asc',
      });
    },
    [sortStatus]
  );

  const onSearch = useCallback((input) => setSearchTerm(input), [
    setSearchTerm,
  ]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchedPallets(pallets);
      return;
    }

    const searchRegex = new RegExp(searchTerm, 'gi');
    // if there are matching pallets, return the group with only those pallets,
    // if there are no matching pallets, remove the group from the results
    const searchResults = pallets.flatMap((palletGroup) => {
      const searchPalletResults = searchPallets(
        searchRegex,
        palletGroup.pallets
      );

      if (searchPalletResults.length) {
        return {
          ...palletGroup,
          pallets: searchPalletResults,
        };
      }
      return [];
    });

    setSearchedPallets(searchResults);
  }, [pallets, searchTerm, setSearchedPallets]);

  useEffect(() => {
    const { sortColumn, sortBy } = sortStatus;
    const orderedPallets = orderBy(
      searchedPallets,
      (pallet) => pallet.groupInfo[sortColumn],
      sortBy
    );
    setSortedPallets(orderedPallets);
  }, [searchedPallets, sortStatus]);

  return {
    sortedPallets,
    sortStatus,
    onSortClick,
    onSearch,
  };
};

export default usePalletSearchAndSort;
