import React, { Fragment, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

import { getMoveRequestDisplayName } from 'lib/viewHelpers';
import { getFirstAndLastInitials } from 'lib/utils';
import { useScanner } from 'lib/custom_hooks';
import { useLocationDepths } from 'components/WarehouseInventory/helpers';
import { ActionCard, Loading } from 'components/shared';
import { DROP_OFF, BULK } from 'lib/constants';

import LocationForm from '../../LocationForm';
import DepthSelection from '../../DepthSelection';
import QuantityToZoneDisplay from '../../QuantityToZoneDisplay';
import NumberOfLabelsDialog from './NumberOfLabelsDialog';
import ConfirmationScanDialog from './ConfirmationScanDialog';
import PrinterOverrideDialog from './PrinterOverrideDialog';

const TIME_GOAL = 1200000; // 20 minutes

const ViewInProgressRequest = ({
  autoprintEnabled,
  classes,
  history,
  itemMoveRequest,
  signedInUser,
  packingFacilityId,
  depthOptions,
  depthsLoading,
  depthsFailed,
  itemMoveRequestUpdating,
  itemMoveRequestUpdatingFailed,
  onGetDepths,
  onResetDepths,
  onUpdate,
  onPrintMoveLicensePlate,
  postingPrintChildLicensePlate,
  postingPrintChildLicensePlateFailed,
  successConfettiCallback,
}) => {
  const locationInputElement = useRef(null);
  const [locationNumber, setLocationNumber] = useState('');
  const [selectedDepth, setSelectedDepth] = useState({});
  const [openNumLabelsDialog, setOpenNumLabelsDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openPrinterOverrideDialog, setOpenPrinterOverrideDialog] = useState(
    false
  );

  const getDepthsCallback = useLocationDepths(
    onResetDepths,
    onGetDepths,
    packingFacilityId,
    itemMoveRequest ? itemMoveRequest.palletId : null
  );

  const locationReset = useCallback(() => {
    setLocationNumber('');
  }, [setLocationNumber]);

  const resetDepths = useCallback(() => {
    locationReset();
    setSelectedDepth({});
    onResetDepths();
  }, [locationReset, setSelectedDepth, onResetDepths]);

  const processScan = (scan) => {
    onGetDepths(scan);
  };

  useScanner(locationInputElement, processScan, true);

  const requestHeader = () => {
    const originatedFromRequest = itemMoveRequest.requestableId !== null;
    if (originatedFromRequest && itemMoveRequest.category !== BULK) {
      return getMoveRequestDisplayName(itemMoveRequest);
    } else if (originatedFromRequest) {
      return `Request - ${itemMoveRequest.itemName}`;
    } else {
      return 'Request';
    }
  };

  const onSubmitClick = () => {
    onUpdate(itemMoveRequest.id, DROP_OFF, {
      drop_off_location_id: selectedDepth.id,
    });

    const showConfetti =
      new Date() - new Date(itemMoveRequest.requestStart) <= TIME_GOAL;

    setOpenConfirmDialog(false);
    successConfettiCallback(showConfetti);
  };

  const onClickDepth = () => {
    if (
      itemMoveRequest.fullPallet ||
      itemMoveRequest.fullPallet === null ||
      selectedDepth.staging ||
      !autoprintEnabled
    ) {
      onSubmitClick();
    } else {
      setOpenNumLabelsDialog(true);
    }
  };

  const onSelectNumLabels = async (num) => {
    const response = await onPrintMoveLicensePlate(
      itemMoveRequest.id,
      num,
      selectedDepth.id
    );

    if (response.ok) {
      setOpenConfirmDialog(true);
    } else {
      setOpenPrinterOverrideDialog(true);
    }

    setOpenNumLabelsDialog(false);
  };

  const renderRequest = () => {
    return (
      <Fragment>
        <QuantityToZoneDisplay
          itemMoveRequest={itemMoveRequest}
          forceRequestToDisplay
        />
        <div className={classes.rightSide}>
          {itemMoveRequest.runner && (
            <Chip
              className={classNames(classes.chip, {
                [classes.myChip]: itemMoveRequest.runner === signedInUser,
              })}
              label={getFirstAndLastInitials(itemMoveRequest.runner)}
            />
          )}
        </div>
        <hr className={classes.line} />
      </Fragment>
    );
  };

  const handleClose = () => {
    setOpenNumLabelsDialog(false);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleClosePrinterOverrideDialog = () => {
    setOpenPrinterOverrideDialog(false);
  };

  const onReprintClick = () => {
    setOpenPrinterOverrideDialog(false);
    setOpenNumLabelsDialog(true);
  };

  if (depthOptions.length > 0) {
    return (
      <>
        <DepthSelection
          itemMoveRequest={itemMoveRequest}
          selectedDepthId={selectedDepth.id}
          setSelectedDepth={setSelectedDepth}
          locationBaseName={locationNumber}
          history={history}
          onSubmit={onClickDepth}
          setLocationNumber={setLocationNumber}
          callbackLocation="/warehouse_moves/in_progress"
          updating={itemMoveRequestUpdating}
          updatingFailed={itemMoveRequestUpdatingFailed}
        />
        <NumberOfLabelsDialog
          openNumLabelsDialog={openNumLabelsDialog}
          handleClose={handleClose}
          loading={postingPrintChildLicensePlate}
          failed={postingPrintChildLicensePlateFailed}
          onSubmit={onSelectNumLabels}
        />
        <ConfirmationScanDialog
          open={openConfirmDialog}
          itemMoveRequest={itemMoveRequest}
          handleClose={handleCloseConfirmationDialog}
          onConfirmationSuccess={onSubmitClick}
        />
        <PrinterOverrideDialog
          open={openPrinterOverrideDialog}
          handleClose={handleClosePrinterOverrideDialog}
          onTryReprint={onReprintClick}
          onOverride={onSubmitClick}
        />
      </>
    );
  }

  if (!itemMoveRequest) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <ActionCard
          header={requestHeader()}
          renderRequest={renderRequest()}
          message="Scan Location to Drop"
        />
        {itemMoveRequest.category === BULK && (
          <Card className={classes.card}>
            <div
              className={classes.header}
            >{`Pickup - Lot ${itemMoveRequest.palletId}`}</div>
            <CardContent className={classes.cardContent}>
              <span className={classes.boldText}>
                {itemMoveRequest.itemName}
              </span>
              {itemMoveRequest.brand && (
                <span
                  className={classes.lightText}
                >{` - ${itemMoveRequest.brand}`}</span>
              )}
              <hr className={classes.line} />
              <div>
                <span
                  className={classes.lightText}
                >{`${itemMoveRequest.measuringUnit} - `}</span>
                <span
                  className={classes.boldText}
                >{`Moving ${itemMoveRequest.fulfilledCases}`}</span>
              </div>
            </CardContent>
          </Card>
        )}
        <LocationForm
          resetDepths={resetDepths}
          getDepthsCallback={getDepthsCallback}
          setLocationNumber={setLocationNumber}
          locationInputElement={locationInputElement}
          locationNumber={locationNumber}
          classes={classes}
          depthsFailed={depthsFailed}
          depthsLoading={depthsLoading}
          activePallet
        />
      </>
    );
  }
};

ViewInProgressRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  itemMoveRequest: PropTypes.object.isRequired,
  signedInUser: PropTypes.string.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
  depthOptions: PropTypes.array.isRequired,
  depthsLoading: PropTypes.bool.isRequired,
  depthsFailed: PropTypes.bool.isRequired,
  itemMoveRequestUpdating: PropTypes.bool.isRequired,
  itemMoveRequestUpdatingFailed: PropTypes.bool.isRequired,
  onGetDepths: PropTypes.func.isRequired,
  onResetDepths: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ViewInProgressRequest;
