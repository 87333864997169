import { options, handleResponse } from 'lib/homeChefApi';
import { buildIngredientAssignmentQuery } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getIngredientAssignments = async ({ ingredientAssignmentIds = [] }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/ingredient_assignments/inventory_check?${buildIngredientAssignmentQuery(
      ingredientAssignmentIds
    )}`,
    options
  );
  return await handleResponse(response);
};

export default { getIngredientAssignments };
