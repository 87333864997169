import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import withStyles from '@mui/styles/withStyles';

import {
  BY_HAND,
  BY_MACHINE,
  PORTIONERS,
  PORTION_FILLER_COUNT_BY_FACILITY_ID,
} from 'lib/constants';

import styles from './styles';

const PortionMethodQuestion = ({
  portionMethod,
  setPortionMethod,
  portioner,
  setPortioner,
  portionFillerNumber,
  setPortionFillerNumber,
  packingFacilityId,
  classes,
}) => {
  useEffect(() => {
    if (portionMethod === BY_HAND) {
      setPortioner(undefined);
    }
  }, [portionMethod, setPortioner]);

  useEffect(() => {
    if (portioner !== 'Portion Filler') {
      setPortionFillerNumber(undefined);
    }
  }, [portioner, setPortionFillerNumber]);

  const portionFillerNumberOptions = useMemo(
    () =>
      Array.from(
        Array(PORTION_FILLER_COUNT_BY_FACILITY_ID[packingFacilityId])
      ).map((_, index) => index + 1),
    [packingFacilityId]
  );

  const portionerButtonGroup = () => {
    if (portionMethod === BY_MACHINE) {
      return (
        <ToggleButtonGroup
          exclusive
          className={classes.buttonGroup}
          size="large"
          value={portioner}
          onChange={(_, newPortioner) => setPortioner(newPortioner)}
        >
          {PORTIONERS.map((portionerName) => {
            return (
              <ToggleButton
                className={classNames(classes.button, classes.portionerButton)}
                key={portionerName}
                value={portionerName}
              >
                {portionerName}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      );
    } else {
      null;
    }
  };

  const portionFillerNumberGroup = () => {
    return (
      <ToggleButtonGroup
        exclusive
        className={classNames(
          classes.buttonGroup,
          classes.portionFillerNumberButtonGroup
        )}
        size="small"
        value={portionFillerNumber}
        onChange={(_, newPortionFillerNumber) =>
          setPortionFillerNumber(newPortionFillerNumber)
        }
      >
        {portionFillerNumberOptions.map((portionFillerNumberOption) => {
          return (
            <ToggleButton
              className={classNames(
                classes.button,
                classes.portionFillerNumberButton
              )}
              key={portionFillerNumberOption}
              value={portionFillerNumberOption}
              data-testid={`portioner-number-${portionFillerNumberOption}`}
            >
              {portionFillerNumberOption}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  };

  return (
    <Fragment>
      <h3>Portioning Method</h3>
      <ToggleButtonGroup
        exclusive
        className={classes.buttonGroup}
        size="large"
        value={portionMethod}
        onChange={(_, newPortionMethod) => setPortionMethod(newPortionMethod)}
      >
        {[
          { description: 'By Hand', value: BY_HAND },
          { description: 'By Machine', value: BY_MACHINE },
        ].map((method) => {
          return (
            <ToggleButton
              className={classes.button}
              key={method.description}
              value={method.value}
            >
              {method.description}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <div>{portionerButtonGroup()}</div>
      {portioner === 'Portion Filler' && (
        <div>{portionFillerNumberGroup()}</div>
      )}
    </Fragment>
  );
};

PortionMethodQuestion.propTypes = {
  portionMethod: PropTypes.string,
  setPortionMethod: PropTypes.func.isRequired,
  portioner: PropTypes.string,
  setPortioner: PropTypes.func.isRequired,
  portionFillerNumber: PropTypes.number,
  setPortionFillerNumber: PropTypes.func.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

PortionMethodQuestion.defaultProps = {
  portionMethod: undefined,
  portioner: undefined,
  portionFillerNumber: undefined,
};

export default withStyles(styles)(PortionMethodQuestion);
