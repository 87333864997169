import { GET_MEALS_TRACKER_PROGRESS } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { getTimeFromDate, filterDateAsDDMMYY } from 'lib/utils';
import { updateLastSuccessfulFetchAt } from 'redux/session/actions';

import {
  getMealsTrackerProgress,
  getMealsForAssemblyTrackerProgress,
} from './services';

export const fetchMealsTrackerProgress = (menuId) => async (dispatch) => {
  const response = await dispatch(
    makeApiRequest({
      type: GET_MEALS_TRACKER_PROGRESS,
      method: () => getMealsTrackerProgress(menuId),
    })
  );

  if (response.ok) {
    const currentTime = new Date();
    const formattedTime = `${filterDateAsDDMMYY(currentTime)} ${getTimeFromDate(
      currentTime
    )}`;

    dispatch(updateLastSuccessfulFetchAt(formattedTime));
  }
};

export const fetchMealsForAssemblyTrackerProgress = (menuId) => async (
  dispatch
) => {
  const response = await dispatch(
    makeApiRequest({
      type: GET_MEALS_TRACKER_PROGRESS,
      method: () => getMealsForAssemblyTrackerProgress(menuId),
    })
  );

  if (response.ok) {
    const currentTime = new Date();
    const formattedTime = `${filterDateAsDDMMYY(currentTime)} ${getTimeFromDate(
      currentTime
    )}`;

    dispatch(updateLastSuccessfulFetchAt(formattedTime));
  }
};
