import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const ProductionSheetsIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
      >
        <title>prod_sheet</title>
        <rect
          x="18.64"
          y="16.7"
          width="19.71"
          height="15.67"
          rx="1.44"
          style={{ fill: HC_GREEN }}
        />
        <rect
          x="18.64"
          y="58.04"
          width="42.71"
          height="4.62"
          rx="1.44"
          style={{ fill: HC_GREEN, opacity: '0.4' }}
        />
        <path
          d="M67.84,8H12.16a2.75,2.75,0,0,0-2.75,2.75v58.5A2.75,2.75,0,0,0,12.16,72H67.84a2.75,2.75,0,0,0,2.75-2.75V10.75A2.75,2.75,0,0,0,67.84,8ZM66,65.48c0,1-.64,1.9-1.44,1.9H15.47c-.8,0-1.44-.85-1.44-1.9V14a1.34,1.34,0,0,1,1.44-1.49H64.53A1.27,1.27,0,0,1,66,14Z"
          style={{ fill: HC_GREEN }}
        />
        <rect
          x="18.64"
          y="47.71"
          width="42.71"
          height="4.62"
          rx="1.44"
          style={{ fill: HC_GREEN, opacity: '0.4' }}
        />
        <rect
          x="18.64"
          y="37.37"
          width="42.71"
          height="4.62"
          rx="1.44"
          style={{ fill: HC_GREEN, opacity: '0.4' }}
        />
        <rect
          x="43.36"
          y="27.03"
          width="17.71"
          height="4.62"
          rx="1.44"
          style={{ fill: HC_GREEN, opacity: '0.4' }}
        />
        <rect
          x="43.36"
          y="16.7"
          width="17.71"
          height="4.62"
          rx="1.44"
          style={{ fill: HC_GREEN, opacity: '0.4' }}
        />
      </svg>
    </div>
  );
};

ProductionSheetsIcon.propTypes = {
  className: PropTypes.string,
};

ProductionSheetsIcon.defaultProps = {
  className: 'ProductionSheetsIcon',
};

export default ProductionSheetsIcon;
