import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';
import { makeQueryParamURL } from 'lib/utils';

const getPallets = async ({ packingFacilityId, timeRange = null }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/fsqa_reviews`,
      {
        ...(timeRange && { time_range: timeRange }),
      }
    ),
    options
  );

  return await handleResponse(response);
};

export default {
  getPallets,
};
