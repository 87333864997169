import { GREY_150, WHITE, BLACK } from 'lib/constants';

const cellMinWidth = '50px';

export default {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    fontSize: 'small',
  },
  cell: {
    padding: '0px 0px 0px 10px',
    textAlign: 'left',
    borderBottom: 'none',
    borderRight: '2px solid white',
    '&:last-of-type': {
      paddingRight: '0px !important',
      border: 'none',
    },
    '&:first-of-type': {
      marginLeft: '2px !important',
    },
    backgroundColor: GREY_150,
    minWidth: cellMinWidth,
  },
  lastCell: {
    padding: '0px 0px 0px 10px',
    textAlign: 'left',
    borderBottom: 'none',
    borderRight: '1px solid lightgrey',
    borderLeft: '2px solid white',
    '&:last-of-type': {
      paddingRight: '0px !important',
      border: 'none',
    },
    '&:first-of-type': {
      marginLeft: '2px !important',
    },
    backgroundColor: GREY_150,
    backgroundSize: '95%',
    minWidth: cellMinWidth,
  },
  headerRow: {
    height: '34px',
  },
  row: {
    height: '34px',
    '&:nth-of-type(odd)': {
      backgroundColor: GREY_150,
    },
  },
  dayName: {
    backgroundColor: WHITE,
    fontSize: '14px',
    color: BLACK,
    textAlign: 'left',
    padding: '0px 0px 0px 10px',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 'none',
    minWidth: cellMinWidth,
  },
  completedDay: {
    opacity: '0.3',
  },
  percentComplete: {
    backgroundColor: WHITE,
    fontSize: '14px',
    textAlign: 'right',
    padding: '0px 10px 0px 0px',
    borderBottom: 'none',
    minWidth: cellMinWidth,
  },
  cellBigText: {
    fontSize: '15px',
  },
};
