import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import {
  selectItemMoveRequest,
  selectFollowUp,
} from 'redux/itemMoveRequests/selectors';

import styles from './styles';
import FollowUp from './FollowUp';

const mapStateToProps = (state, ownProps) => {
  return {
    itemMoveRequest: selectItemMoveRequest(
      state,
      ownProps.match.params.requestId
    ),
    followUp: selectFollowUp(state, ownProps.match.params.requestId),
    signedInUser: state.session.user_name,
    palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
  };
};

const mapDispatchToProps = {};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FollowUp)
);
