import {
  INGREDIENTS_REQUEST,
  INGREDIENTS_SUCCESS,
  INGREDIENTS_ERROR,
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_REQUEST,
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_SUCCESS,
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_ERROR,
  CLEAR_INGREDIENT_ASSIGNMENT,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  ingredients: {},
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case INGREDIENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case INGREDIENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        ingredients: action.response.ingredients,
      };
    case INGREDIENTS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        ingredientAssignment: action.response.ingredientAssignment,
      };
    case PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case CLEAR_INGREDIENT_ASSIGNMENT:
      return {
        ...state,
        ingredientAssignment: {},
      };
    default:
      return state;
  }
}
