import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { QUANTITY_REMOVAL_OPTIONS } from 'lib/constants';

const QtyRemovalModals = ({
  classes,
  removalModalOpen,
  onQuantityReasonCancel,
  subReasons,
  removalReasonType,
  onQuantityReasonSubmit,
  onReasonTypeClick,
}) => {
  return (
    <Fragment>
      <Dialog
        open={removalModalOpen}
        onClose={onQuantityReasonCancel}
        classes={{ paperScrollPaper: classes.muiPaper }}
      >
        <Fragment>
          <DialogContent classes={{ root: classes.promptContainer }}>
            <DialogContentText classes={{ root: classes.promptText }}>
              {subReasons.length ? 'Select reason:' : 'Select removal reason:'}
            </DialogContentText>
          </DialogContent>
          <DialogContent classes={{ root: classes.optionsContainer }}>
            {subReasons.length
              ? removalReasonType.reasons.map((reason) => (
                  <Button
                    key={reason}
                    classes={{ root: classes.modalButtons }}
                    onClick={() =>
                      onQuantityReasonSubmit(removalReasonType.type, reason)
                    }
                  >
                    {reason}
                  </Button>
                ))
              : QUANTITY_REMOVAL_OPTIONS.map((option) => (
                  <Button
                    key={option.type}
                    classes={{ root: classes.modalButtons }}
                    onClick={() => onReasonTypeClick(option.type)}
                  >
                    {option.type}
                  </Button>
                ))}
          </DialogContent>
        </Fragment>
      </Dialog>
    </Fragment>
  );
};

QtyRemovalModals.propTypes = {
  classes: PropTypes.object.isRequired,
  removalModalOpen: PropTypes.bool.isRequired,
  onQuantityReasonCancel: PropTypes.func.isRequired,
  subReasons: PropTypes.array,
  removalReasonType: PropTypes.object.isRequired,
  onQuantityReasonSubmit: PropTypes.func.isRequired,
  onReasonTypeClick: PropTypes.func.isRequired,
};

QtyRemovalModals.defaultProps = {
  subReasons: [],
};

export default QtyRemovalModals;
