import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { ECOMMERCE_CHANNEL } from 'lib/constants';
import { StickyBarProvider } from 'components/shared';

import OverviewCard from './OverviewCard';
import DetailedOverview from './DetailedOverview';
import styles from './styles';
import { formatOverviewCardData } from './utils';

const ProgressOverview = ({
  classes,
  meals,
  customizeItIngredients,
  menu,
  isPartOfStandardMenu,
}) => {
  const weeklyProgress =
    meals.length > 0
      ? formatOverviewCardData(meals, customizeItIngredients, ECOMMERCE_CHANNEL)
      : [];

  return (
    <div className={classes.root}>
      <StickyBarProvider stickyYCoord={90}>
        <Link
          underline="none"
          color="inherit"
          className={classes.routerLink}
          component={RouterLink}
          to="/progress_tracker"
        >
          <OverviewCard
            isPartOfStandardMenu={isPartOfStandardMenu}
            menu={menu}
            classes={classes}
            weeklyProgress={weeklyProgress}
            title={ECOMMERCE_CHANNEL.slice(0, 5)}
          />
        </Link>
      </StickyBarProvider>
      <DetailedOverview
        isPartOfStandardMenu={isPartOfStandardMenu}
        meals={meals}
        customizeItIngredients={customizeItIngredients}
      />
    </div>
  );
};

ProgressOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  meals: PropTypes.array.isRequired,
  customizeItIngredients: PropTypes.array.isRequired,
  isPartOfStandardMenu: PropTypes.bool.isRequired,
  menu: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProgressOverview);
