import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { getLicensePlatePrinters } from 'redux/printers/actions';

import styles from './styles';
import ReceivingLandingPage from './ReceivingLandingPage';

const mapDispatchToProps = {
  onFetchPrinters: getLicensePlatePrinters,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ReceivingLandingPage));
