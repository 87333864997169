import { ROBOTO } from 'lib/constants';

import {
  arrowIcon,
  formLabelText,
  formSubmitButton,
  formDivider,
  sublotStyles,
} from '../../styles';

export default {
  arrowIcon,
  formLabelText,
  formSubmitButton,
  formDivider,
  ...sublotStyles,
  assemblyDrawerHeader: {
    fontFamily: ROBOTO,
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    paddingBottom: '10px',
  },
  assemblyDrawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
};
