import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';

import styles from '../styles';

const IngredientDataCard = ({ classes, ingredientAssignment }) => {
  const {
    brand,
    quantity,
    unitOfMeasure,
    lowTolerance,
    highTolerance,
    packagingType,
    portionMethod,
    allergens,
  } = ingredientAssignment;

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{ head: classes.metaDataTableHeader }}>
              Brand
            </TableCell>
            <TableCell classes={{ head: classes.metaDataTableHeader }}>
              Qty/Uom
            </TableCell>
            <TableCell classes={{ head: classes.metaDataTableHeader }}>
              Tolerance
            </TableCell>
            <TableCell classes={{ head: classes.metaDataTableHeader }}>
              Pkg Type
            </TableCell>
            <TableCell classes={{ head: classes.metaDataTableHeader }}>
              Portion Method
            </TableCell>
            <TableCell classes={{ head: classes.metaDataTableHeader }}>
              Allergens
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{brand}</TableCell>
            <TableCell>
              {quantity} {unitOfMeasure}
            </TableCell>
            <TableCell>
              {lowTolerance} - {highTolerance}
            </TableCell>
            <TableCell>{packagingType}</TableCell>
            <TableCell>{portionMethod}</TableCell>
            <TableCell>{allergens}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

IngredientDataCard.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredientAssignment: PropTypes.shape({
    brand: PropTypes.string,
    quantity: PropTypes.number,
    unitOfMeasure: PropTypes.string,
    lowTolerance: PropTypes.number,
    highTolerance: PropTypes.number,
    packagingType: PropTypes.string,
    portionMethod: PropTypes.string,
    allergens: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }).isRequired,
};

export default withStyles(styles)(IngredientDataCard);
