import { createTheme } from '@mui/material/styles';

import theme from 'lib/theme';
import { WHITE, HC_GREEN, GREY_200, DARK_HC_GREEN } from 'lib/constants';

const submitButtonTheme = createTheme({
  ...theme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: HC_GREEN,
          color: `${WHITE} !important`,
          fontSize: '17px',
          height: '70px',
          width: '100%',
          margin: '20px auto 0',
          '&:disabled': {
            backgroundColor: GREY_200,
          },
          '&:hover': {
            backgroundColor: DARK_HC_GREEN,
          },
        },
      },
    },
  },
});
export default submitButtonTheme;
