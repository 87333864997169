import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import classNames from 'classnames';

import { TabOutlineDiv } from 'components/shared';

const ItemDrawerNeedTruck = ({
  classes,
  selectTruck,
  toggleTruckDrawer,
  closeDrawer,
  selectOrderForTruck,
  netsuiteOrderItem,
  trucks,
  onAddOrderToTruck,
}) => {
  const onCreateTruck = useCallback(() => {
    closeDrawer();
    selectTruck({});
    toggleTruckDrawer(true);
    selectOrderForTruck({
      truckNetsuiteOrderId: null,
      id: netsuiteOrderItem.netsuiteOrderId,
      vendor: netsuiteOrderItem.vendorName,
      netsuiteOrderNumber: netsuiteOrderItem.netsuiteOrderNumber,
    });
  }, [
    selectTruck,
    toggleTruckDrawer,
    closeDrawer,
    selectOrderForTruck,
    netsuiteOrderItem,
  ]);
  const onTruckClick = (truck) => {
    onAddOrderToTruck({ truck, netsuiteOrderItem });
  };
  return (
    <div data-testid="item-drawer-need-truck">
      <div className={classes.message}>
        A truck needs to be assigned prior to printing license plates
      </div>
      <Button
        className={classes.button}
        variant="contained"
        onClick={onCreateTruck}
      >
        Create Truck
      </Button>
      <div className={classes.trucksContainer}>
        {trucks.length > 0 && (
          <div
            className={classNames(classes.header, classes.truckRow)}
            data-testid="header-row"
          >
            <span className={classes.truckName}>Truck</span>
            <span className={classes.vendor}>Vendor</span>
          </div>
        )}
        {trucks.map((truck) => (
          <TabOutlineDiv
            onClick={() => onTruckClick(truck)}
            role="button"
            tabIndex={0}
            className={classes.truckRow}
            key={truck.id}
            data-testid="truck-row"
          >
            <span className={classes.truckName}>{`T${truck.id}`}</span>
            <span className={classes.vendor}>
              {truck.vendorsArray.join(', ')}
            </span>
          </TabOutlineDiv>
        ))}
      </div>
    </div>
  );
};

ItemDrawerNeedTruck.propTypes = {
  classes: PropTypes.object.isRequired,
  selectTruck: PropTypes.func.isRequired,
  toggleTruckDrawer: PropTypes.func.isRequired,
  selectOrderForTruck: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onAddOrderToTruck: PropTypes.func.isRequired,
  netsuiteOrderItem: PropTypes.shape({
    netsuiteOrderId: PropTypes.number.isRequired,
    vendorName: PropTypes.string.isRequired,
    netsuiteOrderNumber: PropTypes.string.isRequired,
  }).isRequired,
  trucks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      vendor: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ItemDrawerNeedTruck;
