import React from 'react';

import { formatDateAsDDMMYY, formatDateHyphenated } from 'lib/utils';
import WarehouseReceivingStatusIcon from 'components/icons/WarehouseReceivingStatusIcon';
import { FormatNumberCell } from 'components/shared';

export const columns = (
  lastTruck,
  allNetsuiteOrderTruckIds,
  hasOffsiteFacility
) => {
  let cols = [
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const result = <WarehouseReceivingStatusIcon status={value} />;
          return result;
        },
      },
    },
    {
      name: 'expectedDeliveryDate',
      label: 'Delivery Date',
      options: {
        filterList: lastTruck.id
          ? null
          : [formatDateAsDDMMYY(formatDateHyphenated(new Date()))],
        customBodyRender: (value) => {
          if (value) {
            return formatDateAsDDMMYY(value);
          } else {
            return '';
          }
        },
      },
    },
    {
      name: 'trucks',
      label: 'Trucks',
      options: {
        filter: true,
        sort: true,
        filterList: lastTruck.id ? [`T${lastTruck.id}`] : null,
        customBodyRender: (value) => {
          if (value.length > 0) {
            return value.map((truck) => `T${truck.id}`).join(', ');
          } else {
            return '';
          }
        },
        filterOptions: {
          names: allNetsuiteOrderTruckIds.map((id) => `T${id}`),
          logic(formattedTruckIds, filterVal) {
            return !formattedTruckIds.includes(filterVal);
          },
        },
      },
    },
    { name: 'netsuiteOrderNumber', label: 'PO' },
    {
      name: 'netsuiteOrderType',
      label: 'Item Type',
      options: {
        filter: true,
        display: 'excluded',
      },
    },
    {
      name: 'orderType',
      label: 'Order Type',
      options: {
        filter: true,
        display: 'excluded',
      },
    },
    { name: 'vendorName', label: 'Vendor Name' },
    {
      name: 'brand',
      label: 'Brand',
      options: {
        filter: true,
        sort: true,
      },
    },
    { name: 'name', label: 'Item' },
    {
      name: 'quantity',
      label: 'Expected Quantity',
      options: {
        filter: false,
        customBodyRender: (value) => {
          const result = <FormatNumberCell value={value} addCommas />;
          return result;
        },
      },
    },
    { name: 'measuringUnitName', label: 'Units Size' },
    {
      name: 'receivedQuantity',
      label: 'Received Quantity',
      options: {
        filter: false,
        customBodyRender: (value) => {
          const receivedQuantity = value == 0 || value == null ? '' : value;
          const result = (
            <FormatNumberCell value={receivedQuantity} addCommas />
          );
          return result;
        },
      },
    },
  ];
  if (hasOffsiteFacility) {
    cols = [
      ...cols,
      {
        name: 'facility',
        label: 'Facility',
        options: {
          filter: true,
          display: 'excluded',
        },
      },
    ];
  }

  return cols;
};

export const options = {
  selectableRows: 'none',
  rowsPerPage: 100,
  rowsPerPageOptions: [10, 50, 100],
  print: false,
  responsive: 'standard',
};
