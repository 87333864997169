import { connect } from 'react-redux';

import { toggleMeal } from 'redux/meal/actions';

import MenuProgress from './MenuProgress';

const mapStateToProps = (state) => ({ meals: state.meals });

const mapDispatchToProps = { onToggleClick: toggleMeal };

export default connect(mapStateToProps, mapDispatchToProps)(MenuProgress);
