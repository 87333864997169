import React from 'react';
import PropTypes from 'prop-types';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import { formatTime, dateTimeInTimeZone } from 'lib/utils';

import { gridPosition, getColumnNumber, apptBlockStatusStyle } from './styles';

const Appointment = ({
  apptId,
  currentTime,
  startTime,
  status,
  timeZone,
  duration,
  poNumbers,
  vendorNames,
  row,
  memo,
  classes,
  onClickAppointment,
  onSetSelectedAppt,
}) => {
  const startTimeInZone = dateTimeInTimeZone(timeZone, new Date(startTime));
  const startTimeHHMM = formatTime(
    startTimeInZone.getHours() + ':' + startTimeInZone.getMinutes()
  );

  const colSpan = duration / 30;

  const handleAppointmentClick = () => {
    onClickAppointment(apptId);
    onSetSelectedAppt(apptId);
  };

  const hasMemo = () => {
    return memo ? (
      <div>
        <ChatBubbleOutlineIcon className={classes.icon} />
      </div>
    ) : null;
  };

  return (
    <div
      style={{
        ...gridPosition(getColumnNumber(startTimeHHMM), row, colSpan),
        ...apptBlockStatusStyle(status, startTimeInZone, currentTime),
      }}
      onClick={handleAppointmentClick}
      role="button"
      aria-hidden="true"
    >
      <p className={classes.appointmentHeader}>{poNumbers.join()}</p>
      <p className={classes.appointmentDetails}>{vendorNames.join(',')}</p>
      {hasMemo()}
    </div>
  );
};

export default Appointment;

Appointment.propTypes = {
  currentTime: PropTypes.object.isRequired,
  startTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  memo: PropTypes.string.isRequired,
  poNumbers: PropTypes.array.isRequired,
  vendorNames: PropTypes.array.isRequired,
  duration: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  apptId: PropTypes.number.isRequired,
  onClickAppointment: PropTypes.func,
  onSetSelectedAppt: PropTypes.func,
};

Appointment.defaultProps = {
  onSetSelectedAppt: () => {},
  onClickAppointment: () => {},
};
