import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { fetchPallet, resetSelectedPallet } from 'redux/pallets/actions';

import styles from './styles';
import PalletFetcher from './PalletFetcher';

const mapStateToProps = (state) => ({
  pallet: state.pallets.inventoryApp.selectedPallet,
  fetching: state.pallets.inventoryApp.fetching,
  fetchFailed: state.pallets.inventoryApp.fetchFailed,
});

const mapDispatchToProps = {
  onResetSelectedPallet: resetSelectedPallet,
  onFetchPallet: fetchPallet,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PalletFetcher)
);
