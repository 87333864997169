import { connect } from 'react-redux';

import { selectItemMoveRequestsBucketedForWarehouseMoves } from 'redux/itemMoveRequests/selectors';

import OpenRequests from './OpenRequests';
import { sortedToDos } from './sorting';

const mapStateToProps = (state) => {
  const { toDo } = selectItemMoveRequestsBucketedForWarehouseMoves(state);

  return {
    toDoRequests: sortedToDos(toDo) || [],
    fetching: state.itemMoveRequests.fetching,
    signedInUser: state.session.user_name,
    palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
  };
};

export default connect(mapStateToProps, null)(OpenRequests);
