import React, { useMemo } from 'react';
import withStyles from '@mui/styles/withStyles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';

import {
  NEEDS_REVIEW,
  FULL_CHECK_COMPLETED,
  PARTIAL_CHECK_COMPLETED,
  NOT_COMPLETED,
} from 'lib/constants/warehouseConstants';
import { formatDateAsDDMMYY } from 'lib/utils';
import { Search } from 'components/shared';
import { usePalletSearchAndSort } from 'lib/custom_hooks';

import {
  MainTable,
  MainTableHeadRow,
  MainTableHead,
  CollapsibleArea,
  MainTableRow,
  MainTableCell,
  SecondaryTable,
  SecondaryTableHeadRow,
  SecondaryTableHead,
  StatusIcon,
  TableSort,
} from '../sharedTableComponents';
import styles from './styles';

const statusIconMap = {
  [FULL_CHECK_COMPLETED]: 'filledCheck',
  [PARTIAL_CHECK_COMPLETED]: 'outlineCheck',
  [NEEDS_REVIEW]: 'circle',
  [NOT_COMPLETED]: 'circle',
};

const IngredientsPalletsTable = ({ pallets, onClickPallet, classes }) => {
  const {
    sortedPallets,
    sortStatus,
    onSortClick,
    onSearch,
  } = usePalletSearchAndSort(pallets);

  const sortInfo = useMemo(
    () => ({
      sortStatus,
      onSortClick,
    }),
    [sortStatus, onSortClick]
  );

  return (
    <>
      <h3 className={classes.tableHeader}>Food</h3>
      <Toolbar className={classes.toolbar}>
        <Search onSearch={onSearch} />
      </Toolbar>
      <MainTable>
        <MainTableHeadRow>
          <MainTableHead>
            <TableSort id="item" {...sortInfo}>
              Item
            </TableSort>
          </MainTableHead>
          <MainTableHead>
            <TableSort id="vendorName" {...sortInfo}>
              Vendor Name
            </TableSort>
          </MainTableHead>
          <MainTableHead size="Medium">
            <TableSort id="netsuiteOrderNumber" {...sortInfo}>
              PO
            </TableSort>
          </MainTableHead>
          <MainTableHead>Prod. Date Code</MainTableHead>
          <MainTableHead size="Medium">Batch QA</MainTableHead>
          <MainTableHead size="Small">Checked</MainTableHead>
          <MainTableHead size="XSmall" />
        </MainTableHeadRow>
        <TableBody data-testid="main-table-body">
          {sortedPallets.map(({ id, groupInfo, pallets: groupPallets }) => (
            <CollapsibleArea key={id}>
              <MainTableRow>
                <MainTableCell>
                  <strong data-testid="ingredient-pallet-item">
                    {groupInfo.item}
                  </strong>
                </MainTableCell>
                <MainTableCell>
                  <div data-testid="ingredient-pallet-vendor">
                    {groupInfo.vendorName}
                  </div>
                </MainTableCell>
                <MainTableCell>
                  <div data-testid="ingredient-pallet-po">
                    {groupInfo.netsuiteOrderNumber}
                  </div>
                </MainTableCell>
                <MainTableCell>
                  <span>{groupInfo.productDateType}: </span>
                  <span>{formatDateAsDDMMYY(groupInfo.productDate)}</span>
                </MainTableCell>
                <MainTableCell icon>
                  {groupInfo.hasFullCheckPallet && (
                    <StatusIcon iconType="filledCheck" />
                  )}
                </MainTableCell>
                <MainTableCell
                  greenText={
                    groupInfo.checkedPallets === groupInfo.totalPallets
                  }
                >
                  {`${groupInfo.checkedPallets}/${groupInfo.totalPallets}`}
                </MainTableCell>
              </MainTableRow>
              <SecondaryTable colSpan={7}>
                <SecondaryTableHeadRow>
                  <SecondaryTableHead>Status</SecondaryTableHead>
                  <SecondaryTableHead>Lot #</SecondaryTableHead>
                  <SecondaryTableHead>Received At</SecondaryTableHead>
                  <SecondaryTableHead>Quantity</SecondaryTableHead>
                  <SecondaryTableHead>Unit Sizes</SecondaryTableHead>
                  <SecondaryTableHead>Brand</SecondaryTableHead>
                  {/* below items are used to force correct spacing/layout */}
                  <SecondaryTableHead />
                  <SecondaryTableHead />
                </SecondaryTableHeadRow>
                <TableBody data-testid="secondary-table-body">
                  {groupPallets.map((pallet) => (
                    <TableRow
                      key={pallet.id}
                      onClick={() => {
                        onClickPallet(pallet);
                      }}
                    >
                      <TableCell classes={{ root: classes.secondaryIconCell }}>
                        <StatusIcon iconType={statusIconMap[pallet.status]} />
                      </TableCell>
                      <TableCell>{pallet.id}</TableCell>
                      <TableCell>{pallet.createdAt}</TableCell>
                      <TableCell>{pallet.quantity}</TableCell>
                      <TableCell>{pallet.measuringUnit}</TableCell>
                      <TableCell>{pallet.brand}</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ))}
                </TableBody>
              </SecondaryTable>
            </CollapsibleArea>
          ))}
        </TableBody>
      </MainTable>
    </>
  );
};

export default withStyles(styles)(IngredientsPalletsTable);
