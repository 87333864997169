import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {
  updateTruck,
  createTruck,
  toggleTruckDrawer,
  clearPreselectedTruckOrder,
} from 'redux/truck/actions';

import styles from './styles';
import TruckSubmissionForm from './TruckSubmissionForm';

const mapStateToProps = (state) => ({
  truck: state.trucks.receiving.selectedTruck,
  preselectedNO: state.trucks.receiving.preselectedNOForForm,
});

const mapDispatchToProps = {
  updateTruck: updateTruck,
  createTruck: createTruck,
  toggleTruckDrawer: toggleTruckDrawer,
  clearPreselected: clearPreselectedTruckOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TruckSubmissionForm));
