export const HOME_BUTTON = 'HOME_BUTTON';
export const PROGRESS_TRACKER = 'PROGRESS_TRACKER';
export const BACK_BUTTON = 'BACK_BUTTON';
export const TEXT = 'TEXT';
export const QA_APP = 'QA_APP';
export const ACCOUNT_DROPDOWN = 'ACCOUNT_DROPDOWN';
export const PALLET_ACTIONS = 'PALLET_ACTIONS';
export const INVENTORY_MANAGER_ACTIONS = 'INVENTORY_MANAGER_ACTIONS';
export const STANDARD_MENU_BUTTONS = 'STANDARD_MENU_BUTTONS';
export const EXTENDED_MENU_DISPLAY = 'EXTENDED_MENU_DISPLAY';
export const QR_CODE = 'QR_CODE';
export const WAREHOUSE_MOVES_CONTENT = 'WAREHOUSE_MOVES_CONTENT';
export const OPEN_ITEM_MOVES_REQUEST_CONTENT =
  'OPEN_ITEM_MOVES_REQUEST_CONTENT';
export const QR_CODE_WITH_TEXT = 'QR_CODE_WITH_TEXT';
export const ITEM_MANAGER = 'ITEM_MANAGER';
