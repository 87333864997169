import {
  HC_GREEN,
  WHITE,
  BLACK,
  GREY_100,
  GREY_200,
  ERROR_RED,
} from 'lib/constants';
import { centeredLoading } from 'lib/styles';

const panel = {
  width: '50%',
  display: 'inline-block',
};

export default {
  centeredLoading,
  container: {
    padding: 0,
    height: '100vh',
  },
  leftGrid: {
    height: '94vh',
    padding: '5px 10px 0 0',
  },
  leftPanel: {
    ...panel,
  },
  rightPanel: {
    background: GREY_100,
    marginTop: '-10px',
    minHeight: '100vh',
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusCard: {
    minWidth: '85%',
  },
  error: {
    color: WHITE,
    background: ERROR_RED,
  },
  disabled: {
    color: BLACK,
    background: GREY_200,
  },
  success: {
    color: WHITE,
    background: HC_GREEN,
  },
};
