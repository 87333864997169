import { connect } from 'react-redux';

import { fetchStorageAreas } from 'redux/storageArea/actions';
import { getLicensePlatePrinters } from 'redux/printers/actions';

import WarehouseInventory from './WarehouseInventory';

const mapDispatchToProps = {
  onFetchStorageAreas: fetchStorageAreas,
  onFetchPrinters: getLicensePlatePrinters,
};

export default connect(null, mapDispatchToProps)(WarehouseInventory);
