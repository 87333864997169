import React from 'react';
import PropTypes from 'prop-types';

import ProductionPlansCard from './ProductionPlansCard';
import MealInformation from './MealInformation';
import TeamDetailsCard from './TeamDetailsCard';
import IngredientsCard from './IngredientsCard';

const ProductionSheet = ({
  productionPlans,
  fetchingProdPlans,
  shiftNames,
  menuSelection,
  classes,
  match,
}) => {
  return (
    <>
      <MealInformation menuSelection={menuSelection} classes={classes} />
      <TeamDetailsCard
        classes={classes}
        productionPlans={productionPlans}
        fetching={fetchingProdPlans}
        match={match}
        menuSelection={menuSelection}
      />
      <ProductionPlansCard
        productionPlans={productionPlans}
        fetching={fetchingProdPlans}
        shiftNames={shiftNames}
        classes={classes}
      />
      <IngredientsCard menuSelection={menuSelection} classes={classes} />
    </>
  );
};

ProductionSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchingProdPlans: PropTypes.bool.isRequired,
  productionPlans: PropTypes.array.isRequired,
  menuSelection: PropTypes.object.isRequired,
};

export default ProductionSheet;
