import { SHIFTS } from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import Shift from './services';

export const getShifts = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: SHIFTS,
      method: Shift.getShifts,
      params,
    })
  );
};
