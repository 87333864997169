import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import { Prop12PhotoViewerTaker } from '../PhotoViewerTaker';
import styles from './styles';
import { useValidator } from '../FsqaReviewForms/formHelpers';

const useStyles = makeStyles(styles);

const Prop12Photo = ({ palletId, prop12PhotosLength, validationCallback }) => {
  const classes = useStyles();

  useValidator(prop12PhotosLength > 0, validationCallback);

  return (
    <>
      <span className={classes.infoLabel}>NON-PROP 12 PHOTO</span>
      <Prop12PhotoViewerTaker palletId={palletId} />
    </>
  );
};

Prop12Photo.propTypes = {
  palletId: PropTypes.number.isRequired,
  prop12PhotosLength: PropTypes.number.isRequired,
  validationCallback: PropTypes.func.isRequired,
};

export default Prop12Photo;
