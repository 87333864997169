import { GREY_600, ROBOTO } from 'lib/constants';

export default {
  count: {
    fontFamily: ROBOTO,
    fontSize: '24px',
    lineHeight: '133%',
    marginTop: '4px',
  },
  editIcon: {
    color: GREY_600,
    fontSize: '20px',
    padding: '2px 6px 0px 6px',
  },
  input: {
    display: 'flex',
    fontFamily: ROBOTO,
    fontSize: '16px',
    lineHeight: '100%',
    maxWidth: '80px',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  saveButton: {
    fontFamily: ROBOTO,
    fontWeight: 600,
  },
};
