import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetTopBarToDefault } from 'redux/topBar/actions';

import AppLandingPage from './AppLandingPage';

const mapDispatchToProps = {
  onResetTopBarToDefault: resetTopBarToDefault,
};

export default withRouter(connect(null, mapDispatchToProps)(AppLandingPage));
