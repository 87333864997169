import React, { useState, useEffect, useCallback, createContext } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import styles from './styles';

export const StickyBarContext = createContext(false);

const TOP = 'top';
const BOTTOM = 'bottom';

const StickyBarProvider = ({
  children,
  stickyYCoord,
  stickyPosition,
  classes,
}) => {
  const [sticky, setSticky] = useState(stickyYCoord === 0);

  const handleScroll = useCallback(() => {
    let offsetTop = window.pageYOffset;

    offsetTop >= stickyYCoord ? setSticky(true) : setSticky(false);
  }, [stickyYCoord]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <StickyBarContext.Provider value={sticky}>
      <div
        className={classnames({
          [classes.stickyTopStyles]: sticky && stickyPosition === TOP,
          [classes.stickyBottomStyles]: sticky && stickyPosition === BOTTOM,
        })}
      >
        {children}
      </div>
    </StickyBarContext.Provider>
  );
};

StickyBarProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  stickyYCoord: PropTypes.number,
  stickyPosition: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

StickyBarProvider.defaultProps = {
  stickyYCoord: 0,
  stickyPosition: TOP,
};

export default withStyles(styles)(StickyBarProvider);
