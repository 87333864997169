import {
  GUIDED_PICKING_OVERRIDE_POST_REQUEST,
  GUIDED_PICKING_OVERRIDE_POST_SUCCESS,
  GUIDED_PICKING_OVERRIDE_POST_ERROR,
} from 'lib/constants';

export const initialState = {
  posting: false,
  postingFailed: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case GUIDED_PICKING_OVERRIDE_POST_REQUEST:
      return {
        ...state,
        posting: true,
      };
    case GUIDED_PICKING_OVERRIDE_POST_SUCCESS:
      return {
        ...state,
        posting: false,
      };
    case GUIDED_PICKING_OVERRIDE_POST_ERROR:
      return {
        ...state,
        posting: false,
        postingFailed: true,
      };
    default:
      return state;
  }
}
