import { HC_GREEN, GREY_200 } from 'lib/constants';

export default {
  crumb: {
    marginRight: '15px',
  },
  link: {
    color: HC_GREEN,
    textDecoration: 'none',
  },
  separator: {
    marginRight: '15px',
    color: GREY_200,
  },
  currentTab: {
    fontWeight: 800,
  },
  backArrow: {
    color: HC_GREEN,
    marginBottom: '-6px',
    marginRight: '15px',
  },
};
