import { linkTabStyling } from 'lib/styles';

export default {
  ...linkTabStyling,
  link: {
    ...linkTabStyling.link,
    fontSize: '16px',
  },
  linkContainer: {
    ...linkTabStyling.linkContainer,
    height: '40px',
    padding: '10px',
  },
};
