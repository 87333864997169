import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { getIngredientAssignmentsForMenuSelection } from 'redux/ingredientAssignments/actions';
import { postMealBagInventory } from 'redux/productionInventories/mealBagInventory/actions';

import styles from './styles';
import MealBagging from './MealBagging';

const mapStateToProps = (state) => ({
  podLocations: state.podLocations.pods,
  printers: state.printers.printers,
  mealBagInventory: state.mealBagInventory,
  selectedMenu: state.menus.selectedMenu,
  posting: state.mealBagInventory.posting,
  errors: state.mealBagInventory.errors,
});

const mapDispatchToProps = {
  onPostMealBagInventory: postMealBagInventory,
  onGetIngredientAssignments: getIngredientAssignmentsForMenuSelection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MealBagging));
