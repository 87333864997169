import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';

import styles from '../styles';

const QaErrorCount = ({ error, classes }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={8} lg={10}>
          <h3 className={classes.cardHeading}>{error.description}</h3>
          <h4 className={classes.cardHeading}>{error.item}</h4>
        </Grid>
        <Grid item xs={4} lg={2} className={classes.informationalCardNumber}>
          <h4 className={classes.cardHeading}>{error.occurrences}</h4>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

QaErrorCount.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.shape({
    description: PropTypes.string,
    item: PropTypes.string,
    occurrences: PropTypes.number,
  }).isRequired,
};

export default withStyles(styles)(QaErrorCount);
