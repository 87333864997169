import {
  HC_GREEN,
  WHITE,
  GREY_200,
  DARK_HC_GREEN,
  ERROR_RED,
  DARK_RED,
} from 'lib/constants';

const button = {
  height: 50,
  fontSize: 16,
  textTransform: 'none',
  marginTop: 20,
};

const buttonDisabled = {
  backgroundColor: GREY_200,
};

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 20px',
    borderBottom: `1px solid ${GREY_200}`,
    fontWeight: 'bold',
    fontSize: 20,
  },
  bodySection: {
    overflowY: 'auto',
    height: 'calc(100vh - 85px)',
  },
  button: {
    height: '55px',
    width: '80px',
    marginRight: '10px',
  },
  tempInput: {
    margin: '0 5px',
  },
  dockDoorInput: {
    margin: '0 5px',
    width: '100%',
  },
  textFieldWrapper: {
    display: 'inline-block',
    width: '190px',
    marginRight: '7px',
    marginTop: '20px',
    fontSize: '14px',
  },
  itemContainer: {
    margin: '5px 0px 20px',
    marginInlineStart: 0,
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  infoLabel: {
    fontSize: 14,
  },
  infoValue: {
    fontSize: 20,
    margin: '5px 0px 20px',
    marginInlineStart: 0,
    display: 'flex',
  },
  icon: {
    width: 20,
    height: 20,
    margin: '0 auto',
  },
  noScroll: {
    overflowY: 'hidden',
  },
  timePicker: {
    marginLeft: 5,
    marginRight: 5,
    width: 180,
  },
  textField: {
    margin: '0 5px',
    width: 370,
  },
  skinnyTextField: {
    margin: '0 5px',
  },
  buttonDisabled,
  submitButton: {
    ...button,
    borderRadius: '4px 0px 0px 4px',
    '&$buttonDisabled': buttonDisabled,
    color: WHITE,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  selectedNo: {
    backgroundColor: HC_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  selectedYes: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_RED,
    },
  },
};
