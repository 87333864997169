import React from 'react';
import PropTypes from 'prop-types';

import { LIGHT_GREEN, DARK_HC_GREEN, WHITE } from 'lib/constants';

const TruckLoadingIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.75 37.5H5.25C4.65326 37.5 4.08095 37.263 3.659 36.841C3.23704 36.419 3 35.8467 3 35.25V9C3 8.20435 3.31607 7.44133 3.87868 6.87872C4.44129 6.31611 5.20435 6 6 6H34.5C35.2957 6 36.0587 6.31611 36.6213 6.87872C37.1839 7.44133 37.5 8.20435 37.5 9V19.785L43.3425 22.7025C43.8409 22.9519 44.26 23.3352 44.5528 23.8095C44.8455 24.2837 45.0004 24.8302 45 25.3876V35.25C45 35.8467 44.763 36.419 44.341 36.841C43.919 37.263 43.3467 37.5 42.75 37.5ZM6 7.5C5.60218 7.5 5.22064 7.65806 4.93934 7.93936C4.65803 8.22067 4.5 8.60218 4.5 9V35.25C4.5 35.4489 4.57901 35.6397 4.71966 35.7804C4.86031 35.921 5.05109 36 5.25 36H42.75C42.9489 36 43.1397 35.921 43.2803 35.7804C43.421 35.6397 43.5 35.4489 43.5 35.25V25.3876C43.4999 25.1085 43.4218 24.835 43.2748 24.5978C43.1277 24.3607 42.9174 24.1692 42.6675 24.045L36.825 21.1275C36.5765 21.0023 36.3677 20.8105 36.222 20.5734C36.0763 20.3363 35.9995 20.0633 36 19.785V9C36 8.60218 35.8419 8.22067 35.5606 7.93936C35.2793 7.65806 34.8978 7.5 34.5 7.5H6Z"
          style={{ fill: DARK_HC_GREEN }}
        />
        <path
          d="M12.75 41.25C15.2353 41.25 17.25 39.2353 17.25 36.75C17.25 34.2647 15.2353 32.25 12.75 32.25C10.2647 32.25 8.25 34.2647 8.25 36.75C8.25 39.2353 10.2647 41.25 12.75 41.25Z"
          style={{ fill: WHITE }}
        />
        <path
          d="M12.75 42.7501C11.5633 42.7501 10.4033 42.3982 9.41657 41.739C8.42988 41.0797 7.66084 40.1426 7.20672 39.0462C6.75259 37.9499 6.63378 36.7434 6.86529 35.5796C7.09681 34.4157 7.66825 33.3465 8.50736 32.5074C9.34648 31.6683 10.4156 31.0969 11.5795 30.8654C12.7433 30.6339 13.9497 30.7527 15.0461 31.2068C16.1425 31.6609 17.0795 32.4299 17.7388 33.4166C18.3981 34.4033 18.75 35.5634 18.75 36.7501C18.75 38.3414 18.1179 39.8675 16.9927 40.9927C15.8674 42.1179 14.3413 42.7501 12.75 42.7501ZM12.75 33.7501C12.1567 33.7501 11.5766 33.9261 11.0833 34.2557C10.5899 34.5854 10.2054 35.0539 9.97836 35.6021C9.7513 36.1503 9.69188 36.7534 9.80764 37.3354C9.92339 37.9173 10.2091 38.4519 10.6287 38.8714C11.0482 39.291 11.5828 39.5767 12.1647 39.6925C12.7467 39.8082 13.3499 39.7488 13.898 39.5217C14.4462 39.2947 14.9148 38.9101 15.2444 38.4168C15.5741 37.9234 15.75 37.3434 15.75 36.7501C15.75 35.9544 15.4339 35.1914 14.8713 34.6288C14.3087 34.0662 13.5457 33.7501 12.75 33.7501Z"
          style={{ fill: DARK_HC_GREEN }}
        />
        <path
          d="M35.25 41.25C37.7353 41.25 39.75 39.2353 39.75 36.75C39.75 34.2647 37.7353 32.25 35.25 32.25C32.7647 32.25 30.75 34.2647 30.75 36.75C30.75 39.2353 32.7647 41.25 35.25 41.25Z"
          style={{ fill: WHITE }}
        />
        <path
          d="M35.25 42.7501C34.0633 42.7501 32.9032 42.3982 31.9165 41.739C30.9298 41.0797 30.1608 40.1426 29.7067 39.0462C29.2525 37.9499 29.1337 36.7434 29.3652 35.5796C29.5967 34.4157 30.1682 33.3465 31.0073 32.5074C31.8464 31.6683 32.9155 31.0969 34.0794 30.8654C35.2433 30.6339 36.4497 30.7527 37.5461 31.2068C38.6424 31.6609 39.5795 32.4299 40.2388 33.4166C40.8981 34.4033 41.25 35.5634 41.25 36.7501C41.25 38.3414 40.6178 39.8675 39.4926 40.9927C38.3674 42.1179 36.8413 42.7501 35.25 42.7501ZM35.25 33.7501C34.6566 33.7501 34.0766 33.9261 33.5832 34.2557C33.0899 34.5854 32.7054 35.0539 32.4783 35.6021C32.2513 36.1503 32.1918 36.7534 32.3076 37.3354C32.4234 37.9173 32.7091 38.4519 33.1286 38.8714C33.5482 39.291 34.0827 39.5767 34.6647 39.6925C35.2466 39.8082 35.8498 39.7488 36.398 39.5217C36.9462 39.2947 37.4147 38.9101 37.7444 38.4168C38.074 37.9234 38.25 37.3434 38.25 36.7501C38.25 35.9544 37.9339 35.1914 37.3713 34.6288C36.8087 34.0662 36.0456 33.7501 35.25 33.7501Z"
          style={{ fill: DARK_HC_GREEN }}
        />
        <path
          d="M12.75 39.75C14.4069 39.75 15.75 38.4069 15.75 36.75C15.75 35.0931 14.4069 33.75 12.75 33.75C11.0931 33.75 9.75 35.0931 9.75 36.75C9.75 38.4069 11.0931 39.75 12.75 39.75Z"
          style={{ fill: LIGHT_GREEN }}
        />
        <path
          d="M35.25 39.75C36.9069 39.75 38.25 38.4069 38.25 36.75C38.25 35.0931 36.9069 33.75 35.25 33.75C33.5931 33.75 32.25 35.0931 32.25 36.75C32.25 38.4069 33.5931 39.75 35.25 39.75Z"
          style={{ fill: LIGHT_GREEN }}
        />
        <path
          d="M32.28 21.0975H26.2125C25.8211 21.0976 25.4451 20.9448 25.1649 20.6715C24.8846 20.3983 24.7223 20.0263 24.7125 19.635V13.5601C24.7125 13.1622 24.8705 12.7806 25.1518 12.4993C25.4331 12.218 25.8147 12.0601 26.2125 12.0601H32.28C32.6778 12.0601 33.0594 12.218 33.3407 12.4993C33.622 12.7806 33.78 13.1622 33.78 13.5601V19.635C33.7702 20.0263 33.6079 20.3983 33.3276 20.6715C33.0474 20.9448 32.6714 21.0976 32.28 21.0975ZM32.28 13.5975H26.28V19.635L32.28 19.5975V13.5975Z"
          style={{ fill: DARK_HC_GREEN }}
        />
      </svg>
    </div>
  );
};

TruckLoadingIcon.propTypes = {
  className: PropTypes.string,
};

TruckLoadingIcon.defaultProps = {
  className: 'TruckLoading',
};

export default TruckLoadingIcon;
