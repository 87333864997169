import { BORDER_GREY } from 'lib/constants';

import {
  arrowIcon,
  cell,
  cellLast,
  header,
  zebraRow,
  clickableRows,
} from '../styles';

export default {
  arrowIcon,
  cell,
  cellLast,
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  header,
  zebraRow,
  clickableRows,
};
