import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import orderBy from 'lodash/orderBy';

import { formatNumber, minuteDiffFromNow } from 'lib/utils';
import { isStaleSubmission } from 'lib/viewHelpers';

import ActivityProductionCount from './ActivityProductionCount';
import styles from '../styles';

const TotalProductionCard = ({
  classes,
  lastProductionSubmittedAt,
  totalProductionCount,
  activities,
}) => {
  const [minutesSubmittedAgo, setMinutesSubmittedAgo] = useState(
    minuteDiffFromNow(lastProductionSubmittedAt)
  );

  const [isSubmissionStale, setIsStaleSubmission] = useState(false);

  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    let timer = setInterval(
      () =>
        setMinutesSubmittedAgo(minuteDiffFromNow(lastProductionSubmittedAt)),
      60000
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setMinutesSubmittedAgo(minuteDiffFromNow(lastProductionSubmittedAt));
  }, [lastProductionSubmittedAt]);

  useEffect(() => {
    setIsStaleSubmission(isStaleSubmission(minutesSubmittedAgo));
  }, [minutesSubmittedAgo]);

  const handleClick = () => setExpanded(!expanded);

  const defaultDetails = () => {
    return (
      <CardActionArea
        onClick={handleClick}
        className={classes.clickableInformationalCardArea}
      >
        <Grid container spacing={3}>
          <Grid item lg={8} xs={6}>
            <h3 className={classes.cardHeading}>Total Production:</h3>
          </Grid>
          <Grid item lg={4} xs={6} className={classes.informationalCardNumber}>
            <h3 className={classes.cardHeading}>
              {formatNumber(totalProductionCount)}
            </h3>
          </Grid>
        </Grid>
        <div
          className={classNames(classes.cardLastSubmitted, {
            [classes.staleSubmissionColor]: isSubmissionStale,
            [classes.freshSubmissionColor]: !isSubmissionStale,
          })}
        >
          Last submitted: {minutesSubmittedAgo} min
        </div>
      </CardActionArea>
    );
  };

  const activityDetails = () => {
    if (activities.length > 1) {
      return orderBy(activities, 'lastProductionSubmittedAt', 'desc').map(
        (activity) => {
          return (
            <ActivityProductionCount key={activity.name} activity={activity} />
          );
        }
      );
    }
  };

  return (
    <Card
      className={classNames(
        classes.informationalCard,
        classes.clickableInformationalCard,
        classes.rightColInformationalCard
      )}
    >
      {defaultDetails()}
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        classes={{
          root: activities.length > 1 && classes.expandableArea,
        }}
      >
        {activityDetails()}
      </Collapse>
    </Card>
  );
};

TotalProductionCard.propTypes = {
  classes: PropTypes.object.isRequired,
  lastProductionSubmittedAt: PropTypes.string,
  totalProductionCount: PropTypes.number,
  activities: PropTypes.arrayOf(PropTypes.object),
};

TotalProductionCard.defaultProps = {
  activities: [],
  lastProductionSubmittedAt: undefined,
  totalProductionCount: 0,
};

export default withStyles(styles)(TotalProductionCard);
