import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import withStyles from '@mui/styles/withStyles';

import { FULL_CHECK_COMPLETED, PARTIAL_CHECK_COMPLETED } from 'lib/constants';
import { Loading } from 'components/shared';

import IngredientForm from '../FsqaReviewForms/IngredientForm';
import PackagingForm from '../FsqaReviewForms/PackagingForm';
import styles from './styles';

const PalletDrawer = ({
  classes,
  closeDrawer,
  open,
  pallet,
  fetchFsqaPhotos,
  clearFSQAForm,
}) => {
  useEffect(() => {
    if (pallet.id) {
      fetchFsqaPhotos(pallet.id);
    }
  }, [fetchFsqaPhotos, pallet.id]);

  const onCloseDrawer = () => {
    pallet.status !== FULL_CHECK_COMPLETED &&
      pallet.status !== PARTIAL_CHECK_COMPLETED &&
      clearFSQAForm(pallet.id);
    closeDrawer();
  };

  const renderForm = () =>
    pallet.orderableType == 'Ingredient' ? (
      <IngredientForm pallet={pallet} closeDrawer={closeDrawer} />
    ) : (
      <PackagingForm pallet={pallet} closeDrawer={closeDrawer} />
    );

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onCloseDrawer}
      classes={{ paper: classes.noScroll }}
    >
      {pallet.id ? (
        renderForm()
      ) : (
        <div className={classes.root}>
          <Loading />
        </div>
      )}
    </Drawer>
  );
};

PalletDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  pallet: PropTypes.object,
  fetchFsqaPhotos: PropTypes.func.isRequired,
  clearFSQAForm: PropTypes.func,
};

PalletDrawer.defaultProps = {
  pallet: null,
  clearFSQAForm: {},
};

export default withStyles(styles)(PalletDrawer);
