import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import styles from './styles';

export const shadingClassName = (numLeft, className, completedDay) => {
  return numLeft < 1 ? `${className} ${completedDay}` : className;
};

const BaggingDetails = (props) => {
  const { classes, daySplitGoals } = props;

  return (
    <div className="BaggingDetails">
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.row}>
            {daySplitGoals.map((goal) => {
              return (
                <React.Fragment key={goal.day}>
                  <TableCell
                    colSpan={1}
                    className={shadingClassName(
                      goal.num_left_to_complete,
                      classes.dayName,
                      classes.completedDay
                    )}
                  >
                    {goal.day}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    className={shadingClassName(
                      goal.num_left_to_complete,
                      classes.percentComplete,
                      classes.completedDay
                    )}
                  >
                    {goal.percent_completed}%
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
          <TableRow className={classes.headerRow}>
            {daySplitGoals.map((goal) => {
              return (
                <React.Fragment key={goal.day}>
                  <TableCell
                    className={shadingClassName(
                      goal.num_left_to_complete,
                      classes.cell,
                      classes
                    )}
                  >
                    LEFT
                  </TableCell>
                  <TableCell
                    className={shadingClassName(
                      goal.num_left_to_complete,
                      classes.lastCell,
                      classes.completedDay
                    )}
                  >
                    TOTAL
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.headerRow}>
            {daySplitGoals.map((goal) => {
              return (
                <React.Fragment key={goal.day}>
                  <TableCell
                    className={classNames(
                      shadingClassName(
                        goal.num_left_to_complete,
                        classes.cell,
                        classes.completedDay
                      ),
                      classes.cellBigText
                    )}
                  >
                    {goal.num_left_to_complete?.toLocaleString()}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      shadingClassName(
                        goal.num_left_to_complete,
                        classes.lastCell,
                        classes.completedDay
                      ),
                      classes.cellBigText
                    )}
                  >
                    {goal.total_to_complete_for_day?.toLocaleString()}
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

BaggingDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  daySplitGoals: PropTypes.array.isRequired,
};

export default withStyles(styles)(BaggingDetails);
