import {
  HC_GREEN,
  GREY_500,
  GREY_200,
  WHITE,
  MEDIA_QUERY_LARGER_THAN_PHONE,
} from 'lib/constants';

export default {
  calculatorTitle: {
    alignSelf: 'start',
  },
  calculatorSubtitle: {
    alignSelf: 'start',
    float: 'left',
    fontSize: '18px',
    color: GREY_500,
  },
  openButton: {
    padding: '3px',
    color: HC_GREEN,
  },
  openIcon: {
    width: '30px',
    height: '30px',
  },
  dialog: {
    margin: '10px',
  },
  inputValue: {
    display: 'flex',
  },
  dialogContent: {
    height: '270px',
    width: '240px',
    padding: '30px',
    fontFamily: 'Muli',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonDisabled: {
    color: GREY_200,
  },
  selectedOperator: {
    background: HC_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: HC_GREEN,
    },
  },
  mathProblem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100px',
    justifyContent: 'space-between',
  },
  modifier: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  quantityInputRoot: {
    width: '100px',
    marginLeft: '18px',
    height: '100%',
  },
  quantityInput: {
    height: '100%',
  },
  input: {
    padding: '5px',
    textAlign: 'right',
    // Removes arrows from number input
    '&::-webkit-inner-spin-button': {
      '::-webkit-appearance': 'none',
      '::-moz-appearance': 'none',
      appearance: 'none',
    },
    fontSize: '18px',
  },
  smallerFont: {
    fontSize: '16px',
  },
  line: {
    width: '220px',
    margin: '0 0 4px 0',
  },
  setValueButton: {
    marginLeft: '5px',
  },
  [MEDIA_QUERY_LARGER_THAN_PHONE]: {
    mathProblem: {
      height: '200px',
    },
    dialogContent: {
      height: '400px',
      width: '470px',
      padding: '40px',
      fontSize: '26px',
    },
    input: {
      fontSize: '26px',
    },
    setValueButton: {
      marginLeft: '15px',
    },
  },
};
