import { useEffect, useState, useRef } from 'react';

import { usePrevious } from 'lib/custom_hooks';

const useSnackbarQueue = (loading, failure, messages) => {
  const queue = useRef([]);

  const [open, setOpen] = useState(false);

  const [info, setInfo] = useState({});

  const processQueue = () => {
    if (queue.current.length > 0) {
      const newSnackbarInfo = queue.current.shift();

      setInfo(newSnackbarInfo);
      setOpen(true);
    }
  };

  const prevLoading = usePrevious(loading);

  useEffect(() => {
    const handleSnackBar = (message, variant) => {
      queue.current.push({
        message,
        variant,
        key: new Date().getTime(),
      });

      if (open) {
        // immediately begin dismissing current message
        // to start showing new one
        setOpen(false);
      } else {
        processQueue();
      }
    };

    if (!prevLoading && loading) {
      handleSnackBar(messages.loading, null);
    } else if (prevLoading && !loading && failure) {
      handleSnackBar(messages.failure, 'error');
    } else if (prevLoading && !loading && !failure) {
      handleSnackBar(messages.success, 'success');
    }
  }, [
    prevLoading,
    loading,
    failure,
    messages.success,
    messages.failure,
    messages.loading,
    open,
  ]);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    processQueue();
  };

  return {
    open,
    handleClose,
    handleExited,
    info,
  };
};

export default useSnackbarQueue;
