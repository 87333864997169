import sortBy from 'lodash/sortBy';

const SUBMISSION_MINUTE_INTERVAL = 30;

export function isStaleSubmission(minutes) {
  return minutes >= SUBMISSION_MINUTE_INTERVAL;
}

export function sortByLabelOrder(items) {
  return sortBy(items, (item) => {
    if (item.label_order) {
      return [
        item.label_order.replace(/[0-9]/g, ''),
        item.label_order.length,
        item.label_order,
      ];
    } else {
      return (
        item.labelOrder && [
          item.labelOrder.replace(/[0-9]/g, ''),
          item.labelOrder.length,
          item.labelOrder,
        ]
      );
    }
  });
}

export function sortByLetterAssignment(items) {
  return sortBy(items, (item) => {
    if (item.letterAssignment) {
      return [
        item.letterAssignment.replace(/[0-9]/g, ''),
        item.letterAssignment.length,
        item.letterAssignment,
      ];
    } else {
      return (
        item.letter_assignment && [
          item.letter_assignment.replace(/[0-9]/g, ''),
          item.letter_assignment.length,
          item.letter_assignment,
        ]
      );
    }
  });
}

export function sortByName(names) {
  return sortBy(names, (name) => {
    return (
      name.name && [
        name.name.replace(/[0-9]/g, ''),
        name.name.length,
        name.name,
      ]
    );
  });
}

export * from './fsqa';
export * from './moveRequests';
