import { useReducer } from 'react';

export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const UPDATE_MEASURING_UNIT = 'UPDATE_MEASURING_UNIT';
export const UPDATE_PRODUCT_DATE_TYPE = 'UPDATE_PRODUCT_DATE_TYPE';
export const UPDATE_PRODUCT_DATE = 'UPDATE_PRODUCT_DATE';
export const SELECT_STORAGE_SLOT = 'SELECT_STORAGE_SLOT';
export const SET_SELECTED_ITEM_ID = 'SET_SELECTED_ITEM_ID';
export const CLEAR_SLOT_INFO = 'CLEAR_SLOT_INFO';
export const SET_LOADING = 'SET_LOADING';
export const CHANGE_FILTER = 'CHANGE_FILTER';

// since not every slot has a pallet and multiple pallets can be in one slot,
// the only unique identifier for a given item is the slotName/palletId combo
export const formatItemId = ({ slotName, palletId }) =>
  `${slotName}_${palletId}`;

export const sidebarFilterOptions = [
  { value: 'showAll', label: 'Show All Locations' },
  { value: 'showCountItems', label: 'Show Count Items' },
  { value: 'showUncompleted', label: 'Show Uncompleted' },
  { value: 'showGroundPosition', label: 'Show Ground Position' },
];

export const initialState = {
  pallet: {},
  countListAssignmentId: '',
  selectedItemId: '',
  quantity: '',
  measuringUnitId: '',
  measuringUnit: '',
  storageAreaName: '',
  storageSlotName: '',
  storageSlotId: '',
  productDateType: '',
  productDate: '',
  shouldAutoScroll: false,
  loading: true, // just used for initial loading
  appliedFilter: sidebarFilterOptions[0].value,
};

export const reducer = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    // selecting the storage slot will populate all the data for that storage slot
    case SELECT_STORAGE_SLOT: {
      const {
        pallet,
        countListAssignmentId,
        storageAreaName,
        storageSlotName,
        storageSlotId,
      } = action.storageSlotInfo;
      return {
        ...state,
        selectedItemId: formatItemId({
          slotName: storageSlotName,
          palletId: pallet.id,
        }),
        pallet,
        countListAssignmentId,
        storageAreaName,
        storageSlotName,
        storageSlotId,
        quantity: pallet.quantity || '',
        measuringUnitId: pallet.measuringUnitId || '',
        measuringUnit: pallet.measuringUnit || '',
        productDateType: pallet.productDateType || '',
        productDate: pallet.productDate || '',
        shouldAutoScroll: action.shouldAutoScroll,
      };
    }
    // setting the selected item id just informs the app which item should be auto selected
    case SET_SELECTED_ITEM_ID: {
      return {
        ...state,
        selectedItemId: action.itemId,
        shouldAutoScroll: action.shouldAutoScroll,
      };
    }
    case UPDATE_QUANTITY:
      return {
        ...state,
        quantity: action.quantity,
      };
    case UPDATE_MEASURING_UNIT:
      return {
        ...state,
        measuringUnitId: action.measuringUnitId,
        measuringUnit: action.measuringUnit,
      };
    case UPDATE_PRODUCT_DATE_TYPE:
      return {
        ...state,
        productDateType: action.productDateType,
      };
    case UPDATE_PRODUCT_DATE:
      return {
        ...state,
        productDate: action.productDate,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CHANGE_FILTER:
      return {
        ...state,
        appliedFilter: action.appliedFilter,
      };
    case CLEAR_SLOT_INFO: {
      return initialState;
    }
    default:
      return state;
  }
};

export const useCycleCountingState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectStorageSlot = ({ storageSlotInfo, shouldAutoScroll }) => {
    dispatch({ type: SELECT_STORAGE_SLOT, storageSlotInfo, shouldAutoScroll });
  };

  const setSelectedItemId = ({ itemId, shouldAutoScroll }) => {
    dispatch({ type: SET_SELECTED_ITEM_ID, itemId, shouldAutoScroll });
  };

  const updateQuantity = (quantity) => {
    dispatch({ type: UPDATE_QUANTITY, quantity });
  };

  const updateMeasuringUnit = (id, name) => {
    dispatch({
      type: UPDATE_MEASURING_UNIT,
      measuringUnitId: id,
      measuringUnit: name,
    });
  };

  const updateProductDateType = (productDateType) => {
    dispatch({ type: UPDATE_PRODUCT_DATE_TYPE, productDateType });
  };

  const updateProductDate = (productDate) => {
    dispatch({ type: UPDATE_PRODUCT_DATE, productDate });
  };

  const setLoading = (loading) => {
    dispatch({ type: SET_LOADING, loading });
  };

  const changeFilter = (filter) => {
    dispatch({ type: CHANGE_FILTER, appliedFilter: filter });
  };

  const clearSlotInfo = () => {
    dispatch({ type: CLEAR_SLOT_INFO });
  };

  return {
    state,
    selectStorageSlot,
    setSelectedItemId,
    updateQuantity,
    updateMeasuringUnit,
    updateProductDate,
    updateProductDateType,
    setLoading,
    changeFilter,
    clearSlotInfo,
  };
};
