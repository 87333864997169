import { makeApiRequest } from 'lib/api';
import {
  MENU_SELECTIONS,
  MEALS_FOR_INGREDIENT_ASSIGNMENT,
  CLEAR_MEALS_FOR_INGREDIENT_ASSIGNMENT,
} from 'lib/constants';

import MenuSelections from './services';

export const getMenuSelections = (menuId, forRth = '') => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MENU_SELECTIONS,
        method: MenuSelections.getMenuSelections,
        params: { menuId, forRth },
      })
    );
  };
};

export const getMealsForIngredientAssignment = (
  ingredientAssignmentIds,
  activity
) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEALS_FOR_INGREDIENT_ASSIGNMENT,
        method: MenuSelections.getMealsForIngredientAssignment,
        params: {
          ingredientAssignmentIds: ingredientAssignmentIds,
          activity: activity,
        },
      })
    );
  };
};

export const clearMealsForIngredientAssignment = () => {
  return {
    type: CLEAR_MEALS_FOR_INGREDIENT_ASSIGNMENT,
  };
};
