import {
  POD_STATUSES_REQUEST,
  POD_STATUSES_SUCCESS,
  POD_STATUSES_UPDATED,
  POD_STATUS_ERROR,
  POD_STATUS_REQUEST,
  POD_STATUS_SUCCESS,
  SELECTED_POD_UPDATED,
} from 'lib/constants';

export const initialState = {
  results: [],
  fetching: true,
  selectedPod: {},
  fetchFailed: false,
  statusText: null,
};

const newPodStatuses = (podStatuses, newPodStatus) => {
  return podStatuses.map((pod) => {
    if (
      pod.packingFacilityInternalLocationId ===
      newPodStatus.packingFacilityInternalLocationId
    ) {
      return newPodStatus;
    } else {
      return pod;
    }
  });
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case POD_STATUSES_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case POD_STATUSES_SUCCESS:
      return {
        ...state,
        results: action.response.podStatuses,
        fetchFailed: false,
        fetching: false,
      };
    case POD_STATUSES_UPDATED:
      return {
        ...state,
        results: newPodStatuses(state.results, action.payload.podStatus),
      };
    case POD_STATUS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case POD_STATUS_SUCCESS:
      return {
        ...state,
        selectedPod: action.response.podStatus,
        results: newPodStatuses(state.results, action.response.podStatus),
        fetching: false,
      };

    case POD_STATUS_ERROR:
      return {
        ...state,
        fetching: false,
        fetchFailed: true,
        statusText: action.response.statusText,
      };
    case SELECTED_POD_UPDATED:
      return {
        ...state,
        selectedPod: action.payload.podStatus || action.response.podStatus,
        fetching: false,
      };

    default:
      return state;
  }
}
