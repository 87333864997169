import React, { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import { formatNumber, formatDateAsDDMMYY } from 'lib/utils';
import { ACTIVE, DEACTIVATED, ON_HOLD } from 'lib/constants';

import styles from './styles';

export const DetailsColumn = ({ classes, palletHistory }) => {
  const formatSlotLocation = useCallback(
    (location) => {
      // the unicode character \u2011 is a non-breaking hyphen, it forces
      // the slot location to display on a single line instead of wrapping
      const formattedLocation =
        (location && location.replace(/-/g, '\u2011')) || 'No Location';
      const lastSpace = formattedLocation.lastIndexOf(' ');

      return (
        <Fragment>
          <div className={classes.storageArea}>
            <div className={classes.storageAreaText}>
              {formattedLocation.substring(0, lastSpace)}
            </div>
          </div>
          <span className={classes.storageSlot}>
            {formattedLocation.substring(lastSpace)}
          </span>
        </Fragment>
      );
    },
    [classes]
  );
  const statusChangeText = (newValue, oldValue) => {
    switch (newValue) {
      case ACTIVE:
        if (oldValue === ON_HOLD) {
          return 'Pallet Released';
        } else {
          return 'Pallet Activated';
        }
      case DEACTIVATED:
        return 'Pallet Deactivated';
      case ON_HOLD:
        return 'Pallet Put On Hold';
      default:
        return '';
    }
  };
  switch (palletHistory.changeType) {
    case 'location':
      return (
        <Fragment>
          <span className={classes.locationName}>
            {formatSlotLocation(palletHistory.oldValue)}
          </span>
          <ArrowIcon
            classes={{
              root: classes.arrowIcon,
            }}
          />
          <span className={classes.locationName}>
            {formatSlotLocation(palletHistory.newValue)}
          </span>
        </Fragment>
      );

    case 'quantity':
      return `${formatNumber(palletHistory.oldValue)} to ${formatNumber(
        palletHistory.newValue
      )}`;
    case 'info':
      if (palletHistory.changeReason === 'Quantity') {
        return `${formatNumber(palletHistory.oldValue)} to ${formatNumber(
          palletHistory.newValue
        )}`;
      } else if (palletHistory.changeReason === 'Reprint') {
        return 'Reprint';
      } else {
        return '';
      }
    case 'status':
      return statusChangeText(palletHistory.newValue, palletHistory.oldValue);
    case 'product_date':
      return (
        <Fragment>
          <span className={classes.locationName}>
            {formatDateAsDDMMYY(palletHistory.oldValue)}
          </span>
          <ArrowIcon
            classes={{
              root: classes.arrowIcon,
            }}
          />
          <span className={classes.locationName}>
            {formatDateAsDDMMYY(palletHistory.newValue)}
          </span>
        </Fragment>
      );
    case 'product_date_type':
      return (
        <Fragment>
          <span className={classes.locationName}>{palletHistory.oldValue}</span>
          <ArrowIcon
            classes={{
              root: classes.arrowIcon,
            }}
          />
          <span className={classes.locationName}>{palletHistory.newValue}</span>
        </Fragment>
      );
    case 'creation':
      return 'License Plate Created';
    case 'last_cycle_counted_at':
      return 'Cycle Count';
    case 'measuring_unit':
      return (
        <Fragment>
          <span className={classes.locationName}>{palletHistory.oldValue}</span>
          <ArrowIcon
            classes={{
              root: classes.arrowIcon,
            }}
          />
          <span className={classes.locationName}>{palletHistory.newValue}</span>
        </Fragment>
      );
    default:
      return '';
  }
};

const PalletHistoryItem = ({ grey, palletHistory, classes }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawerOpen = () => {
    setDrawerOpen((prevOpen) => !prevOpen);
  };

  const quantityChange = palletHistory.changeType === 'quantity';

  return (
    <Fragment>
      <ListItem
        data-testid="pallet-history-item"
        onClick={toggleDrawerOpen}
        classes={{
          root: classNames(classes.listItem, {
            [classes.grey]: grey,
          }),
        }}
      >
        <ListItemText
          classes={{
            root: classNames(classes.listItemText, classes.activityDate),
            primary: classes.listItemTextSpan,
          }}
        >
          {palletHistory.createdAt}
        </ListItemText>
        {quantityChange && (
          <ListItemText
            classes={{
              root: classNames(classes.quantityChange, classes.listItemText),
              primary: classNames(
                classes.listItemTextSpan,
                classes.dateColumn,
                {
                  [classes.negativeQuantityDifference]:
                    palletHistory.quantityDifference &&
                    palletHistory.quantityDifference[0] === '-',
                  [classes.positiveQuantityDifference]:
                    palletHistory.quantityDifference &&
                    palletHistory.quantityDifference[0] === '+',
                }
              ),
            }}
          >
            {palletHistory.quantityDifference}
          </ListItemText>
        )}
        <ListItemText
          classes={{
            root: classNames(classes.listItemText, {
              [classes.quantityDetailsColumn]: quantityChange,
              [classes.detailsColumn]: !quantityChange,
            }),
            primary: classNames(
              classes.listItemTextSpan,
              classes.detailsColumnText
            ),
          }}
        >
          <DetailsColumn palletHistory={palletHistory} classes={classes} />
        </ListItemText>
        {drawerOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={drawerOpen}>
        <div
          className={classNames(classes.extraInfoArea, {
            [classes.grey]: grey,
          })}
          data-testid="pallet-history-item-collapse-content"
        >
          <div className={classes.extraInfoText}>
            <span>{palletHistory.user}</span>
          </div>
          {palletHistory.details && (
            <div className={classes.extraInfoText}>
              <span>{palletHistory.details}</span>
            </div>
          )}
        </div>
      </Collapse>
    </Fragment>
  );
};

const palletHistoryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeType: PropTypes.string.isRequired,
  quantityDifference: PropTypes.string,
  user: PropTypes.string,
  details: PropTypes.string,
});

PalletHistoryItem.propTypes = {
  palletHistory: palletHistoryShape.isRequired,
  grey: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PalletHistoryItem);
