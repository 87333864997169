import { SHIFTS_REQUEST, SHIFTS_SUCCESS, SHIFTS_ERROR } from 'lib/constants';

export const initialState = {
  results: [],
  errors: [],
  fetching: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case SHIFTS_REQUEST:
      return {
        ...state,
        results: [],
        fetching: true,
      };
    case SHIFTS_SUCCESS:
      return {
        ...state,
        results: action.response.shifts,
        fetching: false,
      };
    case SHIFTS_ERROR:
      return {
        ...state,
        fetching: false,
        errors: state.errors.concat(action.response),
      };
    default:
      return state;
  }
}
