import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';

import { ECOMMERCE_CHANNEL } from 'lib/constants';
import { qaStyles, PodLocationQuestion } from 'components/shared';

const PreliminaryCheck = ({
  classes,
  meal,
  menuId,
  podLocations,
  setRedirectToNextStep,
  onSubmitPreliminaryCheck,
}) => {
  const [selectedPodName, setSelectedPodName] = useState('');

  const submitCheck = () => {
    onSubmitPreliminaryCheck({
      selectedPod: selectedPodName,
      ingredient: null,
      portionMethod: null,
      packaging: null,
      channel: ECOMMERCE_CHANNEL,
      menuId: menuId,
      testWeight1: null,
      testWeight2: null,
      correctiveAction: null,
      meal: meal,
    });
    setRedirectToNextStep(true);
  };

  return (
    <div className={classes.container}>
      <PodLocationQuestion
        podLocations={podLocations}
        internalLocationName={selectedPodName}
        setInternalLocationName={setSelectedPodName}
        questionText="Where are you performing this QA Check?"
      />
      <Button
        id="startCheck"
        fullWidth
        disabled={!selectedPodName}
        className={classes.startCheckButton}
        onClick={submitCheck}
      >
        Start Check
      </Button>
    </div>
  );
};

PreliminaryCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  meal: PropTypes.object.isRequired,
  menuId: PropTypes.number.isRequired,
  podLocations: PropTypes.array.isRequired,
  setRedirectToNextStep: PropTypes.func.isRequired,
  onSubmitPreliminaryCheck: PropTypes.func.isRequired,
};

export default withStyles(qaStyles)(PreliminaryCheck);
