import { makeApiRequest } from 'lib/api';
import { DOCK_DOORS } from 'lib/constants';

import DockDoors from './services';

export const fetchDockDoors = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: DOCK_DOORS,
        method: DockDoors.getDockDoors,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};
