import {
  BLACK,
  GREY_200,
  GREY_500,
  WHITE,
  HC_GREEN,
  DARK_HC_GREEN,
} from 'lib/constants';

const actionButton = {
  width: '100%',
};
export default {
  content: {
    color: BLACK,
    fontFamily: 'Muli',
  },
  dialogContent: { padding: '17px' },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: '1000',
    color: HC_GREEN,
    fontFamily: 'Muli',
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  dialogClose: {
    width: '50px',
    position: 'absolute',
    top: '0px',
    right: '-14px',
    margin: '0px',
  },
  closeIcon: {
    width: '15px',
  },
  subheader: {
    fontSize: '12px',
    textTransform: 'uppercase',
    marginBottom: '1px',
  },
  submissionDetails: {
    fontWeight: 'bold',
  },
  question: {
    margin: '20px 0 1px',
    fontFamily: 'Muli',
    paddingLeft: '10px',
  },
  cancelButton: {
    ...actionButton,
    padding: '10px 50px 10px 50px',
    '&:first-child': {
      '&:hover': {
        backgroundColor: GREY_200,
      },
      color: GREY_500,
      border: `1px solid ${GREY_200}`,
      backgroundColor: WHITE,
    },
  },
  submitButton: {
    ...actionButton,
    backgroundColor: HC_GREEN,
    color: WHITE,
    padding: '10px 50px 10px 50px',
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    '&:disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: 0,
    flex: '1 1 5%', // Ensures 2 max items per row. (flexGrow flexShrink flexBasis)
    margin: '5px !important',
    display: 'inline-block',
    fontSize: '16px',
    color: BLACK,
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
  printerButton: {
    height: '100px',
  },
};
