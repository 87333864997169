import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableFooter from '@mui/material/TableFooter';
import MuiTablePagination from '@mui/material/TablePagination';
import withStyles from '@mui/styles/withStyles';
import { makeStyles } from '@mui/styles';

import { HC_GREEN } from 'lib/constants';
import { formatNumber, QuantityContext } from 'lib/utils';

const useStyles = makeStyles(() => ({
  hcGreen: {
    color: HC_GREEN,
  },
}));

const defaultFooterStyles = {};

const CustomFooter = ({
  page,
  rowsPerPage,
  count,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions,
}) => {
  // MUI Datatables doesnt really handle adding your own custom props to the footer very well.
  // Basically, the values (or even callbacks) dont update with new props and have stale data.
  // So instead, the footer component itself is looking up to see the value rather than trusting
  // the datatables library to pass it in as a prop
  const quantitySubmitted = useContext(QuantityContext);
  const classes = useStyles();

  return (
    <MuiTableFooter>
      <MuiTableRow>
        <MuiTableCell>
          Quantity Submitted:{' '}
          <span className={classes.hcGreen}>
            {formatNumber(quantitySubmitted)}
          </span>
        </MuiTableCell>
        <MuiTablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </MuiTableRow>
    </MuiTableFooter>
  );
};

CustomFooter.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default withStyles(defaultFooterStyles, {
  name: 'CustomFooter',
})(CustomFooter);
