import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const cleanData = (data) => {
  return data.map((row) => {
    const dataRow = row.data;
    return {
      date: dataRow['Date'],
      packing_facility_id: dataRow['Packing Facility'],
      shift: dataRow['Shift'],
      menu_id: dataRow['Menu ID'],
      meal_id: dataRow['Meal ID'],
      activity: dataRow['Activity'],
      ingredient_id: dataRow['Ingredient'],
      goal_count: dataRow['Amount to Complete'].replace(/,/g, ''),
      hours: dataRow['Hours'],
      people_count: dataRow['People'],
      target_rate: dataRow['Target Rate'],
      pod_location_name: dataRow['Pod Location'],
    };
  });
};

const postProductionPlans = async (params) => {
  const response = await fetch(`${API_BASE_URI}/ops/production_plans`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ production_plans: cleanData(params) }),
  });

  return await handleResponse(response);
};

const getProductionPlans = async ({
  packingFacilityId,
  menuId,
  mealId,
  date,
}) => {
  const queryParams = `?menu_id=${menuId}&meal_id=${mealId}&date=${date}`;
  const response = await fetch(
    `${API_BASE_URI}/ops/packing_facilities/${packingFacilityId}/production_plans/${queryParams}`,
    { ...options }
  );

  return await handleResponse(response);
};

const postProductionPlansLead = async (params) => {
  const response = await fetch(`${API_BASE_URI}/ops/production_plans/:id`, {
    ...options,
    method: 'PATCH',
    body: JSON.stringify({
      production_plans_lead: {
        supervisor_id: params.supervisor?.id,
        team_lead_id: params.teamLead?.id,
        shift_id: params.shiftId,
        menu_id: params.menuId,
        meal_id: params.mealId,
        date: params.date,
      },
    }),
  });

  return await handleResponse(response);
};

export default {
  postProductionPlans,
  getProductionPlans,
  postProductionPlansLead,
};
