import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';

import { postGuidedPickingOverride } from 'redux/guidedPickingOverride/actions';
import { getMoveRequestDisplayName } from 'lib/viewHelpers';
import {
  QR_CODE,
  OPEN_ITEM_MOVES_REQUEST_CONTENT,
  BACK_BUTTON,
  ASSIGN,
  UNASSIGN,
  BULK,
  INGREDIENT,
} from 'lib/constants';
import { getFirstAndLastInitials } from 'lib/utils';
import {
  StatefulButton,
  PalletScanTextField,
  ActionCard,
  Loading,
} from 'components/shared';

import CancelDialog from '../CancelDialog';
import PalletIssueDialog from '../PalletIssueDialog';
import SuggestedPalletInfo from '../SuggestedPalletInfo';
import styles from '../styles';

const useStyles = makeStyles(styles);

const ViewOpenRequest = ({
  itemMoveRequest,
  signedInUser,
  onSetTopBarContent,
  onUpdateItemMoveRequest,
  updating,
  updatingFailed,
  postingGuidedPickingOverride,
  postingGuidedPickingOverrideFailed,
  history,
  palletSearchDrawerOpen,
  setShowQRIcon,
  onFetchSuggestedPallet,
  suggestedPallet,
  fetchingSuggestedPallet,
  onClearSuggestedPallet,
}) => {
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  // Check category and update the state to determine what to set for topBar.
  if (itemMoveRequest && itemMoveRequest.category !== BULK) {
    setShowQRIcon(false);
  }

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: QR_CODE,
      rightContent: OPEN_ITEM_MOVES_REQUEST_CONTENT,
    });
  });

  useEffect(() => {
    const moveRequestChanged =
      itemMoveRequest?.id !== suggestedPallet?.itemMoveRequestId;

    if (itemMoveRequest && moveRequestChanged) {
      onClearSuggestedPallet();
      onFetchSuggestedPallet(itemMoveRequest.id);
    }
  }, [itemMoveRequest?.id]);

  const processPalletScan = (palletId) => {
    history.push(
      `/warehouse_moves/requests/${itemMoveRequest.id}/pallet/${palletId}`
    );
  };

  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const isAssigned = Boolean(itemMoveRequest?.runner);

  const [cancelButtonClicked, setCancelButtonClicked] = useState(false);
  const [assignButtonClicked, setAssignButtonClicked] = useState(false);
  const [pickupButtonClicked, setPickupButtonClicked] = useState(false);

  const assignButtonText = {
    LOADING: 'Updating...',
    SUCCESS: 'Success',
    ERROR: 'There was an error submitting',
    DEFAULT: isAssigned ? 'Unassign' : 'Assign to me',
  };

  const assignCancelButtonText = {
    LOADING: 'Updating...',
    SUCCESS: 'Success',
    ERROR: 'There was an error submitting',
    DEFAULT: 'Close Request',
  };

  const handleDialogClose = () => {
    setShowCancelDialog(false);
  };

  const overrideSuggestedPallet = useCallback(
    (reasonCode) => {
      return dispatch(
        postGuidedPickingOverride(
          itemMoveRequest?.id,
          suggestedPallet?.id,
          reasonCode
        )
      );
    },
    [itemMoveRequest?.id, suggestedPallet?.id]
  );

  const requestIsForIngredient =
    itemMoveRequest?.requestableType === INGREDIENT;

  if (!itemMoveRequest) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  } else {
    return (
      <div data-testid="view-open-request">
        {itemMoveRequest && itemMoveRequest.category == BULK && (
          <PalletScanTextField
            canScan={!palletSearchDrawerOpen}
            onScan={processPalletScan}
          />
        )}
        <Card className={classes.card}>
          <div className={classes.header}>Request</div>
          <CardContent className={classes.cardContent}>
            <span className={classes.ingName}>
              {getMoveRequestDisplayName(itemMoveRequest)}
            </span>
            {itemMoveRequest.requestedBrand && (
              <span className={classes.text}>
                {itemMoveRequest.requestedBrand}
              </span>
            )}
            {requestIsForIngredient && itemMoveRequest.category == BULK && (
              <span className={classes.ingredientId}>
                ID: {itemMoveRequest.requestableId}
              </span>
            )}
            <hr className={classes.line} />
            <SuggestedPalletInfo
              suggestedPallet={suggestedPallet}
              itemMoveRequest={itemMoveRequest}
              fetching={fetchingSuggestedPallet}
            />
            <div className={classes.rightSide}>
              {itemMoveRequest.status === 'Acknowledged' && (
                <Chip
                  className={classNames(classes.chip, {
                    [classes.myChip]: itemMoveRequest.runner === signedInUser,
                  })}
                  label={getFirstAndLastInitials(itemMoveRequest.runner)}
                />
              )}
            </div>
          </CardContent>
          <CardActions>
            <StatefulButton
              id="cancel"
              loading={updating}
              failed={updatingFailed}
              classes={{ root: classes.cancelButton }}
              onClick={() => {
                setShowCancelDialog(true);
                setCancelButtonClicked(true);
              }}
              buttonTextOptions={assignCancelButtonText}
              currentClicked={cancelButtonClicked}
            />
            <StatefulButton
              id="assign"
              loading={updating && !showCancelDialog}
              failed={updatingFailed}
              buttonTextOptions={assignButtonText}
              className={classNames(classes.assignButton, {
                [classes.unassign]: isAssigned,
              })}
              classes={{ root: classes.assignButton }}
              onClick={() => {
                onUpdateItemMoveRequest(
                  itemMoveRequest.id,
                  isAssigned ? UNASSIGN : ASSIGN
                );
                setAssignButtonClicked(true);
              }}
              currentClicked={assignButtonClicked}
            />
          </CardActions>
        </Card>

        <ActionCard
          header="Pickup"
          message="Scan Pallet to Start Move"
          request={itemMoveRequest}
          onUpdateItemMoveRequest={onUpdateItemMoveRequest}
          updating={updating}
          updatingFailed={updatingFailed}
          pickupButtonClicked={pickupButtonClicked}
          setPickupButtonClicked={setPickupButtonClicked}
          history={history}
        />

        <PalletIssueDialog
          suggestedPallet={suggestedPallet}
          itemMoveRequest={itemMoveRequest}
          onSubmitIssue={overrideSuggestedPallet}
          loading={postingGuidedPickingOverride}
          failed={postingGuidedPickingOverrideFailed}
        />

        <CancelDialog
          updating={updating}
          updatingFailed={updatingFailed}
          show={showCancelDialog}
          handleClose={handleDialogClose}
          onUpdateItemMoveRequest={onUpdateItemMoveRequest}
          itemMoveRequest={itemMoveRequest}
          history={history}
          onCancelSuccess={() => overrideSuggestedPallet('Cancellation')}
        />
      </div>
    );
  }
};

ViewOpenRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  itemMoveRequest: PropTypes.object,
  signedInUser: PropTypes.string.isRequired,
  onUpdateItemMoveRequest: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
  setShowQRIcon: PropTypes.func.isRequired,
  suggestedPallet: PropTypes.object,
  fetchingSuggestedPallet: PropTypes.bool.isRequired,
};

ViewOpenRequest.defaultProps = {
  itemMoveRequest: null,
  suggestedPallet: null,
};

export default ViewOpenRequest;
