import sortBy from 'lodash/sortBy';
import zipObject from 'lodash/zipObject';
import produce from 'immer';

export const createAllFalsePurchaseItems = (netsuiteItemIds) =>
  zipObject(netsuiteItemIds, new Array(netsuiteItemIds.length).fill(false));

export const sortItems = (orders) =>
  produce(orders, (sortedOrders) => {
    sortedOrders.forEach((order) => {
      order.netsuiteItems = sortBy(order.netsuiteItems, ['id']);
    });
  });

export const insertUpdatedOrderItem = (item, orders) =>
  produce(orders, (newOrders) => {
    const relevantOrderIndex = orders.findIndex(
      (order) => order.id === item.netsuiteOrderId
    );
    if (relevantOrderIndex !== -1) {
      const relevantItemIndex = newOrders[
        relevantOrderIndex
      ].netsuiteItems.findIndex((i) => i.id === item.id);
      newOrders[relevantOrderIndex].netsuiteItems[relevantItemIndex] = item;
    }
  });

export const addExpandedState = (expandedState, netsuiteOrders) =>
  produce(expandedState, (newExpandedState) => {
    netsuiteOrders.forEach((order) => {
      if (!newExpandedState.hasOwnProperty(order.id)) {
        newExpandedState[order.id] = {
          open: true,
          netsuiteItems: createAllFalsePurchaseItems(
            order.netsuiteItems.map((item) => item.id)
          ),
        };
      }
    });
  });
