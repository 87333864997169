import { makeApiRequest } from 'lib/api';
import { STORAGE_AREAS } from 'lib/constants';

import store from '../store';
import StorageAreas from './services';

export const fetchStorageAreas = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: STORAGE_AREAS,
        method: StorageAreas.getStorageAreas,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};

export const fetchStorageAreasWithHooks = () => {
  return makeApiRequest({
    type: STORAGE_AREAS,
    method: StorageAreas.getStorageAreas,
    params: {
      packingFacilityId: store.getState().session.packing_facility_id,
    },
  });
};
