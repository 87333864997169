import {
  GET_MEALS_TRACKER_PROGRESS_REQUEST,
  GET_MEALS_TRACKER_PROGRESS_SUCCESS,
  GET_MEALS_TRACKER_PROGRESS_ERROR,
} from 'lib/constants';

const initialState = {
  mealsFetching: false,
  mealsFailed: false,
  meals: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case GET_MEALS_TRACKER_PROGRESS_REQUEST:
      return {
        ...state,
        mealsFetching: true,
        mealsFailed: false,
      };
    case GET_MEALS_TRACKER_PROGRESS_SUCCESS:
      return {
        ...state,
        meals: action.response.meals,
        mealsFetching: false,
        mealsFailed: false,
      };
    case GET_MEALS_TRACKER_PROGRESS_ERROR:
      return {
        ...state,
        mealsFetching: false,
        mealsFailed: true,
      };
    default:
      return state;
  }
}
