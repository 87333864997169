export const insertUpdatedTrucks = (oldTrucks, newTrucks) => {
  return oldTrucks.map((truck) => {
    const updatedTruck = newTrucks.find((newTruck) => newTruck.id === truck.id);

    if (updatedTruck) {
      return updatedTruck;
    }
    return truck;
  });
};
