import {
  DOCK_APPOINTMENT_POST_REQUEST,
  DOCK_APPOINTMENT_POST_SUCCESS,
  DOCK_APPOINTMENT_POST_ERROR,
  DOCK_APPOINTMENT_PUT_REQUEST,
  DOCK_APPOINTMENT_PUT_SUCCESS,
  DOCK_APPOINTMENT_PUT_ERROR,
  DOCK_APPOINTMENT_DELETE_REQUEST,
  DOCK_APPOINTMENT_DELETE_SUCCESS,
  DOCK_APPOINTMENT_DELETE_ERROR,
  DOCK_APPOINTMENTS_REQUEST,
  DOCK_APPOINTMENTS_SUCCESS,
  DOCK_APPOINTMENTS_ERROR,
} from 'lib/constants';

export const initialState = {
  posting: false,
  updating: false,
  updateFailed: false,
  deleting: false,
  deletionFailed: false,
  errors: [],
  fetching: false,
  appointments: [],
  unscheduledPOs: [],
};

const updateAppointment = (state, appointment) => {
  return state.appointments.map((appt) => {
    if (appt.id !== appointment.id) {
      return appt;
    } else {
      return { ...appointment };
    }
  });
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case DOCK_APPOINTMENT_POST_REQUEST:
      return {
        ...state,
        errors: [],
        posting: true,
      };
    case DOCK_APPOINTMENT_POST_SUCCESS:
      return {
        ...state,
        errors: [],
        appointments: [...action.response.appointments, ...state.appointments],
        posting: false,
      };
    case DOCK_APPOINTMENT_POST_ERROR:
      return {
        ...state,
        posting: false,
        errors: state.errors.concat(action.response),
      };
    case DOCK_APPOINTMENT_PUT_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case DOCK_APPOINTMENT_PUT_SUCCESS:
      return {
        ...state,
        updating: false,
        appointments: updateAppointment(state, action.response.appointment),
      };
    case DOCK_APPOINTMENT_PUT_ERROR:
      return {
        ...state,
        updating: false,
        updateFailed: true,
        errors: state.errors.concat(action.response),
      };
    case DOCK_APPOINTMENT_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
        deletionFailed: false,
      };
    case DOCK_APPOINTMENT_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deletionFailed: false,
        appointments: state.appointments.filter(
          (appt) => appt.id !== action.params.appointmentId
        ),
      };
    case DOCK_APPOINTMENT_DELETE_ERROR:
      return {
        ...state,
        deleting: false,
        deletionFailed: true,
        errors: state.errors.concat(action.response),
      };
    case DOCK_APPOINTMENTS_REQUEST:
      return {
        ...state,
        errors: [],
        fetching: true,
      };
    case DOCK_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        errors: [],
        appointments: action.response.appointments,
        unscheduledPOs: action.response.unscheduledPOs,
        fetching: false,
      };
    case DOCK_APPOINTMENTS_ERROR:
      return {
        ...state,
        fetching: false,
        errors: state.errors.concat(action.response),
      };

    default:
      return state;
  }
}
