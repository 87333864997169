export const ITEM_MANAGER_WAREHOUSE_MOVE = 'warehouse_move';
export const ITEM_MANAGER_POD_MOVE = 'pod_move';
export const ITEM_MANAGER_AUDIT = 'audit';
export const ITEM_MANAGER_WAREHOUSE_PUTBACK = 'warehouse_putback';
export const ITEM_MANAGER_POD_PUTBACK = 'pod_putback';
export const ITEM_MANAGER_WASTE_MOVE = 'waste_move';
export const ITEM_MANAGER_AVAILABLE_MOVE_TYPES = [
  ITEM_MANAGER_WAREHOUSE_MOVE,
  ITEM_MANAGER_POD_MOVE,
  ITEM_MANAGER_AUDIT,
  ITEM_MANAGER_WAREHOUSE_PUTBACK,
  ITEM_MANAGER_POD_PUTBACK,
  ITEM_MANAGER_WASTE_MOVE,
];
