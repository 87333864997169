import { GREY_200, QA_ERROR_RED, BLACK } from 'lib/constants';

export default {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  cameraButton: {
    height: '55px',
    textTransform: 'none',
    fontFamily: 'Muli',
    fontSize: '16px',
    color: BLACK,
  },
  cameraIcon: {
    color: GREY_200,
    marginRight: '10px',
  },
  thumbnail: {
    height: '55px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: `1px solid ${GREY_200}`,
    width: '72px',
    zIndex: -1,
  },
  removePhotoButton: {
    height: '55px',
    color: QA_ERROR_RED,
    marginLeft: '5px',
    textTransform: 'none',
  },
};
