import {
  CHICAGO_FACILITY_ID,
  SB_FACILITY_ID,
  BAL_FACILITY_ID,
} from 'lib/constants';

const labeledPackagingFacilities = [CHICAGO_FACILITY_ID, SB_FACILITY_ID];
const portionedIngredientFacilities = [SB_FACILITY_ID, BAL_FACILITY_ID];

export function facilityHasLabeledPackaging(packingFacilityId) {
  return labeledPackagingFacilities.includes(packingFacilityId);
}
export function facilityHasPortionedIngredients(packingFacilityId) {
  return portionedIngredientFacilities.includes(packingFacilityId);
}
