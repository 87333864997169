import React from 'react';
import map from 'lodash/map';
import times from 'lodash/times';
import classnames from 'classnames';

import { useTimeIncrements } from 'lib/custom_hooks';

import TimeMarker from './TimeMarker';
import { timeIncrementPosition, gridPosition, MAX_GRID_LENGTH } from './styles';

const TimeIncrements = ({ timeZone, classes }) => {
  const timeIncrements = useTimeIncrements();

  return (
    <>
      {/* background shading for grid */}
      {map(timeIncrements, (time, index) => (
        <div
          id={time}
          key={time}
          className={classnames(classes.timeBox, {
            ['shaded']: parseInt(time) % 2 == 0,
          })}
          style={timeIncrementPosition(index + 1)}
        />
      ))}

      {/* Sticky time headers */}
      {map(timeIncrements, (time, index) => (
        <div
          key={time}
          className={classnames(classes.times, 'timeIncrement', {
            ['shaded']: parseInt(time) % 2 == 0,
          })}
          style={gridPosition(index + 1, 1, 1)}
        >
          {time}
        </div>
      ))}

      {/* Background rows with horizontal lines */}
      {times(MAX_GRID_LENGTH, (number) => (
        <div
          key={number}
          className={classes.rows}
          style={gridPosition(1, number + 1, timeIncrements.length)}
        />
      ))}

      <TimeMarker classes={classes} timeZone={timeZone} />
    </>
  );
};

export default TimeIncrements;
