import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEqual } from 'date-fns';

import {
  SLOT_REGEX,
  ACTIVE,
  ON_HOLD,
  DEACTIVATED,
  BIGGER_THAN_TABLET,
  UNKNOWN_LOCATION,
} from 'lib/constants';
import { useScanner } from 'lib/custom_hooks';
import { useLocationMoveName } from 'components/WarehouseInventory/helpers';
import DepthSelection from 'components/WarehouseInventory/DepthSelection';
import LocationInput from 'components/WarehouseInventory/LocationInput';
import SuccessScreen from 'components/shared/SuccessScreen';
import { Kraken } from 'components/icons';
import PalletDetailsButtons from 'components/WarehouseInventory/PalletDetailsButtons';
import ReleaseButton from 'components/WarehouseInventory/ReleaseButton';
import ReactivateButton from 'components/WarehouseInventory/ReactivateButton';

import PalletDetailsTopSection from '../PalletDetailsTopSection';
import PalletDetailsDeleteButton from '../PalletDetailsDeleteButton';
import PalletDetailsHoldButton from '../PalletDetailsHoldButton';
import PalletDetailsAuditButton from '../PalletDetailsAuditButton';
import styles from './styles';

const PalletDetails = ({
  classes,
  pallet,
  onPalletUpdate,
  updating,
  updateFailed,
  depthsLoading,
  depthsFailed,
  depthOptions,
  onGetDepths,
  history,
  fetchFailed,
  palletSearchDrawerOpen,
  onMakeUnknownLocPalletToActive,
  measuringUnitsForSelectedPallet,
}) => {
  const largerThanTablet = useMediaQuery(BIGGER_THAN_TABLET);
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [productDateType, setProductDateType] = useState(
    pallet.productDateType
  );

  const [productDate, setProductDate] = useState(pallet.productDate);

  const [fieldsDirty, setFieldsDirty] = useState(false);

  useEffect(() => {
    if (
      productDateType !== pallet.productDateType ||
      !isEqual(new Date(productDate), new Date(pallet.productDate))
    ) {
      setFieldsDirty(true);
    } else {
      setFieldsDirty(false);
    }
  }, [
    productDateType,
    productDate,
    setFieldsDirty,
    pallet.productDateType,
    pallet.productDate,
  ]);

  const invalidData = useMemo(() => !productDateType || !productDate, [
    productDateType,
    productDate,
  ]);

  const quantityInputDisabled = useMemo(
    () =>
      updating ||
      depthOptions.length > 0 ||
      depthsFailed ||
      depthsLoading ||
      pallet.status !== ACTIVE,
    [updating, depthOptions, depthsFailed, depthsLoading, pallet.status]
  );

  const onFieldsCancel = () => {
    setFieldsDirty(false);
    setProductDate(pallet.productDate);
    setProductDateType(pallet.productDateType);
  };

  const submitPalletUpdate = (values) => {
    onPalletUpdate(pallet.id, values);
  };

  async function submitPalletLocation(value) {
    await onPalletUpdate(pallet.id, { storage_slot_id: value });
    if (pallet.statusReason == UNKNOWN_LOCATION) {
      onMakeUnknownLocPalletToActive(pallet.id);
    }
  }

  // Scanner input stuff
  const [locationNumber, setLocationNumber] = useState('');
  const locationInputElement = useRef(null);
  const inLocationMoveMode = useMemo(
    () => depthOptions.length > 0 || depthsFailed || depthsLoading,
    [depthOptions, depthsFailed, depthsLoading]
  );
  const renderLocationInput = () => {
    return (
      <LocationInput
        showDepths={inLocationMoveMode}
        setLocationNumber={setLocationNumber}
        locationInputElement={locationInputElement}
        locationNumber={locationNumber}
        onGetDepths={onGetDepths}
        fetchFailed={fetchFailed}
        activePallet={pallet.status === ACTIVE}
        onHoldPallet={pallet.status === ON_HOLD}
      />
    );
  };

  const [selectedDepth, setSelectedDepth] = useState({});
  const locationReset = useCallback(() => {
    setLocationNumber('');
  }, [setLocationNumber]);

  const moveToLocationName = useLocationMoveName(selectedDepth, depthOptions);

  const processScan = useCallback(
    (scan) => {
      if (scan.match(SLOT_REGEX)) {
        onGetDepths(scan);
      } else {
        history.push(`/inventory-manager/pallets/${scan}`);
      }
    },
    [onGetDepths, history]
  );

  const canScan = useMemo(
    () => !fieldsDirty && !palletSearchDrawerOpen && !calculatorOpen,
    [fieldsDirty, palletSearchDrawerOpen, calculatorOpen]
  );
  const onPageKeyDown = useScanner(locationInputElement, processScan, canScan);

  // Rendering
  const activePalletActions = () => {
    return (
      <>
        <div>{renderLocationInput()}</div>
        <div className={classes.actionsContainer}>
          <PalletDetailsHoldButton palletId={pallet.id} />
          <PalletDetailsDeleteButton palletId={pallet.id} />
          <PalletDetailsAuditButton palletId={pallet.id} />
        </div>
      </>
    );
  };

  const heldPalletActions = () => {
    return (
      <Fragment>
        {largerThanTablet && pallet.statusReason === UNKNOWN_LOCATION ? (
          <>
            <div className={classes.locationContainer}>
              <p className={classes.locationText}>
                To use this pallet, first enter the storage slot of this
                pallet's current location
              </p>
              {renderLocationInput()}
            </div>
            <div className={classes.deleteForUnknown}>
              <hr className={classes.orLine} />
              <p className={classes.orText}>OR</p>
              <hr className={classes.orLine2} />
            </div>
            <div className={classes.deleteButtonContainer}>
              <PalletDetailsDeleteButton
                className={classes.deleteButton}
                palletId={pallet.id}
              />
            </div>
          </>
        ) : !largerThanTablet && pallet.statusReason === UNKNOWN_LOCATION ? (
          <>
            {renderLocationInput()}
            <div className={classes.deleteButtonContainer}>
              <PalletDetailsDeleteButton
                className={classes.deleteButton}
                palletId={pallet.id}
              />
            </div>
          </>
        ) : (
          <>
            <div className={classes.kraken}>
              <Kraken />
            </div>
            <div className={classes.actionsContainer}>
              <ReleaseButton palletId={pallet.id} />
              <PalletDetailsDeleteButton palletId={pallet.id} />
            </div>
          </>
        )}
      </Fragment>
    );
  };

  const deactivatedPalletActions = () => {
    return (
      <ReactivateButton
        palletId={pallet.id}
        includeWasteCorrection={pallet.wasteCorrectionReactivationEligible}
      />
    );
  };

  const renderPalletActions = () => {
    switch (pallet.status) {
      case ACTIVE:
        return activePalletActions();
      case ON_HOLD:
        return heldPalletActions();
      case DEACTIVATED:
        return deactivatedPalletActions();
      default:
        return '';
    }
  };

  const renderBottomContent = () => {
    if (fieldsDirty) {
      return (
        <PalletDetailsButtons
          onCancelClick={onFieldsCancel}
          onSubmitClick={() =>
            submitPalletUpdate({
              product_date_type: productDateType,
              product_date: new Date(productDate),
            })
          }
          loading={updating}
          failed={updateFailed}
          disabled={invalidData}
        />
      );
    } else if (inLocationMoveMode) {
      return (
        <DepthSelection
          locationReset={locationReset}
          updating={updating}
          updateFailed={updateFailed}
          palletId={pallet.id}
          selectedDepthId={selectedDepth.id}
          setSelectedDepth={setSelectedDepth}
          itemMoveRequest={false}
          onSubmit={submitPalletLocation}
        />
      );
    } else {
      return renderPalletActions();
    }
  };

  if (fetchFailed) {
    return (
      <Fragment>
        {renderLocationInput()}
        <div className={classes.centered}>
          Sorry, no pallet matching that id found
        </div>
      </Fragment>
    );
  }

  const palletUpdateAction = (measuringUnitIdObject) => {
    onPalletUpdate(pallet.id, measuringUnitIdObject);
  };

  return (
    <Fragment>
      <SuccessScreen loading={updating} failed={updateFailed} />
      <div data-testid="pallet-details" className={classes.root}>
        <div>
          <PalletDetailsTopSection
            disabled={quantityInputDisabled}
            quantity={pallet.quantity}
            onPageKeyDown={onPageKeyDown}
            onProductDateChange={(val) => setProductDate(val)}
            onProductDateTypeChange={(val) => setProductDateType(val)}
            onSubmit={submitPalletUpdate}
            productDateType={productDateType}
            productDate={productDate}
            pallet={pallet}
            inLocationMoveMode={inLocationMoveMode}
            toLocation={moveToLocationName}
            fieldsDirty={fieldsDirty}
            setCalculatorOpen={setCalculatorOpen}
            calculatorOpen={calculatorOpen}
            measuringUnitsForSelectedPallet={measuringUnitsForSelectedPallet}
            passUpdatedUOMToPalletDetails={palletUpdateAction}
          />
        </div>

        {renderBottomContent()}
      </div>
    </Fragment>
  );
};

PalletDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  pallet: PropTypes.shape({
    quantity: PropTypes.number,
    location: PropTypes.string,
    item: PropTypes.string,
    id: PropTypes.number,
  }),
  onPalletUpdate: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  updateFailed: PropTypes.bool.isRequired,
  depthOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  depthsLoading: PropTypes.bool.isRequired,
  depthsFailed: PropTypes.bool.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  onGetDepths: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
  onMakeUnknownLocPalletToActive: PropTypes.func,
  measuringUnitsForSelectedPallet: PropTypes.array,
};

PalletDetails.defaultProps = {
  pallet: {},
  onMakeUnknownLocPalletToActive: () => {},
  measuringUnitsForSelectedPallet: [],
};

export default withStyles(styles)(PalletDetails);
