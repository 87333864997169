import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import Ingredient from './Ingredient';
import IngredientRow from '../IngredientRow';
import styles from './styles';

const NonPortionableIngredientsRow = ({ classes, ingredients }) => {
  return (
    <IngredientRow>
      <Grid
        item
        xs={12}
        className={classnames(classes.gridItemAlignLeft, 'zebra')}
      >
        <Grid item xs={12}>
          <div className={classes.dataCellText}>
            {ingredients.length > 0 ? (
              <span className={classes.prePortioned}>Pre-portioned:&nbsp;</span>
            ) : null}
            {ingredients.map((ingredient) => (
              <Ingredient
                key={ingredient.name}
                ingredient={ingredient}
                isLastOfList={
                  ingredients.indexOf(ingredient) === ingredients.length - 1
                }
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </IngredientRow>
  );
};

NonPortionableIngredientsRow.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
};

export default withStyles(styles)(NonPortionableIngredientsRow);
