import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import {
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
  INVENTORY_MANAGER_ACTIONS,
  PROGRESS_TRACKER,
  TEXT,
  QA_APP,
  BACK_BUTTON,
  PALLET_ACTIONS,
  STANDARD_MENU_BUTTONS,
  QR_CODE,
  WAREHOUSE_MOVES_CONTENT,
  OPEN_ITEM_MOVES_REQUEST_CONTENT,
  QR_CODE_WITH_TEXT,
  EXTENDED_MENU_DISPLAY,
  ITEM_MANAGER,
} from 'lib/constants';

import HomeButton from './HomeButton';
import BackButton from './BackButton';
import AccountSwitch from './AccountSwitch';
import ProgressTrackerContent from './ProgressTrackerContent';
import PalletDetailsActions from './PalletDetailsActions';
import InventoryManagerActions from './InventoryManagerActions';
import QaTopBar from './QaTopBar';
import StandardMenuButtons from './StandardMenuButtons';
import ExtendedMenuDropDown from './ExtendedMenuDropDown';
import QrCodeContent from './QrCodeContent';
import WarehouseMovesContent from './WarehouseMovesContent';
import ItemManagerPageSelectors from './ItemManagerPageSelectors';
import styles from './styles';

const TopBar = ({ classes, topBarContent }) => {
  const renderContent = (contentKey) => {
    switch (contentKey) {
      case HOME_BUTTON:
        return (
          <li
            data-testid="home-button-wrapper"
            className={classNames(classes.navItem, classes.leftButton)}
          >
            <HomeButton />
          </li>
        );
      case BACK_BUTTON:
        return (
          <li>
            <BackButton absoluteLocation={topBarContent.backLocation} />
          </li>
        );
      case ACCOUNT_DROPDOWN:
        return (
          <li className={classNames(classes.navItem, classes.accountSwitch)}>
            <AccountSwitch />
          </li>
        );
      case INVENTORY_MANAGER_ACTIONS:
        return <InventoryManagerActions />;
      case PALLET_ACTIONS:
        return <PalletDetailsActions />;
      case PROGRESS_TRACKER:
        return <ProgressTrackerContent />;
      case TEXT:
        return <li className={classes.text}>{topBarContent.text}</li>;
      case QA_APP:
        return <QaTopBar text={topBarContent.text} />;
      case STANDARD_MENU_BUTTONS:
        return <StandardMenuButtons />;
      case EXTENDED_MENU_DISPLAY:
        return <ExtendedMenuDropDown />;
      case QR_CODE:
        return <QrCodeContent />;
      case WAREHOUSE_MOVES_CONTENT:
        return <WarehouseMovesContent />;
      case OPEN_ITEM_MOVES_REQUEST_CONTENT:
        return <WarehouseMovesContent parent={WAREHOUSE_MOVES_CONTENT} />;
      case ITEM_MANAGER:
        return <ItemManagerPageSelectors />;
      case QR_CODE_WITH_TEXT:
        return (
          <>
            <QrCodeContent />
            <li
              className={classNames(classes.text, classes.textWithSpacing)}
            >{`${topBarContent.text}`}</li>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <nav className={classes.root}>
      {renderContent(topBarContent.leftContent)}
      {renderContent(topBarContent.middleContent)}
      {renderContent(topBarContent.rightContent)}
    </nav>
  );
};

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  topBarContent: PropTypes.shape({
    leftContent: PropTypes.string,
    middleContent: PropTypes.string,
    rightContent: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(TopBar);
