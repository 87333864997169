import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { StatefulButton } from 'components/shared';
import { QRCodeIcon } from 'components/icons';
import {
  BULK,
  PICK_UP_ONLY,
  LABELED_PACKAGING,
  STAGING,
  DISPOSABLE_ITEM,
} from 'lib/constants';

import styles from './styles';

const ActionCard = ({
  message,
  classes,
  header,
  renderRequest,
  request,
  onUpdateItemMoveRequest,
  updating,
  updatingFailed,
  pickupButtonClicked,
  setPickupButtonClicked,
  history,
}) => {
  const isPickUpOnly =
    request.category !== BULK || request.submitter === STAGING;

  const pickUpButtonText = () => {
    if (request.submitter === STAGING) {
      return 'Click to pick up Staged Item';
    } else if (request.category === LABELED_PACKAGING) {
      return 'Click to pick up Labeled Packaging';
    } else if (request.category === DISPOSABLE_ITEM) {
      return 'Click to pick up Disposable Item';
    } else {
      return 'Click to pick up Portioned Ingredients';
    }
  };

  const buttonText = {
    LOADING: 'Updating...',
    SUCCESS: 'Success!',
    ERROR: 'There was an error picking up',
    DEFAULT: pickUpButtonText(),
  };

  return (
    <Card className={classes.card}>
      <div className={classes.header}>{header}</div>
      <CardContent className={classes.cardContent}>
        {renderRequest}
        {isPickUpOnly ? (
          <StatefulButton
            id="pickupLabeled"
            waitTime={750}
            loading={updating}
            failed={updatingFailed}
            buttonTextOptions={buttonText}
            className={classes.assignButton}
            classes={{ root: classes.assignButton }}
            onClick={() => {
              onUpdateItemMoveRequest(request.id, PICK_UP_ONLY);
              setPickupButtonClicked(true);
            }}
            successCallback={() => history.push('/warehouse_moves/in_progress')}
            currentClicked={pickupButtonClicked}
            fullWidth
          />
        ) : (
          <QRCodeIcon className={classes.icon} />
        )}
        <div className={classes.scanText}>{isPickUpOnly ? null : message}</div>
      </CardContent>
    </Card>
  );
};

ActionCard.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  header: PropTypes.string,
  renderRequest: PropTypes.element,
  request: PropTypes.object,
  onUpdateItemMoveRequest: PropTypes.func,
  updating: PropTypes.bool,
  updatingFailed: PropTypes.bool,
  pickupButtonClicked: PropTypes.bool,
  setPickupButtonClicked: PropTypes.func,
  history: PropTypes.object,
};

ActionCard.defaultProps = {
  message: 'Scan to start',
  header: 'Request',
  renderRequest: null,
  request: { category: BULK },
  onUpdateItemMoveRequest: () => {},
  pickupButtonClicked: false,
  setPickupButtonClicked: () => {},
  updating: false,
  updatingFailed: false,
  history: {},
};

export default withStyles(styles)(ActionCard);
