import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const UnknownAreaHoldBanner = ({ classes, pallet }) => {
  return (
    <div
      className={classNames(
        classes.UnknownAreaPalletBanner,
        classes[pallet.status.replace(/ /g, '')]
      )}
    >
      This pallet was marked as missing a location on {pallet.heldAt}
    </div>
  );
};

UnknownAreaHoldBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  pallet: PropTypes.shape({
    heldAt: PropTypes.string,
  }).isRequired,
};

export default UnknownAreaHoldBanner;
