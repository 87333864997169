import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { makeStyles } from '@mui/styles';

import { Snackbar } from 'components/shared';
import { printLicensePlate } from 'redux/itemManager/actions';

import styles from './styles';

const useStyles = makeStyles(styles);

const PrintButton = ({ productionZoneItem }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submitPrintRequest = async () => {
    setLoading(true);
    const response = await dispatch(
      printLicensePlate({ productionZoneItemId: productionZoneItem.id })
    );

    if (response.ok) {
      setError(false);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <IconButton className={classes.button}>
        <PrintIcon className={classes.icon} onClick={submitPrintRequest} />
      </IconButton>
      <Snackbar loading={loading} failed={error} />
    </div>
  );
};

PrintButton.propTypes = {
  productionZoneItem: PropTypes.object.isRequired,
};

export default PrintButton;
