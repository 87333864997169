import { GREY_200, GREY_800 } from 'lib/constants';
import { linkTabStyling, centeredLoading } from 'lib/styles';
import theme from 'lib/theme';

const tableCellDefaults = {
  color: GREY_800,
  padding: '18px 5px 18px 15px',
  borderBottom: `1px solid ${GREY_200}`,
};

export default {
  centeredLoading,
  ...linkTabStyling,
  centeredText: {
    textAlign: 'center',
  },
  table: {
    color: GREY_800,
  },
  headerRow: {
    textTransform: 'uppercase',
    fontSize: '14px',
  },
  tableCell: {
    ...tableCellDefaults,
    fontSize: '16px',
  },
  tableHeadCell: {
    ...tableCellDefaults,
    fontWeight: 'bolder',
  },
  ingredientTableCell: {
    ...tableCellDefaults,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  ingredientTableHeadCell: {
    ...tableCellDefaults,
    fontWeight: 'bolder',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  media: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '86%',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '88%',
    },
  },
  card: {
    margin: '9px',
  },
  mealInfoCard: {
    [theme.breakpoints.up('md')]: {
      height: '350px',
    },
    [theme.breakpoints.down('md')]: {
      height: '378px',
    },
  },
  cardTitle: {
    fontSize: '18px',
    margin: '0px',
  },
  mealInfoCardRow: {
    color: GREY_800,
    borderTop: `1px solid ${GREY_200}`,
    padding: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  boldCell: {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  heavyFont: {
    fontWeight: 'bold',
  },
  userSearchInput: {
    marginLeft: '-20px',
  },
  shift: {
    textTransform: 'capitalize',
  },
  mealType: {
    textTransform: 'capitalize',
  },
  boldText: {
    fontWeight: 'bolder',
  },
  numberColumn: {
    textAlign: 'right',
  },
};
