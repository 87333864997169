import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BoxDetailsIcon, PrintNewLabelIcon } from 'components/icons';
import { PHONE_SCREEN, TABLET_SCREEN, ENTER_KEY_CODE } from 'lib/constants';
import { useScanner, useHiddenNativeKeyboard } from 'lib/custom_hooks';

const BoxRayScanField = ({
  classes,
  trackingNumber,
  onSetTrackingNumber,
  pushUrl,
  location,
}) => {
  const isViewBoxDetailsPage = /view-box-details/.test(location.pathname);
  const isPrintNewLabelPage = /print-new-label/.test(location.pathname);

  const history = useHistory();

  const inputElement = useRef(null);
  const hideKeyboard = useHiddenNativeKeyboard(inputElement);

  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const tabletScreen = useMediaQuery(TABLET_SCREEN);
  const showBarCodeIcon =
    (phoneScreen || tabletScreen) &&
    (isViewBoxDetailsPage || isPrintNewLabelPage) &&
    trackingNumber === '';

  const [scanInputValue, setScanInputValue] = useState('');

  const invalidScan = (scan) => {
    return !scan.match(/^[0-9a-z]+$/i);
  };

  const setTrackingNumberAndClearField = (scan) => {
    if (invalidScan(scan)) return;

    onSetTrackingNumber(scan);
    history.push(`${pushUrl}/${scan}`);
    setScanInputValue('');
    onSetTrackingNumber('');
  };

  const onInputChange = (e) => {
    if (phoneScreen || tabletScreen) {
      setTrackingNumberAndClearField(e.target.value);
    } else {
      setScanInputValue(e.target.value);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      setTrackingNumberAndClearField(e.target.value);
    }
  };

  useScanner(inputElement, onSetTrackingNumber, true);

  const helpMessage = () => {
    if (trackingNumber === '' && !(phoneScreen || tabletScreen)) {
      return 'Please enter a tracking number.';
    } else {
      return '';
    }
  };

  return (
    <>
      {helpMessage() && (
        <div className={classes.helpMessage}>{helpMessage()}</div>
      )}
      <div className={classes.root}>
        {showBarCodeIcon && (
          <div className={classes.container}>
            <div className={classes.header}>
              {isPrintNewLabelPage
                ? 'Scan Box to Print a New Label'
                : 'Scan Box to View Details'}
            </div>
            <div className={classes.svgWrapper}>
              {isPrintNewLabelPage ? <PrintNewLabelIcon /> : <BoxDetailsIcon />}
            </div>
          </div>
        )}
        <TextField
          className={classes.textField}
          variant="outlined"
          name="shippingLabel"
          label="Shipping Label"
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          value={scanInputValue}
          InputProps={{
            classes: { input: classes.input },
            autoComplete: 'off',
          }}
          inputRef={inputElement}
          onFocus={hideKeyboard}
        />
      </div>
    </>
  );
};

BoxRayScanField.propTypes = {
  classes: PropTypes.object.isRequired,
  onSetTrackingNumber: PropTypes.func.isRequired,
  trackingNumber: PropTypes.string.isRequired,
  pushUrl: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default BoxRayScanField;
