export const sortAlphaNum = (selection, prevSelection) => {
  if (
    selection.letterAssignment === null ||
    prevSelection.letterAssignment === null
  ) {
    return -1;
  } else {
    return selection.letterAssignment.localeCompare(
      prevSelection.letterAssignment,
      'en',
      {
        numeric: true,
      }
    );
  }
};
