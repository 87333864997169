import {
  WHITE,
  HC_GREEN,
  DARK_HC_GREEN,
  GREY_200,
  GREY_800,
} from 'lib/constants';

export default {
  noRequestsMessage: {
    margin: '15px 10px',
    fontSize: '15px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '60px',
  },
  subheader: {
    padding: '5px',
    color: WHITE,
    background: GREY_800,
    fontSize: '14px',
  },
  buttonDisabled: {
    backgroundColor: GREY_200,
  },
  buttonContainer: {
    width: '80%',
    margin: '0 auto',
  },
  submitButton: {
    margin: '10px',
    height: '55px',
    width: '100%',
    '&$buttonDisabled': {
      backgroundColor: GREY_200,
      color: GREY_800,
    },
    color: HC_GREEN,
    backgroundColor: WHITE,
    '&:hover': {
      color: WHITE,
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': {
        backgroundColor: GREY_200,
      },
    },
  },
};
