import { useState } from 'react';

// This isn't currently being used but may be in the future - makes forms very easy
// Note: the keys of initial values need to match the 'name' prop on the input
const useSimpleForm = (initialValues, onSubmit = () => {}) => {
  const [inputs, setInputs] = useState(initialValues);
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    onSubmit(inputs);
  };
  const handleInputChange = (event) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [event.target.name]: event.target.value,
    }));
  };
  const handleClear = () => {
    setInputs(initialValues);
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs,
    handleClear,
  };
};

export default useSimpleForm;
