import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { filterGroupByTime } from 'lib/viewHelpers/fsqa';

import IngredientsPalletsTable from './PalletsTables/IngredientsPalletsTable';
import PackagingPalletsTable from './PalletsTables/PackagingPalletsTable';
import PalletDrawer from './PalletDrawer';

const WarehouseQA = ({
  groupedIngredientsPallets,
  groupedPackagingPallets,
}) => {
  const [selectedPallet, setSelectedPallet] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onClickPallet = (clickedPallet) => {
    setSelectedPallet(clickedPallet);
    setDrawerOpen(true);
  };

  return (
    <>
      {/* the pallet table passes in an entire pallet to the pallet drawer */}
      <IngredientsPalletsTable
        pallets={filterGroupByTime(groupedIngredientsPallets)}
        onClickPallet={onClickPallet}
      />
      <PackagingPalletsTable
        pallets={filterGroupByTime(groupedPackagingPallets)}
        onClickPallet={onClickPallet}
      />
      <PalletDrawer
        open={drawerOpen}
        closeDrawer={() => setDrawerOpen(false)}
        pallet={selectedPallet}
      />
    </>
  );
};

WarehouseQA.propTypes = {
  groupedIngredientsPallets: PropTypes.array.isRequired,
};

export default WarehouseQA;
