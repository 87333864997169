import { BLACK, WHITE, DARK_HC_GREEN, HC_GREEN } from 'lib/constants';
import theme from 'lib/theme';

export default {
  formContainer: {
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
  },
  portionFillerNumberButtonGroup: {
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: 0,
    flex: '1 1 5%',
    margin: '5px !important',
    display: 'inline-block',
    fontSize: '16px',
    color: BLACK,
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
  portionerButton: {
    minWidth: '23%',
    maxWidth: '24%',
  },
  portionFillerNumberButton: {
    minWidth: '9%',
    maxWidth: '9%',
  },
};
