import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import {
  CANCEL_MOVE_TYPE,
  ACCIDENTAL_REQUEST,
  KNOWN_SHORT,
  ON_HOLD,
  ON_FLOOR,
  NOT_FOUND,
  PENDING_RECEIPT,
  OTHER,
} from 'lib/constants';
import { CloseIcon } from 'components/icons';
import { StatefulButton } from 'components/shared';

const buttonText = {
  LOADING: 'Cancelling...',
  SUCCESS: 'Success',
  ERROR: 'There was an error submitting',
  DEFAULT: 'Confirm Cancellation',
};

const cancellationReasons = [
  CANCEL_MOVE_TYPE,
  ACCIDENTAL_REQUEST,
  KNOWN_SHORT,
  ON_HOLD,
  ON_FLOOR,
  NOT_FOUND,
  PENDING_RECEIPT,
  OTHER,
];

const CancelDialog = ({
  showCancel,
  setCancelNextStep,
  rowData,
  onUpdateItemMoveRequest,
  updating,
  updatingFailed,
  classes,
}) => {
  const [reason, setReason] = useState('');

  const closeDialog = () => {
    setCancelNextStep(false);
    setReason('');
  };

  const cancelRequest = () => {
    onUpdateItemMoveRequest(rowData.id, CANCEL_MOVE_TYPE, {
      cancellation_reason: reason,
    });
  };

  return (
    <Dialog
      open={showCancel}
      onClose={closeDialog}
      className={classes.root}
      data-testid="cancel-dialog"
    >
      <Button className={classes.closeButton} onClick={closeDialog}>
        <CloseIcon className={classes.icon} />
      </Button>
      <div className={classes.text}>
        Select the reason for cancelling this request:
      </div>
      <div className={classes.text}>{rowData.name}</div>
      <div className={classes.text}>
        Requested Amount: {rowData.quantityRequested}
      </div>
      <ToggleButtonGroup
        exclusive
        value={reason}
        className={classes.toggleButtonGroup}
      >
        {cancellationReasons.map((cancellationReason) => (
          <ToggleButton
            key={cancellationReason}
            value={cancellationReason}
            className={classes.reasonButton}
            onClick={() => setReason(cancellationReason)}
          >
            {cancellationReason}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <StatefulButton
        buttonTextOptions={buttonText}
        successCallback={closeDialog}
        loading={updating}
        failed={updatingFailed}
        disabled={!reason}
        type="button"
        id="submitButton"
        data-testid="submit-button"
        classes={{ root: classes.submitButton }}
        onClick={() => cancelRequest()}
      />
    </Dialog>
  );
};

CancelDialog.propTypes = {
  showCancel: PropTypes.bool.isRequired,
  setCancelNextStep: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  onUpdateItemMoveRequest: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CancelDialog;
