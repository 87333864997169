import { createTheme } from '@mui/material/styles';

import theme from 'lib/theme';
import { GREY_200 } from 'lib/constants';

const buttonGroupTheme = createTheme({
  ...theme,
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { fontSize: '16px' },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: '5px !important', // wouldnt work without !important (TYP)
          margin: '5px',
          borderLeft: `1px solid ${GREY_200} !important`,
          marginLeft: '5px',
        },
        label: {
          lineHeight: '20px',
        },
      },
    },
  },
});

export default buttonGroupTheme;
