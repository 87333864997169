import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import withStyles from '@mui/styles/withStyles';

import { PhotoTaker } from 'components/shared';

import styles from './styles';

const TakePhotoButton = ({ id = 'take-photo', classes, setPhoto }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [backupImage, setBackupImage] = useState(null);

  useEffect(() => {
    setThumbnail(backupImage);
  }, [backupImage]);

  const onSubmitPhoto = useCallback(
    (photo) => {
      setModalOpen(false);
      setPhoto(photo);
      setBackupImage(photo);
    },
    [setPhoto]
  );

  const removePhoto = () => {
    setPhoto(null);
    setThumbnail(null);
    setBackupImage(null);
  };

  if (thumbnail) {
    return (
      <div className={classes.container}>
        {
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            className={classes.thumbnail}
            alt="backupPhoto"
            src={thumbnail}
            onError={() => setThumbnail(backupImage)}
          />
        }

        <Button
          variant="outlined"
          onClick={removePhoto}
          className={classes.removePhotoButton}
          size="large"
        >
          Remove Photo
        </Button>
      </div>
    );
  } else {
    return (
      <Fragment>
        <Button
          className={classes.cameraButton}
          variant="outlined"
          onClick={() => setModalOpen(true)}
        >
          <CameraIcon fontSize="large" className={classes.cameraIcon} />
          Take A Photo
        </Button>
        <Dialog
          id={id}
          data-testid={id}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <PhotoTaker onSubmit={onSubmitPhoto} />
        </Dialog>
      </Fragment>
    );
  }
};

TakePhotoButton.propTypes = {
  classes: PropTypes.object.isRequired,
  setPhoto: PropTypes.func.isRequired,
};

export default withStyles(styles)(TakePhotoButton);
