import flatMap from 'lodash/flatMap';

import { makeApiRequest } from 'lib/api';
import {
  QA_MEAL_BAG_CHECKS_UPDATED,
  QA_MEAL_BAG_CHECKS_RESET,
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST,
} from 'lib/constants';
import {
  QA_MEAL_BAG_CHECKS_POST,
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT,
} from 'lib/constants/reduxConstants';

import MealBagChecks from '../sharedChecks/services';

export const updateQaMealBagChecks = (mealBagCheck) => {
  return {
    type: QA_MEAL_BAG_CHECKS_UPDATED,
    payload: mealBagCheck,
  };
};

export const postQaMealBagCheck = ({ issues, preliminaryCheckId }) => async (
  dispatch
) => {
  const response = await dispatch(
    makeApiRequest({
      type: QA_MEAL_BAG_CHECKS_POST,
      method: MealBagChecks.postQaChecks,
      params: {
        issues,
        photos: null,
        preliminaryCheckId,
      },
    })
  );

  if (response.ok) {
    dispatch(
      updateQaMealBagChecks({
        labelingIssues: issues.labelingIssues,
        packagingIssues: issues.packagingIssues,
        ingredientErrors: flatMap(
          issues.problematicIngredientErrors,
          (ing) => ing.errors
        ),
      })
    );
  }
};

export const submitPreliminaryCheck = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: QA_MEAL_BAG_PRELIMINARY_CHECK_POST,
      method: MealBagChecks.createPreliminaryCheck,
      params,
    })
  );
};

export const updatePreliminaryCheck = (params) => async (
  dispatch,
  getState
) => {
  await dispatch(
    makeApiRequest({
      type: QA_MEAL_BAG_PRELIMINARY_CHECK_PUT,
      method: MealBagChecks.updatePreliminaryCheck,
      params: {
        correctiveActions: params,
        preliminaryCheckId: getState().qaMealBagChecks.preliminaryCheckId,
      },
    })
  );
};

export const resetQaMealBagChecks = () => ({
  type: QA_MEAL_BAG_CHECKS_RESET,
});
