import { GREY_600, HC_GREEN } from 'lib/constants';

export default {
  link: {
    display: 'block',
    textDecoration: 'none',
    color: GREY_600,
    width: '100%',
    paddingBottom: '18px',
    borderBottom: `2px solid ${GREY_600}`,
  },
  activeLink: {
    color: HC_GREEN,
    paddingBottom: '16px',
    borderBottom: `4px solid ${HC_GREEN}`,
  },
  navLinksContainer: {
    textAlign: 'center',
    paddingTop: '20px',
    textTransform: 'uppercase',
  },
};
