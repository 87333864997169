import produce from 'immer';

import {
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR,
  AUDIT_ITEM_UPDATE_REQUEST,
  AUDIT_ITEM_UPDATE_SUCCESS,
  AUDIT_ITEM_UPDATE_ERROR,
  WAREHOUSE_AUDIT_EXPAND_ALL,
  WAREHOUSE_AUDIT_COLLAPSE_ALL,
  WAREHOUSE_AUDIT_TOGGLE_EXPAND_ITEM,
  WAREHOUSE_AUDIT_TOGGLE_EXPAND_ORDER,
  WMS_ITEM_RECEIPT_UPDATE_SUCCESS,
} from 'lib/constants';
import {
  sortItems,
  addExpandedState,
  insertUpdatedOrderItem,
  createAllFalsePurchaseItems,
} from 'lib/redux_utils';

export const initialState = {
  fetching: false,
  failed: false,
  updating: false,
  updateFailed: false,
  netsuiteOrders: [],
  expandedState: {},
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        netsuiteOrders: sortItems(action.response.netsuiteOrders),
        expandedState: addExpandedState(
          state.expandedState,
          action.response.netsuiteOrders
        ),
      };
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case AUDIT_ITEM_UPDATE_REQUEST:
      return { ...state, updating: true, updateFailed: false };
    case AUDIT_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        netsuiteOrders: insertUpdatedOrderItem(
          action.response.netsuiteOrderItem,
          state.netsuiteOrders
        ),
      };
    case AUDIT_ITEM_UPDATE_ERROR:
      return { ...state, updating: false, updateFailed: true };
    case WMS_ITEM_RECEIPT_UPDATE_SUCCESS:
      return {
        ...state,
        netsuiteOrders: insertUpdatedOrderItem(
          action.response.netsuiteOrderItem,
          state.netsuiteOrders
        ),
      };
    case WAREHOUSE_AUDIT_TOGGLE_EXPAND_ORDER:
      return {
        ...state,
        expandedState: produce(state.expandedState, (newExpandedState) => {
          newExpandedState[action.netsuiteOrderId].open = !newExpandedState[
            action.netsuiteOrderId
          ].open;
          newExpandedState[
            action.netsuiteOrderId
          ].netsuiteItems = createAllFalsePurchaseItems(
            Object.keys(newExpandedState[action.netsuiteOrderId].netsuiteItems)
          );
        }),
      };
    case WAREHOUSE_AUDIT_TOGGLE_EXPAND_ITEM:
      return {
        ...state,
        expandedState: produce(state.expandedState, (newExpandedState) => {
          newExpandedState[action.netsuiteOrderId].netsuiteItems[
            action.netsuiteItemId
          ] = !newExpandedState[action.netsuiteOrderId].netsuiteItems[
            action.netsuiteItemId
          ];
        }),
      };
    case WAREHOUSE_AUDIT_EXPAND_ALL:
      return {
        ...state,
        expandedState: produce(state.expandedState, (newExpandedState) => {
          Object.keys(newExpandedState).forEach((key) => {
            newExpandedState[key] = {
              ...newExpandedState[key],
              open: true,
            };
          });
        }),
      };
    case WAREHOUSE_AUDIT_COLLAPSE_ALL:
      return {
        ...state,
        expandedState: produce(state.expandedState, (newExpandedState) => {
          Object.keys(newExpandedState).forEach((key) => {
            newExpandedState[key] = {
              ...newExpandedState[key],
              open: false,
              netsuiteItems: createAllFalsePurchaseItems(
                Object.keys(newExpandedState[key].netsuiteItems)
              ),
            };
          });
        }),
      };
    default:
      return state;
  }
}
