import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import AppointmentViewer from './AppointmentViewer';
import styles from './styles';

const mapStateToProps = (state) => ({
  timeZone: state.session.timeZone,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppointmentViewer));
