import React from 'react';
import PropTypes from 'prop-types';

import { WHITE } from 'lib/constants';

const TimeClockIcon = ({ className, visible }) => {
  if (visible === true) {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 24 24"
        data-testid="time-clock-icon"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M15 1H9v2h6V1zm-4 13h2V8h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          fill={WHITE}
        />
      </svg>
    );
  } else {
    return null;
  }
};

TimeClockIcon.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
};

TimeClockIcon.defaultProps = {
  className: 'TimeClockIcon',
  visible: true,
};

export default TimeClockIcon;
