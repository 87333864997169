import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const byMenu = async ({ menuId, facilityId }) => {
  const url = `${API_BASE_URI}/ops/menus/${menuId}/packing_facilities/${facilityId}/production_progress`;

  const response = await fetch(url, options);
  return await handleResponse(response);
};

export default {
  byMenu,
};
