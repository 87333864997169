import {
  FSQA_PALLETS_GET_REQUEST,
  FSQA_PALLETS_GET_SUCCESS,
  FSQA_PALLETS_GET_ERROR,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  defectsByItemCategory: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case FSQA_PALLETS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        defectsByItemCategory: [],
      };
    case FSQA_PALLETS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        defectsByItemCategory: action.response.defectsByItemCategory,
      };
    case FSQA_PALLETS_GET_ERROR:
      return { ...state, fetching: false, failed: true };
    default:
      return state;
  }
}
