import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { useHistory } from 'react-router-dom';

import {
  Loading,
  StatefulButton,
  PalletScanTextField,
} from 'components/shared';

import styles from './styles';
import ItemMoveRequest from '../ItemMoveRequest/ItemMoveRequest';

const InProgressRequests = ({
  classes,
  inProgressRequests,
  completedRequests,
  fetching,
  signedInUser,
  onFetchHistoricals,
  fetchingHistoricals,
  fetchingHistoricalsFailed,
  palletSearchDrawerOpen,
}) => {
  const history = useHistory();

  const [numberOfHistoricals, setNumberOfHistoricals] = useState(5);

  const allHistoricalsLoaded = numberOfHistoricals > completedRequests.length;

  const historicalsButtonTextOptions = {
    // eslint and prettier are being rule petty about this string syntax
    DEFAULT: allHistoricalsLoaded
      ? `You're at the beginning of time 🎉` // eslint-disable-line
      : 'Load More',
    SUCCESS: 'Success!',
    LOADING: 'Fetching data...',
    ERROR: 'Unable to load data',
  };

  const processPalletScan = (palletId) => {
    history.push(`/warehouse_moves/pallets/${palletId}`);
  };

  if (fetching) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  }

  const userGroupedRequests = groupBy(inProgressRequests, (request) => {
    return request.runner === signedInUser;
  });

  const orderedUserRequests = sortBy(userGroupedRequests[true], ['pickUpTime']);
  const orderedOtherRequests = sortBy(userGroupedRequests[false], [
    'pickUpTime',
  ]);

  const sortHistoricalDates = () => {
    return completedRequests
      .sort((a, b) => {
        const dateA = new Date(a?.formattedDropOffTime || a?.cancelledAtTime);
        const dateB = new Date(b?.formattedDropOffTime || b?.cancelledAtTime);
        return dateB - dateA;
      })
      .slice(0, numberOfHistoricals);
  };

  return (
    <Fragment>
      <PalletScanTextField
        canScan={!palletSearchDrawerOpen}
        onScan={processPalletScan}
      />
      <div>
        {inProgressRequests.length > 0 ? (
          [
            ...orderedUserRequests,
            ...orderedOtherRequests,
          ].map((itemMoveRequest, index) => (
            <ItemMoveRequest
              itemMoveRequest={itemMoveRequest}
              index={index}
              key={itemMoveRequest.id}
              last={index === inProgressRequests.length - 1}
              signedInUser={signedInUser}
            />
          ))
        ) : (
          <div className={classes.noRequestsMessage}>
            There are currently no new requests
          </div>
        )}
      </div>
      <div className={classes.subheader}>Historical Requests</div>
      {completedRequests.length > 0 ? (
        sortHistoricalDates().map((itemMoveRequest, index) => (
          <ItemMoveRequest
            itemMoveRequest={itemMoveRequest}
            index={index}
            key={itemMoveRequest.id}
            last={index === completedRequests.length - 1}
            signedInUser={signedInUser}
            historical
          />
        ))
      ) : (
        <div className={classes.nothingMessage}>
          There are currently no historical requests
        </div>
      )}
      {completedRequests.length > 0 ? (
        <div className={classes.buttonContainer}>
          <StatefulButton
            buttonTextOptions={historicalsButtonTextOptions}
            loading={fetchingHistoricals}
            failed={fetchingHistoricalsFailed}
            onClick={() => {
              onFetchHistoricals();
              setNumberOfHistoricals(numberOfHistoricals + 10);
            }}
            classes={{
              disabled: classes.buttonDisabled,
              root: classes.submitButton,
            }}
            disabled={allHistoricalsLoaded}
            variant="outlined"
          />
        </div>
      ) : null}
    </Fragment>
  );
};

const itemMoveRequestShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
});

InProgressRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  inProgressRequests: PropTypes.arrayOf(itemMoveRequestShape).isRequired,
  completedRequests: PropTypes.arrayOf(itemMoveRequestShape).isRequired,
  signedInUser: PropTypes.string.isRequired,
  onFetchHistoricals: PropTypes.func.isRequired,
  fetchingHistoricals: PropTypes.bool.isRequired,
  fetchingHistoricalsFailed: PropTypes.bool.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(InProgressRequests);
