import { MEDIA_QUERY_LARGE_SCREEN } from 'lib/constants';

export default {
  root: {
    padding: '5px 0 0 10px',

    [MEDIA_QUERY_LARGE_SCREEN]: {
      paddingLeft: '40px',
    },
  },
  loadingWrapper: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  iconWrapper: {
    paddingRight: '5px',
  },
  textWrapper: {
    display: 'flex',
  },
  buttonTextWrapper: {
    display: 'none',

    [MEDIA_QUERY_LARGE_SCREEN]: {
      display: 'block',
      fontSize: '18px',
      paddingLeft: '5px',
    },
  },
};
