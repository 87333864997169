import React from 'react';
import PropTypes from 'prop-types';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';

import { RealtimeLegendIcon } from 'components/icons';

import styles from './styles';

const RealtimeLegend = (props) => {
  const { classes } = props;

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.legend}
    >
      <Grid item xs={4} sm container align="right" style={{ padding: 6 }}>
        <Grid item xs container direction="column">
          <Grid item xs>
            <div className={classes.oneLiner}>
              <span className={classes.legendItem}>Not in Use</span>
              <ArrowForward />
            </div>
            <div className={classes.oneLiner}>
              <span className={classes.legendItem}>
                Production Submission (&lt; 30 min)
              </span>
              <ArrowForward />
            </div>
            <div className={classes.oneLiner}>
              <span className={classes.legendItem}>
                Needs Production Submission
              </span>
              <ArrowForward />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <RealtimeLegendIcon />
      </Grid>
      <Grid item xs={4} sm container style={{ padding: 6 }}>
        <Grid item xs container direction="column">
          <Grid item xs>
            <div className={classes.oneLiner}>
              <ArrowBack />{' '}
              <span className={classes.legendItem}>
                Start of Line Check Required
              </span>
            </div>
            <div className={classes.oneLiner}>
              <ArrowBack />
              <span className={classes.legendItem}>High Errors</span>
            </div>
            <div className={classes.oneLiner}>
              <ArrowBack />
              <span className={classes.legendItem}>QA Check Required</span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

RealtimeLegend.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealtimeLegend);
