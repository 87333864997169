import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';
import PalletDetailsPrint from 'components/WarehouseInventory/InventoryManager/PalletDetailsPrint';
import ActivityLog from 'components/WarehouseInventory/InventoryManager/ActivityLog';
import PalletSearch from 'components/WarehouseInventory/PalletSearch';

import styles from './styles';
import AccountSwitch from '../AccountSwitch';

const PalletDetailsActions = ({ classes, fetching }) => {
  if (fetching) {
    return (
      <li className={classes.root} data-testid="loading-component">
        <Loading />
      </li>
    );
  }
  return (
    <li className={classes.root}>
      <PalletSearch />
      <ActivityLog />
      <PalletDetailsPrint />
      <AccountSwitch />
    </li>
  );
};

PalletDetailsActions.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PalletDetailsActions);
