import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TableCell, TableRow, Button, Collapse } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';

import { ASSEMBLY_BAR_PIXEL_COUNT, ASSEMBLY_STEP_ORDER } from 'lib/constants';

import styles from './styles';
import ProgressBar from '../ProgressBar/ProgressBar';
import DaySplits from '../DaySplits';

const useStyles = makeStyles(styles);

const MealForAssembly = ({ meal, expandAll, trigger }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  const { assembled, readyForAssembly, goal, toGoal } = meal;
  const goalToAchieve = assembled + (goal < 0 ? 0 : goal);
  const stepOrder = ASSEMBLY_STEP_ORDER;

  const rthDaySplitsEnabled = useSelector(
    (state) => state.session.experiments?.rth_day_splits
  );

  const transformedProgressSteps = () => {
    // We do not want excess readyForAssembly count to distort the progress bar visualizations
    // so only account for the minimum amount that is needed to reach the goal
    const adjustedAssemblyAmount = Math.min(readyForAssembly, toGoal);

    const mealActivityCounts = {
      assembled: assembled,
      readyForAssembly: adjustedAssemblyAmount,
      toGoal: toGoal - adjustedAssemblyAmount,
    };
    return mealActivityCounts;
  };

  const sortedStep = Object.entries(transformedProgressSteps()).sort((a, b) => {
    return stepOrder.indexOf(a[0]) - stepOrder.indexOf(b[0]);
  });

  useEffect(() => {
    setExpand(expandAll);
  }, [expandAll, trigger]);

  return (
    <>
      <TableRow
        className={classes.row}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <TableCell
          className={classNames(
            classes.header,
            classes.totalProgressInfoContainer
          )}
        >
          <p className={classes.title}>
            {`${meal.letter} ${meal.name} - ${meal.id}`.trim()}
          </p>
          {rthDaySplitsEnabled && (
            <Button className={classes.toggleOpenButton}>
              {expand ? 'Close Day Splits' : 'View Day Splits'}
            </Button>
          )}
        </TableCell>
        <TableCell
          className={classNames(
            classes.cell,
            classes.totalProgressRecipeContainer
          )}
        >
          <div className={classes.progressStepContainer}>
            <div className={classes.progressBarContainer}>
              <ProgressBar
                activityCounts={sortedStep}
                totalCount={goalToAchieve}
                totalProgressBarLengthInPixels={ASSEMBLY_BAR_PIXEL_COUNT}
              />
            </div>
            <div className={classes.completedContainer}>
              <span className={classes.completedText}>Goal: {goal}</span>
            </div>
          </div>
          <div className={classes.progressCountsContainer}>
            <div className={classes.progressStepText}>
              <Brightness1Icon
                className={classNames(
                  classes.progressStepIcon,
                  classes.darkGreenStepIcon
                )}
              />
              <div className={classes.progressCount}>
                <span>Assembled</span>
                <span>{assembled}</span>
              </div>
            </div>

            <div className={classes.progressStepText}>
              <Brightness1Icon
                className={classNames(
                  classes.progressStepIcon,
                  classes.sapphireBlueStepIcon
                )}
              />
              <div className={classes.progressCount}>
                <span>Ready For Assembly</span>
                <span>{readyForAssembly}</span>
              </div>
            </div>

            <div className={classes.progressStepText}>
              <Brightness1Icon
                className={classNames(
                  classes.progressStepIcon,
                  classes.peachOrangeStepIcon
                )}
              />
              <div className={classes.progressCount}>
                <span>To Goal</span>
                <span>{toGoal}</span>
              </div>
            </div>
          </div>
        </TableCell>
      </TableRow>
      {rthDaySplitsEnabled && (
        <TableRow className={classes.row}>
          <TableCell colSpan="2" className={classes.collapseContainer}>
            <Collapse in={expand}>
              <DaySplits meal={meal} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MealForAssembly;
