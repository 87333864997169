import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import {
  subscribeToItemMoveRequests,
  unsubscribeToItemMoveRequests,
} from 'redux/itemMoveRequests/channels';
import { fetchRecentMoveRequests } from 'redux/itemMoveRequests/actions';

import styles from './styles';
import Queue from './Queue';

const mapStateToProps = (state) => ({
  requests: state.itemMoveRequests.itemMoveRequests,
  fetching: state.itemMoveRequests.fetching,
});

const mapDispatchToProps = {
  onGetMoveRequests: fetchRecentMoveRequests,
  onSubscribeToItemMoveRequests: subscribeToItemMoveRequests,
  onUnsubscribeToItemMoveRequests: unsubscribeToItemMoveRequests,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Queue));
