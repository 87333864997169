import * as Sentry from '@sentry/react';
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import { Snackbar } from 'components/shared';
import { SNACK_BAR_DURATION } from 'lib/constants';

import IngredientList from '../IngredientList';
import PortioningInventoryForm from '../IngredientInventoryForm/PortioningInventoryForm';
import CustomizeItInventoryForm from '../IngredientInventoryForm/CustomizeItInventoryForm';
import IngredientAssignmentMealList from '../IngredientAssignmentMealList';
import BreadcrumbProvider from '../BreadcrumbProvider';
import LabelInventoryForm from './LabelInventoryForm';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const PortioningLabeling = ({
  ingredients,
  printers,
  labelInventory,
  ingredientInventory,
  onGetIngredients,
  onGetProductionProgressForIngredient,
  onGetProductionProgressForCustomizeIt,
  onPostLabelInventory,
}) => {
  const { posting, errors, printing, printingError } = ingredientInventory;
  const labelPosting = labelInventory.posting;
  const labelErrors = labelInventory.errors;

  useEffect(() => {
    onGetIngredients();
  }, [onGetIngredients]);

  return (
    <Fragment>
      <BreadcrumbProvider>
        <Switch>
          <Route
            exact
            path="/production_inventory/labeling"
            render={() => (
              <IngredientList ingredients={ingredients.labelableIngredients} />
            )}
          />
          <Route
            exact
            path="/production_inventory/portioning"
            render={() => (
              <IngredientList
                ingredients={ingredients.portionableIngredients}
                getProductionProgressForIngredient={
                  onGetProductionProgressForIngredient
                }
              />
            )}
          />
          <Route
            exact
            path={[
              '/production_inventory/portioning/ingredient/:ingredientId/meals',
              '/production_inventory/labeling/ingredient/:ingredientId/meals',
            ]}
            render={() => (
              <IngredientAssignmentMealList ingredients={ingredients} />
            )}
          />
          <Route
            exact
            path="/production_inventory/portioning/ingredient_assignments/:ingredientAssignmentId/ship_week/:shipWeek"
            render={(routingProps) => (
              <CustomizeItInventoryForm
                {...routingProps}
                onGetProductionProgressForCustomizeIt={
                  onGetProductionProgressForCustomizeIt
                }
              />
            )}
          />
          <Route
            exact
            path="/production_inventory/portioning/ingredient/:ingredientId/ingredient_assignment/:ingredientAssignmentId"
            render={(routingProps) => (
              <PortioningInventoryForm {...routingProps} />
            )}
          />
          <Route
            path="/production_inventory/labeling/ingredient/:ingredientId/ingredient_assignment/:ingredientAssignmentId"
            render={(routingProps) => (
              <LabelInventoryForm
                {...routingProps}
                posting={labelInventory.posting}
                printers={printers}
                errors={labelInventory.errors}
                onPostLabelInventory={onPostLabelInventory}
              />
            )}
          />
        </Switch>
      </BreadcrumbProvider>

      <Snackbar
        loading={labelPosting}
        failed={Boolean(labelErrors)}
        messaging={{
          loading: 'Saving Labeling Inventory...',
          failure:
            (labelErrors && labelErrors[0]) ||
            'Error Submitting Label Inventory',
          success: 'Label Inventory success!',
        }}
        autoHideDuration={SNACK_BAR_DURATION}
      />

      <Snackbar
        loading={posting}
        failed={Boolean(errors)}
        messaging={{
          loading: 'Saving Ingredient Inventory...',
          failure:
            (errors && errors[0]) || 'Error Submitting Ingredient Inventory',
          success: 'Ingredient Inventory Success!',
        }}
        autoHideDuration={SNACK_BAR_DURATION}
      />
      <Snackbar
        loading={printing}
        failed={Boolean(printingError)}
        messaging={{
          loading: 'Printing...',
          failure: 'Error Printing',
          success: 'Print Success!',
        }}
        autoHideDuration={SNACK_BAR_DURATION}
      />
    </Fragment>
  );
};

PortioningLabeling.defaultProps = {
  ingredients: {},
  loading: false,
};

PortioningLabeling.propTypes = {
  loading: PropTypes.bool,
  ingredientInventory: PropTypes.object.isRequired,
  labelInventory: PropTypes.object.isRequired,
  ingredients: PropTypes.object,
  printers: PropTypes.array.isRequired,
  onGetIngredients: PropTypes.func.isRequired,
  onGetProductionProgressForCustomizeIt: PropTypes.func.isRequired,
};

export default PortioningLabeling;
