import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { TO_DO, ACKNOWLEDGED } from 'lib/constants';
import { CloseIcon } from 'components/icons';

import CancelDialog from './CancelDialog';
import ChangeDropDialog from './ChangeDropDialog';

const UpdateDialog = ({
  open,
  closeDialog,
  toggleDrawer,
  rowData,
  classes,
}) => {
  const [cancelNextstep, setCancelNextStep] = useState(false);
  const [changeDropNextstep, setChangeDropNextStep] = useState(false);

  const showButtonStatus = [TO_DO, ACKNOWLEDGED];

  const renderOptions = () => {
    return showButtonStatus.includes(rowData.status) ? (
      <>
        <Button
          variant="contained"
          className={classes.changeButton}
          onClick={() => {
            toggleDrawer(false);
            setChangeDropNextStep(true);
          }}
        >
          Change Drop Location
        </Button>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={() => {
            toggleDrawer(false);
            setCancelNextStep(true);
          }}
        >
          Cancel Request
        </Button>
      </>
    ) : (
      <div className={classes.cantRequestText}>
        This request can no longer be changed.{' '}
        <span role="img" aria-label="sad">
          😢
        </span>
      </div>
    );
  };

  return (
    rowData && (
      <>
        <Dialog
          onClose={closeDialog}
          open={open && !cancelNextstep}
          className={classes.root}
        >
          <Button className={classes.closeButton} onClick={closeDialog}>
            <CloseIcon className={classes.icon} />
          </Button>
          <div className={classes.bold}>{rowData.name}</div>
          <div className={classes.text}>
            Requested Amount: {rowData.quantityRequested}
          </div>
          <div className={classes.text}>Requested By: {rowData.submitter}</div>
          <ArrowDownwardIcon className={classes.arrow} />
          <div className={classes.dropLocation}>{rowData.dropOffLocation}</div>
          <div id="options" className={classes.buttonGroup}>
            {renderOptions()}
          </div>
        </Dialog>
        <CancelDialog
          showCancel={cancelNextstep}
          setCancelNextStep={setCancelNextStep}
          rowData={rowData}
        />
        <ChangeDropDialog
          showChangeDrop={changeDropNextstep}
          setChangeDropNextStep={setChangeDropNextStep}
          rowData={rowData}
        />
      </>
    )
  );
};

UpdateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  rowData: PropTypes.object,
};

export default UpdateDialog;
