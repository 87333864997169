import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import {
  DROP_ZONE,
  ITEM_MANAGER_POD_PUTBACK,
  INITIAL_PUTBACK_QUANTITY,
  ITEM_MANAGER_NOT_LESS_THAN_ZERO,
  ITEM_MANAGER_GREATER_THAN_ZERO,
  ITEM_MANAGER_QUANTITY_REQUIRED,
  ITEM_MANAGER_NEW_LOCATION_REQUIRED,
  ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY,
} from 'lib/constants';

import FormDrawer from '../formComponents/FormDrawer';
import FormLocationSelectors from '../formComponents/FormLocationSelectors';
import CaseQuantity from '../formComponents/CaseQuantity';
import SubmitButtonWrapper from '../formComponents/SubmitButtonWrapper';

const PodPutbackForm = ({ productionZoneItem, buttonStyles }) => {
  const [open, toggleOpen] = useState(false);

  const allLocations = useSelector((state) => state.podLocations?.pods);

  const dropzoneLocations = allLocations.filter(
    (location) => location.type === DROP_ZONE
  );

  const validationSelections = [
    ITEM_MANAGER_NOT_LESS_THAN_ZERO,
    ITEM_MANAGER_GREATER_THAN_ZERO,
    ITEM_MANAGER_QUANTITY_REQUIRED,
    ITEM_MANAGER_NEW_LOCATION_REQUIRED,
    ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY,
  ];

  return (
    <>
      <Button
        className={buttonStyles}
        variant="contained"
        onClick={() => toggleOpen(true)}
      >
        Return
      </Button>
      <FormDrawer
        open={open}
        toggleOpen={toggleOpen}
        headerText="Return Item"
        productionZoneItem={productionZoneItem}
      >
        <CaseQuantity
          promptText="How many cases are you returning?"
          initialQuantity={INITIAL_PUTBACK_QUANTITY}
          maxValue={productionZoneItem.quantity}
        />
        <FormLocationSelectors
          locations={dropzoneLocations}
          defaultSelectionId={productionZoneItem.previousZoneId}
        />
        <SubmitButtonWrapper
          buttonText="Return"
          onSuccess={toggleOpen}
          moveType={ITEM_MANAGER_POD_PUTBACK}
          validationSelections={validationSelections}
        />
      </FormDrawer>
    </>
  );
};

PodPutbackForm.defaultProps = {
  buttonStyles: '',
};

PodPutbackForm.propTypes = {
  productionZoneItem: PropTypes.object.isRequired,
  buttonStyles: PropTypes.string,
};

export default PodPutbackForm;
