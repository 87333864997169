import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useTabOutline } from 'lib/custom_hooks';

const PickableRow = ({ classes, history, disableRowLink, selectedMenu }) => {
  const [handleMouseDown, handleBlur, style] = useTabOutline({});

  return (
    <Link
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
      style={style}
      className={classNames(classes.tableRow, {
        [classes.darkStripe]: true,
      })}
      tabIndex={0}
      to={{
        pathname: disableRowLink
          ? null
          : `${history.location.pathname}/pickables`,
        state: { selectedMenu: selectedMenu },
      }}
    >
      <span
        className={classNames(classes.rowMarker, {
          [classes.green]: true,
        })}
        role="img"
        aria-label="pickables"
      >
        📦
      </span>
      <span className={classes.mealName}>Pickables</span>
    </Link>
  );
};
PickableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  disableRowLink: PropTypes.bool,
  selectedMenu: PropTypes.object.isRequired,
};

PickableRow.defaultProps = {
  disableRowLink: false,
};

export default PickableRow;
