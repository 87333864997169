import { GREY_800 } from 'lib/constants';

export default {
  toolbar: {
    justifyContent: 'flex-end',
    color: GREY_800,
    padding: '0 10px',
  },
  tableHeader: {
    marginBottom: '-44px',
  },
};
