import React from 'react';

export const titleize = (sentence) => {
  if (!sentence.split) return sentence;
  const titleizeWord = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    result = [];

  sentence
    .replace('_', ' ')
    .split(' ')
    .forEach(function (w) {
      result.push(titleizeWord(w));
    });
  return result.join(' ');
};

export const pluralize = (count, noun, suffix = 's') => {
  return `${count} ${noun}${Math.abs(count) !== 1 ? suffix : ''}`;
};

/**
 *
 Util static method to add ellipsis to the end of a string.  Method does subtract 3 characters
 to include the ellisis in the total counted characters
  @param {string} text
  @param {string} totalCharacters Total characters including elipsis, to be returned.  Defaults to 20.
  @returns {String} The string if under the total character limit or a new string ellipsized
  **/
export const ellipseText = (text, totalCharacters = 20) => {
  return text.length > totalCharacters
    ? text.substr(0, totalCharacters - 3) + '...'
    : text;
};

export const insertBoldBeginning = (text, className = '') => {
  if (!text) {
    return text;
  }
  const lastSpaceIndex = text.lastIndexOf(' ');
  if (lastSpaceIndex === -1) {
    return text;
  }

  return (
    <span className={className}>
      <b>{text.substring(0, lastSpaceIndex)}</b>
      {text.substring(lastSpaceIndex)}
    </span>
  );
};

export const getFirstAndLastInitials = (name) => {
  if (name.length < 1) {
    return '';
  }
  const parts = name.split(' ');
  let initials = parts[0][0];
  if (parts.length > 1) {
    initials += parts[parts.length - 1][0];
  }
  return initials.toUpperCase();
};
