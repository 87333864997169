import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// storing this value OUTSIDE of a react component, since it is just placeholder that needs to persist between renders without affecting anything else when it's changed
let scrollPosition = 0;
const useScrolling = () => {
  const history = useHistory();

  const scrollToPreviousPosition = useCallback(() => {
    window.scrollTo(0, scrollPosition);
    scrollPosition = 0;
  }, []);

  // NOTE: history.listen returns a function to turn off the listner
  // any function returned in a hook will be called when the component unmounts
  // therefore returning history.listen directly will handle turning off the listner
  useEffect(
    () =>
      history.listen((location, action) => {
        if (action === 'PUSH') {
          scrollPosition = window.scrollY;
        }
      }),
    [history]
  );

  return {
    scrollToPreviousPosition,
  };
};

export default useScrolling;
