import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

import { sortByLabelOrder } from 'lib/viewHelpers';

import MealRow from './MealRow';
import IngredientRow from './IngredientRow';
import styles from './styles';

const DetailedOverview = ({
  meals,
  classes,
  customizeItIngredients,
  isPartOfStandardMenu,
}) => {
  const columnHeaderProps = {
    className: classnames(
      classes.detailedOverviewHeaderRow,
      classes.alignCenter
    ),
    item: true,
  };

  return (
    <Link
      underline="none"
      color="inherit"
      className={classes.routerLink}
      component={RouterLink}
      to={{
        pathname: '/progress_tracker',
      }}
    >
      <Card
        className={classes.detailedOverviewCard}
        data-testid="detailed-overview-container"
      >
        <Grid container spacing={0}>
          <Grid xs={1} {...columnHeaderProps}>
            #
          </Grid>
          <Grid item xs={2} className={classes.detailedOverviewHeaderRow}>
            Meal Name
          </Grid>
          <Grid xs={3} {...columnHeaderProps}>
            Labeling
          </Grid>
          <Grid xs={3} {...columnHeaderProps}>
            Portioning
          </Grid>
          <Grid xs={3} {...columnHeaderProps}>
            Bagging
          </Grid>
          {sortByLabelOrder(meals)
            .concat(customizeItIngredients)
            .map((row, index) => {
              if (row.meal_id) {
                return (
                  <MealRow
                    key={row.meal_id}
                    meal={row}
                    index={index}
                    isPartOfStandardMenu={isPartOfStandardMenu}
                  />
                );
              } else {
                return (
                  <IngredientRow key={row.id} ingredient={row} index={index} />
                );
              }
            })}
        </Grid>
      </Card>
    </Link>
  );
};

DetailedOverview.defaultProps = {
  customizeItIngredients: [],
  meals: [],
};

DetailedOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  meals: PropTypes.array,
  customizeItIngredients: PropTypes.array,
  isPartOfStandardMenu: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DetailedOverview);
