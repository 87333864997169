import {
  MEDIA_QUERY_TABLET_OR_SMALLER,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    outline: 'none',
  },
  textField: {
    margin: 'auto',
    width: '70%',
    marginTop: '10px',
    marginBottom: '10px',
  },
  header: {
    fontWeight: 'bolder',
    paddingBottom: '5vh',
    textAlign: 'center',
    fontSize: '5vw',
  },

  container: {
    paddingTop: '8vh',
    width: '80%',
    margin: 'auto',
  },
  svgWrapper: {
    width: '80%',
    margin: 'auto',
  },
  helpMessage: {
    margin: '30px 15px 0px 15px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    textField: {
      opacity: 0,
      height: 0,
    },
    input: {
      height: 0,
      padding: 0,
    },
    scanningArea: {
      width: '33%',
      marginTop: '30px',
    },
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    scanText: {
      fontSize: '1.0em',
    },
  },
};
