import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';

export const selectQaPalletData = (state, palletId) =>
  state.pallets.qaApp.pallets.find((p) => p.id === palletId);

export const selectPalletsInSameGroup = (state, palletId) =>
  state.pallets.qaApp.groupedIngredientsPallets.find(
    (group) => !!group.pallets.find((pallet) => pallet.id === palletId)
  )?.pallets || [];

export const selectLotCodesForAGroup = (state, palletId) => {
  const allPallets = selectPalletsInSameGroup(state, palletId);
  const allLotCodes = allPallets.flatMap((pallet) => pallet.lotCodes || []);
  return uniq(allLotCodes);
};

export const selectExpectedAllergensByGroup = (state, palletId) => {
  const expectedAllergenIds = selectQaPalletData(state, palletId).allergenIds;
  const allAllergens = state.pallets.qaApp.allAllergens;

  const expectedAllergensInfo = expectedAllergenIds.map((id) =>
    allAllergens.find(
      (allergenInfo) =>
        allergenInfo.categoreId === id || allergenInfo.allergies?.includes(id)
    )
  );

  return groupBy(expectedAllergensInfo, 'groupId');
};
