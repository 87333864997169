import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import ContentModal from 'components/shared/ContentModal';
import { TabOutlineButton } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const DepthOptionsSelector = ({
  selectedDepthOptionId,
  setSelectedDepthOption,
  depthOptions,
}) => {
  if (depthOptions.length === 1 && !depthOptions[0].atMaxPallets) {
    setSelectedDepthOption(depthOptions[0]);
  }
  const classes = useStyles();
  const [selectedWarningDepthOption, setSelectedWarningDepthOption] = useState(
    null
  );

  const showWarningModal = !!selectedWarningDepthOption;

  const formatDepthValue = (depth) => {
    let depthValue = depth.name.slice(-2);

    if (isNaN(depthValue[0])) {
      depthValue = depthValue.split(depthValue[0]).pop();
    }

    return depthValue;
  };

  const onSetSelectedDepth = (depth) => {
    if (depth.atMaxPallets) {
      setSelectedWarningDepthOption(depth);
    } else {
      setSelectedDepthOption(depth);
    }
  };

  const renderPalletsForWarningSelectedDepth = (ids = []) => {
    return (
      <ul>
        {ids.map((palletId) => (
          <li key={palletId}>
            <Link
              className={classes.link}
              to={`/inventory-manager/pallets/${palletId}`}
            >
              {palletId}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  const hasMaxPallets = depthOptions.some((depth) => depth.atMaxPallets);

  return (
    <div data-testid="depth-options-selector">
      {hasMaxPallets && (
        <div className={classes.maxPalletText} data-testid="max-pallet-text">
          Click on slot depth to see pallets in this location
        </div>
      )}
      <div className={classes.depthButtonContainer}>
        {depthOptions.map((depth) => (
          <TabOutlineButton
            type="radio"
            key={depth.id}
            className={classNames(classes.depthButton, {
              [classes.selectedDepth]: depth.id === selectedDepthOptionId,
              [classes.maxedPallets]: depth.atMaxPallets,
            })}
            onClick={() => onSetSelectedDepth(depth)}
          >
            {formatDepthValue(depth)}
          </TabOutlineButton>
        ))}
      </div>
      <ContentModal
        open={showWarningModal}
        onClose={() => setSelectedWarningDepthOption(null)}
        title="This slot is maxed out with the following pallets:"
      >
        {showWarningModal &&
          renderPalletsForWarningSelectedDepth(
            selectedWarningDepthOption.palletIds
          )}
      </ContentModal>
    </div>
  );
};

DepthOptionsSelector.propTypes = {
  selectedDepthOptionId: PropTypes.number,
  setSelectedDepthOption: PropTypes.func.isRequired,
  depthOptions: PropTypes.array.isRequired,
};

DepthOptionsSelector.defaultProps = {
  selectedDepthOptionId: null,
};

export default DepthOptionsSelector;
