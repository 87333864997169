import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getFsqaReview = async ({ palletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/fsqa_reviews/`,
    options
  );

  return await handleResponse(response);
};

const postFsqaReview = async ({ fsqaReview, palletId, packingFacilityId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/fsqa_reviews/`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        fsqa_review: fsqaReview,
        packing_facility_id: packingFacilityId,
      }),
    }
  );
  return await handleResponse(response);
};

const putFsqaReview = async ({ updatedInfo, palletId, packingFacilityId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/fsqa_reviews/`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        fsqa_review: updatedInfo,
        packing_facility_id: packingFacilityId,
      }),
    }
  );

  return await handleResponse(response);
};
export default {
  getFsqaReview,
  postFsqaReview,
  putFsqaReview,
};
