import React from 'react';
import PropTypes from 'prop-types';

const ArrowDownIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="white" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  );
};

ArrowDownIcon.propTypes = {
  className: PropTypes.string,
};

ArrowDownIcon.defaultProps = {
  className: '',
};
export default ArrowDownIcon;
