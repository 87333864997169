import { useState, useCallback, useMemo, useEffect } from 'react';

const useSelectionDrawer = (
  data,
  options,
  onCloseCallback = null,
  defaultSelectedItem = null
) => {
  const [drawerOpen, toggleDrawer] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
  // onRowClick returns that row as the first argument, but as an array. Using that feels brittle
  // So instead we use the second arg, which gives us the original index of the clicked row
  const onRowClick = useCallback(
    (_, rowData) => {
      toggleDrawer(true);

      setSelectedItem(data[rowData.dataIndex]);
    },
    [toggleDrawer, setSelectedItem, data]
  );

  // If data updates, update selected item so up to date
  useEffect(
    () => {
      if (selectedItem && selectedItem !== defaultSelectedItem) {
        const item = data.find((orderItem) => orderItem.id === selectedItem.id);

        setSelectedItem(item);
      }
    },
    // eslint-disable-next-line
    [data]
  );
  const onDrawerClose = useCallback(() => {
    toggleDrawer(false);
    onCloseCallback && onCloseCallback();
    setSelectedItem(defaultSelectedItem);
  }, [toggleDrawer, setSelectedItem, onCloseCallback, defaultSelectedItem]);
  const optionsWithRowClick = useMemo(
    () => ({
      ...options,
      onRowClick,
    }),
    [onRowClick, options]
  );
  const onForceDrawerOpen = useCallback(
    (itemId) => {
      const item = data.find((datum) => datum.id === itemId);
      if (item) {
        toggleDrawer(true);

        setSelectedItem(item);
      }
    },
    [toggleDrawer, setSelectedItem, data]
  );

  return {
    drawerOpen,
    selectedItem,
    optionsWithRowClick,
    onDrawerClose,
    onForceDrawerOpen,
    toggleDrawer,
  };
};

export default useSelectionDrawer;
