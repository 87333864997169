import React, { useState, Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import UpDownIcon from '@mui/icons-material/SwapVerticalCircle';

import {
  HC_GREEN,
  QUANTITY_REMOVAL_OPTIONS,
  QUANTITY_INCREASE_OPTIONS,
  WASTE_CORRECTION,
  PUTBACK_TYPE,
} from 'lib/constants';
import QuantityCalculator from 'components/shared/QuantityCalculator';

const QuantityChangeModal = ({
  classes,
  onSubmit,
  onPageDownCallback,
  quantity,
  calculatorDisabled,
  measuringUnit,
  setCalculatorOpen,
  calculatorOpen,
  includeWasteCorrection,
}) => {
  const ADD = 'ADD';
  const REMOVE = 'REMOVE';
  const DEFAULT = 'DEFAULT';

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [displayedReasons, setDisplayedReasons] = useState([]);
  const [reasonsModalOpen, setReasonsModalOpen] = useState(false);
  const [modalPromptState, setModalPromptState] = useState('');

  const selectedReasons = useRef([]);
  const activeReason = useRef({});

  const submittingQuantity = useRef();

  // What happens when you submit on the calculator
  const onCalculatorSubmit = (_inputValue, result) => {
    submittingQuantity.current = result;
    selectedReasons.current = [];
    activeReason.current = {};

    let chosenReasons;
    if (result >= quantity) {
      if (includeWasteCorrection) {
        chosenReasons = [
          ...QUANTITY_INCREASE_OPTIONS,
          { type: WASTE_CORRECTION, requiresConfirmation: true },
        ];
      } else {
        chosenReasons = QUANTITY_INCREASE_OPTIONS;
      }
      setModalPromptState(ADD);
    } else {
      chosenReasons = QUANTITY_REMOVAL_OPTIONS;
      setModalPromptState(REMOVE);
    }
    setDisplayedReasons(chosenReasons);
    setReasonsModalOpen(true);
    setCalculatorOpen(false);
  };

  const closeAllModals = () => {
    setReasonsModalOpen(false);
    setConfirmationModalOpen(false);
  };

  // When we're ready to submit
  const onQuantityReasonSubmit = () => {
    closeAllModals();

    onSubmit({
      quantity: submittingQuantity.current,
      change_reason_type: selectedReasons.current[0].type,
      change_reason: selectedReasons.current[1]?.type,
    });
  };

  // What happens when you click any reason.
  const onReasonTypeClick = (selectedOption) => {
    selectedReasons.current = selectedReasons.current.concat(selectedOption);

    if (selectedOption.requiresConfirmation) {
      setConfirmationModalOpen(true);
    } else if (selectedOption.reasons) {
      activeReason.current = selectedOption;
      setModalPromptState(DEFAULT);
      setDisplayedReasons(selectedOption.reasons);
    } else if (!selectedOption.reasons) {
      onQuantityReasonSubmit();
    }
  };

  // Display helpers
  const linkToPutbackFeature = () => {
    return (
      <div className={classes.putbackFeatureLink}>
        <Link
          to="/warehouse_moves/putbacks"
          data-testid="putback-feature-link"
          underline="none"
          color={HC_GREEN}
          component={RouterLink}
        >
          <span className={classes.title}>Go to Item Putback Feature</span>
        </Link>
      </div>
    );
  };

  const displayText = () => {
    if (activeReason.current?.subReasonText) {
      return activeReason.current.subReasonText;
    } else if (modalPromptState === ADD) {
      return 'Select addition reason:';
    } else if (modalPromptState === REMOVE) {
      return 'Select removal reason:';
    } else if (modalPromptState === DEFAULT) {
      return 'Select reason:';
    }
  };

  // Callbacks for calculator
  const onQuantityInputBlur = useCallback(() => {
    document.addEventListener('keydown', onPageDownCallback, true);
  }, [onPageDownCallback]);

  const onQuantityInputFocus = useCallback(
    (e) => {
      document.removeEventListener('keydown', onPageDownCallback, true);

      e.target.select();
    },
    [onPageDownCallback]
  );

  return (
    <Fragment>
      <IconButton
        disabled={calculatorDisabled}
        onClick={() => setCalculatorOpen(true)}
        classes={{
          root: classes.openButton,
          disabled: classes.buttonDisabled,
        }}
        data-testid="quantity-calculator-icon-btn"
        size="large"
      >
        <UpDownIcon className={classes.openIcon} />
      </IconButton>
      <QuantityCalculator
        selectedOperators={['subtract', 'add', 'set']}
        defaultOperator="subtract"
        submitText="Set Qty to $"
        submitValueType="result"
        disabled={calculatorDisabled}
        quantity={quantity}
        submitQuantity={onCalculatorSubmit}
        closeModal={() => setCalculatorOpen(false)}
        isOpen={calculatorOpen}
        onInputBlur={onQuantityInputBlur}
        onInputFocus={onQuantityInputFocus}
        measuringUnit={measuringUnit}
      />
      <Dialog
        open={reasonsModalOpen}
        data-testid="reasons-modal"
        onClose={closeAllModals}
        classes={{ paperScrollPaper: classes.muiPaper }}
      >
        <Fragment>
          <DialogContent classes={{ root: classes.promptContainer }}>
            <DialogContentText classes={{ root: classes.promptText }}>
              {displayText()}
            </DialogContentText>
            <div className={classes.putbackFeatureLink}>
              {activeReason.current.type === PUTBACK_TYPE
                ? linkToPutbackFeature()
                : null}
            </div>
          </DialogContent>
          <DialogContent classes={{ root: classes.optionsContainer }}>
            {displayedReasons.map((option) => (
              <Button
                key={option.type}
                classes={{ root: classes.modalButtons }}
                onClick={() => onReasonTypeClick(option)}
              >
                {option.type}
              </Button>
            ))}
          </DialogContent>
        </Fragment>
      </Dialog>
      <Dialog id="confirmationDialog" open={confirmationModalOpen}>
        <DialogContent>
          <DialogContentText classes={{ root: classes.confirmationText }}>
            Are you sure this is a waste correction?
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <div className={classes.confirmationButtonContainer}>
            <Button
              id="confirmationNoButton"
              onClick={() => setConfirmationModalOpen(false)}
              variant="outlined"
              className={classes.confirmationButton}
            >
              No
            </Button>
            <Button
              id="confirmationYesButton"
              onClick={onQuantityReasonSubmit}
              variant="outlined"
              className={classes.confirmationButton}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

QuantityChangeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPageDownCallback: PropTypes.func.isRequired,
  calculatorDisabled: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
  measuringUnit: PropTypes.string.isRequired,
  includeWasteCorrection: PropTypes.bool,
  calculatorOpen: PropTypes.bool.isRequired,
  setCalculatorOpen: PropTypes.func.isRequired,
};

QuantityChangeModal.defaultProps = {
  includeWasteCorrection: false,
};

export default QuantityChangeModal;
