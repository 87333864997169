import {
  PRODUCTION_ZONE_ITEMS_GET_REQUEST,
  PRODUCTION_ZONE_ITEMS_GET_SUCCESS,
  PRODUCTION_ZONE_ITEMS_GET_ERROR,
  PRODUCTION_ZONE_ITEM_UPDATE_REQUEST,
  PRODUCTION_ZONE_ITEM_UPDATE_SUCCESS,
  PRODUCTION_ZONE_ITEM_UPDATE_ERROR,
  CHANGEOVER_ZONE_REQUEST,
  CHANGEOVER_ZONE_SUCCESS,
  CHANGEOVER_ZONE_ERROR,
} from 'lib/constants';

const initialState = {
  loading: false,
  error: false,
  productionZoneItems: [],
  updateLoading: false,
  updateError: false,
  changeoverLoading: false,
  changeoverError: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case CHANGEOVER_ZONE_REQUEST:
      return {
        ...state,
        changeoverLoading: true,
        changeoverError: false,
      };
    case CHANGEOVER_ZONE_SUCCESS:
      return {
        ...state,
        changeoverLoading: false,
        changeoverError: false,
        productionZoneItems: action.response.productionZoneItems,
      };
    case CHANGEOVER_ZONE_ERROR:
      return {
        ...state,
        changeoverLoading: false,
        changeoverError: true,
      };
    case PRODUCTION_ZONE_ITEMS_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        productionZoneItems: [],
      };
    case PRODUCTION_ZONE_ITEMS_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        productionZoneItems: [],
      };
    case PRODUCTION_ZONE_ITEMS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        productionZoneItems: action.response.productionZoneItems,
      };
    case PRODUCTION_ZONE_ITEM_UPDATE_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
      };
    case PRODUCTION_ZONE_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        productionZoneItems: action.response.productionZoneItems,
        updateLoading: false,
        updateError: false,
      };
    case PRODUCTION_ZONE_ITEM_UPDATE_ERROR:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
      };
    default:
      return state;
  }
}
