const ONE_HOUR_IN_MS = 1 * 60 * 60 * 1000;

// this will filter out any group of pallets that are completed and over an hour old and only return incomplete groups or completed groups less than an hour old
export function filterGroupByTime(palletGroups) {
  return palletGroups.filter(({ groupInfo }) => {
    return (
      !groupInfo.groupCompleted ||
      Date.now() - new Date(groupInfo.latestCheckTime).getTime() <
        ONE_HOUR_IN_MS
    );
  });
}
