import isEmpty from 'lodash/isEmpty';

import {
  INVENTORY_PALLET_GET_REQUEST,
  INVENTORY_PALLET_GET_SUCCESS,
  INVENTORY_PALLET_GET_ERROR,
  INVENTORY_PALLET_PUT_REQUEST,
  INVENTORY_PALLET_PUT_SUCCESS,
  INVENTORY_PALLET_PUT_ERROR,
  INVENTORY_PALLET_DEACTIVATE_REQUEST,
  INVENTORY_PALLET_DEACTIVATE_SUCCESS,
  INVENTORY_PALLET_DEACTIVATE_ERROR,
  INVENTORY_PALLET_HOLD_REQUEST,
  INVENTORY_PALLET_HOLD_SUCCESS,
  INVENTORY_PALLET_HOLD_ERROR,
  INVENTORY_PALLET_REACTIVATE_REQUEST,
  INVENTORY_PALLET_REACTIVATE_SUCCESS,
  INVENTORY_PALLET_REACTIVATE_ERROR,
  INVENTORY_PALLET_RESET_SELECTED,
  STORAGE_SLOT_DEPTHS_SUCCESS,
  RESET_STORAGE_SLOTS,
  INVENTORY_PALLET_QUERY_GET_REQUEST,
  INVENTORY_PALLET_QUERY_GET_SUCCESS,
  INVENTORY_PALLET_QUERY_GET_ERROR,
  INVENTORY_PALLETS_TOGGLE_SEARCH,
  INVENTORY_PALLETS_CLEAR_SEARCH,
  INVENTORY_PALLETS_STORE_SEARCH,
  MISSING_SLOT_PALLETS_REQUEST,
  MISSING_SLOT_PALLETS_SUCCESS,
  MISSING_SLOT_PALLETS_ERROR,
  RESET_MISSING_SLOT_PALLETS,
} from 'lib/constants';

const initialState = {
  deactivating: false,
  deactivationFailed: false,
  holding: false,
  holdFailed: false,
  auditing: false,
  auditFailed: false,
  reactivating: false,
  reactivationFailed: false,
  selectedPallet: {},
  depthOptions: [],
  fetching: false,
  fetchFailed: false,
  updating: false,
  updateFailed: false,
  searchDrawerOpen: false,
  palletFetchingQuery: false,
  palletFetchQueryFailed: false,
  queryResults: [],
  queryTerm: '',
  newLocationTempMatch: null,
  fetchingMissingPallets: false,
  fetchingMissingPalletsFailed: false,
  missingPallets: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case INVENTORY_PALLET_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchFailed: false,
        selectedPallet: {},
        measuringUnitsForSelectedPallet: [],
      };
    case INVENTORY_PALLET_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        selectedPallet: action.response.pallet,
        measuringUnitsForSelectedPallet:
          action.response.measuringUnitsForSelectedPallet,
      };
    case INVENTORY_PALLET_GET_ERROR:
      return {
        ...state,
        fetching: false,
        fetchFailed: true,
      };
    case INVENTORY_PALLET_PUT_REQUEST:
      return {
        ...state,
        updating: true,
        updateFailed: false,
      };
    case INVENTORY_PALLET_PUT_SUCCESS:
      return {
        ...state,
        updating: false,
        selectedPallet: action.response.pallet,
      };
    case INVENTORY_PALLET_PUT_ERROR:
      return {
        ...state,
        updating: false,
        updateFailed: true,
      };
    case INVENTORY_PALLET_RESET_SELECTED:
      return {
        ...state,
        selectedPallet: {},
        depthOptions: [],
      };

    case INVENTORY_PALLET_DEACTIVATE_REQUEST:
      return {
        ...state,
        deactivating: true,
        deactivationFailed: false,
      };
    case INVENTORY_PALLET_DEACTIVATE_SUCCESS:
      return {
        ...state,
        deactivating: false,
        deactivationFailed: false,
      };
    case INVENTORY_PALLET_DEACTIVATE_ERROR:
      return {
        ...state,
        deactivating: false,
        deactivationFailed: true,
      };

    case INVENTORY_PALLET_HOLD_REQUEST:
      return {
        ...state,
        holding: true,
        holdFailed: false,
      };
    case INVENTORY_PALLET_HOLD_SUCCESS:
      return {
        ...state,
        holding: false,
        holdFailed: false,
        selectedPallet: action.response.pallet,
      };
    case INVENTORY_PALLET_HOLD_ERROR:
      return {
        ...state,
        holding: false,
        holdFailed: true,
      };

    case INVENTORY_PALLET_REACTIVATE_REQUEST:
      return {
        ...state,
        reactivating: true,
        reactivationFailed: false,
      };
    case INVENTORY_PALLET_REACTIVATE_SUCCESS:
      return {
        ...state,
        reactivating: false,
        reactivationFailed: false,
        selectedPallet: action.response.pallet,
      };
    case INVENTORY_PALLET_REACTIVATE_ERROR:
      return {
        ...state,
        reactivating: false,
        reactivationFailed: true,
      };

    case RESET_STORAGE_SLOTS:
      return {
        ...state,
        depthOptions: [],
        newLocationTempMatch: null,
      };
    case STORAGE_SLOT_DEPTHS_SUCCESS:
      if (isEmpty(state.selectedPallet)) {
        return state;
      }
      return {
        ...state,
        depthOptions: action.response.storageSlots,
        newLocationTempMatch: action.response.temperatureMatches,
      };
    case INVENTORY_PALLET_QUERY_GET_REQUEST:
      return {
        ...state,
        palletFetchingQuery: true,
        palletFetchQueryFailed: false,
      };
    case INVENTORY_PALLET_QUERY_GET_SUCCESS:
      return {
        ...state,
        palletFetchingQuery: false,
        queryResults: action.response.pallets,
      };
    case INVENTORY_PALLET_QUERY_GET_ERROR:
      return {
        ...state,
        palletFetchingQuery: false,
        palletFetchQueryFailed: true,
      };
    case INVENTORY_PALLETS_TOGGLE_SEARCH:
      return {
        ...state,
        searchDrawerOpen: !state.searchDrawerOpen,
      };
    case INVENTORY_PALLETS_CLEAR_SEARCH:
      return {
        ...state,
        queryResults: [],
      };
    case INVENTORY_PALLETS_STORE_SEARCH:
      return {
        ...state,
        queryTerm: action.payload.searchQuery,
      };
    case MISSING_SLOT_PALLETS_REQUEST:
      return {
        ...state,
        fetchingMissingPallets: true,
      };
    case MISSING_SLOT_PALLETS_SUCCESS:
      return {
        ...state,
        fetchingMissingPallets: false,
        missingPallets: action.response.pallets,
      };
    case MISSING_SLOT_PALLETS_ERROR:
      return {
        ...state,
        fetchingMissingPallets: false,
        fetchingMissingPalletsFailed: true,
      };
    case RESET_MISSING_SLOT_PALLETS:
      return {
        ...state,
        missingPallets: [],
      };
    default:
      return state;
  }
}
