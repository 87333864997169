// below is to make sure each item can scroll independently; (paddingBottom is to account for header offset)
export default {
  container: {
    height: '100vh',
    position: 'fixed',
  },
  item: {
    height: '100%',
    overflow: 'auto',
    paddingBottom: '55px',
    backgroundColor: '#F7F9FB',
  },
  leftRail: {
    zIndex: 2,
    boxShadow: '4px 0 5px -2px #d8dadc',
    backgroundColor: '#fff',
  },
  itemHeader: {
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 3,
    padding: '15px',
    boxShadow: '0px 0px 0px 1px #E0E0E0',
  },
  cornerButton: {
    position: 'absolute',
    bottom: '25px',
    right: '25px',
  },
};
