import {
  GET_PORTIONING_TRACKER_PROGRESS_REQUEST,
  GET_PORTIONING_TRACKER_PROGRESS_SUCCESS,
  GET_PORTIONING_TRACKER_PROGRESS_ERROR,
} from 'lib/constants';

const initialState = {
  ingredientsFetching: false,
  ingredientsFailed: false,
  ingredients: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case GET_PORTIONING_TRACKER_PROGRESS_REQUEST:
      return {
        ...state,
        ingredientsFetching: true,
        ingredientsFailed: false,
      };
    case GET_PORTIONING_TRACKER_PROGRESS_SUCCESS:
      return {
        ...state,
        ingredients: action.response.ingredients,
        ingredientsFetching: false,
        ingredientsFailed: false,
      };

    case GET_PORTIONING_TRACKER_PROGRESS_ERROR:
      return {
        ...state,
        ingredientsFetching: false,
        ingredientsFailed: true,
      };
    default:
      return state;
  }
}
