import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Checkbox from '@mui/material/Checkbox';

import { useActivityIngredients } from 'lib/custom_hooks';
import { StatefulButton } from 'components/shared';
import {
  submitIngredientProgress,
  updateIngredientProgress,
} from 'redux/productionProgress/rth/actions';

import styles from './styles';
import FormDrawer from '../formComponents/FormDrawer';
import { PrinterContext } from '../../PrinterProvider';
import SublotCard from '../../SublotCard';

const useStyles = makeStyles(styles);

const SlackingForm = ({ selectedProgress, open, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getMealIdsByIngredientId } = useActivityIngredients();

  const [numberOfBags, setnumberOfBags] = useState('');
  const [palletId, setPalletId] = useState('');
  const [isCarryover, setIsCarryover] = useState(false);

  const {
    submitIngredientProgressFetching,
    submitIngredientProgressFailed,
  } = useSelector((state) => state.rthProductionProgress);

  const { setPrinterDialogOpen, setPrintSubmission } = useContext(
    PrinterContext
  );

  const buttonText = {
    DEFAULT: 'CONFIRM',
    SUCCESS: 'Success!',
    LOADING: 'SAVING...',
    ERROR: 'ERROR - Try Again',
  };

  const resetForm = () => {
    setnumberOfBags('');
    setPalletId('');
    setIsCarryover(false);
  };

  const onToggleOpen = () => {
    resetForm();
    toggleOpen(false);
  };

  const printLabels = (sublotId, submissionCount, isReprint = false) => {
    const printData = {
      progress: selectedProgress,
      submissionCount,
      isReprint,
      sublotId,
    };
    setPrintSubmission(printData);
    setPrinterDialogOpen(true);
  };

  const onSubmit = async () => {
    const mealIds = getMealIdsByIngredientId(selectedProgress.ingredientId);

    const data = {
      progressId: selectedProgress.id,
      count: numberOfBags,
      carryover: isCarryover,
      palletId,
      mealIds,
    };

    const response = await dispatch(submitIngredientProgress(data));

    if (response.ok) {
      printLabels(response.activity.id, numberOfBags);
    }
  };

  const onUpdate = async (data) => {
    data.progressId = selectedProgress.id;
    data.mealIds = getMealIdsByIngredientId(selectedProgress.ingredientId);

    const response = await dispatch(updateIngredientProgress(data));
    response.ok && resetForm();

    return response;
  };

  return (
    <>
      <FormDrawer open={open} toggleOpen={onToggleOpen} drawerWidth="66%">
        <div className={classes.slackingDrawerContainer}>
          <div className={classes.slackingDrawerHeader}>
            <div className={classes.slackingDrawerTitle}>
              {selectedProgress.ingredientName} -{' '}
              {selectedProgress.ingredientId}
            </div>
            <div className={classes.slackingDrawerSubtitle}>
              {selectedProgress.mealLetter} - {selectedProgress.mealTitle}
            </div>
          </div>
          <div className={classes.questionContainer}>
            <div>How many bags are being slacked?</div>
            <TextField
              label="Number of Bags"
              type="number"
              variant="outlined"
              value={numberOfBags}
              onChange={(e) => setnumberOfBags(e.target.value)}
            />
          </div>
          <div className={classes.questionContainer}>
            <div>What is the pallet id?</div>
            <TextField
              label="Pallet Id"
              type="number"
              variant="outlined"
              value={palletId}
              onChange={(e) => setPalletId(e.target.value)}
            />
          </div>
          <div className={classes.carryoverQuestionContainer}>
            <div>Is this carryover?</div>
            <Checkbox
              onChange={(e) => setIsCarryover(e.target.value === 'on')}
              inputProps={{ 'aria-label': 'carryover-checkbox' }}
            />
          </div>
          <div className={classes.buttonContainer}>
            <StatefulButton
              classes={{ root: classes.slackingFormSubmitButton }}
              type="submit"
              disabled={!numberOfBags || !palletId}
              loading={submitIngredientProgressFetching}
              failed={submitIngredientProgressFailed}
              buttonTextOptions={buttonText}
              onClick={onSubmit}
              successCallback={onToggleOpen}
            />
          </div>
          {!isEmpty(selectedProgress?.completedSublots) && (
            <>
              <hr className={classes.formDivider} />
              <div className={classes.sublotDetails}>
                <div className={classes.sublotHeader}>
                  Print Labels for Completed Sublots
                </div>
                {selectedProgress.completedSublots.map((sublot) => {
                  return (
                    <div className={classes.sublot} key={sublot.id}>
                      <SublotCard
                        sublot={sublot}
                        onPrint={printLabels}
                        onUpdate={onUpdate}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </FormDrawer>
    </>
  );
};

SlackingForm.propTypes = {
  selectedProgress: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default SlackingForm;
