import {
  QA_PORTION_CHECKS_UPDATED,
  QA_PORTION_CHECKS_RESET,
  QA_PRELIMINARY_CHECK_POST,
  QA_PRELIMINARY_CHECK_POST_SUCCESS,
  QA_PORTION_CHECKS_POST,
  QA_PORTION_CHECKS_COMPLETE,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import PortionChecks from '../sharedChecks/services';

export const updateQaPortionChecks = (portionCheck) => {
  return {
    type: QA_PORTION_CHECKS_UPDATED,
    payload: portionCheck,
  };
};

export const updatePreliminaryCheckId = (response) => {
  return {
    type: QA_PRELIMINARY_CHECK_POST_SUCCESS,
    response: response,
  };
};

export const submitPreliminaryCheck = (params) => async (dispatch) => {
  const response = await dispatch(
    makeApiRequest({
      type: QA_PRELIMINARY_CHECK_POST,
      method: PortionChecks.createPreliminaryCheck,
      params,
    })
  );

  if (response.ok) {
    dispatch(updatePreliminaryCheckId(response));
  }
};

export const qaPortionChecksComplete = () => {
  return {
    type: QA_PORTION_CHECKS_COMPLETE,
  };
};

export const postQaPortionCheck = (
  issues,
  preliminaryCheckId,
  checkNumber,
  mainIssues,
  photo,
  maxNumberOfChecks
) => async (dispatch) => {
  const response = await dispatch(
    makeApiRequest({
      type: QA_PORTION_CHECKS_POST,
      method: PortionChecks.postQaChecks,
      params: { issues, preliminaryCheckId, photo },
    })
  );

  if (response.ok) {
    dispatch(
      updateQaPortionChecks({
        checkNumber: checkNumber,
        pass: mainIssues.length === 0,
      })
    );

    if (checkNumber === maxNumberOfChecks) {
      dispatch(qaPortionChecksComplete());
    }
  }
};

export const resetQaPortionChecks = () => ({
  type: QA_PORTION_CHECKS_RESET,
});
