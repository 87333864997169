import { makeApiRequest } from 'lib/api';
import {
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS,
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS,
} from 'lib/constants';

import IngredientAssignments from './services';

export const getIngredientAssignments = (
  ingredientAssignmentIds,
  showPortionedIngredients,
  showCompoundIngredients,
  showComponentIngredients
) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS,
        method: IngredientAssignments.getIngredientAssignments,
        params: {
          ingredientAssignmentIds: ingredientAssignmentIds,
          showPortionedIngredients: showPortionedIngredients,
          showCompoundIngredients: showCompoundIngredients,
          showComponentIngredients: showComponentIngredients,
        },
      })
    );
  };
};

export const getPickableIngredientAssignments = (menuId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS,
        method: IngredientAssignments.getPickableIngredientAssignments,
        params: {
          menuId: menuId,
        },
      })
    );
  };
};
