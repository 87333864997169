import React from 'react';

import DetailsHeader from './DetailsHeader';

const LabelingDetails = (props) => {
  return (
    <div className="LabelingDetails">
      <DetailsHeader
        {...props}
        customColumnHeader1="hand"
        customColumnHeader2="applicator"
      />
    </div>
  );
};

export default LabelingDetails;
