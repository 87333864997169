import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { QUANTITY_INCREASE_OPTIONS, WASTE_CORRECTION } from 'lib/constants';

const QtyIncreaseModal = ({
  'data-testid': dataTestId,
  classes,
  increaseModalOpen,
  onQuantityReasonCancel,
  onReasonTypeClick,
  includeWasteCorrection,
}) => {
  const allQuantityIncreaseReasons = useMemo(() => {
    if (includeWasteCorrection) {
      return [...QUANTITY_INCREASE_OPTIONS, { type: WASTE_CORRECTION }];
    } else {
      return QUANTITY_INCREASE_OPTIONS;
    }
  }, [includeWasteCorrection]);

  return (
    <Dialog
      data-testid={dataTestId}
      open={increaseModalOpen}
      onClose={onQuantityReasonCancel}
      classes={{ paperScrollPaper: classes.muiPaper }}
    >
      <DialogContent classes={{ root: classes.promptContainer }}>
        <DialogContentText classes={{ root: classes.promptText }}>
          Select addition reason:
        </DialogContentText>
      </DialogContent>
      <DialogContent classes={{ root: classes.optionsContainer }}>
        {allQuantityIncreaseReasons.map((option) => (
          <Button
            key={option.type}
            classes={{ root: classes.modalButtons }}
            onClick={() => onReasonTypeClick(option.type)}
          >
            {option.type}
          </Button>
        ))}
      </DialogContent>
    </Dialog>
  );
};

QtyIncreaseModal.propTypes = {
  'data-testid': PropTypes.string,
  classes: PropTypes.object.isRequired,
  increaseModalOpen: PropTypes.bool.isRequired,
  onQuantityReasonCancel: PropTypes.func.isRequired,
  onReasonTypeClick: PropTypes.func.isRequired,
  includeWasteCorrection: PropTypes.bool.isRequired,
};

QtyIncreaseModal.defaultProps = {
  'data-testid': 'qty-increase-modal',
};

export default QtyIncreaseModal;
