import { BLACK, WHITE, HC_GREEN, DARK_HC_GREEN } from 'lib/constants';

export default {
  question: {
    margin: '20px 0 1px',
    fontFamily: 'Muli',
    paddingLeft: '10px',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: 0,
    flex: '1 1 5%', // Ensures 2 max items per row. (flexGrow flexShrink flexBasis)
    margin: '5px !important',
    display: 'inline-block',
    fontSize: '16px',
    color: BLACK,
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
};
