import * as Sentry from '@sentry/react';
import React, { useEffect, Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import { EMOJI_FACE_WITH_MONOCLE } from 'lib/constants';
import { useScrolling } from 'lib/custom_hooks';

import InventoryLandingPage from './InventoryManager/InventoryLandingPage';
import StorageAreaList from './InventoryManager/StorageAreaList';
import StorageArea from './InventoryManager/StorageArea';
import InvestigationArea from './LocationInvestigation/InvestigationArea';
import PalletDetails from './InventoryManager/PalletDetails';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const WarehouseInventory = ({ onFetchStorageAreas, onFetchPrinters }) => {
  // below items are necessary to perserve state when navigating between routes
  const savedTableState = useRef({});
  const updateTableState = (tableState) =>
    (savedTableState.current = tableState);
  const { scrollToPreviousPosition } = useScrolling();
  const [sortState, setSortState] = useState('asc');

  useEffect(() => {
    onFetchStorageAreas();
  }, [onFetchStorageAreas]);

  useEffect(() => {
    onFetchPrinters();
  }, [onFetchPrinters]);

  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/inventory-manager"
          component={InventoryLandingPage}
        />
        <Route
          exact
          path="/inventory-manager/pallets/:id"
          component={PalletDetails}
        />
        <Route
          exact
          path="/inventory-manager/storage-areas"
          component={() => (
            <StorageAreaList title="Browse Locations" showProd />
          )}
        />
        <Route
          exact
          path="/inventory-manager/storage-areas/:id"
          component={() => (
            <StorageArea
              scrollToPreviousPosition={scrollToPreviousPosition}
              sortState={sortState}
              setSortState={setSortState}
            />
          )}
        />
        <Route
          exact
          path="/inventory-manager/location-investigation"
          render={() => {
            updateTableState({});
            return (
              <StorageAreaList
                title="Location Investigation"
                titleEmoji={EMOJI_FACE_WITH_MONOCLE}
                showProd={false}
              />
            );
          }}
        />
        <Route
          exact
          path="/inventory-manager/location-investigation/:id"
          component={() => (
            <InvestigationArea
              scrollToPreviousPosition={scrollToPreviousPosition}
              tableState={{
                get: () => savedTableState.current,
                set: updateTableState,
              }}
            />
          )}
        />
      </Switch>
    </Fragment>
  );
};

WarehouseInventory.propTypes = {
  onFetchStorageAreas: PropTypes.func.isRequired,
  onFetchPrinters: PropTypes.func.isRequired,
};

export default WarehouseInventory;
