import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PrinterContext = createContext({});

const PrinterProvider = ({ children }) => {
  const [printerDialogOpen, setPrinterDialogOpen] = useState(false);
  const [printSubmission, setPrintSubmission] = useState(null);

  return (
    <PrinterContext.Provider
      value={{
        printerDialogOpen,
        setPrinterDialogOpen,
        printSubmission,
        setPrintSubmission,
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
};

PrinterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrinterProvider;
