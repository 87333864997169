import { makeApiRequest } from 'lib/api';
import { GUIDED_PICKING_OVERRIDE_POST } from 'lib/constants';

import guidedPickingOverride from './services';

export const postGuidedPickingOverride = (
  itemMoveRequestId,
  suggestedPalletId,
  reasonCode,
  approver = null
) => {
  return makeApiRequest({
    type: GUIDED_PICKING_OVERRIDE_POST,
    method: guidedPickingOverride.postOverride,
    params: {
      itemMoveRequestId,
      suggestedPalletId,
      reasonCode,
      approver,
    },
  });
};
