const defaultStickyStyles = {
  position: 'fixed',
  zIndex: '500',
  width: '100%',
};

export default {
  stickyTopStyles: {
    ...defaultStickyStyles,
    top: '0px',
  },
  stickyBottomStyles: {
    ...defaultStickyStyles,
    bottom: '0px',
  },
};
