import { HC_GREEN, WHITE } from 'lib/constants';

export default {
  reactivateButton: {
    display: 'block',
    margin: '0 auto',
    backgroundColor: HC_GREEN,
    color: WHITE,
    textTransform: 'none',
    width: '97%',
  },
};
