import {
  WHITE,
  BLACK,
  DARK_HC_GREEN,
  GREY_500,
  GREY_200,
  HC_GREEN,
} from 'lib/constants';

const actionButton = {
  width: '100%',
};
export default {
  printPromptCancelButton: {
    ...actionButton,
    padding: '10px 50px 10px 50px',
    '&:first-child': {
      '&:hover': {
        backgroundColor: GREY_200,
      },
      color: GREY_500,
      border: `1px solid ${GREY_200}`,
      backgroundColor: WHITE,
    },
  },
  printPromptSubmitButton: {
    ...actionButton,
    backgroundColor: HC_GREEN,
    color: WHITE,
    padding: '10px 50px 10px 50px',
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    '&:disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
  },
};
