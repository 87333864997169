import React from 'react';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

import { formatNumber } from 'lib/utils';

const ProgressSummary = ({
  percentProgress,
  goalCount,
  remainingCountText,
  classes,
  progressColor,
  endOfShiftPercentTarget,
}) => (
  <React.Fragment>
    <Grid item xs={3} xl={4} className={classes.progressBarGridItem}>
      <LinearProgress
        variant="determinate"
        value={percentProgress}
        classes={{
          root: classes.linearProgressRoot,
          barColorPrimary: progressColor,
          colorPrimary: classes.barRemaining,
        }}
      />
      <span
        className={classes.progressBarTick}
        style={{ marginLeft: `${endOfShiftPercentTarget}%` }}
      >
        |
      </span>
    </Grid>

    <Grid item xs={4} lg={2} className={classes.countTextWrapper}>
      {remainingCountText}
      <div className={classes.goalDivider}>|</div>
      <div className={classes.emphasizedText}>
        {` Total ${formatNumber(goalCount)}`}
      </div>
    </Grid>
  </React.Fragment>
);

ProgressSummary.propTypes = {
  percentProgress: PropTypes.number.isRequired,
  goalCount: PropTypes.number.isRequired,
  remainingCountText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  progressColor: PropTypes.string.isRequired,
  endOfShiftPercentTarget: PropTypes.number,
};

ProgressSummary.defaultProps = {
  endOfShiftPercentTarget: undefined,
};

export default ProgressSummary;
