import { GREY_650 } from 'lib/constants';

export default {
  countListCard: {
    height: '130px',
    padding: '20px',
  },
  title: {
    fontWeight: 900,
    fontSize: '16px',
    textTransform: 'capitalize',
  },
  location: {
    color: GREY_650,
  },
  startedAt: {
    color: GREY_650,
    fontSize: '12px',
    alignSelf: 'flex-end',
  },
  cardTitle: {
    // This is to make up for MUI grid limitations with negative margin
    // https://mui.com/material-ui/react-grid/#limitations
    paddingBottom: '20px',
  },
  ratio: {},
  users: {
    '& div': {
      backgroundColor: '#1976d2',
    },
  },
  trashIcon: {
    fontSize: '12px',
  },
  deleteWarning: {
    paddingTop: '10px',
  },
  confirmDelete: {
    fontSize: '14px',
    color: 'red',
  },
  submitButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
