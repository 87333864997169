import { ROBOTO } from 'lib/constants';

import {
  arrowIcon,
  formLabelText,
  formSubmitButton,
  formDivider,
  sublotStyles,
} from '../../styles';

export default {
  arrowIcon,
  formLabelText,
  formSubmitButton,
  formDivider,
  ...sublotStyles,
  drawerHeader: {
    fontFamily: ROBOTO,
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    paddingBottom: '10px',
  },
  drawerSubHeader: {
    fontFamily: ROBOTO,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    paddingBottom: '10px',
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  dropdown: {
    width: '300px',
    marginRight: '20px',
    fontFamily: ROBOTO,
  },
};
