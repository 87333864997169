import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import formTheme from 'lib/formTheme';

import styles from './styles';

const FormContainer = ({ children, classes, id }) => {
  return (
    <div className={classes.formContainer} id={id} data-testid={id}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={formTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

FormContainer.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

FormContainer.defaultProps = {
  id: 'form-container',
};

export default withStyles(styles)(FormContainer);
