import React from 'react';
import PropTypes from 'prop-types';

import { BLACK } from 'lib/constants';

const ArrowForwardIcon = ({ width, height, color, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path fill={color} d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};

ArrowForwardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

ArrowForwardIcon.defaultProps = {
  width: '20px',
  height: '20px',
  color: BLACK,
  className: '',
};

export default ArrowForwardIcon;
