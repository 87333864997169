import * as Sentry from '@sentry/react';
import React, { Component } from 'react';
import { Switch, Route as ReactRouterDOMRoute } from 'react-router-dom';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withStyles from '@mui/styles/withStyles';

import { CUSTOMIZE_IT_INGREDIENT_TYPE } from 'redux/meal/reducers';
import { LabelLetterBox } from 'components/shared';
import { percentProgress, formatNumber } from 'lib/utils';

import OverviewDetails from '../OverviewDetails';
import PortioningDetails from '../PortioningDetails';
import ProgressSummary from '../ProgressSummary';
import styles from '../MealItem/styles';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

class IngredientItem extends Component {
  static progressColor(percentProgressCompleted, classes, percentTarget) {
    if (
      percentProgressCompleted >= 100 ||
      percentProgressCompleted - percentTarget > 5
    ) {
      return classes.aheadOfTarget;
    } else if (percentTarget - percentProgressCompleted > 5) {
      return classes.belowTarget;
    } else {
      return classes.onTarget;
    }
  }

  static remainingCountText(remainingCount) {
    const remainingCountAbs = formatNumber(Math.abs(remainingCount));
    const formattedNumber = remainingCountAbs === '-' ? 0 : remainingCountAbs;

    return remainingCount >= 0
      ? `${formattedNumber} To Goal`
      : `${formattedNumber} Over`;
  }

  static expandMoreIcon(ingredient) {
    return <ExpandMoreIcon className={ingredient.classes.expandMore} />;
  }

  progressBar(summaryParams) {
    const { goalCount, remainingCount, endOfShiftTarget } = summaryParams;
    if (goalCount > 0) {
      return (
        <ProgressSummary
          percentProgress={percentProgress(goalCount, remainingCount)}
          goalCount={goalCount}
          remainingCountText={this.constructor.remainingCountText(
            remainingCount
          )}
          classes={this.props.classes}
          progressColor={this.constructor.progressColor(
            percentProgress(goalCount, remainingCount),
            this.props.classes,
            endOfShiftTarget
          )}
          endOfShiftPercentTarget={endOfShiftTarget}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { ...ingredient } = this.props;

    return (
      <div className={ingredient.classes.mealItem}>
        <Accordion expanded={ingredient.expanded}>
          <AccordionSummary
            expandIcon={this.constructor.expandMoreIcon(ingredient)}
            onClick={() =>
              ingredient.toggleAction({
                type: CUSTOMIZE_IT_INGREDIENT_TYPE,
                id: ingredient.id,
              })
            }
          >
            <Grid container spacing={0} style={{ paddingRight: 0 }}>
              <Grid item xs={1}>
                <LabelLetterBox
                  letter={ingredient.labelOrder}
                  empty={!ingredient.labelOrder}
                />
              </Grid>
              <Grid className={ingredient.classes.titleRoot} item xs={3}>
                <span className={ingredient.classes.emphasizedText}>
                  {ingredient.name}
                </span>
              </Grid>
              <Switch>
                <Route
                  exact
                  path="/progress_tracker/menu/:menuId/overview"
                  render={() => {
                    const summaryParams = {
                      goalCount: ingredient.goalCount,
                      remainingCount: ingredient.remainingCount,
                      endOfShiftTarget: ingredient.endOfShiftPercentTarget,
                    };
                    return this.progressBar(summaryParams);
                  }}
                />
                <Route
                  exact
                  path="/progress_tracker/menu/:menuId/portioning"
                  render={() => {
                    const summaryParams = {
                      goalCount: ingredient.portioningGoalCount,
                      remainingCount: ingredient.remainingPortioningGoalCount,
                      endOfShiftTarget: ingredient.portioningEndOfShiftTarget,
                    };

                    return this.progressBar(summaryParams);
                  }}
                />
              </Switch>
            </Grid>
          </AccordionSummary>
          <Switch>
            <Route
              exact
              path="/progress_tracker/menu/:menuId/overview"
              render={() => (
                <OverviewDetails ingredient_assignments={[ingredient]} />
              )}
            />
            <Route
              exact
              path="/progress_tracker/menu/:menuId/portioning"
              render={() => (
                <PortioningDetails
                  ingredient_assignments={[ingredient]}
                  remainingCount={ingredient.remainingPortionCount}
                />
              )}
            />
          </Switch>
        </Accordion>
      </div>
    );
  }
}

IngredientItem.propTypes = {
  classes: PropTypes.object.isRequired,
  goalCount: PropTypes.number.isRequired,
  id: PropTypes.number,
  remainingCount: PropTypes.number.isRequired,
  name: PropTypes.string,
  endOfShiftPortioningTarget: PropTypes.number,
  expanded: PropTypes.bool,
  toggleAction: PropTypes.func,
  labelingGoalCount: PropTypes.number,
  remainingLabelingCount: PropTypes.number,
  portioningGoalCount: PropTypes.number,
  remainingPortioningGoalCount: PropTypes.number,
  portioningEndOfShiftTarget: PropTypes.number,
  labelOrder: PropTypes.string,
  method: PropTypes.string,
};

IngredientItem.defaultProps = {
  id: undefined,
  name: undefined,
  method: undefined,
  labelOrder: undefined,
  endOfShiftPortioningTarget: undefined,
  expanded: undefined,
  toggleAction: undefined,
  labelingGoalCount: undefined,
  remainingLabelingCount: undefined,
  portioningGoalCount: undefined,
  remainingPortioningGoalCount: undefined,
  portioningEndOfShiftTarget: undefined,
};

export default withStyles(styles)(IngredientItem);
