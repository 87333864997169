import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';
import BoxingInventoryTable from 'components/BoxingInventory/BoxingInventoryTable';
import { isCurrentMenu } from 'lib/utils';

import { sharedStyles } from './styles';

const BoxingInventory = ({
  boxingInventory,
  classes,
  menus,
  session,
  onUpdateSelectedShipDateAndFetch,
}) => {
  if (boxingInventory.fetching) {
    return <Loading type="linear" />;
  } else if (boxingInventory.results.meal_bags.length === 0) {
    return null;
  } else if (isCurrentMenu(menus)) {
    return (
      <BoxingInventoryTable
        boxingInventory={boxingInventory}
        classes={classes}
        menus={menus}
        session={session}
        onUpdateSelectedShipDateAndFetch={onUpdateSelectedShipDateAndFetch}
      />
    );
  } else {
    return (
      <div className={classes.errorMessage}>
        Sorry, you cannot retrieve boxing data for future ship weeks
      </div>
    );
  }
};

BoxingInventory.propTypes = {
  classes: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
  session: PropTypes.object,
  boxingInventory: PropTypes.object.isRequired,
};

BoxingInventory.defaultProps = {
  session: undefined,
};

export default withStyles(sharedStyles)(BoxingInventory);
