import get from 'lodash/get';

export const selectQaPhotoPosting = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].posting`, false);

export const selectQaPhotoPostingFailed = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].postingFailed`, false);

export const selectQaPhotoFetching = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].fetching`, true);

export const selectQaPhotoFetchingFailed = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].fetchingFailed`, false);

export const selectQaPhotoDeleting = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].deleting`, false);

export const selectQaPhotoDeletingFailed = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].deletingFailed`, false);

export const selectDefectPhotos = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].defects`, []);

export const selectLotCodePhotos = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].lotCodes`, []);

export const selectProp12Photos = (state, palletId) =>
  get(state, `photos.wmsQaApp[${palletId}].prop12`, []);

// this will only allow deletion if the photo was saved this session
export const selectPhotosForDisplay = (state, palletId, photoType) => {
  const pallet = get(state, `photos.wmsQaApp[${palletId}]`, {});
  const newPhotoIds = pallet.newPhotos || [];
  const photos = pallet[photoType] || [];

  const photosWithDelete = photos.map((photo) => ({
    ...photo,
    allowDelete: newPhotoIds.includes(photo.id),
  }));

  return photosWithDelete;
};
