import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';

import FacilityLayout from './FacilityLayout';
import RealtimeLegend from './RealtimeLegend';
import styles from './styles';

/**
 * Creates a new Realtime Dashboard component.
 * @class
 */

const RealtimeDashboard = ({ podLocations, podStatuses }) => {
  if (podLocations.fetching) {
    return <Loading type="linear" />;
  }

  return (
    <div>
      {
        <div>
          <FacilityLayout
            pods={podStatuses.results}
            gridMap={podLocations.gridMap}
          />
          <RealtimeLegend />
        </div>
      }
    </div>
  );
};

RealtimeDashboard.propTypes = {
  podLocations: PropTypes.shape({
    gridMap: PropTypes.array.isRequired,
  }),
  podStatuses: PropTypes.shape({ results: PropTypes.array }),
};

RealtimeDashboard.defaultProps = {
  podLocations: {},
  podStatuses: {},
};

export default withStyles(styles)(RealtimeDashboard);
