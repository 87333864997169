import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';

import { HomeIcon } from 'components/icons';

import styles from './styles';

const HomeButton = ({ classes }) => {
  return (
    <Link to="/" className={classes.root}>
      <HomeIcon className={classes.icon} />
    </Link>
  );
};

HomeButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeButton);
