import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DROP_ZONE, POD } from 'lib/constants';
import { requestPodLocations } from 'redux/podLocation/actions';

const initialState = {
  dropzones: [],
  pods: [],
};

const useProductionLocation = (options = {}) => {
  const { forceReload = false, withPziParentIds = false } = options;

  const dispatch = useDispatch();
  const [{ dropzones, pods }, setLocations] = useState(initialState);

  const { allLocations, packingFacilityId } = useSelector((state) => ({
    allLocations: state.podLocations?.pods || [],
    packingFacilityId: state.session.packing_facility_id,
  }));

  // separate filtering from the selector so it doesn't get recalculated on every redux state change
  useEffect(() => {
    setLocations({
      dropzones: allLocations.filter((l) => l.type === DROP_ZONE),
      pods: allLocations.filter((l) => l.type === POD),
    });
  }, [allLocations]);

  useEffect(() => {
    (allLocations.length === 0 || forceReload) &&
      dispatch(
        requestPodLocations({
          facilityId: packingFacilityId,
          withPziParentIds,
        })
      );
  }, []);

  return {
    dropzones,
    pods,
  };
};

export default useProductionLocation;
