import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';

import { formatDateAsDaynameMddyyyy } from 'lib/utils';

const UnscheduledPOs = ({ unscheduledPOs, date, classes }) => {
  return (
    <div className={classes.poContainer}>
      <h2>Unscheduled POs for {formatDateAsDaynameMddyyyy(date)}</h2>

      <Grid container className={classes.row}>
        <Grid item xs={3} className={classes.strongText}>
          <span>PO</span>
        </Grid>
        <Grid item xs={6} className={classes.strongText}>
          <span>VENDOR NAME</span>
        </Grid>
        <Grid item xs={3} className={classes.strongText}>
          <span>ITEMS</span>
        </Grid>
      </Grid>
      {unscheduledPOs.map((order) => (
        <Grid
          key={order.id}
          container
          className={classNames(classes.row, classes.dataRows)}
        >
          <Grid item xs={3}>
            <span>{order.netsuiteNumber}</span>
          </Grid>
          <Grid item xs={6}>
            <span>{order.vendorName}</span>
          </Grid>
          <Grid item xs={3}>
            <span>{order.itemCount}</span>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

UnscheduledPOs.propTypes = {
  unscheduledPOs: PropTypes.array.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  classes: PropTypes.object.isRequired,
};

export default UnscheduledPOs;
