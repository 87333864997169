import { makeApiRequest } from 'lib/api';
import {
  FSQA_PHOTO_POST,
  FSQA_PHOTOS_GET,
  FSQA_PHOTO_DELETE,
} from 'lib/constants';

import Photo from '../services';

export const postFsqaPhoto = (palletId, photo, category) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: FSQA_PHOTO_POST,
        method: Photo.wmsQaApp.postFsqaPhoto,
        params: { photo, palletId, category },
      })
    );
  };
};

export const fetchFsqaPhotos = (palletId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: FSQA_PHOTOS_GET,
        method: Photo.wmsQaApp.fetchFsqaPhotos,
        params: { palletId },
      })
    );
  };
};

export const deleteFsqaPhoto = (palletId, photoId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: FSQA_PHOTO_DELETE,
        method: Photo.wmsQaApp.deleteFsqaPhoto,
        params: { photoId, palletId },
      })
    );
  };
};
