import { HC_GREEN, WHITE } from 'lib/constants';

import { infoLabel, infoValue } from '../FsqaReviewForms/styles';

export default {
  infoValue,
  infoLabel,
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    height: 20,
  },
  closeIcon: {
    width: 15,
    height: 15,
    margin: '0 auto',
  },
  textField: {
    margin: '0 10px 0 0',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  first: {
    marginLeft: '29px',
  },
  addButton: {
    background: HC_GREEN,
    height: 55,
    color: WHITE,
    '&:hover': {
      backgroundColor: HC_GREEN,
    },
  },
  autoComplete: {
    marginRight: '10px',
    width: '215px',
  },
};
