import {
  DARK_HC_GREEN,
  HC_GREEN,
  BLACK,
  WHITE,
  ERROR_RED,
  DARK_RED,
  BLUE,
} from 'lib/constants';

export default {
  buttonGroup: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
    marginBottom: '20px',
  },
  helperText: {
    color: BLUE,
  },
  buttonYes: {
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
    flex: '1 0 47%',
    borderRadius: '5px',
    display: 'block',
    fontSize: '16px',
    color: BLACK,
  },
  buttonNo: {
    '&.Mui-selected': {
      backgroundColor: ERROR_RED,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_RED,
      },
    },
    flex: '1 0 47%',
    borderRadius: '5px',
    display: 'block',
    fontSize: '16px !important',
    color: BLACK,
  },
};
