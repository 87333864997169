import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const BooleanToggleButton = ({
  classes,
  headerText,
  helperText,
  value,
  onChange,
  id,
  'data-testid': dataTestId,
}) => (
  <>
    <h3 className={classes.heading}>{headerText}</h3>
    {helperText && <span className={classes.helperText}>{helperText}</span>}
    <ToggleButtonGroup
      className={classes.buttonGroup}
      exclusive
      value={value}
      onChange={onChange}
    >
      <ToggleButton
        // This ID can be removed after all enzyme tests are removed
        id={`${id}Yes`}
        data-testid={`${dataTestId}-yes`}
        className={classes.buttonYes}
        key={1}
        value
      >
        Yes
      </ToggleButton>
      <ToggleButton
        // This ID can be removed after all enzyme tests are removed
        id={`${id}No`}
        data-testid={`${dataTestId}-no`}
        className={classes.buttonNo}
        key={2}
        value={false}
      >
        No
      </ToggleButton>
    </ToggleButtonGroup>
  </>
);

BooleanToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  headerText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

BooleanToggleButton.defaultProps = {
  value: null,
  helperText: null,
};

export default withStyles(styles)(BooleanToggleButton);
