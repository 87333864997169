import { WHITE, HC_GREEN } from 'lib/constants';

export default {
  button: {
    fontSize: '12px',
    border: '2px solid',
    background: WHITE,
    paddingLeft: '12px',
    paddingRight: '12px',
    marginTop: '10px',
    marginBottom: '10px',
    color: HC_GREEN,
  },
};
