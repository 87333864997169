import { ORANGE_YELLOW } from 'lib/constants';

export default {
  banner: {
    marginTop: '10px',
    textAlign: 'center',
    padding: '10px',
    backgroundColor: ORANGE_YELLOW,
    fontWeight: 'bold',
  },
};
