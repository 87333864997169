import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';

import TruckSubmissionForm from '../TruckSubmissionForm';

const TruckDrawer = ({
  open,
  toggleTruckDrawer,
  clearSearchQuery,
  truck,
  classes,
  posting,
  postFailed,
  updating,
  updateFailed,
  dockDoors,
}) => {
  const closeDrawer = () => {
    toggleTruckDrawer(false);
    clearSearchQuery();
  };

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      classes={{ paper: classes.noScroll }}
    >
      <div className={classes.root}>
        <div className={classes.topSection}>
          {truck.number ? (
            <header>Truck {truck.number}</header>
          ) : (
            <header>Create a Truck</header>
          )}
          <TabOutlineButton
            className={classes.closeButton}
            type="button"
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.icon} />
          </TabOutlineButton>
        </div>
        <div className={classes.bodySection}>
          <TruckSubmissionForm
            truck={truck}
            closeDrawer={closeDrawer}
            posting={posting}
            postFailed={postFailed}
            updating={updating}
            updateFailed={updateFailed}
            dockDoors={dockDoors}
          />
        </div>
      </div>
    </Drawer>
  );
};

TruckDrawer.defaultProps = {
  truck: {},
  open: false,
};

TruckDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  truck: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    vendor: PropTypes.string,
  }),
  dockDoors: PropTypes.array.isRequired,
  open: PropTypes.bool,
  posting: PropTypes.bool.isRequired,
  postFailed: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  updateFailed: PropTypes.bool.isRequired,
  toggleTruckDrawer: PropTypes.func.isRequired,
  clearSearchQuery: PropTypes.func.isRequired,
};

export default TruckDrawer;
