import React, { useEffect, useMemo } from 'react';
import xor from 'lodash/xor';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';

import {
  SELECTED_ALLERGENS,
  EXPECTED_ALLERGENS,
  TRUE_ALLERGENS_GROUP_ID,
} from 'lib/constants';

import AllergensQuestion from './AllergensQuestion';

const GroupedAllergensContainer = ({
  pallet,
  formState,
  updateSelectedAllergens,
  updateExpectedAllergens,
  updateAllergensCorrect,
  updateIntolerancesCorrect,
  allAllergens,
  groupedAllergens,
}) => {
  const expectedAllergens = useMemo(() => formState[EXPECTED_ALLERGENS] || [], [
    formState[EXPECTED_ALLERGENS],
  ]);
  const selectedAllergens = useMemo(() => formState[SELECTED_ALLERGENS] || [], [
    formState[SELECTED_ALLERGENS],
  ]);

  // only want to save intial allergens for pallets that need that info, so calculating it here and sending it up redux
  useEffect(() => {
    if (expectedAllergens.length > 0) return;

    const expectedAllergenInfo = pallet.allergenIds.flatMap((id) =>
      allAllergens.filter((allergen) => allergen.allergies?.includes(id))
    );
    updateExpectedAllergens(uniq(expectedAllergenInfo));
  }, []);

  // calculating if allergens are equal (aka listed) here in one centralized spot and then sending to redux
  useEffect(() => {
    const allIncorrectAllergens = xor(expectedAllergens, selectedAllergens);

    const areTrueAllergensCorrect = !allIncorrectAllergens.some(
      (allergen) => allergen.groupId === TRUE_ALLERGENS_GROUP_ID
    );
    const areIntolerancesCorrect = !allIncorrectAllergens.some(
      (allergen) => allergen.groupId !== TRUE_ALLERGENS_GROUP_ID
    );
    updateAllergensCorrect(areTrueAllergensCorrect);
    updateIntolerancesCorrect(areIntolerancesCorrect);
  }, [expectedAllergens, selectedAllergens]);

  return (
    <>
      {Object.entries(groupedAllergens).map(([group, groupAllergens]) => (
        <AllergensQuestion
          key={group}
          groupId={group}
          groupAllergens={groupAllergens}
          selectedAllergens={selectedAllergens}
          updateSelectedAllergens={updateSelectedAllergens}
        />
      ))}
    </>
  );
};

GroupedAllergensContainer.propTypes = {
  pallet: PropTypes.shape({
    allergenIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  formState: PropTypes.object.isRequired,
  updateSelectedAllergens: PropTypes.func.isRequired,
  updateExpectedAllergens: PropTypes.func.isRequired,
  updateAllergensCorrect: PropTypes.func.isRequired,
  updateIntolerancesCorrect: PropTypes.func.isRequired,
  allAllergens: PropTypes.array,
  groupedAllergens: PropTypes.object,
};

export default GroupedAllergensContainer;
