import { options, handleResponse } from 'lib/homeChefApi';
import { buildIngredientAssignmentQuery } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getIngredientAssignments = async ({
  ingredientAssignmentIds = [],
  showPortionedIngredients = false,
  showCompoundIngredients = false,
  showComponentIngredients = false,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/ingredient_assignments/?${buildIngredientAssignmentQuery(
      ingredientAssignmentIds
    )}&showPortionedIngredients=${showPortionedIngredients}&showCompoundIngredients=${showCompoundIngredients}&showComponentIngredients=${showComponentIngredients}`,
    options
  );
  return await handleResponse(response);
};

const getPickableIngredientAssignments = async ({ menuId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/ingredient_assignments/pickables?menu_id=${menuId}`,
    options
  );
  return await handleResponse(response);
};

export default { getIngredientAssignments, getPickableIngredientAssignments };
