import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getLoginFields = async () => {
  const url = `${API_BASE_URI}/ops/login`;
  const response = await fetch(url, options);

  return await handleResponse(response);
};

const searchUsers = async ({ query }) => {
  const url = `${API_BASE_URI}/ops/login/search/${query}`;
  const response = await fetch(url, options);

  return await handleResponse(response);
};

export default {
  getLoginFields,
  searchUsers,
};
