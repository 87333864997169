import {
  TOP_BAR_RESET,
  TOP_BAR_UPDATE_CONTENT,
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
} from 'lib/constants';

export const initialState = {
  leftContent: HOME_BUTTON,
  middleContent: null,
  text: '',
  rightContent: ACCOUNT_DROPDOWN,
  backLocation: null,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case TOP_BAR_UPDATE_CONTENT:
      return { ...state, ...action.newState };
    case TOP_BAR_RESET:
      return initialState;
    default:
      return state;
  }
}
