import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { QUANTITY_INCREASE_OPTIONS, WASTE_CORRECTION } from 'lib/constants';
import { StatefulButton } from 'components/shared';

import QtyIncreaseModal from '../QuantityModals/QtyIncreaseModal';
import styles from './styles';

const ReactivateButton = ({
  onPalletReactivate,
  reactivating,
  reactivationFailed,
  palletId,
  classes,
  includeWasteCorrection,
}) => {
  const [increaseModalOpen, setIncreaseModalOpen] = useState(false);

  const submitReactivatePallet = (statusType) => {
    setTimeout(() => {
      onPalletReactivate(palletId, statusType);
    }, 300);
  };

  const onReasonTypeClick = (adjustmentType) => {
    const adjustmentOption = QUANTITY_INCREASE_OPTIONS.concat({
      type: WASTE_CORRECTION,
    }).find((opt) => opt.type === adjustmentType);

    submitReactivatePallet(adjustmentOption.type);
  };

  const openReasonModal = () => {
    setIncreaseModalOpen(true);
  };

  return (
    <>
      <StatefulButton
        failed={reactivationFailed}
        loading={reactivating}
        buttonTextOptions={{
          LOADING: 'Reactivating...',
          SUCCESS: 'Success',
          ERROR: 'There was an error...',
          DEFAULT: 'Reactivate Pallet',
        }}
        type="button"
        className={classes.reactivateButton}
        onClick={openReasonModal}
      />
      <QtyIncreaseModal
        increaseModalOpen={increaseModalOpen}
        onQuantityReasonCancel={() => setIncreaseModalOpen(false)}
        onReasonTypeClick={onReasonTypeClick}
        includeWasteCorrection={includeWasteCorrection}
      />
    </>
  );
};

ReactivateButton.propTypes = {
  onPalletReactivate: PropTypes.func.isRequired,
  reactivating: PropTypes.bool,
  reactivationFailed: PropTypes.bool,
  palletId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  classes: PropTypes.object.isRequired,
  includeWasteCorrection: PropTypes.bool,
};

ReactivateButton.defaultProps = {
  reactivating: false,
  reactivationFailed: false,
  includeWasteCorrection: false,
};

export default withStyles(styles)(ReactivateButton);
