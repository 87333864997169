import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { CloseIcon } from 'components/icons';
import {
  TabOutlineButton,
  StickyBarProvider,
  Loading,
} from 'components/shared';

const SearchItemDrawer = ({
  open,
  classes,
  closeDrawer,
  addItemToForm,
  menuSelectionIngredientIds,
  menuSelectionPackagingItemIds,
  requestedIngredientIds,
  requestedPackagingItemIds,
  onSearchItems,
  onClearSearchResults,
  searchResults,
  fetchingSearchResults,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [didSearch, setDidSearch] = useState(false);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setSearchButtonDisabled(e.target.value.length < 3);
  };

  const handleSearchClick = () => {
    onSearchItems(searchValue);
    setDidSearch(true);
  };

  const toggleSelectedItem = (clickedItem) => {
    if (clickedItem === selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedItem(clickedItem);
    }
  };

  const handleCloseDrawer = () => {
    setSearchValue('');
    setSearchButtonDisabled(true);
    setSelectedItem(null);
    onClearSearchResults();
    setDidSearch(false);
    closeDrawer();
  };

  const addItemToRequestForm = () => {
    addItemToForm(selectedItem);
    handleCloseDrawer();
  };

  const resultDisabled = (result) => {
    if (result.requestableType === 'Ingredient') {
      return (
        menuSelectionIngredientIds.includes(result.requestableId) ||
        requestedIngredientIds.includes(result.requestableId)
      );
    } else {
      return (
        menuSelectionPackagingItemIds.includes(result.requestableId) ||
        requestedPackagingItemIds.includes(result.requestableId)
      );
    }
  };

  return (
    <Drawer open={open} anchor="right">
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={11}>
            <h3>Request Additional Items</h3>
          </Grid>
          <Grid item xs={1}>
            <TabOutlineButton
              className={classes.closeButton}
              type="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon className={classes.icon} />
            </TabOutlineButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={8}>
            <TextField
              className={classes.searchInput}
              onChange={handleInputChange}
              value={searchValue}
              variant="outlined"
              placeholder="Search for an item..."
            />
          </Grid>
          <Grid item xs={4} className={classes.searchButtonContainer}>
            <Button
              variant="contained"
              disabled={searchButtonDisabled}
              className={classes.searchButton}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {fetchingSearchResults && (
          <div className={classes.centeredLoading}>
            <Loading />
          </div>
        )}
        {didSearch && !fetchingSearchResults && searchResults.length === 0 && (
          <div className={classes.noResults}>No results found</div>
        )}
        {searchResults.length > 0 && (
          <div className={classes.searchResultsContainer}>
            {searchResults.map((result) => {
              return (
                <Grid
                  key={`${result.requestableId}-${result.requestableType}`}
                  container
                  className={classNames(
                    classes.resultContainer,
                    {
                      [classes.selectedItem]: result == selectedItem,
                    },
                    { [classes.disabled]: resultDisabled(result) }
                  )}
                  onClick={() => toggleSelectedItem(result)}
                >
                  <Grid item xs={2}>
                    <strong>{result.requestableId}</strong>
                  </Grid>
                  <Grid item xs={10}>
                    {result.name}
                  </Grid>
                  <Grid item xs={2} className={classes.marginTop10}>
                    <strong>Brand:</strong>
                  </Grid>
                  <Grid item xs={10} className={classes.marginTop10}>
                    {result.brand}
                  </Grid>
                </Grid>
              );
            })}
          </div>
        )}
        <StickyBarProvider stickyPosition="bottom">
          <div className={classes.actionButtons}>
            <div>
              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={handleCloseDrawer}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                className={classes.addItemButton}
                disabled={selectedItem === null || resultDisabled(selectedItem)}
                onClick={addItemToRequestForm}
              >
                Add Item to Request
              </Button>
            </div>
          </div>
        </StickyBarProvider>
      </div>
    </Drawer>
  );
};

SearchItemDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  addItemToForm: PropTypes.func.isRequired,
  menuSelectionIngredientIds: PropTypes.array.isRequired,
  menuSelectionPackagingItemIds: PropTypes.array.isRequired,
  requestedIngredientIds: PropTypes.array.isRequired,
  requestedPackagingItemIds: PropTypes.array.isRequired,
  onSearchItems: PropTypes.func.isRequired,
  onClearSearchResults: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  fetchingSearchResults: PropTypes.bool.isRequired,
};

export default SearchItemDrawer;
