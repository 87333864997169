import {
  HC_GREEN,
  BLACK,
  FRESH_EASY_BLUE,
  RETAIL_ORANGE,
  GREY_150,
} from 'lib/constants';

import styles from '../styles';

export default {
  ...styles,
  tableRow: {
    ...styles.tableRow,
    cursor: 'pointer',
    textDecoration: 'none',
    color: BLACK,
  },
  green: {
    color: HC_GREEN,
  },
  blue: {
    color: FRESH_EASY_BLUE,
  },
  orange: {
    color: RETAIL_ORANGE,
  },
  darkStripe: {
    background: GREY_150,
  },
};
