import isEqual from 'lodash/isEqual';
import unionWith from 'lodash/unionWith';

import {
  RECEIVING_PALLETS_POST_REQUEST,
  RECEIVING_PALLETS_POST_SUCCESS,
  RECEIVING_PALLETS_POST_ERROR,
  RECEIVING_PALLETS_GET_REQUEST,
  RECEIVING_PALLETS_GET_SUCCESS,
  RECEIVING_PALLETS_GET_ERROR,
  RECEIVING_PALLETS_RESET,
  RECEIVING_PALLET_DELETE_REQUEST,
  RECEIVING_PALLET_DELETE_SUCCESS,
  RECEIVING_PALLET_DELETE_ERROR,
} from 'lib/constants';

const initialState = {
  posting: false,
  postFailed: false,
  fetching: false,
  fetchFailed: false,
  deleting: false,
  deleteFailed: false,
  errorMessage: '',
  pallets: [],
  measuringUnits: [],
  associatedNetsuiteOrderItem: null,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case RECEIVING_PALLETS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchFailed: false,
        pallets: [],
        associatedNetsuiteOrderItem: action.params.netsuiteOrderItemId,
        measuringUnits: [],
      };
    case RECEIVING_PALLETS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchFailed: false,
        measuringUnits:
          action.params.netsuiteOrderItemId ===
          state.associatedNetsuiteOrderItem
            ? unionWith(
                state.measuringUnits,
                action.response.potentialMeasuringUnits,
                isEqual
              )
            : state.measuringUnits,
        pallets:
          action.params.netsuiteOrderItemId ===
          state.associatedNetsuiteOrderItem
            ? action.response.pallets
            : state.pallets,
      };
    case RECEIVING_PALLETS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        fetchFailed: true,
      };
    case RECEIVING_PALLETS_POST_REQUEST:
      return { ...state, posting: true, postFailed: false };
    case RECEIVING_PALLETS_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        postFailed: false,
        pallets:
          action.params.netsuiteOrderItemId ===
          state.associatedNetsuiteOrderItem
            ? // defensive in case post comes back after subsequent get to same netsuite order item
              unionWith(state.pallets, action.response.newPallets, isEqual)
            : state.pallets,
      };
    case RECEIVING_PALLETS_POST_ERROR:
      return {
        ...state,
        posting: false,
        postFailed: true,
      };
    case RECEIVING_PALLET_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteFailed: false,
        errorMessage: '',
      };
    case RECEIVING_PALLET_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteFailed: false,
        errorMessage: '',
      };
    case RECEIVING_PALLET_DELETE_ERROR:
      return {
        ...state,
        deleting: false,
        deleteFailed: true,
        errorMessage: action.response.error,
      };
    case RECEIVING_PALLETS_RESET:
      return {
        ...state,
        pallets: [],
        measuringUnits: [],
        associatedNetsuiteOrderItem: null,
      };
    default:
      return state;
  }
}
