import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getProductionSubmissions } from 'redux/productionSubmission/actions';
import { requestPodLocations } from 'redux/podLocation/actions';

import ProductionSubmissionsTracker from './ProductionSubmissionsTracker';

const ProductionSubmissionsContainer = ({
  productionSubmissions,
  onGetProductionSubmissions,
  onRequestPodLocations,
  session,
  podLocations,
}) => {
  useEffect(() => {
    !podLocations.length &&
      onRequestPodLocations({
        facilityId: session.packing_facility_id,
      });
  }, [onRequestPodLocations, session]);

  const onTableChange = (tableState) => {
    onGetProductionSubmissions(tableState);
  };

  return (
    <ProductionSubmissionsTracker
      productionSubmissions={productionSubmissions}
      onTableChange={onTableChange}
      podLocations={podLocations}
    />
  );
};

const mapStateToProps = (state) => ({
  productionSubmissions: state.productionSubmissions,
  session: state.session,
  podLocations: state.podLocations.pods,
});

const mapDispatchToProps = {
  onGetProductionSubmissions: getProductionSubmissions,
  onRequestPodLocations: requestPodLocations,
};

ProductionSubmissionsContainer.propTypes = {
  onGetProductionSubmissions: PropTypes.func.isRequired,
  productionSubmissions: PropTypes.object.isRequired,
  onRequestPodLocations: PropTypes.func,
  session: PropTypes.object.isRequired,
  podLocations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

ProductionSubmissionsContainer.defaultProps = {
  onRequestPodLocations: () => [],
  podLocations: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionSubmissionsContainer);
