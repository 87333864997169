import { cell, header } from '../styles';

export default {
  cell,
  header,
  collapseContainer: { padding: 0, borderBottom: 0 },
  row: {
    alignItems: 'left',
    cursor: 'pointer',
    height: '10px',
  },
  ingredientDetailsContainer: {
    padding: '0px',
    backgroundColor: 'rgb(250,250,250)',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bolder',
    paddingBottom: '16px',
    paddingLeft: '16px',
  },
  toggleDetailsLink: {
    height: '40px',
    fontWeight: 'bolder',
  },
};
