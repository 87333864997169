import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import chunk from 'lodash/chunk';

const IceTable = ({ classes, icePacks }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow className={classNames(classes.tableHeader)}>
          <TableCell>ICE</TableCell>
          <TableCell>32oz</TableCell>
          <TableCell>64oz</TableCell>
          <TableCell>96oz</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classNames(classes.zebra)}>
        {chunk(icePacks, 3).map((row) => (
          <TableRow
            key={row[0].location}
            className={classNames(classes.tableBody)}
          >
            <TableCell>{capitalize(row[0].location.split(' ')[0])}</TableCell>
            <TableCell>{row[0].quantity}</TableCell>
            <TableCell>{row[1].quantity}</TableCell>
            <TableCell>{row[2].quantity}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

IceTable.propTypes = {
  classes: PropTypes.object.isRequired,
  icePacks: PropTypes.array.isRequired,
};

export default IceTable;
