import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ButtonBase from '@mui/material/ButtonBase';

import { StatefulButton } from 'components/shared';
import { Kraken } from 'components/icons';

import styles from './styles';

const ReleaseButton = ({
  reactivating,
  reactivationFailed,
  onPalletReactivate,
  palletId,
  classes,
  krakenVersion,
}) => {
  const releaseButtonText = {
    DEFAULT: 'Release Pallet',
    SUCCESS: 'Success!',
    LOADING: 'Saving',
    ERROR: 'Failed',
  };

  const requestPalletReactivate = () => {
    setTimeout(() => {
      onPalletReactivate(palletId);
    }, 300);
  };
  if (krakenVersion) {
    return (
      <ButtonBase
        className={classes.iconButton}
        onClick={requestPalletReactivate}
      >
        <p className={classes.text}>Reactivate Pallet</p>
        <div className={classes.kraken}>
          <Kraken />
        </div>
      </ButtonBase>
    );
  }

  return (
    <StatefulButton
      type="button"
      onClick={requestPalletReactivate}
      buttonTextOptions={releaseButtonText}
      loading={reactivating}
      failed={reactivationFailed}
      classes={{ root: classes.button }}
    />
  );
};

ReleaseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  palletId: PropTypes.number.isRequired,
  reactivating: PropTypes.bool.isRequired,
  reactivationFailed: PropTypes.bool.isRequired,
  krakenVersion: PropTypes.bool,
  onPalletReactivate: PropTypes.func.isRequired,
};

ReleaseButton.defaultProps = {
  krakenVersion: false,
};

export default withStyles(styles)(ReleaseButton);
