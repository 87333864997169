import {
  WMS_ITEM_RECEIPT_UPDATE_SUCCESS,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS,
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR,
} from 'lib/constants';
import { insertUpdatedTrucks } from 'lib/redux_utils';

export const initialState = {
  fetching: false,
  failed: false,
  updating: false,
  updateFailed: false,
  trucks: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        trucks: action.response.trucks,
      };
    case SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case WMS_ITEM_RECEIPT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        trucks: insertUpdatedTrucks(state.trucks, action.response.trucks),
      };
    default:
      return state;
  }
}
