import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import { numberWithCommas } from 'lib/utils';

import styles from './styles';

const FormatNumberCell = ({ value, classes, className, addCommas }) => {
  let formattedValue = addCommas && value ? numberWithCommas(value) : value;
  return (
    <div className={classnames(className, classes.alignRight)}>
      {formattedValue}
    </div>
  );
};

FormatNumberCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  addCommas: PropTypes.bool,
};

FormatNumberCell.defaultProps = {
  value: '',
  className: '',
  addCommas: false,
};

export default withStyles(styles)(FormatNumberCell);
