import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import { isStaleSubmission } from 'lib/viewHelpers';
import { formatNumber, minuteDiffFromNow, titleize } from 'lib/utils';

import styles from '../styles';

const ActivityProductionCount = ({ activity, classes }) => {
  const [minutesSubmittedAgo, setMinutesSubmittedAgo] = useState(
    minuteDiffFromNow(activity.lastProductionSubmittedAt)
  );

  const [isSubmissionStale, setIsStaleSubmission] = useState(false);

  useEffect(() => {
    let timer = setInterval(
      () =>
        setMinutesSubmittedAgo(
          minuteDiffFromNow(activity.lastProductionSubmittedAt)
        ),
      60000
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setMinutesSubmittedAgo(
      minuteDiffFromNow(activity.lastProductionSubmittedAt)
    );
  }, [activity.lastProductionSubmittedAt]);

  useEffect(() => {
    setIsStaleSubmission(isStaleSubmission(minutesSubmittedAgo));
  }, [minutesSubmittedAgo]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <h4 className={classes.cardHeading}>
            {titleize(activity.type)} {titleize(activity.name)}
          </h4>
        </Grid>
        <Grid item xs={4} className={classes.informationalCardNumber}>
          <h4 className={classes.cardHeading}>
            {formatNumber(activity.productionSubmittedCount)}
          </h4>
        </Grid>
      </Grid>
      <div
        className={classNames(classes.cardLastSubmitted, {
          [classes.staleSubmissionColor]: isSubmissionStale,
          [classes.freshSubmissionColor]: !isSubmissionStale,
        })}
      >
        Last submitted: {minutesSubmittedAgo} min
      </div>
    </React.Fragment>
  );
};

ActivityProductionCount.propTypes = {
  classes: PropTypes.object.isRequired,
  activity: PropTypes.shape({
    name: PropTypes.string,
    lastProductionSubmittedAt: PropTypes.string,
  }),
};

ActivityProductionCount.defaultProps = {
  activity: [],
};

export default withStyles(styles)(ActivityProductionCount);
