import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';

import { UploaderUserInterface } from 'components/shared';

import { reducer, initialState, ADD_ERROR, RESET_ERRORS } from './formUtils';

const DropListUploader = ({ onPostDropList, posting, postingErrors }) => {
  const [validationState, dispatch] = useReducer(reducer, initialState);
  const [isValidating, setIsValidating] = useState(false);

  const addError = (message) => {
    dispatch({ type: ADD_ERROR, message: message });
  };

  const requiredFields = [
    'Type',
    'Meal ID',
    'Ingredient ID',
    'Amount to Complete',
    'Drop Zone',
  ];

  const headerError = (data) => {
    const diff = difference(requiredFields, data[0].meta.fields);

    if (diff.length > 0) {
      return {
        message: `CSV is missing ${diff
          .map((field) => field)
          .join(', ')} column(s)`,
      };
    }
  };

  const validateAndSubmitData = (fileData) => {
    dispatch({ type: RESET_ERRORS });
    setIsValidating(true);

    const headerMissingError = headerError(fileData);
    if (headerMissingError) {
      return addError(headerMissingError.message);
    }

    onPostDropList(fileData);
  };

  const onDrop = (fileData) => {
    validateAndSubmitData(fileData);
    setIsValidating(false);
  };

  const formattedErrorList = () => {
    return postingErrors.length > 0 ? (
      <ul>
        {postingErrors.map((error) => {
          return (
            <li
              key={error.row_number}
            >{`Row ${error.row_number}: ${error.errors}`}</li>
          );
        })}
      </ul>
    ) : null;
  };

  return (
    <UploaderUserInterface
      isValidating={isValidating}
      onDrop={onDrop}
      posting={posting}
      postingErrors={postingErrors}
      validationState={validationState}
      formattedErrorList={formattedErrorList}
      uploadName="Drop List"
    />
  );
};

DropListUploader.propTypes = {
  onPostDropList: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  postingErrors: PropTypes.array.isRequired,
};

export default DropListUploader;
