import Pusher from 'pusher-js';

import { REACT_APP_PUSHER_KEY } from 'lib/constants';

if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'staging'
) {
  // Enable pusher logging - don't include this in production
  Pusher.logToConsole = true;
}

export const pusher = new Pusher(REACT_APP_PUSHER_KEY, {
  cluster: 'us2',
  forceTLS: true,
});

export default {
  pusher,
};
