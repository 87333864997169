import { GREY_100, HC_GREEN, WHITE } from 'lib/constants';

export default {
  editButtonContent: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 20px',
    height: '24px',
    width: '100%',
  },
  editButtonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '98%',
  },
  editIcon: {
    margin: '0px 0px 5px 5px',
    width: '20px',
    height: '20px',
    justifySelf: 'end',
  },
  createButton: {
    background: GREY_100,
    color: HC_GREEN,
    '&:hover': {
      color: WHITE,
    },
  },
  button: {
    width: '210px',
  },
};
