import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowDownIcon, ArrowUpIcon } from 'components/icons';

import { TabOutlineButton } from '../TabOutline';

const ExpandCollapse = ({
  classes,
  onCollapseClick,
  onExpandClick,
  className,
}) => {
  return (
    <div className={classNames(classes.root, className)}>
      <TabOutlineButton
        type="button"
        data-testid="expand-collapse-up-button"
        className={classes.button}
        onClick={onCollapseClick}
      >
        <ArrowUpIcon className={classes.arrow} />
      </TabOutlineButton>
      <TabOutlineButton
        type="button"
        data-testid="expand-collapse-down-button"
        className={classes.button}
        onClick={onExpandClick}
      >
        <ArrowDownIcon className={classes.arrow} />
      </TabOutlineButton>
    </div>
  );
};

ExpandCollapse.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onExpandClick: PropTypes.func.isRequired,
  onCollapseClick: PropTypes.func.isRequired,
};

ExpandCollapse.defaultProps = {
  className: '',
};

export default ExpandCollapse;
