import {
  BOXING_INVENTORY_REQUEST,
  BOXING_INVENTORY_SUCCESS,
  SELECTED_SHIP_DATE_UPDATED,
} from 'lib/constants';

const initialState = {
  results: {
    meal_bags: [],
    proteins: [],
    available_ship_dates: [],
  },
  fetching: false,
  ship_date_value: 'ship_date_1',
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case BOXING_INVENTORY_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case BOXING_INVENTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        results: action.response.boxing_inventories,
        ship_date_value: action.response.boxing_inventories.ship_date_value,
      };
    case SELECTED_SHIP_DATE_UPDATED:
      return {
        ...state,
        ship_date_value: action.payload.selectedShipDate,
      };
    default:
      return state;
  }
};
