import React from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

import styles from './styles';
import ProgressStepDetails from '../Ingredient/ProgressStepDetails';

const useStyles = makeStyles(styles);

const IngredientMealDetails = ({ ingredient }) => {
  const classes = useStyles();

  return ingredient.meals.map((meal) => {
    const {
      mealLetter,
      name: mealName,
      id: mealId,
      goalCount,
      completed,
      failed,
      notStarted,
    } = meal;

    return (
      <Table key={mealId}>
        <TableBody>
          <TableRow className={classes.row}>
            <TableCell className={classNames(classes.cell)}>
              <div className={classes.title}>
                {`${mealLetter} ${mealName} - ${mealId}`.trim()}
              </div>
              <ProgressStepDetails
                title=""
                goalCount={goalCount}
                step={{ completed, failed, notStarted }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  });
};

export default IngredientMealDetails;
