import { GREY_600, HC_GREEN, LIGHT_BLUE } from 'lib/constants';

export default {
  root: {
    backgroundColor: LIGHT_BLUE,
    height: '100vh',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 5px 0px',
    fontWeight: 'bold',
    fontSize: 20,
  },
  textField: {
    width: '100%',
    margin: '5px',
    padding: '150px 300px 300px 200px',
  },
  input: {
    padding: '12px',
  },
  navLinksContainer: {
    fontSize: '16px',
    paddingTop: '20px',
    textAlign: 'center',
    backgroundColor: 'white',
    marginBottom: '5px',
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: GREY_600,
    width: '100%',
    paddingBottom: '18px',
    borderBottom: `2px solid ${GREY_600}`,
  },
  activeLink: {
    color: HC_GREEN,
    fontWeight: 'bolder',
    paddingBottom: '16px',
    borderBottom: `4px solid ${HC_GREEN}`,
  },
  comingSoon: {
    margin: '25px',
    textAlign: 'center',
    fontSize: '25px',
  },
};
