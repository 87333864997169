import React from 'react';

import { HC_GREEN, LIGHT_GREEN, GREY_500 } from 'lib/constants';

const Kraken = () => {
  return (
    <svg
      data-testid="kraken"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
    >
      <path
        d="M15.34,56.55A8.63,8.63,0,0,1,14.08,56,13.34,13.34,0,0,1,8.7,50.76c-.27-.47-.86-.08-.71.47a15.38,15.38,0,0,0,7.44,9.88,12,12,0,0,0,4.47,1.16A11.71,11.71,0,0,1,15.34,56.55Z"
        style={{ fill: HC_GREEN }}
      />
      <path
        d="M71.49,50.71a13.35,13.35,0,0,1-5,5.1A16.1,16.1,0,0,1,61.87,62a11,11,0,0,0,2.94-1,15.36,15.36,0,0,0,7.39-9.89C72.35,50.63,71.77,50.24,71.49,50.71Z"
        style={{ fill: HC_GREEN }}
      />
      <path
        d="M29.6,58.08a13.49,13.49,0,0,0,6.06-5.23H36a13.89,13.89,0,0,1-6.32,5.52,11.66,11.66,0,0,1-9.62-.26,14.6,14.6,0,0,1-1.71-.94h0a9.37,9.37,0,0,1-.79-.57.27.27,0,0,0-.08-.07l-.31-.28L17,56.09l-.27-.27c-.13-.14-.26-.3-.38-.45l-.19-.24-.29-.4-.16-.23-.46-.72a.25.25,0,0,0-.21-.13.15.15,0,0,0-.09,0,.37.37,0,0,0-.18.46c.14.47.29.92.44,1.35s.31.83.48,1.21a11.36,11.36,0,0,0,5.93,6.22,11.87,11.87,0,0,0,9.81.27A15.1,15.1,0,0,0,39.63,54c.13-.38.23-.77.34-1.16h.43c.14.6.32,1.2.52,1.79a15.12,15.12,0,0,0,8.21,9.13A12,12,0,0,0,59,63.5a12.76,12.76,0,0,0,1.14-.64c.36-.22.7-.46,1-.71h0A15.55,15.55,0,0,0,66,56.05h0c.16-.35.31-.71.45-1.07s.31-.84.44-1.28a.38.38,0,0,0-.18-.46.24.24,0,0,0-.31.12c-.2.33-.42.65-.65,1l-.17.22c-.18.24-.37.47-.56.7l-.17.19c-.21.24-.44.47-.66.7l-.11.11a13.49,13.49,0,0,1-3.5,2.45,11.77,11.77,0,0,1-9.7.27,14.13,14.13,0,0,1-6.73-6.11h.36A13.75,13.75,0,0,0,51,58.67a11.46,11.46,0,0,0,9.45-.26,13.3,13.3,0,0,0,2.18-1.34c-4.33.53-8.61-1.84-11.21-6.25a10.64,10.64,0,0,0,17.49-4.27.34.34,0,0,0-.59-.31,10.17,10.17,0,0,1-17.38-1.52,5.24,5.24,0,0,0,1.79-8.09,14.55,14.55,0,0,0,1.37-6.16A14.05,14.05,0,0,0,40.27,16.2,14.05,14.05,0,0,0,26.45,30.47a14.54,14.54,0,0,0,1.35,6.12A5.25,5.25,0,0,0,26.55,40a5.3,5.3,0,0,0,2.52,4.51l.36.19a10.28,10.28,0,0,1-17.66,1.78.33.33,0,0,0-.58.31,10.74,10.74,0,0,0,17.39,4.5,11.8,11.8,0,0,1-9.64,5.89c.38.22.8.44,1.28.68A11.37,11.37,0,0,0,29.6,58.08Zm20.07-20a2.25,2.25,0,1,1-2.25,2.25A2.25,2.25,0,0,1,49.67,38.06Zm-21,2.25a2.26,2.26,0,1,1,2.25,2.25A2.25,2.25,0,0,1,28.62,40.31Z"
        style={{ fill: HC_GREEN }}
      />
      <path
        d="M28.83,62.32a.9.9,0,1,0,.89.9A.89.89,0,0,0,28.83,62.32Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <circle cx="30.58" cy="62.4" r="0.62" style={{ fill: LIGHT_GREEN }} />
      <path
        d="M31.76,62.32A.89.89,0,0,0,32.4,64a.89.89,0,1,0-.64-1.66Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M33.2,61.18a.63.63,0,1,0,.45,1.17.63.63,0,0,0-.45-1.17Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M26.73,61.31a.9.9,0,1,0,.9.9A.89.89,0,0,0,26.73,61.31Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <circle cx="26.93" cy="63.88" r="0.62" style={{ fill: LIGHT_GREEN }} />
      <path
        d="M22.27,61.52a.89.89,0,1,0,.47,1.17A.89.89,0,0,0,22.27,61.52Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M24.42,62.53a.63.63,0,0,0-.33-.82.62.62,0,0,0-.49,1.14A.62.62,0,0,0,24.42,62.53Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M25,62.67a.9.9,0,1,0,.86.93A.9.9,0,0,0,25,62.67Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M20.75,59.76A.9.9,0,0,0,20,61.41a.9.9,0,0,0,.71-1.65Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M18.86,59.43a.61.61,0,0,0-.81.32.62.62,0,1,0,1.14.49A.62.62,0,0,0,18.86,59.43Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M55.45,62.58a.92.92,0,1,0,.94.9A.92.92,0,0,0,55.45,62.58Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M57.62,62.59a.93.93,0,1,0,1.2.52A.92.92,0,0,0,57.62,62.59Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M59.32,61.63a.65.65,0,0,0-.36.84.64.64,0,1,0,.36-.84Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M53.26,61.57a.93.93,0,1,0,.94.91A.92.92,0,0,0,53.26,61.57Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M53.5,63.57a.65.65,0,0,0,0,1.29.65.65,0,0,0,0-1.29Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M48.66,61.87a.92.92,0,1,0,.51,1.2A.91.91,0,0,0,48.66,61.87Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M50.9,62.87a.66.66,0,0,0-.35-.84.64.64,0,1,0,.35.84Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M51.47,63a.92.92,0,1,0,.9.94A.92.92,0,0,0,51.47,63Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M47.06,60.08a.94.94,0,0,0-1.21.51.92.92,0,1,0,1.21-.51Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M45.1,59.77A.64.64,0,1,0,44.62,61a.64.64,0,1,0,.48-1.18Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M8.66,52.68a.41.41,0,0,0,.15-.56A.4.4,0,0,0,8.25,52a.4.4,0,0,0-.15.56A.41.41,0,0,0,8.66,52.68Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M8.79,53.05a.62.62,0,0,0,.62,1.08.62.62,0,0,0-.62-1.08Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M8.28,51.2a.28.28,0,0,0-.4-.1.29.29,0,0,0,.29.51A.3.3,0,0,0,8.28,51.2Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M10.13,53.66a.29.29,0,0,0-.11.4.3.3,0,0,0,.41.11.28.28,0,0,0,.1-.4A.29.29,0,0,0,10.13,53.66Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M71.84,51.72a.41.41,0,0,0-.53.21.4.4,0,1,0,.53-.21Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M71.41,52.86a.6.6,0,0,0-.8.32.6.6,0,1,0,1.09.49A.6.6,0,0,0,71.41,52.86Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M72.12,50.82a.28.28,0,0,0-.38.15.29.29,0,0,0,.14.39.3.3,0,0,0,.24-.54Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M69.78,53.76a.3.3,0,0,0,.14.39A.29.29,0,0,0,70.3,54a.29.29,0,0,0-.14-.39A.29.29,0,0,0,69.78,53.76Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M12.78,49.34a.42.42,0,0,0,.15-.56.41.41,0,0,0-.72.41A.42.42,0,0,0,12.78,49.34Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M12.9,49.71a.63.63,0,0,0,.63,1.08.63.63,0,0,0,.22-.85A.62.62,0,0,0,12.9,49.71Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M68.11,48a.42.42,0,0,0-.34.76.42.42,0,0,0,.54-.22A.41.41,0,0,0,68.11,48Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M67.68,49.13a.6.6,0,0,0-.8.32.61.61,0,0,0,.29.81.62.62,0,0,0,.81-.32A.6.6,0,0,0,67.68,49.13Z"
        style={{ fill: LIGHT_GREEN }}
      />
      <path
        d="M77.5,66.91H75.43V13.09h1.45a.89.89,0,0,0,.88-.88v-5a.89.89,0,0,0-.88-.88H2.6a.89.89,0,0,0-.88.88v5a.89.89,0,0,0,.88.88H3.9V66.91H3.22a.89.89,0,0,0-.88.88v5a.89.89,0,0,0,.88.88H77.5a.89.89,0,0,0,.88-.88v-5A.89.89,0,0,0,77.5,66.91Zm-5.26,0H64.71V13.09h7.53ZM30.13,13.09h8.34V66.91H30.13ZM27,66.91H18.61V13.09H27ZM41.66,13.09H50V66.91H41.66Zm11.52,0h8.35V66.91H53.18Zm-46.1,0h8.34V66.91H7.08Z"
        style={{ fill: GREY_500 }}
      />
    </svg>
  );
};

export default Kraken;
