import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const postOverride = async ({
  itemMoveRequestId,
  suggestedPalletId,
  reasonCode,
  approver,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/guided_picking_overrides`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        guided_picking_override: {
          item_move_request_id: itemMoveRequestId,
          suggested_pallet_id: suggestedPalletId,
          reason_code: reasonCode,
          approver: approver,
        },
      }),
    }
  );

  return await handleResponse(response);
};

export default {
  postOverride,
};
