import React from 'react';
import PropTypes from 'prop-types';

import AppointmentViewer from './AppointmentViewer';

const ReceivingAppointments = ({
  appointments,
  onClickAppointment,
  onSetSelectedAppt,
}) => {
  return (
    <AppointmentViewer
      appointments={appointments}
      onClickAppointment={onClickAppointment}
      onSetSelectedAppt={onSetSelectedAppt}
    />
  );
};

export default ReceivingAppointments;

ReceivingAppointments.propTypes = {
  appointments: PropTypes.array.isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onSetSelectedAppt: PropTypes.func.isRequired,
};
