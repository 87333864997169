import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import classnames from 'classnames';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';
import { IMPOSSIBLE_UOM_ID } from 'lib/constants';

import styles from './styles';
import { INPUT_NAMES } from '../PalletSubmissionForm/formUtils';

const PalletSubmissionLine = ({
  classes,
  inputs,
  defaultUnits,
  handleInputChange,
  handleInvalidQuantities,
  unitsOptions,
  handleDelete,
  index,
}) => {
  const changedUom =
    defaultUnits !== IMPOSSIBLE_UOM_ID &&
    defaultUnits != inputs[INPUT_NAMES.units] &&
    inputs[INPUT_NAMES.units] !== '';

  return (
    <Fragment>
      <div
        className={classnames(classes.container, {
          [classes.first]: index === 0,
        })}
        data-testid="pallet-submission-line"
      >
        <TextField
          required
          type="number"
          label="QTY/PALLET"
          name={INPUT_NAMES.quantity}
          value={inputs[INPUT_NAMES.quantity]}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          variant="outlined"
          margin="normal"
          inputProps={{ min: '1', step: '1' }}
          error={handleInvalidQuantities(inputs[INPUT_NAMES.quantity])}
          id={`quantity-per-pallet-${index}`}
          className={classes.textField}
          InputLabelProps={{ required: false }}
        />
        <TextField
          required
          label="UNIT OF MEASURE"
          name={INPUT_NAMES.units}
          data-testid="unit-of-measure-select"
          value={inputs[INPUT_NAMES.units]}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          variant="outlined"
          margin="normal"
          id={`units-of-measure-${index}`}
          select
          className={`${classes.textField} ${
            changedUom && classes.textFieldWarning
          }`}
          SelectProps={{ MenuProps: { className: classes.menuItem } }}
          InputLabelProps={{ required: false }}
        >
          {unitsOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          type="number"
          label="# OF PALLETS"
          name={INPUT_NAMES.numberPallets}
          value={inputs[INPUT_NAMES.numberPallets]}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          variant="outlined"
          margin="normal"
          inputProps={{ min: '1', step: '1' }}
          error={handleInvalidQuantities(inputs[INPUT_NAMES.numberPallets])}
          id={`number-pallets-${index}`}
          className={classes.textField}
          InputLabelProps={{ required: false }}
        />
        {index !== 0 && (
          <TabOutlineButton
            onClick={handleDelete}
            type="button"
            className={classes.closeButton}
            data-testid="delete-pallet-line"
          >
            <CloseIcon className={classes.closeIcon} />
          </TabOutlineButton>
        )}
      </div>
      {changedUom && (
        <Alert severity="warning">
          Please contact procurement with the UoM change so that they can make
          the necessary changes to NetSuite
        </Alert>
      )}
    </Fragment>
  );
};

const unitOptionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

PalletSubmissionLine.propTypes = {
  classes: PropTypes.object.isRequired,
  inputs: PropTypes.shape({
    quantity: PropTypes.string.isRequired,
    number_of_pallets: PropTypes.string.isRequired,
    unit_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  }).isRequired,
  defaultUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  unitsOptions: PropTypes.arrayOf(unitOptionShape).isRequired,
  handleInvalidQuantities: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(PalletSubmissionLine);
