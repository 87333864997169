import { useState } from 'react';
//Be careful when re-using configuration paths
//This will overwrite data in local storage and may produce
//unexpected results within components that are reusing paths
export const WR_QUEUE_TABLE_CONFIG_PATH = 'warehouseRequests_queueTableConfig';

const REGISTERED_PATHS = [WR_QUEUE_TABLE_CONFIG_PATH];
const useLocalObjectStorage = (objectPath) => {
  if (!REGISTERED_PATHS.includes(objectPath)) {
    throw 'Path is not registed in custom hook';
  }

  const [path] = useState(objectPath);

  const getObject = () => {
    return JSON.parse(window.localStorage.getItem(path));
  };
  const setObject = (obj) => {
    return window.localStorage.setItem(path, JSON.stringify(obj));
  };
  const deleteObject = () => {
    window.localStorage.setItem(path, null);
  };
  return [getObject, setObject, deleteObject];
};

export default useLocalObjectStorage;
