import { options, handleResponse } from 'lib/homeChefApi';
import { formatDateHyphenated, getTimeFromDate } from 'lib/utils/date';
import { API_BASE_URI } from 'lib/constants';

const cleanData = (data, isManualAppointment) => {
  if (isManualAppointment) {
    const date = new Date(data[0].local_start_time);

    // need date formatted like: "2020-10-05 6:00 PM"
    data[0].local_start_time = `${formatDateHyphenated(date)} ${getTimeFromDate(
      date
    )}`;

    return data;
  } else
    return data.map((row) => {
      const dataRow = row.data;
      return {
        duration: dataRow['Length'],
        local_start_time: `${formatDateHyphenated(
          new Date(dataRow['Timestamp'])
        )} ${getTimeFromDate(new Date(dataRow['Timestamp']))}`,
        memo: dataRow['Memo'],
        purchase_order: dataRow['PO'],
      };
    });
};

const postDockAppointment = async ({ data, isManualAppointment }) => {
  const response = await fetch(`${API_BASE_URI}/ops/dock_appointments`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      appointments: cleanData(data, isManualAppointment),
    }),
  });

  return await handleResponse(response);
};

const putDockAppointment = async ({ data, isManualAppointment }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/dock_appointments/${data[0].id}`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        appointments: cleanData(data, isManualAppointment),
      }),
    }
  );

  return await handleResponse(response);
};

const deleteDockAppointment = async ({ appointmentId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/dock_appointments/${appointmentId}`,
    {
      ...options,
      method: 'DELETE',
    }
  );

  return await handleResponse(response);
};

const getDockAppointmentsForDate = async ({ date }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/dock_appointments?date=${date}`,
    options
  );

  return await handleResponse(response);
};

export default {
  postDockAppointment,
  getDockAppointmentsForDate,
  putDockAppointment,
  deleteDockAppointment,
};
