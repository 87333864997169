import { format, addHours } from 'date-fns';

export const getPace = () => {
  const minutes = new Date().getMinutes();
  return minutes / 60;
};

export const convertTime12To24 = (hour) => {
  const modifier = hour.substr(-2);
  let parsedHour =
    hour.length === 3
      ? parseInt(hour.slice(0, 1), 10)
      : parseInt(hour.slice(0, 2), 10);

  if (modifier === 'PM' && parsedHour === 12) {
    return parsedHour;
  } else if (modifier === 'PM') {
    return (parsedHour += 12);
  } else if (modifier === 'AM' && parsedHour === 12) {
    return (parsedHour -= 12);
  } else {
    return parsedHour;
  }
};

export const convertTime24To12 = (hour) => {
  const merdianTime = '';
  const meridian = hour < 12 ? 'AM' : 'PM';
  const adjustedTime = hour % 12 || 12;

  return merdianTime.concat(adjustedTime, meridian);
};

export const isCurrentHour = (hour, paceLineHour) => {
  const currentHour = convertTime24To12(paceLineHour);

  const activityHour = hour;

  return currentHour === activityHour;
};

export const incrementActiveHour = (hour, index, numberOfHoursInShift) => {
  if (index >= numberOfHoursInShift - 1) return undefined;
  const currentHour = new Date(2000, 1, 1, convertTime12To24(hour));

  const incrementedHour = format(addHours(currentHour, 1), 'ha');

  return incrementedHour;
};
