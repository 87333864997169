import { options, handleResponse } from 'lib/homeChefApi';
import { buildIngredientAssignmentQuery } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getPackagingItems = async ({ ingredientAssignmentIds = [] }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/packaging_items/?${buildIngredientAssignmentQuery(
      ingredientAssignmentIds
    )}`,
    options
  );
  return await handleResponse(response);
};

export default { getPackagingItems };
