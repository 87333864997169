import { GREY_200 } from 'lib/constants';

export default {
  sticky: {
    position: 'sticky',
    top: '2px',
    zIndex: 2,
  },
  progressTextArea: {
    padding: '5px 25px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressBar: {
    height: '12px',
    marginTop: '2px',
    backgroundColor: GREY_200,
  },
};
