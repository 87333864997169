import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogActions from '@mui/material/DialogActions';

import { usePrevious } from 'lib/custom_hooks';
import { StatefulButton } from 'components/shared';
import {
  CANCEL_MOVE_TYPE,
  KNOWN_SHORT,
  ON_HOLD,
  ON_FLOOR,
  NOT_FOUND,
  NO_BARCODE,
  PENDING_RECEIPT,
} from 'lib/constants';

const cancelTypeOptions = [
  KNOWN_SHORT,
  ON_FLOOR,
  ON_HOLD,
  NOT_FOUND,
  NO_BARCODE,
  PENDING_RECEIPT,
];

const buttonText = {
  LOADING: 'Closing...',
  SUCCESS: 'Success',
  ERROR: 'There was an error closing request',
  DEFAULT: 'Close Request',
};

const CancelDialog = ({
  show,
  handleClose,
  itemMoveRequest,
  onUpdateItemMoveRequest,
  updating,
  updatingFailed,
  classes,
  history,
  onCancelSuccess,
}) => {
  const [reason, setReason] = useState('');

  const prevUpdating = usePrevious(updating);

  const closeDialog = () => {
    setReason('');
    handleClose();
    if (!updating && prevUpdating && !updatingFailed) {
      history.push('/warehouse_moves/open');
    }
  };

  const cancelRequest = async () => {
    await onUpdateItemMoveRequest(itemMoveRequest.id, CANCEL_MOVE_TYPE, {
      cancellation_reason: reason,
    });
    !updatingFailed && onCancelSuccess();
  };

  return (
    <Dialog open={show} onClose={closeDialog} className={classes.root}>
      <div className={classes.text}>Reason for Closing</div>
      <RadioGroup
        className={classes.radioButtonGroup}
        aria-label="cancelType"
        name="cancelType"
        onChange={(e) => {
          setReason(e.target.value);
        }}
      >
        {cancelTypeOptions.map((option) => {
          return (
            <FormControlLabel
              value={option}
              label={option}
              key={option}
              control={<Radio color="primary" />}
              className={classes.radioButtonText}
            />
          );
        })}
      </RadioGroup>

      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <StatefulButton
          buttonTextOptions={buttonText}
          disabled={!reason}
          type="button"
          id="submitButton"
          loading={updating}
          failed={updatingFailed}
          onClick={cancelRequest}
          successCallback={closeDialog}
          color="secondary"
        />
      </DialogActions>
    </Dialog>
  );
};

CancelDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onUpdateItemMoveRequest: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemMoveRequest: PropTypes.object.isRequired,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  onCancelSuccess: PropTypes.func.isRequired,
};

export default CancelDialog;
