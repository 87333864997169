import { LIGHTER_GREEN, ROBOTO } from 'lib/constants';

import { formLabelText } from '../styles';

export default {
  formLabelText: {
    ...formLabelText,
    fontFamily: ROBOTO,
  },
  autocompleteContainer: {
    width: '100%',
    minWidth: '700px',
    marginTop: '12px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: 'none',
    width: '100%',
    minWidth: '700px',
    padding: '0',
    marginBottom: '12px',
  },
  binText: {
    fontSize: '18px',
    fontFamily: ROBOTO,
    marginRight: 'auto',
  },
  binOption: {
    minWidth: '600px',
    padding: '8px 12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: LIGHTER_GREEN,
    },
  },
  binOptionIngredient: {
    fontFamily: ROBOTO,
  },
  binOptionTitle: {
    fontWeight: 'bold',
    fontFamily: ROBOTO,
  },
  checkbox: {
    padding: '8px',
    marginRight: '16px',
  },
  deleteIcon: {
    alignSelf: 'center',
  },
  depletedLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: ROBOTO,
  },
  depletedSection: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledOption: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  selectedOption: {
    backgroundColor: LIGHTER_GREEN,
  },
  selectedText: {
    color: '#00796b',
  },
  deactivatedText: {
    color: 'red',
    textTransform: 'capitalize',
    fontFamily: ROBOTO,
  },
};
