/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react';

import isFuture from 'date-fns/isFuture';

const AM = 'AM';
const PM = 'PM';
const NOON = 12;

const isPM = (mer) => mer === PM;

const calculateHoursToDisplay = (md, hrs) =>
  isPM(md) && hrs > NOON ? hrs - NOON : hrs;

const calculateHoursToMilitary = (md, hrs) =>
  isPM(md) && hrs < NOON ? hrs + NOON : hrs;

const timeFromExistingAppointment = (dateTime, appointment) => {
  if (!appointment) return { hours: '', minutes: '', meridiem: '' };

  const militaryHours = dateTime.getHours();

  const meridiem = militaryHours >= NOON ? PM : AM;
  const hours = String(calculateHoursToDisplay(meridiem, militaryHours));
  const minutes = String(dateTime.getMinutes());

  return { hours: hours, minutes: minutes, meridiem: meridiem };
};

const useDateTimeCalculator = (selectedDateTime, appointment) => {
  // selectedDateTime comes in as a string if the appointment exists, otherwise is a Date object
  // So we have to coerce it into a Date type here
  const dateTime = new Date(selectedDateTime);
  const [calculatedDate, setCalculatedDate] = useState(dateTime);

  const { hours, minutes, meridiem } = timeFromExistingAppointment(
    dateTime,
    appointment
  );

  const [displayHours, setDisplayHours] = useState(hours);
  const [displayMinutes, setDisplayMinutes] = useState(minutes);
  const [displayMeridiem, setDisplayMeridiem] = useState(meridiem);

  const timeDropdownsFilled = !!(
    displayHours &&
    displayMinutes &&
    displayMeridiem
  );

  useEffect(() => {
    if (!timeDropdownsFilled) return;

    dateTime.setHours(
      calculateHoursToMilitary(displayMeridiem, parseInt(displayHours)),
      displayMinutes
    );

    setCalculatedDate(dateTime);
  }, [displayHours, displayMinutes, displayMeridiem, selectedDateTime]);

  const validDateTime = useMemo(
    () => !!appointment || isFuture(calculatedDate),
    [appointment, calculatedDate]
  );

  return {
    displayHours,
    displayMinutes,
    displayMeridiem,
    setDisplayHours,
    setDisplayMinutes,
    setDisplayMeridiem,
    timeDropdownsFilled,
    validDateTime,
    calculatedDate,
  };
};

export default useDateTimeCalculator;
