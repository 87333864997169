import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import Pod from '../Pod';
import styles from './styles';

const FacilityLayout = ({ pods, gridMap, classes }) => {
  const numberOfColumns =
    typeof gridMap[0] == 'undefined' ? null : gridMap[0].length;

  const columnClassMap = {
    7: classes.colSevenths,
    6: classes.colSixth,
    5: classes.colFifths,
    4: classes.colFourths,
  };

  const layoutColumnsClassName = columnClassMap[numberOfColumns];

  return (
    <div className={classes.root}>
      <div className={classes.flexGrid}>
        {gridMap
          ? gridMap.map((row) =>
              row.map((coordinate) => (
                <div
                  key={`pod_${coordinate}`}
                  data-testid="pod-wrapper"
                  className={layoutColumnsClassName}
                >
                  <Pod
                    pod={pods.find((pod) => pod.coordinates === coordinate)}
                  />
                </div>
              ))
            )
          : null}
      </div>
    </div>
  );
};

FacilityLayout.propTypes = {
  pods: PropTypes.array,
  gridMap: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

FacilityLayout.defaultProps = {
  pods: undefined,
  gridMap: undefined,
};

export default withStyles(styles)(FacilityLayout);
