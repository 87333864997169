import { connect } from 'react-redux';

import { selectProp12Photos } from 'redux/photos/selectors';

import Prop12Photo from './Prop12Photo';

const mapStateToProps = (state, { palletId }) => {
  return {
    prop12PhotosLength: selectProp12Photos(state, palletId).length,
  };
};

export default connect(mapStateToProps, null)(Prop12Photo);
