import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import withStyles from '@mui/styles/withStyles';

import TeamMembers from './TeamMembers';
import styles from '../styles';

const TeamMembersCard = ({ classes, teamMembers }) => {
  if (teamMembers && teamMembers.length > 0) {
    return (
      <Card
        className={classNames(
          classes.informationalCard,
          classes.rightColInformationalCard
        )}
      >
        <Grid container spacing={3}>
          <Grid item>
            <h3 className={classes.cardHeading}>TEAM MEMBERS</h3>
          </Grid>
        </Grid>
        <List>
          <TeamMembers teamMembers={teamMembers} />
        </List>
      </Card>
    );
  } else {
    return null;
  }
};

TeamMembersCard.propTypes = {
  classes: PropTypes.object.isRequired,
  teamMembers: PropTypes.array,
};

TeamMembersCard.defaultProps = {
  teamMembers: null,
};

export default withStyles(styles)(TeamMembersCard);
