import { HC_GREEN, ERROR_RED } from 'lib/constants';

export default {
  defectButton: {
    marginLeft: '15px',
  },
  dialogContent: {
    height: '150px',
    width: '300px',
    padding: '30px',
    paddingBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
  },
  buttonYes: {
    backgroundColor: ERROR_RED,
    height: '55px',
    width: '80px',
    margin: '20px',
  },
  buttonNo: {
    backgroundColor: HC_GREEN,
    height: '55px',
    width: '80px',
    margin: '20px',
  },
  defectYes: {
    backgroundColor: ERROR_RED,
  },
  defectNo: {
    backgroundColor: HC_GREEN,
  },
  defectQuestion: {
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Muli',
  },
};
