import { linkTabStyling } from 'lib/styles';
import {
  HC_GREEN,
  DARK_HC_GREEN,
  GREY_100,
  GREY_700,
  WHITE,
} from 'lib/constants';

export default {
  ...linkTabStyling,
  navLinkContainer: {
    padding: '15px',
    background: GREY_100,
  },
  uploadButton: {
    float: 'right',
    fontSize: '16px',
    textAlign: 'center',
    margin: '20px 0px',
    color: GREY_700,
    marginLeft: 'auto',
  },
  comingSoon: {
    margin: '25px',
    textAlign: 'center',
    fontSize: '25px',
  },
  createButton: {
    float: 'right',
    fontSize: '16px',
    textAlign: 'center',
    margin: '20px 20px',
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    color: WHITE,
  },
  dateInput: {
    width: '225px',
  },
  headerContainer: {
    margin: '5px 15px',
  },
};
