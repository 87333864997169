import React from 'react';
import PropTypes from 'prop-types';

import { ExpandCollapse } from 'components/shared';

const AuditExpandCollapse = ({ onCollapseAll, onExpandAll }) => {
  return (
    <ExpandCollapse
      onExpandClick={onExpandAll}
      onCollapseClick={onCollapseAll}
    />
  );
};

AuditExpandCollapse.propTypes = {
  onCollapseAll: PropTypes.func.isRequired,
  onExpandAll: PropTypes.func.isRequired,
};

export default AuditExpandCollapse;
