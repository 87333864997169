import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN, LIGHT_GREEN } from 'lib/constants';

const ProductionInventoryIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.3 90.89">
        <path
          style={{ fill: HC_GREEN }}
          d="M127,73.1a13.38,13.38,0,0,0-9.85-7.46l1.59-.5V12.35L83.3.06,83.14,0,47.42,12.34V45.12c-1-.09-2.14-.14-3.28-.14-8.36,0-18,2.67-28.7,7.94A104.47,104.47,0,0,0,2,60.81L0,62.23,20.27,90.89,31.37,79l35,10a24.8,24.8,0,0,0,13.24.1l45.61-12.25A2.67,2.67,0,0,0,127,73.1ZM93.59,67.32l0,0a8.22,8.22,0,0,0-1-7.33,6.47,6.47,0,0,0-5.37-2.89h-.25c-.49,0-.87,0-1.36,0h-.32V26.83l28.61-8.47v43ZM83.28,5.17l26.87,9.27-27.05,8-26.65-8Zm-31,13.2,28.06,8.45V56.61c-6.78-1-11.58-3.49-15.83-5.71l-.11-.06A49.4,49.4,0,0,0,52.31,45.9Zm68.21,54.69L78.38,84.38a19.84,19.84,0,0,1-10.62-.08L29.84,73.49l-9,9.67L6.86,63.43c5.48-3.53,22.36-13.56,37.42-13.56,8,0,13,2.6,18.3,5.39C68.87,58.55,75.38,62,87.09,62h.13a1.67,1.67,0,0,1,1.37.82,3.38,3.38,0,0,1,.51,2.79c-.8,2-2.39,3.08-5.13,3.43-1.81.09-3.52.13-5.07.13a56.88,56.88,0,0,1-9.71-.94,84.15,84.15,0,0,1-13.73-5,2.46,2.46,0,0,0-2.16,4.43,86.64,86.64,0,0,0,15.78,5.6,45,45,0,0,0,10.35,1A109.51,109.51,0,0,0,94.8,73c7-.76,13.73-1.81,18.28-2.69a8.76,8.76,0,0,1,7.59,2.17.34.34,0,0,1,.1.33A.32.32,0,0,1,120.52,73.06Z"
        />
        <g style={{ opacity: '0.4', fill: LIGHT_GREEN }}>
          <polygon points="49.8 47.89 49.8 14.89 82.8 24.89 82.8 58.89 69.8 56.89 56.8 49.89 49.8 47.89" />
        </g>
        <g style={{ opacity: '0.4', fill: LIGHT_GREEN }}>
          <polygon points="19.8 85.89 13.8 76.89 27.8 65.89 69.8 84.89 30.8 75.89 19.8 85.89" />
        </g>
      </svg>
    </div>
  );
};

ProductionInventoryIcon.propTypes = {
  className: PropTypes.string,
};

ProductionInventoryIcon.defaultProps = {
  className: 'ProductionInventoryIcon',
};

export default ProductionInventoryIcon;
