// Helena: Shared functions between csvValidationHelpers could be extracted (headerError, requiredFieldErrors)
import difference from 'lodash/difference';

const fieldsExist = (data, fields) => {
  if (!data?.length) {
    return false;
  }
  const headers = data[0].meta.fields;
  const isPresent = (field) => {
    return headers.includes(field);
  };
  return fields.every(isPresent);
};

export const headerError = (data, headerRows) => {
  if (!data?.length) {
    return;
  }

  const headers = data[0].meta.fields;
  const diff = difference(headerRows, headers);

  if (diff.length > 0) {
    return {
      message: `CSV is missing ${diff
        .map((field) => field)
        .join(', ')} column(s)`,
    };
  }
};

export const fileDataLengthError = (data, minimum) => {
  if (data.length < minimum) {
    return {
      message: `Please include at least ${minimum} row/s of data`,
    };
  }
};

export const requiredNumColumnsError = (data, requiredNumColumns) => {
  if (!data?.length) {
    return;
  }

  const headers = data[0].meta.fields;
  if (headers.length != requiredNumColumns) {
    return {
      message: `There must be exactly ${requiredNumColumns} columns in the CSV.`,
    };
  }
};

export const nonNumericErrors = (data, col) => {
  let errors = [];

  if (!fieldsExist(data, [col])) {
    return;
  }

  data.forEach((row, index) => {
    const chars = row.data[col].split('');
    const nanCheck = (el) => isNaN(el);

    const hasNonNumericCharacters = chars.some(nanCheck);

    if (hasNonNumericCharacters) {
      const error = {
        message: `Row ${
          index + 2
        } in column ${col} has a non numeric character`,
      };
      errors.push(error);
    }
  });

  return errors;
};

export const checkAgainstListErrors = (data, col, list) => {
  let errors = [];

  if (!fieldsExist(data, [col])) {
    return;
  }

  data.forEach((row, index) => {
    let dataItem = row.data[col];

    const isInList = list.includes(dataItem);

    if (!isInList) {
      const error = {
        message: `Row ${
          index + 2
        } in column ${col} must be of values ${list.toString()}`,
      };
      errors.push(error);
    }
  });

  return errors;
};

export const requiredFieldErrors = (data, requiredFields) => {
  let errors = [];

  if (!fieldsExist(data, requiredFields)) {
    return;
  }

  data.forEach((row, index) => {
    requiredFields.forEach((field) => {
      const hasValue = row.data[field]?.length > 0;
      if (!hasValue) {
        const error = {
          message: `Row ${index + 2} does not have a value for ${field}`,
        };
        errors.push(error);
      }
    });
  });
  return errors;
};

// Helena: Additional validators will need to be added here in OPS-5072
