import {
  FSQA_ADD_TO_FORM,
  FSQA_REVIEW_POST_REQUEST,
  FSQA_REVIEW_POST_SUCCESS,
  FSQA_REVIEW_POST_ERROR,
  FSQA_REVIEW_PUT_REQUEST,
  FSQA_REVIEW_PUT_SUCCESS,
  FSQA_REVIEW_PUT_ERROR,
  FSQA_REVIEW_GET_REQUEST,
  FSQA_REVIEW_GET_SUCCESS,
  FSQA_REVIEW_GET_ERROR,
  FSQA_CLEAR_FORM,
  FSQA_TOGGLE_FULL_CHECK,
  LOT_CODES,
} from 'lib/constants';
import {
  clearFullCheckFields,
  formatIncomingAllergenInfo,
} from 'lib/redux_utils';

export const initialReviewState = {
  review: {},
  posting: false,
  postingFailed: false,
  fetching: false,
  fetchingFailed: false,
};

function reviewReducer(state = initialReviewState, action = { type: null }) {
  switch (action.type) {
    case FSQA_CLEAR_FORM:
      return {
        ...state,
        review: {},
      };
    case FSQA_ADD_TO_FORM:
      return {
        ...state,
        review: {
          ...state.review,
          [action.field]: action.value,
        },
      };
    case FSQA_TOGGLE_FULL_CHECK: {
      const turnToggleOn = !state.review.fullCheck;
      return {
        ...state,
        review: {
          ...(turnToggleOn ? state.review : clearFullCheckFields(state.review)),
          fullCheck: turnToggleOn,
        },
      };
    }
    case FSQA_REVIEW_POST_REQUEST:
    case FSQA_REVIEW_PUT_REQUEST:
      return {
        ...state,
        posting: true,
        postingFailed: false,
      };
    case FSQA_REVIEW_POST_SUCCESS:
    case FSQA_REVIEW_PUT_SUCCESS:
      return {
        ...state,
        posting: false,
        review: {
          ...action.response.fsqa_review,
          savedLotCodes: action.response.fsqa_review[LOT_CODES] || [],
          ...formatIncomingAllergenInfo(action.response.fsqa_review),
        },
      };
    case FSQA_REVIEW_POST_ERROR:
    case FSQA_REVIEW_PUT_ERROR:
      return {
        ...state,
        posting: false,
        postingFailed: true,
      };
    case FSQA_REVIEW_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingFailed: false,
      };
    case FSQA_REVIEW_GET_SUCCESS:
      if (action.response.fsqa_review) {
        return {
          ...state,
          fetching: false,
          review: {
            ...action.response.fsqa_review,
            savedLotCodes: action.response.fsqa_review[LOT_CODES] || [],
            ...formatIncomingAllergenInfo(action.response.fsqa_review),
          },
        };
      }
      return {
        ...state,
        fetching: false,
      };
    case FSQA_REVIEW_GET_ERROR:
      return {
        ...state,
        fetching: false,
        fetchingFailed: true,
      };
    default:
      return state;
  }
}

export default function (state = {}, action = { type: null }) {
  switch (action.type) {
    case FSQA_REVIEW_POST_REQUEST:
    case FSQA_REVIEW_POST_SUCCESS:
    case FSQA_REVIEW_POST_ERROR:
    case FSQA_REVIEW_PUT_REQUEST:
    case FSQA_REVIEW_PUT_SUCCESS:
    case FSQA_REVIEW_PUT_ERROR:
    case FSQA_REVIEW_GET_REQUEST:
    case FSQA_REVIEW_GET_SUCCESS:
    case FSQA_REVIEW_GET_ERROR:
      return {
        ...state,
        [action.params.palletId]: reviewReducer(
          state[action.params.palletId],
          action
        ),
      };
    case FSQA_ADD_TO_FORM:
    case FSQA_CLEAR_FORM:
    case FSQA_TOGGLE_FULL_CHECK:
      return {
        ...state,
        [action.palletId]: reviewReducer(state[action.palletId], action),
      };
    default:
      return state;
  }
}
