import React from 'react';
import PropTypes from 'prop-types';

const ArrowUpIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" fill="white" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  );
};

ArrowUpIcon.propTypes = {
  className: PropTypes.string,
};

ArrowUpIcon.defaultProps = {
  className: '',
};

export default ArrowUpIcon;
