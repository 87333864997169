import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { updateTruck } from 'redux/truck/actions';

import styles from './styles';
import EditTruckLine from './EditTruckLine';

const mapStateToProps = (state) => ({
  truckUpdatingFailed: state.trucks.summary.updateFailed,
});

const mapDispatchToProps = {
  onUpdateTruck: updateTruck,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditTruckLine));
