import React from 'react';

import IngredientRow from '../IngredientRow';

const IngredientPortioningRow = (props) => {
  const { ingredient } = props;
  if (ingredient.method === 'no portion method') {
    return null;
  } else {
    return (
      <IngredientRow
        {...props}
        showWIPColumn
        customData={[
          { dataType: 'percent', dataKey: 'portioning_percent' },
          { dataType: 'number', dataKey: 'portioning_remaining' },
          {
            dataType: 'percent',
            dataKey: 'portioning_percent_by_hand',
          },
          {
            dataType: 'percent',
            dataKey: 'portioning_percent_by_machine',
          },
          { dataType: 'number', dataKey: 'portioning_wip' },
          {
            dataType: 'number',
            dataKey: 'portioning_completed_count',
          },
        ]}
      />
    );
  }
};

export default IngredientPortioningRow;
