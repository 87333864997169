import React, { Fragment, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StatefulButton, ButtonGroup } from 'components/shared';
import { PHONE_SCREEN } from 'lib/constants';

import UserSearch from '../shared/UserSearch';
import {
  useLoginFormState,
  SELECTED_FACILITY_ID,
  SELECTED_FACILITY_NAME,
  SHIFT,
  ROLE,
  NAME,
  USER_WAS_SELECTED,
} from './stateUtils';

const buttonText = {
  LOADING: 'Loading...',
  SUCCESS: 'Success',
  ERROR: 'Login Failed',
  DEFAULT: 'Login',
};

export const UserInfoLine = ({ label, value, classes }) => {
  return (
    <div className={classes.preselectedInfo}>
      <span className={classes.preselectedLabel}>{label}:</span>
      <span
        data-testid={`user-info-${label}`}
        className={classes.preselectedValue}
      >
        {value}
      </span>
    </div>
  );
};

UserInfoLine.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

UserInfoLine.defaultProps = {
  value: '',
};

const Login = ({
  classes,
  onSubmitLoginForm,
  shifts,
  roles,
  facilities,
  sessionFailed,
  sessionPosting,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);

  const formattedFacilities = useMemo(
    () =>
      phoneScreen
        ? facilities.map((facility) => ({
            label: facility.labelAbbrev,
            value: facility.value,
          }))
        : facilities,
    [facilities, phoneScreen]
  );

  const {
    formState,
    clearSelectedUser,
    handleSelectUser,
    createUpdaterCallback,
  } = useLoginFormState(formattedFacilities);

  const canBeSubmitted =
    formState[NAME] &&
    formState[SELECTED_FACILITY_ID] &&
    formState[ROLE] &&
    formState[SHIFT];

  const handleLogin = () => {
    onSubmitLoginForm({
      packingFacilityId: formState[SELECTED_FACILITY_ID],
      shift: formState[SHIFT],
      name: formState[NAME],
      role: formState[ROLE],
    });
  };

  const renderUserInfo = () => {
    if (formState[USER_WAS_SELECTED]) {
      return (
        <Fragment>
          <UserInfoLine
            classes={classes}
            label="Facility"
            value={formState[SELECTED_FACILITY_NAME]}
          />
          <UserInfoLine
            classes={classes}
            label="Role"
            value={formState[ROLE]}
          />
        </Fragment>
      );
    }
    if (formattedFacilities.length === 1) {
      return (
        <Fragment>
          <UserInfoLine
            classes={classes}
            label="Facility"
            value={formState[SELECTED_FACILITY_NAME]}
          />

          <ButtonGroup
            currentValue={formState[ROLE]}
            setNewValue={createUpdaterCallback(ROLE)}
            headerText="Role"
            values={roles}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <ButtonGroup
          currentValue={formState[SELECTED_FACILITY_ID]}
          setNewValue={createUpdaterCallback(SELECTED_FACILITY_ID)}
          headerText="Facility"
          values={formattedFacilities}
        />
        <ButtonGroup
          currentValue={formState[ROLE]}
          setNewValue={createUpdaterCallback(ROLE)}
          headerText="Role"
          values={roles}
        />
      </Fragment>
    );
  };

  return (
    <Box className={classes.container} display="flex">
      <Paper className={classes.root} elevation={3}>
        <h2 className={classes.title}>Ops Login</h2>
        <UserSearch
          onCreateUser={createUpdaterCallback(NAME)}
          nameSelected={formState[NAME]}
          onSelectUser={handleSelectUser}
          onClearUser={() => clearSelectedUser(formattedFacilities)}
        />
        {formState[NAME] && (
          <Fragment>
            {renderUserInfo()}
            <ButtonGroup
              currentValue={formState[SHIFT]}
              setNewValue={createUpdaterCallback(SHIFT)}
              headerText="Shift"
              values={shifts}
            />
            <StatefulButton
              disabled={!canBeSubmitted}
              buttonTextOptions={buttonText}
              loading={sessionPosting}
              failed={sessionFailed}
              type="button"
              onClick={handleLogin}
              resetToNormal
              classes={{ root: classes.submitButton }}
            />
          </Fragment>
        )}
      </Paper>
    </Box>
  );
};

const buttonOptionsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelAbbrev: PropTypes.string,
});

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  facilities: PropTypes.arrayOf(buttonOptionsShape).isRequired,
  roles: PropTypes.arrayOf(buttonOptionsShape).isRequired,
  shifts: PropTypes.arrayOf(buttonOptionsShape).isRequired,
  sessionFailed: PropTypes.bool.isRequired,
  sessionPosting: PropTypes.bool.isRequired,
  onSubmitLoginForm: PropTypes.func.isRequired,
};

export default Login;
