import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';

import MenuDropDown from '../MenuDropDown';
import FacilitySelect from '../FacilitySelect';
import styles from '../styles';

const ProgressTrackerContent = ({
  menus,
  meals,
  onMenuSelected,
  onSelectedFacilityUpdated,
  classes,
  session,
}) => {
  const fetching = menus !== undefined ? menus.fetching : true;

  const findMenuFromSelect = (e) => {
    let foundMenu = menus.results.find((menu) => menu.id === e.target.value);
    onMenuSelected(foundMenu);
  };

  if (fetching)
    return (
      <div className={classes.progressTrackerLoading}>
        <Loading type="circular" />
      </div>
    );
  return (
    <Fragment>
      {meals && meals.fetching ? null : (
        <MenuDropDown
          menus={menus}
          className={classes.navItem}
          classes={classes}
          value={menus.selectedMenu}
          onChange={(e) => {
            findMenuFromSelect(e);
          }}
        />
      )}
      <FacilitySelect
        value={session.selectedPackingFacilityId || session.packing_facility_id}
        onChange={(e) => onSelectedFacilityUpdated(e.target.value, menus)}
      />
    </Fragment>
  );
};

ProgressTrackerContent.propTypes = {
  session: PropTypes.object,
  menus: PropTypes.object,
  meals: PropTypes.object,
  onSelectedFacilityUpdated: PropTypes.func,
  onMenuSelected: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

ProgressTrackerContent.defaultProps = {
  menus: undefined,
  meals: undefined,
  onSelectedFacilityUpdated: undefined,
  onMenuSelected: undefined,
  session: undefined,
};

export default withStyles(styles)(ProgressTrackerContent);
