import { connect } from 'react-redux';

import {
  selectQaPhotoPosting,
  selectQaPhotoFetching,
  selectQaPhotoFetchingFailed,
  selectQaPhotoPostingFailed,
  selectQaPhotoDeleting,
  selectQaPhotoDeletingFailed,
  selectPhotosForDisplay,
} from 'redux/photos/selectors';
import {
  postFsqaPhoto,
  deleteFsqaPhoto,
  fetchFsqaPhotos,
} from 'redux/photos/actions';
import {
  FSQA_LOT_CODE,
  FSQA_DEFECT,
  PROP_12,
  DEFECT_PHOTOS,
  LOT_CODE_PHOTOS,
  PROP_12_PHOTOS,
} from 'lib/constants';

import PhotoViewerTaker from './PhotoViewerTaker';

const mapSharedStateToProps = (state, palletId) => ({
  postingPhoto: selectQaPhotoPosting(state, palletId),
  postingPhotoFailed: selectQaPhotoPostingFailed(state, palletId),
  fetchingPhotos: selectQaPhotoFetching(state, palletId),
  fetchingPhotosFailed: selectQaPhotoFetchingFailed(state, palletId),
  deletingPhoto: selectQaPhotoDeleting(state, palletId),
  deletingPhotoFailed: selectQaPhotoDeletingFailed(state, palletId),
});

const mapLotCodeStateToProps = (state, { palletId }) => {
  return {
    ...mapSharedStateToProps(state, palletId),
    photos: selectPhotosForDisplay(state, palletId, LOT_CODE_PHOTOS),
  };
};

const mapDefectStateToProps = (state, { palletId }) => {
  return {
    ...mapSharedStateToProps(state, palletId),
    photos: selectPhotosForDisplay(state, palletId, DEFECT_PHOTOS),
  };
};

const mapProp12StateToProps = (state, { palletId }) => {
  return {
    ...mapSharedStateToProps(state, palletId),
    photos: selectPhotosForDisplay(state, palletId, PROP_12_PHOTOS),
  };
};

const mapSharedDispatchToProps = (dispatch, palletId) => ({
  onFetchPhotos: () => dispatch(fetchFsqaPhotos(palletId)),
});

export const mapLotCodeDispatchToProps = (dispatch, { palletId }) => ({
  ...mapSharedDispatchToProps(dispatch, palletId),
  onPostPhoto: (photo) =>
    dispatch(postFsqaPhoto(palletId, photo, FSQA_LOT_CODE)),
  onDeletePhoto: (photoId) =>
    dispatch(deleteFsqaPhoto(palletId, photoId, FSQA_LOT_CODE)),
});

export const mapDefectDispatchToProps = (dispatch, { palletId }) => ({
  ...mapSharedDispatchToProps(dispatch, palletId),
  onPostPhoto: (photo) => dispatch(postFsqaPhoto(palletId, photo, FSQA_DEFECT)),
  onDeletePhoto: (photoId) =>
    dispatch(deleteFsqaPhoto(palletId, photoId, FSQA_DEFECT)),
});

export const mapProp12DispatchToProps = (dispatch, { palletId }) => ({
  ...mapSharedDispatchToProps(dispatch, palletId),
  onPostPhoto: (photo) => dispatch(postFsqaPhoto(palletId, photo, PROP_12)),
  onDeletePhoto: (photoId) =>
    dispatch(deleteFsqaPhoto(palletId, photoId, PROP_12)),
});

export const LotCodesPhotoViewerTaker = connect(
  mapLotCodeStateToProps,
  mapLotCodeDispatchToProps
)(PhotoViewerTaker);
export const DefectsPhotoViewerTaker = connect(
  mapDefectStateToProps,
  mapDefectDispatchToProps
)(PhotoViewerTaker);
export const Prop12PhotoViewerTaker = connect(
  mapProp12StateToProps,
  mapProp12DispatchToProps
)(PhotoViewerTaker);
