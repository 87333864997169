import { connect } from 'react-redux';

import { postDropList } from 'redux/dropList/actions';

import DropListUploader from './DropListUploader';

const mapStateToProps = (state) => {
  return {
    posting: state.dropList.posting,
    postingErrors: state.dropList.errors,
  };
};

const mapDispatchToProps = {
  onPostDropList: postDropList,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropListUploader);
