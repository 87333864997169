import { WHITE, MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  root: {
    display: 'inline-block',
  },
  accountSelect: {
    fontSize: '14px',
  },
  accountSelectMenu: {
    color: WHITE,
  },
  accountIcon: {
    color: WHITE,
  },
  menuItem: {
    fontSize: '14px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    accountSelect: {
      fontSize: '14px',
      paddingRight: '24px',
    },
  },
};
