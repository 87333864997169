import { WHITE, GREY_200 } from 'lib/constants';

export default {
  root: {
    maxHeight: '800px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 0px 8px 16px',
    minWidth: '196px',
    minHeight: '256px',
  },
  photo: {
    width: '178px',
    height: '238px',
    objectFit: 'cover',
    borderRadius: '3px',
    border: `1px solid ${GREY_200}`,
  },
  photoContainer: {
    margin: '8px 16px 8px 0px',
    position: 'relative',
    height: '240px',
  },
  processingContainer: {
    fontFamily: 'Muli',
    borderRadius: '3px',
    border: `1px solid ${GREY_200}`,
    display: 'flex',
    alignItems: 'center',
    width: '178px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  deleteButton: {
    background: WHITE,
    position: 'absolute',
    right: '10px',
    top: '10px',
    padding: '6px',
    '&:hover': {
      backgroundColor: WHITE,
    },
  },
};
