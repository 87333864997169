import React from 'react';

import IngredientRow from '../IngredientRow';

const IngredientLabelingRow = (props) => {
  const { ingredient } = props;

  if (ingredient.labelRequired === false) {
    return null;
  } else {
    return (
      <IngredientRow
        {...props}
        showWIPColumn
        customData={[
          { dataType: 'percent', dataKey: 'labeling_percent' },
          { dataType: 'number', dataKey: 'labeling_remaining' },
          {
            dataType: 'percent',
            dataKey: 'labeling_percent_by_hand',
          },
          {
            dataType: 'percent',
            dataKey: 'labeling_percent_by_applicator',
          },
          { dataType: 'number', dataKey: 'labeling_wip' },
          { dataType: 'number', dataKey: 'labeling_completed_count' },
        ]}
      />
    );
  }
};

export default IngredientLabelingRow;
