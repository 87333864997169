export const SLACKING = 'slacking';
export const MIXING = 'mixing';
export const COOKING = 'cooking';
export const CHILLING = 'chilling';
export const RTH_PORTIONING = 'portioning';
export const ASSEMBLY = 'assembly';

export const COMPLETE_ACTION = 'complete';
export const COMPLETED_STATE = 'completed';
export const FAIL_ACTION = 'fail';
export const ON_HOLD_STATE = 'on_hold';
export const ON_HOLD_ACTION = 'put_on_hold';
export const RELEASE_HOLD_ACTION = 'release_hold';
export const PRINT_ACTION = 'print';
export const DECANT_ACTION = 'decant';

export const ACTIVITIES_WITH_IN_PROGRESS_STATE = [COOKING, CHILLING];

export const ACTIVITIES_WITH_ONLY_SUBMITTED_COUNT_IN_TABLE = [SLACKING];

export const ACTIVITY_UNIT_MAP = {
  [ASSEMBLY]: 'meal',
  [MIXING]: 'recipe',
  [COOKING]: 'recipe',
  [CHILLING]: 'recipe',
  [SLACKING]: 'bag',
  [RTH_PORTIONING]: 'portion',
  default: 'unit',
};
