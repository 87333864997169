import { BLACK, HC_GREEN, GREY_200 } from 'lib/constants';

// Re-usable styles
export const centeredLoading = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '200px',
};

export const addEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const centeredLoadingContainer = {
  flexDirection: 'column',
  paddingTop: '0',
};

export const linkTabStyling = {
  linkContainer: {
    height: '50px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    padding: '5px 0px',
    marginRight: '20px',
    textDecoration: 'none',
    color: BLACK,
    fontSize: '18px',
  },
  activeLink: {
    color: HC_GREEN,
    borderBottom: `4px solid ${HC_GREEN}`,
  },
};

export const drawerStyling = {
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 0px 20px',
    borderBottom: `1px solid ${GREY_200}`,
    fontWeight: 'bold',
    fontSize: 20,
  },
  bodySection: {
    overflowY: 'auto',
    height: 'calc(100vh - 85px)',
  },
};

export const loadingMessage = {
  fontWeight: 'bold',
};
