import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  NavLink,
  Redirect,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { HOME_BUTTON, ACCOUNT_DROPDOWN, TEXT } from 'lib/constants';

import BoxRayScanField from './BoxRayScanField';
import ShipmentDetails from './ShipmentDetails';
import ShippingOptionDetails from './ShippingOptionDetails';
import LabelPrinting from './LabelPrinting';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const BoxRay = ({ onSetTopBarContent, classes, location }) => {
  const [trackingNumber, setTrackingNumber] = useState('');

  const isShipmentDetailsPage = /shipment-details/.test(location.pathname);
  const isShippingOptionsPage = /shipping-options/.test(location.pathname);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: TEXT,
      rightContent: ACCOUNT_DROPDOWN,
      text: 'Box-Ray',
    });
  }, [location.pathname, onSetTopBarContent]);

  return (
    <div className={classes.root}>
      {!isShipmentDetailsPage && !isShippingOptionsPage && (
        <Grid container className={classes.navLinksContainer}>
          <Grid item xs={6} className={classes.navLinkContainer}>
            <NavLink
              to="/box-ray/view-box-details"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              View Box Details
            </NavLink>
          </Grid>
          <Grid item xs={6} className={classes.navLinkContainer}>
            <NavLink
              to="/box-ray/print-new-label"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Print New Label
            </NavLink>
          </Grid>
        </Grid>
      )}

      <Switch>
        <Route exact path="/box-ray/">
          <Redirect to="/box-ray/view-box-details" />
        </Route>
        <Route exact path="/box-ray/print-new-label">
          <BoxRayScanField
            trackingNumber={trackingNumber}
            onSetTrackingNumber={setTrackingNumber}
            pushUrl="/box-ray/shipping-options"
            location={location}
          />
        </Route>
        <Route
          exact
          path="/box-ray/view-box-details"
          render={() => (
            <BoxRayScanField
              trackingNumber={trackingNumber}
              onSetTrackingNumber={setTrackingNumber}
              pushUrl="/box-ray/shipment-details"
              location={location}
            />
          )}
        />
        <Route
          exact
          path="/box-ray/shipment-details/:trackingNumber"
          render={(routingProps) => (
            <>
              <BoxRayScanField
                trackingNumber={trackingNumber}
                onSetTrackingNumber={setTrackingNumber}
                pushUrl="/box-ray/shipment-details"
                location={location}
              />
              <ShipmentDetails {...routingProps} />
            </>
          )}
        />
        <Route
          exact
          path="/box-ray/shipping-options/:trackingNumber"
          render={(routingProps) => <ShippingOptionDetails {...routingProps} />}
        />
        <Route
          exact
          path="/box-ray/printing-label"
          render={(routingProps) => <LabelPrinting {...routingProps} />}
        />
      </Switch>
    </div>
  );
};

BoxRay.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BoxRay;
