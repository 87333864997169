import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { CHILLING_MACHINES } from 'lib/constants';
import { StatefulButton } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const buttonText = {
  DEFAULT: 'CONFIRM',
  SUCCESS: 'Success!',
  LOADING: 'SAVING...',
  ERROR: 'ERROR - Try Again',
};

const EquipmentForm = ({ closeForm, isFailed, isFetching, onSubmit }) => {
  const classes = useStyles();
  const [machineryIdentifier, setMachineryIdentifier] = useState('');

  const resetForm = () => {
    closeForm(false);
    setMachineryIdentifier('');
  };

  return (
    <>
      <hr className={classes.formDivider} />
      <div className={classes.formHeader}>
        <div className={classes.formHeaderText}>Complete Sublot</div>
        <div>
          <Button variant="text" color="error" onClick={resetForm}>
            Cancel
          </Button>
        </div>
      </div>
      <div className={classes.formLabelText}>
        Which equipment number was used?
      </div>
      <Select
        className={classes.dropdown}
        defaultValue=""
        displayEmpty
        onChange={(e) => setMachineryIdentifier(e.target.value)}
        value={machineryIdentifier}
        data-testid="select-machinery-identifier"
      >
        {CHILLING_MACHINES.map((identifier) => (
          <MenuItem value={identifier} key={identifier}>
            Chiller {identifier}
          </MenuItem>
        ))}
      </Select>
      <div>
        <StatefulButton
          classes={{ root: classes.formSubmitButton }}
          type="submit"
          disabled={!machineryIdentifier}
          loading={isFetching}
          failed={isFailed}
          buttonTextOptions={buttonText}
          onClick={() => onSubmit({ machineryIdentifier })}
        />
      </div>
    </>
  );
};

EquipmentForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  isFailed: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

EquipmentForm.defaultProps = {
  isFailed: false,
  isFetching: false,
};

export default EquipmentForm;
