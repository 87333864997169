import { makeApiRequest } from 'lib/api';
import { WAREHOUSE_REQUESTS_PACKAGING_ITEMS } from 'lib/constants';

import PackagingItems from './services';

export const getPackagingItems = (ingredientAssignmentIds) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: WAREHOUSE_REQUESTS_PACKAGING_ITEMS,
        method: PackagingItems.getPackagingItems,
        params: {
          ingredientAssignmentIds: ingredientAssignmentIds,
        },
      })
    );
  };
};
