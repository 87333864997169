import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  BACK_BUTTON,
  INVENTORY_MANAGER_ACTIONS,
  TEXT,
  CYCLE_COUNT,
} from 'lib/constants';
import { Loading, Snackbar } from 'components/shared';

import PalletDetails from './Components/PalletDetails/index';
import MarkCountAsComplete from './Components/MarkCountAsComplete/MarkCountAsComplete';
import AddPalletToLocation from './Components/AddPalletToLocation';
import ProgressVisuals from './Components/ProgressVisuals';
import {
  useCycleCountingState,
  formatItemId,
  sidebarFilterOptions,
} from './stateUtils';
import StorageSlotGroups from '../StorageSlotGroups';
import PalletEditModal from '../PalletEditModal';

const CycleCountingList = ({
  classes,
  onSetTopBarContent,
  onFetchCountList,
  countList,
  onFetchPallet,
  measuringUnitsForSelectedPallet,
  onUpdatePallet,
  onSetCycleCount,
  onUpdateCountListAssignment,
  onSetUnknownLocation,
  deactivatePallet,
  clearCycleCountInfo,
  userName,
  match,
  onCompleteCountList,
  onRefreshCountList,
  refreshCountListFetching,
  refreshCountListError,
  history,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredSlotGroups, setFilteredSlotGroups] = useState([]);
  const { state, ...actions } = useCycleCountingState();

  const getNextItemId = (itemId, slotGroups) => {
    // Get all eligible items. An eligible item is an item with
    // a count list assignment.
    const eligibleItems = slotGroups.flatMap((slotGroup) => {
      return slotGroup.slots
        .filter((slot) => {
          return slot.countListAssignmentId != null;
        })
        .map((slot) => {
          return {
            itemId: formatItemId({
              slotName: slot.storageSlotName,
              palletId: slot.pallet.id,
            }),
            itemStatus: slot.countListAssignmentStatus,
          };
        });
    });

    //If there are no eligibleItems return null
    if (eligibleItems.length < 1) {
      return null;
    }

    //Get the index for the itemId passed into this function
    const indexForItemId = eligibleItems.findIndex((el) => {
      return el['itemId'] === itemId;
    });

    //If no index was found for the item passed in there's no
    //use continuing. Return the first eligibleItemId.
    if (indexForItemId === -1) {
      return eligibleItems[0]['itemId'];
    }

    //Reorder the eligibleItems so the itemId passed in is first
    const orderedByItemId = eligibleItems
      .splice(indexForItemId)
      .concat(eligibleItems);

    //Search for pending status items that are not our
    //passed in itemId
    const foundItem = orderedByItemId.find((el) => {
      return el['itemStatus'] === 'pending' && el['itemId'] !== itemId;
    });

    //If no pending item was found, return the itemId that
    //was passed in.
    let selectedItem;
    if (typeof foundItem === 'undefined') {
      selectedItem = itemId;
    } else {
      selectedItem = foundItem['itemId'];
    }

    return selectedItem;
  };

  const selectStorageSlot = (
    storageSlotInfo = { pallet: {} },
    shouldAutoScroll
  ) => {
    actions.selectStorageSlot({ storageSlotInfo, shouldAutoScroll });
  };

  useEffect(() => {
    !countList.id && onFetchCountList(match.params.id);
  }, [match]);

  useEffect(() => {
    // currently just using this to possible units of measure list for the selected pallet
    state.pallet.id && onFetchPallet(state.pallet.id);
  }, [state.pallet.id]);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      text: 'Count List',
      middleContent: TEXT,
      rightContent: INVENTORY_MANAGER_ACTIONS,
    });

    // clean up method: on unmount this will clear out data in redux and the reducer
    return () => {
      clearCycleCountInfo();
      actions.clearSlotInfo();
    };
  }, []);

  useEffect(() => {
    const storageSlots = filteredSlotGroups.flatMap(
      (slotGroup) => slotGroup.slots
    );
    // default slot is determined by the following order
    // selectedId -> first pending item in the list -> first storge slot -> undefined for no storage slots
    let defaultSlot =
      storageSlots.find((slot) =>
        state.selectedItemId
          ? state.selectedItemId ===
            formatItemId({
              slotName: slot.storageSlotName,
              palletId: slot.pallet.id,
            })
          : slot.countListAssignmentId &&
            slot.countListAssignmentStatus === 'pending'
      ) || storageSlots[0];

    if (defaultSlot) {
      const shouldAutoScroll = !state.selectedItemId;
      selectStorageSlot(defaultSlot, shouldAutoScroll);
    }
  }, [filteredSlotGroups]);

  useEffect(() => {
    if (state.shouldAutoScroll && !state.loading) {
      document
        ?.getElementById(state.selectedItemId)
        ?.scrollIntoView({ block: 'center' });
    }
  }, [state.selectedItemId, state.loading]);

  useEffect(() => {
    if (state.loading && state.selectedItemId) {
      actions.setLoading(false);
    }
  }, [state.selectedItemId]);

  useEffect(() => {
    switch (state.appliedFilter) {
      case 'showAll': {
        setFilteredSlotGroups(countList.storageSlots);
        break;
      }
      case 'showCountItems': {
        const filteredByCountItem = countList.storageSlots
          // map through slotGroups to find slots associated with the count list
          .map((slotGroup) => {
            const relevantSlots = slotGroup.slots.filter((slot) => {
              return slot.countListAssignmentId;
            });

            // return only the relevant slots within a slotGroup (i.e. has matching count list id)
            return {
              name: slotGroup.name,
              slots: relevantSlots,
            };
          })
          // filter out empty slotGroups
          .filter((slotGroup) => {
            return slotGroup.slots.length > 0;
          });

        setFilteredSlotGroups(filteredByCountItem);
        break;
      }
      case 'showUncompleted': {
        const filteredByUncompletedItems = countList.storageSlots
          // map through slotGroups to find slots associated with the count list AND a status of pending
          .map((slotGroup) => {
            const relevantSlots = slotGroup.slots.filter((slot) => {
              return (
                slot.countListAssignmentId &&
                slot.countListAssignmentStatus === 'pending'
              );
            });

            // return only the relevant slots within a slotGroup (i.e. has matching count list id)
            return {
              name: slotGroup.name,
              slots: relevantSlots,
            };
          })
          // filter out empty slotGroups
          .filter((slotGroup) => {
            return slotGroup.slots.length > 0;
          });

        setFilteredSlotGroups(filteredByUncompletedItems);
        break;
      }
      case 'showGroundPosition': {
        const filteredByGroundPosition = countList.storageSlots.map(
          (slotGroup) => {
            const relevantSlots = slotGroup.slots.filter((slot) => {
              let height = slot.position[0];
              return height == '1';
            });

            return {
              name: slotGroup.name,
              slots: relevantSlots,
            };
          }
        );

        setFilteredSlotGroups(filteredByGroundPosition);
        break;
      }
    }
  }, [state.appliedFilter, countList.storageSlots]);

  const toggleModal = () => setIsModalOpen((isOpen) => !isOpen);

  const updateCLA = (claStatus) => {
    onUpdateCountListAssignment(state.countListAssignmentId, {
      users: userName,
      status: claStatus,
    }).then(() => {
      actions.setSelectedItemId({
        itemId: getNextItemId(state.selectedItemId, filteredSlotGroups),
        shouldAutoScroll: true,
      });

      onFetchCountList(match.params.id);
    });
  };

  const refreshCountList = () => {
    onRefreshCountList(countList.id);
  };

  const updatePallet = async () => {
    await onUpdatePallet(state.pallet.id, {
      measuring_unit_id: state.measuringUnitId,
      quantity: state.quantity,
      change_reason_type: CYCLE_COUNT,
      count_list_id: countList.id,
    });
    onSetCycleCount(state.pallet.id, true);
    updateCLA('confirmed');
  };

  const updatePalletProductDate = async () => {
    await onUpdatePallet(state.pallet.id, {
      product_date: state.productDate,
      product_date_type: state.productDateType,
      count_list_id: countList.id,
    });
    setIsModalOpen(false);
    onFetchCountList(match.params.id);
  };

  const setPalletToUnknownLocation = async () => {
    await onSetUnknownLocation(state.pallet.id, countList.id);
    updateCLA('unknown location');
    setIsModalOpen(false);
  };

  const deactivateCountListPallet = async () => {
    await deactivatePallet(state.pallet.id, countList.id);
    updateCLA('deactivated');
    setIsModalOpen(false);
  };

  const showAddedPallet = (palletId) => {
    actions.setSelectedItemId({
      itemId: formatItemId({ slotName: state.storageSlotName, palletId }),
      shouldAutoScroll: false,
    });

    onFetchCountList(match.params.id);
  };

  const completeCount = async (countListId) => {
    await onCompleteCountList(countListId);
    history.push('/cycle-counting');
  };

  const onFilterChange = (filter) => {
    actions.setSelectedItemId({
      itemId: getNextItemId(null, filteredSlotGroups),
      shouldAutoScroll: true,
    });
    actions.changeFilter(filter);
  };

  useEffect(() => {
    if (countList.groundOnly) {
      onFilterChange('showGroundPosition');
    }
  }, [countList.id]);

  if (state.loading) {
    return <Loading fullScreen />;
  }

  return (
    <>
      <Grid container spacing={0} className={classes.container}>
        <Grid item xs={4} className={`${classes.item} ${classes.leftRail}`}>
          <Grid container item spacing={1} className={classes.itemHeader}>
            <Grid item md={5} xs={12}>
              <TextField
                data-testid="filter-sidebar"
                variant="outlined"
                value={state.appliedFilter}
                select
                fullWidth
                onChange={(e) => onFilterChange(e.target.value)}
              >
                {sidebarFilterOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={5} xs={12}>
              <MarkCountAsComplete
                countList={countList}
                onClickComplete={completeCount}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                variant="contained"
                aria-label="refresh-button"
                onClick={refreshCountList}
                disabled={refreshCountListFetching}
              >
                <RefreshIcon sx={{ height: '44px' }} />
              </Button>
            </Grid>
          </Grid>
          <StorageSlotGroups
            groupedSlots={filteredSlotGroups}
            selectedItemId={state.selectedItemId}
            selectStorageSlot={selectStorageSlot}
          />
        </Grid>
        <Grid item xs={8} className={classes.item}>
          <ProgressVisuals countList={countList} />
          <PalletDetails
            storageSlotName={state.storageSlotName}
            pallet={state.pallet}
            measuringUnitsForSelectedPallet={measuringUnitsForSelectedPallet}
            openModal={toggleModal}
            onSubmit={updatePallet}
            setQuantity={(quantity) => actions.updateQuantity(quantity)}
            setMeasuringUnit={({ id, name }) =>
              actions.updateMeasuringUnit(id, name)
            }
          />
        </Grid>
      </Grid>
      <div className={classes.cornerButton}>
        <AddPalletToLocation
          storageSlotName={state.storageSlotName}
          storageSlotId={state.storageSlotId}
          countListId={countList.id}
          onAddPalletSuccess={showAddedPallet}
        />
      </div>
      <PalletEditModal
        isOpen={isModalOpen}
        closeModal={toggleModal}
        pallet={state.pallet}
        setProductDateType={(productDateType) =>
          actions.updateProductDateType(productDateType)
        }
        productDateType={state.productDateType}
        setProductDate={(productDate) => actions.updateProductDate(productDate)}
        productDate={state.productDate}
        updatePalletProductDate={updatePalletProductDate}
        setPalletToUnknownLocation={setPalletToUnknownLocation}
        deactivatePallet={deactivateCountListPallet}
      />
      <Snackbar
        loading={refreshCountListFetching}
        failed={refreshCountListError}
        messaging={{
          loading: 'Refreshing count list...',
          failure: 'Failed to refresh count list',
          success: 'Count list refreshed!',
        }}
        options={{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }}
      />
    </>
  );
};

CycleCountingList.propTypes = {
  classes: PropTypes.object.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  onFetchCountList: PropTypes.func.isRequired,
  countList: PropTypes.object.isRequired,
  onFetchPallet: PropTypes.func.isRequired,
  measuringUnitsForSelectedPallet: PropTypes.array,
  onUpdatePallet: PropTypes.func.isRequired,
  onSetCycleCount: PropTypes.func.isRequired,
  onUpdateCountListAssignment: PropTypes.func,
  userName: PropTypes.string,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

CycleCountingList.defaultProps = {
  measuringUnitsForSelectedPallet: [],
  onUpdateCountListAssignment: () => {},
  userName: null,
};

export default CycleCountingList;
