import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setTopBarContent } from 'redux/topBar/actions';

import StorageAreaList from './StorageAreaList';

const mapStateToProps = (state, ownProps) => ({
  fetching: state.storageAreas.fetching,
  storageAreas: ownProps.showProd
    ? state.storageAreas.results
    : state.storageAreas.results.filter((area) => area.name !== 'Prod'),
  failed: state.storageAreas.failed,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StorageAreaList)
);
