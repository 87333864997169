import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const WarehouseRequestsIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130 90"
        width="100%"
        height="100%"
      >
        <title>Warehouse Requests</title>
        <g fillOpacity={0.4}>
          <polygon
            fill={HC_GREEN}
            points="64.5 65.5 39.87 65.5 39.87 3.09 65.5 3.5 64.5 65.5"
          />
        </g>
        <path
          fill={HC_GREEN}
          d="M20.5,50h-7a2.5,2.5,0,0,0,0,5h7a2.5,2.5,0,0,0,0-5Z"
        />
        <path
          fill={HC_GREEN}
          d="M14,27.5A2.5,2.5,0,0,0,11.5,25h-9a2.5,2.5,0,0,0,0,5h9A2.5,2.5,0,0,0,14,27.5Z"
        />
        <path
          fill={HC_GREEN}
          d="M68.5,0H36V12H10.5a2.5,2.5,0,0,0,0,5H36v8H20.5a2.5,2.5,0,0,0,0,5H36v8H10.5a2.5,2.5,0,0,0,0,5H36v7H29.5a2.5,2.5,0,0,0,0,5H36V68h92.5V0Zm-5,63H41V55h9.5a2.5,2.5,0,0,0,0-5H41V43H54.5a2.5,2.5,0,0,0,0-5H41V17h9.5a2.5,2.5,0,0,0,0-5H41V5H63.5Zm60,0H69V5h54.5Z"
        />
        <path
          fill={HC_GREEN}
          d="M111.84,25.68a.93.93,0,0,0-.54-1L96,16.94,80.89,24.59a1,1,0,0,0-.69,1V50.92h31.65Zm-10.69,21c-.16-2.72.17-9.68.08-20.3a.37.37,0,0,0-.66-.25c-1.42,1.79-2.18,3.5-2.54,7.59-.19,2.18,1.08,6.3,1.43,9.4a25.55,25.55,0,0,1,0,4.72H93.63c0-.36,0-.71,0-1.07-.12-2.57-.24-5.15-.34-7.73a3.25,3.25,0,0,1,.21-1.64c.1-.17.23-.33.34-.5a5.11,5.11,0,0,0,.45-1,9.55,9.55,0,0,0,.56-2.8v-.31l-.36-6.13a.35.35,0,0,0-.33-.39.44.44,0,0,0-.4.41l-.2,7a.27.27,0,0,1-.27.29c-.14,0-.21-.11-.21-.28l-.16-7.08a.4.4,0,0,0-.38-.36c-.26,0-.31.32-.31.43l-.25,7a.28.28,0,0,1-.26.29c-.16,0-.22-.14-.22-.31l-.16-7a.43.43,0,0,0-.39-.43c-.2,0-.33.21-.34.43l-.39,6.09v.31a9.53,9.53,0,0,0,.55,2.8,5.15,5.15,0,0,0,.46,1c.11.17.24.33.34.5a3.24,3.24,0,0,1,.2,1.64q-.15,3.87-.33,7.73c0,.36,0,.71,0,1.07h-8v-21L96,20.42l12.75,6.49v20.9h-7.52C101.19,47.41,101.17,47,101.15,46.65Z"
        />
      </svg>
    </div>
  );
};

WarehouseRequestsIcon.propTypes = {
  className: PropTypes.string,
};

WarehouseRequestsIcon.defaultProps = {
  className: 'WarehouseRequestsIcon',
};

export default WarehouseRequestsIcon;
