import { makeApiRequest } from 'lib/api';
import { VIEW_BOX_DETAILS, CLEAR_SHIPMENT_DETAILS } from 'lib/constants';

import ViewBoxDetails from './services';

export const getShipment = (params) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: VIEW_BOX_DETAILS,
        method: ViewBoxDetails.getShipment,
        params: params,
      })
    );
  };
};

export const clearShipmentDetails = () => {
  return {
    type: CLEAR_SHIPMENT_DETAILS,
  };
};
