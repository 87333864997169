import { connect } from 'react-redux';

import {
  fetchPallets,
  printPallets,
  deletePallet,
} from 'redux/pallets/actions';

import ItemDrawerPalletContent from './ItemDrawerPalletContent';

const mapStateToProps = (state) => ({
  pallets: state.pallets.receivingApp.pallets,
  palletsLoading:
    state.pallets.receivingApp.posting || state.pallets.receivingApp.fetching,
  measuringUnits: state.pallets.receivingApp.measuringUnits,
});

const mapDispatchToProps = {
  fetchPallets,
  printPallet: printPallets,
  deletePallet: deletePallet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemDrawerPalletContent);
