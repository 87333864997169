import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const IngredientAssignmentList = ({ ingredientAssignments }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>Ingredient + Brand</TableCell>
            <TableCell className={classes.header}>Qty/UOM</TableCell>
            <TableCell className={classes.header}>Portion Method</TableCell>
            <TableCell className={classes.header}>Packaging</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredientAssignments.map((ingredientAssignment) => (
            <TableRow
              key={ingredientAssignment.id}
              className={classes.zebraRow}
            >
              <TableCell className={classes.nameAndBrand}>
                <div className={classes.ingredientAssignmentName}>
                  {ingredientAssignment.name}
                </div>
                <div className={classes.ingredientAssignmentBrand}>
                  {ingredientAssignment.brand}
                </div>
              </TableCell>
              <TableCell className={classes.cell}>
                {ingredientAssignment.quantityToUom}
              </TableCell>
              <TableCell className={classes.cell}>
                {ingredientAssignment.portionMethod}
              </TableCell>
              <TableCell className={classes.cell}>
                {ingredientAssignment.packaging}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

IngredientAssignmentList.propTypes = {
  ingredientAssignments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      brand: PropTypes.string,
      quantityToUom: PropTypes.string,
      portionMethod: PropTypes.string,
      packaging: PropTypes.string,
    })
  ),
};

IngredientAssignmentList.defaultProps = {
  ingredientAssignments: [],
};

export default IngredientAssignmentList;
