import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import withStyles from '@mui/styles/withStyles';

import { resetIngredientAssignment } from 'redux/ingredients/actions';
import { Loading } from 'components/shared';

import styles from './styles';

import IngredientInventoryForm from './index';

const CustomizeItInventoryForm = ({
  classes,
  match,
  onGetProductionProgressForCustomizeIt,
  ingredientAssignment,
  onResetIngredientAssignment,
}) => {
  const { shipWeek, ingredientAssignmentId } = match.params;

  useEffect(() => {
    onGetProductionProgressForCustomizeIt({
      shipWeek,
      ingredientAssignmentId,
    });
  }, [onGetProductionProgressForCustomizeIt, shipWeek, ingredientAssignmentId]);

  useEffect(
    () => () => {
      onResetIngredientAssignment();
    },
    [onResetIngredientAssignment]
  );

  if (isEmpty(ingredientAssignment)) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  } else {
    return (
      <IngredientInventoryForm
        ingredientAssignment={ingredientAssignment}
        isCustomizeIt
      />
    );
  }
};

CustomizeItInventoryForm.propTypes = {
  match: PropTypes.object.isRequired,
  onGetProductionProgressForCustomizeIt: PropTypes.func.isRequired,
  onResetIngredientAssignment: PropTypes.func.isRequired,
  ingredientAssignment: PropTypes.object,
};

CustomizeItInventoryForm.defaultProps = {
  ingredientAssignment: {},
};

const mapStateToProps = (state) => ({
  ingredientAssignment: state.ingredients.ingredientAssignment,
});

const mapDispatchToProps = {
  onResetIngredientAssignment: resetIngredientAssignment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(CustomizeItInventoryForm)));
