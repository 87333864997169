import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import produce from 'immer';

import { sortAlphaNum } from 'lib/redux_utils';

const selectMenuSelectionsByIngredient = (state) => {
  const groupedMenuSelections = groupBy(
    state.menuSelection.ingredientAssignments,
    'menuShipWeek'
  );

  const sortedMenuSelections = {};
  Object.keys(groupedMenuSelections)
    .sort()
    .forEach((key) => (sortedMenuSelections[key] = groupedMenuSelections[key]));

  return sortedMenuSelections;
};

export const selectMenuSelectionsByIngredientAssignment = createSelector(
  selectMenuSelectionsByIngredient,
  (groupedMenuselectionsByIngredient) =>
    produce(
      groupedMenuselectionsByIngredient,
      (sortedMenuSelectionsByIngredient) => {
        Object.keys(sortedMenuSelectionsByIngredient).forEach((key) => {
          sortedMenuSelectionsByIngredient[key].sort(sortAlphaNum);
        });
      }
    )
);

export const selectMenuSelection = (menuSelections, id) => {
  return find(menuSelections, { id: Number(id) });
};
