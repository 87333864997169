import { options, handleResponse } from 'lib/homeChefApi';
import { buildIngredientAssignmentQuery } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getIngredientAssignmentsForMenuSelection = async ({
  ingredientAssignmentIds = [],
  menuId = null,
  showCompoundIngredients = false,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/ingredient_assignments/?menu_id=${menuId}&${buildIngredientAssignmentQuery(
      ingredientAssignmentIds
    )}&showCompoundIngredients=${showCompoundIngredients}`,
    options
  );
  return await handleResponse(response);
};

export default { getIngredientAssignmentsForMenuSelection };
