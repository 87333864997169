import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getFromQuery = async ({ packingFacilityId, searchQuery }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/netsuite_orders/search`,
      {
        query: searchQuery,
      }
    ),
    options
  );

  return await handleResponse(response);
};
export default {
  getFromQuery,
};
