import { alpha } from '@mui/material/styles';

import { HC_GREEN, ERROR_RED } from 'lib/constants';

const customChip = (color) => ({
  color,
  borderRadius: '2px',
  background: alpha(color, 0.1),
});

export default {
  pallet: {
    borderBottom: '0.5px solid #D8DADC',
    padding: '15px',
    marginBottom: '1px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  selected: {
    backgroundColor: '#F4F8FD',
  },
  orderName: {
    fontWeight: 400,
    fontSize: '18px',
    marginTop: '5px',
  },
  bolded: {
    fontWeight: 600,
  },
  greyedOut: {
    color: 'grey',
  },
  primaryChip: customChip(HC_GREEN),
  secondaryChip: customChip(ERROR_RED),
};
