import React from 'react';

import { POSITIVE_GREEN, POD_GREY, NEGATIVE_RED } from 'lib/constants';

const width = 100;

const RealtimeLegendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 72"
      width={`${width}px`}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M1,4V68a3,3,0,0,0,3,3H56V1H4A3,3,0,0,0,1,4Z" fill="none" />
          <path
            d="M67.5,34.17h1.33v2.67H67.5Zm0,4.67h1.33V37.51H67.5ZM80,4V68a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H76A4,4,0,0,1,80,4ZM56,1H4A3,3,0,0,0,1,4V68a3,3,0,0,0,3,3H56ZM74.17,59.17a6,6,0,0,0-1.32-3.74l1-1a7.59,7.59,0,0,0-.94-.94l-1,1a6,6,0,0,0-9.74,4.68,6,6,0,0,0,12,0Zm-4-6.67V51.17h-4V52.5Zm4-11.66-6-12-6,12Zm0-27.67a6.5,6.5,0,0,0-12,0,6.5,6.5,0,0,0,12,0Zm-3.28,0a2.73,2.73,0,1,1-2.72-2.67A2.69,2.69,0,0,1,70.89,13.17Zm-1.09,0a1.64,1.64,0,1,0-1.63,1.6A1.61,1.61,0,0,0,69.8,13.17Zm3,46a4.67,4.67,0,1,1-4.66-4.67A4.65,4.65,0,0,1,72.83,59.17Zm-4-3.34H67.5v4h1.33Z"
            fill="#494c4e"
          />
          <rect
            x="5"
            y="5"
            width="47"
            height="18"
            rx="4"
            ry="4"
            fill={POD_GREY}
          />
          <rect
            x="5"
            y="27"
            width="47"
            height="18"
            rx="4"
            ry="4"
            fill={POSITIVE_GREEN}
          />
          <rect
            x="5"
            y="49"
            width="47"
            height="18"
            rx="4"
            ry="4"
            fill={NEGATIVE_RED}
          />
        </g>
      </g>
    </svg>
  );
};

export default RealtimeLegendIcon;
