import React, { Fragment, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import withStyles from '@mui/styles/withStyles';

import { PhotoTaker, Snackbar, Loading, PhotoGallery } from 'components/shared';

import styles from './styles';

const snackbarPostMessaging = {
  loading: 'Saving Photo...',
  failure: 'Save Photo Failed',
  success: 'Save Photo Success!',
};

const snackbarDeleteMessaging = {
  loading: 'Deleting Photo...',
  failure: 'Delete Photo Failed',
  success: 'Delete Photo Success!',
};

const PhotoViewerTaker = ({
  classes,
  postingPhoto,
  postingPhotoFailed,
  deletingPhoto,
  deletingPhotoFailed,
  fetchingPhotos,
  onPostPhoto,
  className,
  photos,
  onDeletePhoto,
  onFetchPhotos,
}) => {
  const [cameraModalOpen, setCameraModalOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [backupImage, setBackupImage] = useState(null);
  const [galleryModalOpen, setGalleryModalOpen] = useState(false);

  useEffect(() => {
    if (photos.length < 1) {
      setThumbnail(null);
    } else if (last(photos).processing) {
      setThumbnail(backupImage);
    } else {
      setThumbnail(last(photos).thumbnail);
    }
  }, [photos, setThumbnail, backupImage]);

  useEffect(() => {
    if (galleryModalOpen && photos.length < 1) {
      setGalleryModalOpen(false);
    }
  }, [galleryModalOpen, setGalleryModalOpen, photos]);

  const onSubmitPhoto = useCallback(
    (photo) => {
      setCameraModalOpen(false);
      onPostPhoto(photo);
      setBackupImage(photo);
    },
    [onPostPhoto, setCameraModalOpen, setBackupImage]
  );
  const onGalleryClick = useCallback(() => {
    if (photos.length > 0) {
      setGalleryModalOpen(true);
    }
  }, [photos, setGalleryModalOpen]);
  const onGalleryModalClose = useCallback(() => {
    onFetchPhotos();
    setGalleryModalOpen(false);
  }, [onFetchPhotos, setGalleryModalOpen]);

  return (
    <Fragment>
      <div className={classNames(className, classes.container)}>
        {fetchingPhotos || postingPhoto ? (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        ) : (
          <Button
            className={classNames(classes.picturesButton, {
              [classes.buttonWithPicture]: photos.length > 0,
            })}
            variant="outlined"
            color="neutral"
            data-testid="photoGalleryButton"
            onClick={onGalleryClick}
          >
            <div
              className={classNames({
                [classes.buttonThumbnailText]: photos.length > 0,
              })}
            >
              {photos.length}
            </div>

            {thumbnail && (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                className={classes.thumbnail}
                src={thumbnail}
                onError={() => setThumbnail(backupImage)}
              />
            )}
          </Button>
        )}
        <Button
          className={classes.cameraButton}
          variant="outlined"
          color="neutral"
          onClick={() => setCameraModalOpen(true)}
        >
          <CameraIcon fontSize="large" className={classes.cameraIcon} />
          Take a Photo
        </Button>
      </div>
      <Dialog open={cameraModalOpen} onClose={() => setCameraModalOpen(false)}>
        <PhotoTaker onSubmit={onSubmitPhoto} />
      </Dialog>
      <Dialog
        open={galleryModalOpen}
        data-testid="photoGalleryModal"
        onClose={onGalleryModalClose}
        classes={{ paper: classes.gallery }}
      >
        <PhotoGallery onDelete={onDeletePhoto} photos={photos} />
      </Dialog>
      <Snackbar
        loading={postingPhoto}
        failed={postingPhotoFailed}
        messaging={snackbarPostMessaging}
      />
      <Snackbar
        loading={deletingPhoto}
        failed={deletingPhotoFailed}
        messaging={snackbarDeleteMessaging}
      />
    </Fragment>
  );
};

const photoShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
});

PhotoViewerTaker.propTypes = {
  classes: PropTypes.object.isRequired,
  onPostPhoto: PropTypes.func.isRequired,
  postingPhoto: PropTypes.bool.isRequired,
  postingPhotoFailed: PropTypes.bool.isRequired,
  deletingPhoto: PropTypes.bool.isRequired,
  deletingPhotoFailed: PropTypes.bool.isRequired,
  fetchingPhotos: PropTypes.bool.isRequired,
  className: PropTypes.string,
  photos: PropTypes.arrayOf(photoShape).isRequired,
  onDeletePhoto: PropTypes.func.isRequired,
  onFetchPhotos: PropTypes.func.isRequired,
};

PhotoViewerTaker.defaultProps = {
  className: '',
};

export default withStyles(styles)(PhotoViewerTaker);
