import { makeApiRequest } from 'lib/api';
import { PALLET_HISTORIES_GET, PALLET_HISTORIES_RESET } from 'lib/constants';

import PalletHistories from './services';

export const fetchPalletHistories = (palletId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: PALLET_HISTORIES_GET,
        method: PalletHistories.getPalletHistories,
        params: { palletId },
      })
    );
  };
};

export const resetPalletHistories = () => ({
  type: PALLET_HISTORIES_RESET,
});
