import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Route as ReactRouterDOMRoute } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';

import useProductionLocations from 'lib/custom_hooks/useProductionLocation';
import { HOME_BUTTON, ITEM_MANAGER, ACCOUNT_DROPDOWN } from 'lib/constants';
import { setTopBarContent } from 'redux/topBar/actions';

import {
  ITEM,
  REQUESTED_MEALS,
  DROPPED_OFF,
  QUANTITY,
  ACTIONS,
  PALLET_ID,
  CHECKED_IN,
  TIME_ZONE,
  AUDIT_ACTION,
  MOVE_ACTION,
  RETURN_ACTION,
  PRINT_ACTION,
} from './ItemManagerTable/dataTableConfig';
import LocationSelectors from './LocationSelectors';
import ItemManagerTable from './ItemManagerTable';
import ChangeoverButton from './ChangeoverButton';
import styles from './styles';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const DROP_ZONE_VIEW_ACTIONS = [AUDIT_ACTION, MOVE_ACTION, PRINT_ACTION];

const DROP_ZONE_VIEW_COLUMNS = [
  PALLET_ID,
  ITEM,
  REQUESTED_MEALS,
  DROPPED_OFF,
  QUANTITY,
  ACTIONS,
  TIME_ZONE,
];

const POD_VIEW_COLUMNS = [ITEM, CHECKED_IN, ACTIONS, TIME_ZONE];
const POD_VIEW_ACTIONS = [RETURN_ACTION];

const useStyles = makeStyles(styles);

const ItemManager = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { dropzones, pods } = useProductionLocations();

  useEffect(() => {
    dispatch(
      setTopBarContent({
        leftContent: HOME_BUTTON,
        middleContent: ITEM_MANAGER,
        rightContent: ACCOUNT_DROPDOWN,
      })
    );
  }, []);

  return (
    <>
      <Route
        path={['/item-manager/dropzones']}
        render={() => <LocationSelectors locations={dropzones} />}
      />
      <Route
        path={['/item-manager/pods']}
        render={() => <LocationSelectors locations={pods} />}
      />
      <Route
        path={['/item-manager/dropzones/:locationId']}
        render={() => (
          <ItemManagerTable
            columns={DROP_ZONE_VIEW_COLUMNS}
            actions={DROP_ZONE_VIEW_ACTIONS}
          />
        )}
      />
      <Route
        path={['/item-manager/pods/:locationId']}
        render={() => (
          <>
            <div className={classes.changeoverButtonContainer}>
              <ChangeoverButton />
            </div>
            <ItemManagerTable
              columns={POD_VIEW_COLUMNS}
              actions={POD_VIEW_ACTIONS}
            />
          </>
        )}
      />
    </>
  );
};

export default ItemManager;
