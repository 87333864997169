import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import classNames from 'classnames';

import { useBins, useDebounce } from 'lib/custom_hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const renderBinOption = (props, bin, selectedBins, classes) => {
  const isSelected = selectedBins.some(
    (selectedBin) => selectedBin.id === bin.id
  );
  const isDisabled = bin.status === 'deactivated';

  return (
    <li
      {...props}
      key={bin.id}
      className={classNames(classes.binOption, {
        [classes.disabledOption]: isDisabled,
        [classes.selectedOption]: isSelected,
      })}
      style={{
        // Prevent interaction with disabled options
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Typography
            className={
              isSelected ? classes.selectedText : classes.binOptionTitle
            }
          >
            {`Bin ${bin.id}`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.binOptionIngredient}>
            {bin.ingredientName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.deactivatedText}>
            {isDisabled && `(${bin.statusReason})`}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};

const AssemblyBinSearch = ({ selectedBins, setSelectedBins }) => {
  const classes = useStyles();
  const { bins, fetching, onSearch, resetBins } = useBins();
  const [searchTerm, setSearchTerm] = useState('');

  const delayedSearch = useDebounce(
    (searchValue) => onSearch(searchValue),
    500
  );

  useEffect(() => {
    searchTerm && delayedSearch(searchTerm);
  }, [searchTerm]);

  const resetAutoCompleteInput = () => {
    setSearchTerm('');
    resetBins();
  };

  // tweaked to handle for edge case when input text is cleared
  const handleInputChange = (_event, value, reason) => {
    if (reason === 'input') {
      setSearchTerm(value);
      if (value === '') resetBins();
    } else if (reason === 'clear') resetAutoCompleteInput();
  };

  const handleBinSelect = (_event, value) => {
    if (
      value &&
      !selectedBins.some((bin) => bin.id === value.id) &&
      value.status !== 'deactivated'
    ) {
      setSelectedBins((prev) => [...prev, { ...value, depleted: false }]);
    }
    resetAutoCompleteInput();
  };

  const handleRemoveBin = (binId) => {
    setSelectedBins((prev) => prev.filter((bin) => bin.id !== binId));
  };

  const handleDepletedToggle = (binId) => {
    setSelectedBins((prev) =>
      prev.map((bin) =>
        bin.id === binId ? { ...bin, depleted: !bin.depleted } : bin
      )
    );
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 500 }}>
      <Typography className={classes.formLabelText}>
        Which ingredient bins are you using?
      </Typography>
      <List>
        {selectedBins.map((bin) => (
          <ListItem key={bin.id} className={classes.listItem}>
            <Typography
              className={classes.binText}
            >{`Bin ${bin.id} - ${bin.ingredientName}`}</Typography>
            <div className={classes.depletedSection}>
              <Typography className={classes.depletedLabel}>
                Depleted?
              </Typography>
              <Checkbox
                checked={bin.depleted}
                className={classes.checkbox}
                inputProps={{ 'aria-label': 'Depleted checkbox' }}
                onChange={() => handleDepletedToggle(bin.id)}
              />
              <IconButton
                aria-label="delete"
                className={classes.deleteIcon}
                edge="end"
                onClick={() => handleRemoveBin(bin.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </ListItem>
        ))}
      </List>
      <Autocomplete
        freeSolo
        inputValue={searchTerm}
        onInputChange={handleInputChange}
        id="bin-search"
        loading={fetching}
        options={bins}
        onChange={handleBinSelect}
        getOptionDisabled={(bin) => bin.status === 'deactivated'}
        getOptionLabel={(bin) => `Bin ${bin.id} - ${bin.ingredientName}`}
        filterOptions={(x) => x}
        renderOption={(props, bin) =>
          renderBinOption(props, bin, selectedBins, classes)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Enter a bin ID to search"
            autoComplete="off"
          />
        )}
        data-testid="assembly-bin-list-input"
      />
    </Box>
  );
};

AssemblyBinSearch.propTypes = {
  selectedBins: PropTypes.array.isRequired,
  setSelectedBins: PropTypes.func.isRequired,
};

export default AssemblyBinSearch;
