import { GREY_200, WHITE, HC_GREEN, DARK_HC_GREEN } from 'lib/constants';

const buttonDisabled = {
  backgroundColor: GREY_200,
};

export default {
  halfButton: {
    padding: '1em',
    cursor: 'pointer',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '1em',
    boxSizing: 'border-box',
    background: WHITE,
    border: `1px solid ${GREY_200}`,
    width: '45%',
  },
  cancel: {
    border: `1px solid ${GREY_200}`,
    background: WHITE,
  },
  buttonDisabled,
  submit: {
    border: 'none',
    background: HC_GREEN,
    color: WHITE,
    '&$buttonDisabled': buttonDisabled,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '12px',
  },
};
