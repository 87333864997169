import { DIVIDER_GREY, ERROR_DARK, ROBOTO } from 'lib/constants';

import { formSubmitButton } from '../../styles';

export default {
  addBinButton: {
    ...formSubmitButton,
    alignSelf: 'flex-start',
    marginLeft: '0',
  },
  addIcon: {
    marginRight: '8px',
  },
  binFieldContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '10px',
  },
  binInput: {
    flexGrow: 1,
    minHeight: '80px',
  },
  binLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '22px',
  },
  binRow: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '8px',
  },
  cancelButton: {
    color: ERROR_DARK,
    fontFamily: ROBOTO,
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.46px',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  formBody: {
    marginTop: '8px',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  formDivider: {
    height: `1px solid ${DIVIDER_GREY}`,
  },
  formHeader: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formHeaderText: {
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '160%',
  },
  formSubmitButton: {
    ...formSubmitButton,
    marginRight: '0',
  },
  trashIcon: {
    fontSize: '20px',
    marginBottom: '22px',
    marginLeft: '0px',
  },
};
