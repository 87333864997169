import { drawerStyling } from 'lib/styles';
import { HC_GREEN, WHITE, GREY_200, DARK_HC_GREEN } from 'lib/constants';

const button = {
  height: 50,
  fontSize: 16,
  textTransform: 'none',
  marginTop: 20,
};

const buttonDisabled = {
  backgroundColor: GREY_200,
};

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  ...drawerStyling,
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  infoLabel: {
    fontSize: 14,
  },
  infoValue: {
    fontSize: 20,
    margin: '5px 0px 20px',
    marginInlineStart: 0,
  },
  icon: {
    width: 20,
    height: 20,
    margin: '0 auto',
  },
  noScroll: {
    overflowY: 'hidden',
  },
  timePicker: {
    marginLeft: 5,
    marginRight: 5,
    width: 180,
  },
  buttonDisabled,
  submitButton: {
    ...button,
    borderRadius: '4px 0px 0px 4px',
    '&$buttonDisabled': buttonDisabled,
    color: WHITE,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
};
