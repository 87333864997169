import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import PalletSearch from 'components/WarehouseInventory/PalletSearch';

import AccountSwitch from '../AccountSwitch';
import styles from './styles';

const InventoryManagerActions = ({ classes }) => {
  return (
    <li className={classes.root}>
      <PalletSearch link="/inventory-manager/pallets" iconHeight="40px" />
      <AccountSwitch />
    </li>
  );
};

InventoryManagerActions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InventoryManagerActions);
