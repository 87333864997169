import React, { Fragment } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import { APPLICATOR, BY_HAND } from 'lib/constants';

import styles from './styles';

const LabelingMethodQuestion = ({
  labelingMethod,
  setLabelingMethod,
  classes,
}) => {
  return (
    <Fragment>
      <h3>Labeling Method</h3>
      <ToggleButtonGroup
        exclusive
        className={classes.buttonGroup}
        size="large"
        value={labelingMethod}
        onChange={(_, newLabelingMethod) =>
          setLabelingMethod(newLabelingMethod)
        }
      >
        {[
          { description: 'Applicator', value: APPLICATOR },
          { description: 'By Hand', value: BY_HAND },
        ].map((method) => {
          return (
            <ToggleButton
              className={classes.button}
              key={method.description}
              value={method.value}
            >
              {method.description}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Fragment>
  );
};

LabelingMethodQuestion.propTypes = {
  labelingMethod: PropTypes.string,
  setLabelingMethod: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

LabelingMethodQuestion.defaultProps = {
  labelingMethod: undefined,
};

export default withStyles(styles)(LabelingMethodQuestion);
