import { makeApiRequest } from 'lib/api';
import {
  INGREDIENTS,
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT,
  CLEAR_INGREDIENT_ASSIGNMENT,
} from 'lib/constants';

import Ingredients from './services';

export const getIngredients = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INGREDIENTS,
        method: Ingredients.getIngredients,
      })
    );
  };
};

export const getProductionProgressForIngredient = (
  packingFacilityId,
  menuId,
  activity,
  ingredientId
) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT,
        method: Ingredients.getProductionProgressForIngredient,
        params: {
          packingFacilityId,
          menuId,
          activity,
          ingredientId,
        },
      })
    );
  };
};

export const getProductionProgressForCustomizeIt = ({
  shipWeek,
  ingredientAssignmentId,
}) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT,
        method: Ingredients.getProductionProgressForCustomizeIt,
        params: {
          shipWeek,
          ingredientAssignmentId,
        },
      })
    );
  };
};

export const resetIngredientAssignment = () => {
  return {
    type: CLEAR_INGREDIENT_ASSIGNMENT,
  };
};
