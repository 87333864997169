import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ButtonBase from '@mui/material/ButtonBase';

import { QUANTITY_INCREASE_OPTIONS, WASTE_CORRECTION } from 'lib/constants';
import { Chicken } from 'components/icons';
import QtyIncreaseModal from 'components/WarehouseMoves/ViewSearchPallet/QuantityModals/QtyIncreaseModal';

import styles from './styles';

const ReactivateButton = ({
  onPalletReactivate,
  palletId,
  classes,
  includeWasteCorrection,
}) => {
  const [animating, setAnimating] = useState(false);
  const [increaseModalOpen, setIncreaseModalOpen] = useState(false);

  const submitReactivatePallet = (statusType) => {
    setAnimating(true);
    setTimeout(function () {
      onPalletReactivate(palletId, statusType);
    }, 2500);
  };

  const onReasonTypeClick = (adjustmentType) => {
    const adjustmentOption = QUANTITY_INCREASE_OPTIONS.concat({
      type: WASTE_CORRECTION,
    }).find((opt) => opt.type === adjustmentType);

    submitReactivatePallet(adjustmentOption.type);
  };

  const openReasonModal = () => {
    setIncreaseModalOpen(true);
  };

  return (
    <Fragment>
      <ButtonBase
        className={classes.reactivateButton}
        onClick={openReasonModal}
      >
        <p className={classes.reactivateText}>Reactivate Pallet</p>
        <div className={classes.chickenWrapper}>
          <Chicken isAnimating={animating} />
        </div>
      </ButtonBase>
      <QtyIncreaseModal
        increaseModalOpen={increaseModalOpen}
        onQuantityReasonCancel={() => setIncreaseModalOpen(false)}
        onReasonTypeClick={onReasonTypeClick}
        includeWasteCorrection={includeWasteCorrection}
      />
    </Fragment>
  );
};

ReactivateButton.propTypes = {
  onPalletReactivate: PropTypes.func.isRequired,
  palletId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  includeWasteCorrection: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ReactivateButton);
