export default {
  scanContainer: {
    paddingTop: '24px',
  },
  scanText: {
    paddingTop: '24px',
  },
  errorText: {
    color: 'red',
  },
};
