import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

export const getIngredientsTrackerProgress = async (menuId) => {
  const endpoint = `${API_BASE_URI}/ops/rth_progress_tracker/menus/${menuId}/ingredients`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};

export const getIngredientsForSlackingTrackerProgress = async (menuId) => {
  const endpoint = `${API_BASE_URI}/ops/rth_progress_tracker/menus/${menuId}/slacking`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};
