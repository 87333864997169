import { makeApiRequest } from 'lib/api';
import {
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET,
  WAREHOUSE_AUDIT_TOGGLE_EXPAND_ORDER,
  WAREHOUSE_AUDIT_TOGGLE_EXPAND_ITEM,
  WAREHOUSE_AUDIT_EXPAND_ALL,
  WAREHOUSE_AUDIT_COLLAPSE_ALL,
} from 'lib/constants';

import NetsuiteOrders from './services';

export const getNetsuiteOrders = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: SUMMARY_AUDIT_ORDERS_TRUCKS_GET,
        method: NetsuiteOrders.getNetsuiteOrders,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};

export const handleOrderToggle = (netsuiteOrderId) => ({
  type: WAREHOUSE_AUDIT_TOGGLE_EXPAND_ORDER,
  netsuiteOrderId,
});

export const handleItemToggle = (netsuiteOrderId, netsuiteItemId) => ({
  type: WAREHOUSE_AUDIT_TOGGLE_EXPAND_ITEM,
  netsuiteOrderId,
  netsuiteItemId,
});

export const handleExpandAll = () => ({
  type: WAREHOUSE_AUDIT_EXPAND_ALL,
});

export const handleCollapseAll = () => ({
  type: WAREHOUSE_AUDIT_COLLAPSE_ALL,
});
