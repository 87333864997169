import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { FormatNumberCell } from 'components/shared';
import { NO_PORTION_METHOD } from 'lib/constants';

const isTrueCheckBox = (isTrue) => {
  if (isTrue) {
    return <CheckBoxIcon />;
  }
};

const portioningWIP = (
  portioningGoalCount,
  portionRemainCount,
  goalCount,
  remainingCount
) => {
  return (
    portioningGoalCount - portionRemainCount - (goalCount - remainingCount)
  );
};

const ingredientIdColumn = {
  name: 'ingredientId',
  label: 'INGREDIENT ID',
};
const brandColumn = { name: 'brand', label: 'BRAND' };
const highToleranceColumn = { name: 'highTolerance' };
const unitOfMeasureColumn = { name: 'unitOfMeasure' };
const labelRequiredColumn = { name: 'labelRequired' };
const labelingGoalCountColumn = { name: 'labelingGoalCount' };
const portioningRemainingCountColumn = {
  name: 'portioningRemainingCount',
};
const portionMethodColumn = {
  name: 'portionMethod',
  label: 'PRTN MTHD',
};
const goalCountColumn = { name: 'goalCount' };
const remainingCountColumn = { name: 'remainingCount' };

const excludedColumnOptions = {
  options: {
    display: 'excluded',
    filter: false,
  },
};

let ingredientIdIndex,
  brandIndex,
  unitOfMeasureIndex,
  highToleranceIndex,
  labelRequiredIndex,
  labelingGoalCountIndex,
  portioningRemainingCountIndex,
  portionMethodIndex,
  goalCountIndex,
  remainingCountIndex;

export const columns = [
  {
    name: 'name',
    label: 'INGREDIENT',
    options: {
      customBodyRender: (name, tableMeta) => {
        const brand = tableMeta.rowData[brandIndex];
        const ingredientId = tableMeta.rowData[ingredientIdIndex];
        return `${name} - ${ingredientId} - ${brand}`;
      },
    },
  },
  {
    name: 'quantity',
    label: 'QTY/UOM',
    options: {
      customBodyRender: (quantity, tableMeta) => {
        const unitOfMeasure = tableMeta.rowData[unitOfMeasureIndex];
        return `${quantity} / ${unitOfMeasure}`;
      },
    },
  },

  {
    name: 'lowTolerance',
    label: 'TLRNC',
    options: {
      filter: false,
      customBodyRender: (lowTolerance, tableMeta) => {
        const highTolerance = tableMeta.rowData[highToleranceIndex];
        const unitOfMeasure = tableMeta.rowData[unitOfMeasureIndex];
        return `${lowTolerance} - ${highTolerance} ${unitOfMeasure}`;
      },
    },
  },
  {
    name: 'packagingType',
    label: 'PKG TYPE',
  },
  {
    name: 'labelRequired',
    label: 'LABEL',
    options: {
      customBodyRender: (labelRequired) => {
        return isTrueCheckBox(labelRequired);
      },
    },
  },
  {
    name: 'labelingRemainingCount',
    label: '# LBLS DONE',
    options: {
      customBodyRender: (labelingRemainingCount, tableMeta) => {
        const labelRequired = tableMeta.rowData[labelRequiredIndex];
        const labelGoalCount = tableMeta.rowData[labelingGoalCountIndex];
        if (labelRequired) {
          return (
            <FormatNumberCell
              value={labelGoalCount - labelingRemainingCount}
              addCommas
            />
          );
        }
      },
    },
  },
  {
    name: 'portioningGoalCount',
    label: 'PRTNS ON-HAND',
    options: {
      filter: false,
      customBodyRender: (portioningGoalCount, tableMeta) => {
        const portionMethod = tableMeta.rowData[portionMethodIndex];
        if (portionMethod !== NO_PORTION_METHOD) {
          const portionRemainCount =
            tableMeta.rowData[portioningRemainingCountIndex];
          const goalCount = tableMeta.rowData[goalCountIndex];
          const remainingCount = tableMeta.rowData[remainingCountIndex];

          return (
            <FormatNumberCell
              value={portioningWIP(
                portioningGoalCount,
                portionRemainCount,
                goalCount,
                remainingCount
              )}
              addCommas
            />
          );
        }
      },
    },
  },
  {
    ...portionMethodColumn,
  },
  {
    name: 'readyToEat',
    label: 'RTE',
    options: {
      display: 'false',
      customBodyRender: (readyToEat) => {
        return isTrueCheckBox(readyToEat);
      },
    },
  },
  {
    ...brandColumn,
    options: {
      display: excludedColumnOptions.display,
      filter: true,
    },
  },
  {
    ...ingredientIdColumn,
    ...excludedColumnOptions,
  },
  {
    ...labelingGoalCountColumn,
    ...excludedColumnOptions,
  },
  {
    ...portioningRemainingCountColumn,
    ...excludedColumnOptions,
  },
  {
    ...goalCountColumn,
    ...excludedColumnOptions,
  },
  {
    ...remainingCountColumn,
    ...excludedColumnOptions,
  },
  {
    ...highToleranceColumn,
    ...excludedColumnOptions,
  },
  {
    ...unitOfMeasureColumn,
    ...excludedColumnOptions,
  },
];

ingredientIdIndex = columns.findIndex(
  (column) => column.name === ingredientIdColumn.name
);
brandIndex = columns.findIndex((column) => column.name === brandColumn.name);
unitOfMeasureIndex = columns.findIndex(
  (column) => column.name === unitOfMeasureColumn.name
);
highToleranceIndex = columns.findIndex(
  (column) => column.name === highToleranceColumn.name
);
labelRequiredIndex = columns.findIndex(
  (column) => column.name === labelRequiredColumn.name
);
labelingGoalCountIndex = columns.findIndex(
  (column) => column.name === labelingGoalCountColumn.name
);
portioningRemainingCountIndex = columns.findIndex(
  (column) => column.name === portioningRemainingCountColumn.name
);
portionMethodIndex = columns.findIndex(
  (column) => column.name === portionMethodColumn.name
);
goalCountIndex = columns.findIndex(
  (column) => column.name === goalCountColumn.name
);
remainingCountIndex = columns.findIndex(
  (column) => column.name === remainingCountColumn.name
);

export const options = {
  selectableRows: 'none',
  print: false,
  responsive: 'standard',
  download: false,
};
