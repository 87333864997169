import { DROP_LIST_POST } from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import DropList from './services';

export const postDropList = (params) => async (dispatch, getState) => {
  await dispatch(
    makeApiRequest({
      type: DROP_LIST_POST,
      method: DropList.postDropList,
      params: {
        ...params,
        packingFacilityId: getState().session.packing_facility_id,
      },
    })
  );
};
