import { makeApiRequest } from 'lib/api';
import {
  ADD_TRUCK,
  AUDIT_ITEM_UPDATE,
  SUMMARY_ITEM_UPDATE,
  NETSUITE_ORDER_ITEM_STORE_SEARCH,
  NETSUITE_ORDER_ITEM_CLEAR_SEARCH,
} from 'lib/constants';

import NetsuiteItems from './services';

export const updateNetsuiteItemAudit = (itemId, status) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: AUDIT_ITEM_UPDATE,
        method: NetsuiteItems.updateNetsuiteItem,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          itemId,
          status,
        },
      })
    );
  };
};

export const updateNetsuiteItemSummary = (itemId, status, truckId = null) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: SUMMARY_ITEM_UPDATE,
        method: NetsuiteItems.updateNetsuiteItem,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          itemId,
          status,
          truckId,
        },
      })
    );
  };
};

export const addTruck = (truck) => ({
  type: ADD_TRUCK,
  truck: truck,
});

export const storeSearchQuery = (searchQuery) => {
  return {
    type: NETSUITE_ORDER_ITEM_STORE_SEARCH,
    payload: { searchQuery },
  };
};

export const clearSearchQuery = () => {
  return {
    type: NETSUITE_ORDER_ITEM_CLEAR_SEARCH,
  };
};
