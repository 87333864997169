import { makeApiRequest } from 'lib/api';
import {
  ITEM_PUTBACK_PARENT_PALLET,
  ITEM_PUTBACK_CREATE,
  ITEM_PUTBACK_EXCEPTION_CREATE,
  ITEM_PUTBACK_CANCEL,
  ITEM_PUTBACK_COMPLETE,
  ITEM_PUTBACKS,
  ITEM_PUTBACK,
  RESET_PARENT_PALLET,
  PALLETS_PRINT,
} from 'lib/constants';

import guidedPutback from './services';

export const fetchParentPallet = (childPalletId) => {
  return makeApiRequest({
    type: ITEM_PUTBACK_PARENT_PALLET,
    method: guidedPutback.getParentPallet,
    params: {
      childPalletId,
    },
  });
};

export const fetchItemPutback = (id) => {
  return makeApiRequest({
    type: ITEM_PUTBACK,
    method: guidedPutback.getItemPutback,
    params: { id },
  });
};

export const fetchItemPutbacks = () => {
  return makeApiRequest({
    type: ITEM_PUTBACKS,
    method: guidedPutback.getItemPutbacks,
  });
};

export const createItemPutback = (childPalletId, pickupLocationId = null) => {
  return makeApiRequest({
    type: ITEM_PUTBACK_CREATE,
    method: guidedPutback.createItemPutback,
    params: {
      childPalletId,
      pickupLocationId,
    },
  });
};

export const createItemPutbackException = (
  itemPutbackId,
  problemPalletId,
  reasonCode
) => {
  return makeApiRequest({
    type: ITEM_PUTBACK_EXCEPTION_CREATE,
    method: guidedPutback.createItemPutbackException,
    params: {
      itemPutbackId,
      problemPalletId,
      reasonCode,
    },
  });
};

export const completeItemPutback = (
  itemPutbackId,
  changedItemPutbackValues
) => {
  return makeApiRequest({
    type: ITEM_PUTBACK_COMPLETE,
    method: guidedPutback.completeItemPutback,
    params: { itemPutbackId, changedItemPutbackValues },
  });
};

export const cancelItemPutback = (itemPutbackId) => {
  return makeApiRequest({
    type: ITEM_PUTBACK_CANCEL,
    method: guidedPutback.cancelItemPutback,
    params: {
      itemPutbackId,
    },
  });
};

export const resetParentPallet = () => ({
  type: RESET_PARENT_PALLET,
});

export const printPallet = (palletId, printer = null, itemPutbackId) => {
  return makeApiRequest({
    type: PALLETS_PRINT,
    method: guidedPutback.printPallet,
    params: {
      palletId: palletId,
      printer,
      itemPutbackId: itemPutbackId,
    },
  });
};
