import { linkTabStyling } from 'lib/styles';
import { GREY_600, GREY_200 } from 'lib/constants';

export default {
  ...linkTabStyling,
  link: {
    ...linkTabStyling.link,
    fontSize: '14px',
    padding: '10px 10px 17px 10px',
    color: GREY_600,
    fontFamily: 'Roboto',
  },
  linkContainer: {
    ...linkTabStyling.linkContainer,
    display: 'block',
    height: '40px',
    padding: '20px 0px 0px 10px',
    paddingBottom: '0px',
    borderBottom: `2px solid ${GREY_200}`,
  },
  breadcrumbContainer: { padding: '12px' },
};
