import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import styles from './styles';

const AllergensQuestion = ({
  classes,
  groupId,
  groupAllergens,
  selectedAllergens,
  updateSelectedAllergens,
}) => {
  // categoryId here is the parent allergenId the specific allergen belongs to
  const selectedAllergenIds = useMemo(
    () => selectedAllergens.map((allergen) => allergen.categoryId),
    [selectedAllergens]
  );

  const onAllergenClick = (allergen) => {
    let updatedAllergenList;

    if (selectedAllergenIds.includes(allergen.categoryId)) {
      updatedAllergenList = selectedAllergens.filter(
        (ca) => ca.categoryId !== allergen.categoryId
      );
    } else {
      updatedAllergenList = selectedAllergens.concat(allergen);
    }
    updateSelectedAllergens(updatedAllergenList);
  };

  return (
    <Fragment>
      <div className={classes.infoValue}>
        <div className={classes.selectAllergens}>
          <p>
            {groupAllergens.length > 1
              ? `SELECT ${groupId.toUpperCase()} THAT ARE PRESENT`
              : `SELECT ${groupId.toUpperCase()} IF IT IS PRESENT`}
          </p>
        </div>
        <div className={classes.allergenOptionsContainer}>
          {groupAllergens.map((allergen) => (
            <Button
              className={classNames(classes.allergenButton, {
                [classes.selected]: selectedAllergenIds.includes(
                  allergen.categoryId
                ),
              })}
              onClick={() => onAllergenClick(allergen)}
              key={allergen.categoryId}
              variant="outlined"
              color="neutral"
            >
              {allergen.category}
            </Button>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

AllergensQuestion.propTypes = {
  groupId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  groupAllergens: PropTypes.arrayOf(PropTypes.object),
  selectedAllergens: PropTypes.arrayOf(PropTypes.object),
  updateSelectedAllergens: PropTypes.func.isRequired,
};

AllergensQuestion.defaultProps = {
  groupAllergens: [],
  selectedAllergens: [],
};

export default withStyles(styles)(AllergensQuestion);
