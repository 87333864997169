import React, { Fragment, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import find from 'lodash/find';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import { useLocation } from 'react-router-dom';

import { Loading } from 'components/shared';
import { sortByLabelOrder } from 'lib/viewHelpers';
import Search from 'components/shared/Search';
import { NO_PORTION_METHOD } from 'lib/constants';
import { CUSTOMIZE_IT_INGREDIENT_TYPE, MEAL_TYPE } from 'redux/meal/reducers';

import styles from './styles';
import IngredientItem from '../IngredientItem';
import MealItem from '../MealItem';

const BAGGING_DISPLAY_DAYS = ['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU'];

export const daySplits = (meals) =>
  meals.meals.map((meal) => {
    return meal.day_split_goals;
  });

export const weeklySplit = (meals, type) => {
  return BAGGING_DISPLAY_DAYS.map((day) => {
    const totalsToCompleteForDay = flatten(daySplits(meals))
      .filter((daySplit) => daySplit.day === day)
      .map((daySplit) => daySplit[type]);
    return sum(totalsToCompleteForDay).toLocaleString();
  });
};

export const weeklyTotal = (meals, type) =>
  sum(meals.meals.map((meal) => meal[type])).toLocaleString();
export const mealBagTotals = (meals) =>
  weeklySplit(meals, 'total_to_complete_for_day');
export const mealBagsLeftTotals = (meals) =>
  weeklySplit(meals, 'num_left_to_complete');

export const labelingLeft = (meals) =>
  weeklyTotal(meals, 'remainingLabelingCount');
export const labelingGoal = (meals) => weeklyTotal(meals, 'labelingGoalCount');

export const portioningLeft = (meals) =>
  weeklyTotal(meals, 'remainingPortioningCount');
export const portioningGoal = (meals) =>
  weeklyTotal(meals, 'portioningGoalCount');

const MenuProgress = ({ meals, onToggleClick, classes }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const isLabelingPath = location.pathname.includes('labeling');
  const isPortioningPath = location.pathname.includes('portioning');
  const isMealBaggingPath = location.pathname.includes('bagging');
  const isMealOverviewPath = location.pathname.includes('overview');

  useEffect(() => {
    if (isMealBaggingPath || isMealOverviewPath) {
      setSearchTerm('');
    }
  }, []);

  const mealsFilteredByIngredients = useMemo(() => {
    const mealsArray = meals.meals;

    if (!mealsArray) {
      return {};
    }

    return searchTerm.length > 0
      ? filter(mealsArray, (meal) => {
          const matchedValues = meal.ingredient_assignments.find((ia) => {
            if (isLabelingPath) {
              return (
                ia.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                ia.labelRequired
              );
            } else if (isPortioningPath) {
              return (
                ia.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                ia.method !== NO_PORTION_METHOD
              );
            }
          });

          if (matchedValues) {
            return meal;
          }
        })
      : mealsArray;
  }, [meals, searchTerm]);

  const ingredientsFiltered = useMemo(() => {
    const ingredientsArray = meals.customizeItIngredients;

    if (!ingredientsArray) {
      return {};
    }

    return searchTerm.length > 0
      ? filter(ingredientsArray, (ingredient) => {
          return ingredient.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        })
      : ingredientsArray;
  }, [meals, searchTerm]);

  const isSearchPage = isPortioningPath || isLabelingPath;

  const searchBar = () => {
    if (isSearchPage) {
      return (
        <div className={classes.search}>
          <Search onSearch={(term) => setSearchTerm(term)} />
        </div>
      );
    }
  };

  const pageMeals = () => {
    const viewMeals = isSearchPage ? mealsFilteredByIngredients : meals.meals;
    return sortByLabelOrder(viewMeals).map((meal) => (
      <MealItem
        key={meal.id}
        expanded={Boolean(
          find(meals.expandedPanels, { type: MEAL_TYPE, id: meal.id })
        )}
        toggleAction={onToggleClick}
        {...meal}
      />
    ));
  };

  const pageIngredients = () => {
    const viewIngredients = isSearchPage
      ? ingredientsFiltered
      : meals.customizeItIngredients;

    return viewIngredients.map((ingredient) => (
      <IngredientItem
        key={ingredient.id}
        expanded={Boolean(
          find(meals.expandedPanels, {
            type: CUSTOMIZE_IT_INGREDIENT_TYPE,
            id: ingredient.id,
          })
        )}
        toggleAction={onToggleClick}
        {...ingredient}
      />
    ));
  };

  const mealsForTotals = () => {
    if (mealsFilteredByIngredients) {
      return {
        meals: mealsFilteredByIngredients,
      };
    } else {
      return {
        meals,
      };
    }
  };

  if (meals.fetching) {
    return <Loading type="linear" />;
  } else {
    const filteredMeals = mealsForTotals();

    return (
      <Fragment>
        {searchBar()}
        {pageMeals()}
        {pageIngredients()}
        <Table>
          <TableHead>
            <TableRow>
              {isMealBaggingPath &&
                BAGGING_DISPLAY_DAYS.map((dayTotal, index) => (
                  <Fragment key={BAGGING_DISPLAY_DAYS[index]}>
                    <TableCell className={classes.tableHeaderCell} align="left">
                      {' '}
                      {BAGGING_DISPLAY_DAYS[index]}
                    </TableCell>
                  </Fragment>
                ))}
              {isLabelingPath && (
                <Fragment>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    {' '}
                    Weekly
                  </TableCell>
                </Fragment>
              )}
              {isPortioningPath && (
                <Fragment>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    {' '}
                    Weekly
                  </TableCell>
                </Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {isMealBaggingPath &&
                mealBagsLeftTotals(meals).map((dayTotal, index) => (
                  <Fragment key={BAGGING_DISPLAY_DAYS[index]}>
                    <TableCell align="left">
                      {' '}
                      <span className={classes.tableCellDesc}>#LEFT: </span>
                      <span className={classes.tableCellData}>{dayTotal}</span>
                    </TableCell>
                  </Fragment>
                ))}
              {isLabelingPath && (
                <Fragment>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    {' '}
                    <span className={classes.tableCellDesc}>#LEFT: </span>
                    <span className={classes.tableCellData}>
                      {labelingLeft(filteredMeals)}
                    </span>
                  </TableCell>
                </Fragment>
              )}
              {isPortioningPath && (
                <Fragment>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    {' '}
                    <span className={classes.tableCellDesc}>#LEFT: </span>
                    <span className={classes.tableCellData}>
                      {portioningLeft(filteredMeals)}
                    </span>
                  </TableCell>
                </Fragment>
              )}
            </TableRow>
            <TableRow>
              {isMealBaggingPath &&
                mealBagTotals(meals).map((dayTotal, index) => (
                  <Fragment key={BAGGING_DISPLAY_DAYS[index]}>
                    <TableCell align="left">
                      {' '}
                      <span className={classes.tableCellDesc}>TOTAL: </span>
                      <span className={classes.tableCellData}>{dayTotal}</span>
                    </TableCell>
                  </Fragment>
                ))}
              {isLabelingPath && (
                <Fragment>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    {' '}
                    <span className={classes.tableCellDesc}>TOTAL: </span>
                    <span className={classes.tableCellData}>
                      {labelingGoal(filteredMeals)}
                    </span>
                  </TableCell>
                </Fragment>
              )}
              {isPortioningPath && (
                <Fragment>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    {' '}
                    <span className={classes.tableCellDesc}>TOTAL: </span>
                    <span className={classes.tableCellData}>
                      {portioningGoal(filteredMeals)}
                    </span>
                  </TableCell>
                </Fragment>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
};

MenuProgress.propTypes = {
  meals: PropTypes.object.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};

export { MenuProgress as UnwrappedMenuProgress };

export default withStyles(styles)(MenuProgress);
