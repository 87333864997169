import { ERROR_RED, GREY_150 } from 'lib/constants';

export default {
  root: {
    margin: '5px 0px 20px',
    marginInlineStart: 0,
  },
  textFieldDisabled: {
    backgroundColor: GREY_150,
    margin: '10px 0 0 0',
  },
  noBorder: {
    border: 'none',
  },
  textFieldWrapper: {
    display: 'inline-block',
    width: '190px',
    marginRight: '7px',
    marginTop: '20px',
    fontSize: '14px',
  },
  errorText: {
    marginTop: '5px',
    color: ERROR_RED,
  },
  textField: {
    margin: '10px 0 0 0',
  },
};
