import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const MealBaggingBanner = ({ classes, text }) => {
  return <div className={classes.banner}>{text}</div>;
};

MealBaggingBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};
export default withStyles(styles)(MealBaggingBanner);
