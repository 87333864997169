import React from 'react';
import PropTypes from 'prop-types';

import IngredientInventoryForm from './index';

const PortioningInventoryForm = ({ location }) => {
  const ingredientAssignment = location.state.ingredientAssignment;

  return (
    <IngredientInventoryForm
      ingredientAssignment={ingredientAssignment}
      isCustomizeIt={false}
    />
  );
};

PortioningInventoryForm.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PortioningInventoryForm;
