import { makeApiRequest } from 'lib/api';
import { POD_LOCATIONS } from 'lib/constants';

import PodLocations from './services';

export const requestPodLocations = (params) =>
  makeApiRequest({
    type: POD_LOCATIONS,
    method: PodLocations.getPods,
    params: params,
  });
