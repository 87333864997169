import { makeApiRequest } from 'lib/api';
import { MEAL_BAG_INGREDIENT_ASSIGNMENTS } from 'lib/constants';

import IngredientAssignments from './services';

export const getIngredientAssignmentsForMenuSelection = (
  ingredientAssignmentIds,
  menuId,
  showCompoundIngredients = false
) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEAL_BAG_INGREDIENT_ASSIGNMENTS,
        method: IngredientAssignments.getIngredientAssignmentsForMenuSelection,
        params: {
          ingredientAssignmentIds,
          menuId,
          showCompoundIngredients,
        },
      })
    );
  };
};
