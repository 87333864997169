import { BORDER_GREY, ROBOTO } from 'lib/constants';

import { tableHead, loadingSpinner } from '../styles';

export default {
  tableHead: {
    ...tableHead,
    '& .MuiTableCell-head:nth-child(1)': {
      textAlign: 'left',
      width: '50%',
    },
    '& .MuiTableCell-head:nth-child(2) ': {
      textAlign: 'left',
    },
  },
  loadingSpinner,
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
    fontFamily: ROBOTO,
    marginTop: '20px',
  },
};
