import { selectQaPalletData } from 'redux/pallets/selectors';
import { selectFsqaReview } from 'redux/wmsFsqaReview/selectors';
import { makeApiRequest } from 'lib/api';
import { printPallets } from 'redux/pallets/actions';
import { addPallets } from 'redux/pallets/qaApp/actions';
import { formatOutgoingAllergenInfo } from 'lib/redux_utils';
import {
  FSQA_ADD_TO_FORM,
  FSQA_REVIEW_POST,
  FSQA_REVIEW_PUT,
  INITIAL_BRAND,
  ADJUSTED_BRAND,
  INITIAL_PRODUCT_DATE,
  INITIAL_PRODUCT_DATE_TYPE,
  ADJUSTED_PRODUCT_DATE,
  ADJUSTED_PRODUCT_DATE_TYPE,
  FULL_CHECK,
  EXPECTED_ALLERGENS,
  ALLERGENS_LISTED,
  SELECTED_ALLERGENS,
  TRUE_ALLERGENS_CORRECT,
  INTOLERANCES_CORRECT,
  FSQA_REVIEW_ALLERGENS,
  ITEM_LABELED,
  ITEM_ALLERGENS_CORRECT,
  ITEM_NAME_CORRECT,
  TEMPS,
  WEIGHT_UOM,
  WEIGHTS,
  LOT_CODES,
  DEFECTS,
  DEFECT_IDS,
  NUMBER_DEFECTIVE,
  NUMBER_SAMPLED,
  FSQA_REVIEW_GET,
  REJECTED_QUANTITY,
  FINAL_QUANTITY,
  HAS_DEFECTS,
  FSQA_CLEAR_FORM,
  FSQA_TOGGLE_FULL_CHECK,
  PARTIAL_CHECK_COMPLETED,
} from 'lib/constants';

import WmsFsqaReview from './services';

export const fetchFsqaReview = (palletId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: FSQA_REVIEW_GET,
        method: WmsFsqaReview.getFsqaReview,
        params: { palletId },
      })
    );
  };
};

const getInfo = (palletId, state) => ({
  formData: selectFsqaReview(state, palletId),
  initialData: selectQaPalletData(state, palletId),
});

const getFullCheckInfo = (formData, initialData) => ({
  [INITIAL_BRAND]: initialData.brand,
  [ADJUSTED_BRAND]: formData[ADJUSTED_BRAND],
  [NUMBER_DEFECTIVE]: formData[HAS_DEFECTS]
    ? Number(formData[NUMBER_DEFECTIVE])
    : null,
  [NUMBER_SAMPLED]: formData[HAS_DEFECTS]
    ? Number(formData[NUMBER_SAMPLED])
    : null,
  [FSQA_REVIEW_ALLERGENS]: formatOutgoingAllergenInfo(
    formData[EXPECTED_ALLERGENS],
    formData[SELECTED_ALLERGENS],
    formData[TRUE_ALLERGENS_CORRECT] && formData[INTOLERANCES_CORRECT]
  ),
  [ALLERGENS_LISTED]:
    formData[TRUE_ALLERGENS_CORRECT] && formData[INTOLERANCES_CORRECT],
  [ITEM_LABELED]: formData[ITEM_LABELED],
  [ITEM_ALLERGENS_CORRECT]: formData[ITEM_ALLERGENS_CORRECT],
  [ITEM_NAME_CORRECT]: formData[ITEM_NAME_CORRECT],
  [TEMPS]: formData[TEMPS],
  [WEIGHT_UOM]: formData[WEIGHT_UOM],
  [WEIGHTS]: formData[WEIGHTS],
  [DEFECT_IDS]: formData[DEFECTS].map((defect) => defect.id),
  [REJECTED_QUANTITY]: formData[REJECTED_QUANTITY],
  [FINAL_QUANTITY]: formData[FINAL_QUANTITY],
  [FULL_CHECK]: true,
});

export const updateFsqaReview = (palletId, palletStatus, selectedPrinterId) => {
  return async (dispatch, getState) => {
    const { formData, initialData } = getInfo(palletId, getState());
    let updatedInfo = {};

    if (palletStatus === PARTIAL_CHECK_COMPLETED && formData.fullCheck) {
      updatedInfo = getFullCheckInfo(formData, initialData);
    }
    if (formData[LOT_CODES].length !== formData.savedLotCodes?.length) {
      updatedInfo[LOT_CODES] = formData[LOT_CODES].map((code) => code.value);
    }

    try {
      const response = await dispatch(
        makeApiRequest({
          type: FSQA_REVIEW_PUT,
          method: WmsFsqaReview.putFsqaReview,
          params: {
            updatedInfo,
            palletId,
            packingFacilityId: getState().session.packing_facility_id,
          },
        })
      );
      if (response.ok) {
        dispatch(addPallets(response.updatedPallets));
        if (selectedPrinterId) {
          dispatch(printPallets([palletId], selectedPrinterId));
        }
      }
    } catch (e) {
      return { ok: false };
    }
  };
};

export const postFsqaReview = (palletId, selectedPrinterId) => {
  return async (dispatch, getState) => {
    const { formData, initialData } = getInfo(palletId, getState());
    // for consistency sake packaging items will ALWAYS be considered a full check (even though they have less fields, all those fields must be filled in every time)
    // converging ingredient and packaging fullChecks here will mean some irrelevant data for packaging item, but it will not break anything and keeps code simplified
    const includeFullCheckInfo =
      formData.fullCheck || initialData.orderableType === 'PackagingItem';

    // handles sending data for both a full check and a partial check
    const fsqaReview = {
      [INITIAL_PRODUCT_DATE]: initialData.productDate,
      [INITIAL_PRODUCT_DATE_TYPE]: initialData.productDateType,
      [ADJUSTED_PRODUCT_DATE]: formData[ADJUSTED_PRODUCT_DATE],
      [ADJUSTED_PRODUCT_DATE_TYPE]: formData[ADJUSTED_PRODUCT_DATE_TYPE],
      [LOT_CODES]: formData[LOT_CODES].map((code) => code.value),
      [FULL_CHECK]: formData.fullCheck || false,
      ...(includeFullCheckInfo && getFullCheckInfo(formData, initialData)),
    };

    try {
      const response = await dispatch(
        makeApiRequest({
          type: FSQA_REVIEW_POST,
          method: WmsFsqaReview.postFsqaReview,
          params: {
            fsqaReview,
            palletId,
            packingFacilityId: getState().session.packing_facility_id,
          },
        })
      );
      if (response.ok) {
        dispatch(addPallets(response.updatedPallets));
        if (selectedPrinterId) {
          dispatch(printPallets([palletId], selectedPrinterId));
        }
      }
    } catch (e) {
      return { ok: false };
    }
  };
};

export const addValueToFsqaReview = (palletId, field, value) => ({
  type: FSQA_ADD_TO_FORM,
  palletId,
  field,
  value,
});

export const clearFSQAForm = (palletId) => ({
  type: FSQA_CLEAR_FORM,
  palletId,
});

export const fsqaToggleFullCheck = (palletId) => ({
  type: FSQA_TOGGLE_FULL_CHECK,
  palletId,
});
