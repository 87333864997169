import { BLUE } from 'lib/constants';

export default {
  icon: {
    height: '27px',
    width: '27px',
  },
  dialogContent: {
    fontFamily: 'Muli',
    padding: '12px',
    width: '200px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    height: '345px',
  },
  form: {
    width: '100%',
    marginBottom: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  button: {
    height: '60px',
    width: '94px',
  },
  badge: {
    backgroundColor: BLUE,
    top: '5px',
    right: '5px',
    height: '12px',
    width: '12px',
  },
};
