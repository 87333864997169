import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import { DateTime } from 'luxon';

const MenuDropDown = ({ menus, classes, value, onChange }) => {
  const buttonText = (weekOfDate) => {
    return DateTime.fromISO(weekOfDate).toFormat('M/dd');
  };

  return (
    <>
      <li className={classNames(classes.navItem, classes.facilityOrMenuSelect)}>
        <form autoComplete="off">
          <Select
            variant="standard"
            id="select-menu-drop-down"
            className={classes.selectMenuDropDown}
            classes={{
              select: classes.menuShipText,
              icon: classes.selectMenuArrowIcon,
            }}
            disableUnderline
            value={value.id || ''}
            onChange={onChange}
          >
            {menus.results.map((menu) => (
              <MenuItem value={menu.id} key={menu.id}>
                {`Menu Ship: ${buttonText(menu.weekOfDate)}`}
              </MenuItem>
            ))}
          </Select>
        </form>
      </li>
    </>
  );
};

MenuDropDown.propTypes = {
  menus: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MenuDropDown;
