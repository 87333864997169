import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useTruckSummaryUpdater } from 'components/WarehouseReceivingApps/shared_hooks';

import TruckSummaryButton from '../TruckSummaryButton';

const MemoDialog = ({ classes, onUpdateTruck, truckUpdatingFailed, truck }) => {
  const {
    value: memo,
    modalOpen,
    setModalOpen,
    handleTruckSummaryChange,
  } = useTruckSummaryUpdater({
    truckUpdatingFailed,
    onUpdateTruck,
    field: truck.memo,
    truckId: truck.id,
    backendFieldName: 'memo',
  });

  const [inputValue, setInputValue] = useState(memo || '');

  const handleSubmit = () => {
    handleTruckSummaryChange(inputValue);
    setModalOpen(false);
  };

  const inputRef = useRef(null);

  const handleInputFocus = () => {
    inputRef.current.focus();
  };

  const handleResetInput = () => {
    setInputValue(memo || '');
  };

  const buttonDisabled = inputValue.length === 0;

  return (
    <Fragment>
      <TruckSummaryButton
        className={classes.memoButton}
        value={memo}
        onClick={() => setModalOpen(true)}
        field="Memo"
      />
      <Dialog
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        TransitionProps={{
          onEntered: handleInputFocus,
          onExited: handleResetInput,
        }}
      >
        <div className={classes.dialogContent}>
          <TextField
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            variant="outlined"
            label="Memo"
            inputRef={inputRef}
            onFocus={(e) => e.target.select()}
            onSelect={(e) => e.preventDefault()}
          />
          <div className={classes.buttonGroup}>
            <Button onClick={() => setModalOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              disabled={buttonDisabled}
              onClick={handleSubmit}
              color="primary"
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

MemoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdateTruck: PropTypes.func.isRequired,
  truckUpdatingFailed: PropTypes.bool.isRequired,
  truck: PropTypes.shape({
    memo: PropTypes.string,
  }).isRequired,
};

export default MemoDialog;
