import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Collapse from '@mui/material/Collapse';
import Backdrop from '@mui/material/Backdrop';

import { isStaleSubmission } from 'lib/viewHelpers';
import { minuteDiffFromNow } from 'lib/utils';

import QaErrorCount from './QaErrorCount';
import styles from '../styles';

function QaErrorHighLow(highErrorRate) {
  if (highErrorRate === true) {
    return 'HIGH';
  } else {
    return 'LOW';
  }
}

function QaErrorHighLowColor(highErrorRate, classes) {
  if (highErrorRate === true && !isEmpty(classes)) {
    return classes.highErrorColor;
  } else {
    return classes.lowErrorColor;
  }
}

const QaErrorCard = ({
  id = 'qa-error-card',
  classes,
  lastQaCheckSubmittedAt,
  highErrorRate,
  errors,
}) => {
  const [minutesSubmittedAgo, setMinutesSubmittedAgo] = useState(
    minuteDiffFromNow(lastQaCheckSubmittedAt)
  );

  const [isSubmissionStale, setIsStaleSubmission] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [QaErrorColor, setQaErrorColor] = useState(
    QaErrorHighLowColor(highErrorRate, classes)
  );

  useEffect(() => {
    let timer = setInterval(
      () => setMinutesSubmittedAgo(minuteDiffFromNow(lastQaCheckSubmittedAt)),
      60000
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setMinutesSubmittedAgo(minuteDiffFromNow(lastQaCheckSubmittedAt));
  }, [lastQaCheckSubmittedAt]);

  useEffect(() => {
    setIsStaleSubmission(isStaleSubmission(minutesSubmittedAgo));
  }, [minutesSubmittedAgo]);

  useEffect(() => {
    setQaErrorColor(QaErrorHighLowColor(highErrorRate, classes));
  }, [highErrorRate, classes]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const message = () => {
    if (lastQaCheckSubmittedAt !== null) {
      return `Last submitted: ${minutesSubmittedAgo} min`;
    } else {
      return 'No submissions at this time';
    }
  };

  const defaultDetails = () => {
    return (
      <CardActionArea
        id={id}
        data-testid={id}
        onClick={handleClick}
        className={classes.clickableInformationalCardArea}
      >
        <Grid container spacing={3}>
          <Grid item lg={8} xs={6}>
            <h3 className={classes.cardHeading}>QA Errors:</h3>
          </Grid>
          <Grid item lg={4} xs={6} className={classes.informationalCardNumber}>
            <h3 className={classNames(classes.cardHeading, QaErrorColor)}>
              {QaErrorHighLow(highErrorRate)}
            </h3>
          </Grid>
        </Grid>
        <div
          className={classNames(classes.cardLastSubmitted, {
            [classes.staleSubmissionColor]: isSubmissionStale,
            [classes.freshSubmissionColor]: !isSubmissionStale,
          })}
        >
          {message()}
        </div>
      </CardActionArea>
    );
  };

  return (
    <React.Fragment>
      <Backdrop
        open={errors.length > 0 && expanded}
        classes={{ root: classes.backdrop }}
      />
      <Card
        className={classNames(
          classes.informationalCard,
          classes.clickableInformationalCard,
          classes.backdroppableCard
        )}
        onClick={handleClick}
      >
        {defaultDetails()}
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          classes={{
            root: errors.length > 0 && classes.expandableArea,
          }}
        >
          {errors.map((error) => {
            return <QaErrorCount key={error.description} error={error} />;
          })}
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

QaErrorCard.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  lastQaCheckSubmittedAt: PropTypes.string,
  highErrorRate: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      item: PropTypes.string,
      occurrences: PropTypes.number,
    })
  ).isRequired,
};

QaErrorCard.defaultProps = {
  lastQaCheckSubmittedAt: undefined,
  highErrorRate: undefined,
  id: 'qa-error-card',
};

export default withStyles(styles)(QaErrorCard);
