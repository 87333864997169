export const initialState = {
  errors: [],
};

export const ADD_ERROR = 'ADD_ERROR';
export const RESET_ERRORS = 'RESET_ERRORS';

export const reducer = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        errors: state.errors.concat(action.message),
      };
    case RESET_ERRORS:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};
