import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import submitButtonTheme from 'lib/submitButtonTheme';
import { StatefulButton } from 'components/shared';

const PalletNotPresent = ({ setPalletToUnknownLocation }) => {
  return (
    <div data-testid="pallet-not-present">
      <Alert severity="warning">
        This action will remove this pallet from this storage slot and put the
        pallet on hold. It will need to have a location assigned to be used.
        Lost pallets can be viewed in "Location Investigation" of this app.
      </Alert>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={submitButtonTheme}>
          <StatefulButton
            buttonTextOptions={{
              LOADING: 'Loading...',
              SUCCESS: 'Success',
              ERROR: 'There was an error saving',
              DEFAULT: 'Mark pallet as missing',
            }}
            type="button"
            id="submitButton"
            onClick={setPalletToUnknownLocation}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

PalletNotPresent.propTypes = {
  setPalletToUnknownLocation: PropTypes.func.isRequired,
};

export default PalletNotPresent;
