import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Loading } from 'components/shared';

const PalletFetcher = ({
  classes,
  children,
  onFetchPallet,
  onResetSelectedPallet,
  fetching,
  fetchFailed,
  pallet,
  palletId,
}) => {
  useEffect(() => {
    onFetchPallet(palletId);
    return () => {
      onResetSelectedPallet();
    };
  }, [onFetchPallet, onResetSelectedPallet, palletId]);
  if ((fetching || isEmpty(pallet)) && !fetchFailed) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  }

  return children;
};

PalletFetcher.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onFetchPallet: PropTypes.func.isRequired,
  onResetSelectedPallet: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  pallet: PropTypes.object.isRequired,
  palletId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default PalletFetcher;
