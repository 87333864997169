import { GREY_100, BLACK } from 'lib/constants';

const value = {
  textAlign: 'right',
  lineHeight: '1.875em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '40px',
  display: 'block',
};

export default {
  quantityInputRoot: {
    width: '60%',
    maxWidth: '250px',
  },
  roationTypeRoot: {
    maxWidth: '300px',
    margin: 0,
  },
  quantityInput: {
    padding: '5px',
    textAlign: 'right',
  },
  datePicker: {
    marginLeft: 5,
    marginRight: 5,
    width: 180,
  },
  odd: {
    backgroundColor: GREY_100,
  },
  row: {
    padding: '0px 0px 0px 16px',
    height: '2.8em',
  },
  value,
  datePickerWrapper: {
    ...value,
    width: '22%',
  },
  toLocationValue: {
    ...value,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  middleValue: {
    width: '25%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  label: {
    fontWeight: '800',
    minWidth: '40px',
  },
  list: {
    fontSize: '14px',
  },
  last: {
    borderBottom: `1px solid ${GREY_100}`,
  },

  locationColor: {
    height: '12px',
    width: '12px',
    borderRadius: '45px',
    marginRight: '10px',
    border: `1px solid ${BLACK}`,
    marginTop: '5px',
  },
  locationText: {
    whiteSpace: 'pre',
  },
  uomConfirmDialog: {
    margin: '10px',
  },
  uomConfirmDialogContent: {
    height: '160px',
    width: '240px',
    padding: '30px',
    fontFamily: 'Muli',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
};
