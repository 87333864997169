import {
  DARK_HC_GREEN,
  WHITE,
  HC_GREEN,
  LIGHT_RED,
  ERROR_RED,
  DARK_RED,
} from 'lib/constants';

export default {
  selectors: {
    margin: '36px 24px',
  },
  button: {
    color: 'black',
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    borderRadius: '40px !important',
    padding: '20px 4px',
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
  wasteButton: {
    borderColor: `${ERROR_RED} !important`,
    color: ERROR_RED,
    '&:hover': {
      backgroundColor: LIGHT_RED,
    },
    '&.Mui-selected': {
      backgroundColor: ERROR_RED,
      color: WHITE,
      '&:focus': {
        backgroundColor: ERROR_RED,
      },
      '&:hover': {
        backgroundColor: DARK_RED,
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(20%, 0.25fr))',
    marginBottom: '20px',
  },
  title: { fontWeight: 'bolder', marginBottom: '20px' },
};
