import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getDockDoors = async ({ packingFacilityId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/dock_doors`,
    options
  );

  return await handleResponse(response);
};

export default {
  getDockDoors,
};
