import {
  VIEW_BOX_DETAILS_REQUEST,
  VIEW_BOX_DETAILS_SUCCESS,
  VIEW_BOX_DETAILS_ERROR,
  CLEAR_SHIPMENT_DETAILS,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  results: {},
  trackingNumber: '',
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case VIEW_BOX_DETAILS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case VIEW_BOX_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        results: action.response.scanprintXray,
      };
    case VIEW_BOX_DETAILS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: action.response?.status !== 404,
      };
    case CLEAR_SHIPMENT_DETAILS:
      return {
        ...state,
        results: {},
      };
    default:
      return state;
  }
}
