import { GREY_600, GREY_800, ROBOTO } from 'lib/constants';

export const cell = {
  color: GREY_800,
  fontFamily: ROBOTO,
  fontSize: '14px',
  fontWeight: '400',
  padding: '16px',
};

export const tableHead = {
  '& .MuiTableCell-head': {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontWeight: 100,
    color: GREY_600,
  },
  '& .MuiTableCell-head:nth-child(1)': {
    textAlign: 'center',
    width: '50%',
  },
  '& .MuiTableCell-head:nth-child(2)': {
    textAlign: 'center',
  },
};

export const header = {
  fontSize: '18px',
  letterSpacing: '1px',
  fontWeight: 'bold',
};

export const loadingSpinner = {
  marginTop: '20px',
};

export const select = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: '1px',
  marginTop: '16px',
};
