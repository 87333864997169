import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PalletScanTextField } from 'components/shared';
import { TABLET_SCREEN_OR_SMALLER } from 'lib/constants';
import ContentModal from 'components/shared/ContentModal';
import ScanBanner from 'components/WarehouseInventory/ScanBanner';

import styles from './styles';

const useStyles = makeStyles(styles);

const ConfirmationScanDialog = ({
  open,
  handleClose,
  itemMoveRequest,
  onConfirmationSuccess,
}) => {
  const classes = useStyles();
  const [showMismatchError, setShowMismatchError] = useState(false);

  const comparePalletIds = (palletId) => {
    const matchingPalletScan = palletId === 'C-' + itemMoveRequest.palletId;

    if (matchingPalletScan) {
      onConfirmationSuccess();
    } else {
      setShowMismatchError(true);
    }
  };

  const handleDialogClose = () => {
    setShowMismatchError(false);
    handleClose();
  };

  const tabletScreenOrSmaller = useMediaQuery(TABLET_SCREEN_OR_SMALLER);
  const showQRBanner = tabletScreenOrSmaller;

  return (
    <ContentModal
      data-testid="confirmation-scan-dialog"
      open={open}
      onClose={handleDialogClose}
    >
      <div className={classes.scanContainer}>
        {showQRBanner && <ScanBanner text="Scan move plate" />}
        <div className={classes.scanText}>
          Pick up move plates and scan in to the system to complete this move
          request.
        </div>
      </div>
      <PalletScanTextField onScan={comparePalletIds} canScan clearAfterScan />
      {showMismatchError && (
        <div className={classes.errorText} data-testid="scan-mismatch-error">
          Pallet ID does not match
        </div>
      )}
    </ContentModal>
  );
};

ConfirmationScanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemMoveRequest: PropTypes.object.isRequired,
  onConfirmationSuccess: PropTypes.func.isRequired,
};

export default ConfirmationScanDialog;
