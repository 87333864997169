import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { StatefulButton } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const buttonText = {
  SUCCESS: 'Success!',
  LOADING: 'SAVING...',
  ERROR: 'ERROR - Try Again',
};

const HoldForm = ({
  alreadyOnHold,
  closeForm,
  isFailed,
  isFetching,
  onSubmit,
}) => {
  const classes = useStyles();
  const [approverName, setApproverName] = useState('');

  const renderPlaceHold = () => {
    return (
      <>
        <div className={classes.formConfirmationText}>
          Are you sure you'd like to place these recipes on hold?
        </div>
        <div className={classes.buttonRow}>
          <Button
            className={classes.formCancelButton}
            variant="outlined"
            color="primary"
            onClick={closeForm}
          >
            No, Cancel
          </Button>
          <StatefulButton
            buttonTextOptions={{ ...buttonText, DEFAULT: 'Yes, Place on Hold' }}
            classes={{ root: classes.formSubmitButton }}
            failed={isFailed}
            loading={isFetching}
            onClick={() => onSubmit()} // explicit func call to avoid passing html event params to submit
            type="submit"
          />
        </div>
      </>
    );
  };

  const renderReleaseHold = () => {
    return (
      <>
        <hr className={classes.formDivider} />
        <div className={classes.formHeader}>
          <div className={classes.formHeaderText}>Release Hold</div>
          <Button
            className={classes.cancelButton}
            variant="text"
            color="error"
            onClick={closeForm}
          >
            Cancel
          </Button>
        </div>
        <TextField
          label="Approver name"
          inputProps={{
            'data-testid': 'approver-name-input',
          }}
          value={approverName}
          onChange={(e) => {
            setApproverName(e.target.value);
          }}
          fullWidth
        />
        <StatefulButton
          buttonTextOptions={{ ...buttonText, DEFAULT: 'CONFIRM' }}
          classes={{ root: classes.formSubmitButton }}
          disabled={!approverName}
          failed={isFailed}
          loading={isFetching}
          onClick={() => onSubmit({ releaseApproverName: approverName })}
          type="submit"
        />
      </>
    );
  };

  return (
    <>
      <hr className={classes.formDivider} />
      {alreadyOnHold ? renderReleaseHold() : renderPlaceHold()}
    </>
  );
};

HoldForm.propTypes = {
  alreadyOnHold: PropTypes.bool,
  closeForm: PropTypes.func.isRequired,
  isFailed: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

HoldForm.defaultProps = {
  alreadyOnHold: false,
  isFailed: false,
  isFetching: false,
};

export default HoldForm;
