import { GREY_700 } from 'lib/constants';

import { cell, header } from '../styles';

export default {
  cell,
  header,
  collapseContainer: { padding: 0, borderBottom: 0 },
  row: {
    cursor: 'pointer',
    height: '10px',
  },
  title: {
    ...header,
    color: GREY_700,
    fontSize: '16px',
    fontWeight: 'bolder',
    margin: '20px 0px 16px 18px',
    padding: '0px 17px',
  },
  toggleDetailsLink: {
    height: '40px',
    fontWeight: 'bolder',
  },
  progressStepIcon: {
    display: 'inline-block',
    fontSize: '15px',
    paddingRight: '5px',
    marginTop: '2px',
  },
  ingredientDetailsContainer: {
    borderBottom: '1px solid rgb(224,224,224)',
  },
  totalProgressContainer: {
    padding: '20px 50px',
  },
  mealDetailsContainer: {
    padding: '0px',
    backgroundColor: 'rgb(250,250,250)',
  },
};
