import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

const SHIFT_ORDER = ['first', 'second', 'third'];

const orderShifts = (shifts) => {
  return sortBy(shifts, (shift) => {
    return SHIFT_ORDER.indexOf(shift.name);
  });
};

export const allShiftNames = (shifts) => {
  return map(orderShifts(shifts), 'name');
};

export const selectCurrentShift = (shifts) => {
  return find(shifts, { isCurrent: true }) || orderShifts(shifts)[0];
};
