import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { submitAndPrintPallets } from 'redux/pallets/actions';

import PalletSubmissionForm from './PalletSubmissionForm';
import styles from './styles';

const mapStateToProps = (state) => ({
  user: state.session.user_name,
  fetching: state.pallets.receivingApp.posting,
  failed: state.pallets.receivingApp.postFailed,
  errorMessage: state.pallets.receivingApp.errorMessage,
  deleteFailed: state.pallets.receivingApp.deleteFailed,
  printers: state.printers.printers,
});

const mapDispatchToProps = {
  submitAndPrintPallets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PalletSubmissionForm));
