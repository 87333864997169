import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import {
  getShipment,
  createReplacementLabel,
  clearShippingOptionDetails,
} from 'redux/boxRay/printNewLabel/actions';
import { setTopBarContent } from 'redux/topBar/actions';

import ShippingOptionDetails from './ShippingOptionDetails';
import styles from './styles';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onGetShipment: getShipment,
  onClearShippingOptionDetails: clearShippingOptionDetails,
  onCreateReplacementLabel: createReplacementLabel,
};

const mapStateToProps = (state, ownProps) => ({
  trackingNumberFromUrl: ownProps.match.params.trackingNumber,
  printNewLabel: state.printNewLabel.results,
  fetching: state.printNewLabel.fetching,
  failed: state.printNewLabel.failed,
  creating: state.printNewLabel.creating,
  creatingFailed: state.printNewLabel.creatingFailed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShippingOptionDetails));
