import { WHITE, GREY_800, HC_GREEN, GREY_200, BLACK } from 'lib/constants';

export default {
  scanCardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  card: {
    margin: '5px',
  },
  header: {
    backgroundColor: GREY_800,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    borderRadius: '5px 5px 0px 0px',
  },
  cardContent: {
    color: GREY_800,
    fontWeight: 'bold',
    padding: '10px',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  icon: {
    width: '25px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  scanText: {
    marginLeft: '10px',
    display: 'inline-block',
    fontFamilt: 'Muli',
    color: GREY_800,
  },
  assignButton: {
    color: WHITE,
    margin: '0px auto',
    fontWeight: 'bolder',
    padding: '15px 0',
    border: `1px solid ${GREY_200}`,
    fontSize: '14px',
    '&:hover.Mui-disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
    '&.Mui-disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
    backgroundColor: `${HC_GREEN}`,
    '&:hover': {
      color: WHITE,
      backgroundColor: HC_GREEN,
    },
  },
};
