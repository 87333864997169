import {
  WHITE,
  GREY_600,
  GREY_400,
  MEDIA_QUERY_TABLET_OR_SMALLER,
} from 'lib/constants';

export default {
  root: {
    display: 'flex',
    minHeight: '76vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    outline: 'none',
  },
  palletButton: {
    background: WHITE,
    padding: '5px',
    borderRadius: '3px',
    border: `1px solid ${GREY_400}`,
  },
  buttonContainer: {
    display: 'flex',
  },
  browseButton: {
    width: '80%',
    background: GREY_600,
    color: WHITE,
    borderRadius: '4px',
    border: 'none',
    padding: '1em',
    margin: '5px auto',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '47%',
  },
  scanningArea: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px',
    width: '100%',
  },
  icon: {
    width: '70%',
    margin: '10px auto',
  },
  iconContainer: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    scanningArea: {
      width: '60%',
    },
    buttonContainer: {
      flexDirection: 'column',
    },
    browseButton: {
      maxWidth: '100%',
    },
  },
};
