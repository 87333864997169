import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import isNil from 'lodash/isNil';

import {
  toggleTruckDrawer,
  selectTruck,
  preselectOrderForTruck,
  addOrderToTruck,
} from 'redux/truck/actions';

import styles from './styles';
import ItemDrawerNeedTruck from './ItemDrawerNeedTruck';

const mapStateToProps = (state) => ({
  trucks: state.trucks.receiving.trucks.filter((truck) =>
    isNil(truck.dockOutTime)
  ),
});

const mapDispatchToProps = {
  toggleTruckDrawer: toggleTruckDrawer,
  selectTruck: selectTruck,
  selectOrderForTruck: preselectOrderForTruck,
  onAddOrderToTruck: addOrderToTruck,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ItemDrawerNeedTruck));
