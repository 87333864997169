import {
  ON_TARGET_GREY,
  BELOW_TARGET_RED,
  AHEAD_OF_TARGET_GREEN,
  GREY_200,
} from 'lib/constants';

export default {
  emphasizedText: {
    color: '#595959',
    fontWeight: 'bolder',
  },
  titleRoot: {
    overflow: 'hidden',
    padding: '14px 0 0 10px',
    textOverflow: 'ellipsis',
  },
  goalProgressStatistics: {
    display: 'contents',
  },
  progressBarGridItem: {
    paddingTop: '19px',
    marginLeft: '10px',
    position: 'relative',
  },
  linearProgressRoot: {
    height: '8px',
    borderRadius: '4px',
    maxWidth: '100%',
  },
  onTarget: {
    backgroundColor: ON_TARGET_GREY,
  },
  belowTarget: {
    backgroundColor: BELOW_TARGET_RED,
  },
  aheadOfTarget: {
    backgroundColor: AHEAD_OF_TARGET_GREEN,
  },
  barRemaining: {
    backgroundColor: GREY_200,
  },
  countTextWrapper: {
    display: 'flex',
    padding: '14px 0 0 2%',
    color: 'gray',
    justifyContent: 'center',
  },
  goalDivider: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  progressBarTick: {
    position: 'absolute',
    top: '18px',
    fontSize: '8px',
    fontWeight: '900',
  },
  expanded: {},
  mealItem: {
    marginBottom: '15px',
  },
  expandMore: {
    margin: 0,
    width: '48px',
  },
};
