import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchCountLists, deleteCountList } from 'redux/cycleCounting/actions';

import CountLists from './CountLists';

const mapDispatchToProps = {
  onFetchCountLists: fetchCountLists,
  onDeleteCountList: deleteCountList,
};

const mapStateToProps = (state) => ({
  countLists: state.cycleCounting.countLists,
  timeZone: state.session.timeZone,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CountLists));
