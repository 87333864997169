import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { Loading, ListItemLink } from 'components/shared';
import { BACK_BUTTON, TEXT, INVENTORY_MANAGER_ACTIONS } from 'lib/constants';

import styles from './styles';

const StorageAreaList = ({
  onSetTopBarContent,
  storageAreas,
  fetching,
  classes,
  match,
  title,
  titleEmoji,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: TEXT,
      text: title,
      rightContent: INVENTORY_MANAGER_ACTIONS,
    });
  }, [onSetTopBarContent, title]);

  if (fetching) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  }
  return (
    <>
      {titleEmoji && (
        <Box className={classes.titleBox}>
          <span
            role="img"
            aria-label="Thinking Emoji"
            className={classes.titleEmoji}
          >
            {titleEmoji}
          </span>
          {title}
        </Box>
      )}
      <List disablePadding classes={{ root: classes.list }}>
        {storageAreas.map((storageArea, index) => (
          <ListItemLink
            last={index === storageAreas.length - 1}
            grey={index % 2 === 0}
            key={storageArea.id}
            link={`${match.path}/${storageArea.id}`}
            className={classes.listItemLink}
          >
            <ListItemText>{storageArea.name}</ListItemText>
          </ListItemLink>
        ))}
      </List>
    </>
  );
};

StorageAreaList.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  storageAreas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(StorageAreaList);
