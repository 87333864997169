import { drawerStyling } from 'lib/styles';

export default {
  bodySection: {
    ...drawerStyling.bodySection,
    height: 'calc(100vh - 145px)',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: 20,
    height: 20,
    padding: '20px 20px 0px 20px',
  },
  root: {
    width: '100%',
    fontFamily: 'Roboto',
  },
  topSection: {
    ...drawerStyling.topSection,
    justifyContent: 'end',
  },
};
