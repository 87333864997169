import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import styles from './styles';

class LabelLetterBox extends Component {
  classes() {
    const { empty, classes } = this.props;
    return empty ? classes.empty : classes.labelRoot;
  }

  render() {
    const { letter } = this.props;
    return (
      <Typography variant="h4" classes={{ root: this.classes() }}>
        {letter}
      </Typography>
    );
  }
}

LabelLetterBox.propTypes = {
  classes: PropTypes.object.isRequired,
  empty: PropTypes.bool,
  letter: PropTypes.string,
};

LabelLetterBox.defaultProps = {
  empty: undefined,
  letter: undefined,
};

export default withStyles(styles)(LabelLetterBox);
