import React from 'react';

import ItemCell from './Cells/ItemCell';
import ActionsCell from './Cells/ActionsCell';

// Keys for columns. These are unique identifiers for the columns
export const ITEM = 'item';
export const REQUESTED_MEALS = 'requestedMeals';
export const DROPPED_OFF = 'droppedOff';
export const QUANTITY = 'quantity';
export const ACTIONS = 'actions';
export const PALLET_ID = 'palletId';
export const CHECKED_IN = 'checkedIn';
export const TIME_ZONE = 'timeZone';

export const AVAILABLE_COLUMNS = [
  PALLET_ID,
  ITEM,
  REQUESTED_MEALS,
  DROPPED_OFF,
  QUANTITY,
  ACTIONS,
  TIME_ZONE,
];

export const AUDIT_ACTION = 'audit';
export const MOVE_ACTION = 'move';
export const RETURN_ACTION = 'return';
export const PRINT_ACTION = 'print';

export const AVAILABLE_ACTIONS = [
  AUDIT_ACTION,
  MOVE_ACTION,
  RETURN_ACTION,
  PRINT_ACTION,
];

const zonedDateTime = (value, tableMeta) => {
  // time zone is reliably last... hopefully
  const timeZone = tableMeta.rowData.slice(-1)[0];

  const options = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  };

  const formatter = new Intl.DateTimeFormat([], options);

  return value ? formatter.format(new Date(value)) : null;
};

export const defaultTableConfiguration = [
  {
    name: ITEM,
    key: ITEM,
    label: 'Item',
    options: {
      filter: false,
      customBodyRender: function displayIngredientCell(value) {
        return <ItemCell item={value} />;
      },
      sortCompare: (order) => {
        return ({ data: item1 }, { data: item2 }) => {
          return item1 > item2 && order === 'asc' ? 1 : -1;
        };
      },
    },
  },
  {
    name: CHECKED_IN,
    key: CHECKED_IN,
    label: 'Checked In',
    options: {
      filter: false,
      sort: true,
      customBodyRender: function displayIngredientCell(value, tableMeta) {
        return <div>{zonedDateTime(value, tableMeta)}</div>;
      },
    },
  },
  {
    name: PALLET_ID,
    key: PALLET_ID,
    label: 'Pallet ID',
  },
  {
    name: REQUESTED_MEALS,
    key: REQUESTED_MEALS,
    label: 'Requested Meals',
    options: {
      customBodyRender: (value) => {
        return (
          <>
            {value.map((mealTitle) => {
              return <div key={mealTitle}>{mealTitle}</div>;
            })}
          </>
        );
      },
      sortCompare: (order) => {
        return ({ data: mealsList1 }, { data: mealsList2 }) => {
          mealsList1.sort();
          mealsList2.sort();
          return mealsList1[0] > mealsList2[0] && order === 'asc' ? 1 : -1;
        };
      },
    },
  },
  {
    name: DROPPED_OFF,
    key: DROPPED_OFF,
    label: 'Dropped Off',
    options: {
      filter: false,
      sort: true,
      customBodyRender: function displayIngredientCell(value, tableMeta) {
        return <div>{zonedDateTime(value, tableMeta)}</div>;
      },
    },
  },
  {
    name: QUANTITY,
    key: QUANTITY,
    label: 'Quantity',
    options: {
      filter: false,
    },
  },
  {
    name: ACTIONS,
    key: ACTIONS,
    label: 'Actions',
    options: {
      filter: false,
      sort: false,
      customBodyRender: function displayActionCell(value) {
        return <ActionsCell actions={value} />;
      },
    },
  },
  {
    name: TIME_ZONE,
    key: TIME_ZONE,
    label: 'Time Zone',
    options: {
      display: 'false',
      filter: false,
    },
  },
];

const getSearchableTerms = (row, columns) => {
  const itemIndex = columns.findIndex((column) => column.name === ITEM);
  const palletIdIndex = columns.findIndex(
    (column) => column.name === PALLET_ID
  );
  const requestedMealsIndex = columns.findIndex(
    (column) => column.name === REQUESTED_MEALS
  );

  // search on item name, pallet id, or requested meals
  return [row[itemIndex].name, row[palletIdIndex], row[requestedMealsIndex]];
};

export const options = {
  selectableRows: 'none',
  rowsPerPage: 100,
  rowsPerPageOptions: [10, 50, 100],
  print: false,
  responsive: 'standard',
  download: false,
  customSearch: (searchQuery, currentRow, columns) => {
    const query = RegExp(searchQuery, 'i');
    const searchableTerms = getSearchableTerms(currentRow, columns);

    return searchableTerms.some((item) => query.test(item));
  },
};
