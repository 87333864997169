import { ROBOTO, DIVIDER_GREY } from 'lib/constants';

import { formLabelText, formSubmitButton, sublotStyles } from '../../styles';

export default {
  ...sublotStyles,
  formDivider: {
    height: `1px solid ${DIVIDER_GREY}`,
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  drawerHeader: {
    fontFamily: ROBOTO,
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '123.5%',
    letterSpacing: '0.25px',
    paddingBottom: '10px',
  },
  drawerSubheader: {
    fontFamily: ROBOTO,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    paddingBottom: '10px',
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-20px',
  },
  formHeaderText: {
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },
  formLabelText,
  formSubmitButton: {
    ...formSubmitButton,
    marginRight: '0',
  },
};
