import { useEffect, useCallback, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  PHONE_SCREEN,
  TABLET_SCREEN,
  ENTER_KEY,
  SHIFT_KEY,
  UNIDENTIFIED,
} from 'lib/constants';

const useScanner = (inputElement, processScan, canScan = true) => {
  const tabletScanResults = useRef('');
  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const tabletScreen = useMediaQuery(TABLET_SCREEN);

  const onPageKeyDown = useCallback(
    (e) => {
      if (
        tabletScreen &&
        document.activeElement !== inputElement.current &&
        canScan
      ) {
        if (e.key === ENTER_KEY) {
          const scan = tabletScanResults.current;
          tabletScanResults.current = '';
          processScan(scan);
        } else if (e.key !== SHIFT_KEY && e.key !== UNIDENTIFIED) {
          tabletScanResults.current += e.key;
        }
      } else if (phoneScreen && inputElement.current && canScan) {
        inputElement.current.focus();
      }
    },
    [phoneScreen, inputElement, canScan, processScan, tabletScreen]
  );

  const handleVisibilityChange = useCallback(() => {
    // This allows you to scan after going from lock screen on handhelds
    if (phoneScreen && !document.hidden && document.hasFocus()) {
      if (inputElement.current) {
        inputElement.current.blur();
      }
    }
  }, [inputElement, phoneScreen]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    document.addEventListener('keydown', onPageKeyDown, true);

    return () => {
      document.removeEventListener('keydown', onPageKeyDown, true);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onPageKeyDown, handleVisibilityChange]);

  useEffect(() => {
    if (inputElement.current && !tabletScreen) {
      inputElement.current.focus();
    }
  }, [inputElement, tabletScreen]);

  return onPageKeyDown;
};

export default useScanner;
