import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { fetchMealsTrackerProgress } from 'redux/rthProgressTracker/assembly/actions';
import { Loading } from 'components/shared';

import MealForAssembly from '../MealForAssembly';
import styles from './styles';

const useStyles = makeStyles(styles);

const Recipes = ({ expandAll, trigger }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuId } = useParams();

  const { meals, mealsFetching } = useSelector(
    (state) => state.rthAssemblyProgressTracker
  );

  useEffect(() => {
    if (menuId) {
      dispatch(fetchMealsTrackerProgress(menuId));
    }
  }, [menuId]);

  return (
    <>
      {mealsFetching ? (
        <div className={classes.loadingSpinner}>
          <Loading />
        </div>
      ) : (
        <TableContainer className={classes.container} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell>Meal Name + Id</TableCell>
                <TableCell>Progress</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meals.map((meal) => (
                <MealForAssembly
                  key={meal.id}
                  meal={meal}
                  expandAll={expandAll}
                  trigger={trigger}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Recipes;
