import { createTheme } from '@mui/material';

import { centeredLoading } from 'lib/styles';
import {
  TABLET_SCREEN_OR_SMALLER,
  HC_GREEN,
  DARK_HC_GREEN,
} from 'lib/constants';

export default {
  centeredLoading,
  itemName: {
    fontFamily: 'Arial',
    fontWeight: '900',
    fontSize: '1.0em',
  },
  actionButtonContainer: {
    marginLeft: '5px',
  },
  container: {
    display: 'flex',
  },
  button: {
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  [`@media ${TABLET_SCREEN_OR_SMALLER}`]: {
    actionButtonContainer: {
      marginTop: '10px',
    },
    container: {
      display: 'block',
    },
  },
  toggleOn: {
    '&.Mui-checked': {
      color: HC_GREEN,
    },
    '+.Mui-checked': {
      backgroundColor: HC_GREEN,
    },
  },
  filterZeroQuantityToggle: {
    display: 'flex',
    alignItems: 'flex-end',
  },
};

// This is to override the filter themes so we can
// have a wider popover for the filter options.
export const getMuiDataTableTheme = () =>
  createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            minWidth: '400px',
          },
        },
      },
    },
  });
