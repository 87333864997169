import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { fetchIngredientsTrackerProgress } from 'redux/rthProgressTracker/slacking/actions';
import { Loading } from 'components/shared';

import IngredientForSlacking from '../IngredientForSlacking';
import styles from './styles';

const useStyles = makeStyles(styles);

const IngredientsForSlacking = ({ expandAll, trigger }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuId } = useParams();

  const { ingredients, ingredientsFetching } = useSelector(
    (state) => state.rthSlackingProgressTracker
  );

  useEffect(() => {
    if (menuId) {
      dispatch(fetchIngredientsTrackerProgress(menuId));
    }
  }, [menuId]);

  return (
    <>
      {ingredientsFetching ? (
        <div className={classes.loadingSpinner}>
          <Loading />
        </div>
      ) : (
        <TableContainer className={classes.container} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell>Ingredient Name + Id</TableCell>
                <TableCell>Total Submitted</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ingredients.map((ingredient) => (
                <IngredientForSlacking
                  key={`ingredients-for-slacking-${ingredient.id}`}
                  ingredient={ingredient}
                  expandAll={expandAll}
                  trigger={trigger}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

IngredientsForSlacking.propTypes = {
  expandAll: PropTypes.bool.isRequired,
  trigger: PropTypes.bool.isRequired,
};

export default IngredientsForSlacking;
