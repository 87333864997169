import {
  WHITE,
  GREY_800,
  GREY_600,
  GREY_200,
  BLUE,
  HC_GREEN,
  LIGHT_GREEN,
  DARK_RED,
  ORANGE_YELLOW,
  BLACK,
} from 'lib/constants';

export default {
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    margin: '7px 5px',
  },
  header: {
    backgroundColor: GREY_800,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    borderRadius: '5px 5px 0px 0px',
  },
  cardContent: {
    color: GREY_800,
    fontWeight: 'bold',
    padding: '8px',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  lightText: {
    color: GREY_600,
  },
  lightTextOneLine: {
    color: GREY_600,
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  line: {
    border: 0,
    borderTop: `1px solid ${GREY_200}`,
  },
  rightSide: {
    display: 'inline',
    margin: '5px',
  },
  chip: {
    fontWeight: 'bolder',
    height: '24px',
    float: 'right',
  },
  myChip: {
    backgroundColor: BLUE,
    color: WHITE,
  },
  textField: {
    width: '100%',
  },
  numericInput: {
    // Removes arrows from number input
    '&::-webkit-inner-spin-button': {
      '::-webkit-appearance': 'none',
      '::-moz-appearance': 'none',
      appearance: 'none',
    },
  },
  cancelButton: {
    textTransform: 'none',
    width: '30%',
  },
  backButton: {
    textTransform: 'none',
    width: '100%',
  },
  submitButton: {
    textTransform: 'none',
    width: '70%',
    backgroundColor: HC_GREEN,
    color: WHITE,
    '&:disabled': {
      color: WHITE,
      backgroundColor: LIGHT_GREEN,
    },
    '&:hover.Mui-disabled': {
      color: WHITE,
      backgroundColor: LIGHT_GREEN,
    },
  },
  noPalletFound: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '120px',
  },
  deactivedBanner: {
    backgroundColor: DARK_RED,
    color: WHITE,
    padding: '10px 7px',
  },
  onHoldBanner: {
    backgroundColor: ORANGE_YELLOW,
    color: BLACK,
    padding: '10px 7px',
  },
  reactivateContainer: {
    padding: '5px 0px',
  },
  casesRequested: {
    textAlign: 'center',
    fontSize: '24px',
    color: BLACK,
    fontWeight: 'bold',
  },
  casesAvailable: {
    textAlign: 'center',
    color: GREY_600,
    marginTop: '8px',
  },
  palletMeasuringUnit: {
    borderRight: `1px solid ${GREY_200}`,
    paddingRight: '10px',
  },
  palletQuantity: {
    paddingLeft: '10px',
  },
  insufficientQuantity: {
    color: DARK_RED,
  },
};
