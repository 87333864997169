import React from 'react';
import { DateTime } from 'luxon';
import { subDays, addDays, format, startOfWeek, parseISO } from 'date-fns';

import { FormatNumberCell } from 'components/shared';

const generatePastXDates = (x) => {
  const today = new Date();
  return Array.from(Array(x)).map((_, index) =>
    format(subDays(today, index), 'yyyy-MM-dd')
  );
};

// relevant ship weeks are 1 past, present, and 4 future
const generateShipWeeks = () => {
  const thisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  return [
    subDays(thisWeek, 7),
    thisWeek,
    addDays(thisWeek, 7),
    addDays(thisWeek, 14),
    addDays(thisWeek, 21),
    addDays(thisWeek, 28),
  ].map((date) => format(date, 'yyyy-MM-dd'));
};

export const columns = [
  {
    name: 'created_at',
    label: 'Date 📅',
    options: {
      customBodyRender: (value) => value && value.split(' ')[0],
      filterOptions: {
        names: generatePastXDates(28),
        renderValue: (date) => format(parseISO(date), 'MM/dd/yyyy'),
      },
      customFilterListOptions: {
        render: (date) => format(parseISO(date), 'MM/dd/yyyy'),
      },
      setCellHeaderProps: () => ({
        style: { width: '7%', whiteSpace: 'nowrap' },
      }),
    },
  },
  {
    name: 'created_at',
    label: 'Time 🕔',
    options: {
      customBodyRender: (value = '') =>
        value && `${value.split(' ')[1]} ${value.split(' ')[2]}`,
      filter: false,
      setCellHeaderProps: () => ({
        style: { width: '6%', paddingTop: '10px', whiteSpace: 'nowrap' },
      }),
      setCellProps: () => ({
        style: { whiteSpace: 'nowrap' },
      }),
      sort: false,
    },
  },
  {
    name: 'id',
    label: 'Lot Number',
    options: {
      filter: false,
      customBodyRender: (value) => {
        const result = <FormatNumberCell value={value} />;
        return result;
      },
      setCellHeaderProps: () => ({ style: { width: '6%' } }),
    },
  },
  {
    name: 'count',
    label: 'Quantity Submitted',
    options: {
      filter: false,
      customBodyRender: (value) => {
        const result = <FormatNumberCell value={value} addCommas />;
        return result;
      },
      setCellHeaderProps: () => ({ style: { width: '1%' } }),
      setCellProps: () => ({ style: { fontWeight: 'bold', fontSize: '18px' } }),
    },
  },
  {
    name: 'activity',
    label: 'Activity',
    options: {
      filterOptions: {
        names: ['Labeling', 'Portioning', 'Meal Bagging'],
      },
      setCellHeaderProps: () => ({ style: { width: '3%' } }),
    },
  },

  {
    name: 'meal_letter',
    label: 'Letter',
    options: {
      filter: true,
      setCellHeaderProps: () => ({ style: { width: '1%' } }),
    },
  },
  {
    name: 'meal_title',
    label: 'Meal Name',
    options: {
      filter: false,
      setCellHeaderProps: () => ({ style: { width: '20%' } }),
    },
  },
  {
    name: 'ingredient_name',
    label: 'Ingredient',
    options: {
      filter: false,
      setCellHeaderProps: () => ({ style: { width: '15%' } }),
    },
  },
  {
    name: 'shift',
    label: 'Shift',
    options: {
      filter: true,
      filterOptions: {
        names: ['First', 'Second', 'Third'],
      },
      setCellHeaderProps: () => ({ style: { width: '1%' } }),
    },
  },
  {
    name: 'user_name',
    label: 'Submitter Name',
    options: {
      filter: false,
      setCellHeaderProps: () => ({ style: { width: '10%' } }),
    },
  },
  {
    name: 'facility_internal_location_name',
    label: 'Pod Location',
    setCellHeaderProps: () => ({ style: { width: '3%' } }),
  },
  {
    name: 'ship_week',
    label: 'Ship Week',
    options: {
      filter: true,
      display: false,
      customBodyRender: (shipWeek) => {
        return DateTime.fromISO(shipWeek).toFormat('M/dd');
      },
      filterOptions: {
        names: generateShipWeeks(),
        renderValue: (date) => format(parseISO(date), 'MM/dd'),
      },
      setCellHeaderProps: () => ({ style: { width: '3%' } }),
      customFilterListOptions: {
        render: (date) => format(parseISO(date), 'MM/dd'),
      },
    },
  },
];

export const options = {
  filterType: 'dropdown',
  responsive: 'standard',
  selectableRows: 'none',
  print: false,
  download: false,
  rowsPerPage: 100,
  sortOrder: {
    name: 'created_at',
    direction: 'desc',
  },
};
