import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const MINUTE_OFFSET = 60;

const getShifts = async () => {
  const response = await fetch(
    `${API_BASE_URI}/ops/shifts/?minute_offset=${MINUTE_OFFSET}`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

export default { getShifts };
