import { makeApiRequest } from 'lib/api';
import {
  STORAGE_SLOTS,
  RESET_STORAGE_SLOTS,
  STORAGE_SLOT_DEPTHS,
  INVENTORY_PALLET_CYCLE_COUNT,
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION,
  INVENTORY_PALLET_UNKNOWN_LOCATION,
  MISSING_SLOT_PALLETS,
} from 'lib/constants';

import StorageSlots from './services';

export const fetchStorageSlots = (storageAreaId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: STORAGE_SLOTS,
        method: StorageSlots.getStorageSlots,
        params: { storageAreaId },
      })
    );
  };
};

export const fetchMissingSlotPallets = (storageAreaId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MISSING_SLOT_PALLETS,
        method: StorageSlots.getMissingSlotPallets,
        params: { storageAreaId },
      })
    );
  };
};

export const getDepths = (location, packingFacilityId, palletId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: STORAGE_SLOT_DEPTHS,
        method: StorageSlots.getDepths,
        params: { location, packingFacilityId, palletId },
      })
    );
  };
};

export const setCycleCount = (palletId, wasCounted) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_CYCLE_COUNT,
        method: StorageSlots.setCycleCount,
        params: { palletId, wasCounted },
      })
    );
  };
};

export const cycleCountDeactivation = (palletId, countListId = null) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION,
        method: StorageSlots.cycleCountDeactivation,
        params: { palletId, countListId },
      })
    );
  };
};

export const setUnknownLocation = (palletId, countListId) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: INVENTORY_PALLET_UNKNOWN_LOCATION,
        method: StorageSlots.setUnknownLocation,
        params: {
          palletId,
          packingFacilityId: getState().session.packing_facility_id,
          countListId,
        },
      })
    );
  };
};

export const resetStorageSlots = () => ({
  type: RESET_STORAGE_SLOTS,
});

export const resetMissingSlotPallets = () => ({
  type: RESET_STORAGE_SLOTS,
});
