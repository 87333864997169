import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { postIngredientInventory } from 'redux/productionInventories/ingredientInventory/actions';

import IngredientInventoryForm from './IngredientInventoryForm';

const mapDispatchToProps = {
  onPostIngredientInventory: postIngredientInventory,
};

const mapStateToProps = (state) => ({
  printers: state.printers.printers,
  podLocations: state.podLocations.pods,
  posting: state.ingredientInventory.posting,
  errors: state.ingredientInventory.errors,
  packingFacilityId: state.session.packing_facility_id,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IngredientInventoryForm));
