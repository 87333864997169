import { WHITE, GREY_200, BLACK } from 'lib/constants';

export default {
  printerButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  printerButton: {
    cursor: 'pointer',
    borderRadius: '8px',
    boxSizing: 'border-box',
    background: WHITE,
    border: `1px solid ${GREY_200}`,
    height: '3.7em',
    margin: '8px',
    fontFamily: 'Muli',
  },
  printerOptionsText: {
    color: BLACK,
    textAlign: 'center',
  },
};
