import { useCallback } from 'react';
import debounce from 'lodash/debounce';

const useDebounce = (fn, wait = 200, maxWait = 450, dependencies = []) => {
  const delayedFunction = useCallback(
    debounce(fn, wait, {
      leading: true,
      maxWait: maxWait,
    }),
    dependencies
  );

  return delayedFunction;
};

export default useDebounce;
