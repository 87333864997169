import { makeApiRequest } from 'lib/api';
import { updateLastSuccessfulFetchAt } from 'redux/session/actions';
import {
  ALL_PANELS_COLLAPSED,
  ALL_PANELS_EXPANDED,
  PANEL_TOGGLED,
  MEALS_AND_INGREDIENTS,
} from 'lib/constants';
import { filterDateAsDDMMYY, getTimeFromDate } from 'lib/utils';

import MealsAndIngredients from './services';

export const fetchMealsByMenu = ({ menuId, facilityId }) => async (
  dispatch
) => {
  const response = await dispatch(
    makeApiRequest({
      type: MEALS_AND_INGREDIENTS,
      method: MealsAndIngredients.byMenu,
      params: { menuId, facilityId },
    })
  );

  if (response.ok) {
    const currentTime = new Date();
    const formattedTime = `${filterDateAsDDMMYY(currentTime)} ${getTimeFromDate(
      currentTime
    )}`;

    dispatch(updateLastSuccessfulFetchAt(formattedTime));
  }
};

export const expandAllMeals = () => {
  return {
    type: ALL_PANELS_EXPANDED,
  };
};

export const collapseAllMeals = () => {
  return {
    type: ALL_PANELS_COLLAPSED,
  };
};

export const toggleMeal = (panel) => {
  return {
    type: PANEL_TOGGLED,
    payload: { panel },
  };
};
