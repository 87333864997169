import {
  WMS_ITEM_RECEIPT_CREATE,
  WMS_ITEM_RECEIPT_UPDATE,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import ItemReceipt from './services';

export const createItemReceipt = (params) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: WMS_ITEM_RECEIPT_CREATE,
        method: ItemReceipt.createItemReceipt,
        params,
      })
    );
  };
};

export const updateItemReceipt = (params) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: WMS_ITEM_RECEIPT_UPDATE,
        method: ItemReceipt.updateItemReceipt,
        params,
      })
    );
  };
};
