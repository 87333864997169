import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import TextField from '@mui/material/TextField';

import { formatPalletInfo } from 'components/WarehouseMoves/helpers';
import ContentModal from 'components/shared/ContentModal';

import styles from './styles';

const useStyles = makeStyles(styles);

const APPROVED_SUBSTITUTION = 'approvedSubstitution';
const SCAN_NEW_PALLET = 'scanNewPallet';

const WrongItemModal = ({
  isOpen,
  closeModal,
  pallet,
  suggestedPallet,
  itemMoveRequest,
  setOverrideInfo,
  history,
}) => {
  const [activeButton, setActiveButton] = useState('');
  const [supervisorName, setSupervisorName] = useState('');

  const classes = useStyles();

  const handleModalClose = () => {
    closeModal();
    itemMoveRequest &&
      history.push(`/warehouse_moves/requests/open/${itemMoveRequest.id}`);
  };

  const hasItemMoveRequest = itemMoveRequest?.id;

  const handleOptionClick = (e) => {
    setActiveButton(e.currentTarget.id);
  };

  const handleSupervisorNameChange = (e) => {
    setSupervisorName(e.target.value);
  };

  const handleOverrideClick = () => {
    setOverrideInfo({
      itemMoveRequestId: itemMoveRequest.id,
      suggestedPalletId: suggestedPallet.id,
      reasonCode: 'Approved Substitution',
      approver: supervisorName,
    });
    closeModal();
  };

  return (
    <ContentModal open={isOpen} onClose={handleModalClose}>
      <div className={classes.dangerMessage}>
        <DangerousIcon className={classes.dangerIcon} />
        <div className={classes.dangerText}>
          <div className={classes.dangerHeader}>Incorrect Pallet Scanned</div>
          <div className={classes.dangerInfo}>
            The pallet scanned does not match the request
          </div>
        </div>
      </div>
      <div className={classes.palletComparison}>
        <span className={classes.boldFont}>Suggested:&nbsp;</span>
        <span className={classes.lightFont}>
          {suggestedPallet?.id
            ? formatPalletInfo(suggestedPallet)
            : 'No viable pallets'}
        </span>
      </div>
      <div className={classes.palletComparison}>
        <span className={classes.boldFont}>Scanned:&nbsp; </span>
        <span className={classes.lightFont}>{formatPalletInfo(pallet)}</span>
      </div>

      {!hasItemMoveRequest && (
        <div>
          <hr className={classes.divider} />
          <Button variant="contained" fullWidth onClick={closeModal}>
            Scan Another Pallet
          </Button>
        </div>
      )}

      {hasItemMoveRequest && (
        <div>
          <Button
            id={APPROVED_SUBSTITUTION}
            variant="outlined"
            className={classnames(classes.optionButton, {
              selected: activeButton === APPROVED_SUBSTITUTION,
            })}
            onClick={handleOptionClick}
          >
            Approved Substitution
          </Button>
          <Button
            id={SCAN_NEW_PALLET}
            variant="outlined"
            className={classnames(classes.optionButton, {
              selected: activeButton === SCAN_NEW_PALLET,
            })}
            onClick={handleModalClose}
          >
            Scan New Pallet
          </Button>
          {activeButton === APPROVED_SUBSTITUTION && (
            <TextField
              data-testid="supervisor-name-field"
              className={classes.supervisorName}
              fullWidth
              variant="outlined"
              label="Supervisor Name"
              value={supervisorName}
              onChange={handleSupervisorNameChange}
            />
          )}
          <hr className={classes.divider} />
          <Button
            variant="contained"
            fullWidth
            onClick={handleOverrideClick}
            disabled={!supervisorName}
          >
            Confirm Override
          </Button>
        </div>
      )}
    </ContentModal>
  );
};

WrongItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  pallet: PropTypes.object,
  suggestedPallet: PropTypes.object,
  itemMoveRequest: PropTypes.object,
  setOverrideInfo: PropTypes.func,
  history: PropTypes.object.isRequired,
};

WrongItemModal.defaultProps = {
  pallet: {},
  suggestedPallet: null,
  itemMoveRequest: null,
  setOverrideInfo: () => {},
};

export default WrongItemModal;
