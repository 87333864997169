export const cycleCountPallet = ({ storageSlots, palletId, cycleCounted }) =>
  storageSlots.map((slot) => ({
    ...slot,
    pallets: slot.pallets.map((pallet) => ({
      ...pallet,
      ...(parseInt(pallet.id) === parseInt(palletId) && { cycleCounted }),
    })),
  }));

export const removePallet = ({ storageSlots, palletId }) =>
  storageSlots.map((slot) => ({
    ...slot,
    pallets: slot.pallets.filter((pallet) => pallet.id !== palletId),
  }));
