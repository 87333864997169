import React from 'react';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';

import { formatNumber } from 'lib/utils';
import { Loading } from 'components/shared';

const ProductionPlansCard = ({
  productionPlans,
  fetching,
  shiftNames,
  classes,
}) => {
  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.menuSelectorContainer}>
          {shiftNames.map((shift) => (
            <NavLink
              key={shift}
              to={shift}
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              {startCase(shift)}
            </NavLink>
          ))}
        </div>
      </CardContent>

      <Grid container className={classes.table}>
        <Grid container classes={{ root: classes.headerRow }}>
          <Grid item xs={2} className={classes.tableHeadCell}>
            Activity
          </Grid>
          <Grid item xs={3} className={classes.tableHeadCell}>
            Name
          </Grid>
          <Grid item xs={2} className={classes.tableHeadCell}>
            Pod Location
          </Grid>
          <Grid item xs={1} className={classes.tableHeadCell} align="right">
            Req&apos;d
          </Grid>
          <Grid item xs={1} className={classes.tableHeadCell} align="right">
            Left
          </Grid>
          <Grid item xs={1} className={classes.tableHeadCell} align="right">
            Hours
          </Grid>
          <Grid item xs={1} className={classes.tableHeadCell} align="right">
            # Ppl
          </Grid>
          <Grid item xs={1} className={classes.tableHeadCell} align="right">
            Tgt Rt/HR
          </Grid>
        </Grid>
        {fetching && !isEmpty(productionPlans) ? null : (
          <Grid container>
            {productionPlans.map((row) => (
              <Grid container key={`${row.name}-${row.activity}`}>
                <Grid item xs={2} className={classes.tableCell}>
                  {startCase(row.activity)}
                </Grid>
                <Grid item xs={3} className={classes.tableCell}>
                  {row.name}
                </Grid>
                <Grid item xs={2} className={classes.tableCell}>
                  {row.podLocationName}
                </Grid>
                <Grid item xs={1} className={classes.tableCell} align="right">
                  {formatNumber(row.goalCount)}
                </Grid>
                <Grid item xs={1} className={classes.tableCell} align="right">
                  {formatNumber(row.remainingCount)}
                </Grid>
                <Grid item xs={1} className={classes.tableCell} align="right">
                  {row.hours}
                </Grid>
                <Grid item xs={1} className={classes.tableCell} align="right">
                  {row.peopleCount}
                </Grid>
                <Grid item xs={1} className={classes.tableCell} align="right">
                  {formatNumber(row.targetRate)}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {fetching ? <Loading className={classes.centeredLoading} /> : null}
      {!fetching && isEmpty(productionPlans) ? (
        <div className={classes.centeredText}>
          <h4>No production plans have been made for this meal and shift.</h4>
        </div>
      ) : null}
    </Card>
  );
};

ProductionPlansCard.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  shiftNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  productionPlans: PropTypes.array.isRequired,
};

export default ProductionPlansCard;
