import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const ItemManagerFormContext = createContext();

// field names that will also be used by child components
const fieldNames = {
  quantity: 'quantity',
  newLocation: 'newLocation',
  overrideMoveType: 'overrideMoveType',
};

// initial form state
const initialState = {
  [fieldNames.newLocation]: null,
  [fieldNames.overrideMoveType]: null,
  [fieldNames.quantity]: 0,
};

// reducer to update form state
const reducer = (state, action = {}) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

// helper hook to map methods to reducer types
const useItemManagerForm = (productionZoneItem) => {
  const withParameters = {
    ...initialState,
    productionZoneItem,
  };

  const [state, dispatch] = useReducer(reducer, withParameters);

  const setField = (field, value) => {
    dispatch({ type: 'SET_FIELD', field, value });
  };

  return {
    state,
    actions: {
      setField,
    },
  };
};

// provider to wrap children with context from reducer
const ItemManagerFormProvider = ({ productionZoneItem, children }) => {
  const { state, actions } = useItemManagerForm(productionZoneItem);

  return (
    <ItemManagerFormContext.Provider value={{ state, actions }}>
      {children}
    </ItemManagerFormContext.Provider>
  );
};

ItemManagerFormProvider.propTypes = {
  productionZoneItem: PropTypes.object,
  children: PropTypes.node,
};

ItemManagerFormProvider.defaultProps = {
  children: null,
  productionZoneItem: {},
};

export {
  ItemManagerFormProvider,
  ItemManagerFormContext,
  fieldNames,
  initialState,
};
