import { POD_LOCATIONS_REQUEST, POD_LOCATIONS_SUCCESS } from 'lib/constants';

const initialState = { pods: [], gridMap: [], fetching: false };

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case POD_LOCATIONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case POD_LOCATIONS_SUCCESS:
      return {
        ...state,
        gridMap: action.response.gridMap,
        pods: action.response.internalLocations,
        fetching: false,
      };
    default:
      return state;
  }
}
