import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import styles from './styles';

const QRCodeIcon = ({ className, classes }) => {
  return (
    <div className={classNames(className, classes.grey)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.64 40.12">
        <g data-name="Layer 2">
          <g data-name="QR Code">
            <rect x="38.78" y="21.06" width="5.86" height="5.27" />
            <path d="M44.59,0H23.42V19.05H44.59ZM26.17,16.58V2.47H41.85V16.58Z" />
            <rect x="29.84" y="5.77" width="8.34" height="7.51" />
            <rect x="6.41" y="26.85" width="8.34" height="7.51" />
            <polygon points="38.78 34.78 34.4 34.78 34.4 25.57 29.28 25.57 29.28 21.06 23.42 21.06 23.42 26.33 28.54 26.33 28.54 34.78 23.42 34.78 23.42 40.05 29.28 40.05 29.28 35.45 33.66 35.45 33.66 40.05 44.64 40.05 44.64 30.17 38.78 30.17 38.78 34.78" />
            <rect x="6.41" y="5.77" width="8.34" height="7.51" />
            <path d="M0,19.05H21.17V0H0ZM18.43,2.47V16.58H2.74V2.47Z" />
            <path d="M0,40.12H21.17v-19H0ZM18.43,23.54V37.66H2.74V23.54Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

QRCodeIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

QRCodeIcon.defaultProps = {
  className: 'QRCodeIcon',
};

export default withStyles(styles)(QRCodeIcon);
