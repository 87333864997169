import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { UploaderUserInterface } from 'components/shared';

import {
  headerError,
  requiredFieldErrors,
  thirtyMinuteIntervalError,
} from './csvValidationHelper';
import { reducer, initialState, ADD_ERROR, RESET_ERRORS } from './formUtils';

const UploaderInterface = ({
  onPostDockAppointment,
  posting,
  postingErrors,
}) => {
  const [validationState, dispatch] = useReducer(reducer, initialState);
  const [isValidating, setIsValidating] = useState(false);

  const addError = (message) => {
    dispatch({ type: ADD_ERROR, message: message });
  };

  const validateAndSubmitData = (fileData) => {
    dispatch({ type: RESET_ERRORS });
    setIsValidating(true);

    const headerMissingError = headerError(fileData);
    if (headerMissingError) {
      return addError(headerMissingError.message);
    }
    const fieldErrors = requiredFieldErrors(fileData);
    if (!isEmpty(fieldErrors)) {
      return addError(fieldErrors.map((err) => err.message));
    }
    const timeError = thirtyMinuteIntervalError(fileData);
    if (timeError) {
      return addError(timeError.message);
    }
    onPostDockAppointment(fileData);
  };

  const onDrop = (fileData) => {
    validateAndSubmitData(fileData);
    setIsValidating(false);
  };

  const postingErrorList = () => {
    return postingErrors.length > 0 ? (
      <ul>
        {postingErrors.map((error) => {
          return Object.keys(error).map((key) => {
            return <li key={key}>{`${key}: ${error[key]}`}</li>;
          });
        })}
      </ul>
    ) : null;
  };
  return (
    <UploaderUserInterface
      isValidating={isValidating}
      onDrop={onDrop}
      posting={posting}
      postingErrors={postingErrors}
      postingErrorList={postingErrorList}
      validationState={validationState}
      uploadName="Dock Schedule"
    />
  );
};

UploaderInterface.propTypes = {
  onPostDockAppointment: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  postingErrors: PropTypes.array.isRequired,
};

export default UploaderInterface;
