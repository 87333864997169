import {
  BLACK,
  HC_GREEN,
  GREY_200,
  GREY_400,
  DARK_RED,
  WHITE,
  BLUE,
  ORANGE_YELLOW,
} from 'lib/constants';

const moveTypeChip = {
  textTransform: 'uppercase',
  marginRight: '12px',
  height: '24px',
  fontWeight: 'bolder',
};

export default {
  listItemLink: {
    padding: '5px',
    color: BLACK,
  },
  itemMoveRequest: {
    minWidth: 0,
    marginTop: '3px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    fontFamily: 'Muli',
    flexFlow: 'row wrap',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
  },
  lotId: {
    color: GREY_400,
    fontSize: '13px',
    padding: '0 0 5px 4px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    margin: '5px',
  },
  bold: {
    fontWeight: 600,
    marginLeft: '2px',
    marginBottom: '5px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timestamp: {
    margin: '3px 0px 3px 2px',
    color: HC_GREEN,
  },
  expiredTime: {
    color: DARK_RED,
  },
  buttonDisabled: {
    backgroundColor: GREY_200,
  },
  chip: {
    fontWeight: 'bolder',
    height: '24px',
  },
  myChip: {
    backgroundColor: BLUE,
    color: WHITE,
  },
  lastRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropListChip: {
    ...moveTypeChip,
    backgroundColor: HC_GREEN,
    color: WHITE,
  },
  followUpChip: {
    backgroundColor: ORANGE_YELLOW,
    color: BLACK,
  },
  stagingChip: {
    backgroundColor: BLUE,
    color: WHITE,
  },
};
