import difference from 'lodash/difference';
import reject from 'lodash/reject';
import ceil from 'lodash/ceil';

import { pluralize } from 'lib/utils';

export const UPDATE_INTERNAL_LOCATION_NAME = 'UPDATE_INTERNAL_LOCATION_NAME';
export const UPDATE_SELECTED_INGREDIENTS = 'UPDATE_SELECTED_INGREDIENTS';
export const UPDATE_SELECTED_INGREDIENT_PACKAGINGS =
  'UPDATE_SELECTED_INGREDIENT_PACKAGINGS';
export const UPDATE_SELECTED_REQUESTABLE_COUNT =
  'UPDATE_SELECTED_REQUESTABLE_COUNT';
export const REMOVE_REQUESTED = 'REMOVE_REQUESTED';
export const REMOVE_REQUESTED_INGREDIENT = 'REMOVE_REQUESTED_INGREDIENT';
export const REMOVE_REQUESTED_PACKAGING = 'REMOVE_REQUESTED_PACKAGING';

export const initialState = {
  internalLocationName: '',
  selectedIngredientAssignments: [],
  selectedIngredientPackagings: [],
  selectedRequestablesWithCounts: [],
  defaultCount: '',
};

const updateSelectedIAWithCounts = (state, newIAs) => {
  const diffIds = difference(state.selectedIngredientAssignments, newIAs);
  if (diffIds.length > 0) {
    return state.selectedRequestablesWithCounts.filter(
      (ia) => ia.requestableId !== diffIds[0].ingredientId
    );
  }
  return state.selectedRequestablesWithCounts;
};

const updateSelectedIPWithCounts = (state, newIPs) => {
  const diffIds = difference(state.selectedIngredientPackagings, newIPs);
  if (diffIds.length > 0) {
    const foundItem = state.selectedRequestablesWithCounts.find(
      (req) =>
        req.category === diffIds[0].category &&
        req.requestableId === diffIds[0].id &&
        req.ingredientAssignmentId === diffIds[0].ingredientAssignmentId
    );
    if (foundItem) {
      const indexOfFoundItem = state.selectedRequestablesWithCounts.indexOf(
        foundItem
      );
      state.selectedRequestablesWithCounts.splice(indexOfFoundItem, 1);
    }
  }
  return state.selectedRequestablesWithCounts;
};

export const pushRequestCount = (requestCountState, newValue) => {
  const filteredRequestCountState = reject(requestCountState, {
    requestableId: newValue.requestableId,
    requestableType: newValue.requestableType,
    category: newValue.category,
    ingredientAssignmentId: newValue.ingredientAssignmentId,
    compoundIngredientRecipeAssignmentId:
      newValue.compoundIngredientRecipeAssignmentId,
  });

  filteredRequestCountState.push({
    requestableId: newValue.requestableId,
    requestableType: newValue.requestableType,
    requestCount: newValue.count,
    category: newValue.category,
    ingredientAssignmentId: newValue.ingredientAssignmentId,
    isProtein: newValue.isProtein,
    compoundIngredientRecipeAssignmentId:
      newValue.compoundIngredientRecipeAssignmentId,
  });
  return filteredRequestCountState;
};

export const removeRequest = (requestState, removedId, requestable = null) => {
  // Need optional chaining (?.) for when there is only 1 ingredient
  // or 1 packaging in the state
  if (
    requestable?.isPackagingItem ||
    requestable?.requestableType === 'PackagingItem'
  ) {
    const foundItem = requestState.find(
      (req) =>
        req.category === requestable.category &&
        (req.id || req.requestableId) === removedId &&
        req.ingredientAssignmentId === requestable.ingredientAssignmentId &&
        req.compoundIngredientRecipeAssignmentId ===
          requestable.compoundIngredientRecipeAssignmentId
    );
    if (foundItem) {
      const indexOfFoundItem = requestState.indexOf(foundItem);
      requestState.splice(indexOfFoundItem, 1);
    }
    return requestState;
  } else {
    const updatedIngredient = requestState.filter(
      (request) =>
        request.category !== requestable.category ||
        (request.ingredientId || request.requestableId) !== removedId ||
        request.compoundIngredientRecipeAssignmentId !==
          requestable.compoundIngredientRecipeAssignmentId
    );
    return updatedIngredient;
  }
};

export const reducer = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case UPDATE_INTERNAL_LOCATION_NAME:
      return {
        ...state,
        internalLocationName: action.value,
      };
    case UPDATE_SELECTED_INGREDIENTS:
      return {
        ...state,
        selectedIngredientAssignments: action.value,
        selectedRequestablesWithCounts: updateSelectedIAWithCounts(
          state,
          action.value
        ),
      };
    case UPDATE_SELECTED_INGREDIENT_PACKAGINGS:
      return {
        ...state,
        selectedIngredientPackagings: action.value,
        selectedRequestablesWithCounts: updateSelectedIPWithCounts(
          state,
          action.value
        ),
      };
    case UPDATE_SELECTED_REQUESTABLE_COUNT:
      return {
        ...state,
        selectedRequestablesWithCounts: pushRequestCount(
          state.selectedRequestablesWithCounts,
          action.value
        ),
      };
    case REMOVE_REQUESTED_INGREDIENT:
      return {
        ...state,
        selectedIngredientAssignments: removeRequest(
          state.selectedIngredientAssignments,
          action.value.removedId,
          action.value.requestable
        ),
        selectedRequestablesWithCounts: removeRequest(
          state.selectedRequestablesWithCounts,
          action.value.removedId,
          action.value.requestable
        ),
      };
    case REMOVE_REQUESTED_PACKAGING:
      return {
        ...state,
        selectedIngredientPackagings: removeRequest(
          state.selectedIngredientPackagings,
          action.value.removedId,
          action.value.requestable
        ),
        selectedRequestablesWithCounts: removeRequest(
          state.selectedRequestablesWithCounts,
          action.value.removedId,
          action.value.requestable
        ),
      };
    default:
      return state;
  }
};

export const conversionHelperText = (requestable, numPortions) => {
  if (!requestable.portionToCasesRatio) {
    return 'No conversion available';
  } else {
    const equation = requestable.portionToCasesRatio * (numPortions || 0);
    let numberOfCases = ceil(parseFloat(equation).toFixed(2));

    // because some of the conversions are such small numbers, we need to round up to 1 case
    // in the instances where rounding to two decimals still gives us zero with a valid
    // number of portions
    if (numPortions > 0 && numberOfCases === 0) {
      numberOfCases = 1;
    }

    return pluralize(numberOfCases, 'case');
  }
};
