import {
  LABEL_INVENTORY_POST_SUCCESS,
  LABEL_INVENTORY_POST_REQUEST,
  LABEL_INVENTORY_POST_ERROR,
} from 'lib/constants';

const initialState = {
  errors: null,
  results: {},
  posting: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case LABEL_INVENTORY_POST_REQUEST:
      return {
        ...state,
        posting: true,
      };
    case LABEL_INVENTORY_POST_SUCCESS:
      return {
        ...state,
        results: action.response,
        errors: null,
        posting: false,
      };
    case LABEL_INVENTORY_POST_ERROR:
      return {
        ...state,
        errors: [action.response.error],
        posting: false,
      };
    default:
      return state;
  }
}
