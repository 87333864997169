import { DOCK_DOORS_REQUEST, DOCK_DOORS_SUCCESS } from 'lib/constants';

const initialState = {
  fetching: false,
  results: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case DOCK_DOORS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case DOCK_DOORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        results: action.response.dockDoors,
      };
    default:
      return state;
  }
}
