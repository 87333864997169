import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { fetchIngredientsTrackerProgress } from 'redux/rthProgressTracker/ingredients/actions';
import { Loading } from 'components/shared';

import Ingredient from '../Ingredient';
import styles from './styles';

const useStyles = makeStyles(styles);

const Ingredients = ({ expandAll, trigger }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menuId } = useParams();

  const { ingredients, ingredientsFetching } = useSelector(
    (state) => state.rthIngredientsProgressTracker
  );

  useEffect(() => {
    if (menuId) {
      dispatch(fetchIngredientsTrackerProgress(menuId));
    }
  }, [menuId]);

  return (
    <>
      {ingredientsFetching ? (
        <div className={classes.loadingSpinner}>
          <Loading />
        </div>
      ) : (
        <>
          <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Ingredient Name + ID</TableCell>
                  <TableCell>Recipes Completed for Assembly</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredients.map((ingredient) => (
                  <Ingredient
                    key={ingredient.id}
                    ingredient={ingredient}
                    expandAll={expandAll}
                    trigger={trigger}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default Ingredients;
