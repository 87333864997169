import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import get from 'lodash/get';
import flatten from 'lodash/flatten';

import { handleOrderToggle as handleAuditToggle } from 'redux/netsuiteOrders/audit/actions';
import { selectTrucksByNetsuiteOrderItemId } from 'redux/truck/selectors';
import { AUDIT } from 'lib/constants';

import styles from './styles';
import NetsuiteOrderLine from './NetsuiteOrderLine';

export const mapStateToProps = (state, ownProps) => {
  const isAudit = ownProps.whichTab === AUDIT;
  const netsuiteItemIds = ownProps.netsuiteOrder?.netsuiteItems?.map(
    (item) => item.id
  );
  let auditTrucks = [];

  netsuiteItemIds?.forEach((itemId) => {
    const itemTrucks = selectTrucksByNetsuiteOrderItemId(
      state,
      itemId,
      isAudit
    );

    if (itemTrucks.length > 0) {
      auditTrucks = flatten([...auditTrucks, itemTrucks]);
    }
  });

  return {
    expanded: get(
      state.netsuiteOrders,
      [ownProps.whichTab, 'expandedState', ownProps.netsuiteOrder.id, 'open'],
      false
    ),
    auditTrucks,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onOrderToggle: (orderId) => dispatch(handleAuditToggle(orderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NetsuiteOrderLine));
