import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import { Loading } from 'components/shared';
import { fetchMealProgresses } from 'redux/productionProgress/rth/actions';
import { ASSEMBLY } from 'lib/constants';

import MenuNavigation from '../MenuNavigation';
import MealsList from '../MealsList';
import styles from './styles';

const useStyles = makeStyles(styles);

const MealAssembly = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { menuId } = useParams();

  const {
    mealProgresses,
    mealProgressesFailed: failed,
    mealProgressesFetching: fetching,
  } = useSelector((state) => state.rthProductionProgress);

  useEffect(() => {
    menuId && dispatch(fetchMealProgresses(menuId));
  }, [menuId]);

  return (
    <div className={classes.container}>
      <MenuNavigation menusCount={3} />
      {failed && (
        <div className={classes.error}>Failed to fetch meal progresses.</div>
      )}
      {fetching && <Loading fullScreen />}
      {!failed && !fetching && (
        <MealsList mealProgresses={mealProgresses} activity={ASSEMBLY} />
      )}
    </div>
  );
};

export default MealAssembly;
