import { WARNING_YELLOW } from 'lib/constants';

export default {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    marginLeft: 5,
    marginRight: 5,
    width: 180,
  },
  textFieldWarning: {
    backgroundColor: WARNING_YELLOW,
  },
  menuItem: {
    width: 150,
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    height: 20,
  },
  closeIcon: {
    width: 15,
    height: 15,
    margin: '0 auto',
  },
  first: {
    paddingRight: 29,
  },
};
