import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';

import ContentModal from 'components/shared/ContentModal';

import styles from './styles';

const useStyles = makeStyles(styles);

const WrongLotModal = ({
  isOpen,
  closeModal,
  pallet,
  suggestedPallet,
  itemMoveRequest,
  setOverrideInfo,
  history,
}) => {
  const classes = useStyles();

  const handleModalClose = () => {
    closeModal();
    history.push(`/warehouse_moves/requests/open/${itemMoveRequest.id}`);
  };

  const handleOverrideClick = () => {
    setOverrideInfo({
      itemMoveRequestId: itemMoveRequest.id,
      suggestedPalletId: suggestedPallet.id,
      reasonCode: 'User Override',
    });
    closeModal();
  };

  return (
    <ContentModal open={isOpen} onClose={handleModalClose}>
      <div className={classes.warningHeader}>
        <WarningAmberIcon className={classes.warningIcon} />
        Pallet out of Rotation
      </div>
      <div className={classes.lightFont}>
        The pallet scanned does not match suggested.
      </div>
      <div className={classes.palletComparison}>
        <span className={classes.boldFont}>Suggested:&nbsp; </span>
        <span className={classes.lightFont}>
          {suggestedPallet?.id} @ {suggestedPallet?.location}
        </span>
      </div>
      <div>
        <span className={classes.boldFont}>Scanned:&nbsp; </span>
        <span className={classes.lightFont}>
          {pallet?.id} @ {pallet?.location}
        </span>
      </div>
      <hr className={classes.divider} />
      <Button variant="contained" fullWidth onClick={handleOverrideClick}>
        Confirm Override
      </Button>
    </ContentModal>
  );
};

WrongLotModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  pallet: PropTypes.object,
  suggestedPallet: PropTypes.object,
  itemMoveRequest: PropTypes.object,
  setOverrideInfo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

WrongLotModal.defaultProps = {
  pallet: {},
  suggestedPallet: null,
  itemMoveRequest: null,
};

export default WrongLotModal;
