import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const ContentModal = ({
  'data-testid': dataTestId,
  open,
  onClose,
  title,
  subTitle,
  children,
  customFit,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} data-testid={dataTestId}>
      <Box
        className={classNames(classes.modalContainer, {
          [classes.customFit]: customFit,
        })}
      >
        {title && <div className={classes.primaryText}>{title}</div>}
        {subTitle && <div className={classes.secondaryText}>{subTitle}</div>}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.close}
          size="large"
          data-testid="content-modal-close-button"
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

ContentModal.propTypes = {
  'data-testid': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customFit: PropTypes.bool, // use this prop when passing in fully styled components
};

ContentModal.defaultProps = {
  'data-testid': 'content-modal',
  title: '',
  subTitle: '',
  children: null,
  customFit: false,
};

export default ContentModal;
