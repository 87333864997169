import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
  SubtractionInputs,
  PodLocationQuestion,
  FormContainer,
} from 'components/shared';
import {
  RETAIL,
  RETAIL_CHANNEL,
  ECOMMERCE_CHANNEL,
  BY_MACHINE,
  BY_HAND,
  PORTIONING,
} from 'lib/constants';
import InventorySubmitButton from 'components/shared/InventorySubmitButton';
import { usePrevious } from 'lib/custom_hooks';

import IngredientDataCard from '../IngredientDataCard/IngredientDataCard';
import {
  ConfirmationDialog,
  PortionMethodQuestion,
  NumberEmployeesQuestion,
  TeamNumberQuestion,
} from '../formComponents';

const IngredientInventoryForm = ({
  podLocations,
  printers,
  posting,
  errors,
  packingFacilityId,
  onPostIngredientInventory,
  ingredientAssignment,
  history,
  isCustomizeIt,
}) => {
  const prevPosting = usePrevious(posting);

  useEffect(() => {
    if (!posting && prevPosting && !errors) {
      history.push('/production_inventory/portioning');
    }
  }, [history, prevPosting, posting, errors]);

  const [count, setCount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(undefined);
  const [teamNumber, setTeamNumber] = useState(undefined);
  const [portionMethod, setPortionMethod] = useState(undefined);
  const [portioner, setPortioner] = useState(undefined);
  const [portionFillerNumber, setPortionFillerNumber] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [internalLocationName, setInternalLocationName] = useState('');

  const submissionData = {
    count: count,
    people_count: peopleCount,
    team_number: teamNumber,
    portion_method: portionMethod,
    facility_internal_location_name: internalLocationName,
    portioner: portioner,
    portioner_number: portionFillerNumber,
    ingredient_id: ingredientAssignment.ingredientId,
    meal_id: isCustomizeIt ? null : ingredientAssignment.mealId,
    menu_id: ingredientAssignment.menuId,
    channel:
      ingredientAssignment.mealPlan === RETAIL
        ? RETAIL_CHANNEL
        : ECOMMERCE_CHANNEL,
    customize_it: isCustomizeIt,
  };

  const isValidByHand = portionMethod === BY_HAND && portioner === undefined;

  const isValidByPortionFiller =
    portionMethod === BY_MACHINE &&
    portioner === 'Portion Filler' &&
    portionFillerNumber !== undefined &&
    portionFillerNumber !== '';

  const isValidByNonPortionFiller =
    portionMethod === BY_MACHINE && portioner && portioner !== 'Portion Filler';

  const formIsComplete =
    internalLocationName !== '' &&
    peopleCount > 0 &&
    peopleCount < 51 &&
    !isEmpty(teamNumber) &&
    (isValidByHand || isValidByPortionFiller || isValidByNonPortionFiller);

  return (
    <FormContainer id="ingredient-inventory-form">
      <IngredientDataCard ingredientAssignment={ingredientAssignment} />
      <Card>
        <SubtractionInputs
          initialValue={ingredientAssignment.portioningRemainingCount}
          subtractingValue={count}
          updateSubtractingValue={(value) => setCount(value)}
          hasError={count < 0}
          autoFocus
          inputType="text"
          text={{
            initialValueLabel: 'Initial',
            subtractingValueLabel: '# Portions',
            resultLabel: 'Final',
          }}
        />
      </Card>
      <Card>
        <PortionMethodQuestion
          portionMethod={portionMethod}
          setPortionMethod={setPortionMethod}
          portioner={portioner}
          setPortioner={setPortioner}
          portionFillerNumber={portionFillerNumber}
          setPortionFillerNumber={setPortionFillerNumber}
          packingFacilityId={packingFacilityId}
        />
      </Card>
      <Card>
        <PodLocationQuestion
          podLocations={podLocations}
          internalLocationName={internalLocationName}
          setInternalLocationName={setInternalLocationName}
        />
      </Card>
      <Card>
        <TeamNumberQuestion
          teamNumber={teamNumber}
          setTeamNumber={setTeamNumber}
        />
      </Card>
      <Card>
        <NumberEmployeesQuestion
          peopleCount={peopleCount}
          setPeopleCount={setPeopleCount}
        />
      </Card>
      <InventorySubmitButton
        disabled={!formIsComplete}
        count={count}
        item="Portion"
        setDialogOpen={setDialogOpen}
      />
      <ConfirmationDialog
        open={dialogOpen}
        activity={PORTIONING}
        ingredientName={ingredientAssignment.name}
        mealName={ingredientAssignment.mealName || ''}
        handleClose={() => setDialogOpen(false)}
        posting={posting}
        printers={printers}
        submissionData={submissionData}
        onSubmit={onPostIngredientInventory}
      />
    </FormContainer>
  );
};

IngredientInventoryForm.propTypes = {
  podLocations: PropTypes.array.isRequired,
  printers: PropTypes.array.isRequired,
  posting: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  packingFacilityId: PropTypes.number.isRequired,
  onPostIngredientInventory: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  ingredientAssignment: PropTypes.object.isRequired,
  isCustomizeIt: PropTypes.bool.isRequired,
};

IngredientInventoryForm.defaultProps = {
  errors: null,
};

export default IngredientInventoryForm;
