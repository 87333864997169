import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import SuggestedPalletInfo from '../../SuggestedPalletInfo';
import styles from '../../styles';

const useStyles = makeStyles(styles);

const IssueReportedView = ({
  onCloseModal,
  suggestedPallet,
  itemMoveRequest,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid className={classes.palletIssueTitle} item xs={12}>
        Issue Reported! <Check className={classes.greenCheck} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.boldText}>New Pallet Assigned</div>
        <SuggestedPalletInfo
          suggestedPallet={suggestedPallet}
          itemMoveRequest={itemMoveRequest}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onCloseModal} className={classes.okButton}>
          OK
        </Button>
      </Grid>
    </Grid>
  );
};

IssueReportedView.defaultProps = {
  itemMoveRequest: {},
};

IssueReportedView.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  suggestedPallet: PropTypes.object.isRequired,
  itemMoveRequest: PropTypes.object,
};

export default IssueReportedView;
