import merge from 'lodash/merge';
import { createTheme } from '@mui/material/styles';

import {
  HC_GREEN,
  DARK_HC_GREEN,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

const defaultTableStyles = {
  typography: {
    fontFamily: 'Roboto',
  },
  components: {
    MUIDataTableToolbar: {
      styleOverrides: {
        titleText: {
          fontFamily: 'Muli',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          verticalAlign: 'top',
          height: '56px',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          color: 'rgba(0, 0, 0, 1)',
          padding: '4px 15px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px 15px 20px;',
        },
        body: {
          color: 'rgba(0, 0, 0, 0.65)',
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        responsiveScrollMaxHeight: {
          maxHeight: '75vh',
        },
      },
    },
  },
};

let warehouseReceivingStyles = {
  palette: {
    primary: {
      main: HC_GREEN,
      dark: DARK_HC_GREEN,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: HC_GREEN,
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        titleText: { fontWeight: 'bold' },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: HC_GREEN,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: { cursor: 'pointer' },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        responsiveScrollMaxHeight: {
          maxHeight: '69vh',
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: HC_GREEN,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        current: {
          color: HC_GREEN,
        },
      },
    },
  },
};
warehouseReceivingStyles = merge(
  {},
  defaultTableStyles,
  warehouseReceivingStyles
);

const datePickerInventoryManager = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          textAlign: 'right',
        },
      },
    },
  },
};

export const smallerDatePickerForHoneywell = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          [MEDIA_QUERY_PHONE_SCREEN]: {
            margin: '10px auto',
          },
        },
        paperScrollPaper: {
          [MEDIA_QUERY_PHONE_SCREEN]: {
            maxHeight: 'calc(100% - 70px)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          [MEDIA_QUERY_PHONE_SCREEN]: {
            alignSelf: 'end',
          },
        },
      },
    },
  },
};

const datePickerReceiving = {
  components: {
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          '&:hover': {
            backgroundColor: DARK_HC_GREEN,
          },
        },
      },
      day: {
        width: '40px',
        height: '40px',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        label: {
          fontSize: '1.5em',
          fontFamily: 'Roboto',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        label: {
          fontSize: '1.5em',
          padding: '5px',
        },
      },
    },
    MuiPickersModal: {
      styleOverrides: {
        dialogRoot: {
          minWidth: '330px',
        },
      },
    },
    MuiPickersCalendar: {
      styleOverrides: {
        transitionContainer: {
          minHeight: '240px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        dayLabel: {
          width: '40px',
        },
      },
    },
  },
};

let palletTable = {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { marginBottom: 100 },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: 35,
        },
      },
    },
  },
};
palletTable = merge({}, palletTable, defaultTableStyles);

export default createTheme(defaultTableStyles);

export const stylesWithWarehouseReceiving = createTheme(
  warehouseReceivingStyles
);
export const palletTableStyles = createTheme(palletTable);
export const datePickerReceivingStyles = createTheme(
  merge({}, datePickerReceiving, warehouseReceivingStyles)
);
export const datePickerInventoryManagerStyles = createTheme(
  merge({}, warehouseReceivingStyles, datePickerInventoryManager)
);
