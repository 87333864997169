import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { requestPodLocations } from 'redux/podLocation/actions';

import PreliminaryCheck from './PreliminaryCheck';

const PreliminaryCheckContainer = ({
  location,
  podLocations,
  onRequestPodLocations,
  packingFacilityId,
}) => {
  useEffect(() => {
    onRequestPodLocations({ facilityId: packingFacilityId });
  }, [onRequestPodLocations, packingFacilityId]);
  const meal = location.state.meal;
  const channel = location.state.channel;
  return (
    <PreliminaryCheck
      meal={meal}
      channel={channel}
      podLocations={podLocations}
    />
  );
};

const mapDispatchToProps = {
  onRequestPodLocations: requestPodLocations,
};

const mapStateToProps = (state) => ({
  packingFacilityId: state.session.packing_facility_id,
  podLocations: state.podLocations.pods,
});

PreliminaryCheckContainer.propTypes = {
  podLocations: PropTypes.array.isRequired,
  onRequestPodLocations: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreliminaryCheckContainer);
