import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';

export const BreadcrumbContext = createContext([]);

const BreadcrumbProvider = ({ children, location }) => {
  const deriveCrumbs = () => {
    const locationInfo = location.pathname.split('/');

    const crumbs = [];

    const matchForMealBagging = matchPath(location.pathname, {
      path:
        '/production_inventory/meal_bagging/menu/:menuId/menu_selection/:menuSelectionId',
      exact: true,
    });

    const matchForLabelingMealList = matchPath(location.pathname, {
      path: '/production_inventory/labeling/ingredient/:ingredientId/meals',
      exact: true,
    });

    const matchForLabelingForm = matchPath(location.pathname, {
      path:
        '/production_inventory/labeling/ingredient/:ingredientId/ingredient_assignment/:ingredientAssignmentId',
      exact: true,
    });

    const matchForPortioningMealList = matchPath(location.pathname, {
      path: '/production_inventory/portioning/ingredient/:ingredientId/meals',
      exact: true,
    });

    const matchForPortioningForm = matchPath(location.pathname, {
      path:
        '/production_inventory/portioning/ingredient/:ingredientId/ingredient_assignment/:ingredientAssignmentId',
      exact: true,
    });

    if (matchForMealBagging) {
      const { menuSelection } = location.state;

      crumbs.push({
        link: locationInfo.slice(0, 5).join('/'),
        text: 'Meal Bagging',
      });
      crumbs.push({
        text: `${menuSelection.letterAssignment} - ${menuSelection.title}`,
      });
    }

    if (location.state && locationInfo.includes('ingredient')) {
      const comingFromIngredient = location.state.ingredient;
      const ingredientOrAssignment = comingFromIngredient
        ? location.state.ingredient
        : location.state.ingredientAssignment;
      const ingredientName = comingFromIngredient
        ? ingredientOrAssignment.ingredientName
        : ingredientOrAssignment.name;

      if (matchForLabelingMealList) {
        crumbs.push({
          link: locationInfo.slice(0, 3).join('/'),
          text: 'Labeling',
        });
        crumbs.push({
          text: ingredientName,
          link: locationInfo.slice(0, 8).join('/'),
        });
      }

      if (matchForLabelingForm) {
        const { ingredientAssignment } = location.state;

        crumbs.push({
          link: locationInfo.slice(0, 3).join('/'),
          text: 'Labeling',
        });
        crumbs.push({
          text: `${ingredientAssignment.name}`,
          link: locationInfo.slice(0, 5).concat(['meals']).join('/'),
          location: location,
        });
        crumbs.push({
          text: `${ingredientAssignment.letterAssignment} - ${ingredientAssignment.mealName}`,
        });
      }

      if (matchForPortioningMealList) {
        crumbs.push({
          link: locationInfo.slice(0, 3).join('/'),
          text: 'Portioning',
        });
        crumbs.push({
          text: ingredientName,
          link: locationInfo.slice(0, 8).join('/'),
        });
      }

      if (matchForPortioningForm) {
        const { ingredientAssignment } = location.state;

        crumbs.push({
          link: locationInfo.slice(0, 3).join('/'),
          text: 'Portioning',
        });
        crumbs.push({
          text: `${ingredientAssignment.name}`,
          link: locationInfo.slice(0, 5).concat(['meals']).join('/'),
          location: location,
        });
        crumbs.push({
          text: `${ingredientAssignment.letterAssignment} - ${ingredientAssignment.mealName}`,
        });
      }
    }
    return crumbs;
  };
  return (
    <BreadcrumbContext.Provider value={deriveCrumbs()}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

BreadcrumbProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default BreadcrumbProvider;
