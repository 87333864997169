import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import styles from './styles';

export const MainTable = withStyles(styles)(({ classes, children }) => (
  <Table className={classes.fixedTable}>{children}</Table>
));

export const MainTableHeadRow = ({ children }) => (
  <TableHead>
    <TableRow>{children}</TableRow>
  </TableHead>
);

export const MainTableHead = withStyles(
  styles
)(({ classes, size = 'Large', children }) => (
  <TableCell
    classes={{ head: classNames(classes.tableHead, classes[`column${size}`]) }}
  >
    {children}
  </TableCell>
));

// The rows of the main table are also the click area to show/hide collapsible content (aka secondary table)
// // isOpen and setIsOpen props are passed in from HOC CollapsibleArea
export const MainTableRow = ({ children, isOpen, setIsOpen }) => (
  <TableRow onClick={() => setIsOpen(!isOpen)}>
    {children}
    <MainTableCell>
      {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </MainTableCell>
  </TableRow>
);

export const MainTableCell = withStyles(styles)(
  ({ classes, icon, greenText, children }) => (
    <TableCell
      classes={{
        root: classNames(classes.tableCell, {
          [classes.iconCell]: icon,
          [classes.greenText]: greenText,
        }),
      }}
    >
      {children}
    </TableCell>
  )
);

MainTable.propTypes = {
  children: PropTypes.node.isRequired,
};

MainTableHeadRow.propTypes = {
  children: PropTypes.node.isRequired,
};

MainTableHead.defaultProps = {
  size: 'Large',
  children: null,
};

MainTableHead.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
};

MainTableRow.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
};

MainTableRow.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
};

MainTableCell.defaultProps = {
  icon: false,
  children: null,
};

MainTableCell.propTypes = {
  icon: PropTypes.bool,
  children: PropTypes.node,
};
