import { connect } from 'react-redux';

import {
  handleExpandAll,
  handleCollapseAll,
} from 'redux/netsuiteOrders/audit/actions';

import AuditExpandCollapse from './AuditExpandCollapse';

const mapDispatchToProps = {
  onExpandAll: handleExpandAll,
  onCollapseAll: handleCollapseAll,
};

export default connect(null, mapDispatchToProps)(AuditExpandCollapse);
