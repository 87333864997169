import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import { usePrevious } from 'lib/custom_hooks';
import { CircledCheckmark } from 'components/icons';

import styles from './styles';

const SuccessScreen = ({ classes, loading, failed }) => {
  const prevLoading = usePrevious(loading);
  const [showSuccess, setShowSuccess] = useState(false);
  const showSuccessScreen = useCallback(() => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 400);
  }, [setShowSuccess]);
  useEffect(() => {
    if (prevLoading && !loading && !failed) {
      showSuccessScreen();
    }
  }, [showSuccessScreen, prevLoading, loading, failed]);

  return (
    <div
      className={classNames(classes.successScreen, {
        [classes.showSuccessScreen]: showSuccess,
      })}
    >
      Success
      <CircledCheckmark className={classes.checkmark} />
    </div>
  );
};

SuccessScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SuccessScreen);
