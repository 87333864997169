import { makeApiRequest } from 'lib/api';
import {
  PRODUCTION_ZONE_ITEMS_GET,
  PRODUCTION_ZONE_ITEM_UPDATE,
  CHANGEOVER_ZONE,
  PRINT_LICENSE_PLATE,
} from 'lib/constants';

import {
  getProductionZoneItems,
  updateProductionZoneItem,
  changeover,
  print,
} from './services';

export const fetchProductionZoneItems = (params) =>
  makeApiRequest({
    type: PRODUCTION_ZONE_ITEMS_GET,
    method: getProductionZoneItems,
    params: params,
  });

export const manageProductionZoneItem = (params) => {
  return makeApiRequest({
    type: PRODUCTION_ZONE_ITEM_UPDATE,
    method: updateProductionZoneItem,
    params: params,
  });
};

export const changeoverZone = (params) => {
  return makeApiRequest({
    type: CHANGEOVER_ZONE,
    method: changeover,
    params: params,
  });
};

export const printLicensePlate = (params) => {
  return makeApiRequest({
    type: PRINT_LICENSE_PLATE,
    method: print,
    params: params,
  });
};
