import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { selectItemMoveRequest } from 'redux/itemMoveRequests/selectors';
import { fetchPallet, resetSelectedPallet } from 'redux/pallets/actions';
import {
  submitItemMoveRequest,
  updateItemMoveRequest,
  getItemMoveRequestCaseConversion,
  fetchSuggestedPallet,
  clearSuggestedPallet,
} from 'redux/itemMoveRequests/actions';
import { postGuidedPickingOverride } from 'redux/guidedPickingOverride/actions';

import styles from './styles';
import ViewPallet from './ViewPallet';

const mapStateToProps = (state, ownProps) => {
  return {
    signedInUser: state.session.user_name,
    itemMoveRequest: selectItemMoveRequest(
      state,
      ownProps.match.params.requestId
    ),
    hasItemMoveRequest: ownProps.match.params.requestId ? true : false,
    palletId: ownProps.match.params.palletId,
    pallet: state.pallets.inventoryApp.selectedPallet,
    suggestedPallet: state.itemMoveRequests.suggestedPallet,
    history: ownProps.history,
    fetchFailed: state.pallets.inventoryApp.fetchFailed,
    posting: state.itemMoveRequests.posting,
    postingFailed: state.itemMoveRequests.postingFailed,
    updating: state.itemMoveRequests.updating,
    updatingFailed: state.itemMoveRequests.updatingFailed,
  };
};

const mapDispatchToProps = {
  onFetchPallet: fetchPallet,
  onGetCaseConversion: getItemMoveRequestCaseConversion,
  onResetSelectedPallet: resetSelectedPallet,
  onSubmitItemMoveRequest: submitItemMoveRequest,
  onUpdateItemMoveRequest: updateItemMoveRequest,
  onFetchSuggestedPallet: fetchSuggestedPallet,
  onClearSuggestedPallet: clearSuggestedPallet,
  onConfirmOverride: postGuidedPickingOverride,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ViewPallet)
);
