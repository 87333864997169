import { TOP_BAR_UPDATE_CONTENT, TOP_BAR_RESET } from 'lib/constants';

const nulledOutState = {
  leftContent: null,
  middleContent: null,
  text: '',
  rightContent: null,
  backLocation: null,
};

export const setTopBarContent = (newState) => ({
  type: TOP_BAR_UPDATE_CONTENT,
  newState: { ...nulledOutState, ...newState },
});

export const resetTopBarToDefault = () => ({
  type: TOP_BAR_RESET,
});
