import { GREY_100, GREY_600 } from 'lib/constants';

import { header } from '../styles';

export default {
  collapseContainer: { padding: 0, borderBottom: 0 },
  row: {
    cursor: 'pointer',
  },
  title: {
    ...header,
    fontSize: '18px',
    fontWeight: 'bolder',
  },
  subheader: {
    ...header,
    fontSize: '16px',
    fontWeight: 'bolder',
  },
  info: {
    ...header,
    fontSize: '16px',
    fontWeight: 'bolder',
    color: GREY_600,
  },
  submission: {
    ...header,
    fontSize: '15px',
    fontWeight: 'normal',
  },
  icon: {
    float: 'right',
  },
  ingredientMealsDetailsContainer: {
    backgroundColor: GREY_100,
  },
};
