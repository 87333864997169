import { WHITE, GREY_200, BLUE } from 'lib/constants';

export const palletBannerStyles = {
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '2px',
  color: WHITE,
};

export default {
  icon: {
    width: '25px',
    fill: WHITE,
    display: 'inline-block',
    marginRight: '10px',
  },
  QRBanner: {
    backgroundColor: BLUE,
    ...palletBannerStyles,
  },
  greyBanner: {
    backgroundColor: GREY_200,
  },
};
