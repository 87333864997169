import { useCallback } from 'react';

import { hideNativeKeyboard } from 'lib/utils';

const useHiddenNativeKeyboard = (element, condition = true) => {
  return useCallback(() => {
    if (condition) {
      hideNativeKeyboard(element);
    }
  }, [element, condition]);
};

export default useHiddenNativeKeyboard;
