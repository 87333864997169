import React from 'react';
import PropTypes from 'prop-types';

import { QRCodeIcon } from 'components/icons';

const QrCodeContent = ({ classes }) => {
  return (
    <div className={classes.codeContainer}>
      <QRCodeIcon className={classes.icon} />
    </div>
  );
};

QrCodeContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default QrCodeContent;
