import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import isEmpty from 'lodash/isEmpty';

import { MIXING, COOKING, CHILLING } from 'lib/constants';

import styles from './styles';
import ProgressStepDetails from './ProgressStepDetails';

const useStyles = makeStyles(styles);

const IngredientDetails = ({ ingredient }) => {
  const classes = useStyles();

  const { goalCount, mixing, cooking, chilling } = ingredient;

  return (
    <div className={classes.progressStepsContainer}>
      {!isEmpty(mixing) && (
        <>
          <ProgressStepDetails
            title={MIXING.toUpperCase()}
            goalCount={goalCount}
            step={mixing}
          />
        </>
      )}
      {!isEmpty(cooking) && (
        <>
          <Divider className={classes.divider} />
          <ProgressStepDetails
            title={COOKING.toUpperCase()}
            goalCount={goalCount}
            step={cooking}
          />
        </>
      )}
      {!isEmpty(chilling) && (
        <>
          <Divider className={classes.divider} />
          <ProgressStepDetails
            title={CHILLING.toUpperCase()}
            goalCount={goalCount}
            step={chilling}
          />
        </>
      )}
    </div>
  );
};

IngredientDetails.propTypes = {
  ingredient: PropTypes.object.isRequired,
};

export default IngredientDetails;
