import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

const usePalletExceptionHandler = (
  itemMoveRequest = {},
  pallet = {},
  suggestedPallet = {}
) => {
  const [wrongItemModalOpen, setWrongItemModalOpen] = useState(false);
  const [wrongLotModalOpen, setWrongLotModalOpen] = useState(false);

  // this makes sure we aren't getting truthy statements for falsey == falsey
  // also that we don't show the modals when there is no associated item move request
  const allInfoPresent =
    Boolean(itemMoveRequest) &&
    Boolean(itemMoveRequest.requestableId) &&
    Boolean(itemMoveRequest.requestableType) &&
    Boolean(pallet) &&
    Boolean(pallet.itemId) &&
    Boolean(pallet.itemType) &&
    Boolean(pallet.id);

  const suggestedPalletPresent = !isEmpty(suggestedPallet);

  const suggestedPalletDoesNotMatchSelectedPallet =
    suggestedPalletPresent && pallet.id != suggestedPallet.id;

  // same item as requested, just not the suggested pallet
  const wrongLot =
    allInfoPresent &&
    suggestedPalletDoesNotMatchSelectedPallet &&
    itemMoveRequest.requestableId == pallet.itemId &&
    itemMoveRequest.requestableType == pallet.itemType;

  // completely different item from what was requested
  const wrongItem =
    allInfoPresent &&
    (itemMoveRequest.requestableId != pallet.itemId ||
      itemMoveRequest.requestableType != pallet.itemType);

  // must be wrapped in useEffect otherwise modal will be set to true once we submit
  useEffect(() => {
    if (wrongItem) {
      setWrongItemModalOpen(true);
    } else if (wrongLot) {
      setWrongLotModalOpen(true);
    }
  }, [wrongItem, wrongLot]);

  const closeModal = () => {
    setWrongItemModalOpen(false);
    setWrongLotModalOpen(false);
  };

  return {
    wrongLotModalOpen,
    wrongItemModalOpen,
    closeModal,
  };
};

export default usePalletExceptionHandler;
