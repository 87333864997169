import { options, handleResponse } from 'lib/homeChefApi';
import { formatDateHyphenated } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const createPallets = async ({
  netsuiteOrderItemId,
  pallets,
  productDate,
  productDateType,
  truckId,
  user,
  overageApprover = null,
  overageReason = null,
}) => {
  let formattedProductDate;

  if (productDate) {
    formattedProductDate = formatDateHyphenated(productDate);
  }

  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/netsuite_order_items/${netsuiteOrderItemId}/pallets`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        pallets: {
          pallet_data: pallets,
          product_date: formattedProductDate,
          product_date_type: productDateType,
          truck_id: truckId,
          user,
        },
        netsuite_order_item: {
          overage_approver: overageApprover,
          overage_reason: overageReason,
        },
      }),
    }
  );

  return await handleResponse(response);
};

const getPallets = async ({ netsuiteOrderItemId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/netsuite_order_items/${netsuiteOrderItemId}/pallets`,
    options
  );

  return await handleResponse(response);
};

const deletePallet = async ({ palletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}`,
    {
      ...options,
      method: 'DELETE',
    }
  );

  return await handleResponse(response);
};

export default {
  createPallets,
  getPallets,
  deletePallet,
};
