export default {
  dialog: {
    maxHeight: 'calc(100% - 76px)',
  },
  container: {
    fontFamily: 'Muli',
    minHeight: '824px',
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  errorLoading: {
    paddingTop: '115px',
    display: 'flex',
    justifyContent: 'center',
  },
  bottomContent: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridColumnGap: '5px',
    justifyItems: 'center',
    padding: '0px 12px',
  },
  buttons: {
    gridColumnStart: '2',
  },
  pageNumbers: {
    marginLeft: 'auto',
  },
  image: {
    width: '600px',
    marginTop: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padingLeft: '12px',
    alignItems: 'center',
  },
};
