import { ERROR_RED, WHITE } from 'lib/constants';

export default {
  deactivatedPalletBanner: {
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    color: WHITE,
    backgroundColor: ERROR_RED,
    justifyContent: 'left',
    paddingLeft: '10px',
    paddingTops: '2px',
    fontSize: '14px',
  },
};
