import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const DockSchedulerIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
      >
        <path
          d="M33.9,54.92V40.53a.89.89,0,0,0-.9-.89H14a.89.89,0,0,0-.9.89V54.92H6v7.54a.89.89,0,0,0,.89.89h4.57a.9.9,0,0,0,.9-.89V60.79H35v2a.9.9,0,0,0,.9.9h4.57a.9.9,0,0,0,.9-.9v-2h0V54.92Zm-4.3-2.28H17.44a.9.9,0,0,1,0-1.8H29.6a.9.9,0,0,1,0,1.8Zm0-4.08H17.44a.9.9,0,1,1,0-1.8H29.6a.9.9,0,0,1,0,1.8Zm0-4.12H17.44a.9.9,0,1,1,0-1.8H29.6a.9.9,0,0,1,0,1.8ZM6,17.32H41.19a1.61,1.61,0,0,1,1.61,1.61V53.51H41.25V45a2.2,2.2,0,0,0-2.19-2.19h-.77V19.34H35.88v7.22H33.72V23s-2-.72-9.85-.72S14,23,14,23v3.59H11.86V19.34H9.44V42.83H8.24A2.2,2.2,0,0,0,6,45v8.49H4.42V18.93A1.61,1.61,0,0,1,6,17.32Z"
          fill={HC_GREEN}
        />
        <path
          d="M13.88,35.71V31.2a.9.9,0,0,1,.9-.9H32.63a.89.89,0,0,1,.89.9v4.51a.89.89,0,0,1-.89.89H14.78A.89.89,0,0,1,13.88,35.71ZM36.37,51.13h2.31a.9.9,0,0,0,.9-.9V47.92a.9.9,0,0,0-.9-.9H36.37a.9.9,0,0,0-.9.9v2.31A.9.9,0,0,0,36.37,51.13Zm-27.68,0H11a.9.9,0,0,0,.9-.9V47.92A.9.9,0,0,0,11,47H8.69a.9.9,0,0,0-.9.9v2.31A.9.9,0,0,0,8.69,51.13Zm68-31.45V51.4a2,2,0,0,1-1.78,2.11H48.8A2,2,0,0,1,47,51.4V19.68a2,2,0,0,1,1.79-2.11H74.91A2,2,0,0,1,76.69,19.68Zm-16,10.46a1.72,1.72,0,0,0-1.56-1.85H53.68a1.87,1.87,0,0,0,0,3.69h5.44A1.71,1.71,0,0,0,60.68,30.14Zm10.91,0A1.73,1.73,0,0,0,70,28.29H64.58a1.87,1.87,0,0,0,0,3.69H70A1.72,1.72,0,0,0,71.59,30.14ZM77.93,6.48H2.48v5.57H77.93Zm0,61.07H2.48v5.57H77.93Z"
          fill={HC_GREEN}
          opacity="0.4"
        />
      </svg>
    </div>
  );
};

DockSchedulerIcon.propTypes = {
  className: PropTypes.string,
};

DockSchedulerIcon.defaultProps = {
  className: 'DockSchedulerIcon',
};

export default DockSchedulerIcon;
