import {
  GREY_200,
  GREY_800,
  BLACK,
  WHITE,
  HC_GREEN,
  DARK_HC_GREEN,
} from 'lib/constants';

const buttonDisabled = {
  backgroundColor: GREY_200,
};

export default {
  halfButton: {
    width: '47%',
    color: GREY_800,
    margin: '0px auto',
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '14px',
    '&:hover.Mui-disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
    '&.Mui-disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
  },
  cancel: {
    border: `1px solid ${GREY_200}`,
    background: WHITE,
  },
  buttonDisabled,
  submit: {
    border: 'none',
    background: HC_GREEN,
    color: WHITE,
    '&$buttonDisabled': buttonDisabled,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  buttonContainer: {
    display: 'contents',
  },
};
