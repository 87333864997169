import { WHITE } from 'lib/constants';

export default {
  root: {
    color: WHITE,
    marginLeft: 'auto',
    marginRight: '16px',
    display: 'flex',
    alignItems: 'center',
  },
};
