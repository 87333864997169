import React from 'react';
import PropTypes from 'prop-types';

const ArrowBackIcon = ({ width, height, fillColor, className }) => {
  return (
    <svg
      xmls="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={fillColor}
        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>
  );
};

ArrowBackIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

ArrowBackIcon.defaultProps = {
  width: '20px',
  height: '20px',
  fillColor: '#000000',
  className: '',
};

export default ArrowBackIcon;
