import { ERROR_RED, GREY_800, ROBOTO } from 'lib/constants';

export default {
  container: {
    margin: '24px',
  },
  error: {
    color: ERROR_RED,
    margin: '24px',
    fontFamily: ROBOTO,
    fontSize: '14px',
  },
  linkContainer: {
    display: 'block',
    borderBottom: 'none',
    paddingBottom: '10px',
  },
  menuWeeks: {
    fontFamily: ROBOTO,
    textTransform: 'uppercase',
    fontSize: '12px',
    paddingBottom: '24px',
    color: `${GREY_800}`,
  },
};
