import {
  HC_GREEN,
  DARK_RED,
  WHITE,
  GREY_800,
  GREY_600,
  GREY_200,
  BLUE,
} from 'lib/constants';

export default {
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circleIcon: {
    marginTop: '10px',
    color: HC_GREEN,
  },
  card: {
    margin: '5px',
  },
  header: {
    backgroundColor: DARK_RED,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    borderRadius: '5px 5px 0 0',
  },
  qrIcon: {
    width: '25px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  item: {
    fontWeight: 'bold',
  },
  lightText: {
    color: GREY_600,
  },
  secondLine: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chip: {
    fontWeight: 'bolder',
    height: '24px',
    float: 'right',
  },
  myChip: {
    backgroundColor: BLUE,
    color: WHITE,
  },
  line: {
    border: 0,
    borderTop: `1px solid ${GREY_200}`,
  },
  scanText: {
    marginLeft: '10px',
    display: 'inline-block',
    color: GREY_800,
  },
  laterButton: {
    textTransform: 'none',
    display: 'flex',
    margin: '10px',
  },
};
