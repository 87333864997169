import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { numberWithCommas } from 'lib/utils';

const PalletAuditLine = ({ classes, pallet, netsuiteItemUOM, grey }) => {
  return (
    <div
      className={classNames(classes.palletSummary, {
        [classes.grey]: grey,
        [classes.white]: !grey,
      })}
      data-testid="pallet-audit-line"
    >
      <span className={classes.palletSpanOne}>{pallet.id}</span>
      <span className={classes.palletSpanTwo}>{pallet.receivedAt}</span>
      <span
        className={classNames(classes.palletSpanOne, {
          [classes.differentUOM]: pallet.measuringUnit !== netsuiteItemUOM,
        })}
      >
        {pallet.measuringUnit}
      </span>
      <span
        className={classes.palletSpanTwo}
      >{`${pallet.productDateType} ${pallet.productDate}`}</span>
      <span className={classes.palletSpanOne}>
        {numberWithCommas(pallet.rejectedQuantity)}
      </span>
      <span className={classes.palletSpanOne}>
        {numberWithCommas(pallet.finalQuantity)}
      </span>
      <span className={classes.palletSpanOne}>{pallet.receiver}</span>
    </div>
  );
};

export const palletShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  receivedAt: PropTypes.string.isRequired,
  measuringUnit: PropTypes.string.isRequired,
  productDate: PropTypes.string.isRequired,
  productDateType: PropTypes.string.isRequired,
  receiver: PropTypes.string.isRequired,
  rejectedQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  finalQuantity: PropTypes.number.isRequired,
});

PalletAuditLine.propTypes = {
  classes: PropTypes.object.isRequired,
  netsuiteItemUOM: PropTypes.string.isRequired,
  pallet: palletShape.isRequired,
  grey: PropTypes.bool.isRequired,
};

export default PalletAuditLine;
