import { makeApiRequest } from 'lib/api';
import { PALLETS_PRINT } from 'lib/constants';

import Pallets from './services';

export const printPallets = (pallets, printer = null, firstPrint = false) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: PALLETS_PRINT,
        method: Pallets.printPallets,
        params: { pallets, printer, firstPrint },
      })
    );
  };
};

export * from './qaApp/actions';
export * from './inventoryApp/actions';
export * from './receivingApp/actions';
