import { connect } from 'react-redux';

import { addValueToFsqaReview } from 'redux/wmsFsqaReview/actions';

import ItemQuestions from './ItemQuestions';

const mapStateToProps = (state) => ({
  groupedAllergens: state.pallets.qaApp.groupedAllergens || {},
  allAllergens: state.pallets.qaApp.allAllergens,
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  updateItemQuestion: (field, value) =>
    dispatch(addValueToFsqaReview(ownProps.pallet.id, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemQuestions);
