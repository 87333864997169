import {
  PRODUCTION_PLANS_POST,
  PRODUCTION_PLANS,
  PRODUCTION_PLANS_LEAD_POST,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import ProductionPlans from './services';

export const postProductionPlans = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: PRODUCTION_PLANS_POST,
      method: ProductionPlans.postProductionPlans,
      params,
    })
  );
};

export const getProductionPlans = (params) => async (dispatch, getState) => {
  await dispatch(
    makeApiRequest({
      type: PRODUCTION_PLANS,
      method: ProductionPlans.getProductionPlans,
      params: {
        packingFacilityId: getState().session.packing_facility_id,
        ...params,
      },
    })
  );
};

export const postProductionPlansLead = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: PRODUCTION_PLANS_LEAD_POST,
      method: ProductionPlans.postProductionPlansLead,
      params,
    })
  );
};
