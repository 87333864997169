import { useEffect, useCallback, useState } from 'react';

import { usePrevious } from 'lib/custom_hooks';
import { formatTimeAsHHMM } from 'lib/utils';

export const useSummaryAuditShared = (
  onGetNetsuiteOrders,
  onUpdateNetsuiteItem
) => {
  const [currentUpdatingItem, setCurrentUpdatingItem] = useState('Item');

  useEffect(() => {
    onGetNetsuiteOrders();
  }, [onGetNetsuiteOrders]);

  const handleUpdateNetsuiteItem = useCallback(
    (itemId, status, itemName) => {
      onUpdateNetsuiteItem(itemId, status);
      setCurrentUpdatingItem(itemName);
    },
    [onUpdateNetsuiteItem]
  );

  const snackbarMessaging = {
    loading: `Updating ${currentUpdatingItem}...`,
    failure: 'Item Update Failed',
    success: 'Item Updated!',
  };

  return { snackbarMessaging, handleUpdateNetsuiteItem };
};

export const useTruckSummaryUpdater = ({
  field,
  truckUpdatingFailed,
  onUpdateTruck,
  truckId,
  backendFieldName,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  // Make copy of redux state so component doesn't flash old time while updating on backend
  const [value, setValue] = useState(field);
  const prevUpdateFailed = usePrevious(truckUpdatingFailed);

  useEffect(() => {
    if (truckUpdatingFailed && !prevUpdateFailed) {
      // But revert if backend change failed
      setValue(field);
    }
  }, [truckUpdatingFailed, prevUpdateFailed, field]);

  const handleTruckSummaryChange = useCallback(
    (val) => {
      setValue(val);

      if (backendFieldName === 'dock_out_time') {
        val = formatTimeAsHHMM(val);
      }

      onUpdateTruck({
        truck: { [backendFieldName]: val },
        id: truckId,
      });
    },
    [setValue, onUpdateTruck, truckId, backendFieldName]
  );
  return { value, handleTruckSummaryChange, modalOpen, setModalOpen };
};
