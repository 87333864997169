import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import flattenDeep from 'lodash/flattenDeep';

import { ENTER_KEY_CODE } from 'lib/constants';

const TruckOrderList = ({
  classes,
  todaysNOs,
  updateSelectedNOs,
  selectedOrders,
  ordersToRemove,
  searchNOs,
  onStoreSearchQuery,
  searchResultsNOs,
  clearSearch,
  queryTerm,
}) => {
  /**
   *
   * @type {function} - isASelectedNO
   * Determines if the current PO has been selected under a truck
   * @param {string} number - The netsuite number to find in all netsuite orders
   */
  const isASelectedNO = (number) => {
    if (selectedOrders) {
      return selectedOrders.find((po) => po.netsuiteOrderNumber === number);
    }
  };

  const validSearchQuery = useMemo(() => queryTerm.length > 3, [queryTerm]);
  /**
   *
   * @type {function} - netsuiteOrdersToDisplay
   * Checks to see if there are any search results and if so, display them, otherwise display
   * NOs from today
   *
   * @param {array} searchResultsNOs - Refers to the Netsuite Orders in the search result set
   * @param {array} todaysNOs - Refers to all Netsuite Orders expected today
   */

  const netsuiteOrdersToDisplay = useMemo(
    () => (validSearchQuery ? searchResultsNOs : todaysNOs),
    [searchResultsNOs, todaysNOs, validSearchQuery]
  );

  /**
   *
   * @type {function} - sortedNetsuitePurchaseOrders
   * Sorts Netsuite Orders by selected first and then NOs that haven't been selected
   *
   * @param {array} netsuiteOrdersToDisplay - Either the search result data set or the
   *                                         NOs expected today
   * @see #netsuiteOrdersToDisplay
   * @param {array} selectedOrders - Refers to all Netsuite Orders expected today
   * @return {array} A combination of netsuiteOrdersToDisplay and selectedOrders
   */
  const sortedNetsuitePurchaseOrders = useMemo(() => {
    let itemsSelected = netsuiteOrdersToDisplay.filter((order) =>
      selectedOrders
        .map((x) => x.netsuiteOrderNumber)
        .includes(order.netsuiteOrderNumber)
    );
    let itemsNotSelected = netsuiteOrdersToDisplay.filter(
      (order) =>
        !selectedOrders
          .map((x) => x.netsuiteOrderNumber)
          .includes(order.netsuiteOrderNumber)
    );
    let missingItems = selectedOrders.filter(
      (order) =>
        !netsuiteOrdersToDisplay
          .map((x) => x.netsuiteOrderNumber)
          .includes(order.netsuiteOrderNumber)
    );
    itemsSelected.push(missingItems);
    itemsSelected.push(itemsNotSelected);
    return flattenDeep(itemsSelected);
  }, [netsuiteOrdersToDisplay, selectedOrders]);

  /**
   *
   * @type {function} - netsuiteOrderToggle
   * Finds the selected and unselected netsuite orders and adds or removes from the arrays
   * depending on if it is found in the selected array
   *
   */
  const netsuiteOrderToggle = (po) => {
    const selectedNOs = [...selectedOrders];
    const poToRemove = [...ordersToRemove];

    const selectedNOIndex = selectedNOs.findIndex(
      (selectedNO) => selectedNO.netsuiteOrderNumber === po.netsuiteOrderNumber
    );
    const removeNOIndex = poToRemove.findIndex(
      (removeNO) => removeNO.netsuiteOrderNumber === po.netsuiteOrderNumber
    );

    const massageData = (arrayToAdd, arrayToRemove, selectedIndex) => {
      let selectedNo = [];
      if (selectedIndex !== -1) {
        selectedNo = arrayToRemove.splice(selectedIndex, 1);
      }
      let currentNO =
        selectedNo.length > 0
          ? selectedNo[0]
          : {
              truckNetsuiteOrderId: null,
              id: po.id,
              vendor: po.vendor,
              netsuiteOrderNumber: po.netsuiteOrderNumber,
            };
      arrayToAdd.push(currentNO);
    };

    // If we find the index then we are toggling off (remove from selected Pos)
    if (selectedNOIndex !== -1) {
      massageData(poToRemove, selectedNOs, selectedNOIndex);
    } else {
      massageData(selectedNOs, poToRemove, removeNOIndex);
    }
    updateSelectedNOs(selectedNOs, poToRemove);
  };

  const handleInputKeydown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      searchNOs();
      e.target.blur();
    }
  };

  const handleInputChange = (e) => {
    let query = e.target.value;
    onStoreSearchQuery(query);
    if (query.length >= 3) {
      searchNOs();
    } else {
      clearSearch();
    }
  };

  return (
    <div className={classes.truckOrderContainer}>
      <span className={classes.infoLabel}>ADD PO</span>
      <div className={classes.infoValue}>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          autoComplete="off"
          placeholder="Search..."
          autofocusname="query"
          data-testid="truck-order-list-search"
          onChange={handleInputChange}
          onKeyDown={handleInputKeydown}
          inputProps={{ className: classes.input }}
        />
        {sortedNetsuitePurchaseOrders.length > 0 ? (
          <List className={classes.list} component="nav">
            {sortedNetsuitePurchaseOrders.map((po) => (
              <ListItem
                key={po.id}
                button
                onClick={() => netsuiteOrderToggle(po)}
                className={classNames(classes.listItem, {
                  [classes.selected]: isASelectedNO(po.netsuiteOrderNumber),
                })}
              >
                <ListItemText className={classes.listItemText}>
                  <div className={classes.text}>{po.netsuiteOrderNumber}</div>
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  <div className={classes.text}>{po.vendor}</div>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        ) : (
          <p className={classes.sorryContainer}>No Purchase Orders Found</p>
        )}
      </div>
    </div>
  );
};

TruckOrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  todaysNOs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      netsuiteOrderNumber: PropTypes.string.isRequired,
      vendor: PropTypes.string.isRequired,
    })
  ).isRequired,
  updateSelectedNOs: PropTypes.func.isRequired,
  selectedOrders: PropTypes.array.isRequired,
  searchNOs: PropTypes.func.isRequired,
  onStoreSearchQuery: PropTypes.func.isRequired,
  ordersToRemove: PropTypes.array.isRequired,
  searchResultsNOs: PropTypes.array.isRequired,
  clearSearch: PropTypes.func.isRequired,
  queryTerm: PropTypes.string.isRequired,
};

export default TruckOrderList;
