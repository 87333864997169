import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import classnames from 'classnames';

import buttonGroupTheme from 'lib/buttonGroupTheme';
import { ThumbsDownIcon, ThumbsUpIcon } from 'components/icons';
import { setTopBarContent } from 'redux/topBar/actions';
import { WHITE, ERROR_RED, BACK_BUTTON, QA_APP } from 'lib/constants';
import { qaCheckFormStyles } from 'components/shared';

import TakePhotoButton from './TakePhotoButton';
import IngredientInfo from './IngredientInfo';
import PortioningDialog from '../PortioningDialog';

const defectNames = {
  labeling: ['Incorrect', 'Misplaced', 'Missing', 'Labeling Other'],
  portioning: ['Over Portioned', 'Under Portioned', 'Foreign Object'],
  packaging: ['Wrong', 'Damaged/Broken', 'Packaging Other'],
};

const MAX_NUMBER_OF_CHECKS = 20;
const MAX_NUMBER_OF_FLUID_OZ_CHECKS = 5;

const useStyles = makeStyles(qaCheckFormStyles);

const QaCheckForm = ({
  qaPortionChecks,
  onPostQaPortionCheck,
  onUpdateQaPortionChecks,
  location,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [mainIssues, setIssues] = useState([]);
  const [qualityIssues, setQualityIssues] = useState([]);
  const [labelingIssues, setLabelingIssues] = useState([]);
  const [portioningIssues, setPortioningIssues] = useState([]);
  const [packagingIssues, setPackagingIssues] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [open, setOpen] = useState(false);

  const { checksComplete, postFailed, postErrorText } = useSelector(
    (state) => state.qaPortionChecks
  );

  const { meal, ingredient, preliminaryCheckId } = location.state;
  const checkNumber = qaPortionChecks.checks.length + 1;

  const maxNumberOfChecks =
    ingredient.unitOfMeasure == 'fluid_oz'
      ? MAX_NUMBER_OF_FLUID_OZ_CHECKS
      : MAX_NUMBER_OF_CHECKS;

  useEffect(() => {
    setTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: QA_APP,
      text: `${meal.letterAssignment}: ${meal.title}`,
    });
  }, [meal, setTopBarContent, onUpdateQaPortionChecks, postFailed]);

  const handleIssueChange = (_, newIssues) => {
    setIssues(newIssues);

    if (!newIssues.includes('quality')) {
      setQualityIssues([]);
      setPhoto(null);
    }

    if (!newIssues.includes('labeling')) {
      setLabelingIssues([]);
    }

    if (!newIssues.includes('portioning')) {
      setPortioningIssues([]);
    }

    if (!newIssues.includes('packaging')) {
      setPackagingIssues([]);
    }
  };

  const updateQualityIssues = (_, newQualityIssues) => {
    setQualityIssues(newQualityIssues);
  };

  const needsSubIssueChosen = () => {
    return (
      (mainIssues.includes('quality') && qualityIssues.length === 0) ||
      (mainIssues.includes('labeling') && labelingIssues.length === 0) ||
      (mainIssues.includes('portioning') && portioningIssues.length === 0) ||
      (mainIssues.includes('packaging') && packagingIssues.length === 0)
    );
  };

  const issueCount = () => {
    return qualityIssues.concat(
      labelingIssues,
      portioningIssues,
      packagingIssues
    ).length;
  };

  const submitCheck = (issues) => {
    onPostQaPortionCheck(
      issues,
      preliminaryCheckId,
      checkNumber,
      mainIssues,
      photo,
      maxNumberOfChecks
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonText = () => {
    const errorCount = issueCount();
    if (qaPortionChecks.posting) {
      return 'Submitting...';
    } else if (postFailed) {
      return postErrorText || 'Post Failed';
    } else if (needsSubIssueChosen()) {
      return 'Select Issues';
    } else if (errorCount > 0) {
      return `Submit ${errorCount} ${errorCount === 1 ? 'Error' : 'Errors'}`;
    } else if (checkNumber >= maxNumberOfChecks) {
      return 'Complete Checks';
    } else {
      return (
        <span>
          No Errors: Next Check <ThumbsUpIcon color={WHITE} />
        </span>
      );
    }
  };

  const numberErrors = qaPortionChecks.checks.filter(
    (check) => check.pass === false
  ).length;
  const checkCount = qaPortionChecks.checks.length;

  let activateDialog;
  if (checksComplete && numberErrors > 0) {
    activateDialog = true;
  } else {
    activateDialog = open;
  }

  if (checksComplete && numberErrors === 0) {
    return (
      <Redirect
        to={{
          pathname: '/qa/portioning',
          state: {
            snackBarActivated: true,
            checkCount: checkCount,
          },
        }}
      />
    );
  } else {
    return (
      <Fragment>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={buttonGroupTheme}>
            <IngredientInfo ingredient={ingredient} />
            <div className={classes.mainIssues}>
              <div>
                <h2 className={classes.floatLeft}>
                  <span className={classes.headerBold}>
                    Check{' '}
                    {checkNumber > maxNumberOfChecks
                      ? maxNumberOfChecks
                      : checkNumber}
                  </span>
                  <span className={classes.headerItalic}> of</span>
                  <span> {maxNumberOfChecks}</span>
                </h2>
                <Button
                  className={classnames(
                    classes.floatRight,
                    classes.endChecksButton
                  )}
                  data-testid="endCheck"
                  size="large"
                  variant="outlined"
                  onClick={handleClickOpen}
                  color="neutral"
                >
                  End Checks
                </Button>
                <PortioningDialog
                  numberErrors={numberErrors}
                  checkCount={checkCount}
                  open={activateDialog}
                  handleClose={handleClose}
                  history={history}
                />
              </div>

              <div className={classes.mainIssuesButtons}>
                <h2 className={classes.headerBold}>Main Issues</h2>
                <ToggleButtonGroup
                  className={classes.fourButtonGroup}
                  size="large"
                  value={mainIssues}
                  onChange={handleIssueChange}
                >
                  {['quality', 'labeling', 'portioning', 'packaging'].map(
                    (issueType) => (
                      <ToggleButton
                        key={issueType}
                        className={classnames([
                          classes.buttonOne,
                          classes.button,
                        ])}
                        data-testid={`toggle-${issueType}`}
                        value={issueType}
                      >
                        {issueType}
                        <ThumbsDownIcon
                          color={
                            mainIssues.includes(issueType) ? WHITE : ERROR_RED
                          }
                        />
                      </ToggleButton>
                    )
                  )}
                </ToggleButtonGroup>
              </div>
            </div>

            <div className={classes.subQuestions}>
              <div
                className={classnames({
                  [classes.hidden]: !mainIssues.includes('quality'),
                })}
              >
                <h2 className={classes.headerBold}>Quality Issues</h2>
                <ToggleButtonGroup
                  className={classes.threeButtonGroup}
                  size="large"
                  value={qualityIssues}
                  onChange={updateQualityIssues}
                >
                  {ingredient.defectCategories.map((defect) => {
                    return (
                      <ToggleButton
                        className={classes.button}
                        key={`quality-${defect.name}`}
                        value={defect.id}
                      >
                        {defect.name}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>

              <div
                className={classnames({
                  [classes.hidden]: !mainIssues.includes('labeling'),
                })}
              >
                <h2 className={classes.headerBold}>Labeling Issues</h2>
                <ToggleButtonGroup
                  className={classes.fourButtonGroup}
                  size="large"
                  value={labelingIssues}
                  onChange={(_, newLabelingIssues) =>
                    setLabelingIssues(newLabelingIssues)
                  }
                >
                  {defectNames.labeling.map((defectName) => {
                    return (
                      <ToggleButton
                        className={classes.button}
                        key={`labeling-${defectName}`}
                        value={defectName}
                      >
                        {defectName}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>

              <div
                className={classnames({
                  [classes.hidden]: !mainIssues.includes('portioning'),
                })}
              >
                <h2 className={classes.headerBold}>Portioning Issues</h2>
                <ToggleButtonGroup
                  className={classes.threeButtonGroup}
                  size="large"
                  value={portioningIssues}
                  onChange={(_, newPortioningIssues) =>
                    setPortioningIssues(newPortioningIssues)
                  }
                >
                  {defectNames.portioning.map((defectName) => {
                    return (
                      <ToggleButton
                        className={classes.button}
                        key={`portioning-${defectName}`}
                        value={defectName}
                      >
                        {defectName}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>

              <div
                className={classnames({
                  [classes.hidden]: !mainIssues.includes('packaging'),
                })}
              >
                <h2 className={classes.headerBold}>Packaging Issues</h2>
                <ToggleButtonGroup
                  className={classes.threeButtonGroup}
                  size="large"
                  value={packagingIssues}
                  onChange={(_, newPackagingIssues) =>
                    setPackagingIssues(newPackagingIssues)
                  }
                >
                  {defectNames.packaging.map((defectName) => {
                    return (
                      <ToggleButton
                        className={classes.button}
                        key={`packaging-${defectName}`}
                        value={defectName}
                      >
                        {defectName}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>

              {qualityIssues.length > 0 ? (
                <TakePhotoButton setPhoto={setPhoto} />
              ) : null}

              <div>
                <Button
                  fullWidth
                  disabled={needsSubIssueChosen() || qaPortionChecks.posting}
                  className={classnames({
                    [classes.submitButtonError]: postFailed,
                    [classes.submitButton]: true,
                  })}
                  data-testid="submitCheck"
                  value="submit"
                  type="button"
                  onClick={() =>
                    submitCheck({
                      qualityIssues,
                      portioningIssues,
                      labelingIssues,
                      packagingIssues,
                    })
                  }
                >
                  {buttonText()}
                </Button>
              </div>
            </div>
          </ThemeProvider>
        </StyledEngineProvider>
      </Fragment>
    );
  }
};

QaCheckForm.propTypes = {
  onPostQaPortionCheck: PropTypes.func.isRequired,
  onUpdateQaPortionChecks: PropTypes.func.isRequired,
  qaPortionChecks: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default QaCheckForm;
