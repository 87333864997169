import {
  ITEM_MOVE_REQUESTS_GET_REQUEST,
  ITEM_MOVE_REQUESTS_GET_SUCCESS,
  ITEM_MOVE_REQUESTS_GET_ERROR,
  ITEM_MOVE_REQUEST_POST_REQUEST,
  ITEM_MOVE_REQUEST_POST_SUCCESS,
  ITEM_MOVE_REQUEST_POST_ERROR,
  ITEM_MOVE_REQUEST_PUT_REQUEST,
  ITEM_MOVE_REQUEST_PUT_SUCCESS,
  ITEM_MOVE_REQUEST_PUT_ERROR,
  ITEM_MOVE_REQUEST_UPDATE,
  RESET_STORAGE_SLOTS,
  STORAGE_SLOT_DEPTHS_SUCCESS,
  STORAGE_SLOT_DEPTHS_REQUEST,
  UPDATE_MOVE_REQUEST_FILTERS,
  HISTORICAL_ITEM_MOVE_REQUESTS_GET_REQUEST,
  HISTORICAL_ITEM_MOVE_REQUESTS_GET_SUCCESS,
  HISTORICAL_ITEM_MOVE_REQUESTS_GET_ERROR,
  ITEM_MOVE_REQUESTS_QUERY_GET_REQUEST,
  ITEM_MOVE_REQUESTS_QUERY_GET_SUCCESS,
  ITEM_MOVE_REQUESTS_QUERY_GET_ERROR,
  ITEM_MOVE_REQUESTS_CLEAR_SEARCH,
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_REQUEST,
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_SUCCESS,
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_ERROR,
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_REQUEST,
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_SUCCESS,
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_ERROR,
  GUIDED_PICKING_OVERRIDE_POST_SUCCESS,
  ITEM_MOVE_REQUEST_CLEAR_SUGGESTED_PALLET,
  PRINT_CHILD_LICENSE_PLATE_POST_REQUEST,
  PRINT_CHILD_LICENSE_PLATE_POST_SUCCESS,
  PRINT_CHILD_LICENSE_PLATE_POST_ERROR,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  fetchingFailed: false,
  itemMoveRequests: [],
  posting: false,
  postingFailed: false,
  updating: false,
  updatingFailed: false,
  depthOptions: [],
  filters: {
    pickUpAreas: [],
    dropOffAreas: [],
    pickerUppers: [],
    pickUpArea: '',
    dropList: '',
  },
  fetchingHistoricals: false,
  fetchingHistoricalsFailed: false,
  allHistoricalsLoaded: false,
  fetchingSearchResults: false,
  fetchingSearchResultsFailed: false,
  searchResults: [],
  fetchingCaseConversion: false,
  fetchingCaseConversionFailed: false,
  fetchingSuggestedPallet: false,
  fetchingSuggestedPalletFailed: false,
  postingGuidedPickingOverride: false,
  postingGuidedPickingOverrideFailed: false,
  suggestedPallet: null,
  postingPrintChildLicensePlate: false,
  postingPrintChildLicensePlateFailed: false,
};

const addOrReplace = (currentItemMoves, newItemMoves) => {
  const updatedItemMoves = [...currentItemMoves];

  newItemMoves.forEach((newItemMove) => {
    const index = updatedItemMoves.findIndex(
      (itemMove) => itemMove.id === newItemMove.id
    );

    if (index > -1) {
      updatedItemMoves[index] = newItemMove;
    } else {
      updatedItemMoves.push(newItemMove);
    }
  });

  return updatedItemMoves;
};

const updateItemMoveRequest = (
  currentItemMoveRequests,
  updatedItemMoveRequestId,
  requestedCases
) => {
  return currentItemMoveRequests.map((request) => {
    if (request.id !== updatedItemMoveRequestId) {
      return request;
    } else {
      return { ...request, requestedCases };
    }
  });
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case ITEM_MOVE_REQUESTS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingFailed: false,
        itemMoveRequests: [],
      };
    case ITEM_MOVE_REQUESTS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        itemMoveRequests: action.response.itemMoveRequests,
      };
    case ITEM_MOVE_REQUESTS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        fetchingFailed: true,
      };
    case ITEM_MOVE_REQUEST_POST_REQUEST:
      return {
        ...state,
        posting: true,
        postingFailed: false,
      };
    case ITEM_MOVE_REQUEST_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        postingFailed: false,
        itemMoveRequests: action.response.itemMoveRequests,
      };
    case ITEM_MOVE_REQUEST_POST_ERROR:
      return {
        ...state,
        posting: false,
        postingFailed: true,
      };
    case ITEM_MOVE_REQUEST_PUT_REQUEST:
      return {
        ...state,
        updating: true,
        updatingFailed: false,
      };
    case ITEM_MOVE_REQUEST_PUT_SUCCESS:
      return {
        ...state,
        updating: false,
        itemMoveRequests: action.response.itemMoveRequests,
      };
    case ITEM_MOVE_REQUEST_PUT_ERROR:
      return {
        ...state,
        updating: false,
        updatingFailed: true,
      };
    case ITEM_MOVE_REQUEST_UPDATE:
      return {
        ...state,
        itemMoveRequests: addOrReplace(state.itemMoveRequests, [
          action.payload.itemMoveRequest,
        ]),
      };
    case RESET_STORAGE_SLOTS:
      return { ...state, depthOptions: [] };
    case STORAGE_SLOT_DEPTHS_SUCCESS:
      return {
        ...state,
        depthOptions: action.response.storageSlots,
      };
    case STORAGE_SLOT_DEPTHS_REQUEST:
      return {
        ...state,
        depthOptions: [],
      };
    case UPDATE_MOVE_REQUEST_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.filters },
      };
    case HISTORICAL_ITEM_MOVE_REQUESTS_GET_REQUEST:
      return {
        ...state,
        fetchingHistoricals: true,
        fetchingHistoricalsFailed: false,
      };
    case HISTORICAL_ITEM_MOVE_REQUESTS_GET_SUCCESS:
      return {
        ...state,
        fetchingHistoricals: false,
        fetchingHistoricalsFailed: false,
        itemMoveRequests: addOrReplace(
          state.itemMoveRequests,
          action.response.itemMoveRequests
        ),
        allHistoricalsLoaded:
          state.itemMoveRequests.filter((r) => r.droppedOff || r.cancelled)
            .length === action.response.itemMoveRequests.length,
      };
    case HISTORICAL_ITEM_MOVE_REQUESTS_GET_ERROR:
      return {
        ...state,
        fetchingHistoricals: false,
        fetchingHistoricalsFailed: true,
      };
    case ITEM_MOVE_REQUESTS_QUERY_GET_REQUEST:
      return {
        ...state,
        fetchingSearchResults: true,
      };
    case ITEM_MOVE_REQUESTS_QUERY_GET_SUCCESS:
      return {
        ...state,
        fetchingSearchResults: false,
        searchResults: action.response.searchResults,
      };
    case ITEM_MOVE_REQUESTS_QUERY_GET_ERROR:
      return {
        ...state,
        fetchingSearchResults: false,
        fetchingSearchResultsFailed: true,
      };
    case ITEM_MOVE_REQUESTS_CLEAR_SEARCH:
      return {
        ...state,
        searchResults: [],
      };
    case ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_REQUEST:
      return {
        ...state,
        fetchingCaseConversion: true,
      };
    case ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_SUCCESS:
      return {
        ...state,
        fetchingCaseConversion: false,
        itemMoveRequests: updateItemMoveRequest(
          state.itemMoveRequests,
          action.response.itemMoveRequestId,
          action.response.requestedCases
        ),
      };
    case ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_ERROR:
      return {
        ...state,
        fetchingCaseConversion: false,
        fetchingCaseConversionFailed: true,
      };
    case ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_REQUEST:
      return {
        ...state,
        fetchingSuggestedPallet: true,
        fetchingSuggestedPalletFailed: false,
      };
    case ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_SUCCESS:
      return {
        ...state,
        fetchingSuggestedPallet: false,
        suggestedPallet: action.response.suggestedPallet,
      };
    case ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_ERROR:
      return {
        ...state,
        fetchingSuggestedPallet: false,
        fetchingSuggestedPalletFailed: true,
      };
    case GUIDED_PICKING_OVERRIDE_POST_SUCCESS:
      return {
        ...state,
        suggestedPallet: action.response.suggestedPallet,
      };
    case ITEM_MOVE_REQUEST_CLEAR_SUGGESTED_PALLET:
      return {
        ...state,
        suggestedPallet: null,
      };
    case PRINT_CHILD_LICENSE_PLATE_POST_REQUEST:
      return {
        ...state,
        postingPrintChildLicensePlate: true,
        postingPrintChildLicensePlateFailed: false,
      };
    case PRINT_CHILD_LICENSE_PLATE_POST_ERROR:
      return {
        ...state,
        postingPrintChildLicensePlate: false,
        postingPrintChildLicensePlateFailed: true,
      };
    case PRINT_CHILD_LICENSE_PLATE_POST_SUCCESS:
      return {
        ...state,
        postingPrintChildLicensePlate: false,
        postingPrintChildLicensePlateFailed: false,
      };
    default:
      return state;
  }
}
