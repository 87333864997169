import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import { percentProgress, formatNumber } from 'lib/utils';

import PercentProgress from './PercentProgress';
import styles from './styles';

const MealRow = ({ meal, index, classes, isPartOfStandardMenu }) => {
  const completedOverGoalCount = (remainingCount, goalCount) => {
    if (goalCount > 0) {
      return (
        <div>
          <span className={classes.completedCount}>
            {formatNumber(goalCount - remainingCount)}
          </span>
          <span>/</span>
          <span className={classes.goalCount}>{formatNumber(goalCount)}</span>
        </div>
      );
    } else {
      return null;
    }
  };
  const oddOrEvenClass = index % 2 === 0 ? 'evenCell' : 'oddCell';
  const alignedRightCellProps = {
    className: classnames(classes.alignRight, classes[oddOrEvenClass]),
    item: true,
  };

  const alignedCenterCellProps = {
    className: classnames(classes.alignCenter, classes[oddOrEvenClass]),
    item: true,
  };

  const formatMealTitle = () => {
    if (meal.title.length < 38) {
      return meal.title;
    } else {
      return `${meal.title.slice(0, 28).replace(/\s*$/, '')}...`;
    }
  };

  return (
    <React.Fragment>
      <Grid
        xs={1}
        item
        className={classnames(
          classes.alignCenter,
          classes[oddOrEvenClass],
          classes.labelOrderCell
        )}
      >
        {meal.label_order}
      </Grid>
      <Grid item xs={2} className={classes[oddOrEvenClass]}>
        {formatMealTitle()}
      </Grid>
      <Grid xs={2} {...alignedRightCellProps}>
        {completedOverGoalCount(
          meal.remainingLabelingCount,
          meal.labelingGoalCount
        )}
      </Grid>
      <Grid xs={1} {...alignedCenterCellProps}>
        <PercentProgress
          visible={meal.labelingGoalCount > 0}
          percent={percentProgress(
            meal.labelingGoalCount,
            meal.remainingLabelingCount,
            true
          )}
          percentTarget={meal.labelingEndOfShiftTarget}
        />
      </Grid>
      <Grid xs={2} {...alignedRightCellProps}>
        {completedOverGoalCount(
          meal.remainingPortioningCount,
          meal.portioningGoalCount
        )}
      </Grid>
      <Grid xs={1} {...alignedCenterCellProps}>
        <PercentProgress
          visible={meal.portioningGoalCount > 0}
          percent={percentProgress(
            meal.portioningGoalCount,
            meal.remainingPortioningCount,
            true
          )}
          percentTarget={meal.portioningEndOfShiftTarget}
        />
      </Grid>

      {isPartOfStandardMenu ? (
        <>
          <Grid item xs={2} {...alignedRightCellProps}>
            {completedOverGoalCount(meal.remaining_count, meal.goal_count)}
          </Grid>
          <Grid xs={1} {...alignedCenterCellProps}>
            <PercentProgress
              visible={meal.goal_count > 0}
              percent={percentProgress(
                meal.goal_count,
                meal.remaining_count,
                true
              )}
              percentTarget={meal.end_of_shift_percent_target}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            xs={3}
            {...alignedCenterCellProps}
            id="meal-part-of-extended-menus"
            data-testid="meal-part-of-extended-menus"
          >
            N/A
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

MealRow.propTypes = {
  classes: PropTypes.object.isRequired,
  meal: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(MealRow);
