import {
  HC_GREEN,
  WHITE,
  DARK_HC_GREEN,
  ERROR_RED,
  DARK_RED,
} from 'lib/constants';

import { infoLabel, infoValue, disabledField } from '../FsqaReviewForms/styles';

export default {
  infoLabel,
  infoValue,
  disabledField,
  button: {
    height: '55px',
    width: '80px',
    marginRight: '10px',
  },
  selectedYes: {
    backgroundColor: HC_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  selectedNo: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_RED,
    },
  },
  textField: {
    margin: '0px',
    minWidth: '145px',
  },
  errorText: {
    marginTop: '5px',
    color: ERROR_RED,
  },
};
