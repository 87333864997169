import React, { Fragment } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const PrinterQuestion = ({
  printers,
  selectedPrinter,
  setSelectedPrinter,
  visible,
  classes,
}) => {
  if (visible) {
    return (
      <Fragment>
        <h3 className={classes.question}>Choose a Printer</h3>
        <ToggleButtonGroup
          data-testid="printer-question"
          exclusive
          className={classes.buttonGroup}
          size="large"
          value={selectedPrinter}
          onChange={(_, newPrinter) => setSelectedPrinter(newPrinter)}
        >
          {printers.map((opsPrinter) => {
            return (
              <ToggleButton
                className={classnames(classes.button, classes.printerButton)}
                data-testid={`printer-${opsPrinter.id}`}
                key={opsPrinter.id}
                value={opsPrinter.id}
              >
                {opsPrinter.name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Fragment>
    );
  } else {
    return null;
  }
};

PrinterQuestion.propTypes = {
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedPrinter: PropTypes.number,
  setSelectedPrinter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

PrinterQuestion.defaultProps = {
  selectedPrinter: null,
};

export default withStyles(styles)(PrinterQuestion);
