import React from 'react';
import PropTypes from 'prop-types';

const RthProgressTrackerIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
      >
        <rect width="48" height="48" fill="white" />
        <path
          d="M27 9.25C27 9.94034 27.5597 10.5 28.25 10.5C28.9403 10.5 29.5 9.94034 29.5 9.25C29.5 8.55966 28.9403 8 28.25 8C27.5597 8 27 8.55966 27 9.25Z"
          fill="#99CE99"
        />
        <path
          d="M30.25 15.25C29.5597 15.25 29 14.6903 29 14C29 13.3097 29.5597 12.75 30.25 12.75C30.9403 12.75 31.5 13.3097 31.5 14C31.5 14.6903 30.9403 15.25 30.25 15.25Z"
          fill="#99CE99"
        />
        <path d="M14 27H34V38H14V27Z" fill="#99CE99" />
        <path
          d="M24 9C24.6903 9 25.25 8.44034 25.25 7.75C25.25 7.05966 24.6903 6.5 24 6.5C23.3097 6.5 22.75 7.05966 22.75 7.75C22.75 8.44034 23.3097 9 24 9Z"
          fill="#004400"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 27C2.75 26.3096 3.30964 25.75 4 25.75H44C44.6904 25.75 45.25 26.3096 45.25 27C45.25 27.6904 44.6904 28.25 44 28.25H42.625L41.5571 34.6576C41.2356 36.5863 39.5668 38 37.6115 38H10.3885C8.43315 38 6.76439 36.5863 6.44293 34.6576L5.375 28.25H4C3.30964 28.25 2.75 27.6904 2.75 27ZM37.6115 35.5H35.25V28.5H40.0489L39.0911 34.2466C38.9705 34.9699 38.3447 35.5 37.6115 35.5ZM15.25 35.5V28.5H32.75V35.5H15.25ZM12.75 28.5V35.5H10.3885C9.65525 35.5 9.02946 34.9699 8.90892 34.2466L7.95115 28.5H12.75Z"
          fill="#004400"
        />
        <path
          d="M24 19C23.3097 19 22.75 19.5597 22.75 20.25C22.75 20.9403 23.3097 21.5 24 21.5C24.6903 21.5 25.25 20.9403 25.25 20.25C25.25 19.5597 24.6903 19 24 19Z"
          fill="#004400"
        />
        <path
          d="M18.6967 17.5355C19.1848 17.0474 19.9763 17.0474 20.4645 17.5355C20.9526 18.0237 20.9526 18.8152 20.4645 19.3033C19.9763 19.7914 19.1848 19.7914 18.6967 19.3033C18.2086 18.8152 18.2086 18.0237 18.6967 17.5355Z"
          fill="#004400"
        />
        <path
          d="M19 14C19 13.3097 18.4403 12.75 17.75 12.75C17.0597 12.75 16.5 13.3097 16.5 14C16.5 14.6903 17.0597 15.25 17.75 15.25C18.4403 15.25 19 14.6903 19 14Z"
          fill="#004400"
        />
        <path
          d="M27.5355 17.5355C27.0474 18.0237 27.0474 18.8152 27.5355 19.3033C28.0237 19.7914 28.8152 19.7914 29.3033 19.3033C29.7915 18.8152 29.7915 18.0237 29.3033 17.5355C28.8152 17.0474 28.0237 17.0474 27.5355 17.5355Z"
          fill="#004400"
        />
        <path
          d="M20.4645 10.4645C20.9526 9.97632 20.9526 9.18484 20.4645 8.6967C19.9763 8.20855 19.1848 8.20855 18.6967 8.6967C18.2086 9.18484 18.2086 9.97632 18.6967 10.4645C19.1848 10.9526 19.9763 10.9526 20.4645 10.4645Z"
          fill="#004400"
        />
        <path
          d="M23.4332 15.5668C23.5551 15.6887 23.715 15.7499 23.875 15.7499C24.035 15.7499 24.195 15.6887 24.3169 15.5668L26.8167 13.067C27.0611 12.8226 27.0611 12.4276 26.8167 12.1833C26.5724 11.9389 26.1774 11.9389 25.933 12.1833L23.875 14.2413L23.067 13.4332C22.8226 13.1888 22.4276 13.1888 22.1833 13.4332C21.9389 13.6776 21.9389 14.0725 22.1833 14.3169L23.4332 15.5668Z"
          fill="#004400"
        />
      </svg>
    </div>
  );
};

RthProgressTrackerIcon.propTypes = {
  className: PropTypes.string,
};

RthProgressTrackerIcon.defaultProps = {
  className: 'RthProgressTrackerIcon',
};

export default RthProgressTrackerIcon;
