import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useRotationTypeValidations } from 'lib/custom_hooks';
import { datePickerReceivingStyles } from 'lib/dataTableTheme';
import submitButtonTheme from 'lib/submitButtonTheme';
import { WAREHOUSE_SHELF_LIFE_TYPES } from 'lib/constants';
import productDateFns from 'lib/utils/productDateFns';
import { StatefulButton } from 'components/shared';

const EditProductDate = ({
  pallet,
  setProductDateType,
  productDateType,
  setProductDate,
  productDate,
  updatePalletProductDate,
}) => {
  const customDateFns = useMemo(() => productDateFns(pallet), [pallet]);

  const buttonDisabled =
    !productDate ||
    (pallet.productDateType === productDateType &&
      pallet.productDate === productDate);

  const {
    disableFutureDates,
    disablePastDates,
    onRotationTypeChange,
  } = useRotationTypeValidations(
    productDate,
    productDateType,
    setProductDate,
    setProductDateType,
    pallet.createdAt
  );

  return (
    <div data-testid="edit-product-date">
      <TextField
        id="product-date-code"
        label="Product Date Code"
        name="productDateCode"
        variant="outlined"
        margin="normal"
        value={productDateType}
        select
        fullWidth
        onChange={onRotationTypeChange}
      >
        {WAREHOUSE_SHELF_LIFE_TYPES.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={datePickerReceivingStyles}>
          <LocalizationProvider dateAdapter={customDateFns}>
            <MobileDatePicker
              id="date"
              type="string"
              inputFormat="MM/dd/yyyy"
              label="Product Date"
              emptyLabel=""
              value={productDate}
              onChange={(date) => setProductDate(date)}
              disableFuture={disableFutureDates}
              disablePast={disablePastDates}
              renderInput={(params) => (
                <TextField fullWidth variant="outlined" {...params} />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={submitButtonTheme}>
          <StatefulButton
            buttonTextOptions={{
              LOADING: 'Loading...',
              SUCCESS: 'Success',
              ERROR: 'There was an error saving',
              DEFAULT: 'SAVE CHANGES',
            }}
            type="button"
            id="submitButton"
            disabled={buttonDisabled}
            onClick={updatePalletProductDate}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

EditProductDate.propTypes = {
  pallet: PropTypes.object.isRequired,
  setProductDateType: PropTypes.func.isRequired,
  productDateType: PropTypes.string.isRequired,
  setProductDate: PropTypes.func.isRequired,
  productDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  updatePalletProductDate: PropTypes.func.isRequired,
};

EditProductDate.defaultProps = {
  productDate: '',
};

export default EditProductDate;
