import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const byOpenNetsuiteOrders = async ({ facilityId }) => {
  const url = `${API_BASE_URI}/ops/warehouse/packing_facilities/${facilityId}/netsuite_order_items`;

  const response = await fetch(url, options);
  return await handleResponse(response);
};

const updateNetsuiteItem = async ({ packingFacilityId, itemId, status }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/netsuite_order_items/${itemId}`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        netsuite_order_item: {
          audit_status: status,
        },
      }),
    }
  );

  return await handleResponse(response);
};

export default {
  byOpenNetsuiteOrders,
  updateNetsuiteItem,
};
