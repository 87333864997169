import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  ITEM_MANAGER_POD_MOVE,
  POD,
  ITEM_MANAGER_NOT_LESS_THAN_ZERO,
  ITEM_MANAGER_GREATER_THAN_ZERO,
  ITEM_MANAGER_QUANTITY_REQUIRED,
  ITEM_MANAGER_NEW_LOCATION_REQUIRED,
  ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY,
} from 'lib/constants';

import FormDrawer from '../formComponents/FormDrawer';
import CaseQuantity from '../formComponents/CaseQuantity';
import FormLocationSelectors from '../formComponents/FormLocationSelectors';
import SubmitButtonWrapper from '../formComponents/SubmitButtonWrapper';

const PodMoveForm = ({ productionZoneItem, buttonStyles }) => {
  const [open, toggleOpen] = useState(false);
  const allLocations = useSelector((state) => state.podLocations?.pods);

  const validationSelections = [
    ITEM_MANAGER_NOT_LESS_THAN_ZERO,
    ITEM_MANAGER_GREATER_THAN_ZERO,
    ITEM_MANAGER_QUANTITY_REQUIRED,
    ITEM_MANAGER_NEW_LOCATION_REQUIRED,
    ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY,
  ];

  const podLocations = allLocations.filter(
    (plocation) => plocation.type === POD
  );
  return (
    <>
      <Button
        className={buttonStyles}
        variant="contained"
        onClick={() => toggleOpen(true)}
      >
        Move
      </Button>
      <FormDrawer
        open={open}
        toggleOpen={toggleOpen}
        headerText="Move Item"
        productionZoneItem={productionZoneItem}
      >
        <CaseQuantity
          promptText="How many cases are you moving?"
          maxValue={productionZoneItem.quantity}
        />
        <FormLocationSelectors locations={podLocations} />
        <SubmitButtonWrapper
          buttonText="Move"
          onSuccess={toggleOpen}
          moveType={ITEM_MANAGER_POD_MOVE}
          validationSelections={validationSelections}
        />
      </FormDrawer>
    </>
  );
};

PodMoveForm.propTypes = {
  productionZoneItem: PropTypes.object.isRequired,
  buttonStyles: PropTypes.string,
};
export default PodMoveForm;
