import { useReducer } from 'react';

const UPDATE_VALUE = 'UPDATE_VALUE';
const SELECT_USER = 'SELECT_USER';
const CLEAR_USER = 'CLEAR_USER';

export const SELECTED_FACILITY_ID = 'selectedFacilityId';
export const SELECTED_FACILITY_NAME = 'selectedFacilityName';
export const SHIFT = 'shift';
export const ROLE = 'role';
export const NAME = 'name';
export const USER_WAS_SELECTED = 'userWasSelected';

const getInitialState = (facilities) => ({
  [SELECTED_FACILITY_ID]: facilities.length > 1 ? null : facilities[0].value,
  [SELECTED_FACILITY_NAME]: facilities.length > 1 ? null : facilities[0].label,
  [SHIFT]: null,
  [ROLE]: null,
  [NAME]: null,
  [USER_WAS_SELECTED]: false,
});

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_VALUE:
      return {
        ...state,
        ...action.values,
      };
    case CLEAR_USER:
      return { ...state, ...getInitialState(action.facilities) };
    case SELECT_USER:
      return {
        ...state,
        [NAME]: action.user.name,
        [SELECTED_FACILITY_ID]: action.user.packingFacilityId,
        [SELECTED_FACILITY_NAME]: action.user.packingFacilityName,
        [ROLE]: action.user.role,
        [USER_WAS_SELECTED]: true,
      };
    default:
      return state;
  }
};

export const useLoginFormState = (facilities) => {
  const [formState, dispatch] = useReducer(
    reducer,
    facilities,
    getInitialState
  );

  const clearSelectedUser = (facilityOptions) => {
    dispatch({ type: CLEAR_USER, facilities: facilityOptions });
  };

  const handleSelectUser = (user) => {
    dispatch({ type: SELECT_USER, user });
  };

  const createUpdaterCallback = (key) => {
    return (value) =>
      dispatch({
        type: UPDATE_VALUE,
        values: { [key]: value },
      });
  };

  return {
    formState,
    clearSelectedUser,
    handleSelectUser,
    createUpdaterCallback,
  };
};
