import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const postLabelInventory = async (params) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/production_inventories/labels`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify(params),
    }
  );

  return await handleResponse(response);
};

const printTag = async (params) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/production_inventories/labels/print_tag`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify(params),
    }
  );

  return await handleResponse(response);
};

export default { postLabelInventory, printTag };
