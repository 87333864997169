import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const TeamNumberQuestion = ({ questionText, teamNumber, setTeamNumber }) => {
  return (
    <>
      <h3>{questionText}</h3>
      <TextField
        id="teamNumberField"
        name="teamNumberField"
        inputProps={{ 'data-testid': 'team-number-field', min: 1 }}
        value={teamNumber || ''}
        label=""
        fullWidth
        variant="outlined"
        type="number"
        onChange={(event) => setTeamNumber(event.target.value)}
      />
    </>
  );
};

TeamNumberQuestion.defaultProps = {
  questionText: 'Team #',
  teamNumber: 0,
};

TeamNumberQuestion.propTypes = {
  questionText: PropTypes.string,
  teamNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setTeamNumber: PropTypes.func.isRequired,
};

export default TeamNumberQuestion;
