// This is used to override MUI DatePicker functionality to get the current date.
// For pallet product dates we disable past/future dates depending on product date code selected
// MUI DataPicker (as of v4) can only disable dates based on TODAY'S date,
//   but we want to disable dates based on the pallet's created at date.
// So we override the libraries functionality to define today's date in certain scenarios,
//   that way the disable past/future functionality works as expected.
// NOTE: upgrading to v5 caused some problems with this method and the tests overwrite the extend
//       we could look into if v5 has a better way of handling adding in a default date.

// Example 1: If the product date code is "1-RCV" (aka recieved on),
//   we want the pallet's created at date selected and disable all future/past dates
// Example 2: If the product date code is "13-Pack" (aka packed on),
//   we want to make sure no dates after the created at date can be selected

// Testing Note: upgradeing to MUI v5 caused some problems with this method and the tests need to ignore the extends
// At the top of the test file for a component using the method:
//    import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//    import productDateFns from 'lib/utils/productDateFns';
//    jest.mock('lib/utils/productDateFns');
// In the beforeEach method:
//    productDateFns.mockImplementation(() => AdapterDateFns);

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const productDateFns = (pallet) => {
  class ModifiedDateFnsForProductDate extends AdapterDateFns {
    // eslint-disable-next-line class-methods-use-this
    date = (value) => {
      if (typeof value === 'undefined') {
        return new Date(pallet.createdAt);
      }
      if (value === null) {
        return null;
      }
      return new Date(value);
    };
  }

  return ModifiedDateFnsForProductDate;
};

export default productDateFns;
