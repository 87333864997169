import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const Viewport = (props) => {
  const { children, classes } = props;

  return <div className={classes.root}>{children}</div>;
};

Viewport.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default withStyles(styles)(Viewport);
