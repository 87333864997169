import React from 'react';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const ItemManagerPageSelectors = () => {
  const classes = useStyles();

  return (
    <>
      <NavLink
        to="/item-manager/dropzones"
        className={classes.link}
        activeClassName={classes.activeLink}
      >
        Drop Zones
      </NavLink>
      <NavLink
        to="/item-manager/pods"
        className={classes.link}
        activeClassName={classes.activeLink}
      >
        Pods
      </NavLink>
    </>
  );
};

export default ItemManagerPageSelectors;
