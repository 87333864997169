import { GREY_700, WHITE, DARK_RED, HC_GREEN, GREY_200 } from 'lib/constants';

import { expander, grid, spanOne, spanTwo } from '../styles';

export default {
  orderSummary: {
    '&.Mui-expanded': {
      minHeight: '50px',
      maxHeight: '51px',
    },
    paddingLeft: '1.5%',
    borderTop: `1px solid ${GREY_200}`,
  },
  summaryContent: {
    alignItems: 'center',
    height: '27px',
  },
  bold: {
    fontWeight: 700,
  },
  orderExpander: {
    ...expander,
    marginBottom: '10px',
    '&.Mui-expanded': {
      margin: '10px 0px',
    },
    '&.Mui-expanded:last-child': {
      borderRadius: '0px 0px 10px 10px',
      marginBottom: '-5px',
    },
    '&:last-child': {
      borderRadius: '0px 0px 10px 10px',
    },
    '&.Mui-expanded:first-child': {
      borderRadius: '10px 10px 0px 0px',
    },
    '&:first-child': {
      borderRadius: '10px 10px 0px 0px',
    },
    '&.Mui-expanded:only-child': {
      borderRadius: '10px 10px 10px 10px',
    },
    '&:only-child': {
      borderRadius: '10px 10px 10px 10px',
    },
  },
  separator: {
    margin: '0px 1%',
    color: GREY_200,
  },
  itemHeader: {
    ...grid,
    backgroundColor: GREY_700,
    color: WHITE,
    textTransform: 'uppercase',
    padding: '0.5% 1.5%',
    fontSize: '12px',
  },
  spanTwo,
  spanOne,
  flagged: {
    color: DARK_RED,
  },
  flagContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  green: {
    color: HC_GREEN,
  },
  numberFlagged: {
    fontSize: '16px',
    marginRight: '5px',
  },
};
