import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Add from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { updatePalletLocation } from 'redux/cycleCounting/actions';
import { StatefulButton } from 'components/shared';
import submitButtonTheme from 'lib/submitButtonTheme';
import ContentModal from 'components/shared/ContentModal';

const AddPalletToLocation = ({
  storageSlotName,
  storageSlotId,
  countListId,
  onAddPalletSuccess,
}) => {
  const dispatch = useDispatch();
  const { updateSaving, updateError } = useSelector(
    (state) => state.cycleCounting
  );

  const [lotNumber, setLotNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onChangeLotNumber = useCallback((e) => {
    setLotNumber(e.currentTarget.value);
  });

  const movePallet = useCallback(
    () => dispatch(updatePalletLocation(countListId, lotNumber, storageSlotId)),
    [lotNumber]
  );

  const toggleModal = useCallback(() => setIsModalOpen((isOpen) => !isOpen));

  const onSuccess = () => {
    onAddPalletSuccess(lotNumber);
    toggleModal();
    setLotNumber('');
  };

  return (
    <>
      <Fab
        data-testid="add-pallet-to-location-button"
        onClick={toggleModal}
        color="secondary"
      >
        <Add />
      </Fab>
      <ContentModal
        data-testid="add-pallet-to-location-modal"
        open={isModalOpen}
        onClose={toggleModal}
        title={`Add Pallet to ${storageSlotName}`}
      >
        <p>Enter the Lot Number to add to this location</p>
        <TextField
          data-testid="lot-number-input"
          label="Lot Number"
          variant="outlined"
          fullWidth
          autoFocus
          onChange={onChangeLotNumber}
          onKeyPress={(e) => e.key === 'Enter' && movePallet()}
          error={updateError}
          helperText={updateError ? 'Not a valid lot number' : ''}
        />
        <ThemeProvider theme={submitButtonTheme}>
          <StatefulButton
            loading={updateSaving}
            failed={updateError}
            buttonTextOptions={{
              LOADING: 'Updating...',
              SUCCESS: 'Success',
              ERROR: 'ERROR',
              DEFAULT: 'ADD PALLET TO LOCATION',
            }}
            type="button"
            data-testid="submit-button"
            disabled={!lotNumber}
            onClick={movePallet}
            successCallback={onSuccess}
          />
        </ThemeProvider>
      </ContentModal>
    </>
  );
};

AddPalletToLocation.propTypes = {
  storageSlotName: PropTypes.string,
  storageSlotId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

AddPalletToLocation.defaultProps = {
  storageSlotName: '',
};

export default AddPalletToLocation;
