import {
  HC_GREEN,
  WHITE,
  BLACK,
  GREY_100,
  GREY_200,
  ERROR_RED,
} from 'lib/constants';
import { centeredLoading } from 'lib/styles';

export default {
  centeredLoading,
  fullscreen: {
    height: '100vh',
    '& $leftGrid': {
      // need this because Grid spacing is not properly workings
      padding: '5px 10px 0 0',
      height: '100%',
    },
    '& $statusCard': {
      margin: '3%',
      background: WHITE,
    },
  },
  leftGrid: {
    // the third party CSV component is hardcoded to 100%, so force the height here to fit-content
    height: 'fit-content',
    padding: '5px',
  },
  rightPanel: {
    background: GREY_100,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusCard: {
    backgroundColor: GREY_100,
    width: '100%',
  },
  error: {
    color: WHITE,
    background: ERROR_RED,
  },
  disabled: {
    color: BLACK,
    background: GREY_200,
  },
  success: {
    color: WHITE,
    background: HC_GREEN,
  },
};
