import { connect } from 'react-redux';

import { selectLotCodesForAGroup } from 'redux/pallets/qaApp/selectors';
import { selectLotCodePhotos } from 'redux/photos/selectors';
import { selectSavedLotCodes } from 'redux/wmsFsqaReview/selectors';

import LotCodeQuestions from './LotCodeQuestions';

const mapStateToProps = (state, { palletId }) => {
  return {
    lotCodePhotosLength: selectLotCodePhotos(state, palletId).length,
    savedLotCodes: selectSavedLotCodes(state, palletId),
    groupLotCodes: selectLotCodesForAGroup(state, palletId),
  };
};
export default connect(mapStateToProps)(LotCodeQuestions);
