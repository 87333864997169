import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Rule from '@mui/icons-material/Rule';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from 'react-redux';

import { getIngredientAssignmentsForInventoryCheck } from 'redux/ingredientAssignments/actions';
import ContentModal from 'components/shared/ContentModal';
import Loading from 'components/shared/Loading';

import styles from './styles';

const useStyles = makeStyles(styles);

const InventoryCheck = ({ meal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const { data = [], loading } = useSelector((state = {}) => ({
    data: state.ingredientAssignments?.inventoryCheck?.results,
    loading: state.ingredientAssignments?.inventoryCheck?.fetching,
  }));

  useEffect(() => {
    if (modalOpen) {
      const ingredientAssignmentIds = meal.ingredient_assignments.map(
        (ia) => ia.id
      );
      dispatch(
        getIngredientAssignmentsForInventoryCheck(ingredientAssignmentIds)
      );
    }
  }, [modalOpen]);

  const toggleModal = (e) => {
    setModalOpen(!modalOpen);
    e.stopPropagation();
  };

  const renderIcon = (inStock) => (
    <div className={classes.iconWrapper}>
      {inStock ? <CheckCircle color="primary" /> : <Cancel color="error" />}
    </div>
  );

  return (
    <div data-testid="inventory-check" className={classes.root}>
      <IconButton
        color="primary"
        data-testid="inventory-check-button"
        onClick={toggleModal}
      >
        <Rule />
        <div className={classes.buttonTextWrapper}>Inventory Check</div>
      </IconButton>
      <ContentModal
        open={modalOpen}
        onClose={toggleModal}
        title={`Inventory Check for ${meal.title}`}
      >
        {loading ? (
          <div className={classes.loadingWrapper}>
            <Loading />
          </div>
        ) : (
          data
            .sort((item1, item2) => item2.inStock - item1.inStock) // sort inStock first
            .map((item) => (
              <div
                data-testid="inventory-check-item"
                key={item.id}
                className={classes.textWrapper}
              >
                {renderIcon(item.inStock)}
                {` ${item.name} - ${item.id} - ${item.caseQuantity} cases`}
              </div>
            ))
        )}
      </ContentModal>
    </div>
  );
};

InventoryCheck.propTypes = {
  meal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default InventoryCheck;
