import { pusher } from 'services/pusher';
import { updateSelectedPod } from 'redux/podStatus/actions';

const subscribeToPodDetails = (podStatusId) => {
  const channel = pusher.subscribe(`podDetails_${podStatusId}`);
  return (dispatch) => {
    channel.bind('updatePodDetails', (selectedPodStatus) => {
      dispatch(updateSelectedPod(selectedPodStatus));
    });
  };
};

const unsubscribeToPodDetails = (podStatusId) => {
  pusher.unsubscribe(`podDetails_${podStatusId}`);
};

export { subscribeToPodDetails, unsubscribeToPodDetails };
