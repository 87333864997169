import React from 'react';

import { FormatNumberCell } from 'components/shared';

export const columns = [
  { name: 'createdAt', label: 'Received' },
  {
    name: 'quantity',
    label: 'Quantity',
    options: {
      filter: false,
      customBodyRender: (value) => {
        const result = <FormatNumberCell value={value} addCommas />;
        return result;
      },
    },
  },
  { name: 'measuringUnit', label: 'UoM' },
  { name: 'id', label: 'Lot' },
];

export const options = {
  selectableRows: 'none',
  print: false,
  sort: false,
  search: false,
  filter: false,
  download: false,
  viewColumns: false,
  pagination: false,
  responsive: 'standard',
  textLabels: {
    body: { noMatch: 'No Activity to Display' },
  },
};
