import { MEDIA_QUERY_TABLET_OR_SMALLER } from 'lib/constants';

export default {
  inputRoot: {
    width: '80%',
    display: 'flex',
    margin: '10px auto',
  },
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    inputRoot: {
      opacity: 0,
      height: 0,
    },
    input: {
      height: 0,
      padding: 0,
    },
  },
};
