import { makeQueryParamURL } from 'lib/utils';
import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getPrinters = async ({ printerType, packingFacilityId }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/packing_facilities/${packingFacilityId}/printers`,
      {
        printer_type: printerType,
      }
    ),
    options
  );

  return await handleResponse(response);
};

export default {
  getPrinters,
};
