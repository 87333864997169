import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {
  fetchPOsFromQuery,
  storeSearchQuery,
  clearSearchQuery,
} from 'redux/netsuiteOrders/receiving/actions';

import styles from './styles';
import TruckOrderList from './TruckOrderList';

const mapStatetoProps = (state) => ({
  todaysNOs: state.netsuiteOrderItems.todaysNOs,
  searchResultsNOs: state.netsuiteOrders.receiving.queryResults,
  queryTerm: state.netsuiteOrders.receiving.queryTerm,
});

const mapDispatchToProps = {
  onStoreSearchQuery: storeSearchQuery,
  searchNOs: fetchPOsFromQuery,
  clearSearch: clearSearchQuery,
};

export default withStyles(styles)(
  connect(mapStatetoProps, mapDispatchToProps)(TruckOrderList)
);
