import {
  GET_PRODUCTION_SUBMISSIONS_REQUEST,
  GET_PRODUCTION_SUBMISSIONS_SUCCESS,
} from 'lib/constants';

const initialState = {
  results: [],
  fetching: false,
  pageCount: 0,
  quantitySubmitted: 0,
  allLetters: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case GET_PRODUCTION_SUBMISSIONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case GET_PRODUCTION_SUBMISSIONS_SUCCESS:
      return {
        results: action.response.productionSubmissions,
        pageCount: action.response.pageCount,
        quantitySubmitted: action.response.quantitySubmitted,
        allLetters: action.response.allLetters,
        fetching: false,
      };
    default:
      return state;
  }
}
