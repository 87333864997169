import flattenDeep from 'lodash/flattenDeep';

import format from 'date-fns/format';

export const UPDATE_INPUT = 'UPDATE_INPUT';
export const RESET_FORM = 'RESET_FORM';
export const UPDATE_NOS = 'UPDATE_NOS';
export const ARRIVAL_TIME = 'arrivalTime';
export const DOCK_IN_TIME = 'dockInTime';
export const STOP_TIME = 'stopTime';
export const TRAILER_GAUGE = 'trailerTempGauge';
export const TRAILER_TEMP_INTERNAL = 'trailerTempInternal';
export const TRAILER_SEAL = 'trailerSeal';
export const DOCK_DOOR_ID = 'dockDoorId';

export const getInitialState = () => ({
  arrivalTime: new Date(),
  dockInTime: '',
  stopTime: '',
  dockDoorId: '',
  trailerTempGauge: '',
  trailerTempInternal: '',
  netsuiteOrders: [],
  removeNetsuiteOrders: [],
});

export const isFormValid = (state) => {
  return (
    state.arrivalTime &&
    state.dockInTime &&
    state.stopTime &&
    state.trailerTempGauge !== '' &&
    Boolean(Number(state.trailerTempGauge)) &&
    state.trailerTempInternal !== '' &&
    Boolean(Number(state.trailerTempInternal)) &&
    state.trailerSeal &&
    state.netsuiteOrders &&
    state.netsuiteOrders.length > 0
  );
};

export const formatTruckOrders = (netsuiteOrders, netsuiteOrdersToRemove) => {
  let netsuiteOrderCollection = [];

  if (netsuiteOrders) {
    let formattedOrders = netsuiteOrders.map((order) => ({
      netsuite_order_id: order.id,
      id: order.truckNetsuiteOrderId,
    }));
    netsuiteOrderCollection.push(formattedOrders);
  }
  if (netsuiteOrdersToRemove) {
    let formattedOrders = netsuiteOrdersToRemove.map((order) => ({
      netsuite_order_id: order.id,
      id: order.truckNetsuiteOrderId,
      _destroy: true,
    }));
    netsuiteOrderCollection.push(formattedOrders);
  }
  return flattenDeep(netsuiteOrderCollection);
};

export const reducer = (state, action) => {
  switch (action.type) {
    case RESET_FORM:
      return getInitialState();
    case UPDATE_INPUT:
      return {
        ...state,
        [action.field]: action.value,
      };
    case UPDATE_NOS:
      return {
        ...state,
        netsuiteOrders: action.netsuiteOrders,
        removeNetsuiteOrders: action.removeNetsuiteOrders,
      };
    default:
      return state;
  }
};

export const formatTime = (date) => format(new Date(date), 'kk:mm');
