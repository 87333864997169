import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';
import { makeQueryParamURL } from 'lib/utils';

const mostRecent = async (params) => {
  const response = await fetch(
    makeQueryParamURL(`${API_BASE_URI}/ops/production_submissions`, params),
    options
  );
  return await handleResponse(response);
};

export default {
  mostRecent,
};
