import {
  PRINTERS_REQUEST,
  PRINTERS_SUCCESS,
  PRINTERS_ERROR,
  PALLETS_PRINT_REQUEST,
  PALLETS_PRINT_SUCCESS,
  PALLETS_PRINT_ERROR,
} from 'lib/constants';

const initialState = {
  fetching: false,
  failed: false,
  printers: [],
  palletsPrinting: false,
  palletsPrintFailed: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case PRINTERS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        printers: [],
      };
    case PRINTERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        printers: action.response.printers,
      };
    case PRINTERS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case PALLETS_PRINT_REQUEST:
      return {
        ...state,
        palletsPrinting: true,
        palletsPrintFailed: false,
      };
    case PALLETS_PRINT_SUCCESS:
      return {
        ...state,
        palletsPrinting: false,
        palletsPrintFailed: false,
      };
    case PALLETS_PRINT_ERROR:
      return {
        ...state,
        palletsPrinting: false,
        palletsPrintFailed: true,
      };
    default:
      return state;
  }
}
