import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import uniqBy from 'lodash/uniqBy';
import classnames from 'classnames';

import { ECOMMERCE_CHANNEL } from 'lib/constants';
import { formatNumber } from 'lib/utils';

import IngredientPanelDetails from './IngredientPanelDetails';
import styles from './styles';

const IngredientPanel = ({
  ingredients,
  expanded,
  totalGoalCount,
  totalCompletedCount,
  percentTarget,
  onToggleClick,
  classes,
}) => {
  const panelIngredient = ingredients[0];
  const averagePanelPercent = (
    100 *
    (totalCompletedCount / totalGoalCount)
  ).toFixed(0);

  const progressColorClass = () => {
    if (averagePanelPercent >= 100 || averagePanelPercent - percentTarget > 5) {
      return classes.aheadOfTarget;
    } else if (percentTarget - averagePanelPercent > 5) {
      return classes.belowTarget;
    } else {
      return classes.onTarget;
    }
  };

  return (
    <div
      data-testid={`${panelIngredient.id}-ingredient-panel`}
      className={classes.ingredientItem}
    >
      <Accordion expanded={expanded}>
        <AccordionSummary
          className={classes.panel}
          expandIcon={<ExpandMoreIcon />}
          onClick={onToggleClick}
        >
          <Grid container spacing={0} style={{ paddingRight: 0 }}>
            <Grid item xs={4} lg={4} className={classnames(classes.textBold)}>
              <div className={classes.ingredientName}>
                <span>{panelIngredient.name}</span>
              </div>
              <div className={classes.ingredientName}>
                {' '}
                - {panelIngredient.quantity} {panelIngredient.unit}
              </div>
            </Grid>

            <Grid item xs={1}>
              <span>{panelIngredient.ingredientId}</span>
            </Grid>
            <Grid item xs={2} lg={1}>
              {uniqBy(ingredients, 'channel').map((ingredient) => (
                <div
                  key={`${ingredient.id}-${ingredient.channel}`}
                  className={classnames({
                    [classes.ecommerceCircle]:
                      ingredient.channel === ECOMMERCE_CHANNEL,
                  })}
                >
                  {ingredient.channel === ECOMMERCE_CHANNEL ? 'E' : 'R'}
                </div>
              ))}
            </Grid>
            <Grid
              item
              xs={2}
              lg={3}
              className={classnames(
                progressColorClass(),
                classes.ingredientPercentProgress
              )}
            >
              <span>{`${averagePanelPercent}%`}</span>
            </Grid>
            <Grid item xs={1} className={classnames(classes.textRight)}>
              <span>{formatNumber(totalCompletedCount)}</span>
            </Grid>
            <Grid item xs={false} className={classnames(classes.textCenter)}>
              <span className={classes.slash}>/</span>
            </Grid>
            <Grid item xs={1} className={classnames(classes.textRight)}>
              <span className={classes.goalCount}>
                {formatNumber(totalGoalCount)}
              </span>
            </Grid>
          </Grid>
        </AccordionSummary>

        <IngredientPanelDetails
          ingredientAssignments={ingredients}
          classes={classes}
        />
      </Accordion>
    </div>
  );
};

IngredientPanel.propTypes = {
  expanded: PropTypes.bool.isRequired,
  totalGoalCount: PropTypes.number.isRequired,
  totalCompletedCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  percentTarget: PropTypes.number.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(IngredientPanel);
