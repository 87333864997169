import React from 'react';
import PropTypes from 'prop-types';
import MUISnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useSnackbarQueue } from 'lib/custom_hooks';

const Snackbar = ({ loading, failed, messaging, options }) => {
  const snackbarState = useSnackbarQueue(loading, failed, messaging);

  return (
    <MUISnackbar
      key={snackbarState.info.key}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={snackbarState.open}
      autoHideDuration={2000}
      onClose={snackbarState.handleClose}
      TransitionProps={{ onExited: snackbarState.handleExited }}
      {...options}
    >
      <MuiAlert
        onClose={snackbarState.handleClose}
        severity={snackbarState.info.variant || 'info'}
      >
        {snackbarState.info.message || ''}
      </MuiAlert>
    </MUISnackbar>
  );
};

Snackbar.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  messaging: PropTypes.shape({
    loading: PropTypes.string,
    failure: PropTypes.string,
    success: PropTypes.string,
  }),
};

Snackbar.defaultProps = {
  messaging: {
    loading: 'Printing...',
    failure: 'Print Failed',
    success: 'Print Success!',
  },
  loading: false,
  failed: false,
};

export default Snackbar;
