import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {
  addValueToFsqaReview,
  postFsqaReview,
  fetchFsqaReview,
  updateFsqaReview,
} from 'redux/wmsFsqaReview/actions';
import {
  selectFsqaReview,
  selectFetching,
  selectPostingFailed,
  selectPosting,
} from 'redux/wmsFsqaReview/selectors';
import { usePrevious } from 'lib/custom_hooks';
import { CloseIcon } from 'components/icons';
import { TabOutlineButton, Loading } from 'components/shared';

import PackagingForm from './PackagingForm';
import styles from '../styles';

const PackagingFormContainer = ({
  pallet,
  closeDrawer,
  onSubmitForm,
  onAddToForm,
  submitFailed,
  posting,
  formState,
  fetchFormState,
  classes,
  fetching,
}) => {
  useEffect(() => {
    if (formState === null) {
      fetchFormState();
    }
  }, [formState, fetchFormState]);

  const prevPosting = usePrevious(posting);
  useEffect(() => {
    if (formState && prevPosting && !posting && formState.finalized) {
      closeDrawer();
    }
  }, [prevPosting, posting, formState, closeDrawer]);

  const finalized = formState !== null && formState.finalized;

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        {/* reviewed by also, maybe move finalized div to inside bodySection */}
        <header>Lot #{pallet.id}</header>
        {finalized && (
          <span className={classes.reviewedOn}>
            {'Reviewed on '}
            <strong>{formState.updatedAtDate}</strong>
            {' at '}
            <strong>{formState.updatedAtTime}</strong>
            {' by '}
            <strong>{formState.reviewer}</strong>
          </span>
        )}
        <TabOutlineButton
          className={classes.closeButton}
          type="button"
          onClick={closeDrawer}
        >
          <CloseIcon className={classes.icon} />
        </TabOutlineButton>
      </div>
      {fetching ? (
        <div className={classes.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <PackagingForm
          formState={formState || {}}
          submitLoading={posting}
          submitFailed={submitFailed}
          onAddToForm={onAddToForm}
          onSubmitForm={onSubmitForm}
          pallet={pallet}
          closeDrawer={closeDrawer}
          classes={classes}
        />
      )}
    </div>
  );
};

PackagingFormContainer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  pallet: PropTypes.object.isRequired,
  formState: PropTypes.object,
  onAddToForm: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  fetchFormState: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

PackagingFormContainer.defaultProps = {
  formState: null,
};

export const mapStateToProps = (state, ownProps) => ({
  formState: selectFsqaReview(state, ownProps.pallet.id),
  posting: selectPosting(state, ownProps.pallet.id),
  fetching: selectFetching(state, ownProps.pallet.id),
  submitFailed: selectPostingFailed(state, ownProps.pallet.id),
});

// fix this later
export const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddToForm: (field, value) =>
    dispatch(addValueToFsqaReview(ownProps.pallet.id, field, value)),
  onSubmitForm: () =>
    ownProps.pallet.status === 'Completed' ||
    ownProps.pallet.status === 'Partially Completed'
      ? dispatch(updateFsqaReview(ownProps.pallet.id, ownProps.pallet.status))
      : dispatch(postFsqaReview(ownProps.pallet.id)),
  fetchFormState: () => dispatch(fetchFsqaReview(ownProps.pallet.id)),
});

// export { PackagingFormContainer as FsqaReviewContainerTest };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PackagingFormContainer));
