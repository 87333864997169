import {
  GREY_100,
  NEGATIVE_RED,
  POSITIVE_GREEN,
  GREY_500,
} from 'lib/constants';

export default {
  grey: {
    backgroundColor: GREY_100,
  },
  listItem: {
    padding: '0 5px',
  },
  listItemText: {
    flex: '1',
  },
  activityDate: {
    minWidth: '45px',
    padding: '0',
    marginRight: '4px',
  },
  listItemTextSpan: {
    fontSize: '14px',
    padding: '5px 5px 5px 0px',
  },
  negativeQuantityDifference: {
    color: NEGATIVE_RED,
    fontWeight: 'bold',
  },
  positiveQuantityDifference: {
    color: POSITIVE_GREEN,
    fontWeight: 'bold',
  },
  arrowIcon: {
    fontSize: '14px',
    margin: '0 5px',
  },
  detailsColumn: {
    flex: '5',
    padding: '0',
  },
  quantityDetailsColumn: {
    flex: '1',
    padding: '0',
  },
  detailsColumnText: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  },
  quantityChange: {
    textAlign: 'center',
    width: '80px',
  },
  extraInfoArea: {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  extraInfoText: {
    padding: '5px',
  },
  locationName: {
    flex: 1,
  },
  storageArea: {
    display: 'inline-flex',
  },
  storageAreaText: {
    maxWidth: '93px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  storageSlot: {
    color: GREY_500,
  },
};
