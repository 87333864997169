import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

function getStatusClass(status, classes) {
  switch (status) {
    case 'In Full':
      return classes.filled;
    case 'Partial':
      return classes.partial;
    default:
      return '';
  }
}

const WarehouseReceivingStatusIcon = ({ classes, status }) => {
  let className = getStatusClass(status, classes);
  return <div className={className} />;
};

WarehouseReceivingStatusIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default withStyles(styles)(WarehouseReceivingStatusIcon);
