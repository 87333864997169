import { createTheme } from '@mui/material/styles';

import { GREY_150 } from 'lib/constants';
import theme from 'lib/theme';

export default createTheme({
  ...theme,
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingRight: '0px 15px 0px 0px !important',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: 10,
          borderTopLeftRadius: 6,
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
          borderTopRightRadius: 6,
          borderTopLeftRadius: 6,
          background: GREY_150,
          color: '#202020',
          alignItems: 'start',
          '&.Mui-expanded': {
            margin: 0,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
            minHeight: '48px',
          },
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
  },
});
