import { makeApiRequest } from 'lib/api';
import { MENUS_UPDATED, MENU_SELECTED, MENUS } from 'lib/constants';

import { fetchingLimitedMenus, fetchingAllOrderableMenus } from './services';

export const fetchMenus = (showExtendedProdMenus = false) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MENUS,
        method: fetchingLimitedMenus,
        params: showExtendedProdMenus,
      })
    );
  };
};

export const fetchMenusWithHooks = (showExtendedProdMenus = false) => {
  return makeApiRequest({
    type: MENUS,
    method: fetchingLimitedMenus,
    params: showExtendedProdMenus,
  });
};

export const fetchAllOrderableMenus = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MENUS,
        method: fetchingAllOrderableMenus,
      })
    );
  };
};

export const updateMenus = (menus) => {
  return {
    type: MENUS_UPDATED,
    payload: { results: menus },
  };
};

export const selectMenu = (menu) => {
  return {
    type: MENU_SELECTED,
    payload: { menu },
  };
};

export const fetchMenusAndSelectFirst = (
  showExtendedProdMenus = false
) => async (dispatch) => {
  const response = await dispatch(
    makeApiRequest({
      type: MENUS,
      method: fetchingLimitedMenus,
      params: showExtendedProdMenus,
    })
  );

  if (response.ok) {
    dispatch(selectMenu(response.menus[0]));
  }
};
