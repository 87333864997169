import { useMemo, useCallback } from 'react';
import includes from 'lodash/includes';
import { isAfter, isBefore, isEqual } from 'date-fns';

import {
  TODAY_DATE_ROTATION_TYPES,
  FUTURE_DATE_ROTATION_TYPES,
  PAST_DATE_ROTATION_TYPES,
  RCV,
} from 'lib/constants';

let baseDate;

const hasPastCodeButFutureDate = (dateCode, productDateFormatted) =>
  includes(PAST_DATE_ROTATION_TYPES, dateCode) &&
  isAfter(productDateFormatted, baseDate);

const hasFutureCodeButPastDate = (dateCode, productDateFormatted) =>
  includes(FUTURE_DATE_ROTATION_TYPES, dateCode) &&
  isBefore(productDateFormatted, baseDate);

const hasPastOrFutureCodeButCurrentDate = (dateCode, productDateFormatted) =>
  includes(
    [...FUTURE_DATE_ROTATION_TYPES, ...PAST_DATE_ROTATION_TYPES],
    dateCode
  ) && isEqual(baseDate, productDateFormatted);

const hasCodeAndDateMismatch = (dateCode, productDateFormatted) =>
  hasPastCodeButFutureDate(dateCode, productDateFormatted) ||
  hasFutureCodeButPastDate(dateCode, productDateFormatted) ||
  hasPastOrFutureCodeButCurrentDate(dateCode, productDateFormatted);

const useRotationTypeValidations = (
  productDate,
  productDateType,
  updateProductDate,
  updateProductDateType,
  createdAtDate
) => {
  const onRotationTypeChange = useCallback(
    (event) => {
      updateProductDateType(event.target.value);

      baseDate = createdAtDate ? new Date(createdAtDate) : new Date();
      let productDateFormatted = productDate ? new Date(productDate) : null;

      if (event.target.value === RCV) {
        updateProductDate(baseDate);
      } else if (
        productDate &&
        hasCodeAndDateMismatch(event.target.value, productDateFormatted)
      ) {
        updateProductDate(null);
      }
    },
    [productDate, updateProductDate, updateProductDateType, createdAtDate]
  );

  const disableFutureDates = useMemo(
    () =>
      includes(
        [...PAST_DATE_ROTATION_TYPES, ...TODAY_DATE_ROTATION_TYPES],
        productDateType
      ),
    [productDateType]
  );

  const disablePastDates = useMemo(
    () =>
      includes(
        [...FUTURE_DATE_ROTATION_TYPES, ...TODAY_DATE_ROTATION_TYPES],
        productDateType
      ),
    [productDateType]
  );

  return {
    disableFutureDates,
    disablePastDates,
    onRotationTypeChange,
  };
};

export default useRotationTypeValidations;
