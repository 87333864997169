import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';

import { formatTime, dateTimeInTimeZone } from 'lib/utils';

import {
  gridPosition,
  getColumnNumber,
  getTimeMarkerPercentWidth,
  MAX_GRID_LENGTH,
  HOUR_COL_SPAN,
} from './styles';

const TimeMarker = ({ timeZone, classes }) => {
  const [currentTime, setCurrentTime] = useState(dateTimeInTimeZone(timeZone));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dateTimeInTimeZone(timeZone));
    }, 60000);
    return () => clearInterval(interval);
  });

  const currentHour = formatTime(currentTime.getHours() + ':' + '00');

  return (
    <>
      {times(MAX_GRID_LENGTH, (number) => (
        <div
          key={number}
          className={classes.timeMarker}
          style={gridPosition(
            getColumnNumber(currentHour),
            number + 1,
            HOUR_COL_SPAN,
            `${getTimeMarkerPercentWidth(currentTime)}%`
          )}
        />
      ))}
    </>
  );
};

TimeMarker.propTypes = {
  timeZone: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};
export default TimeMarker;
