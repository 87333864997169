import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';

import { Loading } from 'components/shared';

import { DefectsPhotoViewerTaker } from '../PhotoViewerTaker';
import { useValidator, hasValues } from '../FsqaReviewForms/formHelpers';
import styles from './styles';

const numberRegex = RegExp('^[0-9]+$');

const DefectsQuestion = ({
  classes,
  hasDefects,
  updateHasDefects,
  updateDefects,
  palletId,
  defectOptions,
  defects,
  defectsLoading,
  numberDefective,
  numberSampled,
  updateNumberDefective,
  updateNumberSampled,
  validationCallback,
  defectPhotosLength,
}) => {
  const onNoClick = () => {
    updateHasDefects(false);
    updateDefects([]);
  };
  const onDefectClick = (defect) => {
    const updatedDefects = [...defects];
    const selectedDefectIndex = updatedDefects.findIndex(
      (currentDefect) => currentDefect.id === defect.id
    );

    if (selectedDefectIndex !== -1) {
      updatedDefects.splice(selectedDefectIndex, 1);
    } else {
      updatedDefects.push(defect);
    }

    updateDefects(updatedDefects);
  };
  const getOnNumberInputChange = (callback) => (e) => {
    if (numberRegex.test(e.target.value) || e.target.value === '') {
      callback(e.target.value);
    }
  };
  const PercentDefective = useMemo(() => {
    if (numberDefective === '' || numberSampled === '') {
      return '';
    }
    return `${((numberDefective / numberSampled) * 100).toFixed(1)}%`;
  }, [numberDefective, numberSampled]);
  const sampledError = useMemo(() => {
    if (
      numberDefective.length &&
      numberSampled.length &&
      Number(numberSampled) < Number(numberDefective)
    ) {
      return '# of defective cannot be greater than # sampled';
    } else if (
      (numberSampled.length && Number(numberSampled) < 1) ||
      (numberDefective.length && Number(numberDefective) < 1)
    ) {
      return 'Values must be greater than 0';
    }
    return null;
  }, [numberDefective, numberSampled]);

  const defectsValid = useMemo(
    () =>
      !sampledError &&
      hasValues([defects, numberDefective, numberSampled]) &&
      defectPhotosLength > 0,
    [sampledError, defects, numberDefective, numberSampled, defectPhotosLength]
  );

  useValidator(hasDefects === false || defectsValid, validationCallback);

  return (
    <Fragment>
      <span className={classes.infoLabel}>ARE THERE DEFECTS?</span>
      <div className={classes.defectWrapper}>
        <Button
          className={classNames(classes.button, {
            [classes.selectedNo]: hasDefects === false,
          })}
          variant="outlined"
          color="neutral"
          onClick={onNoClick}
        >
          No
        </Button>
        <Button
          className={classNames(classes.button, {
            [classes.selectedYes]: hasDefects === true,
          })}
          variant="outlined"
          color="neutral"
          onClick={() => updateHasDefects(true)}
        >
          Yes
        </Button>
        <Collapse in={hasDefects}>
          <div className={classes.numberDefective}>
            <label
              className={classes.textFieldWrapper}
              htmlFor="numberDefective"
            >
              # OF DEFECTIVE
              <TextField
                error={typeof sampledError === 'string'}
                required={hasDefects === true}
                value={numberDefective || ''}
                onChange={getOnNumberInputChange(updateNumberDefective)}
                variant="outlined"
                margin="normal"
                className={classes.textField}
                id="numberDefective"
                type="number"
                inputProps={{
                  className: classes.numericInput,
                  min: 1,
                  step: 1,
                  'data-testid': 'numberDefectiveInput',
                }}
              />
            </label>
            <label className={classes.textFieldWrapper} htmlFor="numberSampled">
              # SAMPLED
              <TextField
                error={typeof sampledError === 'string'}
                required={hasDefects === true}
                value={numberSampled || ''}
                onChange={getOnNumberInputChange(updateNumberSampled)}
                variant="outlined"
                margin="normal"
                className={classes.textField}
                id="numberSampled"
                type="number"
                inputProps={{
                  className: classes.numericInput,
                  min: 1,
                  step: 1,
                  'data-testid': 'numberSampledInput',
                }}
              />
            </label>
            <div className={classes.textFieldWrapper}>
              % DEFECTIVE
              <div
                className={classNames(classes.percentDefective, {
                  [classes.errorBox]: sampledError,
                })}
              >
                {!sampledError && PercentDefective}
              </div>
            </div>
          </div>
          {sampledError && (
            <div className={classes.errorText}>{sampledError}</div>
          )}
          <div className={classes.selectDefects}>SELECT DEFECTS</div>
          <div className={classes.defectOptionsContainer}>
            {defectsLoading ? (
              <Loading />
            ) : (
              defectOptions.map((defect) => (
                <Button
                  key={defect.id}
                  className={classNames(classes.defectButton, {
                    [classes.selected]: defects.find(
                      (currentDefect) => currentDefect.id === defect.id
                    ),
                  })}
                  variant="outlined"
                  color="neutral"
                  onClick={() => onDefectClick(defect)}
                >
                  {defect.name}
                </Button>
              ))
            )}
          </div>
          <div className={classes.photoTitle}>DEFECTS PHOTOS</div>
          <DefectsPhotoViewerTaker
            palletId={palletId}
            className={classes.infoValue}
          />
        </Collapse>
      </div>
    </Fragment>
  );
};

const defectShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

DefectsQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  hasDefects: PropTypes.bool,
  defects: PropTypes.arrayOf(defectShape),
  updateDefects: PropTypes.func.isRequired,
  updateHasDefects: PropTypes.func.isRequired,
  defectOptions: PropTypes.arrayOf(defectShape).isRequired,
  palletId: PropTypes.number.isRequired,
  defectsLoading: PropTypes.bool.isRequired,
  numberDefective: PropTypes.string,
  numberSampled: PropTypes.string,
  updateNumberDefective: PropTypes.func.isRequired,
  updateNumberSampled: PropTypes.func.isRequired,
  defectPhotosLength: PropTypes.number.isRequired,
  validationCallback: PropTypes.func.isRequired,
};

DefectsQuestion.defaultProps = {
  hasDefects: null,
  numberDefective: '',
  numberSampled: '',
  defects: [],
};

export default withStyles(styles)(DefectsQuestion);
