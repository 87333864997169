import uniqBy from 'lodash/uniqBy';
import {
  isToday,
  parseISO,
  addWeeks,
  subDays,
  isWithinInterval,
} from 'date-fns';

import {
  NETSUITE_ORDER_ITEMS_REQUEST,
  NETSUITE_ORDER_ITEMS_SUCCESS,
  NETSUITE_ORDER_ITEM_STORE_SEARCH,
  NETSUITE_ORDER_ITEM_CLEAR_SEARCH,
  ADD_TRUCK,
  TRUCK_PUT_SUCCESS,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  results: [],
  todaysNOs: [],
  threeWeeksOutNOs: [],
  queryResults: [],
};

const updateOrderItemTrucks = (order, truck) => {
  const orderTruckIds = order.trucks.map((t) => t.id);
  const truckNetsuiteOrderIds = truck.netsuiteOrders.map((o) => o.id);
  const orderNeedsTruckRemoved =
    orderTruckIds.includes(truck.id) &&
    !truckNetsuiteOrderIds.includes(order.netsuiteOrderId);
  const orderNeedsTruckAdded = truck.netsuiteOrders.find(
    (o) => o.netsuiteOrderNumber === order.netsuiteOrderNumber
  );

  if (orderNeedsTruckRemoved) {
    const newOrderTrucks = [...order.trucks].filter((t) => t.id !== truck.id);
    return {
      ...order,
      trucks: newOrderTrucks,
    };
  }
  if (orderNeedsTruckAdded) {
    return {
      ...order,
      trucks: uniqBy(
        [...order.trucks, { id: truck.id, vendorNames: truck.vendorsArray }],
        'id'
      ),
    };
  }
  return order;
};

const reduceFilteredNOs = (netsuiteOrders) => {
  return netsuiteOrders
    .reduce((listOfPos, current) => {
      if (
        !listOfPos.some(
          (x) => x.netsuiteOrderNumber == current.netsuiteOrderNumber
        )
      ) {
        listOfPos.push(current);
      }
      return listOfPos;
    }, [])
    .map((netsuiteOrderItem) => ({
      id: netsuiteOrderItem.netsuiteOrderId,
      netsuiteOrderNumber: netsuiteOrderItem.netsuiteOrderNumber,
      vendor: netsuiteOrderItem.vendorName,
      expectedDate: netsuiteOrderItem.expectedDeliveryDate,
    }));
};

export default function (state = initialState, action = { type: null }) {
  let today = new Date();
  today.setHours(0, 0, 0, 0); // set today to midnight
  let threeWeeksOut = addWeeks(today, 3);
  let fiveDaysAgo = subDays(today, 5);
  switch (action.type) {
    case NETSUITE_ORDER_ITEMS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case NETSUITE_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        fetching: false,
        results: action.response.openNetsuiteOrderItems,
        todaysNOs: reduceFilteredNOs(
          action.response.openNetsuiteOrderItems.filter((netsuiteOrderItem) =>
            isToday(parseISO(netsuiteOrderItem.expectedDeliveryDate))
          )
        ),
        threeWeeksOutNOs: reduceFilteredNOs(
          action.response.openNetsuiteOrderItems.filter((netsuiteOrderItem) =>
            isWithinInterval(parseISO(netsuiteOrderItem.expectedDeliveryDate), {
              start: fiveDaysAgo,
              end: threeWeeksOut,
            })
          )
        ),
      };
    case NETSUITE_ORDER_ITEM_STORE_SEARCH:
      var queryTerm = action.payload.searchQuery;
      return {
        ...state,
        queryResults: state.threeWeeksOutNOs.filter((po) =>
          po.netsuiteOrderNumber.includes(queryTerm)
        ),
      };
    case NETSUITE_ORDER_ITEM_CLEAR_SEARCH:
      return {
        ...state,
        queryResults: [],
      };
    case ADD_TRUCK:
      return {
        ...state,
        results: state.results.map((order) =>
          updateOrderItemTrucks(order, action.truck)
        ),
      };
    case TRUCK_PUT_SUCCESS:
      return {
        ...state,
        results: state.results.map((order) =>
          updateOrderItemTrucks(order, action.response.truck)
        ),
      };

    default:
      return state;
  }
}
