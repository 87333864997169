import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { sum, values } from 'lodash';

import {
  SMALL_PROGRESS_BAR_PIXEL_COUNT,
  INGREDIENT_ITEM_TYPE,
  MEAL_ITEM_TYPE,
  COMPOUND_INGREDIENTS_STEP_ORDER,
} from 'lib/constants';

import styles from './styles';
import ProgressBar from '../ProgressBar/ProgressBar';

const useStyles = makeStyles(styles);

const TotalProgress = ({ item, itemType }) => {
  const classes = useStyles();

  const completedFor = (itemTypeForProgress) => {
    if (itemTypeForProgress === INGREDIENT_ITEM_TYPE) {
      return item.completedCount;
    } else {
      return item.completedCountMinusOverage;
    }
  };

  const notStartedFor = (itemTypeForProgress) => {
    if (itemTypeForProgress === INGREDIENT_ITEM_TYPE) {
      return item.goalCount - item.completedCount;
    } else {
      return item.notStarted;
    }
  };

  const transformedProgressSteps = () => {
    const ingredientActivityCounts = {
      completed: completedFor(itemType),
      notStarted: notStartedFor(itemType),
    };
    return ingredientActivityCounts;
  };

  const renderFailedWarning = () => {
    if (item.failed > 0) {
      <p
        className={classNames(classes.itemCounts, classes.redProgressIconColor)}
      >
        <CancelOutlinedIcon
          className={classNames(
            classes.progressStepIcon,
            classes.redProgressIconColor
          )}
        />
        FAILED
      </p>;
    }
  };

  const renderOnHoldWarning = () => {
    if (item.onHold > 0) {
      return (
        <div
          className={classNames(
            classes.itemCounts,
            classes.blueProgressIconColor,
            classes.blueProgressIconPosition
          )}
        >
          <WarningAmberOutlinedIcon
            className={classNames(
              classes.progressStepIcon,
              classes.blueProgressIconColor
            )}
          />
          ON HOLD
        </div>
      );
    }
  };

  const renderOverageWarning = () => {
    if (
      itemType === MEAL_ITEM_TYPE &&
      item.completedCount > item.completedCountMinusOverage
    ) {
      return (
        <div
          className={classNames(
            classes.overageIcon,
            classes.overageIconContainer
          )}
        >
          <InfoOutlinedIcon
            className={classNames(classes.overageIcon, classes.greyStepIcon)}
          />
          <span>Ingredient Overage</span>
        </div>
      );
    }
  };

  const stepOrder = COMPOUND_INGREDIENTS_STEP_ORDER;

  const sortedStep = Object.entries(transformedProgressSteps()).sort((a, b) => {
    return stepOrder.indexOf(a[0]) - stepOrder.indexOf(b[0]);
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ProgressBar
        activityCounts={sortedStep}
        totalCount={sum(values(transformedProgressSteps()))}
        totalProgressBarLengthInPixels={SMALL_PROGRESS_BAR_PIXEL_COUNT}
      />
      <Grid item className={classes.itemCountsContainer}>
        <p className={classes.itemCounts}>
          {completedFor(itemType)} complete &nbsp; | &nbsp;
          {notStartedFor(itemType)} to goal &nbsp; | &nbsp;
          {item.goalCount} total
        </p>
        <div className={classes.progressSetpIconContainer}>
          {renderOnHoldWarning()}
          {renderFailedWarning()}
          {renderOverageWarning()}
        </div>
      </Grid>
    </Grid>
  );
};

TotalProgress.propTypes = {
  item: PropTypes.object.isRequired,
  itemType: PropTypes.string.isRequired,
};

export default TotalProgress;
