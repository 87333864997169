import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import classnames from 'classnames';

import { StickyBarContext } from 'components/shared/StickyBarProvider';
import { ArrowForwardIcon } from 'components/icons';
import { HC_GREEN, NEGATIVE_RED, GREY_500 } from 'lib/constants';
import { formatNumber } from 'lib/utils';

const OverviewCard = ({
  classes,
  weeklyProgress,
  title,
  isPartOfStandardMenu,
}) => {
  const sticky = useContext(StickyBarContext);

  const progressColorClass = (percentProgress, percentTarget) => {
    if (percentProgress >= 100 || percentProgress - percentTarget > 5) {
      return classes.aheadOfTarget;
    } else if (percentTarget - percentProgress > 5) {
      return classes.belowTarget;
    } else {
      return classes.onTarget;
    }
  };

  const progressColor = (percentProgress, percentTarget) => {
    if (percentProgress >= 100 || percentProgress - percentTarget > 5) {
      return HC_GREEN;
    } else if (percentTarget - percentProgress > 5) {
      return NEGATIVE_RED;
    } else {
      return GREY_500;
    }
  };

  return (
    <Card
      className={classnames(classes.card, {
        [classes.stickyCard]: sticky,
      })}
    >
      <span className={classes.cardTitle}>
        <div className={classes.subheading}>{title}</div>

        <ArrowForwardIcon className={classes.forwardArrow} color={GREY_500} />
      </span>
      {weeklyProgress.length > 0 ? (
        weeklyProgress.map((activity) => {
          const cleanActivityName = activity.activityName
            .replace(' ', '-')
            .toLowerCase();
          if (
            activity.activityName === 'Meal Bagging' &&
            !isPartOfStandardMenu
          ) {
            return (
              <div
                key={activity.activityName}
                className={classes.activityOverviewSnippet}
              >
                <div
                  className={classes.overviewDetails}
                  data-testid="no-meal-bagging-records"
                  id="no-meal-bagging-records"
                >
                  N/A
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={activity.activityName}
                data-testid={`${cleanActivityName}-overview-snippet`}
                className={classes.activityOverviewSnippet}
                style={{
                  borderColor: progressColor(
                    activity.percentCompleted,
                    activity.target
                  ),
                }}
              >
                <div className={classes.overviewDetails}>
                  {activity.activityName}
                  <span
                    className={progressColorClass(
                      activity.percentCompleted,
                      activity.target
                    )}
                  >
                    {activity.percentCompleted}%
                  </span>
                </div>
                <div className={classes.overviewDetails}>
                  {formatNumber(activity.completedCount)} /{' '}
                  {formatNumber(activity.goalCount)}
                </div>
              </div>
            );
          }
        })
      ) : (
        <div>No activities this week</div>
      )}
    </Card>
  );
};

OverviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
  weeklyProgress: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isPartOfStandardMenu: PropTypes.bool.isRequired,
};

export default OverviewCard;
