import { select } from '../styles';

export default {
  select: {
    ...select,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    fontWeight: 'normal',
  },
  container: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  selectControls: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
};
