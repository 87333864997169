import { MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  root: {
    fontFamily: 'Muli',
  },
  text: {
    padding: '10px 20px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  radioButtonGroup: {
    margin: '0 20px',
    padding: '5px',
  },
  radioButtonText: {
    textTransform: 'capitalize',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    root: {
      height: '120%',
      marginTop: '-30px',
    },
    text: {
      padding: '5px 10px',
    },
  },
};
