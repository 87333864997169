import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import format from 'date-fns/format';
import { getHeightColor } from 'components/WarehouseInventory/helpers'; // May want to move into this folder.
import { OPEN_ITEM_MOVES_REQUEST_CONTENT, BACK_BUTTON } from 'lib/constants';
import { Loading } from 'components/shared';

const ManageSearchPallet = ({
  classes,
  pallet,
  palletId,
  onFetchPallet,
  onResetSelectedPallet,
  fetchFailed,
  onSetTopBarContent,
  itemMoveRequest,
  history,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: null,
      rightContent: OPEN_ITEM_MOVES_REQUEST_CONTENT,
    });
  });

  const formatProductDate = () => {
    if (!pallet.productDate) return;
    const productDate = new Date(pallet.productDate);
    const productDateUTC = new Date(
      productDate.getTime() + productDate.getTimezoneOffset() * 60 * 1000
    );
    return ` - ${format(productDateUTC, 'MM/dd/yy')}`;
  };

  useEffect(() => {
    if (palletId) {
      onFetchPallet(palletId);
    }
    return () => onResetSelectedPallet();
  }, [onFetchPallet, onResetSelectedPallet, palletId]);

  const locationColor = useMemo(() => getHeightColor(pallet.location), [
    pallet.location,
  ]);

  if (fetchFailed) {
    return (
      <Card className={classes.card}>
        <p>Sorry, no pallet matching that id found</p>
      </Card>
    );
  }

  // Initial pallet is an empty object, '{}', when page is refreshed. This check
  // stops it from trying to pass in things like pallet.quantity below
  if (Object.keys(pallet).length === 0) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Card className={classes.card}>
        <div className={classes.header}>Lot {pallet.id}</div>
        <CardContent className={classes.upperCard}>
          <div className={classes.ingName}>
            {pallet.ingredientId && <span>{pallet.ingredientId} - </span>}
            <span>{pallet.item}</span>
          </div>
          {pallet.brand !== '-' && (
            <div className={classes.lightGrey}>{pallet.brand}</div>
          )}
          <hr className={classes.line} />
          <div className={classes.qtyContainer}>
            <div className={classes.qtyBold}>QTY: {pallet.quantity}</div>
            <div className={classes.qtyGrey}> @ {pallet.measuringUnit}</div>
          </div>
          <hr className={classes.line} />
          <div className={classes.qtyContainer}>
            <div className={classes.qtyBold}>
              PDate: {pallet.productDateType}
            </div>
            <div className={classes.qtyGrey}>{formatProductDate(pallet)}</div>
          </div>
          <hr className={classes.line} />
          <div className={classes.qtyContainer}>
            <span className={classes.textBold}>Location: </span>
            <div
              style={{ backgroundColor: locationColor }}
              className={classes.circle}
            />
            <span className={classes.lightGrey}>{pallet.location}</span>
          </div>
          <div>
            <Button
              variant="outlined"
              id="edit-pallet"
              className={classes.editButton}
              onClick={() => {
                history.push(`/warehouse_moves/pallet_search/${pallet.id}`);
              }}
            >
              Edit Pallet
            </Button>
            <Button
              variant="outlined"
              id="use-for-request"
              className={classes.requestButton}
              onClick={() => {
                history.push(
                  `/warehouse_moves/requests/${itemMoveRequest.id}/pallet/${pallet.id}`
                );
              }}
            >
              Use for Request
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

ManageSearchPallet.propTypes = {
  classes: PropTypes.object.isRequired,
  pallet: PropTypes.object,
};

ManageSearchPallet.defaultProps = {
  pallet: {},
};

export default ManageSearchPallet;
