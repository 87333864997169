import {
  DARK_HC_GREEN,
  GREY_200,
  HC_GREEN,
  BLACK,
  WHITE,
  MEDIA_QUERY_TABLET_OR_SMALLER,
  MEDIA_QUERY_PHONE_SCREEN,
  GREY_700,
} from 'lib/constants';
import { centeredLoading } from 'lib/styles';

export default {
  heading: {
    marginTop: '20px',
    marginBottom: '1.3rem',
    fontWeight: '500',
    lineHeight: '1.1',
    fontSize: '24px',
  },
  container: {
    fontFamily: 'Muli',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    width: '55%',
    maxWidth: '600px',
    minHeight: '200px',
    margin: '100px 0 0',
  },
  title: {
    padding: '0 20px 20px',
    borderBottom: `solid 1px ${GREY_200}`,
  },
  facility: {
    flexWrap: 'wrap',
    justifyContent: 'left ',
    margin: '0 20px 20px',
    minWidth: '100px',
    textTransform: 'uppercase',
    width: 'calc(100% - 40px)',
  },
  submitButton: {
    color: `${WHITE} !important`,
    margin: '20px',
    width: 'calc(100% - 40px)',
    height: '50px',
    backgroundColor: HC_GREEN,
    flex: 1,
    '&:disabled': {
      color: `${BLACK} !important`,
      backgroundColor: GREY_200,
    },
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  centeredLoading,
  preselectedInfo: {
    padding: '0px 20px',
    marginBottom: '10px',
  },
  preselectedLabel: {
    marginRight: '10px',
  },
  preselectedValue: {
    marginRight: '10px',
    color: GREY_700,
  },
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    root: {
      width: '80%',
    },
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    root: {
      margin: '25px 0 0',
      width: '80%',
    },
    title: {
      fontSize: '18px',
    },
  },
};
