import { combineReducers } from 'redux';

import audit from './audit/reducers';
import receiving from './receiving/reducers';
import summary from './summary/reducers';

export default combineReducers({
  audit,
  receiving,
  summary,
});
