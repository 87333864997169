import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { QRCodeIcon } from 'components/icons';
import {
  fetchItemPutback,
  createItemPutback,
  completeItemPutback,
  cancelItemPutback,
  createItemPutbackException,
  fetchParentPallet,
} from 'redux/guidedPutback/actions';
import { resetStorageSlots } from 'redux/storageSlot/actions';
import { FULL_PALLET, IN_PROGRESS_PUTBACK, DEACTIVATED } from 'lib/constants';
import { getFirstAndLastInitials } from 'lib/utils';
import {
  Loading,
  PalletScanTextField,
  SnackbarSimpleError,
  LocationInput,
  QuantityCalculator,
} from 'components/shared';

import DeactivatedBanner from './DeactivatedBanner';
import SuggestedPalletInfo from '../ViewRequest/SuggestedPalletInfo';
import WrongItemModal from '../WrongItemModal';
import styles from '../ViewRequest/styles';
import PalletIssueDialog from '../ViewRequest/PalletIssueDialog';
import DepthSelection from '../DepthSelection';
import AssignAndCloseButton from './AssignAndCloseButton';
import PickupLocationModal from '../PickupLocationModal';

const useStyles = makeStyles(styles);

const ItemPutback = ({ history, match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let {
    itemPutback,
    suggestedPallet,
    parentPallet,
    putbackCreateRequestStatus,
    exceptionCreateRequestStatus,
    putbackCancelRequestStatus,
    parentPalletRequestStatus,
    itemPutbackRequestStatus,
    updating,
    updatingFailed,
  } = useSelector((state) => state.guidedPutback);

  let { depthOptions } = useSelector((state) => state.itemMoveRequests);

  let { user_name } = useSelector((state) => state.session);

  let dropZoneManagementEnabled = useSelector(
    (state) => state.session.experiments?.drop_zone_management
  );

  useEffect(() => {
    !itemPutback.id &&
      match.params?.putbackId &&
      dispatch(fetchItemPutback(match.params.putbackId));
  }, [match]);

  useEffect(() => {
    match.params?.childPalletId &&
      dispatch(fetchParentPallet(match.params.childPalletId));
  }, [match.params?.childPalletId]);

  const [scannedPalletId, setScannedPalletId] = useState('');
  const [quantityCalculatorOpen, setQuantityCalculatorOpen] = useState(false);
  const [depthOptionsOpen, setDepthOptionsOpen] = useState(false);
  const [wrongPalletModalOpen, setWrongPalletModalOpen] = useState(false);
  const [pickupLocationModalOpen, setPickupLocationModalOpen] = useState(false);
  const [locationNumber, setLocationNumber] = useState('');
  const [selectedDepth, setSelectedDepth] = useState({});
  const [newPalletAssigned, setNewPalletAssigned] = useState(false);
  const [originalSuggestedPallet, setOriginalSuggestedPallet] = useState({});
  const hasParentPallet = !isEmpty(parentPallet);
  const hasSuggestedPallet = !isEmpty(suggestedPallet);
  const suggestedPalletIsDeactivated = suggestedPallet?.status === DEACTIVATED;

  // This is to check whether we've assigned a new suggested pallet.
  useEffect(() => {
    if (isEmpty(originalSuggestedPallet)) {
      setOriginalSuggestedPallet(suggestedPallet);
    }
    if (
      hasSuggestedPallet &&
      originalSuggestedPallet?.id != suggestedPallet?.id
    ) {
      setNewPalletAssigned(true);
    } else {
      setNewPalletAssigned(false);
    }
  }, [originalSuggestedPallet, suggestedPallet]);

  const itemPutbackExists = Boolean(itemPutback?.id);

  const isFullPallet = itemPutbackExists
    ? itemPutback.category === FULL_PALLET
    : !match.params?.childPalletId?.startsWith('C-');

  const isCases = !isFullPallet;

  const goToQueue = () => {
    history.push('/warehouse_moves/putbacks');
  };

  const onQuantityCalculatorSubmit = async (inputValue) => {
    const response = await dispatch(
      completeItemPutback(itemPutback.id, {
        added_cases: inputValue,
        putback_pallet_id: suggestedPallet.id,
        storage_slot_id: selectedDepth.id,
      })
    );

    if (response.ok) {
      setQuantityCalculatorOpen(false);
      goToQueue();
    }
  };

  const currentUserIsRunner = itemPutback?.runner === user_name;

  const processPalletScan = (palletId) => {
    setScannedPalletId(palletId);

    if (palletId.length > 0) {
      let correctPallet = palletId == suggestedPallet.id;
      setWrongPalletModalOpen(!correctPallet);
      setQuantityCalculatorOpen(correctPallet);
    }
  };

  useEffect(() => {
    if (depthOptions.length > 0) {
      setDepthOptionsOpen(true);
    }
  }, [depthOptions]);

  useEffect(() => {
    return () => dispatch(resetStorageSlots());
  }, []);

  const onDepthSubmit = () => {
    setDepthOptionsOpen(false);
    setQuantityCalculatorOpen(true);
  };

  const onCloseWrongItemModal = () => {
    setScannedPalletId('');
    setWrongPalletModalOpen(false);
  };

  const onCloseQuantityCalculator = () => {
    setQuantityCalculatorOpen(false);
  };

  const onClosePickupLocationModal = () => {
    setPickupLocationModalOpen(false);
  };

  const closeModal = () => {
    setDepthOptionsOpen(false);
  };

  const actionIsFetching = Boolean(
    putbackCreateRequestStatus.fetching || putbackCancelRequestStatus.fetching
  );
  const actionError = Boolean(
    putbackCreateRequestStatus.failed || putbackCancelRequestStatus.failed
  );

  const isAssigned = Boolean(itemPutback?.runner);

  const onCreatePutback = async (pickupLocationId) => {
    const response = await dispatch(
      createItemPutback(match.params.childPalletId, pickupLocationId)
    );

    if (response.ok) {
      setPickupLocationModalOpen(false);
      history.replace(
        `/warehouse_moves/putbacks/in_progress/${response.itemPutback.id}`
      );
    }
  };

  const onAssignPutback = () => {
    if (dropZoneManagementEnabled) {
      setPickupLocationModalOpen(true);
    } else {
      onCreatePutback();
    }
  };

  const onClosePutback = async () => {
    const response = await dispatch(cancelItemPutback(itemPutback.id));
    response.ok && goToQueue();
  };

  const onReportPalletIssue = (reasonCode) => {
    return dispatch(
      createItemPutbackException(itemPutback.id, suggestedPallet.id, reasonCode)
    );
  };

  const itemPutbackClosed =
    itemPutbackExists && itemPutback.status !== IN_PROGRESS_PUTBACK;

  const actionsDisabled =
    !itemPutbackExists || itemPutbackClosed || !currentUserIsRunner;

  const actionsEnabled = !actionsDisabled;

  const modalsClosed =
    !quantityCalculatorOpen &&
    !depthOptionsOpen &&
    !wrongPalletModalOpen &&
    !pickupLocationModalOpen;

  const canScan = actionsEnabled && modalsClosed;

  const renderScanPalletToComplete = () => {
    return (
      <div>
        <PalletScanTextField
          clearAfterScan
          canScan={canScan}
          onScan={processPalletScan}
        />
        <div className={classes.qrCodeAndMessage}>
          <QRCodeIcon className={classes.icon} />
          {'Scan Pallet'}
        </div>
      </div>
    );
  };

  const renderScanLocationToComplete = () => {
    return (
      <Fragment>
        <LocationInput
          isActivePallet
          canScan={canScan}
          iconMessage="Scan Location"
          locationNumber={locationNumber}
          palletId={suggestedPallet.id}
          setLocationNumber={setLocationNumber}
        />
        <Dialog
          open={depthOptionsOpen}
          onClose={closeModal}
          classes={{ paper: classes.dialog }}
        >
          <DepthSelection
            onCancel={closeModal}
            selectedDepthId={selectedDepth.id}
            setSelectedDepth={setSelectedDepth}
            locationBaseName={locationNumber}
            history={history}
            onSubmit={onDepthSubmit}
            setLocationNumber={setLocationNumber}
          />
        </Dialog>
      </Fragment>
    );
  };

  if (parentPalletRequestStatus.fetching || itemPutbackRequestStatus.fetching) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  }

  if (parentPalletRequestStatus.failed) {
    return (
      <div className={classes.centered}>
        Sorry, no pallet with that id was found
      </div>
    );
  }

  return (
    hasParentPallet && (
      <div>
        <Card className={classes.card}>
          <div className={classes.header}>Pallet Location</div>
          <DeactivatedBanner
            pallet={suggestedPallet}
            palletIsDeactivated={suggestedPalletIsDeactivated}
          />
          <CardContent className={classes.cardContent}>
            <span className={classes.ingName}>
              {parentPallet.orderableName}
            </span>
            <span className={classes.text}>{parentPallet.brand}</span>
            <span className={classes.ingredientId}>
              ID: {parentPallet.orderableId}
            </span>
            <hr className={classes.line} />
            {newPalletAssigned && hasSuggestedPallet && (
              <span className={classes.ingName}>New Pallet Assign.</span>
            )}
            <SuggestedPalletInfo
              suggestedPallet={suggestedPallet}
              showLastLocation={isFullPallet || suggestedPalletIsDeactivated}
            />
            <div className={classes.rightSide}>
              {itemPutback.status === IN_PROGRESS_PUTBACK && (
                <Chip
                  className={classNames(classes.chip, {
                    [classes.myChip]: currentUserIsRunner,
                  })}
                  label={getFirstAndLastInitials(itemPutback.runner)}
                />
              )}
            </div>
          </CardContent>
          <CardActions>
            <AssignAndCloseButton
              isAssigned={isAssigned}
              onAssignPutback={onAssignPutback}
              onClosePutback={onClosePutback}
              disabled={itemPutbackClosed || actionIsFetching}
            />
          </CardActions>
        </Card>
        <Card className={classes.card}>
          <div className={classes.header}>Putback</div>
          <CardContent className={classes.cardContent}>
            {actionsEnabled && (
              <Fragment>
                {isCases && renderScanPalletToComplete()}
                {isFullPallet && renderScanLocationToComplete()}
              </Fragment>
            )}
          </CardContent>
        </Card>
        <PickupLocationModal
          isOpen={pickupLocationModalOpen}
          closeModal={onClosePickupLocationModal}
          onSubmit={onCreatePutback}
          parentPalletId={parentPallet.id}
        />
        <WrongItemModal
          isOpen={wrongPalletModalOpen}
          closeModal={onCloseWrongItemModal}
          pallet={{ id: scannedPalletId }}
          suggestedPallet={suggestedPallet}
        />
        {isCases && (
          <PalletIssueDialog
            suggestedPallet={suggestedPallet}
            onSubmitIssue={onReportPalletIssue}
            disabled={actionsDisabled}
            loading={exceptionCreateRequestStatus.fetching}
            failed={exceptionCreateRequestStatus.failed}
          />
        )}
        <QuantityCalculator
          title="Number to Put Back"
          subtitle={`Pallet ${suggestedPallet.id} @ ${
            selectedDepth.name || suggestedPallet.location
          }`}
          selectedOperators={['add']}
          defaultOperator="add"
          submitText="Put Back $ Cases"
          submitValueType="input-value"
          quantity={suggestedPallet.quantity}
          submitQuantity={onQuantityCalculatorSubmit}
          isOpen={quantityCalculatorOpen}
          closeModal={onCloseQuantityCalculator}
          loading={updating}
          failed={updatingFailed}
        />
        <SnackbarSimpleError open={!actionIsFetching && actionError} />
      </div>
    )
  );
};

ItemPutback.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

ItemPutback.defaultProps = {
  history: {},
  match: {},
};

export default ItemPutback;
