import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const PodLocationQuestion = ({
  podLocations,
  internalLocationName,
  setInternalLocationName,
  questionText,
}) => {
  return (
    <Fragment>
      <h3>{questionText}</h3>
      <TextField
        id="podSelectionField"
        data-testid="pod-selection-field"
        select
        value={internalLocationName}
        label="Choose a location"
        fullWidth
        variant="outlined"
        onChange={(event) => setInternalLocationName(event.target.value)}
      >
        {podLocations?.map((pod) => (
          <MenuItem
            id={`pod-${pod.description}`}
            key={pod.description}
            value={pod.description}
          >
            {pod.description}
          </MenuItem>
        ))}
      </TextField>
    </Fragment>
  );
};

PodLocationQuestion.defaultProps = {
  questionText: 'Where was this inventory packed?',
};

PodLocationQuestion.propTypes = {
  podLocations: PropTypes.array.isRequired,
  internalLocationName: PropTypes.string.isRequired,
  setInternalLocationName: PropTypes.func.isRequired,
  questionText: PropTypes.string,
};

export default PodLocationQuestion;
