import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { filterSearchResultsByPackingFacilityId } from 'redux/loginField/selectors';
import { searchUsers, clearUserSearchResults } from 'redux/loginField/actions';

import styles from './styles';
import UserSearch from './UserSearch';

const mapStateToProps = (state) => ({
  searching: state.loginFields.searching,
  userSearchResults: filterSearchResultsByPackingFacilityId(
    state.loginFields.userSearchResults,
    state.session.packing_facility_id
  ),
});

const mapDispatchToProps = {
  onSearchUsers: searchUsers,
  onClearResults: clearUserSearchResults,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UserSearch)
);
