import {
  NETSUITE_ORDER_STORE_SEARCH,
  NETSUITE_ORDER_QUERY_GET_REQUEST,
  NETSUITE_ORDER_QUERY_GET_SUCCESS,
  NETSUITE_ORDER_QUERY_GET_ERROR,
  NETSUITE_ORDER_CLEAR_SEARCH,
} from 'lib/constants';

export const initialState = {
  poFetchingQuery: false,
  queryResults: [],
  queryTerm: '',
  poFetchQueryFailed: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case NETSUITE_ORDER_QUERY_GET_REQUEST:
      return {
        ...state,
        queryingNos: true,
        queryingNosFailed: false,
      };
    case NETSUITE_ORDER_QUERY_GET_SUCCESS:
      return {
        ...state,
        queryingNos: false,
        queryResults: action.response.netsuiteOrders,
      };
    case NETSUITE_ORDER_QUERY_GET_ERROR:
      return {
        ...state,
        queryingNos: false,
        queryingNosFailed: true,
      };
    case NETSUITE_ORDER_STORE_SEARCH:
      return {
        ...state,
        queryTerm: action.payload.searchQuery,
      };
    case NETSUITE_ORDER_CLEAR_SEARCH:
      return {
        ...state,
        queryResults: [],
      };
    default:
      return state;
  }
}
