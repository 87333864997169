import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const byFacility = async ({ packingFacilityId, menuId }) => {
  const url = `${API_BASE_URI}/ops/qa/packing_facilities/${packingFacilityId}/menus/${menuId}/meals_and_ingredients`;
  const response = await fetch(url, options);
  return await handleResponse(response);
};

export default {
  byFacility,
};
