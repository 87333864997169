import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';

import AppointmentSubmissionForm from '../AppointmentSubmissionForm';

const AppointmentDrawer = ({
  classes,
  open,
  setOpen,
  appointment,
  onSetSelectedAppt,
  prefilledDate,
}) => {
  const closeDrawer = () => {
    setOpen(false);
    onSetSelectedAppt(null);
  };

  const header = () =>
    appointment ? 'Appointment Details' : 'Create Appointment';

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      classes={{ paper: classes.noScroll }}
    >
      <div className={classes.root}>
        <div className={classes.topSection}>
          <header>{header()}</header>
          <TabOutlineButton
            className={classes.closeButton}
            type="button"
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.icon} />
          </TabOutlineButton>
        </div>
        <div className={classes.bodySection}>
          <AppointmentSubmissionForm
            prefilledDate={prefilledDate}
            appointment={appointment}
            closeDrawer={closeDrawer}
          />
        </div>
      </div>
    </Drawer>
  );
};

AppointmentDrawer.defaultProps = {
  open: false,
  appointment: null,
};

AppointmentDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool,
  appointment: PropTypes.object,
  onSetSelectedAppt: PropTypes.func.isRequired,
  prefilledDate: PropTypes.instanceOf(Date).isRequired,
};

export default AppointmentDrawer;
