import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import { postProductionPlansLead } from 'redux/productionPlans/actions';

import UserSearch from '../../shared/UserSearch';
import styles from './styles';

const TeamDetailsCard = ({
  classes,
  productionPlans,
  shift,
  menuSelection,
  onPostProductionPlansLead,
}) => {
  const [supervisor, setSupervisor] = useState({});
  const [teamLead, setTeamLead] = useState({});

  const productionPlan = productionPlans[0];
  const productionPlanSupervisor = productionPlan?.supervisor;
  const productionPlanTeamLead = productionPlan?.teamLead;

  let baseParams;

  if (productionPlan) {
    const { menuId, mealId, date, shiftId } = productionPlan;

    baseParams = {
      shiftId,
      menuId,
      mealId,
      date,
    };
  }

  const handleSelectSupervisor = useCallback(
    (newSupervisor) => {
      setSupervisor(newSupervisor);
      onPostProductionPlansLead({
        ...baseParams,
        supervisor: newSupervisor,
      });
    },
    [onPostProductionPlansLead, baseParams]
  );

  const handleSelectTeamLead = useCallback(
    (newTeamLead) => {
      setTeamLead(newTeamLead);
      onPostProductionPlansLead({
        ...baseParams,
        teamLead: newTeamLead,
      });
    },
    [onPostProductionPlansLead, baseParams]
  );

  const handleClearSupervisor = () => {
    setSupervisor({});
    onPostProductionPlansLead({
      ...baseParams,
      supervisor: { id: null },
    });
  };

  const handleClearTeamLead = () => {
    setTeamLead({});
    onPostProductionPlansLead({
      ...baseParams,
      teamLead: { id: null },
    });
  };

  useEffect(() => {
    setSupervisor({ name: productionPlanSupervisor });
  }, [productionPlanSupervisor]);

  useEffect(() => {
    setTeamLead({ name: productionPlanTeamLead });
  }, [productionPlanTeamLead]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h3">
            Team Details
          </Typography>
          {isEmpty(productionPlans) ? (
            <p className={classNames(classes.heavyFont, classes.centeredText)}>
              Upload a plan for this shift to assign team members
            </p>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item sm={4} md={3}>
                SUPERVISOR
              </Grid>
              <Grid item sm={4} md={3}>
                TEAM LEAD
              </Grid>
              <Grid item sm={2} md={3}>
                SHIFT
              </Grid>
              <Grid item sm={2} md={3}>
                MEAL TYPE
              </Grid>
              <Grid item sm={4} md={3} className={classes.userSearchInput}>
                <UserSearch
                  canCreate={false}
                  nameSelected={supervisor.name}
                  onSelectUser={handleSelectSupervisor}
                  onClearUser={handleClearSupervisor}
                />
              </Grid>
              <Grid item sm={4} md={3} className={classes.userSearchInput}>
                <UserSearch
                  canCreate={false}
                  nameSelected={teamLead.name}
                  onSelectUser={handleSelectTeamLead}
                  onClearUser={handleClearTeamLead}
                />
              </Grid>
              <Grid item sm={2} md={3}>
                <span className={classes.shift}>{shift?.name}</span>
              </Grid>
              <Grid item sm={2} md={3}>
                <span className={classes.mealType}>
                  {menuSelection.mealPlan}
                </span>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};

TeamDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  productionPlans: PropTypes.array.isRequired,
  menuSelection: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onPostProductionPlansLead: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  shift: state.shift.results.find(
    (shift) => shift.name === ownProps.match.params.shiftName
  ),
});

const mapDispatchToProps = {
  onPostProductionPlansLead: postProductionPlansLead,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamDetailsCard));
