import React, { useContext, useState, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import { ITEM_MANAGER_WASTE_MOVE } from 'lib/constants';

import {
  ItemManagerFormContext,
  fieldNames,
} from '../../ItemManagerFormProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const FormLocationSelectors = ({ locations, defaultSelectionId }) => {
  const classes = useStyles();

  const { actions } = useContext(ItemManagerFormContext);
  const [selectedValue, setSelectedValue] = useState(defaultSelectionId);
  const formField = fieldNames.newLocation;
  const setSelectedLocation = (value) => actions.setField(formField, value);
  const setOverrideMoveType = (value) =>
    actions.setField(fieldNames.overrideMoveType, value);

  const handleToggleSelect = (_, newValue) => {
    setSelectedValue(newValue);
  };

  useEffect(() => {
    if (selectedValue === ITEM_MANAGER_WASTE_MOVE) {
      setSelectedLocation(null);
      setOverrideMoveType(selectedValue);
    } else {
      setSelectedLocation(selectedValue);
      setOverrideMoveType(null);
    }
  }, [selectedValue]);

  return (
    <div className={classes.selectors}>
      <div className={classes.title}>Where are the cases going?</div>
      <ToggleButtonGroup
        exclusive
        className={classes.buttonGroup}
        value={selectedValue}
        onChange={handleToggleSelect}
      >
        {locations.map((location) => (
          <ToggleButton
            className={classNames(classes.button)}
            key={location.id}
            value={location.id}
          >
            {location.description}
          </ToggleButton>
        ))}
        <ToggleButton
          className={classNames(classes.button, classes.wasteButton)}
          key={ITEM_MANAGER_WASTE_MOVE}
          value={ITEM_MANAGER_WASTE_MOVE}
        >
          Send to Waste
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

FormLocationSelectors.propTypes = {
  locations: PropTypes.array.isRequired,
  defaultSelectionId: PropTypes.number,
};

FormLocationSelectors.defaultProps = {
  defaultSelectionId: null,
};

export default FormLocationSelectors;
