export function randomNumber(places) {
  return Math.floor(Math.random() * Math.floor(places));
}

export function percentProgress(
  goalCount,
  remainingCount,
  showOver100 = false
) {
  let percentage =
    goalCount > 0 ? (100 * (goalCount - remainingCount)) / goalCount : 0;
  if (percentage && !showOver100 && percentage > 100) {
    percentage = 100;
  }
  return percentage;
}

export function stringToNumber(inputNum) {
  if (typeof inputNum === 'number') {
    return inputNum;
  } else {
    return parseInt(inputNum.replace(/,/g, ''), 10);
  }
}

export function formatNumber(number) {
  if (!number) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const numberWithCommas = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isNonNegativeInteger = (value) => {
  value = parseFloat(value);
  return !(value < 0 || value % 1 != 0 || isNaN(value));
};

export const numberValidToDecimalPlace = (
  number,
  decimalPlaces,
  negativeAllowed = false
) => {
  // prettier-ignore
  const negativeAllowedModifier = negativeAllowed ? '\-?' : ''; //eslint-disable-line

  // eslint is less than enthused about the following regular expression
  const regex = RegExp(
    `^${negativeAllowedModifier}\\d+(\.\\d{0,${decimalPlaces}})?$` //eslint-disable-line
  );
  return regex.test(number);
};

export function formatPercentage(percent) {
  return Number.isFinite(percent) ? `${Math.round(percent)}%` : '-';
}
