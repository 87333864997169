import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { fetchMealsByFacilityAndMenuId } from 'redux/qa/portionMeals/actions';
import { selectMenu, fetchMenusAndSelectFirst } from 'redux/menu/actions';

import StandardMenuButtons from './StandardMenuButtons';

const StandardMenuButtonsContainer = ({
  menus,
  onFetchMenusAndSelectFirst,
  onMenuSelected,
}) => {
  useEffect(() => {
    onFetchMenusAndSelectFirst();
  }, []);

  return (
    <StandardMenuButtons
      menus={menus}
      location={location}
      onMenuSelected={onMenuSelected}
    />
  );
};
StandardMenuButtonsContainer.propTypes = {
  menus: PropTypes.object.isRequired,
  onFetchMenusAndSelectFirst: PropTypes.func.isRequired,
  onMenuSelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  meals: state.meals,
  session: state.session,
});

const mapDispatchToProps = {
  onFetchMealsByFacilityAndMenuId: fetchMealsByFacilityAndMenuId,
  onMenuSelected: selectMenu,
  onFetchMenusAndSelectFirst: fetchMenusAndSelectFirst,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StandardMenuButtonsContainer)
);
