import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { MenuItem, FormControl, Select } from '@mui/material';

import {
  COMPOUND_INGREDIENTS,
  INGREDIENTS,
  MEALS,
  RTH_PROGRESS_TRACKER_HOME,
  FIVE_MINUTE_INTERVAL,
} from 'lib/constants';

import ExpandAndCollapsibleButtons from '../ExpandAndCollapsibleButtons';
import MenuNavigation from '../MenuNavigation';
import Ingredients from '../Ingredients';
import Meals from '../Meals';
import styles from './styles';

const useStyles = makeStyles(styles);

const displaySelection = ({ selection, menuId, expandAll, trigger }) => {
  switch (selection) {
    case COMPOUND_INGREDIENTS:
      window.history.replaceState(
        null,
        '',
        `${RTH_PROGRESS_TRACKER_HOME}/${COMPOUND_INGREDIENTS}/${menuId}/${INGREDIENTS.toLowerCase()}`
      );
      return <Ingredients expandAll={expandAll} trigger={trigger} />;
    case MEALS:
      window.history.replaceState(
        null,
        '',
        `${RTH_PROGRESS_TRACKER_HOME}/${COMPOUND_INGREDIENTS}/${menuId}/${MEALS}`
      );
      return <Meals expandAll={expandAll} trigger={trigger} />;
  }
};

const CompoundIngredients = () => {
  const classes = useStyles();
  const [selection, setSelection] = useState('');
  const [expandAll, setExpandAll] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const { menuId } = useParams();

  const handleChange = ({ target }) => {
    const { value } = target;

    setSelection(value);
  };

  useEffect(() => {
    if (window.location.pathname.match(MEALS)) {
      setSelection(MEALS);
    } else {
      setSelection(COMPOUND_INGREDIENTS);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      location.reload();
    }, FIVE_MINUTE_INTERVAL);
    return () => clearInterval(interval);
  });

  return (
    <>
      <MenuNavigation path={COMPOUND_INGREDIENTS} />
      <div className={classes.container}>
        <div className={classes.selectControls}>
          <FormControl>
            <Select
              value={selection}
              data-testid="select-options"
              onChange={handleChange}
              autoWidth
              displayEmpty
              className={classes.select}
            >
              <MenuItem
                data-testid="select-rth-ingredient"
                value={COMPOUND_INGREDIENTS}
              >
                Sort By Ingredient
              </MenuItem>
              <MenuItem data-testid="select-rth-meal" value={MEALS}>
                Sort By Meal
              </MenuItem>
            </Select>
          </FormControl>
          <ExpandAndCollapsibleButtons
            setExpandAll={setExpandAll}
            setTrigger={setTrigger}
            trigger={trigger}
          />
        </div>
        {menuId &&
          displaySelection({
            selection,
            menuId,
            expandAll,
            trigger,
          })}
      </div>
    </>
  );
};

export default CompoundIngredients;
