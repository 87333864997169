import {
  LOGIN_FIELDS_REQUEST,
  LOGIN_FIELDS_SUCCESS,
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_ERROR,
  CLEAR_SEARCH_USERS,
} from 'lib/constants';

export const initialState = {
  facilities: [],
  shifts: [],
  roles: [],
  fetching: false,
  userSearchResults: [],
  searching: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case LOGIN_FIELDS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case LOGIN_FIELDS_SUCCESS:
      return {
        ...state,
        fetching: false,
        facilities: action.response.facilities,
        roles: action.response.roles,
        shifts: action.response.shifts,
      };
    case SEARCH_USERS_REQUEST:
      return {
        ...state,
        searching: true,
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searching: false,
        userSearchResults: action.response.userSearchResults,
      };
    case SEARCH_USERS_ERROR:
      return {
        ...state,
        searching: false,
      };
    case CLEAR_SEARCH_USERS:
      return {
        ...state,
        userSearchResults: [],
      };
    default:
      return state;
  }
}
