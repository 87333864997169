import React from 'react';
import PropTypes from 'prop-types';

const QAPersonnelTrackingIcon = (props) => {
  const { className = 'QAPersonnelTrackingIcon' } = props;
  return (
    <div className={className}>
      <svg width="100%" height="100%" viewBox="0 0 123 90">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(64.852 2)">
            <path
              d="M56.305 58.983c0-15.634-12.604-28.308-28.153-28.308C12.604 30.675 0 43.349 0 58.983s56.305 15.634 56.305 0z"
              stroke="#008600"
              strokeWidth={3}
              fill="#E6F3E6"
            />
            <ellipse
              stroke="#008600"
              strokeWidth={3}
              fill="#FFF"
              cx={28.152}
              cy={17.287}
              rx={17.255}
              ry={17.287}
            />
            <path
              d="M26.385 57.153l1.307-1.158-1.656-1.303c.534-.416.951-.967 1.252-1.655.301-.688.452-1.498.452-2.43v-.445c0-.978-.177-1.84-.53-2.588-.354-.747-.853-1.32-1.499-1.72-.645-.4-1.383-.6-2.213-.6-.83 0-1.568.2-2.213.6-.645.4-1.145.973-1.498 1.72-.354.747-.53 1.612-.53 2.594v.494c.004.96.184 1.808.54 2.546.356.738.857 1.306 1.505 1.704.648.397 1.384.596 2.21.596.26 0 .51-.02.752-.062l2.121 1.707zm-2.873-3.331c-.703 0-1.24-.281-1.615-.843-.374-.562-.56-1.353-.56-2.372v-.487c.004-1.037.193-1.824.567-2.36.374-.538.905-.806 1.594-.806.693 0 1.227.272 1.601.815.374.544.561 1.34.561 2.386v.486c-.004 1.038-.191 1.827-.56 2.369-.37.541-.9.812-1.588.812zm7.074 1.549l.684-2.056h3.599l.69 2.056h2.183l-3.728-9.98h-1.902l-3.709 9.98h2.183zm3.729-3.722h-2.49l1.238-3.736 1.252 3.736z"
              fill="#008600"
              fillRule="nonzero"
            />
          </g>
          <g transform="translate(2 2)">
            <path
              d="M56.305 58.983c0-15.634-12.604-28.308-28.153-28.308C12.604 30.675 0 43.349 0 58.983s56.305 15.634 56.305 0z"
              stroke="#008600"
              strokeWidth={3}
              fill="#E6F3E6"
            />
            <ellipse
              stroke="#008600"
              strokeWidth={3}
              fill="#FFF"
              cx={28.152}
              cy={17.287}
              rx={17.255}
              ry={17.287}
            />
            <path
              d="M26.385 57.153l1.307-1.158-1.656-1.303c.534-.416.951-.967 1.252-1.655.301-.688.452-1.498.452-2.43v-.445c0-.978-.177-1.84-.53-2.588-.354-.747-.853-1.32-1.499-1.72-.645-.4-1.383-.6-2.213-.6-.83 0-1.568.2-2.213.6-.645.4-1.145.973-1.498 1.72-.354.747-.53 1.612-.53 2.594v.494c.004.96.184 1.808.54 2.546.356.738.857 1.306 1.505 1.704.648.397 1.384.596 2.21.596.26 0 .51-.02.752-.062l2.121 1.707zm-2.873-3.331c-.703 0-1.24-.281-1.615-.843-.374-.562-.56-1.353-.56-2.372v-.487c.004-1.037.193-1.824.567-2.36.374-.538.905-.806 1.594-.806.693 0 1.227.272 1.601.815.374.544.561 1.34.561 2.386v.486c-.004 1.038-.191 1.827-.56 2.369-.37.541-.9.812-1.588.812zm7.074 1.549l.684-2.056h3.599l.69 2.056h2.183l-3.728-9.98h-1.902l-3.709 9.98h2.183zm3.729-3.722h-2.49l1.238-3.736 1.252 3.736z"
              fill="#008600"
              fillRule="nonzero"
            />
          </g>
          <g transform="translate(32.117 14.112)">
            <path
              d="M58.924 61.336c0-16.257-13.19-29.436-29.462-29.436S0 45.079 0 61.336c0 16.257 58.924 16.257 58.924 0z"
              stroke="#008600"
              strokeWidth={3}
              fill="#E6F3E6"
            />
            <ellipse
              stroke="#008600"
              strokeWidth={3}
              fill="#FFF"
              cx={29.462}
              cy={18.025}
              rx={18.04}
              ry={18.025}
            />
            <path
              d="M27.434 59.353l1.308-1.156-1.657-1.299c.534-.415.952-.965 1.253-1.651.301-.687.452-1.495.452-2.425v-.444c0-.976-.177-1.836-.53-2.582-.355-.745-.854-1.317-1.5-1.716-.645-.399-1.383-.598-2.214-.598-.83 0-1.568.2-2.214.598-.646.399-1.145.971-1.499 1.716-.354.746-.53 1.609-.53 2.589v.492c.004.957.184 1.804.54 2.54.356.737.858 1.303 1.506 1.7.648.396 1.385.595 2.21.595.26 0 .512-.02.754-.062l2.121 1.703zM24.56 56.03c-.703 0-1.241-.28-1.615-.842-.375-.56-.562-1.349-.562-2.366v-.485c.005-1.035.194-1.82.568-2.356.375-.536.906-.803 1.595-.803.694 0 1.228.27 1.602.813.374.543.56 1.336.56 2.38v.486c-.004 1.034-.19 1.822-.56 2.362-.37.54-.9.81-1.588.81zm7.077 1.545l.684-2.051h3.6l.692 2.051h2.183l-3.73-9.956h-1.903l-3.71 9.956h2.184zm3.73-3.713h-2.491l1.238-3.727 1.253 3.727z"
              fill="#008600"
              fillRule="nonzero"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

QAPersonnelTrackingIcon.propTypes = {
  className: PropTypes.string,
};

QAPersonnelTrackingIcon.defaultProps = {
  className: 'QAPersonnelTrackingIcon',
};

export default QAPersonnelTrackingIcon;
