import { GREY_200 } from 'lib/constants';

const buttonOverlay = {
  borderRadius: '4px',
  boxSizing: 'border-box',
  border: `1px solid ${GREY_200}`,
};

export default {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  cameraButton: {
    height: '55px',
    width: '180px',
    textTransform: 'none',
    fontFamily: 'Muli',
    fontSize: '16px',
  },
  picturesButton: {
    marginRight: '10px',
    height: '55px',
    width: '55px',
    fontFamily: 'Muli',
    fontSize: '16px',
    overflow: 'hidden',
    cursor: 'default',
  },
  cameraIcon: {
    color: GREY_200,
    marginRight: '10px',
  },
  thumbnail: {
    ...buttonOverlay,
    width: '72px',
    zIndex: -1,
    position: 'absolute',
  },
  buttonThumbnailText: {
    ...buttonOverlay,
    height: '55px',
    width: '64px',
    background: 'rgba(0,0,0,0.2)',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonWithPicture: {
    padding: 0,
    border: 0,
    cursor: 'pointer',
  },
  loadingContainer: {
    width: '64px',
    marginRight: '10px',
  },
  gallery: {
    maxWidth: '604px',
  },
};
