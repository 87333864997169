import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import ContentModal from 'components/shared/ContentModal';
import { printPallet } from 'redux/guidedPutback/actions';

import PalletIssueView from './PalletIssueView';
import IssueReportedView from './IssueReportedView';
import NoViablePalletsView from './NoViablePalletsView';
import styles from '../styles';

const useStyles = makeStyles(styles);

const PALLET_ISSUE_VIEW = 'palletIssueView';
const ISSUE_REPORTED_VIEW = 'issueReportedView';
const NO_VIABLE_PALLETS_VIEW = 'noViablePalletsView';

const PalletIssueDialog = ({
  suggestedPallet,
  itemMoveRequest,
  onSubmitIssue,
  loading,
  failed,
  disabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [contentView, setContentView] = useState(PALLET_ISSUE_VIEW);

  const closeModal = () => {
    setShow(false);
    setContentView(PALLET_ISSUE_VIEW);
  };

  const reportIssueSubmit = (reasonCode) => {
    onSubmitIssue(reasonCode).then((results) => {
      if (results.ok) {
        let showNoViable =
          isEmpty(itemMoveRequest) && isEmpty(results.suggestedPallet);

        showNoViable
          ? setContentView(NO_VIABLE_PALLETS_VIEW)
          : setContentView(ISSUE_REPORTED_VIEW);
      }
    });
  };

  const onReprintPallet = (palletId, printer, itemPutbackId) => {
    dispatch(printPallet(palletId, printer, itemPutbackId));
    closeModal();
  };

  return (
    <Fragment>
      <Button
        id="report-pallet-issue-btn"
        classes={{ root: classes.reportPalletIssueButton }}
        onClick={() => setShow(true)}
        disabled={disabled || !suggestedPallet?.id}
      >
        Report Pallet Issue
      </Button>
      <ContentModal open={show} onClose={closeModal}>
        <Box className={classes.palletIssueDialogModal}>
          {contentView == NO_VIABLE_PALLETS_VIEW && (
            <NoViablePalletsView onReprintPallet={onReprintPallet} />
          )}
          {contentView == ISSUE_REPORTED_VIEW && (
            <IssueReportedView
              onCloseModal={closeModal}
              itemMoveRequest={itemMoveRequest}
              suggestedPallet={suggestedPallet}
            />
          )}
          {contentView == PALLET_ISSUE_VIEW && (
            <PalletIssueView
              suggestedPallet={suggestedPallet}
              itemMoveRequest={itemMoveRequest}
              onReportIssueSubmit={reportIssueSubmit}
              loading={loading}
              failed={failed}
              disabled={disabled}
              onCloseModal={closeModal}
            />
          )}
        </Box>
      </ContentModal>
    </Fragment>
  );
};

PalletIssueDialog.propTypes = {
  itemMoveRequest: PropTypes.object,
  suggestedPallet: PropTypes.shape({
    id: PropTypes.number,
    storageArea: PropTypes.string,
  }),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  failed: PropTypes.bool,
  onSubmitIssue: PropTypes.func.isRequired,
};

PalletIssueDialog.defaultProps = {
  itemMoveRequest: {},
  suggestedPallet: {},
  loading: false,
  disabled: false,
  failed: false,
};

export default PalletIssueDialog;
