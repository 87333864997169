import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { pluralize } from 'lib/utils';
import { StatefulButton } from 'components/shared';

import styles from './styles';

const InventorySubmitButton = ({
  count,
  item,
  disabled,
  setDialogOpen,
  classes,
}) => {
  const btnCountText = (displayCount) => {
    const num = parseInt(displayCount);
    return Math.abs(num) === 0 || isNaN(num)
      ? 'Enter Quantity'
      : `Submit ${pluralize(num, item)}`;
  };

  const submitButtonText = {
    LOADING: 'Loading...',
    SUCCESS: 'Success',
    ERROR: 'There was an error submitting meal bags',
    DEFAULT: btnCountText(count),
  };

  return (
    <StatefulButton
      disabled={disabled}
      buttonTextOptions={submitButtonText}
      loading={false}
      failed={false}
      type="button"
      onClick={() => setDialogOpen(true)}
      classes={{ root: classes.submitButton }}
      data-testid="submit-button"
    />
  );
};

InventorySubmitButton.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  item: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InventorySubmitButton);
