import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';

import ContentModal from 'components/shared/ContentModal';

import styles from './styles';

const useStyles = makeStyles(styles);

const PrinterOverrideDialog = ({
  open,
  handleClose,
  onTryReprint,
  onOverride,
}) => {
  const classes = useStyles();

  return (
    <ContentModal
      data-testid="printer-override-dialog"
      open={open}
      onClose={handleClose}
    >
      <div>Unable to print child license plate. Please check printer.</div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.reprintButton}
          variant="contained"
          onClick={onTryReprint}
        >
          Try Reprinting
        </Button>
        <Button
          className={classes.overrideButton}
          variant="contained"
          onClick={onOverride}
        >
          Override
        </Button>
      </div>
    </ContentModal>
  );
};

PrinterOverrideDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onTryReprint: PropTypes.func.isRequired,
  onOverride: PropTypes.func.isRequired,
};

export default PrinterOverrideDialog;
