import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DEACTIVATED } from 'lib/constants';

const DeactivatedBanner = ({ classes, pallet }) => {
  const inactiveBannerText = () => {
    const baseText = 'This Pallet Has Been';

    if (pallet.status === DEACTIVATED) {
      return `${baseText} Deleted ${pallet.deactivatedAt}`;
    } else {
      return `${baseText} Placed On Hold ${pallet.heldAt}`;
    }
  };

  return (
    <div
      className={classNames(
        classes.inactivePalletBanner,
        classes[pallet.status.replace(/ /g, '')]
      )}
    >
      {inactiveBannerText()}
    </div>
  );
};

DeactivatedBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  pallet: PropTypes.shape({
    heldAt: PropTypes.string,
    deactivatedAt: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default DeactivatedBanner;
