import { MEDIA_QUERY_TABLET_OR_SMALLER } from 'lib/constants';

export default {
  scanningArea: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px 0px',
    width: '80%',
  },
  iconContainer: {
    textAlign: 'center',
    fontSize: '1em',
  },
  palletNotFound: {
    opacity: 0,
    height: 0,
  },
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    inputRoot: {
      opacity: 0,
      height: 0,
    },
    input: {
      height: 0,
      padding: 0,
    },
    scanningArea: {
      width: '100%',
      padding: '0px',
    },
  },
};
