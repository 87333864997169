import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';

import styles from './styles';

const QaTopBar = ({ text, selectedMenu, classes }) => {
  const whichApp = window.location.href.includes('portioning')
    ? 'portioning'
    : 'meal_bagging';
  const pathname = selectedMenu.id
    ? `/qa/${whichApp}/menu/${selectedMenu.id}`
    : `/qa/${whichApp}`;

  return (
    <AppBar className={classes.header}>
      <h4 align="center">
        <Link to={pathname}>
          <ArrowBack className={classes.arrow} />
        </Link>
        {text}
      </h4>
    </AppBar>
  );
};

QaTopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  selectedMenu: PropTypes.object.isRequired,
};

export default withStyles(styles)(QaTopBar);
