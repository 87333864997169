import { useMemo } from 'react';

import {
  PACKAGING_ORDER_TYPE,
  DEFAULT_PACKAGING_SHELF_LIFE_TYPE,
} from 'lib/constants';

export const ADD_PALLET_LINE = 'ADD_PALLET_LINE';
export const DELETE_PALLET_LINE = 'DELETE_PALLET_LINE';
export const UPDATE_PALLET_LINE = 'UPDATE_PALLET_LINE';
export const UPDATE_PRODUCT_DATE = 'UPDATE_PRODUCT_DATE';
export const UPDATE_ROTATION_TYPE = 'UPDATE_ROTATION_TYPE';
export const RESET_PALLET_LINES = 'RESET_PALLET_LINES';
export const UPDATE_TRUCK_SELECTION = 'UPDATE_TRUCK_SELECTION';
export const PALLET_WARNING_THRESHOLD = 40;
export const PALLET_NOT_ALLOWED_THRESHOLD = 200;

export const INPUT_NAMES = {
  quantity: 'quantity',
  units: 'unit_id',
  numberPallets: 'number_of_pallets',
};

export const usePalletTotals = (palletLines, existingPalletQuantity) => {
  const totalPallets = useMemo(
    () =>
      palletLines
        .map((line) => Number(line[INPUT_NAMES.numberPallets]))
        .reduce((accumulator, amount) => accumulator + amount),
    [palletLines]
  );

  const totalQuantity = useMemo(() => {
    const formPalletQuantity = palletLines
      .map(
        (line) =>
          Number(line[INPUT_NAMES.quantity]) *
          Number(line[INPUT_NAMES.numberPallets])
      )
      .reduce((accumulator, amount) => accumulator + amount);
    return formPalletQuantity + existingPalletQuantity;
  }, [palletLines, existingPalletQuantity]);

  return [totalPallets, totalQuantity];
};

export const createEmptyPalletLine = (id) => {
  return {
    [INPUT_NAMES.quantity]: '',
    [INPUT_NAMES.units]: '',
    [INPUT_NAMES.numberPallets]: '',
    id,
  };
};

export const initialState = {
  nextId: 1,
  palletLines: [createEmptyPalletLine(0)],
  productDate: null,
  productDateType: '',
  truckId: '',
};

// these initial values depend on props that will never change during the component's lifecycle
export const getInitialState = ({
  netsuiteOrderType,
  defaultProductDateType,
}) => ({
  ...initialState,
  productDateType:
    netsuiteOrderType === PACKAGING_ORDER_TYPE
      ? DEFAULT_PACKAGING_SHELF_LIFE_TYPE
      : defaultProductDateType || '',
  productDate: netsuiteOrderType === PACKAGING_ORDER_TYPE ? new Date() : null,
});

export const reducer = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case ADD_PALLET_LINE:
      return {
        ...state,
        nextId: state.nextId + 1,
        palletLines: [
          ...state.palletLines,
          createEmptyPalletLine(state.nextId),
        ],
      };
    case DELETE_PALLET_LINE:
      return {
        ...state,
        palletLines: state.palletLines.filter(
          (palletLine) => palletLine.id !== action.id
        ),
      };
    case UPDATE_PALLET_LINE:
      return {
        ...state,
        palletLines: state.palletLines.map((palletLine) => {
          if (palletLine.id !== action.id) {
            return palletLine;
          }
          return { ...palletLine, [action.field]: action.value };
        }),
      };
    case UPDATE_PRODUCT_DATE:
      return {
        ...state,
        productDate: action.value,
      };
    case UPDATE_ROTATION_TYPE:
      return {
        ...state,
        productDateType: action.value,
      };
    case RESET_PALLET_LINES:
      return initialState;
    case UPDATE_TRUCK_SELECTION:
      return {
        ...state,
        truckId: action.value,
      };
    default:
      return state;
  }
};
