import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/ReportProblemRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';

import {
  TABLET_SCREEN_OR_SMALLER,
  APPROVED,
  FLAGGED,
  AUDIT,
  AUDITED,
  NEEDS_AUDIT,
} from 'lib/constants';

import { netsuiteItemShape } from '../NetsuiteItemLine/NetsuiteItemLine';
import NetsuiteItemLine from '../NetsuiteItemLine';

const NetsuiteOrderLine = ({
  classes,
  netsuiteOrder,
  onUpdateNetsuiteItem,
  onOrderToggle,
  whichTab,
  last,
  truck,
  expanded,
  auditTrucks,
}) => {
  const tabletScreenOrSmaller = useMediaQuery(TABLET_SCREEN_OR_SMALLER);
  const [numberApproved, setNumberApproved] = useState(0);
  const [numberFlagged, setNumberFlagged] = useState(0);

  useEffect(() => {
    let approved = 0;
    let flagged = 0;
    netsuiteOrder.netsuiteItems.forEach((item) => {
      const trucksAllAudited = !auditTrucks.some(
        (auditTruck) => auditTruck.itemReceiptStatuses[item.id] === NEEDS_AUDIT
      );

      if (
        (whichTab !== AUDIT && item.auditStatus === APPROVED) ||
        (whichTab === AUDIT && item.auditStatus === AUDITED) ||
        (whichTab === AUDIT && trucksAllAudited)
      ) {
        approved += 1;
      } else if ((whichTab !== AUDIT && item.auditStatus) === FLAGGED) {
        flagged += 1;
      }
    });

    setNumberApproved(approved);
    setNumberFlagged(flagged);
  }, [
    setNumberFlagged,
    setNumberApproved,
    netsuiteOrder.netsuiteItems,
    whichTab,
    auditTrucks,
  ]);

  const [isExpanded, setExpanded] = useState(true);

  const handleExpandedForSummaryChange = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Accordion
      expanded={whichTab === AUDIT ? expanded : isExpanded}
      onChange={() =>
        whichTab === AUDIT
          ? onOrderToggle(netsuiteOrder.id)
          : handleExpandedForSummaryChange()
      }
      classes={{
        root: classes.orderExpander,
      }}
      data-testid="netsuite-order-line"
    >
      <AccordionSummary
        className={classes.orderSummary}
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.summaryContent }}
      >
        <span className={classes.bold}>{netsuiteOrder.netsuiteNumber}</span>
        <span className={classes.separator}>|</span>
        <span>{netsuiteOrder.vendor}</span>
        <span className={classes.separator}>|</span>
        {whichTab === AUDIT ? (
          <span
            className={classNames({
              [classes.green]:
                numberApproved === netsuiteOrder.netsuiteItems.length,
            })}
          >{`${numberApproved}/${netsuiteOrder.netsuiteItems.length} Approved`}</span>
        ) : null}
        {numberFlagged > 0 && (
          <span className={classes.flagContainer}>
            <span className={classes.numberFlagged}>{numberFlagged}</span>
            <WarningIcon className={classes.flagged} />
          </span>
        )}
      </AccordionSummary>
      <div className={classes.itemHeader}>
        <span className={classes.spanTwo}>Item</span>
        <span className={classes.spanOne}>Pallets</span>
        <span className={classes.spanTwo}>PO UOM</span>
        <span className={classes.spanOne}>
          {tabletScreenOrSmaller ? 'Exp.' : 'Expected'}
        </span>
        <span className={classes.spanOne}>
          {tabletScreenOrSmaller ? 'Rej.' : 'Rejected'}
        </span>
        <span className={classes.spanOne}>Final</span>
        <span className={classes.itemAudit}>Audit</span>
      </div>
      {netsuiteOrder.netsuiteItems.map((netsuiteItem, index) => (
        <NetsuiteItemLine
          key={netsuiteItem.id}
          netsuiteItem={netsuiteItem}
          netsuiteOrderId={netsuiteOrder.id}
          onUpdateNetsuiteItem={onUpdateNetsuiteItem}
          grey={index % 2 == 1}
          whichTab={whichTab}
          last={index === netsuiteOrder.netsuiteItems.length - 1 && last}
          summaryTruck={truck}
        />
      ))}
    </Accordion>
  );
};

export const netsuiteOrderShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  netsuiteNumber: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  netsuiteItems: PropTypes.arrayOf(netsuiteItemShape).isRequired,
});

NetsuiteOrderLine.propTypes = {
  classes: PropTypes.object.isRequired,
  netsuiteOrder: netsuiteOrderShape.isRequired,
  onUpdateNetsuiteItem: PropTypes.func.isRequired,
  whichTab: PropTypes.oneOf(['summary', 'audit']).isRequired,
  last: PropTypes.bool,
  truck: PropTypes.object,
  onOrderToggle: PropTypes.func,
  expanded: PropTypes.bool,
  auditTrucks: PropTypes.array.isRequired,
};

NetsuiteOrderLine.defaultProps = {
  last: null,
  truck: null,
  onOrderToggle: null,
  expanded: null,
};

export default NetsuiteOrderLine;
