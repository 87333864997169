import { makeApiRequest } from 'lib/api';
import {
  COUNT_LIST_CREATE,
  COUNT_LIST,
  COUNT_LISTS,
  COUNT_LIST_UPDATE,
  CLEAR_CYCLE_COUNT_INFO,
  COUNT_LIST_DELETE,
  UPDATE_PALLET_LOCATION,
  REFRESH_COUNT_LIST,
} from 'lib/constants';

import cycleCounting from './services';

export const fetchCountLists = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: COUNT_LISTS,
        method: cycleCounting.getCountLists,
        params: {},
      })
    );
  };
};

export const fetchCountList = (countListId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: COUNT_LIST,
        method: cycleCounting.getCountList,
        params: { countListId },
      })
    );
  };
};

export const postCountList = (values) => {
  return makeApiRequest({
    type: COUNT_LIST_CREATE,
    method: cycleCounting.postCountList,
    params: values,
  });
};

export const completeCountList = (countListId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: COUNT_LIST_UPDATE,
        method: cycleCounting.updateCountList,
        params: { countListId, payload: { complete_count_list: true } },
      })
    );
  };
};

export const clearCycleCountInfo = () => ({
  type: CLEAR_CYCLE_COUNT_INFO,
});

export const updatePalletLocation = (countListId, palletId, storageSlotId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: UPDATE_PALLET_LOCATION,
        method: cycleCounting.updatePalletLocation,
        params: {
          countListId,
          payload: { pallet_id: palletId, storage_slot_id: storageSlotId },
        },
      })
    );
  };
};

export const refreshCountList = (countListId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: REFRESH_COUNT_LIST,
        method: cycleCounting.refreshCountList,
        params: {
          countListId,
        },
      })
    );
  };
};

export const deleteCountList = (countListId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: COUNT_LIST_DELETE,
        method: cycleCounting.deleteCountList,
        params: { countListId },
      })
    );
  };
};
