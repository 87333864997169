import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { setTopBarContent } from 'redux/topBar/actions';
import { getDockAppointmentsForDate } from 'redux/dockScheduler/actions';

import styles from './styles';
import DockScheduler from './DockScheduler';

const mapStateToProps = (state) => ({
  unscheduledPOs: state.dockScheduler.unscheduledPOs,
  appointments: state.dockScheduler.appointments,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onGetAppointmentsForDate: getDockAppointmentsForDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DockScheduler));
