import * as Sentry from '@sentry/react';
import React from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRouterDOMRoute } from 'react-router-dom';

import MoveQueueFilter from 'components/WarehouseMoves/MoveQueueFilter';
import PalletSearch from 'components/WarehouseInventory/PalletSearch';
import { WAREHOUSE_MOVES_CONTENT } from 'lib/constants';

import AccountSwitch from '../AccountSwitch';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const WarehouseMovesContent = ({ classes, parent }) => {
  return (
    <li className={classes.root}>
      {parent !== WAREHOUSE_MOVES_CONTENT && <MoveQueueFilter />}
      {parent !== WAREHOUSE_MOVES_CONTENT && (
        <PalletSearch link="/warehouse_moves/pallet_search" />
      )}
      {parent === WAREHOUSE_MOVES_CONTENT && (
        <Route
          path={[
            '/warehouse_moves/requests/open/:requestId',
            '/warehouse_moves/pallet_search/:palletId/manage/:requestId',
          ]}
          render={(routingProps) => (
            <PalletSearch
              link="/warehouse_moves/pallet_search"
              parent={parent}
              {...routingProps}
            />
          )}
        />
      )}
      <AccountSwitch />
    </li>
  );
};

WarehouseMovesContent.propTypes = {
  classes: PropTypes.object.isRequired,
  parent: PropTypes.string,
};

WarehouseMovesContent.defaultProps = {
  parent: '',
};

export default WarehouseMovesContent;
