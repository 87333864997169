import {
  WHITE,
  GREY_800,
  GREY_200,
  HC_GREEN,
  DARK_HC_GREEN,
  LIGHT_GREEN,
} from 'lib/constants';

export default {
  card: {
    margin: '5px',
  },
  header: {
    backgroundColor: GREY_800,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    display: 'flex',
    border: `1px solid ${GREY_200}`,
    height: '50px',
    fontSize: '16px',
    textTransform: 'none',
    width: '33%',
    margin: '0 5px',
  },
  submitButton: {
    display: 'flex',
    height: '50px',
    border: 'none',
    background: HC_GREEN,
    color: WHITE,
    fontSize: '16px',
    textTransform: 'none',
    width: '67%',
    marginRight: '5px',
    '&:disabled': {
      color: WHITE,
      backgroundColor: LIGHT_GREEN,
    },
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    lineHeight: 'normal',
  },
};
