import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Edit from '@mui/icons-material/Edit';
import classNames from 'classnames';

const TruckSummaryButton = ({ value, onClick, classes, field, className }) => {
  const hasValue = value !== null && value && value.length !== 0;
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      className={classNames(className, classes.button, {
        [classes.createButton]: !hasValue,
      })}
    >
      {!hasValue ? (
        <span>Set {field}</span>
      ) : (
        <span className={classes.editButtonContent}>
          <span className={classes.editButtonText}>
            {field}: {value}
          </span>
          <Edit className={classes.editIcon} />
        </span>
      )}
    </Button>
  );
};

TruckSummaryButton.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TruckSummaryButton.defaultProps = {
  className: '',
  value: '',
};

export default TruckSummaryButton;
