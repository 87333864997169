import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import { SNACK_BAR_DURATION } from 'lib/constants';

const QaSnackBar = (props) => {
  const { snackBarActivated, checkCount } = props;

  const [snackBarOpen, setSnackBarOpen] = React.useState(snackBarActivated);

  const handleClose = (event, reason) => {
    setSnackBarOpen(false);
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackBarOpen}
        autoHideDuration={SNACK_BAR_DURATION}
        onClose={handleClose}
        message={`Completed ${checkCount} ${
          checkCount === 1 ? 'Check' : 'Checks'
        }`}
        action={[
          <IconButton
            onClick={handleClose}
            key="close"
            aria-label="close"
            color="inherit"
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

QaSnackBar.propTypes = {
  snackBarActivated: PropTypes.bool.isRequired,
  checkCount: PropTypes.number.isRequired,
};

export default QaSnackBar;
