import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import withStyles from '@mui/styles/withStyles';

import { StatefulButton } from 'components/shared';
import { WAREHOUSE_DELETION_OPTIONS } from 'lib/constants';

import styles from './styles';

const PalletDetailsDeleteButton = ({
  deactivating,
  deactivationFailed,
  onPalletDeactivate,
  history,
  palletId,
  classes,
}) => {
  const deleteButtonText = {
    DEFAULT: 'Delete Pallet',
    SUCCESS: 'Success!',
    LOADING: 'Saving',
    ERROR: 'Failed',
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDeletionOption, setSelectedDeletionOption] = useState({});

  const handleDialogClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setSelectedDeletionOption({});
    }, 300);
  };

  const requestPalletDeletion = (deletionType, deletionReason = null) => {
    handleDialogClose();
    onPalletDeactivate(palletId, deletionType, deletionReason);
  };

  const setDeletionOption = (deletionType) => {
    const deletionOption = WAREHOUSE_DELETION_OPTIONS.find(
      (opt) => opt.type === deletionType
    );
    setSelectedDeletionOption(deletionOption);

    if (!deletionOption.reasons) {
      requestPalletDeletion(deletionOption.type, null);
    }
  };

  const redirectToInventoryManager = () => {
    history.push('/inventory-manager/');
  };

  return (
    <Fragment>
      <StatefulButton
        type="button"
        onClick={handleDialogClick}
        buttonTextOptions={deleteButtonText}
        loading={deactivating}
        successCallback={redirectToInventoryManager}
        failed={deactivationFailed}
        classes={{
          root: classNames(classes.button, classes.delete),
        }}
      />
      <Dialog
        data-testid="pallet-details-delete-dialog"
        open={dialogOpen}
        onClose={handleDialogClose}
        classes={{ paperScrollPaper: classes.muiPaper }}
      >
        {Object.keys(selectedDeletionOption).length === 0 && (
          <Fragment>
            <DialogContent classes={{ root: classes.promptContainer }}>
              <DialogContentText classes={{ root: classes.promptText }}>
                Select deletion reason:
              </DialogContentText>
            </DialogContent>
            <DialogContent classes={{ root: classes.deletionOptionsContainer }}>
              {WAREHOUSE_DELETION_OPTIONS.map((option) => (
                <Button
                  key={option.type}
                  classes={{ root: classes.modalButtons }}
                  onClick={() => setDeletionOption(option.type)}
                  color="neutral"
                >
                  {option.type}
                </Button>
              ))}
            </DialogContent>
          </Fragment>
        )}
        {selectedDeletionOption.reasons && (
          <Fragment>
            <DialogContent classes={{ root: classes.promptContainer }}>
              <DialogContentText classes={{ root: classes.promptText }}>
                Select reason:
              </DialogContentText>
            </DialogContent>
            <DialogContent classes={{ root: classes.deletionOptionsContainer }}>
              {selectedDeletionOption.reasons.map((reason) => (
                <Button
                  key={reason}
                  classes={{ root: classes.modalButtons }}
                  onClick={() =>
                    requestPalletDeletion(selectedDeletionOption.type, reason)
                  }
                >
                  {reason}
                </Button>
              ))}
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
};

PalletDetailsDeleteButton.propTypes = {
  deactivating: PropTypes.bool.isRequired,
  deactivationFailed: PropTypes.bool.isRequired,
  onPalletDeactivate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  palletId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PalletDetailsDeleteButton);
