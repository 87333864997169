import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const DeactivatedBanner = ({ pallet, palletIsDeactivated }) => {
  const classes = useStyles();

  const combineStatusTypeAndReason = () => {
    if (!pallet.statusReason) {
      return pallet.statusType;
    } else {
      return pallet.statusType + ' ' + pallet.statusReason;
    }
  };
  return (
    <div>
      {palletIsDeactivated && (
        <div className={classNames(classes.deactivatedPalletBanner)}>
          {combineStatusTypeAndReason() + ' - ' + pallet.deactivatedAt}
        </div>
      )}
    </div>
  );
};

DeactivatedBanner.propTypes = {
  pallet: PropTypes.shape({
    deactivatedAt: PropTypes.string,
    status: PropTypes.string,
    statusType: PropTypes.string,
  }).isRequired,
  palletIsDeactivated: PropTypes.bool.isRequired,
};

export default DeactivatedBanner;
