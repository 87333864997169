export const ITEM_MANAGER_NOT_LESS_THAN_ZERO = 'lessThanZero';
export const ITEM_MANAGER_GREATER_THAN_ZERO = 'greaterThanZero';
export const ITEM_MANAGER_QUANTITY_REQUIRED = 'quantityRequired';
export const ITEM_MANAGER_NEW_LOCATION_REQUIRED = 'newLocationRequired';
export const ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY =
  'cannotExceedAvailableQuantity';

export const ITEM_MANAGER_VALIDATION_OPTIONS = [
  ITEM_MANAGER_NOT_LESS_THAN_ZERO,
  ITEM_MANAGER_GREATER_THAN_ZERO,
  ITEM_MANAGER_QUANTITY_REQUIRED,
  ITEM_MANAGER_NEW_LOCATION_REQUIRED,
  ITEM_MANAGER_CANNOT_EXCEED_AVAILABLE_QUANTITY,
];
