import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getNetsuiteOrders = async ({ packingFacilityId }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/summary_audit`,
      {
        is_audit: true,
      }
    ),
    options
  );

  return await handleResponse(response);
};

export default {
  getNetsuiteOrders,
};
