import get from 'lodash/get';
import flatMap from 'lodash/flatMap';

import { selectPortioningProgressByIngredient } from 'redux/meal/selectors';
import {
  PRODUCTION_PROGRESS_REQUEST,
  PRODUCTION_PROGRESS_SUCCESS,
  ALL_PANELS_EXPANDED,
  ALL_PANELS_COLLAPSED,
  PANEL_TOGGLED,
} from 'lib/constants';

const initialState = {
  meals: [],
  customizeItIngredients: [],
  retailMeals: [],
  fetching: false,
  expandedPanels: [],
};

export const MEAL_TYPE = 'meal';
export const CUSTOMIZE_IT_INGREDIENT_TYPE = 'customize_it_ingredient';
export const INGREDIENT_TYPE = 'ingredient';

const expandAll = (meals, customizeItIngredients, byPortioningIngredients) => {
  const expandedMeals = meals.map((meal) => ({
    type: MEAL_TYPE,
    id: meal.id,
  }));
  const expandedCustomizeItIngredients = customizeItIngredients.map(
    (ingredient) => ({
      type: CUSTOMIZE_IT_INGREDIENT_TYPE,
      id: ingredient.id,
    })
  );
  const expandedIngredients = flatMap(
    Object.values(byPortioningIngredients),
    (ing) => ({
      type: INGREDIENT_TYPE,
      id: `${ing[0].ingredientId} - ${ing[0].quantity} ${ing[0].unit}`,
    })
  );
  return expandedMeals
    .concat(expandedCustomizeItIngredients)
    .concat(expandedIngredients);
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case PRODUCTION_PROGRESS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case PRODUCTION_PROGRESS_SUCCESS:
      return {
        ...state,
        meals: get(action, [
          'response',
          'ecommerce',
          'mealsProductionProgress',
        ]),
        customizeItIngredients: get(action, [
          'response',
          'ecommerce',
          'customizeItProductionProgress',
        ]),
        retailMeals: get(action, [
          'response',
          'retail',
          'mealsProductionProgress',
        ]),
        fetching: false,
      };
    case ALL_PANELS_EXPANDED:
      return {
        ...state,
        expandedPanels: expandAll(
          state.meals,
          state.customizeItIngredients,
          selectPortioningProgressByIngredient(state)
        ),
      };
    case ALL_PANELS_COLLAPSED:
      return {
        ...state,
        expandedPanels: [],
      };
    case PANEL_TOGGLED: {
      const expanded = state.expandedPanels
        .map((panel) =>
          panel.type === action.payload.panel.type ? panel.id : ''
        )
        .includes(action.payload.panel.id)
        ? state.expandedPanels.filter((el) => {
            if (
              action.payload.panel.id === el.id &&
              action.payload.panel.type === el.type
            ) {
              return false;
            }
            return true;
          })
        : state.expandedPanels.concat(action.payload.panel);
      return {
        ...state,
        expandedPanels: expanded,
      };
    }
    default:
      return state;
  }
};
