import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { fetchMenus } from 'redux/menu/actions';
import { submitMoveRequests } from 'redux/itemMoveRequests/actions';
import {
  getIngredientAssignments,
  getPickableIngredientAssignments,
} from 'redux/ingredientAssignments/actions';
import { getPackagingItems } from 'redux/packagingItems/actions';

import styles from './styles';
import Requests from './Requests';

const mapStateToProps = (state) => ({
  podLocations: state.podLocations.pods.filter((pod) => pod.storageSlotId),
  selectedMenu: state.menus.selectedMenu,
  ingredientAssignments: state.ingredientAssignments.warehouseRequests.results,
  pickableIngredientAssignments:
    state.ingredientAssignments.warehouseRequests.pickableResults,
  packagingItems: state.packagingItems.warehouseRequests.results,
  iaFetching: state.ingredientAssignments.warehouseRequests.fetching,
  itemMoveRequests: state.itemMoveRequests.itemMoveRequests,
  piFetching: state.packagingItems.warehouseRequests.fetching,
  packingFacilityId: state.session.packing_facility_id,
});

const mapDispatchToProps = {
  onFetchMenus: fetchMenus,
  onGetIngredientAssignments: getIngredientAssignments,
  onGetPickableIngredientAssignments: getPickableIngredientAssignments,
  onSubmitMoveRequests: submitMoveRequests,
  onGetPackagingItems: getPackagingItems,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Requests));
