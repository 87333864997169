import React, { Fragment, useReducer, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

import { TRUCK_TRAILER_SEAL_OPTIONS } from 'lib/constants';
import { StatefulButton, TimePickerWrapper } from 'components/shared';
import { formatTimeAsHHMM } from 'lib/utils';

import TruckOrderList from '../TruckOrderList';
import {
  reducer,
  getInitialState,
  isFormValid,
  RESET_FORM,
  UPDATE_NOS,
  UPDATE_INPUT,
  ARRIVAL_TIME,
  DOCK_IN_TIME,
  STOP_TIME,
  TRAILER_GAUGE,
  TRAILER_TEMP_INTERNAL,
  TRAILER_SEAL,
  DOCK_DOOR_ID,
  formatTruckOrders,
} from './formUtils';

const TruckSubmissionForm = ({
  classes,
  truck,
  updateTruck,
  createTruck,
  posting,
  postFailed,
  updating,
  updateFailed,
  closeDrawer,
  preselectedNO,
  clearPreselected,
  dockDoors,
}) => {
  const modifyTruck = useMemo(() => {
    const newTruck = cloneDeep(truck);
    newTruck.removeNetsuiteOrders = [];
    return newTruck;
  }, [truck]);

  const [state, dispatch] = useReducer(
    reducer,
    isEmpty(truck) ? getInitialState() : modifyTruck
  );

  useEffect(
    () => {
      if (preselectedNO) {
        dispatch({
          type: UPDATE_NOS,
          netsuiteOrders: [...state.netsuiteOrders, preselectedNO],
          removeNetsuiteOrders: [],
        });
        clearPreselected();
      }
    },
    // We dont want to re-run this just because netsuiteOrders in state changed
    // eslint-disable-next-line
    [dispatch, preselectedNO, clearPreselected]
  );

  const buildData = (currentState) => {
    const dock_out_time = isNil(currentState.dockOutTime)
      ? null
      : formatTimeAsHHMM(currentState.dockOutTime);

    const data = {
      truck: {
        arrival_time: formatTimeAsHHMM(currentState.arrivalTime),
        dock_in_time: formatTimeAsHHMM(currentState.dockInTime),
        stop_time: formatTimeAsHHMM(currentState.stopTime),
        dock_out_time: dock_out_time,
        trailer_seal: currentState.trailerSeal,
        dock_door_id: currentState.dockDoorId,
        trailer_temp_gauge: currentState.trailerTempGauge,
        trailer_temp_internal: currentState.trailerTempInternal,
        truck_netsuite_orders_attributes: formatTruckOrders(
          currentState.netsuiteOrders,
          currentState.removeNetsuiteOrders
        ),
      },
    };
    if (currentState.id) {
      data.id = currentState.id;
    }
    return data;
  };

  const fetching = posting || updating;
  const failed = postFailed || updateFailed;
  const formValid = useMemo(() => isFormValid(state), [state]);
  const numberInvalid = (string) => {
    return !Number(string) && !isEmpty(string);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = buildData(state);

    if (truck.id) {
      updateTruck(data);
    } else {
      createTruck(data);
    }
  };

  useEffect(() => {
    return () => dispatch({ type: RESET_FORM });
  }, []);

  const renderTextField = (params) => (
    <>
      <label className={classes.textFieldWrapper} htmlFor={params.id}>
        {params.label}
        <TextField {...params} className={classes.timePicker} label="" />
      </label>
    </>
  );

  return (
    <Fragment>
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <div className={classes.infoValue}>
          <TimePickerWrapper
            label="ARRIVAL TIME"
            id="arrivalTime"
            name="arrivalTime"
            value={state.arrivalTime}
            renderInput={renderTextField}
            onAccept={(time) => {
              dispatch({
                type: UPDATE_INPUT,
                field: ARRIVAL_TIME,
                value: time,
              });
            }}
          />
          <TimePickerWrapper
            label="DOCK-IN TIME"
            id="dockInTime"
            name="dockInTime"
            value={state.dockInTime}
            renderInput={renderTextField}
            onAccept={(time) =>
              dispatch({
                type: UPDATE_INPUT,
                field: DOCK_IN_TIME,
                value: time,
              })
            }
          />
          <TimePickerWrapper
            label="UNLOADED STOP"
            name="stopTime"
            value={state.stopTime}
            renderInput={renderTextField}
            onAccept={(time) =>
              dispatch({
                type: UPDATE_INPUT,
                field: STOP_TIME,
                value: time,
              })
            }
          />
        </div>
        <TruckOrderList
          selectedOrders={state.netsuiteOrders}
          ordersToRemove={state.removeNetsuiteOrders}
          updateSelectedNOs={(netsuiteOrders, poToRemove) =>
            dispatch({
              type: UPDATE_NOS,
              netsuiteOrders: netsuiteOrders,
              removeNetsuiteOrders: poToRemove,
            })
          }
        />
        <div className={classes.itemContainer}>
          <label
            className={classes.textFieldWrapper}
            htmlFor="trailerTempGauge"
          >
            TRAILER TEMP GAUGE
            <TextField
              classes={{ root: classes.tempInput }}
              error={numberInvalid(state.trailerTempGauge)}
              id="trailerTempGauge"
              data-testid="trailer-temp-gauge"
              name="trailerTempGauge"
              type="text"
              value={state.trailerTempGauge || ''}
              variant="outlined"
              onChange={(e) =>
                dispatch({
                  type: UPDATE_INPUT,
                  field: TRAILER_GAUGE,
                  value: e.target.value,
                })
              }
            />
          </label>
          <label
            className={classes.textFieldWrapper}
            htmlFor="trailerTempInternal"
          >
            INTERNAL TRAILER TEMP
            <TextField
              classes={{ root: classes.tempInput }}
              error={numberInvalid(state.trailerTempInternal)}
              id="trailerTempInternal"
              name="trailerTempInternal"
              type="text"
              variant="outlined"
              value={state.trailerTempInternal || ''}
              onChange={(e) =>
                dispatch({
                  type: UPDATE_INPUT,
                  field: TRAILER_TEMP_INTERNAL,
                  value: e.target.value,
                })
              }
            />
          </label>
          <label className={classes.textFieldWrapper} htmlFor="dockDoor">
            DOCK DOOR
            <TextField
              classes={{ root: classes.dockDoorInput }}
              name="dockDoor"
              variant="outlined"
              margin="normal"
              id="dockDoor"
              data-testid="dock-door"
              select
              value={state.dockDoorId || ''}
              SelectProps={{
                MenuProps: { className: classes.menuItem },
              }}
              className={classes.skinnyTextField}
              onChange={(e) =>
                dispatch({
                  type: UPDATE_INPUT,
                  field: DOCK_DOOR_ID,
                  value: e.target.value,
                })
              }
            >
              {dockDoors.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </label>
        </div>
        <div className={classes.itemContainer}>
          <span className={classes.infoLabel}>TRAILER SEAL</span>
          <div className={classes.infoValue}>
            <TextField
              name="trailerSeal"
              variant="outlined"
              margin="normal"
              id="trailerSeal"
              data-testid="trailer-seal"
              select
              value={state.trailerSeal || ''}
              SelectProps={{
                MenuProps: { className: classes.menuItem },
              }}
              className={classes.textField}
              onChange={(e) =>
                dispatch({
                  type: UPDATE_INPUT,
                  field: TRAILER_SEAL,
                  value: e.target.value,
                })
              }
            >
              {TRUCK_TRAILER_SEAL_OPTIONS.map((option) => (
                <MenuItem key={option.internal_id} value={option.internal_id}>
                  {option.type}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <StatefulButton
          type="submit"
          successCallback={closeDrawer}
          loading={fetching}
          failed={failed}
          disabled={!formValid}
          classes={{
            disabled: classes.buttonDisabled,
            root: classes.submitButton,
          }}
          fullWidth
          waitTime={750}
        >
          Submit
        </StatefulButton>
      </form>
    </Fragment>
  );
};

TruckSubmissionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  truck: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  updateTruck: PropTypes.func.isRequired,
  createTruck: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  postFailed: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  updateFailed: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  clearPreselected: PropTypes.func.isRequired,
  preselectedNO: PropTypes.object,
  dockDoors: PropTypes.array.isRequired,
};

TruckSubmissionForm.defaultProps = {
  preselectedNO: null,
};

export default TruckSubmissionForm;
