import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

import {
  OVERVIEW,
  SLACKING,
  COMPOUND_INGREDIENTS,
  ASSEMBLY,
  RTH_PROGRESS_TRACKER_HOME,
  RTH_PORTIONING,
} from 'lib/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

const ProgressNavigation = () => {
  const classes = useStyles();

  const { selectedMenu } = useSelector((state) => state.menus);

  return (
    <div className={classes.linkContainer}>
      {[OVERVIEW, SLACKING].map((tab) => (
        <NavLink
          key={tab}
          to={`${RTH_PROGRESS_TRACKER_HOME}/${tab}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {tab.toUpperCase()}
        </NavLink>
      ))}
      <NavLink
        key={COMPOUND_INGREDIENTS}
        isActive={(match, location) =>
          location.pathname.match(COMPOUND_INGREDIENTS)
        }
        to={`${RTH_PROGRESS_TRACKER_HOME}/${COMPOUND_INGREDIENTS}/${selectedMenu.id}/ingredients`}
        className={classes.link}
        activeClassName={classes.activeLink}
      >
        {COMPOUND_INGREDIENTS.replace('-', ' ').toUpperCase()}
      </NavLink>
      <NavLink
        key={RTH_PORTIONING}
        to={`${RTH_PROGRESS_TRACKER_HOME}/${RTH_PORTIONING}`}
        className={classes.link}
        activeClassName={classes.activeLink}
      >
        {RTH_PORTIONING.toUpperCase()}
      </NavLink>
      {
        <NavLink
          key={ASSEMBLY}
          to={`${RTH_PROGRESS_TRACKER_HOME}/${ASSEMBLY}`}
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          {ASSEMBLY.toUpperCase()}
        </NavLink>
      }
    </div>
  );
};

export default ProgressNavigation;
