import { connect } from 'react-redux';

import { defectOptionsSelector } from 'redux/itemCategory/selectors';
import { addValueToFsqaReview } from 'redux/wmsFsqaReview/actions';
import { selectDefectPhotos } from 'redux/photos/selectors';
import {
  DEFECTS,
  HAS_DEFECTS,
  NUMBER_DEFECTIVE,
  NUMBER_SAMPLED,
} from 'lib/constants';

import DefectsQuestion from './DefectsQuestion';

const mapStateToProps = (state, props) => ({
  defectOptions: defectOptionsSelector(state, {
    categoryId: props.categoryId,
  }),
  defectsLoading: state.defectsByItemCategory.fetching,
  defectPhotosLength: selectDefectPhotos(state, props.palletId).length,
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  updateDefects: (defects) =>
    dispatch(addValueToFsqaReview(ownProps.palletId, DEFECTS, defects)),
  updateNumberDefective: (numberDefective) =>
    dispatch(
      addValueToFsqaReview(ownProps.palletId, NUMBER_DEFECTIVE, numberDefective)
    ),
  updateNumberSampled: (numberSampled) =>
    dispatch(
      addValueToFsqaReview(ownProps.palletId, NUMBER_SAMPLED, numberSampled)
    ),
  updateHasDefects: (hasDefects) =>
    dispatch(addValueToFsqaReview(ownProps.palletId, HAS_DEFECTS, hasDefects)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefectsQuestion);
