import {
  apiErrorConstant,
  apiRequestConstant,
  apiSuccessConstant,
} from '../reduxConstants';

export const PRODUCTION_ZONE_ITEMS_GET = 'PRODUCTION_ZONE_ITEMS_GET';
export const PRODUCTION_ZONE_ITEMS_GET_REQUEST = apiRequestConstant(
  PRODUCTION_ZONE_ITEMS_GET
);
export const PRODUCTION_ZONE_ITEMS_GET_SUCCESS = apiSuccessConstant(
  PRODUCTION_ZONE_ITEMS_GET
);
export const PRODUCTION_ZONE_ITEMS_GET_ERROR = apiErrorConstant(
  PRODUCTION_ZONE_ITEMS_GET
);

export const PRODUCTION_ZONE_ITEM_UPDATE = 'PRODUCTION_ZONE_ITEM_UPDATE';
export const PRODUCTION_ZONE_ITEM_UPDATE_REQUEST = apiRequestConstant(
  PRODUCTION_ZONE_ITEM_UPDATE
);
export const PRODUCTION_ZONE_ITEM_UPDATE_SUCCESS = apiSuccessConstant(
  PRODUCTION_ZONE_ITEM_UPDATE
);
export const PRODUCTION_ZONE_ITEM_UPDATE_ERROR = apiErrorConstant(
  PRODUCTION_ZONE_ITEM_UPDATE
);

export const CHANGEOVER_ZONE = 'CHANGEOVER_ZONE';
export const CHANGEOVER_ZONE_REQUEST = apiRequestConstant(CHANGEOVER_ZONE);
export const CHANGEOVER_ZONE_SUCCESS = apiSuccessConstant(CHANGEOVER_ZONE);
export const CHANGEOVER_ZONE_ERROR = apiErrorConstant(CHANGEOVER_ZONE);

export const PRINT_LICENSE_PLATE = 'PRINT_LICENSE_PLATE';
export const PRINT_LICENSE_PLATE_REQUEST = apiRequestConstant(
  PRINT_LICENSE_PLATE
);
export const PRINT_LICENSE_PLATE_SUCCESS = apiSuccessConstant(
  PRINT_LICENSE_PLATE
);
export const PRINT_LICENSE_PLATE_ERROR = apiErrorConstant(PRINT_LICENSE_PLATE);
