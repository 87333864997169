import React from 'react';
import PropTypes from 'prop-types';

import ProductionSubmissionsTable from '../ProductionSubmissionsTable';

const ProductionSubmissionsTracker = ({
  productionSubmissions,
  onTableChange,
  podLocations,
}) => {
  return (
    <ProductionSubmissionsTable
      onTableChange={onTableChange}
      results={
        productionSubmissions.fetching ? [] : productionSubmissions.results
      }
      pageCount={productionSubmissions.pageCount}
      quantitySubmitted={productionSubmissions.quantitySubmitted}
      allLetters={productionSubmissions.allLetters}
      fetching={productionSubmissions.fetching}
      podLocations={podLocations}
    />
  );
};

ProductionSubmissionsTracker.propTypes = {
  onTableChange: PropTypes.func.isRequired,
  productionSubmissions: PropTypes.object.isRequired,
  podLocations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string }))
    .isRequired,
};

export default ProductionSubmissionsTracker;
