import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { fetchDockDoors } from 'redux/dockDoor/actions';
import { toggleTruckDrawer, selectTruck } from 'redux/truck/actions';

import TruckReceiving from './TruckReceiving';
import styles from './styles';

const mapDispatchToProps = {
  toggleTruckDrawer: toggleTruckDrawer,
  selectTruck: selectTruck,
  onFetchDockDoors: fetchDockDoors,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(TruckReceiving));
