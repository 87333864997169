import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { StatefulButton, QtyTagsQuestion } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const NumberOfLabelsDialog = ({
  openNumLabelsDialog,
  handleClose,
  loading,
  failed,
  onSubmit,
}) => {
  const classes = useStyles();
  const [qtyTags, setQtyTags] = useState(1);

  return (
    <Dialog
      data-testid="num-labels-dialog"
      open={openNumLabelsDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <QtyTagsQuestion
        sequenceStart={1}
        sequenceEnd={10}
        qtyTags={qtyTags}
        setQtyTags={setQtyTags}
        visible
      />
      <DialogActions>
        <Button
          onClick={handleClose}
          className={classes.printPromptCancelButton}
          color="primary"
        >
          Cancel
        </Button>
        <StatefulButton
          loading={loading}
          failed={failed}
          buttonTextOptions={{
            LOADING: 'Posting...',
            SUCCESS: 'Success',
            ERROR: 'There was an error',
            DEFAULT: 'Submit',
          }}
          type="button"
          onClick={() => onSubmit(qtyTags)}
          classes={{ root: classes.printPromptSubmitButton }}
          disabled={!qtyTags}
        />
      </DialogActions>
    </Dialog>
  );
};

NumberOfLabelsDialog.propTypes = {
  openNumLabelsDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NumberOfLabelsDialog;
