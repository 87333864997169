import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = ({ classes, history, absoluteLocation }) => {
  const buttonRef = useRef(null);

  const onClick = () => {
    if (absoluteLocation) {
      history.push(absoluteLocation);
    } else {
      history.goBack();
    }
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };
  return (
    <IconButton
      ref={buttonRef}
      aria-label="Back"
      onClick={onClick}
      classes={{ root: classes.root }}
      size="large"
      data-testid="back-button"
    >
      <ArrowBackIcon fontSize="large" classes={{ root: classes.icon }} />
    </IconButton>
  );
};

BackButton.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  absoluteLocation: PropTypes.string,
};

BackButton.defaultProps = {
  absoluteLocation: null,
};

export default BackButton;
