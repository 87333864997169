import { drawerStyling } from 'lib/styles';
import { GREY_750 } from 'lib/constants';

export default {
  bodySection: {
    ...drawerStyling.bodySection,
    height: 'calc(100vh - 145px)',
  },
  caseText: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  header: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  icon: {
    width: 20,
    height: 20,
    padding: '20px 20px 0px 20px',
  },
  ingredient: {
    color: GREY_750,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  root: {
    width: 600,
    fontFamily: 'Roboto',
  },
  topSection: {
    ...drawerStyling.topSection,
    justifyContent: 'end',
  },
  dataSection: {
    margin: '24px 24px 48px 24px',
  },
};
