import { MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  reactivateText: {
    display: 'block',
  },
  reactivateButton: {
    display: 'block',
    margin: '0 auto',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    reactivateButton: {
      display: 'flex',
    },
    reactivateText: {
      width: '88px',
    },
  },
};
