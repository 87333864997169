import difference from 'lodash/difference';

const requiredFields = ['Timestamp', 'PO', 'Memo', 'Length'];

export const headerRows = [...requiredFields];

export const headerError = (data) => {
  const diff = difference(headerRows, data[0].meta.fields);

  if (diff.length > 0) {
    return {
      message: `CSV is missing ${diff
        .map((field) => field)
        .join(', ')} column(s)`,
    };
  }
};

export const requiredFieldErrors = (data) => {
  let fieldErrors = [];

  data.forEach((row, index) => {
    requiredFields.forEach((field) => {
      const hasValue = row.data[field].length > 0;
      if (!hasValue) {
        const error = {
          message: `Row ${index + 2} does not have a value for ${field}`,
        };

        const currentField = fieldErrors.find((err) => err.column === field);
        if (!currentField) {
          fieldErrors.push(error);
        }
      }
    });
  });
  return fieldErrors;
};

export const thirtyMinuteIntervalError = (data) => {
  let timeError;

  data.forEach((row, index) => {
    const startTime = row.data['Timestamp'];
    const minutes = startTime.split(':')[1];

    if (minutes === '30' || minutes === '00') {
      return;
    }
    timeError = {
      message: `Row ${
        index + 2
      } time is required to be in 30 minute intervals.`,
    };
  });
  return timeError;
};
