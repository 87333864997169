import { connect } from 'react-redux';

import { resetStorageSlots } from 'redux/storageSlot/actions';

import DepthSelection from './DepthSelection';

export const mapStateToProps = (state, ownProps) => ({
  newLocationTempMatch: state.pallets.inventoryApp.newLocationTempMatch,
  depthOptions: ownProps.itemMoveRequest
    ? state.itemMoveRequests.depthOptions
    : state.pallets.inventoryApp.depthOptions,
  depthsLoading: state.storageSlots.fetching,
  depthsFailed: state.storageSlots.failed,
});

const mapDispatchToProps = (dispatch) => ({
  onResetDepths: () => dispatch(resetStorageSlots()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthSelection);
