import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { isNonNegativeInteger } from 'lib/utils';
import { SubtractionInputs } from 'components/shared';

import { useValidator } from '../FsqaReviewForms/formHelpers';
import styles from './styles';

const QuantityQuestion = ({
  initialQuantity,
  numberRejected,
  updateRejectedQuantity,
  updateFinalQuantity,
  unitType,
  validationCallback,
}) => {
  const invalidQuantity = useMemo(
    () =>
      !isNonNegativeInteger(numberRejected) || numberRejected > initialQuantity,
    [numberRejected, initialQuantity]
  );

  useValidator(!invalidQuantity, validationCallback);

  return (
    <SubtractionInputs
      initialValue={initialQuantity}
      subtractingValue={numberRejected}
      updateSubtractingValue={updateRejectedQuantity}
      updateFinalValue={updateFinalQuantity}
      units={unitType}
      hasError={invalidQuantity}
      text={{
        initialValueLabel: 'INITIAL QTY',
        subtractingValueLabel: '# OF REJECTED',
        resultLabel: '# ACCEPTED',
        errorText: '# of rejected cannot be greater than initial quantity',
      }}
    />
  );
};

QuantityQuestion.defaultProps = {
  numberRejected: 0,
};

QuantityQuestion.propTypes = {
  initialQuantity: PropTypes.number.isRequired,
  numberRejected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateRejectedQuantity: PropTypes.func.isRequired,
  updateFinalQuantity: PropTypes.func.isRequired,
  unitType: PropTypes.string.isRequired,
  validationCallback: PropTypes.func.isRequired,
};

export default withStyles(styles)(QuantityQuestion);
