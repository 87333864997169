import { WHITE, HC_GREEN } from 'lib/constants';

export default {
  infoBesidesButton: {
    padding: '2%',
    margin: '5% 25%',
    borderTop: '5px solid',
    borderColor: HC_GREEN,
  },
  submitButton: {
    backgroundColor: HC_GREEN,
    color: `${WHITE} !important`,
    fontSize: 17,
    width: '100%',
    padding: '3%',
    marginTop: '3%',
    marginLeft: '0%',
  },
};
