import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const WarehouseInventoryIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
      >
        <title>Inventory Manager</title>
        <path
          d="M27.14,41.73,23.53,40v-5l3.61,1.73Zm0,11.64V46.69L23.53,45V70.32A2.48,2.48,0,0,0,26,72.8H53.23a2.48,2.48,0,0,0,2.48-2.48v-9.9L41,53.37Zm25,0h-.76l4.37,2.1V50.52L52.1,48.78ZM53.23,7.2H52.14a2.4,2.4,0,0,0-2.27,1.51,4.32,4.32,0,0,1-4.06,2.82H33.43a4.32,4.32,0,0,1-4.06-2.82A2.4,2.4,0,0,0,27.09,7.2H26a2.48,2.48,0,0,0-2.47,2.48V22.05l14,6.71H52.1v7l3.61,1.73V9.68A2.48,2.48,0,0,0,53.23,7.2Z"
          style={{ fill: HC_GREEN, opacity: '0.4' }}
        />
        <path
          d="M31.88,7.2a1.56,1.56,0,0,1,1.55-1.55h7.43a1.55,1.55,0,0,1,0,3.1H33.43A1.55,1.55,0,0,1,31.88,7.2ZM45.81,8.75A1.55,1.55,0,1,0,44.26,7.2,1.54,1.54,0,0,0,45.81,8.75ZM61.9,7.82V72.18a7.43,7.43,0,0,1-7.43,7.43H24.77a7.43,7.43,0,0,1-7.43-7.43V35.67a1.25,1.25,0,0,1-1.24-1.24V25.77a1.24,1.24,0,0,1,1.24-1.24V22.67a1.24,1.24,0,0,1-1.24-1.24V12.77a1.25,1.25,0,0,1,1.24-1.24V7.82A7.43,7.43,0,0,1,24.77.39h29.7A7.43,7.43,0,0,1,61.9,7.82Zm-3.1,0a4.33,4.33,0,0,0-4.33-4.33H24.77a4.33,4.33,0,0,0-4.34,4.33V72.18a4.33,4.33,0,0,0,4.34,4.33h29.7a4.33,4.33,0,0,0,4.33-4.33ZM48.19,44.69h3.05v-3H48.19Zm-8-15.08h11V40.5h-11V29.61Zm2,1.42h-.58v8.08h8.17V31Zm5.67,2.73v-.84H43.53v4.3h4.34ZM31.34,45v4.3h4.34V45H31.34Zm14.57.82v-1.1H43.25v4.8h2.66Zm-2.67-1.12v0h0v0Zm0-3H40.19v3h3.05v-3Zm4.95,5.22v2.63H45.91v3h5.33V46.89H48.19Zm-8,5.65h3.05v-3H40.19Zm3.05-3h0v0h0ZM35.68,32.92H31.34v4.3h4.34ZM28,29.61H39V40.52H28V29.61Zm1.43,8.27v1.23h8.16V31H29.43Zm8,3.83H39V52.59H28V41.71Zm.21,5V43.1H29.43v8.08h8.16Z"
          style={{ fill: HC_GREEN }}
        />
      </svg>
    </div>
  );
};

WarehouseInventoryIcon.propTypes = {
  className: PropTypes.string,
};

WarehouseInventoryIcon.defaultProps = {
  className: 'WarehouseInventoryIcon',
};

export default WarehouseInventoryIcon;
