import { HC_GREEN, ERROR_RED } from 'lib/constants';

export default {
  loadingContainer: {
    padding: '50px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  circleCheck: {
    fill: HC_GREEN,
    verticalAlign: 'middle',
  },
  priorityIcon: {
    fill: ERROR_RED,
  },
};
