import { combineReducers } from 'redux';

import warehouseRequests from './warehouseRequests/reducers';
import productionInventory from './productionInventory/reducers';
import inventoryCheck from './inventoryCheck/reducers';

export default combineReducers({
  warehouseRequests,
  productionInventory,
  inventoryCheck,
});
