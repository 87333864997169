import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const postFsqaPhoto = async ({ photo, palletId, category }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/fsqa_reviews/photos`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({ photo, category }),
    }
  );

  return await handleResponse(response);
};

const fetchFsqaPhotos = async ({ palletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/fsqa_reviews/photos`,
    options
  );

  return await handleResponse(response);
};

const deleteFsqaPhoto = async ({ photoId, palletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/fsqa_reviews/photos/${photoId}`,
    {
      ...options,
      method: 'DELETE',
    }
  );

  return await handleResponse(response);
};

export default {
  postFsqaPhoto,
  fetchFsqaPhotos,
  deleteFsqaPhoto,
};
