import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';

import { selectMenu } from 'redux/menu/actions';
import { RTH_PROGRESS_TRACKER_HOME, ASSEMBLY } from 'lib/constants';
import { Loading } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const MenuNavigation = ({ path, menusCount }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { menuId } = useParams();

  const { fetching: fetchingMenus, selectedMenu } = useSelector(
    (state) => state.menus
  );

  const menus = useSelector((state) =>
    state.menus.results.slice(0, menusCount)
  );

  const { last_successful_fetch_at } = useSelector((state) => state.session);

  const handleChange = (menu) => {
    dispatch(selectMenu(menu));
  };

  useEffect(() => {
    if (menus.indexOf(selectedMenu) < 0) {
      dispatch(selectMenu(menus[0]));
      history.push(`${RTH_PROGRESS_TRACKER_HOME}/${ASSEMBLY}/${menus[0].id}`);
    } else {
      dispatch(selectMenu(menus.find(({ id }) => id === Number(menuId))));
    }
  }, []);

  return fetchingMenus ? (
    <Loading />
  ) : (
    <div className={classes.navHeader}>
      <div className={classes.linkContainer}>
        {menus.map((menu) => (
          <NavLink
            key={menu.id}
            to={`${RTH_PROGRESS_TRACKER_HOME}/${path}/${menu.id}`}
            className={classes.link}
            activeClassName={classes.activeLink}
            onClick={() => handleChange(menu)}
          >
            {DateTime.fromISO(menu.weekOfDate).toFormat('M/dd')}
          </NavLink>
        ))}
      </div>
      <p
        className={classes.lastUpdatedAt}
      >{`Last Updated ${last_successful_fetch_at}`}</p>
    </div>
  );
};

MenuNavigation.propTypes = {
  path: PropTypes.string.isRequired,
  menusCount: PropTypes.number,
};

MenuNavigation.defaultProps = {
  menusCount: 5,
};

export default MenuNavigation;
