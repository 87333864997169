import { MEDIA_QUERY_PHONE_SCREEN, GREY_200, BLACK } from 'lib/constants';
import { palletBannerStyles } from 'components/WarehouseInventory/ScanBanner/styles';

export default {
  inactivePalletBanner: {
    ...palletBannerStyles,
    color: BLACK,
    backgroundColor: GREY_200,
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    onhold: {
      fontSize: '12px',
    },
    deactivated: {
      fontSize: '14px',
    },
  },
};
