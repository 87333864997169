import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import PreliminaryCheck from './PreliminaryCheck';
import MealGrid from './MealGrid';
import QaCheckForm from './QaCheckForm';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const QaPortioning = ({
  onPostQaPortionCheck,
  qaPortionChecks,
  location,
  onUpdateQaPortionChecks,
}) => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={['/qa/portioning', '/qa/portioning/menu/:menu_id']}
          component={MealGrid}
        />
        <Route
          exact
          path="/qa/portioning/preliminary-checks/:meal_id"
          component={PreliminaryCheck}
        />
        <Route
          exact
          path="/qa/portioning/preliminary-checks/:preliminary_check_id/checks"
          component={() => (
            <QaCheckForm
              onUpdateQaPortionChecks={onUpdateQaPortionChecks}
              qaPortionChecks={qaPortionChecks}
              onPostQaPortionCheck={onPostQaPortionCheck}
              location={location}
            />
          )}
        />
      </Switch>
    </Fragment>
  );
};

export default QaPortioning;

QaPortioning.propTypes = {
  onUpdateQaPortionChecks: PropTypes.func.isRequired,
  onPostQaPortionCheck: PropTypes.func.isRequired,
  qaPortionChecks: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
