import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { RETAIL_CHANNEL, ECOMMERCE_CHANNEL } from 'lib/constants';
import { useTabOutline } from 'lib/custom_hooks';

const Row = ({
  classes,
  letterAssignment,
  mealName,
  numberLeft,
  showNumberLeft,
  channel,
  ingredientAssignment,
  odd,
}) => {
  const [handleMouseDown, handleBlur, style] = useTabOutline({});
  return (
    <Link
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
      style={style}
      className={classNames(classes.tableRow, {
        [classes.darkStripe]: !odd,
      })}
      tabIndex={0}
      to={{
        pathname: `${location.pathname
          .split('/')
          .slice(0, 5)
          .join('/')}/ingredient_assignment/${ingredientAssignment.id}`,
        state: { ingredientAssignment: ingredientAssignment },
      }}
    >
      <span
        className={classNames(classes.letterAssignment, {
          [classes.green]: channel === ECOMMERCE_CHANNEL,
          [classes.orange]: channel === RETAIL_CHANNEL,
        })}
      >
        {letterAssignment}
      </span>
      <span className={classes.mealName}>{mealName}</span>
      {showNumberLeft && (
        <span className={classes.numberLeft}>{numberLeft}</span>
      )}
    </Link>
  );
};

Row.propTypes = {
  classes: PropTypes.object.isRequired,
  letterAssignment: PropTypes.string.isRequired,
  mealName: PropTypes.string.isRequired,
  numberLeft: PropTypes.string,
  showNumberLeft: PropTypes.bool.isRequired,
  channel: PropTypes.string.isRequired,
  ingredientAssignment: PropTypes.object.isRequired,
  odd: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  numberLeft: null,
};

export default Row;
