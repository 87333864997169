import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import progressTrackerTheme from 'lib/prodProgTheme';
import {
  expandAllMeals,
  collapseAllMeals,
  fetchMealsByMenu,
} from 'redux/meal/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { HOME_BUTTON, PROGRESS_TRACKER, ACCOUNT_DROPDOWN } from 'lib/constants';

import styles from './styles';
import ProgressTracker from './ProgressTracker';

const ProgressTrackerContainer = ({
  session,
  menus,
  onExpandAllClick,
  onCollapseAllClick,
  onFetchMealsByMenu,
  history,
  onSetTopBarContent,
  classes,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: PROGRESS_TRACKER,
      rightContent: ACCOUNT_DROPDOWN,
    });
  }, [onSetTopBarContent]);

  useEffect(() => {
    let interval = setInterval(() => {
      const facilityId =
        session.selectedPackingFacilityId || session.packing_facility_id;
      onFetchMealsByMenu({
        menuId: menus.selectedMenu.id,
        facilityId,
      });
    }, 300000);
    return () => clearInterval(interval);
  }, [
    menus.selectedMenu.id,
    onFetchMealsByMenu,
    session.packing_facility_id,
    session.selectedPackingFacilityId,
  ]);

  useEffect(() => {
    if (menus.results.length > 0 && !isEmpty(menus.selectedMenu)) {
      const packingFacilityId =
        session.selectedPackingFacilityId || session.packing_facility_id;
      onFetchMealsByMenu({
        menuId: menus.selectedMenu.id,
        facilityId: packingFacilityId,
      });
    }
  }, [
    menus,
    onFetchMealsByMenu,
    session.packing_facility_id,
    session.selectedPackingFacilityId,
  ]);

  useEffect(() => {
    if (!isEmpty(menus.selectedMenu)) {
      history.push(`/progress_tracker/menu/${menus.selectedMenu.id}/overview`);
    }
  }, [history, menus.selectedMenu]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={progressTrackerTheme}>
        <ProgressTracker
          session={session}
          menus={menus}
          onExpandAllClick={onExpandAllClick}
          onCollapseAllClick={onCollapseAllClick}
          classes={classes}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ProgressTrackerContainer.propTypes = {
  session: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onExpandAllClick: PropTypes.func.isRequired,
  onCollapseAllClick: PropTypes.func.isRequired,
  onFetchMealsByMenu: PropTypes.func.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  menus: state.menus,
});

const mapDispatchToProps = {
  onExpandAllClick: expandAllMeals,
  onCollapseAllClick: collapseAllMeals,
  onFetchMealsByMenu: fetchMealsByMenu,
  onSetTopBarContent: setTopBarContent,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProgressTrackerContainer))
);
