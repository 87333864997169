import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { setTopBarContent } from 'redux/topBar/actions';
import { fetchMenus } from 'redux/menu/actions';
import { requestPodLocations } from 'redux/podLocation/actions';
import { getHoneyTagPrinters } from 'redux/printers/actions';

import styles from './styles';
import ProductionInventory from './ProductionInventory';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onFetchMenus: fetchMenus,
  onRequestPodLocations: requestPodLocations,
  onGetHoneyTagPrinters: getHoneyTagPrinters,
};

const mapStateToProps = (state) => ({
  menus: state.menus.results,
  session: state.session,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductionInventory));
