import React, { useState, Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import UpDownIcon from '@mui/icons-material/SwapVerticalCircle';
import IconButton from '@mui/material/IconButton';

import {
  QUANTITY_REMOVAL_OPTIONS,
  QUANTITY_INCREASE_OPTIONS,
  WASTE_CORRECTION,
} from 'lib/constants';
import { QuantityCalculator } from 'components/shared';

import QtyIncreaseModal from './QtyIncreaseModal';
import QtyRemovalModal from './QtyRemovalModal';

const QuantityModals = ({
  classes,
  onSubmit,
  onPageDownCallback,
  calculatorOpen,
  setCalculatorOpen,
  quantity,
  calculatorDisabled,
  measuringUnit,
  includeWasteCorrection,
}) => {
  const [removalModalOpen, setRemovalModalOpen] = useState(false);
  const [increaseModalOpen, setIncreaseModalOpen] = useState(false);
  const [removalReasonType, setRemovalReasonType] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const submittingQuantity = useRef();

  const onCalculatorSubmit = (_inputValue, result) => {
    submittingQuantity.current = result;
    setCalculatorOpen(false);
    if (result >= quantity) {
      setIncreaseModalOpen(true);
    } else {
      setRemovalModalOpen(true);
    }
  };

  const handleReasonsDialogClose = () => {
    setIncreaseModalOpen(false);
    setRemovalModalOpen(false);
    setTimeout(() => {
      setRemovalReasonType({});
    }, 300);
  };

  const onQuantityReasonSubmit = (statusType, statusReason = null) => {
    handleReasonsDialogClose();

    onSubmit({
      quantity: submittingQuantity.current,
      change_reason_type: statusType,
      change_reason: statusReason,
    });
  };

  const onQuantityReasonCancel = () => {
    handleReasonsDialogClose();
    submittingQuantity.current = null;
  };

  const onReasonTypeClick = (adjustmentType) => {
    const adjustmentOption = QUANTITY_REMOVAL_OPTIONS.concat(
      QUANTITY_INCREASE_OPTIONS
    )
      .concat({ type: WASTE_CORRECTION, requiresConfirmation: true })
      .find((opt) => opt.type === adjustmentType);
    setRemovalReasonType(adjustmentOption);

    if (adjustmentOption.requiresConfirmation) {
      setIncreaseModalOpen(false);
      setConfirmationModalOpen(true);
    } else if (!adjustmentOption.reasons) {
      onQuantityReasonSubmit(adjustmentOption.type);
    }
  };

  const onConfirmationNoClick = () => {
    setConfirmationModalOpen(false);
    setIncreaseModalOpen(true);
  };

  const onConfirmationYesClick = () => {
    setConfirmationModalOpen(false);
    onQuantityReasonSubmit(removalReasonType.type);
  };

  const onClickCalculator = () => {
    setCalculatorOpen(!calculatorOpen);
  };

  const onCloseCalculator = () => {
    setCalculatorOpen(false);
  };

  const onQuantityInputBlur = useCallback(() => {
    document.addEventListener('keydown', onPageDownCallback, true);
  }, [onPageDownCallback]);

  const onQuantityInputFocus = useCallback(
    (e) => {
      document.removeEventListener('keydown', onPageDownCallback, true);

      e.target.select();
    },
    [onPageDownCallback]
  );

  const subReasons = removalReasonType.reasons;
  return (
    <Fragment>
      <IconButton
        data-testid="open-calculator-button"
        disabled={calculatorDisabled}
        onClick={onClickCalculator}
        classes={{
          root: classes.openButton,
          disabled: classes.buttonDisabled,
        }}
        size="large"
      >
        <UpDownIcon className={classes.openIcon} />
      </IconButton>
      <QuantityCalculator
        selectedOperators={['subtract', 'add', 'set']}
        defaultOperator="subtract"
        submitText="Set Qty to $"
        submitValueType="result"
        disabled={calculatorDisabled}
        quantity={quantity}
        submitQuantity={onCalculatorSubmit}
        closeModal={onCloseCalculator}
        isOpen={calculatorOpen}
        onInputBlur={onQuantityInputBlur}
        onInputFocus={onQuantityInputFocus}
        calculatorOpen={calculatorOpen}
        measuringUnit={measuringUnit}
      />
      <QtyRemovalModal
        removalModalOpen={removalModalOpen}
        onQuantityReasonCancel={onQuantityReasonCancel}
        subReasons={subReasons}
        removalReasonType={removalReasonType}
        onQuantityReasonSubmit={onQuantityReasonSubmit}
        onReasonTypeClick={onReasonTypeClick}
      />
      <QtyIncreaseModal
        increaseModalOpen={increaseModalOpen}
        onQuantityReasonCancel={onQuantityReasonCancel}
        onReasonTypeClick={onReasonTypeClick}
        includeWasteCorrection={includeWasteCorrection}
      />
      <Dialog id="confirmationDialog" open={confirmationModalOpen}>
        <DialogContent>
          <DialogContentText classes={{ root: classes.confirmationText }}>
            Are you sure this is a waste correction?
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <div className={classes.confirmationButtonContainer}>
            <Button
              id="confirmationNoButton"
              onClick={onConfirmationNoClick}
              variant="outlined"
              className={classes.confirmationButton}
            >
              No
            </Button>
            <Button
              id="confirmationYesButton"
              onClick={onConfirmationYesClick}
              variant="outlined"
              className={classes.confirmationButton}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

QuantityModals.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPageDownCallback: PropTypes.func.isRequired,
  calculatorOpen: PropTypes.bool.isRequired,
  calculatorDisabled: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
  measuringUnit: PropTypes.string.isRequired,
  setCalculatorOpen: PropTypes.func.isRequired,
  includeWasteCorrection: PropTypes.bool,
};

QuantityModals.defaultProps = {
  includeWasteCorrection: false,
};

export default QuantityModals;
