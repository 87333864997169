import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';

import { getProductionPlans } from 'redux/productionPlans/actions';
import { getMenuSelections } from 'redux/menuSelection/actions';
import { selectMenuSelection } from 'redux/menuSelection/selectors';
import { getShifts } from 'redux/shift/actions';
import { selectProductionPlansByShift } from 'redux/productionPlans/selectors';
import { selectCurrentShift, allShiftNames } from 'redux/shift/selectors';
import { formatDateHyphenated } from 'lib/utils';
import { Loading } from 'components/shared';
import {
  BACK_BUTTON,
  ACCOUNT_DROPDOWN,
  TEXT,
  PRODUCTION_SHEETS_HOME,
} from 'lib/constants';

import styles from './styles';
import ProductionSheet from './ProductionSheet';

const ProductionSheetContainer = ({
  menuSelection,
  productionPlans,
  shiftNames,
  currentShift,
  fetchingProdPlans,
  onGetProductionPlans,
  onGetMenuSelections,
  onSetTopBarContent,
  onGetShifts,
  match,
  location,
  history,
  classes,
}) => {
  useEffect(() => {
    if (!currentShift) {
      onGetShifts();
    }
  }, [currentShift, onGetShifts]);

  useEffect(() => {
    if (menuSelection) {
      onSetTopBarContent({
        leftContent: BACK_BUTTON,
        middleContent: TEXT,
        rightContent: ACCOUNT_DROPDOWN,
        text: 'Production Sheets',
        backLocation: `${PRODUCTION_SHEETS_HOME}/${menuSelection.menuId}`,
      });
    }
  }, [menuSelection, onSetTopBarContent]);

  useEffect(() => {
    if (!match.params.shiftName && currentShift) {
      history.push(`${location.pathname}/shift/${currentShift.name}`);
    }
  });

  useEffect(() => {
    if (!menuSelection) {
      onGetMenuSelections();
    }
  }, [menuSelection, onGetMenuSelections]);

  useEffect(() => {
    if (menuSelection) {
      const { menuId, mealId } = menuSelection;
      onGetProductionPlans({
        date: formatDateHyphenated(new Date()),
        mealId,
        menuId,
      });
    }
  }, [menuSelection, onGetProductionPlans]);

  if (menuSelection) {
    return (
      <ProductionSheet
        {...{
          productionPlans,
          fetchingProdPlans,
          shiftNames,
          menuSelection,
          match,
          classes,
        }}
      />
    );
  } else {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  }
};

const mapDispatchToProps = {
  onGetProductionPlans: getProductionPlans,
  onGetMenuSelections: getMenuSelections,
  onGetShifts: getShifts,
};

const mapStateToProps = (state, ownProps) => ({
  menuSelection: selectMenuSelection(
    state.menuSelection.menuSelections,
    ownProps.match.params.menuSelectionId
  ),
  shiftNames: allShiftNames(state.shift.results),
  productionPlans: selectProductionPlansByShift(
    state.productionPlans.results,
    ownProps.match.params.shiftName
  ),
  fetchingProdPlans: state.productionPlans.fetching,
  currentShift: selectCurrentShift(state.shift.results),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductionSheetContainer));
