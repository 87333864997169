import * as Sentry from '@sentry/react';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  NavLink,
} from 'react-router-dom';
import Button from '@mui/material/Button';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { HOME_BUTTON, ACCOUNT_DROPDOWN, TEXT } from 'lib/constants';
import { Chicken } from 'components/icons';
import { formatDateAsyyyyMMdd } from 'lib/utils';

import UploaderInterface from './UploaderInterface';
import ReceivingAppointments from './ReceivingAppointments';
import AppointmentDrawer from './AppointmentDrawer';
import UnscheduledPOs from './UnscheduledPOs';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const DockScheduler = ({
  onSetTopBarContent,
  location,
  history,
  classes,
  onGetAppointmentsForDate,
  unscheduledPOs,
  appointments,
}) => {
  const [open, setOpen] = useState(false);
  const [apptDate, setApptDate] = useState(new Date());
  const [selectedAppt, setSelectedAppt] = useState(null);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: TEXT,
      rightContent: ACCOUNT_DROPDOWN,
      text: 'Dock Scheduler',
    });
  }, [onSetTopBarContent]);

  useEffect(() => {
    if (location.pathname === '/dock_scheduler') {
      history.push('/dock_scheduler/inbound');
    }
  }, [history, location.pathname]);

  useEffect(() => {
    onGetAppointmentsForDate(formatDateAsyyyyMMdd(apptDate));
  }, [apptDate, setApptDate, onGetAppointmentsForDate]);

  const handleAppointmentClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={classes.navLinkContainer}>
        <NavLink
          to="/dock_scheduler/inbound"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          Inbound
        </NavLink>
        <NavLink
          to="/dock_scheduler/outbound"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          Outbound
        </NavLink>
      </div>
      {!location.pathname.includes('upload') && (
        <Grid container className={classes.headerContainer}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <header className={classes.header}>DATE</header>
              <MobileDatePicker
                inputFormat="MM/dd/yyyy"
                value={apptDate}
                onChange={setApptDate}
                className={classes.dateInput}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              className={classes.createButton}
              onClick={() => setOpen(true)}
            >
              Create Appointment
            </Button>
            <Button
              variant="outlined"
              className={classes.uploadButton}
              onClick={() => {
                history.push('/dock_scheduler/upload');
              }}
            >
              Upload CSV
            </Button>
          </Grid>
        </Grid>
      )}
      {open && (
        <AppointmentDrawer
          open={open}
          setOpen={setOpen}
          apptId={selectedAppt}
          onSetSelectedAppt={setSelectedAppt}
          prefilledDate={apptDate}
        />
      )}
      <Switch>
        <Route
          exact
          path="/dock_scheduler/inbound"
          render={() => (
            <>
              <ReceivingAppointments
                appointments={appointments}
                onClickAppointment={handleAppointmentClick}
                onSetSelectedAppt={setSelectedAppt}
              />
              <UnscheduledPOs unscheduledPOs={unscheduledPOs} date={apptDate} />
            </>
          )}
        />
        <Route
          exact
          path="/dock_scheduler/outbound"
          render={() => (
            <div className={classes.comingSoon}>
              Coming Soon!
              <Chicken isAnimating />
            </div>
          )}
        />
        <Route
          exact
          path="/dock_scheduler/upload"
          render={() => <UploaderInterface />}
        />
      </Switch>
    </>
  );
};

DockScheduler.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onGetAppointmentsForDate: PropTypes.func.isRequired,
};

export default DockScheduler;
