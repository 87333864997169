import React, { useReducer, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import find from 'lodash/find';
import partition from 'lodash/partition';
import withStyles from '@mui/styles/withStyles';

import {
  facilityHasLabeledPackaging,
  facilityHasPortionedIngredients,
} from 'lib/utils';
import {
  PodLocationQuestion,
  FormContainer,
  StatefulButton,
  Loading,
} from 'components/shared';
import {
  RETAIL,
  BULK,
  LABELED_PACKAGING,
  PORTIONED_INGREDIENTS,
  COMPOUND_INGREDIENT,
  DISPOSABLE_ITEM,
} from 'lib/constants';
import { usePrevious } from 'lib/custom_hooks';
import { sortByName } from 'lib/viewHelpers';

import SearchItemDrawer from '../SearchItemDrawer';
import styles from './styles';
import {
  reducer,
  initialState,
  conversionHelperText,
  UPDATE_INTERNAL_LOCATION_NAME,
  UPDATE_SELECTED_INGREDIENTS,
  UPDATE_SELECTED_INGREDIENT_PACKAGINGS,
  UPDATE_SELECTED_REQUESTABLE_COUNT,
  REMOVE_REQUESTED_PACKAGING,
  REMOVE_REQUESTED_INGREDIENT,
} from './formUtils';

const RequestsForm = ({
  podLocations,
  classes,
  onGetIngredientAssignments,
  onSubmitMoveRequests,
  onGetPackagingItems,
  ingredientAssignments,
  packagingItems,
  iaFetching,
  itemMoveRequests,
  location,
  history,
  piFetching,
  packingFacilityId,
}) => {
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { menuSelection } = location.state;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    internalLocationName,
    selectedIngredientAssignments,
    selectedIngredientPackagings,
    defaultCount,
    selectedRequestablesWithCounts,
  } = state;

  const labeledPackagingItems = packagingItems.filter(
    (item) => item.category === LABELED_PACKAGING
  );

  const unlabeledPackagingItems = packagingItems.filter(
    (item) => item.category === BULK || item.category === DISPOSABLE_ITEM
  );

  const bulkIngredientAssignments = ingredientAssignments.filter(
    (ia) => ia.category === BULK
  );

  const compoundIngredients = ingredientAssignments.filter(
    (ia) => ia.category === COMPOUND_INGREDIENT
  );

  const portionedIngredientAssignments = ingredientAssignments.filter(
    (ia) => ia.category === PORTIONED_INGREDIENTS
  );

  const ingredientAssignmentsAndPackagings = selectedIngredientAssignments.concat(
    selectedIngredientPackagings
  );

  const [componentRequests, nonComponentRequests] = partition(
    ingredientAssignmentsAndPackagings,
    'componentIngredient'
  );

  const hasCountError = (countState) => {
    return (
      countState &&
      (countState.requestCount < 1 || countState.requestCount > 99999)
    );
  };

  const allFilledOut =
    internalLocationName !== '' &&
    (selectedIngredientAssignments.length > 0 ||
      selectedIngredientPackagings.length > 0) &&
    ingredientAssignmentsAndPackagings.length ===
      selectedRequestablesWithCounts.length &&
    // Check that it is not the default value && value is inside of bounds.
    selectedRequestablesWithCounts.every(
      (req) => req.requestCount && !hasCountError(req)
    );

  const prevMoveRequests = usePrevious(itemMoveRequests);

  const requestsSuccessfullyCreated =
    !itemMoveRequests.posting &&
    prevMoveRequests &&
    itemMoveRequests.length > prevMoveRequests.length &&
    !itemMoveRequests.errors;

  useEffect(() => {
    const showPortionedIngredients = true;
    // the following two flags need to be set to true to flip the 'show compound ingredients in warehouse requests app' feature to go live
    const showCompoundIngredients = true;
    const showComponentIngredients = true;
    onGetIngredientAssignments(
      menuSelection.ingredientAssignmentIds,
      showPortionedIngredients,
      showCompoundIngredients,
      showComponentIngredients
    );
  }, [menuSelection.ingredientAssignmentIds, onGetIngredientAssignments]);

  useEffect(() => {
    onGetPackagingItems(menuSelection.ingredientAssignmentIds);
  }, [menuSelection.ingredientAssignmentIds, onGetPackagingItems]);

  useEffect(() => {
    if (requestsSuccessfullyCreated) {
      history.push('/warehouse_requests/queue');
    }
  }, [
    history,
    itemMoveRequests,
    prevMoveRequests,
    requestsSuccessfullyCreated,
  ]);

  const handleOtherClick = () => {
    setSearchDrawerOpen(true);
  };

  const onCloseSearchDrawer = () => {
    setSearchDrawerOpen(false);
  };

  const filteredIngredientAssignments = (ingAssignments) => {
    return menuSelection.mealPlan == RETAIL
      ? ingAssignments
      : ingAssignments.filter((ia) => !ia.isProtein);
  };

  const submitRequests = () => {
    setDisableSubmitButton(true);
    onSubmitMoveRequests(
      selectedRequestablesWithCounts,
      internalLocationName,
      menuSelection.menuId,
      menuSelection.menuType,
      menuSelection.mealId
    );
  };

  const addOtherItemToForm = (item) => {
    if (item.requestableType === 'Ingredient') {
      dispatch({
        type: UPDATE_SELECTED_INGREDIENTS,
        value: [...selectedIngredientAssignments, item],
      });
    } else {
      dispatch({
        type: UPDATE_SELECTED_INGREDIENT_PACKAGINGS,
        value: [...selectedIngredientPackagings, item],
      });
    }
  };

  const determineId = (request) => {
    if (request.requestAnything) {
      return request.requestableId;
    } else if (request.isPackagingItem) {
      return request.id;
    } else {
      return request.ingredientId;
    }
  };

  const determineReqType = (requestable) => {
    if (requestable.isPackagingItem) {
      return requestable.isDisposableItem ? 'DisposableItem' : 'PackagingItem';
    } else if (requestable.requestAnything) {
      return requestable.requestableType;
    } else {
      return 'Ingredient';
    }
  };

  const requestableMatcher = (requestable) => {
    return {
      requestableId: determineId(requestable),
      requestableType: determineReqType(requestable),
      category: requestable.category || BULK, //We have this || specifically for requestAnything
      ingredientAssignmentId: requestable.ingredientAssignmentId || null,
      compoundIngredientRecipeAssignmentId:
        requestable.compoundIngredientRecipeAssignmentId || null,
    };
  };

  const removeRequestedItem = (requestable) => {
    return requestable.isPackagingItem ||
      requestable?.requestableType === 'PackagingItem'
      ? dispatch({
          type: REMOVE_REQUESTED_PACKAGING,
          value: {
            removedId: determineId(requestable),
            requestable: requestable,
          },
        })
      : dispatch({
          type: REMOVE_REQUESTED_INGREDIENT,
          value: {
            removedId: determineId(requestable),
            requestable: requestable,
          },
        });
  };

  const otherItemButton = () => {
    return (
      <ToggleButton
        key="other"
        className={classes.ingButton}
        value="Other"
        onClick={handleOtherClick}
      >
        Other
      </ToggleButton>
    );
  };

  const ingredientButtons = (ingredients) => {
    if (!iaFetching) {
      const baseIngredientButtons = () => {
        return sortByName(filteredIngredientAssignments(ingredients)).map(
          (ingredient) => {
            return (
              <ToggleButton
                className={classes.ingButton}
                key={ingredient.ingredientId}
                value={ingredient}
              >
                {ingredient.category === BULK && (
                  <div className={classes.columns}>
                    <div className={classes.ingName}>{ingredient.name}</div>
                    <div className={classes.brandForIng}>
                      {ingredient.brand}
                    </div>
                  </div>
                )}
                {ingredient.category === PORTIONED_INGREDIENTS && (
                  <div>
                    {ingredient.name}
                    <div className="ingButton2">{`${ingredient.quantity} ${ingredient.unitOfMeasure}`}</div>
                  </div>
                )}
              </ToggleButton>
            );
          }
        );
      };
      return ingredients[0]?.category === BULK &&
        ingredients[0]?.componentIngredient === undefined
        ? [baseIngredientButtons(), otherItemButton()]
        : [baseIngredientButtons()];
    } else {
      return (
        <div className={classes.RequestFormCenteredLoading}>
          <Loading />
        </div>
      );
    }
  };

  const packagingItemsButtons = (items) => {
    if (!piFetching && packagingItems !== null) {
      const basePackagingItemButtons = () => {
        return items.map((packagingItem) => {
          return (
            <ToggleButton
              className={classes.ingButton}
              key={`${packagingItem.id}-${packagingItem.name}-${packagingItem.ingredientAssignmentId}`}
              value={packagingItem}
            >
              {packagingItem.category === LABELED_PACKAGING && (
                <div>
                  {packagingItem.name} -
                  <div className="ingButton2">
                    {`${packagingItem.ingredientName} (${packagingItem.iaQuantity} ${packagingItem.iaMeasure})`}
                  </div>
                </div>
              )}
              {(packagingItem.category === BULK ||
                packagingItem.category === DISPOSABLE_ITEM) &&
                packagingItem.name}
            </ToggleButton>
          );
        });
      };
      return items[0]?.category === LABELED_PACKAGING
        ? [basePackagingItemButtons()]
        : [basePackagingItemButtons(), otherItemButton()];
    } else if (piFetching) {
      return (
        <div className={classes.RequestFormCenteredLoading}>
          <Loading />
        </div>
      );
    }
  };

  const requestedPortionsNaming = (requestable) => {
    if (requestable.category === LABELED_PACKAGING) {
      return `Labeled ${requestable.ingredientName} ${requestable.name}:`;
    } else if (requestable.category === PORTIONED_INGREDIENTS) {
      return `Portioned ${requestable.name} - ${requestable.quantity} ${requestable.unitOfMeasure}:`;
    } else {
      return `${requestable.name}:`;
    }
  };

  const renderQuantitySelection = (requestable, selectedWithRequestCount) => {
    return (
      <Fragment
        key={`${determineId(requestable)}-${
          requestable.ingredientAssignmentId
        }-${requestable.requestableId}-${
          requestable.compoundIngredientRecipeAssignmentId
        }`}
      >
        <div className={classes.gridRequestsThreeItems}>
          <h3 className={classes.ingredientRequestPadding}>
            {requestedPortionsNaming(requestable)}
          </h3>
          <TextField
            value={
              (selectedWithRequestCount &&
                selectedWithRequestCount.requestCount) ||
              defaultCount
            }
            onChange={(e) =>
              dispatch({
                type: UPDATE_SELECTED_REQUESTABLE_COUNT,
                value: {
                  ...requestableMatcher(requestable),
                  count: e.target.value,
                },
              })
            }
            label="# TO REQUEST"
            placeholder="0"
            onSelect={(e) => e.preventDefault()}
            type="number"
            onFocus={(e) => e.target.select()}
            variant="outlined"
            error={hasCountError(selectedWithRequestCount)}
            helperText={`${
              hasCountError(selectedWithRequestCount)
                ? 'Please enter a value greater than 0 and less than 100,000'
                : ''
            }`}
            className={classes.textField}
          />
          <div className={classes.portionToCasesRatio}>
            {conversionHelperText(
              requestable,
              selectedWithRequestCount?.requestCount
            )}
          </div>
          <IconButton
            data-testid="delete-icon"
            key={requestable.id}
            aria-label="Delete"
            color="inherit"
            className={classes.trashIcon}
            onClick={() => removeRequestedItem(requestable)}
            size="large"
          >
            <DeleteForeverIcon color="error" />
          </IconButton>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <FormContainer>
        <Card>
          <PodLocationQuestion
            podLocations={podLocations}
            internalLocationName={internalLocationName}
            setInternalLocationName={(value) =>
              dispatch({
                type: UPDATE_INTERNAL_LOCATION_NAME,
                value: value,
              })
            }
            questionText="Drop off location"
          />
        </Card>
        <Card>
          <h3>Choose Ingredients to Request</h3>
          {facilityHasPortionedIngredients(packingFacilityId) && (
            <h3>Bulk Ingredients</h3>
          )}
          <ToggleButtonGroup
            className={classes.buttonGroup}
            size="large"
            value={selectedIngredientAssignments}
            onChange={(e, ingredients) => {
              if (e.target.textContent === 'Other') {
                e.preventDefault();
              } else {
                dispatch({
                  type: UPDATE_SELECTED_INGREDIENTS,
                  value: ingredients,
                });
              }
            }}
          >
            {ingredientButtons(bulkIngredientAssignments)}
          </ToggleButtonGroup>
          {compoundIngredients.length > 0 &&
            compoundIngredients.map((compoundIngredient) => {
              return (
                <Fragment key={compoundIngredient.id}>
                  <h3>{compoundIngredient.name}</h3>
                  <ToggleButtonGroup
                    className={classes.buttonGroup}
                    size="large"
                    value={selectedIngredientAssignments}
                    onChange={(e, ingredient) => {
                      dispatch({
                        type: UPDATE_SELECTED_INGREDIENTS,
                        value: ingredient,
                      });
                    }}
                  >
                    {ingredientButtons(compoundIngredient.componentIngredients)}
                  </ToggleButtonGroup>
                </Fragment>
              );
            })}
          {facilityHasPortionedIngredients(packingFacilityId) &&
            portionedIngredientAssignments.length > 0 && (
              <>
                <h3>Portioned Ingredients</h3>
                <ToggleButtonGroup
                  className={classes.buttonGroup}
                  size="large"
                  value={selectedIngredientAssignments}
                  onChange={(e, ingredient) => {
                    dispatch({
                      type: UPDATE_SELECTED_INGREDIENTS,
                      value: ingredient,
                    });
                  }}
                >
                  {ingredientButtons(portionedIngredientAssignments)}
                </ToggleButtonGroup>
              </>
            )}
        </Card>
        {!piFetching && packagingItems.length > 0 && (
          <Card>
            <h3>Choose Packaging Items to Request</h3>
            <h3>Unlabeled Packaging</h3>
            <ToggleButtonGroup
              className={classes.buttonGroup}
              size="large"
              value={selectedIngredientPackagings}
              onChange={(e, ips) => {
                if (e.target.textContent === 'Other') {
                  e.preventDefault();
                } else {
                  dispatch({
                    type: UPDATE_SELECTED_INGREDIENT_PACKAGINGS,
                    value: ips,
                  });
                }
              }}
            >
              {packagingItemsButtons(unlabeledPackagingItems)}
            </ToggleButtonGroup>
            {facilityHasLabeledPackaging(packingFacilityId) &&
              labeledPackagingItems.length > 0 && (
                <>
                  <h3>Labeled Packaging</h3>
                  <ToggleButtonGroup
                    className={classes.buttonGroup}
                    size="large"
                    value={selectedIngredientPackagings}
                    onChange={(e, ips) => {
                      if (e.target.textContent === 'Other') {
                        e.preventDefault();
                      } else {
                        dispatch({
                          type: UPDATE_SELECTED_INGREDIENT_PACKAGINGS,
                          value: ips,
                        });
                      }
                    }}
                  >
                    {packagingItemsButtons(labeledPackagingItems)}
                  </ToggleButtonGroup>
                </>
              )}
          </Card>
        )}
        {nonComponentRequests.length > 0 && (
          <Card>
            <h3>Enter Quantity of Portions to Request</h3>
            {nonComponentRequests.map((requestable) => {
              const selectedWithRequestCount = find(
                selectedRequestablesWithCounts,
                requestableMatcher(requestable)
              );

              return renderQuantitySelection(
                requestable,
                selectedWithRequestCount
              );
            })}
          </Card>
        )}
        {componentRequests.length > 0 && (
          <Card>
            <h3>Enter Multiplier of Recipe</h3>
            {componentRequests.map((requestable) => {
              const selectedWithRequestCount = find(
                selectedRequestablesWithCounts,
                requestableMatcher(requestable)
              );

              return renderQuantitySelection(
                requestable,
                selectedWithRequestCount
              );
            })}
          </Card>
        )}
        <div className={classes.buttonsContainer}>
          <StatefulButton
            loading={itemMoveRequests.posting}
            failed={itemMoveRequests.postingFailed}
            disabled={!allFilledOut || disableSubmitButton}
            fullWidth
            classes={{
              disabled: classes.buttonDisabled,
              root: classes.submitButton,
            }}
            onClick={submitRequests}
            type="submit"
          />
        </div>
      </FormContainer>
      <SearchItemDrawer
        open={searchDrawerOpen}
        closeDrawer={onCloseSearchDrawer}
        addItemToForm={addOtherItemToForm}
        menuSelectionIngredientIds={ingredientAssignments.map(
          (ia) => ia.ingredientId
        )}
        menuSelectionPackagingItemIds={packagingItems.map((pi) => pi.id)}
        requestedIngredientIds={selectedIngredientAssignments
          .filter(
            (ia) => ia.requestAnything && ia.requestableType === 'Ingredient'
          )
          .map((ia) => ia.requestableId)}
        requestedPackagingItemIds={selectedIngredientPackagings
          .filter(
            (ia) => ia.requestAnything && ia.requestableType === 'PackagingItem'
          )
          .map((ia) => ia.requestableId)}
      />
    </Fragment>
  );
};

RequestsForm.propTypes = {
  podLocations: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onGetIngredientAssignments: PropTypes.func.isRequired,
  onSubmitMoveRequests: PropTypes.func.isRequired,
  onGetPackagingItems: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  ingredientAssignments: PropTypes.array.isRequired,
  packagingItems: PropTypes.array.isRequired,
  iaFetching: PropTypes.bool.isRequired,
  itemMoveRequests: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  piFetching: PropTypes.bool.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
};

export default withStyles(styles)(RequestsForm);
