import { GREY_200, WHITE } from 'lib/constants';

const centered = {
  display: 'flex',
  justifyContent: 'center',
};

const button = {
  padding: '20px',
  cursor: 'pointer',
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '16px',
  boxSizing: 'border-box',
  background: WHITE,
  border: `1px solid ${GREY_200}`,
};

export default {
  centered,
  sorryContainer: {
    ...centered,
    padding: '56px 14px',
  },
  loadingContainer: {
    ...centered,
    margin: 'auto',
  },
  button,
  modalButtons: {
    textTransform: 'none',
  },
};
