import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const createPreliminaryCheck = async ({
  selectedPod,
  ingredient,
  portionMethod,
  packaging,
  channel,
  menuId,
  testWeight1,
  testWeight2,
  meal,
  correctiveAction,
}) => {
  const response = await fetch(`${API_BASE_URI}/ops/qa/preliminary_checks`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      preliminary_check: {
        pod: selectedPod,
        meal_id: portionMethod === null ? meal.mealId : meal.id,
        checkable_id: portionMethod === null ? meal.mealId : ingredient.id,
        checkable_type: portionMethod === null ? 'Meal' : 'Ingredient',
        units_of_measure: ingredient?.unitOfMeasure,
        portion_method: portionMethod,
        packaging: packaging,
        channel: channel,
        menu_id: menuId,
        weights: {
          test_weight_1: parseFloat(testWeight1).toFixed(2),
          test_weight_2: parseFloat(testWeight2).toFixed(2),
        },
        corrective_action: correctiveAction,
      },
    }),
  });

  return await handleResponse(response);
};

const postQaChecks = async ({ issues, preliminaryCheckId, photo }) => {
  const response = await fetch(`${API_BASE_URI}/ops/qa/production_checks`, {
    // NEED TO UPDATE PORTION CONTROLLER TO USE THIS ROUTES INSTEAD
    ...options,
    method: 'POST',
    body: JSON.stringify({
      issues,
      photo,
      preliminary_check_id: preliminaryCheckId,
    }),
  });

  return await handleResponse(response);
};

const updatePreliminaryCheck = async ({
  correctiveActions,
  preliminaryCheckId,
}) => {
  const response = await fetch(`${API_BASE_URI}/ops/qa/preliminary_checks`, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      summary_corrective_action: correctiveActions.otherCorrectiveAction,
      summary_label_corrective_action: correctiveActions.labelCorrectiveAction,
      summary_other_comments: correctiveActions.additionalComments,
      preliminary_check_id: preliminaryCheckId,
    }),
  });

  return await handleResponse(response);
};

export default {
  createPreliminaryCheck,
  postQaChecks,
  updatePreliminaryCheck,
};
