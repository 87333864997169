import {
  DOCK_APPOINTMENT_POST,
  DOCK_APPOINTMENT_PUT,
  DOCK_APPOINTMENT_DELETE,
  DOCK_APPOINTMENTS,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import DockAppointment from './services';

export const postDockAppointment = (data, isManualAppointment) => async (
  dispatch
) => {
  await dispatch(
    makeApiRequest({
      type: DOCK_APPOINTMENT_POST,
      method: DockAppointment.postDockAppointment,
      params: {
        data,
        isManualAppointment,
      },
    })
  );
};

export const putDockAppointment = (data, isManualAppointment) => async (
  dispatch
) => {
  await dispatch(
    makeApiRequest({
      type: DOCK_APPOINTMENT_PUT,
      method: DockAppointment.putDockAppointment,
      params: {
        data,
        isManualAppointment,
      },
    })
  );
};

export const deleteDockAppointment = (appointmentId) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: DOCK_APPOINTMENT_DELETE,
      method: DockAppointment.deleteDockAppointment,
      params: {
        appointmentId,
      },
    })
  );
};

export const getDockAppointmentsForDate = (date) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: DOCK_APPOINTMENTS,
      method: DockAppointment.getDockAppointmentsForDate,
      params: {
        date,
      },
    })
  );
};
