import { GREY_600, HC_GREEN } from 'lib/constants';
import { centeredLoading } from 'lib/styles';

export default {
  centeredLoading,
  navLinksContainer: {
    fontSize: '16px',
    paddingTop: '20px',
    textAlign: 'center',
  },
  navLinkContainer: {
    paddingBottom: '5px',
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: GREY_600,
    width: '100%',
    paddingBottom: '18px',
    borderBottom: `2px solid ${GREY_600}`,
  },
  activeLink: {
    color: HC_GREEN,
    paddingBottom: '16px',
    borderBottom: `4px solid ${HC_GREEN}`,
  },
  comingSoon: {
    margin: '25px',
    textAlign: 'center',
    fontSize: '25px',
  },
  appContainer: {
    marginTop: '20px',
  },
};
