import { GREY_400, GREY_150, WHITE, ERROR_RED } from 'lib/constants';

import { separator, truckId } from '../WarehouseSummary/styles';
import {
  spanOne,
  spanTwo,
  grid,
  approvedContainer,
  approvedChip,
  approvedIcon,
} from '../styles';

const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default {
  truckLine: {
    borderBottom: `1px solid ${GREY_400}`,
    borderTop: `1px solid ${GREY_400}`,
    boxShadow: 'none',
    '&:last-child': { borderRadius: 'unset' },
    '&:first-child': { borderRadius: 'unset' },
    background: GREY_150,
    padding: '5px 1.5% 0px 1.5%',
    display: 'grid',
    gridTemplateColumns: '8fr [buttons-start] minmax(118px, 1fr) [buttons-end]',
    minHeight: '36px',
    alignItems: 'center',
  },

  truckId,
  separator,
  palletSpanTwo: {
    ...spanTwo,
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
  palletSpanOne: {
    ...spanOne,
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
  palletHeader: {
    ...grid,
    textTransform: 'uppercase',
    padding: '0.5% 1.5%',
    fontSize: '12px',
  },
  truck: {
    width: '30px',
    marginRight: '10px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  approvedContainer,
  approvedChip,
  approvedIcon,
  needsSummaryChip: {
    background: ERROR_RED,
    color: WHITE,
  },
  label: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  value: {
    marginRight: '10px',
    ...ellipsis,
  },
  truckDetail: {
    ...ellipsis,
    maxWidth: '23%',
    marginRight: '10px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  truckText: {
    ...ellipsis,
    maxWidth: '40%',
    marginRight: '10px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  truckInfo: {
    lineHeight: '1.2',
  },
};
