import { GREY_100, WHITE } from 'lib/constants';

export default {
  icon: {
    marginRight: '0px',
    minWidth: '35px',
  },
  itemRow: {
    position: 'relative',
  },
  swipeableBackground: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
  grey: {
    backgroundColor: GREY_100,
  },
  white: {
    backgroundColor: WHITE,
  },
  last: {
    borderBottom: `1px solid ${GREY_100}`,
  },
  disabled: {
    cursor: 'default',
  },
};
