import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';

import {
  getMealsForIngredientAssignment,
  clearMealsForIngredientAssignment,
} from 'redux/menuSelection/actions';
import { selectMenuSelectionsByIngredientAssignment } from 'redux/menuSelection/selectors';
import { Loading } from 'components/shared';
import { formatDateAsDDMMYY } from 'lib/utils';

import styles from './styles';
import IngredientAssignmentMealList from './IngredientAssignmentMealList';

export const IngredientAssignmentMealListContainer = ({
  classes,
  onGetMealsForIngredientAssignment,
  ingredientAssignments,
  ingredients,
  fetching,
  match,
  location,
  onClearMealsForIngredientAssignment,
}) => {
  useEffect(() => {
    if (!isEmpty(ingredients)) {
      const ingIdFromUrl = parseInt(location.pathname.split('/')[4]);
      const activity = location.pathname.split('/')[2];
      const { labelableIngredients, portionableIngredients } = ingredients;
      const ingredientsFromStore =
        activity === 'labeling' ? labelableIngredients : portionableIngredients;
      const selectedIngredient = find(ingredientsFromStore, {
        ingredientId: ingIdFromUrl,
      });
      const ingredientAssignmentIds =
        selectedIngredient.ingredientAssignmentIds;

      onGetMealsForIngredientAssignment(ingredientAssignmentIds, activity);
    }
  }, [onGetMealsForIngredientAssignment, match, location, ingredients]);

  useEffect(() => {
    return () => onClearMealsForIngredientAssignment();
  }, [onClearMealsForIngredientAssignment]);

  if (fetching) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  } else {
    return (
      <Fragment>
        {map(ingredientAssignments, (ingredientAssignmentsList, shipWeek) => (
          <div key={shipWeek}>
            <h4>WEEK {formatDateAsDDMMYY(shipWeek)}</h4>

            <IngredientAssignmentMealList
              classes={classes}
              ingredientAssignments={ingredientAssignmentsList}
              showNumberLeft
              urlParamsMenuId={Number(match.params.menuId)}
              fetching={fetching}
            />
          </div>
        ))}
      </Fragment>
    );
  }
};

IngredientAssignmentMealListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  onGetMealsForIngredientAssignment: PropTypes.func.isRequired,
  showNumberLeft: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedMenu: PropTypes.object,
  onClearMealsForIngredientAssignment: PropTypes.func.isRequired,
};

IngredientAssignmentMealListContainer.defaultProps = {
  showNumberLeft: false,
  selectedMenu: null,
};

const mapDispatchToProps = {
  onGetMealsForIngredientAssignment: getMealsForIngredientAssignment,
  onClearMealsForIngredientAssignment: clearMealsForIngredientAssignment,
};

const mapStateToProps = (state) => ({
  fetching: state.menuSelection.fetchingByIa,
  ingredientAssignments: selectMenuSelectionsByIngredientAssignment(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(IngredientAssignmentMealListContainer)));
