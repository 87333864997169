import React, { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import styles from './styles';
import {
  ItemManagerFormContext,
  fieldNames,
} from '../../ItemManagerFormProvider';

const useStyles = makeStyles(styles);

const CaseQuantity = ({ promptText, initialQuantity, maxValue }) => {
  const classes = useStyles();
  const { state, actions } = useContext(ItemManagerFormContext);

  const formField = fieldNames.quantity;
  const quantity = state[formField];
  const setQuantity = (value) => actions.setField(formField, value);

  const handleDecrementClick = () => {
    quantity > 0 && setQuantity(quantity - 1);
  };

  const handleIncrementClick = () => {
    setQuantity(quantity + 1);
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value === '' ? '' : Number(event.target.value);
    setQuantity(value);
  };

  useEffect(() => {
    setQuantity(initialQuantity);
  }, []);

  return (
    <div className={classes.caseQuantity}>
      <div className={classes.heading}>{promptText}</div>
      <Button
        variant="contained"
        classes={{ root: classes.quantityInputRoot }}
        onClick={handleDecrementClick}
        disabled={quantity.length === 0 || quantity <= 0}
        data-testid="decrement-button"
      >
        <RemoveIcon />
      </Button>
      <TextField
        type="number"
        variant="outlined"
        value={quantity}
        onChange={handleQuantityChange}
        className={classes.numberInput}
        inputProps={{
          className: classes.numberTextField,
          'data-testid': 'case-quantity-input',
        }}
      />
      <Button
        variant="contained"
        classes={{ root: classes.quantityInputRoot }}
        onClick={handleIncrementClick}
        disabled={quantity.length === 0 || quantity >= maxValue}
        data-testid="increment-button"
      >
        <AddIcon />
      </Button>
    </div>
  );
};

CaseQuantity.defaultProps = {
  promptText: '',
  initialQuantity: 0,
  maxValue: Number.MAX_SAFE_INTEGER,
};

CaseQuantity.propTypes = {
  promptText: PropTypes.string,
  initialQuantity: PropTypes.number,
  maxValue: PropTypes.number,
};
export default CaseQuantity;
