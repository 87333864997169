import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { selectDockAppointment } from 'redux/dockScheduler/selectors';

import AppointmentDrawer from './AppointmentDrawer';
import styles from './styles';

const mapStateToProps = (state, ownProps) => ({
  appointment: selectDockAppointment(state, ownProps.apptId),
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppointmentDrawer));
