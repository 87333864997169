import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchCountList,
  completeCountList,
  clearCycleCountInfo,
  refreshCountList,
} from 'redux/cycleCounting/actions';
import { updateCountListAssignment } from 'redux/countListAssignment/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { fetchPallet, updatePallet } from 'redux/pallets/actions';
import {
  setCycleCount,
  setUnknownLocation,
  cycleCountDeactivation,
} from 'redux/storageSlot/actions';

import styles from './styles';
import CycleCountingList from './CycleCountingList';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onFetchPallet: fetchPallet,
  onFetchCountList: fetchCountList,
  onUpdatePallet: updatePallet,
  onSetCycleCount: setCycleCount,
  onUpdateCountListAssignment: updateCountListAssignment,
  onSetUnknownLocation: setUnknownLocation,
  onCompleteCountList: completeCountList,
  clearCycleCountInfo,
  deactivatePallet: cycleCountDeactivation,
  onRefreshCountList: refreshCountList,
};

const mapStateToProps = (state) => ({
  userName: state.session.user_name,
  countList: state.cycleCounting.countList,
  pallet: state.pallets.inventoryApp.selectedPallet,
  measuringUnitsForSelectedPallet:
    state.pallets.inventoryApp.measuringUnitsForSelectedPallet,
  refreshCountListFetching: state.cycleCounting.refreshCountListFetching,
  refreshCountListError: state.cycleCounting.refreshCountListError,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(CycleCountingList)));
