import { MEDIA_QUERY_TABLET_OR_SMALLER } from 'lib/constants';

export default {
  root: {
    height: '436px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    overflow: 'hidden',
  },
  viewerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoContainer: {
    bottom: '510px',
  },
  hide: {
    zIndex: '-1',
  },
  photoButton: {
    position: 'relative',
    bottom: '90px',
    width: '100px',
    height: '75px',
  },
  buttonsContainer: {
    position: 'relative',
    bottom: '90px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  button: {
    height: '75px',
    width: '170px',
    fontSize: '18px',
  },
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    root: {
      height: '786px',
      padding: '5px',
    },
    photoContainer: {
      position: 'relative',
      bottom: '860px',
    },
    buttonsContainer: {
      position: 'relative',
      bottom: '90px',
    },
  },
};
