import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import {
  ITEM_LABELED,
  ITEM_ALLERGENS_CORRECT,
  ITEM_NAME_CORRECT,
} from 'lib/constants';

import { useValidator } from '../FsqaReviewForms/formHelpers';
import ItemQuestionButtons from './ItemQuestionButtons';
import styles from './styles';

const useStyles = makeStyles(styles);

const ItemQuestions = ({
  updateItemQuestion,
  formState,
  validationCallback,
}) => {
  const classes = useStyles();
  const isLabelPresent = formState[ITEM_LABELED] === true;

  useValidator(
    formState[ITEM_LABELED] !== undefined &&
      (!isLabelPresent || formState[ITEM_ALLERGENS_CORRECT] !== undefined) &&
      (!isLabelPresent || formState[ITEM_NAME_CORRECT] !== undefined),
    validationCallback
  );

  // this effect clears out the other questions if they are non applicable
  useEffect(() => {
    if (!formState[ITEM_LABELED]) {
      updateItemQuestion(ITEM_ALLERGENS_CORRECT, undefined);
      updateItemQuestion(ITEM_NAME_CORRECT, undefined);
    }
  }, [formState[ITEM_LABELED]]);

  return (
    <div data-testid="item-questions" className={classes.root}>
      <span className={classes.infoLabel}>ARE THE ITEMS LABELED?</span>
      <ItemQuestionButtons
        selectedButtonValue={formState[ITEM_LABELED]}
        onChange={(value) => updateItemQuestion(ITEM_LABELED, value)}
        nullButtonText="Bulk Item"
      />
      {isLabelPresent && (
        <>
          <span className={classes.infoLabel}>
            ARE THE ITEM ALLERGENS CORRECT?
          </span>
          <ItemQuestionButtons
            selectedButtonValue={formState[ITEM_ALLERGENS_CORRECT]}
            onChange={(value) =>
              updateItemQuestion(ITEM_ALLERGENS_CORRECT, value)
            }
          />
          <span className={classes.infoLabel}>IS THE ITEM NAME CORRECT?</span>
          <ItemQuestionButtons
            selectedButtonValue={formState[ITEM_NAME_CORRECT]}
            onChange={(value) => updateItemQuestion(ITEM_NAME_CORRECT, value)}
          />
        </>
      )}
    </div>
  );
};

ItemQuestions.propTypes = {
  updateItemQuestion: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  validationCallback: PropTypes.func,
};

ItemQuestions.defaultProps = {
  validationCallback: () => {},
};

export default ItemQuestions;
