import { HC_GREEN, WHITE, GREY_200 } from 'lib/constants';

const selected = {
  backgroundColor: HC_GREEN,
  color: WHITE,
  '&:hover': {
    backgroundColor: HC_GREEN,
  },
};

const centered = {
  display: 'flex',
  justifyContent: 'center',
};

export default {
  truckOrderContainer: {
    width: '100%',
  },
  infoLabel: {
    fontSize: 14,
  },
  infoValue: {
    fontSize: 20,
    margin: '5px 0px 20px',
    marginInlineStart: 0,
  },
  input: {
    padding: '12px',
  },
  list: {
    paddingTop: '0px',
    maxHeight: 306,
    overflow: 'auto',
    border: `1px solid ${GREY_200}`,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  listItemText: {
    flexGrow: 1,
    flexBasis: '50%',
    textAlign: 'left',
  },
  selected,
  sorryContainer: {
    ...centered,
    padding: '0',
  },
};
