import { makeApiRequest } from 'lib/api';
import { PRINTERS } from 'lib/constants';

import Printers from './services';

export const getLicensePlatePrinters = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: PRINTERS,
        method: Printers.getPrinters,
        params: {
          printerType: 'license_plate',
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};

export const getHoneyTagPrinters = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: PRINTERS,
        method: Printers.getPrinters,
        params: {
          printerType: 'honey_tag',
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};
