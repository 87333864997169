import { connect } from 'react-redux';

import { clearSession } from 'redux/session/actions';

import AccountSwitch from './AccountSwitch';

const mapStateToProps = (state) => ({
  userName: state.session.user_name,
});

const mapDispatchToProps = {
  onClearSession: clearSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSwitch);
