import { ERROR_RED, GREY_600, ROBOTO } from 'lib/constants';

export default {
  container: {
    margin: '24px',
  },
  link: {
    fontSize: '14px',
    padding: '11px 24px',
    color: GREY_600,
    fontFamily: ROBOTO,
    fontWeight: '500',
  },
  error: {
    color: ERROR_RED,
    margin: '24px',
    fontFamily: ROBOTO,
    fontSize: '14px',
  },
  linkContainer: {
    display: 'block',
    borderBottom: 'none',
    paddingBottom: '10px',
  },
};
