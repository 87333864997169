import { createSelector } from 'reselect';

const selectUsersByFacility = (userSearchResults, sessionPackingFacilityId) => {
  if (sessionPackingFacilityId && userSearchResults) {
    return userSearchResults.filter(
      (results) => results.packingFacilityId === sessionPackingFacilityId
    );
  } else return userSearchResults;
};

export const filterSearchResultsByPackingFacilityId = createSelector(
  selectUsersByFacility,
  (users) => users
);
