export default {
  root: {
    fontFamily: 'Muli',
  },
  toggleAllButtonGroup: {
    float: 'right',
  },
  controls: {
    height: '60px',
  },
};
