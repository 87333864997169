import theme from 'lib/theme';

export default {
  formContainer: {
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
};
