import { HC_GREEN } from 'lib/constants';

export default {
  filled: {
    height: 20,
    width: 20,
    backgroundColor: HC_GREEN,
    borderRadius: '50%',
    display: 'inline-block',
  },
  partial: {
    height: 20,
    width: 10,
    backgroundColor: HC_GREEN,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    display: 'inline-block',
  },
};
