import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { makeApiRequest } from 'lib/api';
import netsuiteOrderItemsModels from 'redux/netsuiteOrderItem/services';
import { NETSUITE_ORDER_ITEMS } from 'lib/constants';
import {
  storeSearchQuery,
  clearSearchQuery,
} from 'redux/netsuiteOrderItem/actions';
import {
  postDockAppointment,
  putDockAppointment,
  deleteDockAppointment,
} from 'redux/dockScheduler/actions';

import styles from './styles';
import AppointmentSubmissionForm from './AppointmentSubmissionForm';

const AppointmentSubmissionFormContainer = ({
  packingFacilityId,
  onMakeApiRequest,
  ...restOfProps
}) => {
  useEffect(() => {
    onMakeApiRequest({
      type: NETSUITE_ORDER_ITEMS,
      method: netsuiteOrderItemsModels.byOpenNetsuiteOrders,
      params: { facilityId: packingFacilityId },
    });
  }, [onMakeApiRequest, packingFacilityId]);

  return <AppointmentSubmissionForm {...restOfProps} />;
};

const mapStatetoProps = (state) => ({
  packingFacilityId: state.session.packing_facility_id,
  threeWeeksOfNetsuiteOrderItems: state.netsuiteOrderItems.threeWeeksOutNOs,
  searchResultsNOs: state.netsuiteOrderItems.queryResults,
  posting: state.dockScheduler.posting,
  updating: state.dockScheduler.updating,
  errors: state.dockScheduler.errors,
});

const mapDispatchToProps = {
  onMakeApiRequest: makeApiRequest,
  onPostDockAppointment: postDockAppointment,
  onPutDockAppointment: putDockAppointment,
  onDeleteDockAppointment: deleteDockAppointment,
  onStoreSearchQuery: storeSearchQuery,
  clearSearch: clearSearchQuery,
};

export default withStyles(styles)(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(AppointmentSubmissionFormContainer)
);
