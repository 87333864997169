import { alpha } from '@mui/material/styles';

import {
  BLUE,
  WHITE,
  BLACK,
  ERROR_RED,
  GREY_600,
  GREY_200,
  GREY_400,
  GREY_800,
  HC_GREEN,
  ORANGE_YELLOW,
  MEDIA_QUERY_PHONE_SCREEN,
  BIGGER_THAN_TABLET,
} from 'lib/constants';

const sharedSubmitButtonStyles = {
  width: '47%',
  color: WHITE,
  margin: '0px auto',
  fontWeight: 'bolder',
  border: `1px solid ${GREY_200}`,
  fontSize: '14px',
  '&:hover.Mui-disabled': {
    color: BLACK,
    backgroundColor: GREY_200,
  },
  '&.Mui-disabled': {
    color: BLACK,
    backgroundColor: GREY_200,
  },
};

export default {
  centered: {
    margin: '50px auto',
    textAlign: 'center',
  },
  card: {
    margin: '5px',
  },
  header: {
    backgroundColor: GREY_800,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    borderRadius: '5px 5px 0px 0px',
  },
  forwardArrow: {
    verticalAlign: 'sub',
    color: GREY_600,
    fontSize: '20px',
  },
  greenCheck: {
    color: HC_GREEN,
  },
  icon: {
    width: '25px',
    display: 'inline-block',
    marginRight: '10px',
  },
  text: {
    marginTop: '10px',
    color: GREY_600,
    display: 'inline-block',
    verticalAlign: 'middle',
    maxWidth: '80%',
  },
  lightText: {
    color: GREY_600,
  },
  boldText: {
    color: GREY_800,
    fontWeight: 'bold',
  },
  palletIssueDialogModal: {
    height: '340px',
  },
  palletIssueTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  reportPalletIssueButton: {
    ...sharedSubmitButtonStyles,
    color: BLACK,
    width: '100%',
    backgroundColor: `${ORANGE_YELLOW}`,
    '&:hover': {
      color: BLACK,
      backgroundColor: ORANGE_YELLOW,
    },
  },
  palletIssueButton: {
    color: HC_GREEN,
    height: '55px',
    fontWeight: 600,
    width: '100%',
    border: `solid 0.5px ${GREY_400}`,
    '&.selected': {
      border: `solid 1px ${HC_GREEN}`,
      backgroundColor: alpha(HC_GREEN, 0.1),
    },
  },
  palletIssueCancelButton: {
    height: '55px',
    fontWeight: 600,
    width: '100%',
  },
  submitIssueBtn: {
    ...sharedSubmitButtonStyles,
    width: '100%',
    height: '55px',
    backgroundColor: `${HC_GREEN}`,
    '&:hover': {
      color: WHITE,
      backgroundColor: HC_GREEN,
    },
  },
  okButton: {
    ...sharedSubmitButtonStyles,
    width: '100%',
    backgroundColor: `${HC_GREEN}`,
    '&:hover': {
      color: WHITE,
      backgroundColor: HC_GREEN,
    },
  },
  assignButton: {
    ...sharedSubmitButtonStyles,
    backgroundColor: `${HC_GREEN}`,
    '&:hover': {
      color: WHITE,
      backgroundColor: HC_GREEN,
    },
  },
  unassign: {
    ...sharedSubmitButtonStyles,
    backgroundColor: `${WHITE}`,
    color: HC_GREEN,
    '&:hover': {
      color: HC_GREEN,
      backgroundColor: WHITE,
    },
  },
  //add hover states
  cancelButton: {
    ...sharedSubmitButtonStyles,
    color: ERROR_RED,
  },
  rightSide: {
    display: 'inline',
    margin: '5px',
  },
  chip: {
    fontWeight: 'bolder',
    height: '24px',
    float: 'right',
    marginTop: '9px',
  },
  myChip: {
    backgroundColor: BLUE,
    color: WHITE,
  },
  ingName: {
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  line: {
    border: 0,
    borderTop: `1px solid ${GREY_200}`,
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    noStorageSlot: {
      margin: 0,
    },
    tryAgainContainer: {
      margin: 0,
      paddingTop: '10px',
    },
  },
  [BIGGER_THAN_TABLET]: {
    qrCodeAndMessage: {
      display: 'none',
    },
  },
  tryAgainButton: {
    border: `1px solid ${GREY_200}`,
    padding: '20px',
    fontSize: '16px',
    textTransform: 'none',
    borderRadius: '8px',
    backgroundColor: WHITE,
  },
  ingredientId: {
    marginTop: '10px',
    color: GREY_600,
    display: 'block',
  },
  palletDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  locationColor: {
    height: '12px',
    width: '12px',
    borderRadius: '45px',
    margin: '0 5px',
    border: `1px solid ${BLACK}`,
    marginTop: '4px',
  },
  location: {
    display: 'flex',
    marginBottom: '4px',
  },
  dialog: {
    margin: '10px',
    width: '-webkit-fill-available',
    maxHeight: 'calc(100% - 24px)',
  },
  warningIcon: {
    verticalAlign: 'bottom',
    paddingRight: '5px',
  },
  orangeIcon: {
    color: ORANGE_YELLOW,
  },
  noViablePalletTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
  },
  reprintLicensePlateButton: {
    ...sharedSubmitButtonStyles,
    width: '100%',
    height: '55px',
    marginTop: '30px',
    backgroundColor: `${HC_GREEN}`,
    '&:hover': {
      color: WHITE,
      backgroundColor: HC_GREEN,
    },
  },
};
