import {
  WHITE,
  LIGHT_GREEN,
  GREY_200,
  BLACK,
  MEDIA_QUERY_TABLET,
} from 'lib/constants';

export default {
  button: {
    textTransform: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    border: '1px solid',
    borderRadius: '8px',
    background: WHITE,
    padding: '16px',
    color: LIGHT_GREEN,
  },
  modalButtons: {
    textTransform: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    border: `1px solid ${GREY_200}`,
    boxSizing: 'border-box',
    margin: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  promptContainer: {
    paddingTop: '12px !important',
    paddingBottom: '4px',
    overflow: 'hidden',
  },
  promptText: {
    color: BLACK,
  },
  muiPaper: {
    maxHeight: '100%',
  },
  auditOptionsContainer: {
    marginLeft: '-12px',
    paddingBottom: '4px',
  },
  [MEDIA_QUERY_TABLET]: {
    button: {
      fontSize: '36px',
    },
  },
};
