import {
  ROBOTO,
  HC_GREEN,
  DARK_HC_GREEN,
  GREY_200,
  GREY_600,
  GREY_800,
  WHITE,
  BLACK,
} from 'lib/constants';

const inputLabel = {
  fontSize: '16px',
  fontWeight: '700',
  fontFamily: ROBOTO,
  marginBottom: '12px',
  color: BLACK,
};

export default {
  closeButton: {
    textAlign: 'right',
  },
  closeIcon: {
    width: 20,
    height: 20,
    padding: '20px 20px 0px 20px',
  },
  header: {
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    justifyContext: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: ROBOTO,
    paddingBottom: '24px',
  },
  successIcon: {
    color: HC_GREEN,
    fontSize: '40px',
  },
  submissionInfo: {
    fontSize: '14px',
    lineHeight: '200%',
    marginBottom: '24px',
    fontFamily: ROBOTO,
  },
  quantityInputLabel: {
    ...inputLabel,
  },
  quantityQuestion: {
    marginBottom: '24px',
  },
  printerInputLabel: {
    ...inputLabel,
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  printerButton: {
    marginLeft: 0,
    flex: '1 1 5%',
    margin: '5px !important',
    border: `1px solid ${GREY_600} !important`,
    borderRadius: '5px !important',
    display: 'inline-block',
    fontSize: '15px',
    fontFamily: ROBOTO,
    color: GREY_800,
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
  cancelButton: {
    width: '100%',
    fontFamily: ROBOTO,
    fontSize: '16px',
    padding: '10px 50px 10px 50px',
    '&:first-child': {
      '&:hover': {
        backgroundColor: GREY_200,
      },
      color: GREY_800,
      border: `1px solid ${GREY_600}`,
      backgroundColor: WHITE,
    },
  },
  printButton: {
    width: '100%',
    backgroundColor: HC_GREEN,
    color: WHITE,
    fontFamily: ROBOTO,
    fontSize: '16px',
    padding: '10px 50px 10px 50px',
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
    '&:disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
  },
};
