import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';

import {
  useSelectionDrawer,
  useLocalObjectStorage,
  WR_QUEUE_TABLE_CONFIG_PATH,
} from 'lib/custom_hooks';
import { Loading } from 'components/shared';
import { stylesWithWarehouseReceiving } from 'lib/dataTableTheme';

import UpdateDialog from './UpdateDialog';
import {
  defaultTableConfiguration,
  options,
  functionAssociations,
} from './dataTableConfig';

const Queue = ({
  classes,
  onGetMoveRequests,
  requests,
  fetching,
  onSubscribeToItemMoveRequests,
  onUnsubscribeToItemMoveRequests,
}) => {
  useEffect(() => {
    onGetMoveRequests();
  }, [onGetMoveRequests]);

  useEffect(() => {
    onSubscribeToItemMoveRequests();
    return () => {
      onUnsubscribeToItemMoveRequests();
    };
  }, [onSubscribeToItemMoveRequests, onUnsubscribeToItemMoveRequests]);

  const [getTableConfig, setTableConfig] = useLocalObjectStorage(
    WR_QUEUE_TABLE_CONFIG_PATH
  );

  const dataOptions = {
    ...options,
    onViewColumnsChange: (changedColumn, action) => {
      const config = getTableConfig();

      const columnIndex = config.findIndex((columnConfig) => {
        return columnConfig.name === changedColumn;
      });

      if (action === 'add') {
        config[columnIndex].options.display = true;
      } else if (action === 'remove') {
        config[columnIndex].options.display = false;
      }

      setTableConfig(config);
    },
    onFilterChange: (changedColumn, filterList) => {
      const config = getTableConfig();

      const columnIndex = config.findIndex((columnConfig) => {
        return columnConfig.name === changedColumn;
      });

      config[columnIndex].options.filterList = filterList[columnIndex];

      setTableConfig(config);
    },
  };

  const getColumns = () => {
    let config = getTableConfig();

    if (config == null) {
      setTableConfig(defaultTableConfiguration);
      config = getTableConfig();
    }

    const withFunctions = config.map((column) => {
      column.options.customBodyRender =
        functionAssociations[column?.key]?.customBodyRender;
      column.options.sortCompare =
        functionAssociations[column?.key]?.sortCompare;
      return column;
    });

    return withFunctions;
  };

  const {
    drawerOpen,
    selectedItem,
    optionsWithRowClick,
    onDrawerClose,
    toggleDrawer,
  } = useSelectionDrawer(requests, dataOptions);

  if (fetching) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <ThemeProvider theme={stylesWithWarehouseReceiving}>
        <MUIDataTable
          title="Move Requests"
          id="requestsTable"
          data={requests}
          columns={getColumns()}
          options={optionsWithRowClick}
        />
      </ThemeProvider>
      <UpdateDialog
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
        closeDialog={onDrawerClose}
        rowData={selectedItem}
      />
    </div>
  );
};

const requestShape = PropTypes.shape({
  requestStart: PropTypes.string.isRequired,
  dropOffTime: PropTypes.string,
  palletId: PropTypes.string,
  quantity: PropTypes.number,
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  submitter: PropTypes.string.isRequired,
  dropOffLocation: PropTypes.string.isRequired,
  runner: PropTypes.string,
  pickUpTime: PropTypes.string,
});

Queue.propTypes = {
  classes: PropTypes.object.isRequired,
  onGetMoveRequests: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  requests: PropTypes.arrayOf(requestShape).isRequired,
  onSubscribeToItemMoveRequests: PropTypes.func.isRequired,
  onUnsubscribeToItemMoveRequests: PropTypes.func.isRequired,
};

export default Queue;
