import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { useServerSideMuiDataTable } from 'lib/custom_hooks';
import { Loading } from 'components/shared';
import theme from 'lib/dataTableTheme';
import { QuantityContext } from 'lib/utils';

import CustomFooter from './CustomFooter';
import { columns, options } from './dataTableConfig';

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontWeight: 'bolder',
    borderBottom: '4px solid #008600',
  },
}));

const foodEmojis = {
  bacon: '🥓',
  bean: '🫘',
  beef: '🐄',
  brisket: '🐄',
  burger: '🍔',
  chicken: '🍗',
  egg: '🥚',
  falafel: '🧆',
  'mahi-mahi': '🐟',
  pasta: '🍝',
  pork: '🐖',
  pizza: '🍕',
  ramen: '🍜',
  salad: '🥗',
  salmon: '🐟',
  sausage: '🌭',
  scallops: '🦪',
  seitan: '🍢',
  shrimp: '🦐',
  steak: '🥩',
  tacos: ' 🌮',
  tamales: '🫔',
  tofu: '🍢',
  turkey: '🦃',
};

const transformMealTitle = (mealTitle) => {
  const reversedTitle = mealTitle.split(' ').reverse().join(' ');
  const regex = /\b(bacon|beans?|beef|brisket|burgers?|chicken|eggs?|falafel|mahi-mahi|pasta|pork|pizza|ramen|salad|salmon|sausage|scallops?|seitan|shrimp|steak|tacos?|tamales?|tofu|turkey)\b/i;
  const match = reversedTitle.match(regex);

  if (match && match.at(0)) {
    const foodOption = match.at(0).toLowerCase();
    const emoji = foodEmojis[foodOption] || '';
    const reversedEmojiTitle = `${emoji} ${reversedTitle}`;
    const finalTitle = reversedEmojiTitle.split(' ').reverse().join(' ');
    return finalTitle;
  } else {
    return mealTitle;
  }
};

const ProductionSubmissionsTable = ({
  onTableChange,
  results,
  fetching,
  pageCount,
  podLocations,
  quantitySubmitted,
  allLetters,
}) => {
  const classes = useStyles();

  // dynamically update filter options when that data comes in
  const columnOptions = useMemo(
    () =>
      columns.map((column) => {
        if (column.name === 'facility_internal_location_name') {
          column.options = {
            filterOptions: {
              names: podLocations.map((location) => location.description),
            },
          };
        } else if (column.name === 'meal_letter') {
          column.options = {
            filterOptions: {
              names: allLetters,
            },
          };
        }
        return column;
      }),
    [podLocations, allLetters]
  );

  // dynamically update pageCount when that changes; and keeping JSX customFooter code inside component
  const tableOptions = useMemo(
    () => ({
      ...options,
      count: pageCount,
      textLabels: {
        body: {
          noMatch: fetching ? <Loading /> : 'No results found',
        },
      },
      customFooter: function footer(
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) {
        return (
          <CustomFooter
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            onPageChange={(_, newPage) => changePage(newPage)}
            onRowsPerPageChange={(event) =>
              changeRowsPerPage(event.target.value)
            }
            rowsPerPageOptions={[10, 15, 100]}
          />
        );
      },
    }),
    [pageCount, fetching]
  );

  const resultsWithEmojiMeats = results.map((result) => ({
    ...result,
    meal_title: transformMealTitle(result.meal_title),
  }));

  const { serverSideTableOptions } = useServerSideMuiDataTable(
    onTableChange,
    tableOptions
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QuantityContext.Provider value={quantitySubmitted}>
          <MUIDataTable
            title={
              <span className={classes.tableTitle}>Production Submissions</span>
            }
            data={resultsWithEmojiMeats}
            columns={columnOptions}
            options={serverSideTableOptions}
          />
        </QuantityContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ProductionSubmissionsTable.propTypes = {
  onTableChange: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  fetching: PropTypes.bool,
  pageCount: PropTypes.number.isRequired,
  quantitySubmitted: PropTypes.number.isRequired,
  podLocations: PropTypes.array,
  allLetters: PropTypes.array.isRequired,
};

ProductionSubmissionsTable.defaultProps = {
  fetching: false,
  podLocations: [],
};

export default ProductionSubmissionsTable;
