import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const byMenu = async ({ menuId, facilityId, shipDate }) => {
  const baseUrl = `${API_BASE_URI}/ops/menus/${menuId}/packing_facilities/${facilityId}/boxing_inventories`;
  const url = shipDate ? baseUrl + `/?ship_date=${shipDate}` : baseUrl;

  const response = await fetch(url, options);
  const data = await handleResponse(response);
  return data;
};

export default {
  byMenu,
};
