import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';

import { selectMenu } from 'redux/menu/actions';
import { getMenuSelections } from 'redux/menuSelection/actions';
import { Loading } from 'components/shared';

import styles from './styles';
import MealList from './MealList';

export const MealListContainer = ({
  classes,
  menus,
  onSelectMenu,
  selectedMenu,
  onGetMenuSelections,
  menuSelections,
  showNumberLeft,
  fetching,
  match,
  history,
  location,
  disableRowLink,
  forReadyToHeat,
  enablePickableRow,
}) => {
  // sets 1st menu as the default on initial page load
  useEffect(() => {
    if (match.params.menuId === undefined && menus.length > 0) {
      history.replace(`${location.pathname}/${menus[0].id}`);
    }
  }, [menus, match.params.menuId]);

  // when the selected menu changes && we don't already have info for that menu, then fetch data just for that menu
  useEffect(() => {
    selectedMenu?.id &&
      menuSelections[0]?.menuId !== selectedMenu.id &&
      onGetMenuSelections(selectedMenu.id, forReadyToHeat);
  }, [selectedMenu?.id]);

  // sets selected menu in redux based off updated url param
  useEffect(() => {
    const selected = menus.find(
      (menu) => menu.id === Number(match.params.menuId)
    );
    if (!isEmpty(selected)) {
      onSelectMenu(selected);
    }
  }, [onSelectMenu, menus, match.params.menuId]);

  if (menus.length === 0 || match.params.menuId === undefined) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  } else if (!isEmpty(selectedMenu)) {
    return (
      <Fragment>
        <MealList
          classes={classes}
          menus={menus}
          menuSelections={menuSelections}
          showNumberLeft={showNumberLeft}
          fetching={fetching}
          disableRowLink={disableRowLink}
          selectedMenu={selectedMenu}
          enablePickableRow={enablePickableRow}
        />
      </Fragment>
    );
  } else {
    return (
      <h4>
        Sorry, the menu you requested is not in the current three week time.
        Please select a menu above.
      </h4>
    );
  }
};

MealListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  menus: PropTypes.array.isRequired,
  onGetMenuSelections: PropTypes.func.isRequired,
  menuSelections: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  showNumberLeft: PropTypes.bool,
  fetching: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedMenu: PropTypes.object,
  disableRowLink: PropTypes.bool,
  forReadyToHeat: PropTypes.bool,
  enablePickableRow: PropTypes.bool,
};

MealListContainer.defaultProps = {
  showNumberLeft: false,
  selectedMenu: null,
  disableRowLink: false,
  forReadyToHeat: false,
  enablePickableRow: false,
};

const mapDispatchToProps = {
  onSelectMenu: selectMenu,
  onGetMenuSelections: getMenuSelections,
};

const mapStateToProps = (state) => ({
  menus: state.menus.results,
  selectedMenu: state.menus.selectedMenu,
  fetching: state.menuSelection.fetching,
  menuSelections: state.menuSelection.menuSelections,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(MealListContainer)));
