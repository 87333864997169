import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

import ContentModal from 'components/shared/ContentModal';
import { changeoverZone } from 'redux/itemManager/actions';
import { Snackbar } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const ChangeoverButton = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { locationId } = useParams();

  const selectedLocation = useSelector((state) =>
    state.podLocations?.pods.find((l) => l.id === Number(locationId))
  );

  const { changeoverLoading, changeoverError } = useSelector(
    (state) => state.itemManager
  );
  const closeModal = () => {
    setIsOpen(false);
  };

  const submitChangeover = async () => {
    const response = await dispatch(
      changeoverZone({ locationId: selectedLocation?.id })
    );

    if (response.ok) {
      closeModal();
    }
  };

  return (
    <div>
      <Button className={classes.button} onClick={() => setIsOpen(true)}>
        CHANGEOVER
      </Button>
      <ContentModal
        open={isOpen}
        onClose={closeModal}
        title={`Changeover for ${selectedLocation?.description}`}
        subTitle="Have you returned all leftover items to the drop zone? Clicking confirm will clear all items from this Pod."
      >
        <Button onClick={submitChangeover}>Confirm</Button>
        <Button onClick={closeModal}>Cancel</Button>
      </ContentModal>
      <Snackbar
        loading={changeoverLoading}
        failed={changeoverError}
        messaging={{
          loading: 'Submitting changeover...',
          failure: 'Changeover failed',
          success: 'Success!',
        }}
      />
    </div>
  );
};

export default ChangeoverButton;
