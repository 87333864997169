import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import includes from 'lodash/includes';

import { DROP_LIST_FILTER_ONLY, DROP_LIST_FILTER_NONE } from 'lib/constants';

const selectFilteredMoveRequests = (state) => {
  const filters = [
    { filterField: 'pickUpAreas', requestField: 'pickUpAreaId' },
    { filterField: 'dropOffAreas', requestField: 'dropOffAreaId' },
    { filterField: 'pickerUppers', requestField: 'runner' },
  ];

  let filteredResults = [...state.itemMoveRequests.itemMoveRequests];

  filters.forEach(({ filterField, requestField }) => {
    if (state.itemMoveRequests.filters[filterField].length > 0) {
      filteredResults = filteredResults.filter((request) => {
        return includes(
          state.itemMoveRequests.filters[filterField],
          request[requestField]
        );
      });
    }
  });

  return filteredResults;
};

const selectFilteredMoveRequestsForWarehouseMoves = (state) => {
  const { itemMoveRequests, filters } = state.itemMoveRequests;
  let filteredResults = [...itemMoveRequests];

  if (filters.pickUpArea) {
    filteredResults = filteredResults.filter((request) => {
      return request.assignedStorageAreaId === filters.pickUpArea;
    });
  }

  if (filters.dropList === DROP_LIST_FILTER_ONLY) {
    filteredResults = filteredResults.filter(
      (request) => request.submitter === 'Drop List'
    );
  } else if (filters.dropList === DROP_LIST_FILTER_NONE) {
    filteredResults = filteredResults.filter(
      (request) => request.submitter !== 'Drop List'
    );
  }

  return filteredResults;
};

const groupByStatus = (request) => {
  if (request.droppedOff || request.cancelled) {
    return 'completed';
  } else if (request.pickedUp) {
    return 'inProgress';
  } else {
    return 'toDo';
  }
};

export const selectItemMoveRequestsBucketed = createSelector(
  selectFilteredMoveRequests,
  (filteredMoveRequests) => groupBy(filteredMoveRequests, groupByStatus)
);

export const selectItemMoveRequestsBucketedForWarehouseMoves = createSelector(
  selectFilteredMoveRequestsForWarehouseMoves,
  (filteredMoveRequests) => groupBy(filteredMoveRequests, groupByStatus)
);

export const selectItemMoveRequest = (state, id) =>
  find(state.itemMoveRequests.itemMoveRequests, { id: Number(id) });

export const selectFollowUp = (state, parentId) =>
  find(state.itemMoveRequests.itemMoveRequests, { parentId: Number(parentId) });
