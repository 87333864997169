import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

import { StatefulButton } from 'components/shared';
import { DEACTIVATED } from 'lib/constants';
import { formatPalletInfo } from 'components/WarehouseMoves/helpers';

import styles from '../../styles';

const useStyles = makeStyles(styles);

const PROBLEM_WITH_PALLET = 'Problem With Pallet';
const PALLET_NOT_PRESENT = 'Pallet Not Present';
const PALLET_UTILIZED_IN_PROD = 'Pallet Utilized In Prod';

const getWarningText = {
  [PROBLEM_WITH_PALLET]: 'This pallet will go on hold under inventory control.',
  [PALLET_NOT_PRESENT]: 'This pallet will go on hold for unknown location.',
  [PALLET_UTILIZED_IN_PROD]: 'This pallet will be deactivated.',
};

const PalletIssueView = ({
  suggestedPallet,
  itemMoveRequest,
  onReportIssueSubmit,
  onCloseModal,
  loading,
  failed,
}) => {
  const classes = useStyles();

  const [reasonCode, setReasonCode] = useState('');

  const hasItemMoveRequest = !isEmpty(itemMoveRequest);

  const suggestedPalletIsDeactivated = suggestedPallet?.status === DEACTIVATED;

  const showWarningText =
    !suggestedPalletIsDeactivated ||
    reasonCode === PROBLEM_WITH_PALLET ||
    reasonCode === PALLET_UTILIZED_IN_PROD;

  const warningText = getWarningText[reasonCode];

  const buttonClicked = reasonCode !== '';

  const reportPalletIssueButtonText = {
    LOADING: 'Updating...',
    SUCCESS: 'Success',
    ERROR: 'There was an error submitting',
    DEFAULT: 'Report Issue',
  };

  useEffect(() => {
    return () => setReasonCode('');
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid className={classes.palletIssueTitle} item xs={12}>
        <div>Report Pallet Issue</div>
      </Grid>
      <Grid item xs={12}>
        <div>{formatPalletInfo(suggestedPallet)}</div>
      </Grid>

      <Grid item xs={12}>
        <div>Select a reason to report a pallet issue.</div>
      </Grid>

      {hasItemMoveRequest && (
        <Grid item xs={6}>
          <Button
            variant="outlined"
            id="problem-with-pallet"
            className={classnames(classes.palletIssueButton, {
              selected: reasonCode === PROBLEM_WITH_PALLET,
            })}
            onClick={() => setReasonCode(PROBLEM_WITH_PALLET)}
          >
            {PROBLEM_WITH_PALLET}
          </Button>
        </Grid>
      )}

      <Grid item xs={6}>
        <Button
          variant="outlined"
          id="pallet-not-present"
          className={classnames(classes.palletIssueButton, {
            selected: reasonCode === PALLET_NOT_PRESENT,
          })}
          onClick={() => setReasonCode(PALLET_NOT_PRESENT)}
        >
          {PALLET_NOT_PRESENT}
        </Button>
      </Grid>
      {!hasItemMoveRequest && (
        <Grid item xs={6}>
          <Button
            variant="outlined"
            id="pallet-utilized-in-prod"
            className={classnames(classes.palletIssueButton, {
              selected: reasonCode === PALLET_UTILIZED_IN_PROD,
            })}
            onClick={() => setReasonCode(PALLET_UTILIZED_IN_PROD)}
          >
            {PALLET_UTILIZED_IN_PROD}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {buttonClicked && showWarningText && (
        <Grid item xs={12}>
          <Alert severity="warning">{warningText}</Alert>
        </Grid>
      )}
      <Grid item xs={4}>
        <Button
          className={classes.palletIssueCancelButton}
          variant="outlined"
          onClick={onCloseModal}
          color="neutral"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={8}>
        <StatefulButton
          id="submit-pallet-issue-btn"
          className={classes.submitIssueBtn}
          loading={loading}
          failed={failed}
          onClick={() => onReportIssueSubmit(reasonCode)}
          buttonTextOptions={reportPalletIssueButtonText}
          disabled={!buttonClicked}
        >
          Report Issue
        </StatefulButton>
      </Grid>
    </Grid>
  );
};

PalletIssueView.defaultProps = {
  itemMoveRequest: {},
  suggestedPallet: null,
  loading: false,
  failed: false,
};

PalletIssueView.propTypes = {
  itemMoveRequest: PropTypes.object,
  suggestedPallet: PropTypes.shape({
    id: PropTypes.number,
    storageArea: PropTypes.string,
  }),
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  onReportIssueSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default PalletIssueView;
