import React from 'react';
import PropTypes from 'prop-types';

import { WHITE } from 'lib/constants';

const CircledCheckmark = ({ className, color }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Check_Circle" data-name="Check Circle">
            <path
              d="M53.81,87.88,33.45,67.52a5,5,0,1,1,7.07-7.07L53.81,73.73,83.46,44.08a5,5,0,1,1,7.08,7.07Z"
              style={{ fill: color }}
            />
            <path
              d="M64,128a64,64,0,1,1,64-64A64.07,64.07,0,0,1,64,128ZM64,10a54,54,0,1,0,54,54A54.06,54.06,0,0,0,64,10Z"
              style={{ fill: color }}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

CircledCheckmark.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

CircledCheckmark.defaultProps = {
  className: 'CircledCheckmark',
  color: WHITE,
};

export default CircledCheckmark;
