import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  updateQaPortionChecks,
  postQaPortionCheck,
} from 'redux/qa/portionChecks/actions';

import QaPortioning from './QaPortioning';

const QaPortioningContainer = ({
  onPostQaPortionCheck,
  qaPortionChecks,
  location,
  onUpdateQaPortionChecks,
}) => {
  return (
    <QaPortioning
      onPostQaPortionCheck={onPostQaPortionCheck}
      onUpdateQaPortionChecks={onUpdateQaPortionChecks}
      qaPortionChecks={qaPortionChecks}
      location={location}
    />
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
  qaPortionChecks: state.qaPortionChecks,
});

const mapDispatchToProps = {
  onPostQaPortionCheck: postQaPortionCheck,
  onUpdateQaPortionChecks: updateQaPortionChecks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QaPortioningContainer);

QaPortioningContainer.propTypes = {
  onUpdateQaPortionChecks: PropTypes.func.isRequired,
  onPostQaPortionCheck: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  qaPortionChecks: PropTypes.shape({
    checks: PropTypes.array.isRequired,
  }).isRequired,
};
