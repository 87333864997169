import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getIngredients = async () => {
  const response = await fetch(
    `${API_BASE_URI}/ops/production_inventories/ingredients`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

const getProductionProgressForIngredient = async ({
  packingFacilityId,
  menuShipWeek,
  ingredientId,
  activity,
}) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/menus/${menuShipWeek}/packing_facilities/${packingFacilityId}`,
      {
        ingredient_id: ingredientId,
        activity,
      }
    ),
    options
  );

  return await handleResponse(response);
};

const getProductionProgressForCustomizeIt = async ({
  shipWeek,
  ingredientAssignmentId,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/ingredient_assignments/${ingredientAssignmentId}/?ship_week=${shipWeek}`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

export default {
  getIngredients,
  getProductionProgressForIngredient,
  getProductionProgressForCustomizeIt,
};
