import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import Box from '@mui/material/Box';

import { Loading, PalletScanTextField } from 'components/shared';
import {
  BACK_BUTTON,
  INVENTORY_MANAGER_ACTIONS,
  QR_CODE_WITH_TEXT,
} from 'lib/constants';

import { columns, options } from './dataTableConfig';

const InvestigationArea = ({
  missingPallets,
  onResetMissingPallets,
  storageArea,
  fetching,
  onSetTopBarContent,
  onFetchPallets,
  match,
  history,
  scrollToPreviousPosition,
  tableState,
}) => {
  useEffect(() => {
    onFetchPallets(match.params.id);
    return onResetMissingPallets;
  }, [onFetchPallets, match]);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      text: storageArea ? storageArea.name : '',
      middleContent: QR_CODE_WITH_TEXT,
      rightContent: INVENTORY_MANAGER_ACTIONS,
    });
  }, [onSetTopBarContent, storageArea]);

  useEffect(() => {
    missingPallets.length && scrollToPreviousPosition();
  }, [missingPallets, scrollToPreviousPosition]);

  const processPalletScan = (palletId) => {
    history.push(`/inventory-manager/pallets/${palletId}`);
  };

  if (fetching) {
    return <Loading />;
  }

  return (
    <>
      <MUIDataTable
        title={`Unlocatable Pallets in ${storageArea?.name}`}
        data={missingPallets}
        columns={columns()}
        options={options(history, tableState)}
      />
      <Box
        id="hidden-scanner-input-for-investigation-area"
        sx={{ opacity: 0, height: 0, padding: 0 }}
      >
        <PalletScanTextField canScan onScan={processPalletScan} />
      </Box>
    </>
  );
};

InvestigationArea.propTypes = {
  missingPallets: PropTypes.array.isRequired,
  storageArea: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  onFetchPallets: PropTypes.func.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

InvestigationArea.defaultProps = {
  storageArea: {},
};

export default InvestigationArea;
