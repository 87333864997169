import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import withStyles from '@mui/styles/withStyles';
import isEmpty from 'lodash/isEmpty';

import { StatefulButton } from 'components/shared';
import { WAREHOUSE_HOLD_OPTIONS } from 'lib/constants';

import styles from './styles';

const PalletDetailsHoldButton = ({
  'data-testid': dataTestId,
  holding,
  holdFailed,
  onPalletHold,
  palletId,
  classes,
}) => {
  const holdButtonText = {
    DEFAULT: 'Set On Hold',
    SUCCESS: 'Success!',
    LOADING: 'Saving',
    ERROR: 'Failed',
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedHoldOption, setSelectedHoldOption] = useState({});

  const handleDialogClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setSelectedHoldOption({});
    }, 300);
  };

  const setHoldOption = (holdType) => {
    const holdOption = WAREHOUSE_HOLD_OPTIONS.find(
      (opt) => opt.type === holdType
    );
    setSelectedHoldOption(holdOption);
  };

  const requestPalletHold = (holdType, holdReason) => {
    handleDialogClose();
    onPalletHold(palletId, holdType, holdReason);
  };

  return (
    <Fragment>
      <StatefulButton
        type="button"
        onClick={handleDialogClick}
        buttonTextOptions={holdButtonText}
        loading={holding}
        failed={holdFailed}
        classes={{ root: classes.button }}
      />
      <Dialog
        data-testid={dataTestId}
        open={dialogOpen}
        onClose={handleDialogClose}
        classes={{ paperScrollPaper: classes.muiPaper }}
      >
        {Object.keys(selectedHoldOption).length === 0 && (
          <Fragment>
            <DialogContent classes={{ root: classes.promptContainer }}>
              <DialogContentText classes={{ root: classes.promptText }}>
                Select reason for hold:
              </DialogContentText>
            </DialogContent>
            <DialogContent classes={{ root: classes.holdOptionsContainer }}>
              {WAREHOUSE_HOLD_OPTIONS.map((option) => (
                <Button
                  key={option.type}
                  classes={{ root: classes.modalButtons }}
                  onClick={() => setHoldOption(option.type)}
                  color="neutral"
                >
                  {option.type}
                </Button>
              ))}
            </DialogContent>
          </Fragment>
        )}
        {!isEmpty(selectedHoldOption) && (
          <Fragment>
            <DialogContent classes={{ root: classes.promptContainer }}>
              <DialogContentText classes={{ root: classes.promptText }}>
                Select reason:
              </DialogContentText>
            </DialogContent>
            <DialogContent classes={{ root: classes.holdOptionsContainer }}>
              {selectedHoldOption.reasons.map((reason) => (
                <Button
                  key={reason}
                  classes={{ root: classes.modalButtons }}
                  onClick={() =>
                    requestPalletHold(selectedHoldOption.type, reason)
                  }
                >
                  {reason}
                </Button>
              ))}
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
};

PalletDetailsHoldButton.propTypes = {
  'data-testid': PropTypes.string,
  classes: PropTypes.object.isRequired,
  palletId: PropTypes.number.isRequired,
  holding: PropTypes.bool.isRequired,
  holdFailed: PropTypes.bool.isRequired,
  onPalletHold: PropTypes.func.isRequired,
};

PalletDetailsHoldButton.defaultProps = {
  'data-testid': 'pallet-details-hold',
};

export default withStyles(styles)(PalletDetailsHoldButton);
