import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import useMediaQuery from '@mui/material/useMediaQuery';
import uniq from 'lodash/uniq';
import { format } from 'date-fns';

import {
  AUDIT,
  AUDITED,
  APPROVED,
  CRITICAL_DEFECT_MAPPING,
  TABLET_SCREEN_OR_SMALLER,
} from 'lib/constants';
import { TruckIcon } from 'components/icons';

import PalletAuditLine from '../PalletAuditLine';
import { palletShape } from '../PalletAuditLine/PalletAuditLine';

const TruckLine = ({
  classes,
  truck,
  pallets,
  netsuiteItemId,
  netsuiteItemUOM,
  whichTab,
}) => {
  const tabletScreenOrSmaller = useMediaQuery(TABLET_SCREEN_OR_SMALLER);

  const dockOutTime = truck.dockOutTime
    ? format(new Date(truck.dockOutTime), 'HH:mm')
    : 'Not Set';
  const criticalDefects = truck.criticalDefects
    ? CRITICAL_DEFECT_MAPPING[truck.criticalDefects]
    : 'Not Set';

  const isAuditTab = whichTab === AUDIT;
  const isTruckApproved =
    (isAuditTab && truck.itemReceiptStatuses[netsuiteItemId] === AUDITED) ||
    truck.itemReceiptStatuses[netsuiteItemId] === APPROVED ||
    (!isAuditTab && truck.itemReceiptStatuses[netsuiteItemId]);
  const truckNeedsSummary =
    isAuditTab && !truck.itemReceiptStatuses[netsuiteItemId];

  return (
    <Fragment>
      {isAuditTab ? (
        <div className={classes.truckLine}>
          <span className={classes.truckInfo}>
            <TruckIcon className={classes.truck} />
            <div className={classes.truckText} data-testid="truck-text">
              <span className={classes.truckId}>{`Truck T${truck.id}`}</span>
              <span className={classes.separator}>|</span>
              <span>{truck.vendorsArray.join(', ')}</span>
            </div>
            <div className={classes.truckDetail} data-testid="dock-out">
              <span className={classes.label}>Dock Out:</span>
              <span className={classes.value}>{dockOutTime}</span>
            </div>
            <div className={classes.truckDetail} data-testid="memo">
              <span className={classes.label}>Memo:</span>
              <span className={classes.value}>{truck.memo || 'Not Set'}</span>
            </div>
            <div className={classes.truckDetail} data-testid="cd">
              <span className={classes.label}>CD:</span>
              <span className={classes.value}>{criticalDefects}</span>
            </div>
          </span>
          <span className={classes.approvedContainer}>
            {isTruckApproved && (
              <Chip
                data-testid="approved-chip"
                icon={<DoneIcon className={classes.approvedIcon} />}
                label="APPROVED"
                classes={{ root: classes.approvedChip }}
              />
            )}
            {truckNeedsSummary && (
              <Chip
                data-testid="needs-summary-chip"
                label="NEEDS SUMMARY"
                classes={{ root: classes.needsSummaryChip }}
              />
            )}
          </span>
        </div>
      ) : null}
      <div className={classes.palletHeader} data-testid="truck-line-item">
        <span className={classes.palletSpanOne}>Lot #</span>
        <span className={classes.palletSpanTwo}>Date</span>
        <span className={classes.palletSpanOne}>UOM</span>
        <span className={classes.palletSpanTwo}>PDC</span>
        <span className={classes.palletSpanOne}>
          {tabletScreenOrSmaller ? 'Rej.' : 'Rejected'}
        </span>
        <span className={classes.palletSpanOne}>
          {tabletScreenOrSmaller ? 'Rcv`d' : 'Received'}
        </span>
        <span className={classes.palletSpanOne}>Receiver</span>
      </div>
      {uniq(pallets).map((pallet, index) => (
        <PalletAuditLine
          key={pallet.id}
          pallet={pallet}
          netsuiteItemUOM={netsuiteItemUOM}
          grey={index % 2 === 1}
        />
      ))}
    </Fragment>
  );
};

export const truckShape = PropTypes.shape({
  vendor: PropTypes.string.isRequired,
  dockOutTime: PropTypes.string,
  memo: PropTypes.string,
  id: PropTypes.number.isRequired,
  pallets: PropTypes.arrayOf(palletShape),
  itemReceiptStatuses: PropTypes.object.isRequired,
});

TruckLine.propTypes = {
  classes: PropTypes.object.isRequired,
  truck: truckShape.isRequired,
  pallets: PropTypes.arrayOf(palletShape).isRequired,
  netsuiteItemId: PropTypes.number.isRequired,
  netsuiteItemUOM: PropTypes.string.isRequired,
  whichTab: PropTypes.string.isRequired,
};

export default TruckLine;
