import {
  HC_GREEN,
  NEGATIVE_RED,
  LIGHT_BLUE,
  GREY_200,
  GREY_500,
  GREY_650,
  GREY_700,
} from 'lib/constants';
import theme from 'lib/theme';

const detailedOverviewRowStyles = {
  padding: '10px 0',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '50px',
};

const cardStyles = {
  [theme.breakpoints.down('lg')]: {
    width: '95vw',
  },
  [theme.breakpoints.up('lg')]: {
    width: '800px',
  },
  padding: '10px',
  marginBottom: '10px',
};

export default {
  root: {
    fontFamily: 'Muli',
  },
  subheading: {
    fontWeight: '500',
    fontSize: '25px',
    marginBottom: '20px',
    display: 'inline',
  },
  card: {
    ...cardStyles,
    display: 'flex',
    justifyContent: 'space-around',
  },
  stickyCard: {
    margin: '0',
    boxShadow: 'none',
  },
  detailedOverviewCard: {
    ...cardStyles,
    width: '800px',
    color: GREY_650,
  },
  detailedOverviewHeaderRow: {
    ...detailedOverviewRowStyles,
    backgroundColor: GREY_200,
    textTransform: 'uppercase',
    fontWeight: 'bolder',
  },
  oddCell: {
    ...detailedOverviewRowStyles,
    overflow: 'hidden',
    background: LIGHT_BLUE,
  },

  evenCell: {
    ...detailedOverviewRowStyles,
    overflow: 'hidden',
  },
  labelOrderCell: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: '20px',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
  routerLink: {
    maxWidth: '250px',
    position: 'relative',
    display: 'block',
  },
  cardTitle: {
    marginBottom: '1rem',
    fontSize: '20px',
    color: GREY_700,
    width: '150px',
    textTransform: 'uppercase',
  },
  overviewDetails: {
    marginBottom: '11px',
    width: '200px',
  },
  activityOverviewSnippet: {
    borderLeft: '3px solid',
    borderColor: GREY_500,
    paddingLeft: '8px',
    display: 'inline-block',
  },
  forwardArrow: {
    marginRight: '10px',
  },
  onTarget: {
    color: GREY_500,
    marginLeft: '20px',
  },
  belowTarget: {
    color: NEGATIVE_RED,
    marginLeft: '20px',
  },
  aheadOfTarget: {
    color: HC_GREEN,
    marginLeft: '20px',
  },
  loadingContainer: {
    padding: '50px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  completedCount: {
    display: 'inline-block',
    marginRight: '8px',
  },
  goalCount: {
    display: 'inline-block',
    width: '60px',
  },
  disabledLink: {
    pointerEvents: 'none',
  },
};
