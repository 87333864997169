import React from 'react';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const IndexNavLinks = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.navLinksContainer}>
      <Grid item xs={6} className={classes.navLinkContainer}>
        <NavLink
          to="/cycle-counting"
          className={classes.link}
          activeClassName={classes.activeLink}
          exact
        >
          Count Lists
        </NavLink>
      </Grid>
      <Grid item xs={6} className={classes.navLinkContainer}>
        <NavLink
          to="/cycle-counting/new"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          Create A List
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default IndexNavLinks;
