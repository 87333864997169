import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useHistory } from 'react-router-dom';

import { getFirstAndLastInitials, pluralize } from 'lib/utils';
import { Loading, PalletScanTextField } from 'components/shared';
import { QRCodeIcon } from 'components/icons';

import QuantityToZoneDisplay from '../QuantityToZoneDisplay';

const FollowUp = ({
  classes,
  itemMoveRequest,
  followUp,
  signedInUser,
  palletSearchDrawerOpen,
}) => {
  const history = useHistory();

  const pickedUpPortions =
    itemMoveRequest?.quantityRequested - followUp?.quantityRequested;

  const processPalletScan = (palletId) => {
    history.push(`/warehouse_moves/requests/${followUp.id}/pallet/${palletId}`);
  };

  return (
    <Fragment>
      {itemMoveRequest && followUp ? (
        <Fragment>
          <PalletScanTextField
            canScan={!palletSearchDrawerOpen}
            onScan={processPalletScan}
          />
          <div className={classes.centered}>
            <CheckCircleOutlineIcon
              className={classes.circleIcon}
              style={{ fontSize: 50 }}
            />
            <div className={classes.lightText}>
              {pluralize(pickedUpPortions, itemMoveRequest.quantityType)} picked
              up
            </div>
          </div>
          <Card className={classes.card}>
            <div className={classes.header}>Request Incomplete</div>
            <CardContent className={classes.cardContent}>
              <div className={classes.item}>{followUp.name}</div>
              <div className={classes.secondLine}>
                <QuantityToZoneDisplay itemMoveRequest={itemMoveRequest} />
                <div className={classes.rightSide}>
                  <Chip
                    className={classNames(classes.chip, {
                      [classes.myChip]: followUp.runner === signedInUser,
                    })}
                    label={getFirstAndLastInitials(followUp.runner)}
                  />
                </div>
              </div>
              <hr className={classes.line} />
              <QRCodeIcon className={classes.qrIcon} />
              <div className={classes.scanText}>Scan pallet to pick up now</div>
            </CardContent>
          </Card>
          <Button
            variant="outlined"
            href="/warehouse_moves/in_progress"
            className={classes.laterButton}
          >
            Come back later
          </Button>
        </Fragment>
      ) : (
        <div className={classes.centered}>
          <Loading />
        </div>
      )}
    </Fragment>
  );
};

FollowUp.propTypes = {
  classes: PropTypes.object.isRequired,
  itemMoveRequest: PropTypes.object,
  followUp: PropTypes.object,
  signedInUser: PropTypes.string.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
};

FollowUp.defaultProps = {
  itemMoveRequest: null,
  followUp: null,
};

export default FollowUp;
