import React from 'react';
import PropTypes from 'prop-types';

import { AUDIT } from 'lib/constants';

import NetsuiteOrderLine from '../NetsuiteOrderLine';
import { netsuiteOrderShape } from '../NetsuiteOrderLine/NetsuiteOrderLine';

const WarehouseAudit = ({ classes, netsuiteOrders, onUpdateNetsuiteItem }) => {
  return (
    <div className={classes.auditContainer} data-testid="warehouse-audit">
      {netsuiteOrders.map((netsuiteOrder) => (
        <NetsuiteOrderLine
          key={netsuiteOrder.id}
          netsuiteOrder={netsuiteOrder}
          onUpdateNetsuiteItem={onUpdateNetsuiteItem}
          whichTab={AUDIT}
        />
      ))}
    </div>
  );
};

WarehouseAudit.propTypes = {
  classes: PropTypes.object.isRequired,
  netsuiteOrders: PropTypes.arrayOf(netsuiteOrderShape).isRequired,
  onUpdateNetsuiteItem: PropTypes.func.isRequired,
};

export default WarehouseAudit;
