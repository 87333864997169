import reject from 'lodash/reject';
import filter from 'lodash/filter';

export const UPDATE_PROBLEMATIC_INGREDIENT_IDS =
  'UPDATE_PROBLEMATIC_INGREDIENT_IDS';
export const UPDATE_PROBLEMATIC_INGREDIENT_ERRORS =
  'UPDATE_PROBLEMATIC_INGREDIENT_ERRORS';

export const NO_ISSUES = 'No Issues';

export const initialState = {
  problematicIngredients: [],
  problematicIngredientErrors: [],
};

// NOTES: The shape of problematicIngredientErrors is like so:
// [
//   { ingredientId: 123, errors: ['Too Much', 'Packaging'] },
//   { ingredientId: 234, errors: ['Missing'] },
// ];
// This would mean that there are 3 ingredient errors altogether.

const sanitizeProblematicIngredients = (problematicIngredients, value) => {
  if (
    value.includes(NO_ISSUES) &&
    !problematicIngredients.includes(NO_ISSUES)
  ) {
    return [NO_ISSUES];
  } else {
    return value.filter((val) => val !== NO_ISSUES);
  }
};

const updateIngredientErrors = (
  problematicIngredientErrors,
  selectedValues
) => {
  return selectedValues.includes(NO_ISSUES)
    ? []
    : filter(problematicIngredientErrors, (err) =>
        selectedValues.includes(err.ingredientId)
      );
};

const pushIngredientErrors = (
  problematicIngredientErrors,
  ingredientId,
  value
) => {
  const filteredIngredientErrors = reject(problematicIngredientErrors, {
    ingredientId: ingredientId,
  });

  return filteredIngredientErrors.concat({
    ingredientId,
    errors: value,
  });
};

export const reducer = (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case UPDATE_PROBLEMATIC_INGREDIENT_IDS:
      return {
        ...state,
        problematicIngredients: sanitizeProblematicIngredients(
          state.problematicIngredients,
          action.value
        ),
        problematicIngredientErrors: updateIngredientErrors(
          state.problematicIngredientErrors,
          action.value
        ),
      };

    case UPDATE_PROBLEMATIC_INGREDIENT_ERRORS:
      return {
        ...state,
        problematicIngredientErrors: pushIngredientErrors(
          state.problematicIngredientErrors,
          action.ingredientId,
          action.value
        ),
      };

    default:
      return state;
  }
};
