import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import times from 'lodash/times';

import buttonGroupTheme from 'lib/buttonGroupTheme';

import styles from './styles';

const MAX_PEOPLE_PER_POD = 11;
const NumberEmployeesQuestions = ({ setPeopleCount, classes }) => {
  const [btnSelectedValue, setBtnSelectedValue] = useState(0);
  return (
    <ThemeProvider theme={buttonGroupTheme}>
      <h3>How many employees packed this inventory?</h3>
      <ToggleButtonGroup
        exclusive
        className={classes.buttonGroup}
        size="large"
        value={btnSelectedValue}
        onChange={(_, selectedValue) => setBtnSelectedValue(selectedValue)}
      >
        {times(MAX_PEOPLE_PER_POD, (i) => {
          const num = i + 1;

          return (
            <ToggleButton
              className={classes.button}
              id={`employeeNum-${num}`}
              data-testid={`employee-num-${num}`}
              key={num}
              value={num}
              onClick={() => setPeopleCount(num)}
            >
              {num}
            </ToggleButton>
          );
        })}
        <ToggleButton
          className={classes.button}
          id="employeeNum-12+"
          data-testid="employee-num-12+"
          key="12+"
          value="12+"
        >
          12+
        </ToggleButton>
      </ToggleButtonGroup>
      {btnSelectedValue === '12+' && (
        <Box>
          <h3>Enter number of employees (max: 50)</h3>
          <TextField
            required
            data-testid="outlined-required"
            label="Required"
            type="number"
            onChange={(event) => setPeopleCount(event.target.value)}
          />
        </Box>
      )}
    </ThemeProvider>
  );
};

NumberEmployeesQuestions.propTypes = {
  setPeopleCount: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NumberEmployeesQuestions);
