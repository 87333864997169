import { LOGIN_FIELDS, SEARCH_USERS, CLEAR_SEARCH_USERS } from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import Login from './services';

export const getLoginFields = () => (dispatch) => {
  dispatch(
    makeApiRequest({
      type: LOGIN_FIELDS,
      method: Login.getLoginFields,
    })
  );
};

export const searchUsers = (query) => (dispatch) => {
  dispatch(
    makeApiRequest({
      type: SEARCH_USERS,
      method: Login.searchUsers,
      params: {
        query,
      },
    })
  );
};

export const clearUserSearchResults = () => ({
  type: CLEAR_SEARCH_USERS,
});
