import {
  GREY_200,
  DARK_HC_GREEN,
  BLACK,
  WHITE,
  MEDIA_QUERY_PHONE_SCREEN,
  HC_GREEN,
} from 'lib/constants';

export default {
  heading: {
    flexGrow: '1',
    fontWeight: 'normal',
    margin: '10px 20px',
  },
  toggleButtonGroup: {
    margin: '0 20px 20px',
    minWidth: '100px',
    width: 'calc(100% - 40px)',
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))',
  },
  toggleButton: {
    borderRadius: '4px',
    border: `1px solid ${GREY_200}`,
    color: BLACK,
    width: '100%',
    height: 'auto',
    padding: '5px 0px',
    '&:not(:first-child)': {
      border: `1px solid ${GREY_200}`,
    },
    '&:last-child': {
      padding: '5px 0px',
    },
    '&:hover.Mui-selected': {
      backgroundColor: DARK_HC_GREEN,
      color: WHITE,
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
    },
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    heading: {
      fontSize: '18px',
    },
    toggleButton: {
      fontSize: '12px',
    },
    toggleButtonGroup: {
      marginBottom: '0px',
    },
  },
};
