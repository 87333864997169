import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import { isStaleSubmission } from 'lib/viewHelpers';
import { formatNumber, minuteDiffFromNow } from 'lib/utils';

import styles from '../styles';

const TotalQaCard = ({
  classes,
  lastQaCheckSubmittedAt,
  totalQaCheckCount,
}) => {
  const [minutesSubmittedAgo, setMinutesSubmittedAgo] = useState(
    minuteDiffFromNow(lastQaCheckSubmittedAt)
  );

  const [isSubmissionStale, setIsStaleSubmission] = useState(true);

  useEffect(() => {
    let timer = setInterval(
      () => setMinutesSubmittedAgo(minuteDiffFromNow(lastQaCheckSubmittedAt)),
      60000
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setMinutesSubmittedAgo(minuteDiffFromNow(lastQaCheckSubmittedAt));
  }, [lastQaCheckSubmittedAt]);

  useEffect(() => {
    setIsStaleSubmission(isStaleSubmission(minutesSubmittedAgo));
  }, [minutesSubmittedAgo]);

  const message = () => {
    if (lastQaCheckSubmittedAt !== null) {
      return `Last submitted: ${minutesSubmittedAgo} min`;
    } else {
      return 'No submissions at this time';
    }
  };

  return (
    <Card className={classes.totalQaCard}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <h3 className={classes.cardHeading}>Total QA Checks:</h3>
          </Grid>
          <Grid item xs={4} className={classes.informationalCardNumber}>
            <h3 className={classes.cardHeading}>
              {formatNumber(totalQaCheckCount)}
            </h3>
          </Grid>
        </Grid>
        <div
          className={classNames(classes.cardLastSubmitted, {
            [classes.staleSubmissionColor]: isSubmissionStale,
            [classes.freshSubmissionColor]: !isSubmissionStale,
          })}
        >
          {message()}
        </div>
      </React.Fragment>
    </Card>
  );
};

TotalQaCard.propTypes = {
  classes: PropTypes.object.isRequired,
  lastQaCheckSubmittedAt: PropTypes.string,
  totalQaCheckCount: PropTypes.number,
};

TotalQaCard.defaultProps = {
  lastQaCheckSubmittedAt: undefined,
  totalQaCheckCount: 0,
};

export default withStyles(styles)(TotalQaCard);
