import { alpha } from '@mui/material/styles';

import {
  ERROR_RED,
  GREY_600,
  HC_GREEN,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

export default {
  dangerMessage: {
    display: 'flex',
    backgroundColor: '#feebeb',
    padding: '12px',
    border: `1px solid ${ERROR_RED}`,
    borderRadius: '4px',
    margin: '-8px -8px 0px -8px',
  },
  dangerIcon: {
    color: ERROR_RED,
    fontSize: '54px',
  },
  dangerHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  lightFont: {
    color: GREY_600,
  },
  boldFont: {
    fontWeight: 'bold',
  },
  palletComparison: {
    paddingTop: '4px',
  },
  optionButton: {
    width: '48%',
    margin: '24px 5px 0px 5px',
    '&.selected': {
      border: `solid 1px ${HC_GREEN}`,
      backgroundColor: alpha(HC_GREEN, 0.1),
    },
  },
  supervisorName: {
    margin: '20px 0px 0px 5px',
  },
  divider: {
    margin: '24px 0px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    lightFont: {
      fontSize: '13px',
    },
    boldFont: {
      fontSize: '13px',
    },
    dangerInfo: {
      fontSize: '13px',
    },
    optionButton: {
      width: '46%',
    },
  },
};
