import findIndex from 'lodash/findIndex';
import groupBy from 'lodash/groupBy';

import { groupPallets, groupAllergens } from 'lib/redux_utils';
import {
  FSQA_PALLETS_GET_REQUEST,
  FSQA_PALLETS_GET_SUCCESS,
  FSQA_PALLETS_GET_ERROR,
  FSQA_SEARCH_PALLET,
  FSQA_RESET_SEARCH_PALLET,
  FSQA_REVIEW_POST_SUCCESS,
  FSQA_ADD_PALLETS,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  pallets: [],
  allAllergens: [],
  scannedPalletId: null,
  groupedIngredientsPallets: [],
  groupedPackagingPallets: [],
  groupedAllergens: [],
};

const updateFinalizedPallet = (pallets, palletId, updatedPalletQuantity) => {
  const newPallets = pallets.slice();
  const index = findIndex(pallets, { id: palletId });
  newPallets[index] = {
    ...newPallets[index],
    status: 'Completed',
    quantity: updatedPalletQuantity,
  };

  return newPallets;
};

const updatePallets = (allPallets, newPallets) => {
  return allPallets.map((pallet) => {
    const foundPallet = newPallets.find((newPallet) => {
      return newPallet.id == pallet.id;
    });
    if (foundPallet) {
      return {
        ...pallet,
        status: foundPallet.status,
        fullCheck: foundPallet.fullCheck,
        fsqaReviewUpdatedAt: foundPallet.fsqaReviewUpdatedAt,
        lotCodes: foundPallet.lotCodes,
      };
    } else {
      return pallet;
    }
  });
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case FSQA_PALLETS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        pallets: [],
      };
    case FSQA_PALLETS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        // keeping info about all ungrouped pallets for easier processing when pallets are updated
        pallets: [
          ...action.response.ingredientsPallets,
          ...action.response.packagingPallets,
        ],
        groupedIngredientsPallets: groupPallets(
          action.response.ingredientsPallets
        ),
        groupedPackagingPallets: groupPallets(action.response.packagingPallets),
        allAllergens: action.response.allAllergens,
        groupedAllergens: groupAllergens(action.response.allAllergens),
      };
    case FSQA_PALLETS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case FSQA_SEARCH_PALLET:
    case FSQA_RESET_SEARCH_PALLET:
      return {
        ...state,
        scannedPalletId: action.palletId,
      };
    case FSQA_REVIEW_POST_SUCCESS:
      return {
        ...state,
        pallets: updateFinalizedPallet(
          state.pallets,
          action.params.palletId,
          action.response.updatedPalletQuantity
        ),
      };
    case FSQA_ADD_PALLETS: {
      const updatedPallets = updatePallets(state.pallets, action.pallets);
      const palletsByType = groupBy(updatedPallets, 'orderableType');
      return {
        ...state,
        pallets: updatedPallets,
        groupedIngredientsPallets: groupPallets(palletsByType.Ingredient),
        groupedPackagingPallets: groupPallets(palletsByType.PackagingItem),
      };
    }
    default:
      return state;
  }
}
