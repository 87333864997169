import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  subscribeToPodDetails,
  unsubscribeToPodDetails,
} from 'redux/podDetail/channels';
import { requestPodStatus } from 'redux/podStatus/actions';
import { Loading } from 'components/shared';
import { minuteDiffFromNow } from 'lib/utils';

import PodDetails from './PodDetails';
import styles from './styles';

const STALE_POD_MINUTE_INTERVAL = 180;

export const PodDetailsContainer = ({
  onRequestPodStatus,
  onSubscribeToPodDetails,
  session,
  fetching,
  podStatuses,
  classes,
  fetchFailed,
  match,
}) => {
  const facilityId = session.packing_facility_id;
  useEffect(() => {
    onRequestPodStatus({ facilityId, podSlug: match.params.slug });
  }, [session, onRequestPodStatus, onSubscribeToPodDetails, match, facilityId]);

  useEffect(() => {
    if (podStatuses.selectedPod !== undefined) {
      onSubscribeToPodDetails(podStatuses.selectedPod.id);
      return () => {
        unsubscribeToPodDetails(podStatuses.selectedPod.id);
      };
    }
  }, [onSubscribeToPodDetails, podStatuses.selectedPod]);

  const getStaleSubmissions = () => {
    return podStatuses.selectedPod.activities.filter(
      (activity) =>
        minuteDiffFromNow(activity.lastProductionSubmittedAt) >
        STALE_POD_MINUTE_INTERVAL
    );
  };

  useEffect(() => {
    let timer = setInterval(() => {
      const staleSubmissions = getStaleSubmissions();
      if (staleSubmissions.length > 0) {
        onRequestPodStatus({
          facilityId,
          podSlug: match.params.slug,
        });
      }
    }, 60000);
    return () => clearInterval(timer);
  });

  if (fetching) {
    return <Loading />;
  } else if (
    fetchFailed ||
    podStatuses.selectedPod.activities === undefined ||
    podStatuses.selectedPod.activities.length === 0
  ) {
    return <div>No Pod Activity Found</div>;
  } else {
    return (
      <PodDetails
        fetchFailed={fetchFailed}
        classes={classes}
        pod={podStatuses.selectedPod}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  session: state.session,
  fetching: state.podStatuses.fetching,
  fetchFailed: state.podStatuses.fetchFailed,
  statusText: state.podStatuses.statusText,
  podStatuses: state.podStatuses,
});

const mapDispatchToProps = {
  onRequestPodStatus: requestPodStatus,
  onSubscribeToPodDetails: subscribeToPodDetails,
};

PodDetailsContainer.propTypes = {
  session: PropTypes.shape({
    packing_facility_id: PropTypes.number.isRequired,
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  podStatuses: PropTypes.object.isRequired,
  onSubscribeToPodDetails: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PodDetailsContainer));
