import { combineReducers } from 'redux';

import receiving from './receiving/reducers';
import summary from './summary/reducers';
import audit from './audit/reducers';

export default combineReducers({
  receiving,
  summary,
  audit,
});
