import {
  MEDIA_QUERY_PHONE_SCREEN,
  MEDIA_QUERY_TABLET,
  GREY_200,
} from 'lib/constants';

export default {
  root: {
    display: 'flex',
    minHeight: '75vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5em 1em',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  kraken: {
    alignSelf: 'center',
    height: '160px',
    width: '160px',
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  locationText: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
  },
  deleteForUnknown: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
    alignItems: 'center',
  },
  orLine: {
    display: 'flex',
    order: '1',
    justifyContent: 'center',
    padding: '0px 150px',
    color: GREY_200,
    opacity: '40%',
    overflow: 'hidden',
  },
  orText: {
    display: 'flex',
    order: '2',
    justifyContent: 'center',
    padding: '1em',
  },
  orLine2: {
    display: 'flex',
    order: '3',
    justifyContent: 'center',
    padding: '0px 150px',
    color: GREY_200,
    opacity: '40%',
    overflow: 'hidden',
  },
  deleteButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      width: '500px',
    },
  },
  [MEDIA_QUERY_TABLET]: {
    kraken: {
      height: '400px',
      width: '400px',
    },
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    kraken: {
      display: 'none',
    },
  },
};
