import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import buttonGroupTheme from 'lib/buttonGroupTheme';
import { StatefulButton, QtyTagsQuestion } from 'components/shared';
import { CloseIcon } from 'components/icons';

import PrinterQuestion from './PrinterQuestion';
import styles from './styles';

const ConfirmationDialog = ({
  open,
  handleClose,
  activity,
  mealName,
  ingredientName,
  packagingType,
  submissionData,
  posting,
  onSubmit,
  printers,
  classes,
}) => {
  const [qtyTags, setQtyTags] = useState(0);
  const [printerId, setPrinterId] = useState(undefined);
  const formIsComplete = (qtyTags && printerId) || (qtyTags == 0 && !printerId);
  const submissionDataAndPrintData = () => {
    if (printerId) {
      return {
        submission: { ...submissionData },
        printer: {
          id: printerId,
          qty: qtyTags,
        },
      };
    } else {
      return { submission: submissionData };
    }
  };

  useEffect(() => {
    if (qtyTags === 0 || !qtyTags) {
      setPrinterId(undefined);
    }
  }, [qtyTags, setPrinterId]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Button classes={{ root: classes.dialogClose }} onClick={handleClose}>
        <CloseIcon className={classes.closeIcon} />
      </Button>
      <DialogTitle
        id="alert-dialog-title"
        classes={{ root: classes.dialogTitle }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {activity} Submission
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.content}>
          <p>Meal:</p>
          <p className={classes.submissionDetails}>{mealName}</p>
          {ingredientName ? (
            <Fragment>
              <p>Ingredient:</p>
              {packagingType ? (
                <p className={classes.submissionDetails}>
                  {packagingType}, {ingredientName}
                </p>
              ) : (
                <p className={classes.submissionDetails}>{ingredientName}</p>
              )}
            </Fragment>
          ) : null}
          <p>Quantity:</p>
          <p className={classes.submissionDetails}>{submissionData.count}</p>

          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={buttonGroupTheme}>
              <QtyTagsQuestion
                sequenceStart={0}
                sequenceEnd={11}
                qtyTags={qtyTags}
                setQtyTags={setQtyTags}
                visible={submissionData.count > 0}
              />
              <PrinterQuestion
                printers={printers}
                selectedPrinter={printerId}
                setSelectedPrinter={setPrinterId}
                visible={qtyTags > 0 && printers.length > 0}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.cancelButton}
            color="primary"
          >
            Cancel
          </Button>
          <StatefulButton
            disabled={!formIsComplete}
            loading={posting}
            buttonTextOptions={{
              LOADING: 'Posting...',
              SUCCESS: 'Success',
              ERROR: `There was an error submitting ${activity}`,
              DEFAULT: 'Submit',
            }}
            type="button"
            onClick={() => onSubmit(submissionDataAndPrintData())}
            classes={{ root: classes.submitButton }}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  activity: PropTypes.string.isRequired,
  mealName: PropTypes.string.isRequired,
  ingredientName: PropTypes.string,
  packagingType: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  submissionData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  classes: PropTypes.object.isRequired,
  posting: PropTypes.bool.isRequired,
};

ConfirmationDialog.defaultProps = {
  printers: [],
  ingredientName: undefined,
  packagingType: undefined,
};

export default withStyles(styles)(ConfirmationDialog);
