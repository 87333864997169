import { BLACK } from 'lib/constants';

export default {
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px',
  },
  titleBox: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  titleEmoji: {
    fontSize: '40px',
    paddingRight: '10px',
  },
  list: {
    paddingLeft: 0,
  },
  listItemLink: {
    padding: '15px 15px',
    color: BLACK,
  },
};
