import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  selectPalletsByTrucksAndItem,
  selectTrucksByNetsuiteOrderItemId,
} from 'redux/truck/selectors';
import { handleItemToggle as handleAuditToggle } from 'redux/netsuiteOrders/audit/actions';
import {
  createItemReceipt,
  updateItemReceipt,
} from 'redux/itemReceipt/actions';
import { AUDIT } from 'lib/constants';

import styles from './styles';
import NetsuiteItemLine from './NetsuiteItemLine';

export const mapStateToProps = (state, ownProps) => {
  const isAudit = ownProps.whichTab === AUDIT;
  const auditTrucks = selectTrucksByNetsuiteOrderItemId(
    state,
    ownProps.netsuiteItem.id,
    isAudit
  );

  return {
    expanded: get(
      state.netsuiteOrders,
      [
        ownProps.whichTab,
        'expandedState',
        ownProps.netsuiteOrderId,
        'netsuiteItems',
        ownProps.netsuiteItem.id,
      ],
      false
    ),
    pallets: selectPalletsByTrucksAndItem(
      state,
      isAudit
        ? auditTrucks.map((auditTruck) => auditTruck.id)
        : [ownProps.summaryTruck.id],
      ownProps.netsuiteItem.id,
      isAudit
    ),
    auditTrucks,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  onItemToggle: (orderId, itemId) =>
    dispatch(handleAuditToggle(orderId, itemId)),
  onApproveClick:
    ownProps.whichTab === AUDIT
      ? (itemId) => dispatch(updateItemReceipt(itemId))
      : (itemAndTruckId) => dispatch(createItemReceipt(itemAndTruckId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NetsuiteItemLine));
