import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { formatNumber, formatPercentage } from 'lib/utils';

import styles from './styles';

class IngredientRow extends Component {
  static dataCellDisplay(ingredient, customData) {
    return customData.dataType === 'percent'
      ? formatPercentage(ingredient[customData.dataKey])
      : formatNumber(ingredient[customData.dataKey]);
  }

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    // only need to bind class functions that are called via an even callback
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  dataCellClass(modifier) {
    const { classes } = this.props;
    return classes[`dataCell${modifier || ''}`];
  }

  itemClass(alignment) {
    const { classes } = this.props;
    let className;

    switch (alignment) {
      case 'left':
        className = classes.gridItemAlignLeft;
        break;
      case 'center':
        className = classes.gridItemAlignCenter;
        break;
      case 'right':
        className = classes.gridItemAlignRight;
        break;
      default:
        className = classes.gridItemAlignLeft;
    }

    return { item: className };
  }

  progressColor(percentProgress, percentTarget) {
    if (percentProgress === null) return;
    const className = this.props.classes;
    if (percentProgress >= 99.5 || percentProgress - percentTarget > 5) {
      return className.aheadOfTarget;
    } else if (percentTarget - percentProgress > 5) {
      return className.belowTarget;
    } else {
      return className;
    }
  }

  wipTargetClass(wipCount) {
    const className = this.props.classes;
    //if the total completed meal bags is less than total completed portions then turn the font red
    if (wipCount < 0) {
      return [className.belowTarget, this.dataCellClass('MarginRight')].join(
        ' '
      );
    }
    return this.dataCellClass('MarginRight');
  }

  endOfShiftTargetDOM(ingredient, customData, overviewColumn) {
    const current = ingredient[customData.dataKey];
    let target;
    const tab = location.href.substr(location.href.lastIndexOf('/') + 1);

    // @TODO: Refactor to keep tab state in redux store to remove dependency on location.href for
    // UI state -- or tap into react-router's state API...

    // Portioning OR Overview
    if (
      tab === 'portioning' ||
      (overviewColumn === 'portioning' && tab === 'overview')
    ) {
      target = ingredient.end_of_shift_portioning_target;
    }

    // Labeling Or Overview
    if (
      tab === 'labeling' ||
      (overviewColumn === 'labeling' && tab === 'overview')
    ) {
      target = ingredient.end_of_shift_label_target;
    }

    let cssClass = this.dataCellClass('MarginRight');

    // Overview progressOverview color
    if (
      customData.dataKey === 'labeling_percent' ||
      customData.dataKey === 'portioning_percent'
    ) {
      cssClass += ` ${this.progressColor(current, target)}`;
    }

    return (
      <div className={cssClass}>
        {this.constructor.dataCellDisplay(ingredient, customData)}
      </div>
    );
  }

  render() {
    const {
      ingredient,
      customData,
      showWIPColumn,
      classes,
      children,
    } = this.props;

    if (children) {
      // This condition is to render pre_portioned ingredient rows or other non-traditional rows needing zebra striping.
      return (
        <Grid container className={classes.gridContainer} spacing={1}>
          {children}
        </Grid>
      );
    } else {
      const {
        name,
        ready_to_eat,
        quantity,
        unit,
        method,
        notes,
        ingredient_packaging_name,
      } = ingredient;
      const numIngsLeft = ingredient[customData[1].dataKey]
        ? this.constructor.dataCellDisplay(ingredient, customData[1])
        : '-';
      const { anchorEl } = this.state;
      const isOpen = Boolean(anchorEl);

      return (
        <Grid
          container
          className={classes.gridContainer}
          spacing={1}
          data-testid="ingredient-row"
        >
          <Grid
            item
            className="zebra"
            xs={showWIPColumn ? 5 : 7}
            classes={this.itemClass('left')}
          >
            <div>
              <button
                type="button"
                aria-owns={isOpen ? 'simple-popper' : undefined}
                aria-haspopup="true"
                variant="contained"
                className={`${this.dataCellClass('LargerFont')} ${
                  classes.ingredientName
                }`}
                onClick={this.handleClick}
              >
                {name}
              </button>
              <Popover
                id="simple-popper"
                open={isOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Typography className={classes.typography} component="span">
                  <div>
                    <Grid container>
                      <Grid item xs={12}>
                        <strong>{name}</strong>
                      </Grid>
                      <Grid item xs={5}>
                        <strong>Size:</strong>
                      </Grid>
                      <Grid item xs={7}>
                        <span className={classes.popoverIngredientValue}>
                          {quantity} {unit}
                        </span>
                      </Grid>
                      <Grid item xs={5}>
                        <strong>Method:</strong>
                      </Grid>
                      <Grid item xs={7}>
                        <span className={classes.popoverIngredientValue}>
                          {method}
                        </span>
                      </Grid>
                      <Grid item xs={5}>
                        <strong>Packaging:</strong>
                      </Grid>
                      <Grid item xs={7}>
                        <span className={classes.popoverIngredientValue}>
                          {ingredient_packaging_name || 'None'}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                      <Grid item xs={12}>
                        <strong>Notes: </strong>
                        <span className={classes.popoverIngredientValue}>
                          {notes || 'None'}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                </Typography>
              </Popover>
            </div>
          </Grid>
          <Grid
            item
            className="zebra"
            xs={1}
            classes={this.itemClass('center')}
          >
            <div className={this.dataCellClass()}>
              {ready_to_eat ? '•' : null}
            </div>
          </Grid>
          <Grid item className="zebra" xs={1} classes={this.itemClass('right')}>
            {this.endOfShiftTargetDOM(ingredient, customData[0], 'labeling')}
          </Grid>
          <Grid item className="zebra" xs={1} classes={this.itemClass('right')}>
            <div className={this.dataCellClass('MarginRight')}>
              {numIngsLeft}
            </div>
          </Grid>
          {showWIPColumn && (
            <React.Fragment>
              <Grid
                item
                className="zebra"
                xs={1}
                classes={this.itemClass('right')}
              >
                <div
                  className={this.wipTargetClass(
                    ingredient[customData[5].dataKey]
                  )}
                >
                  {this.constructor.dataCellDisplay(ingredient, customData[5])}
                </div>
              </Grid>
              <Grid
                item
                className="zebra"
                xs={1}
                classes={this.itemClass('right')}
              >
                <div
                  className={this.wipTargetClass(
                    ingredient[customData[4].dataKey]
                  )}
                >
                  {this.constructor.dataCellDisplay(ingredient, customData[4])}
                </div>
              </Grid>
            </React.Fragment>
          )}
          <Grid item className="zebra" xs={1} classes={this.itemClass('right')}>
            {this.endOfShiftTargetDOM(ingredient, customData[2], 'portioning')}
          </Grid>
          <Grid item className="zebra" xs={1} classes={this.itemClass('right')}>
            <div className={this.dataCellClass('MarginRight')}>
              {this.constructor.dataCellDisplay(ingredient, customData[3])}
            </div>
          </Grid>
        </Grid>
      );
    }
  }
}

IngredientRow.propTypes = {
  classes: PropTypes.object,
  ingredient: PropTypes.object,
  customData: PropTypes.array,
  showWIPColumn: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

IngredientRow.defaultProps = {
  classes: undefined,
  ingredient: undefined,
  customData: undefined,
  showWIPColumn: undefined,
  children: undefined,
};

export default withStyles(styles)(IngredientRow);
