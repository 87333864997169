import * as Sentry from '@sentry/react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  Link as RouterLink,
} from 'react-router-dom';
import Link from '@mui/material/Link';

import BoxingInventory from 'components/BoxingInventory';
import { ArrowBackIcon } from 'components/icons';
import { GREY_400 } from 'lib/constants';
import { ExpandCollapse, Loading } from 'components/shared';

import PortioningByIngredient from './PortioningByIngredient';
import NavigationBar from './NavigationBar';
import MenuProgress from './MenuProgress';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const ProgressTracker = ({
  classes,
  menus,
  onExpandAllClick,
  onCollapseAllClick,
  session,
}) => {
  const { selectedMenu } = menus;
  const { last_successful_fetch_at } = session;
  const isBoxingTab = location.href.match(/boxing/);

  return (
    <div>
      {last_successful_fetch_at && (
        <div className={classes.lastUpdated}>
          Auto refreshed at {last_successful_fetch_at}
        </div>
      )}

      <div className={classes.header}>
        <Link
          to="/progress_tracker/overview"
          underline="none"
          color="inherit"
          component={RouterLink}
        >
          <ArrowBackIcon
            fillColor={GREY_400}
            width="44px"
            height="44px"
            className={classes.arrowBack}
          />
          <span className={classes.title}>Summary</span>
        </Link>
      </div>

      <NavigationBar selectedMenu={selectedMenu} classes={classes} />

      {!isBoxingTab && (
        <ExpandCollapse
          className={classes.toggleAllButtonGroup}
          onCollapseClick={onCollapseAllClick}
          onExpandClick={onExpandAllClick}
        />
      )}

      <Switch>
        <Route
          exact
          path="/progress_tracker"
          render={() => <Loading type="linear" />}
        />
        <Route
          exact
          path="/progress_tracker/menu/:menuId/overview"
          component={MenuProgress}
        />
        <Route
          exact
          path="/progress_tracker/menu/:menuId/labeling"
          component={MenuProgress}
        />
        <Route
          exact
          path="/progress_tracker/menu/:menuId/bagging"
          component={MenuProgress}
        />
        <Route
          exact
          path="/progress_tracker/menu/:menuId/boxing"
          component={BoxingInventory}
        />
        <Route
          exact
          path="/progress_tracker/menu/:menuId/portioning"
          component={MenuProgress}
        />
        <Route
          exact
          path="/progress_tracker/menu/:menuId/portioning/by_ingredient"
          component={PortioningByIngredient}
        />
      </Switch>
    </div>
  );
};

ProgressTracker.propTypes = {
  classes: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  onExpandAllClick: PropTypes.func.isRequired,
  onCollapseAllClick: PropTypes.func.isRequired,
  menus: PropTypes.object,
};

ProgressTracker.defaultProps = {
  menus: undefined,
};

export default ProgressTracker;
