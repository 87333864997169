import React from 'react';
import PropTypes from 'prop-types';

import { POD_GREY } from 'lib/constants';

/**
 * Creates a new PodIcon.
 * @class
 */
const PodIcon = ({ className, podColor }) => {
  return (
    <svg className={className} viewBox="0 0 221 171" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle"
          fill={podColor}
          x="0"
          y="0"
          width="221px"
          height="171px"
          rx="12"
          data-testid="pod-icon-rect"
        />
        <path
          d="M 163 0 H 211 Q 221 0 221 10 V 161 Q 221 171 211 171 H 163z"
          fill="rgba(0, 0, 0, 0.2)"
        />
      </g>
    </svg>
  );
};

PodIcon.propTypes = {
  className: PropTypes.string,
  podColor: PropTypes.string,
};

PodIcon.defaultProps = {
  className: '',
  podColor: POD_GREY,
};

export default PodIcon;
