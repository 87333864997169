import { BLACK, NEGATIVE_RED, HC_GREEN } from 'lib/constants';

import { truckLine, truckText, separator, truckId } from '../styles';
import { expander } from '../../styles';

export default {
  truckLine: {
    ...truckLine,
    boxShadow: 'none',
  },
  truckText,
  ...truckId,
  separator,
  truckInfo: {
    height: '50px',
    boxShadow: 'none',
    marginBottom: '0px',
    paddingTop: '5px',
    borderBottom: `solid 1px ${BLACK}`,
    '&:focus': { outline: 'none' },
  },
  text: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  truckIcon: {
    minWidth: '50px',
    marginRight: '10px',
  },
  truckId: {
    fontSize: '1.2em',
  },
  approvedTruck: {
    color: HC_GREEN,
  },
  vendorNames: {
    fontSize: '1.1em',
  },
  truckContainer: {
    width: '100%',
  },
  truckExpander: {
    ...expander,
    marginBottom: '10px',
    '&.Mui-expanded': {
      margin: '10px 0px 25px 0px',
    },
    '&.Mui-expanded:last-child': {
      borderRadius: '0px 0px 10px 10px',
      marginBottom: '30px',
    },
    '&:last-child': {
      borderRadius: '0px 0px 10px 10px',
      marginBottom: '30px',
    },
    '&.Mui-expanded:first-child': {
      borderRadius: '10px 10px 0px 0px',
    },
    '&:first-child': {
      borderRadius: '10px 10px 0px 0px',
    },
    '&.Mui-expanded:only-child': {
      borderRadius: '10px 10px 10px 10px',
    },
    '&:only-child': {
      borderRadius: '10px 10px 10px 10px',
    },
  },
  warningIcon: {
    color: NEGATIVE_RED,
    verticalAlign: 'text-bottom',
  },
  approvalCount: {
    textAlign: 'right',
  },
};
