import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { fetchMealsByFacilityAndMenuId } from 'redux/qa/portionMeals/actions';
import { selectMenu, fetchMenusAndSelectFirst } from 'redux/menu/actions';

import ExtendedMenuDropDown from './ExtendedMenuDropDown';

const ExtendedMenuDropDownContainer = ({
  menus,
  onFetchMenusAndSelectFirst,
  onMenuSelected,
}) => {
  useEffect(() => {
    const showExtendedProdMenus = true;
    onFetchMenusAndSelectFirst(showExtendedProdMenus);
  }, []);

  return (
    <ExtendedMenuDropDown
      menus={menus}
      location={location}
      onMenuSelected={onMenuSelected}
    />
  );
};
ExtendedMenuDropDownContainer.propTypes = {
  menus: PropTypes.object.isRequired,
  onFetchMenusAndSelectFirst: PropTypes.func.isRequired,
  onMenuSelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menus: state.menus,
  meals: state.meals,
  session: state.session,
});

const mapDispatchToProps = {
  onFetchMealsByFacilityAndMenuId: fetchMealsByFacilityAndMenuId,
  onMenuSelected: selectMenu,
  onFetchMenusAndSelectFirst: fetchMenusAndSelectFirst,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExtendedMenuDropDownContainer)
);
