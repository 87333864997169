import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { setTopBarContent } from 'redux/topBar/actions';
import { fetchMenus } from 'redux/menu/actions';
import { requestPodLocations } from 'redux/podLocation/actions';

import styles from './styles';
import WarehouseRequests from './WarehouseRequests';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onRequestPodLocations: requestPodLocations,
  onFetchMenus: fetchMenus,
};

const mapStateToProps = (state) => ({
  menus: state.menus.results,
  session: state.session,
  posting: state.itemMoveRequests.posting,
  failed: state.itemMoveRequests.postingFailed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WarehouseRequests));
