import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import classnames from 'classnames';

import { BooleanToggleButton } from 'components/shared';

const LabelQuestions = ({
  meal,
  isValidLabel,
  labelIssues,
  labelCorrectiveAction,
  onChangeLabelCorrectiveAction,
  onChangeLabelIssueState,
  onChangeLabelIssues,
  classes,
}) => {
  const LABEL_ISSUES = ['Missing', 'Wrong', 'Damaged/Broken', 'Misplaced'];

  return (
    <>
      <BooleanToggleButton
        headerText="Is the Meal Bag label present and accurate?"
        helperText={`Should read "${
          meal.title // On the back end // This meal allergens thing has to match the honey tag. We should probably make allergies presenters or refactor the method
        } (Contains ${meal.mealBagAllergens.join(', ')})"`}
        value={isValidLabel}
        onChange={(_, value) => onChangeLabelIssueState(value)}
        id="labelAccurate"
        data-testid="labelAccurate"
      />

      {isValidLabel === false && (
        <>
          <h3 className={classes.heading}>What are the issues?</h3>
          <ToggleButtonGroup
            className={classes.fourButtonGroup}
            size="large"
            value={labelIssues}
            onChange={(_, value) => onChangeLabelIssues(value)}
          >
            {LABEL_ISSUES.map((issue) => (
              <ToggleButton
                key={issue}
                className={classnames([classes.buttonOne, classes.button])}
                value={issue}
              >
                {issue}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <h3 className={classes.heading}>
            What corrective actions are being taken to resolve the error with
            the meal bag label?
          </h3>
          <TextField
            id="correctiveActionField"
            rows={4}
            fullWidth
            multiline
            variant="outlined"
            value={labelCorrectiveAction}
            onChange={(event) =>
              onChangeLabelCorrectiveAction(event.target.value)
            }
          />
        </>
      )}
    </>
  );
};

LabelQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
  meal: PropTypes.object.isRequired,
  isValidLabel: PropTypes.bool,
  labelIssues: PropTypes.array.isRequired,
  labelCorrectiveAction: PropTypes.string,
  onChangeLabelCorrectiveAction: PropTypes.func.isRequired,
  onChangeLabelIssueState: PropTypes.func.isRequired,
  onChangeLabelIssues: PropTypes.func.isRequired,
};

LabelQuestions.defaultProps = {
  isValidLabel: null,
};

export default LabelQuestions;
