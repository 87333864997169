import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { numberWithCommas } from 'lib/utils';
import { APPROVAL_REASONS } from 'lib/constants';
import { useSimpleForm } from 'lib/custom_hooks';

const OveragesDialog = ({
  open,
  expectedQuantity,
  totalQuantity,
  setDialogOpen,
  handleDialogSubmit,
  classes,
  needApproval,
}) => {
  const { inputs, handleInputChange, handleClear } = useSimpleForm({
    overageApprover: null,
    overageReason: null,
  });

  const closeDialog = () => {
    handleClear();
    setDialogOpen(false);
  };

  const buttonDisabled =
    needApproval && (!inputs.overageApprover || !inputs.overageReason);

  const onSubmitClick = (e) => {
    e.preventDefault();
    handleDialogSubmit(inputs.overageApprover, inputs.overageReason);
    handleClear();
  };
  return (
    <Dialog open={open} onClose={closeDialog} data-testid="overages-dialog">
      <DialogContent>
        <div className={classes.bodyText}>
          <span className={classes.errorText}>
            {needApproval ? 'Attn:' : 'Note:'}{' '}
          </span>
          {needApproval
            ? 'This receipt exceeds the expected PO quantity. Contact procurement for a decision on the overage.'
            : 'This receipt exceeds the expected PO quantity but falls within the acceptable threshold. Please verify quantity before proceeding.'}
          <span className={classes.quantityLine}>
            Expected quantity:
            <span className={classes.bold}>
              {' '}
              {numberWithCommas(expectedQuantity)}
            </span>
          </span>
          <span className={classes.quantityLine}>
            Submitting quantity:
            <span className={classes.bold}>
              {' '}
              {numberWithCommas(totalQuantity)}
            </span>
          </span>
        </div>
        {needApproval && (
          <div className={classes.approvalFields}>
            <TextField
              label="Procurement Approver"
              name="overageApprover"
              variant="outlined"
              margin="normal"
              value={inputs.overageApprover || ''}
              className={classes.textField}
              onChange={handleInputChange}
            />
            <TextField
              label="Approval Reason"
              name="overageReason"
              data-testid="approval-reason"
              variant="outlined"
              margin="normal"
              select
              value={inputs.overageReason || ''}
              className={classes.textField}
              onChange={handleInputChange}
            >
              {APPROVAL_REASONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          className={classes.button}
          disabled={buttonDisabled}
          onClick={onSubmitClick}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OveragesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  expectedQuantity: PropTypes.number.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  handleDialogSubmit: PropTypes.func.isRequired,
  needApproval: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default OveragesDialog;
