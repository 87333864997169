import { WHITE, ERROR_RED, HC_GREEN, GREY_200 } from 'lib/constants';

export default {
  link: {
    color: 'black',
  },
  depthButtonContainer: {
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(49%, 1fr))',
    margin: '10px 5px',
  },
  depthButton: {
    cursor: 'pointer',
    borderRadius: '8px',
    textTransform: 'none',
    fontSize: '16px',
    boxSizing: 'border-box',
    background: WHITE,
    border: `1px solid ${GREY_200}`,
    padding: '0em',
    height: '3.5em',
  },
  selectedDepth: {
    background: HC_GREEN,
    color: WHITE,
  },
  maxedPallets: {
    color: GREY_200,
    border: `1px solid ${ERROR_RED}`,
  },
  maxPalletText: {
    color: 'red',
    textAlign: 'center',
    marginTop: '10px',
  },
};
