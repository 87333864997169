import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import submitButtonTheme from 'lib/submitButtonTheme';
import ContentModal from 'components/shared/ContentModal';

import styles from './styles';

const useStyles = makeStyles(styles);

const MarkCountAsComplete = ({ countList, onClickComplete }) => {
  const [completeCountModalOpen, setCompleteCountModalOpen] = useState(false);

  const completeCountModalToggle = () =>
    setCompleteCountModalOpen((modalOpen) => !modalOpen);

  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.completeButton}
        onClick={completeCountModalToggle}
        data-testid="complete-count-button"
        variant="contained"
      >
        Complete Count
      </Button>

      <ContentModal
        open={completeCountModalOpen}
        onClose={completeCountModalToggle}
        title="Mark Count as Complete?"
      >
        <div>
          <div>
            {`${countList.completed}/${countList.total}` +
              ' pallets have been counted.'}
          </div>
          <div>
            <div
              className={classes.numberOnHold}
            >{` ${countList.onHold} pallets are on hold`}</div>
          </div>
          <ThemeProvider theme={submitButtonTheme}>
            <Button
              onClick={() => onClickComplete(countList.id)}
              data-testid="mark-count-list-complete"
              variant="contained"
            >
              Mark Count as Complete
            </Button>
          </ThemeProvider>
        </div>
      </ContentModal>
    </>
  );
};

MarkCountAsComplete.propTypes = {
  countList: PropTypes.object.isRequired,
  onClickComplete: PropTypes.func,
};

MarkCountAsComplete.defaultProps = {
  onClickComplete: () => {},
};

export default MarkCountAsComplete;
