import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import WarningIcon from '@mui/icons-material/ReportProblemRounded';
import AccordionSummary from '@mui/material/AccordionSummary';
import DoneIcon from '@mui/icons-material/Done';
import classNames from 'classnames';
import get from 'lodash/get';

import { numberWithCommas } from 'lib/utils';
import { FLAGGED, AUDIT, AUDITED, NEEDS_AUDIT } from 'lib/constants';

import TruckLine from '../TruckLine';
import { truckShape } from '../TruckLine/TruckLine';

const NetsuiteItemLine = ({
  classes,
  netsuiteItem,
  onUpdateNetsuiteItem,
  grey,
  last,
  pallets,
  whichTab,
  onApproveClick,
  summaryTruck,
  auditTrucks,
}) => {
  const handleFlagClick = (e) => {
    e.stopPropagation();
    if (netsuiteItem.auditStatus === FLAGGED) {
      onUpdateNetsuiteItem(netsuiteItem.id, null, netsuiteItem.name);
    } else {
      onUpdateNetsuiteItem(netsuiteItem.id, FLAGGED, netsuiteItem.name);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleApproveClick = (e) => {
    e.stopPropagation();
    whichTab === AUDIT
      ? onApproveClick({ netsuiteItemId: netsuiteItem.id })
      : onApproveClick({
          netsuiteItemId: netsuiteItem.id,
          truckId: summaryTruck.id,
        });
  };

  // NOTE: THIS WAS NEVER WORKING!
  //       it was previously always returning undefined so never showed
  // const isDifferentUOM = () =>
  //   pallets
  //     .map((pallet) => pallet.measuringUnit)
  //     .some((unit) => unit !== netsuiteItem.measuringUnit);

  // const headerForUOM = () => {
  //   return (
  //     <span className={classes.spanTwo}>
  //       {isDifferentUOM() ? (
  //         <WarningIcon
  //           data-testid="warning-icon"
  //           className={classNames(classes.warningIcon, classes.yellow)}
  //         />
  //       ) : null}
  //       {netsuiteItem.measuringUnit}
  //     </span>
  //   );
  // };

  const renderButtonOrChip = () => {
    const isAuditTab = whichTab === AUDIT;
    const isApprovable =
      (isAuditTab &&
        auditTrucks.length > 0 &&
        auditTrucks.some(
          (auditableTruck) =>
            auditableTruck.itemReceiptStatuses[netsuiteItem.id]
        )) ||
      (!isAuditTab &&
        summaryTruck?.dockOutTime &&
        summaryTruck.memo !== null &&
        summaryTruck.criticalDefects &&
        get(summaryTruck, 'memo', '').length > 0);

    let trucksAllAudited;

    if (whichTab === AUDIT) {
      trucksAllAudited = !auditTrucks.some(
        (truck) => truck.itemReceiptStatuses[netsuiteItem.id] === NEEDS_AUDIT
      );
    }

    if (
      (!isAuditTab &&
        netsuiteItem.auditStatus &&
        netsuiteItem.auditStatus !== FLAGGED) ||
      (isAuditTab && netsuiteItem.auditStatus === AUDITED) ||
      (isAuditTab && trucksAllAudited) ||
      summaryTruck?.itemReceiptStatuses[netsuiteItem.id]
    ) {
      return (
        <span className={classes.approvedContainer}>
          <Chip
            icon={<DoneIcon className={classes.approvedIcon} />}
            label="APPROVED"
            classes={{ root: classes.approvedChip }}
            data-testid="approved-chip"
          />
        </span>
      );
    } else {
      return (
        <span
          className={classNames({
            [classes.buttonsAudit]: isAuditTab,
            [classes.buttonsSummary]: !isAuditTab,
          })}
        >
          {!isAuditTab && (
            <Button
              className={classes.warningButton}
              variant="contained"
              onClick={handleFlagClick}
              data-testid="flag-button"
            >
              <WarningIcon
                className={classNames(classes.warningIcon, {
                  [classes.flagged]: netsuiteItem.auditStatus === FLAGGED,
                })}
              />
            </Button>
          )}
          {isApprovable && (
            <Button
              className={classNames(classes.approveButton, {
                [classes.auditButton]: isAuditTab,
              })}
              variant="contained"
              color="primary"
              onClick={handleApproveClick}
              data-testid="approve-button"
            >
              Approve
            </Button>
          )}
        </span>
      );
    }
  };

  const renderSummaryOrAuditTrucks = () => {
    if (whichTab === AUDIT) {
      return auditTrucks.map((auditTruck) => (
        <TruckLine
          key={auditTruck.id}
          truck={auditTruck}
          pallets={pallets.filter((pallet) => pallet.truckId === auditTruck.id)}
          netsuiteItemId={netsuiteItem.id}
          netsuiteItemUOM={netsuiteItem.measuringUnit}
          whichTab={whichTab}
        />
      ));
    } else {
      return (
        <TruckLine
          truck={summaryTruck}
          pallets={pallets}
          netsuiteItemId={netsuiteItem.id}
          netsuiteItemUOM={netsuiteItem.measuringUnit}
          whichTab={whichTab}
        />
      );
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onClick={handleChange}
      classes={{
        root: classNames(classes.itemExpander, {
          [classes.grey]: grey,
        }),
        rounded: classNames({ [classes.rounded]: last }),
      }}
      data-testid="netsuite-item-line"
    >
      <AccordionSummary
        classes={{
          content: classes.itemSummary,
          root: classes.itemSummaryRoot,
        }}
      >
        <span
          className={classNames(classes.spanTwo, {
            [classes.blueText]: auditTrucks.length > 0,
          })}
        >{`${netsuiteItem.name} - ${netsuiteItem.orderableId}`}</span>
        <span className={classes.spanOne}>{netsuiteItem.numberOfPallets}</span>
        <span className={classes.spanTwo}>{netsuiteItem.measuringUnit}</span>
        <span className={classes.spanOne}>
          {numberWithCommas(netsuiteItem.expectedQuantity)}
        </span>
        <span className={classes.spanOne}>
          {numberWithCommas(netsuiteItem.rejectedQuantity)}
        </span>
        <span
          className={classNames(classes.spanOne, {
            [classes.higherFinal]:
              netsuiteItem.actualQuantity > netsuiteItem.expectedQuantity,
          })}
        >
          {numberWithCommas(netsuiteItem.actualQuantity)}
        </span>
        {renderButtonOrChip()}
      </AccordionSummary>
      {renderSummaryOrAuditTrucks()}
    </Accordion>
  );
};

export const netsuiteItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  measuringUnit: PropTypes.string.isRequired,
  expectedQuantity: PropTypes.number.isRequired,
  rejectedQuantity: PropTypes.number.isRequired,
  actualQuantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  orderableId: PropTypes.number.isRequired,
  numberOfPallets: PropTypes.number.isRequired,
});

NetsuiteItemLine.propTypes = {
  classes: PropTypes.object.isRequired,
  netsuiteItem: netsuiteItemShape.isRequired,
  onUpdateNetsuiteItem: PropTypes.func.isRequired,
  grey: PropTypes.bool.isRequired,
  whichTab: PropTypes.string.isRequired,
  last: PropTypes.bool,
  onApproveClick: PropTypes.func.isRequired,
  summaryTruck: PropTypes.object,
  auditTrucks: PropTypes.arrayOf(truckShape),
  pallets: PropTypes.array.isRequired,
};

NetsuiteItemLine.defaultProps = {
  auditTrucks: [],
  last: null,
  summaryTruck: null,
};

export default NetsuiteItemLine;
