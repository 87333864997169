import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { ContentModal } from 'components/shared';
import { updateTruck } from 'redux/truck/actions';

const RejectTruckButton = ({ classes, truck }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onCloseModal = (e) => {
    e.stopPropagation();
    setError(false);
    setLoading(false);
    setModalOpen(false);
  };

  const onOpenModal = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const onRejectTruck = async (e) => {
    e.stopPropagation();
    setLoading(true);
    const response = await dispatch(
      updateTruck({
        id: truck.id,
        truck: { rejected: true },
      })
    );
    setLoading(false);
    response.ok ? setError(false) : setError(true);
  };

  if (truck.rejected) {
    return <span className={classes.rejectedText}>Rejected</span>;
  } else {
    return (
      <>
        <Button
          color="primary"
          onClick={onOpenModal}
          size="small"
          variant="outlined"
        >
          Reject
        </Button>
        <ContentModal
          data-testid="reject-truck-modal"
          open={modalOpen}
          onClose={onCloseModal}
        >
          {error ? (
            <p className={classes.confirmRejectText}>
              An error occurred. Please try again.
            </p>
          ) : (
            <>
              <p className={classes.confirmRejectText}>
                Are you sure you want to reject this truck?
                <br />
                Selecting 'confirm' will send an alert to FSQA.
              </p>

              <div className={classes.modalButtonRow}>
                <Button
                  className={classes.cancelButton}
                  onClick={onCloseModal}
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={onRejectTruck}
                  variant="outlined"
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </ContentModal>
      </>
    );
  }
};

RejectTruckButton.propTypes = {
  classes: PropTypes.object.isRequired,
  truck: PropTypes.shape({
    rejected: PropTypes.bool,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default RejectTruckButton;
