import React, { Fragment, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { updateNetsuiteItemSummary } from 'redux/netsuiteOrderItem/actions';
import { Loading, Snackbar } from 'components/shared';
import { getNetsuiteOrders } from 'redux/netsuiteOrders/summary/actions';
import {
  selectSummaryOrdersWithTrucks,
  selectSummaryOrdersGroupedByTruck,
} from 'redux/truck/selectors';
import { NEEDS_AUDIT } from 'lib/constants';

import styles from './styles';
import WarehouseSummary from './WarehouseSummary';
import { useSummaryAuditShared } from '../shared_hooks';

const truckSnackbarMessaging = {
  loading: 'Updating Truck',
  failure: 'Updating Truck Failed',
  success: 'Truck Updated!',
};

export const WarehouseSummaryContainer = ({
  classes,
  fetching,
  onGetNetsuiteOrders,
  trucks,
  onUpdateNetsuiteItem,
  updating,
  updatingFailed,
  homelessNOs,
  updatingTrucks,
  updatingTrucksFailed,
  netsuiteOrders,
  creatingReceipt,
  creatingReceiptFailed,
  itemReceipts,
  ordersByTruck,
}) => {
  const TEN_MINUTE_INTERVAL = 600000;
  const { snackbarMessaging, handleUpdateNetsuiteItem } = useSummaryAuditShared(
    onGetNetsuiteOrders,
    onUpdateNetsuiteItem
  );

  useEffect(() => {
    const interval = setInterval(() => {
      onGetNetsuiteOrders();
    }, TEN_MINUTE_INTERVAL);
    return () => clearInterval(interval);
  });

  const receiptSuccessMessage = () => {
    const approvedMessage = 'Item Receipts Created!';
    if (isEmpty(itemReceipts)) return approvedMessage;
    const needsAuditMessage =
      'Item Receipts Created and sent to Audits tab for review!';
    const isApprovedReceipt = itemReceipts.every(
      (receipt) => receipt.status !== NEEDS_AUDIT
    );
    return isApprovedReceipt ? approvedMessage : needsAuditMessage;
  };

  const receiptSnackbarMessaging = {
    loading: 'Creating Item Receipts',
    failure: 'Item Receipts Creation Failed',
    success: receiptSuccessMessage(),
  };

  if (fetching) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  } else if (trucks.length === 0 && netsuiteOrders.length === 0) {
    return (
      <div className={classes.noTrucks}>
        <span>
          No trucks and no pallets have been created today. Please go to the{' '}
          <Link className={classes.greenLink} to="/receiving/warehouse">
            Warehouse tab
          </Link>{' '}
          to create trucks and pallets.
        </span>
      </div>
    );
  } else {
    return (
      <Fragment>
        <WarehouseSummary
          trucks={trucks}
          netsuiteOrders={netsuiteOrders}
          classes={classes}
          onUpdateNetsuiteItem={handleUpdateNetsuiteItem}
          homelessNOs={homelessNOs}
          ordersByTruck={ordersByTruck}
        />
        <Snackbar
          loading={updatingTrucks}
          failed={updatingTrucksFailed}
          messaging={truckSnackbarMessaging}
        />
        <Snackbar
          loading={creatingReceipt}
          failed={creatingReceiptFailed}
          messaging={receiptSnackbarMessaging}
        />
        <Snackbar
          loading={updating}
          failed={updatingFailed}
          messaging={snackbarMessaging}
        />
      </Fragment>
    );
  }
};

WarehouseSummaryContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  onGetNetsuiteOrders: PropTypes.func.isRequired,
  trucks: PropTypes.array.isRequired,
  onUpdateNetsuiteItem: PropTypes.func.isRequired,
  updatingTrucksFailed: PropTypes.bool.isRequired,
  updatingTrucks: PropTypes.bool.isRequired,
  creatingReceiptFailed: PropTypes.bool.isRequired,
  creatingReceipt: PropTypes.bool.isRequired,
  itemReceipts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { homelessNOs, netsuiteOrders } = selectSummaryOrdersWithTrucks(state);
  const ordersByTruck = selectSummaryOrdersGroupedByTruck(state);
  return {
    trucks: state.trucks.summary.trucks,
    netsuiteOrders,
    homelessNOs,
    ordersByTruck,
    fetching:
      state.netsuiteOrders.summary.fetching || state.trucks.summary.fetching,
    updating: state.netsuiteOrders.summary.updating,
    updatingTrucks: state.trucks.summary.updating,
    updatingTrucksFailed: state.trucks.summary.updateFailed,
    updatingFailed: state.netsuiteOrders.summary.updateFailed,
    creatingReceipt: state.itemReceipt.posting,
    creatingReceiptFailed: state.itemReceipt.postFailed,
    itemReceipts: state.itemReceipt.itemReceipts,
  };
};

const mapDispatchToProps = {
  onGetNetsuiteOrders: getNetsuiteOrders,
  onUpdateNetsuiteItem: updateNetsuiteItemSummary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WarehouseSummaryContainer));
