import {
  GREY_200,
  GREY_600,
  BLACK,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

const palletStyles = {
  color: BLACK,
  padding: '0px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const centered = {
  display: 'flex',
  justifyContent: 'center',
};

export default {
  root: {
    fontFamily: 'Muli',
    padding: 5,
  },
  fullWidth: {
    width: '100%',
    overflowY: 'hidden',
  },
  drawer: {},
  bold: {
    fontWeight: 'bold',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 5px 0px',
    borderBottom: `1px solid ${GREY_200}`,
    fontWeight: 'bold',
    fontSize: 20,
  },
  bodySection: {
    overflowY: 'auto',
    height: 'calc(100vh - 85px)',
  },
  palletList: {
    marginBottom: '20px',
  },
  subheader: {
    color: BLACK,
    width: '100%',
    fontWeight: 'bold',
  },
  textField: {
    width: '100%',
    margin: '5px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5em 1em',
  },
  arrow: {
    color: GREY_600,
    margin: '0px',
    cursor: 'pointer',
    marginRight: '5px',
  },
  palletItem: {
    paddingRight: '5px',
  },
  input: {
    padding: '12px',
  },
  palletInfoWrapper: {
    padding: '0',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  palletLocation: {
    ...palletStyles,
  },
  palletQuantity: {
    textAlign: 'right',
    ...palletStyles,
  },
  palletItemName: {
    maxWidth: '70%',
    fontWeight: '800',
    ...palletStyles,
  },
  palletId: {
    ...palletStyles,
  },
  sorryContainer: {
    ...centered,
    padding: '0',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    textField: {
      margin: '0 0 2px 0',
    },
    palletList: {
      marginBottom: '70px',
    },
  },
  listItemLink: {
    padding: '10px 5px',
  },
  topLine: {
    fontSize: '14px',
    display: 'flex',
    color: BLACK,
    justifyContent: 'space-between',
    marginBottom: '10px',
  },

  searchWrapper: {},
  searchIcon: {
    height: '1.1em',
    width: '1.1em',
  },
};
