import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const BreadcrumbContext = createContext([]);

const BreadcrumbProvider = ({ children }) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = useState([]);

  const deriveCrumbs = () => {
    const locationInfo = location.pathname.split('/');
    const activity = locationInfo[2];

    const indexCrumb = {
      link: `/rth-production-submissions/${activity}`,
      text: activity[0].toUpperCase() + activity.slice(1),
    };

    const detailCrumb = {
      text: location.state.breadCrumbText,
    };

    return [indexCrumb, detailCrumb];
  };

  useEffect(() => {
    location.state?.breadCrumbText ? setCrumbs(deriveCrumbs()) : setCrumbs([]);
  }, [location.state]);

  return (
    <BreadcrumbContext.Provider value={crumbs}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

BreadcrumbProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BreadcrumbProvider;
