import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  NavLink,
} from 'react-router-dom';

import { HOME_BUTTON, ACCOUNT_DROPDOWN, TEXT } from 'lib/constants';

import MealList from '../MealList';
import UploaderInterface from './UploaderInterface';
import ProductionSheet from './ProductionSheet';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const ProductionSheets = ({
  onSetTopBarContent,
  onFetchMenus,
  location,
  classes,
}) => {
  useEffect(() => {
    onFetchMenus();
  }, [onFetchMenus]);

  useEffect(() => {
    if (!location.pathname.includes('menu_selection')) {
      onSetTopBarContent({
        leftContent: HOME_BUTTON,
        middleContent: TEXT,
        rightContent: ACCOUNT_DROPDOWN,
        text: 'Production Sheets',
      });
    }
  }, [location.pathname, onSetTopBarContent]);

  return (
    <>
      <div className={classes.navLinkContainer}>
        <NavLink
          to="/production_sheets/menu"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          Production Plans
        </NavLink>
        <NavLink
          to="/production_sheets/uploader"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          Uploader
        </NavLink>
      </div>
      <Switch>
        <Route
          exact
          path={['/production_sheets/menu/:menuId', '/production_sheets/menu']}
          render={() => <MealList showRetail={false} />}
        />
        <Route
          exact
          path="/production_sheets/uploader"
          render={() => <UploaderInterface />}
        />
        <Route
          exact
          path={[
            '/production_sheets/menu/:menuId/menu_selection/:menuSelectionId',
            '/production_sheets/menu/:menuId/menu_selection/:menuSelectionId/shift/:shiftName',
          ]}
          render={(routingProps) => (
            <ProductionSheet
              {...routingProps}
              onSetTopBarContent={onSetTopBarContent}
            />
          )}
        />
      </Switch>
    </>
  );
};

ProductionSheets.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  onFetchMenus: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ProductionSheets;
