import {
  RETAIL_ORANGE,
  GREY_600,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

export default {
  warningHeader: {
    display: 'flex',
    fontSize: '20px',
    paddingBottom: '4px',
  },
  warningIcon: {
    color: RETAIL_ORANGE,
    paddingRight: '4px',
  },
  lightFont: {
    color: GREY_600,
  },
  boldFont: {
    fontWeight: 'bold',
  },
  palletComparison: {
    paddingTop: '4px',
  },
  divider: {
    margin: '24px 0px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    lightFont: {
      fontSize: '13px',
    },
    boldFont: {
      fontSize: '13px',
    },
  },
};
