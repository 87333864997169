import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const PhotoGallery = ({ classes, onDelete, photos }) => {
  return (
    <div className={classes.root}>
      {photos.map((photo) =>
        photo.processing ? (
          <div
            className={classNames(
              classes.photoContainer,
              classes.processingContainer
            )}
            data-testid="photo-processing"
            key={photo.id}
          >
            Photo processing... Please close pop-up and try again
          </div>
        ) : (
          <div
            className={classes.photoContainer}
            data-testid="photo-processed"
            key={photo.id}
          >
            {photo.allowDelete && (
              <IconButton
                classes={{ root: classes.deleteButton }}
                onClick={() => onDelete(photo.id)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            )}
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img className={classes.photo} src={photo.url} />
          </div>
        )
      )}
    </div>
  );
};

const photoShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
});

PhotoGallery.propTypes = {
  photos: PropTypes.arrayOf(photoShape).isRequired,
  onDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhotoGallery);
