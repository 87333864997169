import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles';

const useStyles = makeStyles(styles);

const DaySplits = ({ meal }) => {
  const classes = useStyles();
  const { daySplitGoals = [] } = meal || {};

  const isComplete = (goal) => goal.num_left_to_complete < 1;

  const allComplete = daySplitGoals.every(isComplete);

  const renderHeaderCells = (goal) => (
    <React.Fragment key={goal.day}>
      <TableCell
        colSpan={1}
        className={classNames(
          classes.dayName,
          classes.bold,
          isComplete(goal) && classes.completedDay,
          allComplete && classes.noBorders
        )}
      >
        {goal.day}
      </TableCell>
      <TableCell
        colSpan={1}
        className={classNames(
          classes.percentComplete,
          classes.bold,
          isComplete(goal) && classes.completedDay,
          allComplete && classes.noBorders
        )}
      >
        {goal.percent_completed}%
      </TableCell>
    </React.Fragment>
  );

  const renderRowLabelCells = (goal) => (
    <React.Fragment key={goal.day}>
      <TableCell
        className={classNames(
          classes.leftCell,
          isComplete(goal) && classes.completedDay,
          allComplete && classes.noBorders
        )}
      >
        Left
      </TableCell>
      <TableCell
        className={classNames(
          classes.rightCell,
          isComplete(goal) && classes.completedDay,
          allComplete && classes.noBorders
        )}
      >
        Total
      </TableCell>
    </React.Fragment>
  );

  const renderBodyCells = (goal) => (
    <React.Fragment key={goal.day}>
      <TableCell
        className={classNames(
          classes.leftCell,
          isComplete(goal) && classes.completedDay,
          classes.cellBigText,
          classes.bold,
          allComplete && classes.noBorders
        )}
      >
        {goal.num_left_to_complete?.toLocaleString()}
      </TableCell>
      <TableCell
        className={classNames(
          classes.rightCell,
          isComplete(goal) && classes.completedDay,
          classes.cellBigText,
          classes.bold,
          allComplete && classes.noBorders
        )}
      >
        {goal.total_to_complete_for_day?.toLocaleString()}
      </TableCell>
    </React.Fragment>
  );

  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            {daySplitGoals.map(renderHeaderCells)}
          </TableRow>
          <TableRow className={classes.row}>
            {daySplitGoals.map(renderRowLabelCells)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.bodyRow}>
            {daySplitGoals.map(renderBodyCells)}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

DaySplits.propTypes = {
  meal: PropTypes.object.isRequired,
};

export default DaySplits;
