import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getPallet = async ({ palletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}`,
    options
  );

  return await handleResponse(response);
};

const putPallet = async ({ palletId, changedPalletAttributes }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        pallet: changedPalletAttributes,
      }),
    }
  );

  return await handleResponse(response);
};

const getFromQuery = async ({ packingFacilityId, searchQuery }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/pallets/search`,
      {
        query: searchQuery,
      }
    ),
    options
  );

  return await handleResponse(response);
};

const getMissingSlotPallets = async ({ storageAreaId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/storage_areas/${storageAreaId}/missing_pallets`,
    options
  );

  return await handleResponse(response);
};

export default {
  getPallet,
  putPallet,
  getFromQuery,
  getMissingSlotPallets,
};
