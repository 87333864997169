export default {
  link: {
    margin: '0 10px',
    color: '#ffffff',
    opacity: '30%',
    textDecoration: 'none',
  },
  activeLink: {
    opacity: 100,
  },
};
