import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import { useTruckSummaryUpdater } from 'components/WarehouseReceivingApps/shared_hooks';

import TruckSummaryButton from '../TruckSummaryButton';

// CriticalDefects stored as int due to netSuite
const DEFECT_YES = 1;
const DEFECT_NO = 2;

const CriticalDefects = ({
  classes,
  onUpdateTruck,
  truckUpdatingFailed,
  truck,
}) => {
  const {
    value: criticalDefects,
    modalOpen,
    setModalOpen,
    handleTruckSummaryChange,
  } = useTruckSummaryUpdater({
    truckUpdatingFailed,
    onUpdateTruck,
    field: truck.criticalDefects,
    truckId: truck.id,
    backendFieldName: 'critical_defects',
  });

  const handleSubmitNo = () => {
    handleTruckSummaryChange(DEFECT_NO);
    setModalOpen(false);
  };

  const handleSubmitYes = () => {
    handleTruckSummaryChange(DEFECT_YES);
    setModalOpen(false);
  };

  const criticalDefectConversion = () => {
    if (criticalDefects === DEFECT_YES) {
      return 'Yes';
    } else if (criticalDefects === DEFECT_NO) {
      return 'No';
    } else return criticalDefects;
  };

  return (
    <Fragment>
      <TruckSummaryButton
        className={classNames(classes.defectButton, {
          [classes.defectYes]: criticalDefects === DEFECT_YES,
          [classes.defectNo]: criticalDefects === DEFECT_NO,
        })}
        value={criticalDefectConversion()}
        onClick={() => setModalOpen(true)}
        field="Defects"
      />
      <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
        <div className={classes.dialogContent}>
          <div className={classes.defectQuestion}>
            Does this truck contain ingredients with critical defects?
          </div>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.buttonNo}
              onClick={handleSubmitNo}
              variant="contained"
              color="primary"
            >
              NO
            </Button>
            <Button
              className={classes.buttonYes}
              onClick={handleSubmitYes}
              variant="contained"
              color="secondary"
            >
              YES
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

CriticalDefects.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdateTruck: PropTypes.func.isRequired,
  truckUpdatingFailed: PropTypes.bool.isRequired,
  truck: PropTypes.shape({
    criticalDefects: PropTypes.number,
  }).isRequired,
};

export default CriticalDefects;
