import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { submitPreliminaryCheck } from 'redux/qa/mealBagChecks/actions';
import { requestPodLocations } from 'redux/podLocation/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { BACK_BUTTON, QA_APP } from 'lib/constants';
import { MealBaggingBanner } from 'components/shared';

import PreliminaryCheck from './PreliminaryCheck';

const PreliminaryCheckContainer = ({
  onRequestPodLocations,
  onSetTopBarContent,
  onSubmitPreliminaryCheck,
  packingFacilityId,
  podLocations,
  preliminaryCheckId,
  location,
}) => {
  const [redirectToNextStep, setRedirectToNextStep] = useState(false);

  const meal = location.state.meal;
  const menuId = meal.menuId;
  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: QA_APP,
      text: `${meal.letterAssignment}: ${meal.title}`,
    });
  }, [meal, onSetTopBarContent]);

  useEffect(() => {
    onRequestPodLocations({ facilityId: packingFacilityId });
  }, [onRequestPodLocations, packingFacilityId]);

  if (redirectToNextStep && preliminaryCheckId) {
    return (
      <Redirect
        to={{
          pathname: `/qa/meal_bagging/preliminary-checks/${preliminaryCheckId}/checks`,
          state: { meal, preliminaryCheckId },
        }}
      />
    );
  } else {
    return (
      <>
        {meal.banner && <MealBaggingBanner text={meal.banner} />}
        <PreliminaryCheck
          podLocations={podLocations}
          meal={meal}
          menuId={menuId}
          setRedirectToNextStep={setRedirectToNextStep}
          onSubmitPreliminaryCheck={onSubmitPreliminaryCheck}
        />
      </>
    );
  }
};

PreliminaryCheckContainer.propTypes = {
  podLocations: PropTypes.array.isRequired,
  onRequestPodLocations: PropTypes.func.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  onRequestPodLocations: requestPodLocations,
  onSetTopBarContent: setTopBarContent,
  onSubmitPreliminaryCheck: submitPreliminaryCheck,
};

const mapStateToProps = (state) => ({
  packingFacilityId: state.session.packing_facility_id,
  podLocations: state.podLocations.pods,
  preliminaryCheckId: state.qaMealBagChecks.preliminaryCheckId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreliminaryCheckContainer);
