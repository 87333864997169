import { GREY_600, ROBOTO } from 'lib/constants';

export default {
  iconContainer: {
    display: 'flex',
    color: GREY_600,
    fontFamily: ROBOTO,
  },
  button: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  icon: {
    paddingRight: '10px',
  },
  buttonContainer: {
    display: 'flex',
  },
};
