import {
  MEDIA_QUERY_PHONE_SCREEN,
  WARNING_YELLOW,
  BLACK,
  ORANGE_YELLOW,
} from 'lib/constants';
import { palletBannerStyles } from 'components/WarehouseInventory/ScanBanner/styles';

export default {
  UnknownAreaPalletBanner: {
    ...palletBannerStyles,
    color: BLACK,
    backgroundColor: WARNING_YELLOW,
    borderBottom: 'solid',
    borderBottomColor: ORANGE_YELLOW,
    borderBottomWidth: '2px',
    paddingLeft: '15px',
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    onhold: {
      fontSize: '12px',
    },
    deactivated: {
      fontSize: '14px',
    },
  },
};
