import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const MealCard = ({ classes, meal, channel, linkTo }) => {
  return (
    <Link
      className={classes.link}
      to={{
        pathname: linkTo,
        state: {
          meal: meal,
          channel: channel,
        },
      }}
    >
      <Card className={classes.card}>
        <div className={classes.cardMediaContent}>
          <CardMedia image={meal.platedImageUrl} className={classes.media} />
          <div className={classes.letterAssignment}>
            {meal.letterAssignment}
          </div>
        </div>
        <CardContent className={classes.content}>{meal.title}</CardContent>
      </Card>
    </Link>
  );
};

MealCard.propTypes = {
  classes: PropTypes.object.isRequired,
  meal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    letterAssignment: PropTypes.string,
    platedImageUrl: PropTypes.string,
  }).isRequired,
  channel: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default withStyles(styles)(MealCard);
