import {
  HC_GREEN,
  WHITE,
  GREY_200,
  GREY_100,
  GREY_500,
  DARK_HC_GREEN,
  WARNING_YELLOW,
} from 'lib/constants';

const button = {
  height: 50,
  fontSize: 16,
  textTransform: 'none',
  marginTop: 20,
};

const buttonDisabled = {
  backgroundColor: GREY_200,
};

const textField = {
  margin: '0 5px',
  width: 370,
};

export default {
  existingTrucksLabel: {
    fontSize: '14px',
    marginBottom: '5px',
  },
  existingTrucksContainer: {
    marginBottom: '22px',
  },
  newTruckContainer: {
    margin: '0 0 15px 5px',
  },
  datePicker: {
    marginLeft: 5,
    marginRight: 5,
    width: 180,
  },
  textField,
  truckInput: {
    ...textField,
    marginBottom: 15,
  },
  textFieldWarning: {
    backgroundColor: WARNING_YELLOW,
  },
  buttonDisabled,
  submitButton: {
    ...button,
    borderRadius: '4px 0px 0px 4px',
    '&$buttonDisabled': buttonDisabled,
    color: WHITE,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  addButton: {
    ...button,
    color: GREY_500,
    backgroundColor: WHITE,
    border: `1px solid ${GREY_200}`,
    '&:hover': {
      backgroundColor: GREY_100,
    },
  },
  warning: {
    margin: '5px 0',
  },
  buttonsContainer: {
    display: 'flex',
  },
};
