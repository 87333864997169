import omit from 'lodash/omit';
import { chain } from 'lodash';
import groupBy from 'lodash/groupBy';

import {
  INITIAL_BRAND,
  ADJUSTED_BRAND,
  BRAND_CORRECT,
  INITIAL_ALLERGENS,
  ADJUSTED_ALLERGENS,
  EXPECTED_ALLERGENS,
  SELECTED_ALLERGENS,
  ALLERGENS_LISTED,
  FSQA_REVIEW_ALLERGENS,
  TEMPS,
  WEIGHT_UOM,
  WEIGHTS,
  DEFECTS,
  DEFECT_IDS,
  NUMBER_DEFECTIVE,
  NUMBER_SAMPLED,
  REJECTED_QUANTITY,
  FINAL_QUANTITY,
  HAS_DEFECTS,
} from 'lib/constants';

const fullCheckFields = [
  INITIAL_BRAND,
  ADJUSTED_BRAND,
  BRAND_CORRECT,
  EXPECTED_ALLERGENS,
  SELECTED_ALLERGENS,
  FSQA_REVIEW_ALLERGENS,
  TEMPS,
  WEIGHT_UOM,
  WEIGHTS,
  DEFECTS,
  DEFECT_IDS,
  NUMBER_DEFECTIVE,
  NUMBER_SAMPLED,
  REJECTED_QUANTITY,
  FINAL_QUANTITY,
  HAS_DEFECTS,
];

export const clearFullCheckFields = (state) => omit(state, fullCheckFields);

export const palletGroupingValues = ({
  netsuiteOrderNumber,
  item,
  vendorName,
  productDateType,
  productDate,
}) =>
  JSON.stringify({
    netsuiteOrderNumber,
    item,
    vendorName,
    productDateType,
    productDate,
  });

const getGroupInfo = (groupInfo, palletInfo) => {
  const totalPallets = palletInfo.length;
  const checkedPallets = palletInfo.filter(
    (pallet) =>
      pallet.status === 'Completed' || pallet.status === 'Partially Completed'
  ).length;
  const hasFullCheckPallet = palletInfo.some((pallet) => pallet.fullCheck);

  const groupCompleted = checkedPallets === totalPallets && hasFullCheckPallet;

  // sorts pallets by updatedAt date, giving the most recent date first; using the speard operator on palletInfo so sort doesn't mutate the orignal data
  const sortedPalletsByDate = [...palletInfo].sort(
    (a, b) => new Date(b.fsqaReviewUpdatedAt) - new Date(a.fsqaReviewUpdatedAt)
  );
  const latestCheckTime = sortedPalletsByDate[0].fsqaReviewUpdatedAt;

  return {
    ...groupInfo,
    hasFullCheckPallet,
    checkedPallets,
    totalPallets,
    groupCompleted,
    latestCheckTime,
  };
};

export function groupPallets(pallets) {
  return chain(pallets)
    .groupBy(palletGroupingValues)
    .entries()
    .map(([key, palletInfo]) => ({
      id: key,
      groupInfo: getGroupInfo(JSON.parse(key), palletInfo),
      pallets: palletInfo,
    }))
    .value();
}

// FSQA ALLERGEN HELPERS

export function groupAllergens(allergens) {
  return groupBy(allergens, 'groupId');
}

const formatAllergens = (allergens = [], type) =>
  allergens.map((allergy) => ({
    allergen_type: type,
    allergy_id: allergy.categoryId,
  }));

export function formatOutgoingAllergenInfo(
  expectedAllergens,
  selectedAllergens,
  areAllergensCorrect
) {
  const formattedAllergens = [
    ...formatAllergens(expectedAllergens, 'Initial'),
    ...formatAllergens(
      areAllergensCorrect ? [] : selectedAllergens,
      'Adjusted'
    ),
  ];
  return formattedAllergens;
}

export function formatIncomingAllergenInfo(data) {
  if (!data.fullCheck) return {};
  return {
    [EXPECTED_ALLERGENS]: data[INITIAL_ALLERGENS],
    [SELECTED_ALLERGENS]: data[ALLERGENS_LISTED]
      ? data[INITIAL_ALLERGENS]
      : data[ADJUSTED_ALLERGENS],
  };
}
