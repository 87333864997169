import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { setTopBarContent } from 'redux/topBar/actions';
import { fetchMenus } from 'redux/menu/actions';

import styles from './styles';
import ProductionSheets from './ProductionSheets';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onFetchMenus: fetchMenus,
};

const mapStateToProps = (state) => ({
  menus: state.menus.results,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProductionSheets));
