import { linkTabStyling } from 'lib/styles';
import { GREY_200, GREY_400, LIGHT_GREEN, WHITE } from 'lib/constants';

export const cell = {
  justifyContent: 'flex-start',
  display: 'flex',
  alignItems: 'center',
};

export const spanTwo = {
  ...cell,
  gridColumn: 'span 2',
};

export const spanOne = {
  ...cell,
  gridColumn: 'span 1',
};

export const expander = {
  '&.Mui-expanded': {
    marginTop: '0px',
  },
};

export const grid = {
  display: 'grid',
  gridTemplateColumns:
    'repeat(8, minmax(67px, 1fr)) [buttons-start] minmax(145px, 1fr) [buttons-end]',
  gridColumnGap: '5px',
};

export const loadingContainer = {
  padding: '50px 0px',
  display: 'flex',
  justifyContent: 'center',
};
export const auditContainer = {
  margin: '-10px',
  marginTop: '-10px',
  background: GREY_200,
  padding: '45px 10px',
  borderTop: `1px solid ${GREY_400}`,
  minHeight: 'calc(100vh - 138px)',
};
export const noTrucks = {
  textAlign: 'center',
  marginTop: '50px',
};

export const approvedContainer = {
  ...cell,
  gridColumn: 'buttons-start / buttons-end',
  justifyContent: 'flex-end',
};
export const approvedChip = {
  color: WHITE,
  background: LIGHT_GREEN,
  width: '118px',
};
export const approvedIcon = {
  color: WHITE,
};

export default {
  ...linkTabStyling,
};
