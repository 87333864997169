import { DIVIDER_GREY, ERROR_DARK, ROBOTO } from 'lib/constants';

import { formSubmitButton } from '../../styles';

export default {
  formDivider: {
    height: `1px solid ${DIVIDER_GREY}`,
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  cancelButton: {
    color: ERROR_DARK,
    fontFamily: ROBOTO,
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  formHeaderText: {
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },
  formConfirmationText: {
    fontFamily: ROBOTO,
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  formCancelButton: {
    fontFamily: ROBOTO,
    fontSize: 17,
    padding: '8px 22px',
    margin: '18px 8px 18px 0',
    flexGrow: 1,
  },
  formSubmitButton: {
    ...formSubmitButton,
    marginRight: '0',
    marginLeft: '8px',
    flexGrow: 1,
  },
};
