import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { usePrevious } from 'lib/custom_hooks';
import { SUCCESS_GREEN, ERROR_RED, WHITE } from 'lib/constants';

const StatefulButton = (props) => {
  const {
    buttonTextOptions,
    buttonBackgroundColors,
    loading,
    failed,
    disabled,
    successCallback,
    errorCallback,
    resetToNormal,
    waitTime,
    currentClicked,
    ...otherProps
  } = props;

  const [buttonText, setButtonText] = useState(null);
  const [buttonColors, setButtonColors] = useState({
    backgroundColor: buttonBackgroundColors.DEFAULT,
  });
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (currentClicked) {
      if (prevLoading && !loading && !failed) {
        setButtonText(buttonTextOptions.SUCCESS);
        setButtonColors({
          color: WHITE,
          backgroundColor: buttonBackgroundColors.SUCCESS,
        });
        if (resetToNormal) {
          setTimeout(() => {
            setButtonColors({});
            setButtonText(null);
            successCallback();
          }, waitTime);
        } else {
          successCallback();
        }
      } else if (prevLoading && !loading && failed) {
        setButtonText(buttonTextOptions.ERROR);
        setButtonColors({
          color: WHITE,
          backgroundColor: buttonBackgroundColors.ERROR,
        });
        if (resetToNormal) {
          setTimeout(() => {
            setButtonColors({
              backgroundColor: buttonBackgroundColors.DEFAULT,
            });
            setButtonText(null);
            errorCallback();
          }, waitTime);
        } else {
          errorCallback();
        }
      } else if (loading) {
        setButtonText(buttonTextOptions.LOADING);
        setButtonColors({
          backgroundColor: buttonBackgroundColors.LOADING,
        });
      }
    }
  }, [
    resetToNormal,
    prevLoading,
    loading,
    failed,
    successCallback,
    errorCallback,
    buttonTextOptions.LOADING,
    buttonTextOptions.ERROR,
    buttonTextOptions.SUCCESS,
    buttonBackgroundColors.ERROR,
    buttonBackgroundColors.SUCCESS,
    buttonBackgroundColors.LOADING,
    buttonBackgroundColors.DEFAULT,
    waitTime,
    currentClicked,
  ]);

  const buttonDisabled = disabled || loading;
  const text = buttonText || buttonTextOptions.DEFAULT;

  return (
    <Button {...otherProps} disabled={buttonDisabled} style={buttonColors}>
      {text}
    </Button>
  );
};

StatefulButton.propTypes = {
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  buttonTextOptions: PropTypes.shape({
    LOADING: PropTypes.string.isRequired,
    SUCCESS: PropTypes.string.isRequired,
    ERROR: PropTypes.string.isRequired,
    DEFAULT: PropTypes.string.isRequired,
  }),
  buttonBackgroundColors: PropTypes.shape({
    LOADING: PropTypes.string,
    SUCCESS: PropTypes.string.isRequired,
    ERROR: PropTypes.string.isRequired,
    DEFAULT: PropTypes.string,
  }),
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  disabled: PropTypes.bool,
  resetToNormal: PropTypes.bool,
  waitTime: PropTypes.number,
  currentClicked: PropTypes.bool,
};

const defaultButtonText = {
  LOADING: 'Loading...',
  SUCCESS: 'Success',
  ERROR: 'Error',
  DEFAULT: 'Submit',
};

const defaultBackgroundColors = {
  SUCCESS: SUCCESS_GREEN,
  ERROR: ERROR_RED,
  DEFAULT: null,
  LOADING: null,
};

StatefulButton.defaultProps = {
  buttonTextOptions: defaultButtonText,
  buttonBackgroundColors: defaultBackgroundColors,
  successCallback: function () {},
  errorCallback: function () {},
  loading: false,
  failed: false,
  disabled: false,
  resetToNormal: true,
  waitTime: 1500,
  currentClicked: true,
};

export default StatefulButton;
