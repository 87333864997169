import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import { percentProgress, formatNumber } from 'lib/utils';

import PercentProgress from './PercentProgress';
import styles from './styles';

const IngredientRow = ({ ingredient, index, classes }) => {
  const completedOverGoalCount = (remainingCount, goalCount) => {
    if (goalCount > 0) {
      return (
        <div>
          <span className={classes.completedCount}>
            {formatNumber(goalCount - remainingCount)}
          </span>
          <span>/</span>
          <span className={classes.goalCount}>{formatNumber(goalCount)}</span>
        </div>
      );
    } else {
      return null;
    }
  };
  const oddOrEvenClass = index % 2 === 0 ? 'evenCell' : 'oddCell';

  const alignedRightCellProps = {
    className: classnames(classes.alignRight, classes[oddOrEvenClass]),
    item: true,
  };

  const alignedCenterCellProps = {
    className: classnames(classes.alignCenter, classes[oddOrEvenClass]),
    item: true,
  };

  return (
    <React.Fragment>
      <Grid
        xs={1}
        item
        className={classnames(
          classes.alignCenter,
          classes[oddOrEvenClass],
          classes.labelOrderCell
        )}
      >
        {ingredient.labelOrder}
      </Grid>
      <Grid item xs={2} className={classes[oddOrEvenClass]}>
        {ingredient.name}
      </Grid>
      <Grid xs={2} {...alignedRightCellProps} />
      <Grid xs={1} {...alignedCenterCellProps} />
      <Grid xs={2} {...alignedRightCellProps}>
        {completedOverGoalCount(
          ingredient.remainingPortionCount,
          ingredient.portioningGoalCount
        )}
      </Grid>
      <Grid xs={1} {...alignedCenterCellProps}>
        <PercentProgress
          visible={ingredient.portioningGoalCount > 0}
          percent={percentProgress(
            ingredient.portioningGoalCount,
            ingredient.remainingPortionCount,
            true
          )}
          percentTarget={ingredient.endOfShiftPortioningTarget}
        />
      </Grid>
      <Grid item xs={2} {...alignedRightCellProps} />
      <Grid xs={1} {...alignedCenterCellProps} />
    </React.Fragment>
  );
};

IngredientRow.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(IngredientRow);
