import {
  MENU_SELECTIONS_REQUEST,
  MENU_SELECTIONS_SUCCESS,
  MENU_SELECTIONS_ERROR,
  MEALS_FOR_INGREDIENT_ASSIGNMENT_REQUEST,
  MEALS_FOR_INGREDIENT_ASSIGNMENT_SUCCESS,
  MEALS_FOR_INGREDIENT_ASSIGNMENT_ERROR,
  CLEAR_MEALS_FOR_INGREDIENT_ASSIGNMENT,
} from 'lib/constants';
import { sortAlphaNum } from 'lib/redux_utils';

export const initialState = {
  fetching: false,
  failed: false,
  menuSelections: [],
  selectedMenuSelection: undefined,
  fetchingByIa: false,
  failedByIa: false,
  ingredientAssignments: [],
};

const filterOutZeroGoalCounts = (ingredientAssignments) => {
  return ingredientAssignments.filter((ia) => {
    return ia.activity === 'labeling'
      ? ia.labelingGoalCount > 0
      : ia.portioningGoalCount > 0;
  });
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case MENU_SELECTIONS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        menuSelections: [],
      };
    case MENU_SELECTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        // sort menuSelections by meal letter, then store in redux
        menuSelections: action.response.menuSelections.sort(sortAlphaNum),
      };
    case MENU_SELECTIONS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case MEALS_FOR_INGREDIENT_ASSIGNMENT_REQUEST:
      return {
        ...state,
        fetchingByIa: true,
        failedByIa: false,
      };
    case MEALS_FOR_INGREDIENT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        fetchingByIa: false,
        failedByIa: false,
        ingredientAssignments: filterOutZeroGoalCounts(
          action.response.ingredientAssignments
        ),
      };
    case MEALS_FOR_INGREDIENT_ASSIGNMENT_ERROR:
      return {
        ...state,
        fetchingByIa: false,
        failedByIa: true,
      };
    case CLEAR_MEALS_FOR_INGREDIENT_ASSIGNMENT:
      return {
        ...state,
        ingredientAssignments: [],
      };
    default:
      return state;
  }
}
