import { MEDIA_QUERY_PHONE_SCREEN, MEDIA_QUERY_TABLET } from 'lib/constants';

let x;
let allKeyFrames = {};
let otherStyles = {};

for (x = 0; x <= 70; x++) {
  const delay = Math.floor(Math.random() * 0.2);
  const duration = Math.floor(Math.random() * 70) + 5;
  const left = Math.floor(Math.random() * 100);
  const top = 100;
  allKeyFrames[`@keyframes emoji-${x}`] = {
    '0%': {
      transform: 'rotate(0deg)',
      left: `${left}%`,
      top: `-${top}%`,
    },
    '25%': {
      left: `${left + 2}%`,
    },
    '50%': {
      left: `${left}%`,
    },
    '75%': {
      left: `${left - 2}%`,
      opacity: '1',
    },
    '100%': {
      transform: 'rotate(360deg)',
      top: '98%',
      opacity: '0',
    },
  };

  otherStyles[`&:nth-of-type(${x})`] = {
    animationName: `$emoji-${x}`,
    animationDelay: `${delay}s`,
    animationDuration: `${duration}s`,
    animationIterationCount: 'infinite',
    left: `${left}%`,
    top: `${top}%`,
    overflow: 'hidden',
  };
}

export default {
  // larger images
  autumnTree: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/autumn-tree.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '-30px',
    left: '375px',
    zIndex: '-4',
    width: '100%',
    height: '3000px',
    minHeight: '3000px',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  pumpkinSpiceLatte: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/psl-clipart.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '20px',
    right: '150px',
    zIndex: '0',
    width: '100%',
    height: '100px',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  pumpkinWagon: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/wagon-with-pumpkins.png") no-repeat 0 bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '-30px',
    left: '0px',
    zIndex: '-4',
    width: '100%',
    height: '350px',
    border: '0 none transparent',
    outline: '0 none transparent',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  turkey: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/turkey-lifelike.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '0px',
    right: '220px',
    zIndex: '-2',
    width: '100%',
    height: '300px',
    minHeight: '255px',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },
  wheatField: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/wheat-field.png") repeat-x 0',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '-3',
    width: '100%',
    height: '100px',
    border: '0 none transparent',
    outline: '0 none transparent',
  },
  willie: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/winter_rabbit.png") no-repeat right bottom',
    backgroundSize: 'contain',
    position: 'absolute',
    bottom: '-6px',
    right: '50px',
    zIndex: '-2',
    width: '100%',
    height: '200px',
    minHeight: '175px',
    WebkitTransform: 'translate3d(0,0,0.01)',
    transform: 'translate3d(0,0,0.01)',
  },

  // leaves
  orangeLeaf: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/orange-leaf.png")',
    },
    ...otherStyles,
  },
  yellowLeaf: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/yellow-leaf.png")',
    },
    ...otherStyles,
  },
  brownLeaf: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/brown-leaf.png")',
    },
    ...otherStyles,
  },
  redLeaf: {
    position: 'absolute',
    '&:after': {
      content:
        'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/red-leaf.png")',
    },
    ...otherStyles,
  },

  // media queries
  [MEDIA_QUERY_TABLET]: {
    pumpkinSpiceLatte: {
      bottom: '10px',
    },
    turkey: {
      height: '200px',
      right: '150px',
    },
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    autumnTree: {
      display: 'none',
    },
    pumpkinSpiceLatte: {
      display: 'none',
    },
    turkey: {
      display: 'none',
    },
    willie: {
      display: 'none',
    },
  },

  // etc
  ...allKeyFrames,
};
