import { useState, useCallback, useEffect, useMemo } from 'react';
/**
 * @description The useSwipable hook allows for a ListItemLink to be swipeable and configure thresholds and reset positions.
 * @param { object } swipeableProps
 *    rightSwipe   [optional] The text displayed behind the swipeable object when active.
 *    leftSwipe       [optional] Sets the active/inactive state of the component.
 *    action            [optional] The method to be called when a swipe has occurred .
 * @return { function } The useSwipeable hook method.
 */
const useSwipeable = (swipeableProps = {}) => {
  /* Configurable Properties in the hook */
  const actionCallback = useMemo(
    () => swipeableProps.action || function () {},
    [swipeableProps.action]
  );

  /* The first recorded value of where the event is triggered */
  const [startX, setStartX] = useState(0);
  const [componentWidth, setComponentWidth] = useState(0);

  /* Swipeable Text Message Styles and Actions (message that shows up behind the swipeable component) */
  const [isSwiping, setSwiping] = useState(false);

  /* Necessary var to fix an issue where the sometimes the last postion of a right swipe goes into the positive numbers, making it look like a left swipe*/
  const [previousSwipe, setPreviousSwipe] = useState(undefined);

  /* Keeping track of a left/right swipe event to know what to do on move end */
  const [swipeRight, setSwipeRight] = useState(false);
  const [swipeLeft, setSwipeLeft] = useState(false);

  // NOTE: since actionCallback gets reinitatiate on every rerender (why!!!), this will get called many times for each row
  // to reduce chatter only doing the callback if isSwiping is true
  useEffect(() => {
    isSwiping &&
      actionCallback({
        isSwiping,
      });
  }, [actionCallback, isSwiping]);

  const getEventInfo = (event) => {
    let eventList = event.changedTouches ? event.changedTouches[0] : event;
    return {
      clientX: eventList.clientX,
      clientY: eventList.clientY,
      currentTarget: event.currentTarget,
    };
  };

  const resetPosition = (currentTarget, xPosition) => {
    currentTarget.style.zIndex = 0;
    currentTarget.style.transform = `translate3d(${xPosition}px, 0, 0)`;
    currentTarget.style.transition = '0ms ease 0s';
  };

  /* Touch and Drag Events */
  const handleMoveStart = useCallback(
    (event) => {
      const { clientX, currentTarget } = getEventInfo(event);

      setComponentWidth(currentTarget.offsetWidth);
      setStartX(clientX);
      setSwiping(true);

      currentTarget.style.zIndex = 100;
    },
    [setSwiping, setComponentWidth]
  );

  const handleMove = useCallback(
    (event) => {
      const { clientX, currentTarget } = getEventInfo(event);
      const progressX = startX - clientX;
      const translation = parseInt(Math.abs(progressX));
      const percentage = parseInt((translation / componentWidth) * 100);

      // threshold for considering a full left/right swipe can either be passed in or just set current value
      const rightSwipeThreshold =
        swipeableProps.rightSwipe?.thresholdPx || translation;
      const leftSwipeThreshold =
        swipeableProps.leftSwipe?.thresholdPx || translation;

      const isSwipingLeft = progressX > 0 && percentage > 5 && percentage < 50;
      const isSwipingRight = progressX < 0 && percentage > 5 && percentage < 50;

      if (isSwipingRight && previousSwipe !== 'left') {
        currentTarget.style.transform = `translate3d(${percentage}%, 0, 0)`;
        currentTarget.style.transition = '0ms ease 0s';
        setPreviousSwipe('right');

        /* Prevents a click from being read as swipe and only shows text once threshold has been achieved */
        if (!swipeRight && translation >= rightSwipeThreshold) {
          setSwipeRight(true);
          setSwipeLeft(false);
        }
      }

      if (isSwipingLeft && previousSwipe !== 'right') {
        currentTarget.style.transform = `translate3d(-${percentage}%, 0, 0)`;
        currentTarget.style.transition = '0ms ease 0s';
        setPreviousSwipe('left');

        /* Prevents a click from being read as swipe and only shows text once threshold has been achieved */
        if (!swipeLeft && translation >= leftSwipeThreshold) {
          setSwipeLeft(true);
          setSwipeRight(false);
        }
      }
    },
    [
      startX,
      componentWidth,
      previousSwipe,
      swipeRight,
      swipeableProps,
      swipeLeft,
    ]
  );

  const handleMoveEnd = useCallback(
    (event) => {
      const { currentTarget } = getEventInfo(event);
      setPreviousSwipe(false);
      setSwiping(false);

      if (swipeRight) {
        actionCallback({
          event,
          swipeDirection: 'right',
        });
        setSwipeRight(false);
        const resetXPosition = swipeableProps.rightSwipe?.resetPositionPx || 0;
        resetPosition(currentTarget, resetXPosition);
      }

      if (swipeLeft) {
        actionCallback({
          event,
          swipeDirection: 'left',
        });
        setSwipeLeft(false);

        const resetXPosition = swipeableProps.leftSwipe?.resetPositionPx
          ? `-${swipeableProps.leftSwipe.resetPositionPx}`
          : 0;
        resetPosition(currentTarget, resetXPosition);
      }

      if (!swipeRight && !swipeLeft) {
        resetPosition(currentTarget, 0);
      }
    },
    [swipeRight, swipeLeft, actionCallback, swipeableProps]
  );

  return {
    handleMoveStart,
    handleMove,
    handleMoveEnd,
    isSwiping,
    swipeLeft,
    swipeRight,
  };
};

export default useSwipeable;
