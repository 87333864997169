import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';

import MenuButtons from '../MenuButtons';
import styles from '../styles';

const StandardMenuButtons = ({ menus, onMenuSelected, classes }) => {
  const fetching = menus !== undefined ? menus.fetching : true;
  if (fetching) {
    return (
      <div className={classes.progressTrackerLoading}>
        <Loading type="circular" />
      </div>
    );
  } else {
    return (
      <Fragment>
        <MenuButtons
          menus={menus}
          className={classes.navItem}
          onMenuSelected={onMenuSelected}
        />
      </Fragment>
    );
  }
};

StandardMenuButtons.propTypes = {
  menus: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

StandardMenuButtons.defaultProps = {
  menus: undefined,
};

export default withStyles(styles)(StandardMenuButtons);
