import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';

import styles from './styles';

const FacilitySelect = ({ classes, value, onChange }) => {
  return (
    <li className={classNames(classes.navItem, classes.facilityOrMenuSelect)}>
      <form autoComplete="off">
        <Select
          variant="standard"
          value={value}
          onChange={onChange}
          disableUnderline
          inputProps={{ name: 'facility', id: 'facility' }}
          classes={{
            select: classNames(
              classes.facilitySelect,
              classes.facilitySelectMenu
            ),
            icon: classes.facilityIcon,
          }}
        >
          <MenuItem className={classes.menuItem} value={1}>
            Chicago
          </MenuItem>
          <MenuItem className={classes.menuItem} value={2}>
            San Bernardino
          </MenuItem>
          <MenuItem className={classes.menuItem} value={3}>
            Atlanta
          </MenuItem>
          <MenuItem className={classes.menuItem} value={7}>
            Skyview
          </MenuItem>
          <MenuItem className={classes.menuItem} value={8}>
            Baltimore
          </MenuItem>
        </Select>
      </form>
    </li>
  );
};

FacilitySelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(FacilitySelect);
