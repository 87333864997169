import {
  HC_GREEN,
  WHITE,
  DARK_HC_GREEN,
  ERROR_RED,
  DARK_RED,
  GREY_150,
  LIGHT_PINK,
} from 'lib/constants';

import { infoLabel, infoValue } from '../FsqaReviewForms/styles';

export default {
  infoLabel,
  infoValue,
  defectWrapper: {
    ...infoValue,
    marginBottom: 0,
  },
  button: {
    height: '55px',
    width: '80px',
    marginRight: '10px',
  },
  defectButton: {
    height: '68px',
    width: '190px',
    marginBottom: '7px',
    marginRight: '7px',
    textTransform: 'none',
    fontFamily: 'Muli',
    fontSize: '16px',
  },
  selectedNo: {
    backgroundColor: HC_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  selectedYes: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: DARK_RED,
    },
  },
  selectDefects: {
    fontSize: 14,
    margin: '20px 0 10px',
  },
  photoTitle: {
    fontSize: 14,
    margin: '10px 0px',
  },
  defectOptionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',

    justifyContent: 'flex-start',
  },
  selected: {
    backgroundColor: ERROR_RED,
    color: WHITE,
    '&:hover': {
      backgroundColor: ERROR_RED,
    },
  },
  textFieldWrapper: {
    display: 'inline-block',
    width: '190px',
    marginRight: '7px',
    marginTop: '20px',
    fontSize: '14px',
  },
  textField: {
    margin: '10px 0 0 0',
  },
  numberDefective: {
    display: 'flex',
  },
  percentDefective: {
    padding: '0px 14px',
    backgroundColor: GREY_150,
    marginTop: '10px',
    borderRadius: '4px',
    alignItems: 'center',
    display: 'flex',
    height: '56px',
  },
  errorBox: {
    backgroundColor: LIGHT_PINK,
  },
  errorText: {
    marginTop: '5px',
    color: ERROR_RED,
  },
  numericInput: {
    '&::-webkit-inner-spin-button': {
      '::-webkit-appearance': 'none',
      '::-moz-appearance': 'none',
      appearance: 'none',
    },
  },
};
