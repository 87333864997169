import { makeApiRequest } from 'lib/api';
import {
  RECEIVING_PALLETS_POST,
  RECEIVING_PALLETS_GET,
  RECEIVING_PALLETS_RESET,
  RECEIVING_PALLET_DELETE,
} from 'lib/constants';
import { printPallets } from 'redux/pallets/actions';

import Pallets from '../services';

export const fetchPallets = (netsuiteOrderItemId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: RECEIVING_PALLETS_GET,
        method: Pallets.receivingApp.getPallets,
        params: { netsuiteOrderItemId },
      })
    );
  };
};

export const resetPallets = () => ({ type: RECEIVING_PALLETS_RESET });

export const submitAndPrintPallets = (params) => async (dispatch) => {
  const response = await dispatch(
    makeApiRequest({
      type: RECEIVING_PALLETS_POST,
      method: Pallets.receivingApp.createPallets,
      params,
    })
  );

  if (response.ok) {
    const newPalletIds = response.newPallets.map((pallet) => pallet.id);
    const firstPrint = true;
    dispatch(printPallets(newPalletIds, params.printer, firstPrint));
  }
};

export const deletePallet = (palletId, netsuiteOrderItemId) => async (
  dispatch
) => {
  const response = await dispatch(
    makeApiRequest({
      type: RECEIVING_PALLET_DELETE,
      method: Pallets.receivingApp.deletePallet,
      params: { palletId },
    })
  );

  if (response.ok) {
    dispatch(fetchPallets(netsuiteOrderItemId));
  }
};
