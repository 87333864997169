import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import EditTruckLine from './EditTruckLine';
import { truckShape } from '../TruckLine/TruckLine';

const WarehouseSummary = ({
  classes,
  onUpdateNetsuiteItem,
  trucks,
  ordersByTruck,
}) => {
  return (
    <div className={classes.auditContainer}>
      <div className={classNames(classes.header, classes.trucksHeader)}>
        Trucks
      </div>
      {trucks.length === 0 ? (
        <div className={classes.noTrucksContainer}>
          <span>
            No trucks have been created today. Please go to the{' '}
            <Link className={classes.greenLink} to="/receiving/warehouse">
              Warehouse tab
            </Link>{' '}
            to create trucks.
          </span>
        </div>
      ) : (
        <div>
          {trucks.map((truck) => (
            <EditTruckLine
              truck={truck}
              key={`truck-${truck.id}`}
              ordersForTruck={ordersByTruck[truck.id]}
              onUpdateNetsuiteItem={onUpdateNetsuiteItem}
            />
          ))}
        </div>
      )}
    </div>
  );
};

WarehouseSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdateNetsuiteItem: PropTypes.func.isRequired,
  trucks: PropTypes.arrayOf(truckShape).isRequired,
  ordersByTruck: PropTypes.object.isRequired,
};

export default WarehouseSummary;
