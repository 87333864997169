import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';

import RejectTruckButton from './RejectTruckButton';

const TruckReceiving = ({
  classes,
  trucks,
  toggleTruckDrawer,
  selectTruck,
  onFetchDockDoors,
}) => {
  useEffect(() => {
    onFetchDockDoors();
  }, [onFetchDockDoors]);

  const formatDate = (date) => {
    return format(new Date(date).getTime(), 'HH:mm');
  };

  const formatNetsuiteOrders = (netsuiteOrders) => {
    return netsuiteOrders.map((no) => no.netsuiteOrderNumber).join(', ');
  };

  const onSelectTruck = useCallback(
    (truck) => {
      selectTruck(truck);
      toggleTruckDrawer(true);
    },
    [selectTruck, toggleTruckDrawer]
  );

  const onCreateTruck = useCallback(() => {
    selectTruck({});
    toggleTruckDrawer(true);
  }, [selectTruck, toggleTruckDrawer]);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        {/* eslint-disable-next-line */}
        <h3 className={classes.title}>{"Today's Trucks"}</h3>
        <Button
          className={classes.createButton}
          variant="contained"
          onClick={() => onCreateTruck()}
        >
          Create a Truck
        </Button>
      </div>
      {trucks.length > 0 ? (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.th} align="left">
                Truck ID
              </TableCell>
              <TableCell className={classes.th} align="left">
                Vendor
              </TableCell>
              <TableCell className={classes.th} align="left">
                POs
              </TableCell>
              <TableCell className={classes.th} align="left">
                Items
              </TableCell>
              <TableCell className={classes.th} align="left">
                Arrival
              </TableCell>
              <TableCell className={classes.th} align="left">
                Dock-In
              </TableCell>
              <TableCell className={classes.th} align="left">
                Unload Stop
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {trucks.map((truck) => (
              <TableRow
                className={classes.row}
                key={truck.id}
                onClick={() => onSelectTruck(truck)}
              >
                <TableCell
                  align="left"
                  data-testid={`truck-${truck.id}`}
                >{`T${truck.id}`}</TableCell>
                <TableCell component="th" scope="row">
                  {truck.vendorsArray.join(', ')}
                </TableCell>
                <TableCell align="left">
                  {formatNetsuiteOrders(truck.netsuiteOrders)}
                </TableCell>
                <TableCell align="left">{truck.items}</TableCell>
                <TableCell align="left">
                  {formatDate(truck.arrivalTime)}
                </TableCell>
                <TableCell align="left">
                  {formatDate(truck.dockInTime)}
                </TableCell>
                <TableCell align="left">{formatDate(truck.stopTime)}</TableCell>
                <TableCell align="left">
                  <RejectTruckButton classes={classes} truck={truck} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.noTruckMessage}>
          <p>
            No trucks have been created today. Please click the button above to
            start a truck.
          </p>
        </div>
      )}
    </Paper>
  );
};

TruckReceiving.propTypes = {
  classes: PropTypes.object.isRequired,
  trucks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  toggleTruckDrawer: PropTypes.func.isRequired,
  selectTruck: PropTypes.func.isRequired,
  onFetchDockDoors: PropTypes.func.isRequired,
};

export default TruckReceiving;
