import { GREY_100, GREY_800 } from 'lib/constants';

export default {
  slotGroup: {
    backgroundColor: GREY_100,
    padding: '15px 21px',
    position: 'sticky',
    top: '70px',
    zIndex: 2,
    fontWeight: 700,
    fontSize: '14px',
    borderBottom: '1px solid #D8DADC',
    color: GREY_800,
  },
};
