import {
  GREY_100,
  GREY_200,
  GREY_400,
  GREY_800,
  WHITE,
  BLACK,
  SUCCESS_GREEN,
} from 'lib/constants';

export default {
  fixedTable: {
    tableLayout: 'fixed',
    cursor: 'pointer',
  },
  tableHead: {
    background: GREY_800,
    color: WHITE,
    fontSize: '16px',
    lineHeight: '36px',
    padding: '0 10px',
    cursor: 'auto',
  },
  columnXSmall: {
    width: '3%',
  },
  columnSmall: {
    width: '5%',
  },
  columnMedium: {
    width: '10%',
  },
  columnLarge: {
    minWidth: '20%',
  },
  tableCell: {
    background: GREY_100,
    padding: '10px !important',
  },
  iconCell: {
    paddingLeft: '38px !important',
  },
  greenText: {
    color: SUCCESS_GREEN,
  },
  circleCheck: {
    fill: SUCCESS_GREEN,
    verticalAlign: 'middle',
  },
  todoIcon: {
    border: `2px solid ${GREY_200}`,
    height: '16px',
    width: '16px',
    borderRadius: '16px',
    margin: '2px',
  },
  secondaryTable: {
    borderBottom: `1px solid ${GREY_400}`,
    padding: '0 !important',
  },
  secondaryTableHead: {
    borderBottom: 'none',
    color: BLACK,
    fontWeight: 'bold',
    cursor: 'auto',
  },
  secondaryIconCell: {
    paddingLeft: '21px ',
  },
  tableRowHead: {
    height: 'auto',
  },
  sortLabel: {
    color: `${WHITE} !important`,
  },
  sortLabelInactive: {
    color: `${GREY_400} !important`,
  },
};
