import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { reactivatePallet } from 'redux/pallets/actions';

import ReleaseButton from './ReleaseButton';

const mapStateToProps = (state) => ({
  reactivating: state.pallets.inventoryApp.reactivating,
  reactivationFailed: state.pallets.inventoryApp.reactivationFailed,
});

const mapDispatchToProps = {
  onPalletReactivate: reactivatePallet,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReleaseButton)
);
