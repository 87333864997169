import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { DateTime } from 'luxon';

export const BreadcrumbContext = createContext([]);

const BreadcrumbProvider = ({ children, location }) => {
  const deriveCrumbs = () => {
    const locationInfo = location.pathname.split('/');

    const crumbs = [];

    const matchForRequestsForm = matchPath(location.pathname, {
      path:
        '/warehouse_requests/requests/menu/:menuId/menu_selection/:menuSelectionId',
      exact: true,
    });

    if (matchForRequestsForm) {
      const { menuSelection } = location.state;
      const shipWeek = DateTime.fromISO(menuSelection.menuShipWeek).toFormat(
        'M/dd'
      );

      crumbs.push({
        link: locationInfo.slice(0, 5).join('/'),
        text: shipWeek,
      });
      crumbs.push({
        text: `${menuSelection.letterAssignment} - ${menuSelection.title}`,
      });
    }

    return crumbs;
  };

  return (
    <BreadcrumbContext.Provider value={deriveCrumbs()}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

BreadcrumbProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default BreadcrumbProvider;
