import { DARK_RED, BLACK, GREY_200, MEDIA_QUERY_TABLET } from 'lib/constants';

export default {
  delete: {
    border: `1px solid ${DARK_RED}`,
    color: DARK_RED,
  },
  button: {
    width: '47%',
    color: DARK_RED,
    margin: '0px auto',
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '14px',
    '&:hover.Mui-disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
    '&.Mui-disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
    border: `1px solid ${DARK_RED}`,
  },
  modalButtons: {
    textTransform: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: `1px solid ${GREY_200}`,
    margin: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  promptContainer: {
    paddingTop: '12px !important',
    paddingBottom: '4px',
    overflow: 'hidden',
  },
  promptText: {
    color: BLACK,
  },
  deletionOptionsContainer: {
    marginLeft: '-12px',
    paddingBottom: '4px',
  },
  muiPaper: {
    maxHeight: '100%',
  },
  [MEDIA_QUERY_TABLET]: {
    button: {
      fontSize: '36px',
    },
  },
};
