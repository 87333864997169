import {
  WHITE,
  BLACK,
  MEDIA_QUERY_TABLET,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

export default {
  button: {
    textTransform: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    border: '1px solid',
    borderRadius: '8px',
    background: WHITE,
    padding: '16px',
    color: BLACK,
  },
  text: {
    display: 'block',
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  kraken: {
    alignSelf: 'center',
    height: '160px',
    width: '160px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    iconButton: {
      display: 'flex',
      flexDirection: 'row',
    },
    text: {
      width: '88px',
    },
    kraken: {
      alignSelf: 'center',
      height: '80px',
      width: '80px',
    },
  },
  [MEDIA_QUERY_TABLET]: {
    button: {
      fontSize: '36px',
    },
  },
};
