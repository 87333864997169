import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { qaStyles } from 'components/shared';

const ErrorReport = ({
  classes,
  anyErrors,
  labelErrorCount,
  packagingErrorCount,
  ingredientErrorCount,
}) => {
  if (anyErrors) {
    return (
      <>
        <div className={classes.redSmallerText}>
          - {labelErrorCount} Label {labelErrorCount === 1 ? 'Error' : 'Errors'}
        </div>
        <div className={classes.redSmallerText}>
          - {packagingErrorCount} Packaging{' '}
          {packagingErrorCount === 1 ? 'Error' : 'Errors'}
        </div>
        <div className={classes.redSmallerText}>
          - {ingredientErrorCount} Ingredient{' '}
          {ingredientErrorCount === 1 ? 'Error' : 'Errors'}
        </div>
      </>
    );
  } else {
    return <div className={classes.greenSmallerText}>No Errors</div>;
  }
};

ErrorReport.propTypes = {
  classes: PropTypes.object.isRequired,
  labelErrorCount: PropTypes.number.isRequired,
  packagingErrorCount: PropTypes.number.isRequired,
  ingredientErrorCount: PropTypes.number.isRequired,
};

export default withStyles(qaStyles)(ErrorReport);
