import * as Sentry from '@sentry/react';
import React from 'react';
import PropTypes from 'prop-types';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import { Switch, Route as ReactRouterDOMRoute } from 'react-router-dom';

import { NO_PORTION_METHOD } from 'lib/constants';

import IngredientOverviewRow from '../IngredientOverviewRow';
import IngredientPortioningRow from '../IngredientPortioningRow';
import IngredientLabelingRow from '../IngredientLabelingRow';
import NonPortionableIngredientsRow from '../NonPortionableIngredientsRow';
import styles from './styles';

const CONTAINER_SPACING = 2;

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const DetailsHeader = ({
  classes,
  customColumnHeader1,
  customColumnHeader2,
  ingredient_assignments,
  secondaryHeader,
}) => {
  let portionableOrLabelableIngredientAssignments = ingredient_assignments.filter(
    (ia) => ia.method !== NO_PORTION_METHOD || ia.labelRequired === true
  );

  return (
    <AccordionDetails>
      <Grid container spacing={CONTAINER_SPACING}>
        {secondaryHeader ? (
          <React.Fragment>
            <Grid item xs={8} classes={{ item: classes.activityHeading }} />
            <Grid item xs={2} classes={{ item: classes.activityHeading }}>
              <p>labeling</p>
              <span />
            </Grid>
            <Grid item xs={2} classes={{ item: classes.activityHeading }}>
              <p>portioning</p>
              <span />
            </Grid>
          </React.Fragment>
        ) : null}
        <Grid
          item
          xs={secondaryHeader ? 7 : 5}
          classes={{ item: classes.gridItemAlignLeft }}
        >
          ingredient
        </Grid>
        <Grid item xs={1} classes={{ item: classes.gridItemAlignCenter }}>
          rte
        </Grid>
        <Grid item xs={1} classes={{ item: classes.gridItemAlignRight }}>
          % done
        </Grid>
        <Grid item xs={1} classes={{ item: classes.gridItemAlignRight }}>
          # left
        </Grid>
        {!secondaryHeader && (
          <React.Fragment>
            <Grid item xs={1} classes={{ item: classes.gridItemAlignRight }}>
              complete
            </Grid>
            <Grid item xs={1} classes={{ item: classes.gridItemAlignRight }}>
              WIP
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={1} classes={{ item: classes.gridItemAlignRight }}>
          {customColumnHeader1}
        </Grid>
        <Grid item xs={1} classes={{ item: classes.gridItemAlignRight }}>
          {customColumnHeader2}
        </Grid>
        {portionableOrLabelableIngredientAssignments.map(
          (ingredient, index) => (
            <Switch key={`switch_${ingredient.id}`}>
              <Route
                exact
                path="/progress_tracker/menu/:menuId/overview"
                render={() => (
                  <IngredientOverviewRow
                    key={`overview_${ingredient.id}`}
                    ingredient={ingredient}
                    index={index}
                  />
                )}
              />
              <Route
                exact
                path="/progress_tracker/menu/:menuId/labeling"
                render={() => (
                  <IngredientLabelingRow
                    key={`labeling_${ingredient.id}`}
                    ingredient={ingredient}
                    index={index}
                  />
                )}
              />
              <Route
                exact
                path="/progress_tracker/menu/:menuId/portioning"
                render={() => (
                  <IngredientPortioningRow
                    key={`portioning_${ingredient.id}`}
                    ingredient={ingredient}
                    index={index}
                  />
                )}
              />
            </Switch>
          )
        )}
        <Switch key="non_portionable_switch">
          <Route
            exact
            path="/progress_tracker/menu/:menuId/overview"
            render={() => (
              <NonPortionableIngredientsRow
                ingredients={ingredient_assignments.filter(
                  (ia) => ia.method === NO_PORTION_METHOD
                )}
              />
            )}
          />
        </Switch>
      </Grid>
    </AccordionDetails>
  );
};

DetailsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  customColumnHeader1: PropTypes.string.isRequired,
  customColumnHeader2: PropTypes.string.isRequired,
  ingredient_assignments: PropTypes.array.isRequired,
  secondaryHeader: PropTypes.bool,
};

DetailsHeader.defaultProps = {
  secondaryHeader: undefined,
};

export default withStyles(styles)(DetailsHeader);
