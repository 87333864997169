import pick from 'lodash/pick';

export const UPDATE_INPUT = 'UPDATE_INPUT';
export const RESET_CATEGORY_RELATED_INFO = 'RESET_CATEGORY_RELATED_INFO';
export const SAVE_FILE_UPLOAD = 'SAVE_FILE_UPLOAD';
export const RESET_FORM = 'RESET_FORM';

// values that will match exactly to what the backend end expects from this form
export const STORAGE_AREA = 'storage_area_id';
export const SHIFT = 'shift';
export const CATEGORY = 'category';
export const NAME = 'name';
export const MENUS = 'menu_ids';
export const INGREDIENT_CHECKBOX = 'include_ingredient';
export const PACKAGING_CHECKBOX = 'include_packaging_items';
export const GROUND_POSITIONS_CHECKBOX = 'ground_only';
export const LIST_ITEMS = 'list_items';
export const ITEM_TYPES = 'item_types';
export const INGREDIENT = 'Ingredient';
export const PACKAGING_ITEM = 'PackagingItem';

const ITEM_ID = 'item_id';
const ITEM_TYPE = 'item_type';

export const BY_AREA = 'by_area';
export const BY_MENU = 'by_menu';
export const BY_ITEM = 'by_item';

export const SHIFT_OPTIONS = [
  { id: 'first', name: 'First' },
  { id: 'second', name: 'Second' },
  { id: 'third', name: 'Third' },
];

export const CATEGORY_OPTIONS = [
  { id: BY_AREA, name: 'All Items' },
  { id: BY_MENU, name: 'Specific Menu' },
  { id: BY_ITEM, name: 'List Upload' },
];

export const isFormValid = (state) => {
  if (!(state[STORAGE_AREA] && state[SHIFT] && state[CATEGORY])) {
    return false;
  }

  switch (state[CATEGORY]) {
    case BY_MENU:
      return (
        state[MENUS]?.length &&
        (state[INGREDIENT_CHECKBOX] || state[PACKAGING_CHECKBOX])
      );
    case BY_AREA:
      return state[INGREDIENT_CHECKBOX] || state[PACKAGING_CHECKBOX];
    case BY_ITEM:
      return state[LIST_ITEMS]?.length;
    default:
      return false;
  }
};

export const formatStateForPost = (state) => {
  const values = pick(state, [STORAGE_AREA, SHIFT, CATEGORY]);

  let itemTypes = [];
  state[INGREDIENT_CHECKBOX] && itemTypes.push(INGREDIENT);
  state[PACKAGING_CHECKBOX] && itemTypes.push(PACKAGING_ITEM);
  values[ITEM_TYPES] = itemTypes;

  values[GROUND_POSITIONS_CHECKBOX] = state.ground_only;

  values[NAME] = state.name;

  if (state.category === BY_MENU) {
    values[MENUS] = state[MENUS];
  } else if (state.category === BY_ITEM) {
    values[ITEM_TYPES] = null;
    const formattedListItems = Object.values(state[LIST_ITEMS]).map((row) => {
      const dataRow = row.data;

      return {
        [ITEM_ID]: dataRow['Item ID'],
        [ITEM_TYPE]: dataRow['Item Type'],
      };
    });
    values[LIST_ITEMS] = formattedListItems;
  }

  return values;
};

// using formId to reset the entire form component, since the elements are uncontrolled
let formId = 0;
export const reducer = (state = { formId }, action) => {
  switch (action.type) {
    case UPDATE_INPUT:
      return {
        ...state,
        [action.field]: action.value,
      };
    case RESET_CATEGORY_RELATED_INFO:
      return {
        ...state,
        [MENUS]: [],
        [LIST_ITEMS]: [],
      };
    case SAVE_FILE_UPLOAD:
      return {
        ...state,
        [LIST_ITEMS]: action.file,
      };
    case RESET_FORM: {
      formId += 1;
      return { formId };
    }
    default:
      return state;
  }
};
