import { options, handleResponse } from 'lib/homeChefApi';
import { makeQueryParamURL } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getPods = async ({ facilityId, withPziParentIds }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/packing_facilities/${
        facilityId || 1
      }/packing_facility_internal_locations`,
      {
        with_pzi_parent_ids: withPziParentIds,
      }
    ),
    options
  );
  return await handleResponse(response);
};

export default {
  getPods,
};
