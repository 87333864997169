import {
  GREY_150,
  GREY_200,
  BLACK,
  WHITE,
  HC_GREEN,
  DARK_RED,
  ERROR_RED,
  BLUE,
} from 'lib/constants';

const sharedButtonStyles = {
  color: WHITE,
  fontSize: '20px',
  width: '175px',
  height: '80px',
  margin: '15px',
  fontWeight: '900',
  lineHeight: 'inherit',
};

const sharedSubmitButtonStyles = {
  width: '85%',
  color: WHITE,
  padding: '15px 0px',
  margin: '50px auto 10px auto',
  fontSize: '20px',
  '&:disabled': {
    color: BLACK,
    backgroundColor: GREY_200,
  },
};

export default {
  root: {
    fontFamily: 'Muli',
  },
  bold: {
    padding: '10px 20px',
    fontWeight: 'bolder',
    fontSize: '24px',
    textAlign: 'center',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    float: 'right',
    marginLeft: 'auto',
    width: '10px',
  },
  icon: {
    width: 20,
    height: 20,
    margin: '10px',
    float: 'right',
  },
  arrow: {
    fontSize: '125px',
    textAlign: 'center',
    margin: '5px',
    color: HC_GREEN,
    width: 'auto',
  },
  bodySection: {
    width: 'calc(100vw - 85px)',
  },
  text: {
    padding: '10px 20px',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  dropLocation: {
    fontSize: '25px',
    textAlign: 'center',
    fontWeight: 'bolder',
  },
  buttonGroup: {
    margin: '5px',
    textAlign: 'center',
  },
  changeButton: {
    ...sharedButtonStyles,
    backgroundColor: BLUE,
  },
  cancelButton: {
    ...sharedButtonStyles,
    backgroundColor: ERROR_RED,
  },
  reasonButton: {
    ...sharedButtonStyles,
    color: BLACK,
    backgroundColor: GREY_150,
    borderRadius: '5px',
    margin: 'auto',
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: HC_GREEN,
      },
    },
  },
  cantRequestText: {
    padding: '20px',
    fontSize: '25px',
    fontWeight: 'bolder',
    backgroundColor: GREY_200,
  },
  submitButton: {
    ...sharedSubmitButtonStyles,
    backgroundColor: DARK_RED,
    '&:hover': {
      backgroundColor: ERROR_RED,
    },
  },
  submitChangeDropButton: {
    ...sharedSubmitButtonStyles,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: HC_GREEN,
    },
  },
  toggleButtonGroup: {
    margin: '10px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '15px',
    padding: '5px',
  },
};
