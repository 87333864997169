import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BACK_BUTTON, TEXT, PALLET_ACTIONS } from 'lib/constants';
import { makeUnknownLocPalletToActive } from 'redux/pallets/inventoryApp/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { updatePallet } from 'redux/pallets/actions';
import { resetStorageSlots, getDepths } from 'redux/storageSlot/actions';
import PalletFetcher from 'components/WarehouseInventory/PalletFetcher';
import { Snackbar } from 'components/shared';
import { useLocationDepths } from 'components/WarehouseInventory/helpers';

import PalletDetails from './PalletDetails';

const PalletDetailsContainer = ({
  onSetTopBarContent,
  onPalletUpdate,
  onResetDepths,
  fetchFailed,
  match,
  pallet,
  updating,
  updateFailed,
  printing,
  printFailed,
  depthsLoading,
  depthsFailed,
  depthOptions,
  history,
  packingFacilityId,
  onGetDepths,
  palletSearchDrawerOpen,
  onMakeUnknownLocPalletToActive,
  measuringUnitsForSelectedPallet,
  actionResult,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: TEXT,
      text: `Lot ${match.params.id}`,
      rightContent: PALLET_ACTIONS,
    });
  }, [onSetTopBarContent, match.params]);

  const getDepthsCallback = useLocationDepths(
    onResetDepths,
    onGetDepths,
    packingFacilityId,
    pallet.id
  );

  return (
    <>
      <PalletFetcher palletId={match.params.id}>
        <PalletDetails
          pallet={pallet}
          onPalletUpdate={onPalletUpdate}
          onMakeUnknownLocPalletToActive={onMakeUnknownLocPalletToActive}
          updating={updating}
          updateFailed={updateFailed}
          depthsLoading={depthsLoading}
          depthsFailed={depthsFailed}
          depthOptions={depthOptions}
          history={history}
          packingFacilityId={packingFacilityId}
          onGetDepths={getDepthsCallback}
          fetchFailed={fetchFailed}
          palletSearchDrawerOpen={palletSearchDrawerOpen}
          measuringUnitsForSelectedPallet={measuringUnitsForSelectedPallet}
          actionResult={actionResult}
        />
      </PalletFetcher>
      <Snackbar
        loading={actionResult.fetching}
        failed={actionResult.result === 'error'}
        messaging={{
          loading: 'Saving...',
          failure: `Unable to save pallet ${actionResult.id}`,
          success: 'Saved!',
        }}
      />
      <Snackbar loading={printing} failed={printFailed} />
    </>
  );
};

PalletDetailsContainer.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  onPalletUpdate: PropTypes.func.isRequired,
  onResetDepths: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  depthsLoading: PropTypes.bool.isRequired,
  depthsFailed: PropTypes.bool.isRequired,
  updateFailed: PropTypes.bool.isRequired,
  printing: PropTypes.bool.isRequired,
  printFailed: PropTypes.bool.isRequired,
  pallet: PropTypes.shape({
    quantity: PropTypes.number,
    location: PropTypes.string,
    item: PropTypes.string,
  }),
  match: PropTypes.object.isRequired,
  depthOptions: PropTypes.array.isRequired,
  onGetDepths: PropTypes.func.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
  onMakeUnknownLocPalletToActive: PropTypes.func,
  measuringUnitsForSelectedPallet: PropTypes.array,
  actionResult: PropTypes.object.isRequired,
};

PalletDetailsContainer.defaultProps = {
  pallet: null,
  onMakeUnknownLocPalletToActive: () => {},
  measuringUnitsForSelectedPallet: [],
};

const mapStateToProps = (state) => ({
  updating: state.pallets.inventoryApp.updating,
  fetchFailed: state.pallets.inventoryApp.fetchFailed,
  updateFailed: state.pallets.inventoryApp.updateFailed,
  pallet: state.pallets.inventoryApp.selectedPallet,
  printing: state.printers.palletsPrinting,
  printFailed: state.printers.palletsPrintFailed,
  depthsLoading: state.storageSlots.fetching,
  depthsFailed: state.storageSlots.failed,
  depthOptions: state.pallets.inventoryApp.depthOptions,
  packingFacilityId: state.session.packing_facility_id,
  palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
  measuringUnitsForSelectedPallet:
    state.pallets.inventoryApp.measuringUnitsForSelectedPallet,
  actionResult: state.storageSlots.actionResult,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onPalletUpdate: updatePallet,
  onMakeUnknownLocPalletToActive: makeUnknownLocPalletToActive,
  onResetDepths: resetStorageSlots,
  onGetDepths: getDepths,
};

export { PalletDetailsContainer as PalletDetailsContainerTest };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PalletDetailsContainer);
