import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import isEmpty from 'lodash/isEmpty';

import { pluralize } from 'lib/utils';
import {
  COMPLETE_ACTION,
  COMPLETED_STATE,
  FAIL_ACTION,
  ON_HOLD_STATE,
  ON_HOLD_ACTION,
  RELEASE_HOLD_ACTION,
  PRINT_ACTION,
  SLACKING,
  DECANT_ACTION,
} from 'lib/constants';

import ActionButtons from './components/ActionButtons';
import BinActions from './components/BinActions/BinActions';
import CountInput from './components/CountInput';
import styles from './styles';
import EquipmentForm from '../SubmissionForms/ChillingForm/EquipmentForm';
import FailureForm from '../SubmissionForms/FailureForm/FailureForm';
import HoldForm from '../SubmissionForms/HoldForm/HoldForm';
import DecantForm from '../SubmissionForms/DecantForm/DecantForm';

const useStyles = makeStyles(styles);

const SublotCard = ({ sublot, onUpdate, onPrint, includeDecant }) => {
  const classes = useStyles();
  const [selectedAction, setSelectedAction] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const binventoryEnabled = useSelector(
    (state) => state.session?.experiments?.binventory
  );

  const isOnHold = sublot.state === ON_HOLD_STATE;
  const isCompleted = sublot.state === COMPLETED_STATE;

  const equipmentAlreadyRecorded = Boolean(sublot.equipment);
  const isActivitySlacking = sublot.activity === SLACKING;
  const showEquipment = !equipmentAlreadyRecorded && !isActivitySlacking;
  const allowPrint = !!onPrint;
  const allowDecanting = includeDecant && binventoryEnabled;

  const onSubmit = async (args = {}) => {
    const isPartialFailure =
      sublot.count && args.count && sublot.count !== parseInt(args.count);

    setIsFetching(true);
    const response = await onUpdate({
      sublotId: sublot.id,
      count: sublot.count,
      action: selectedAction,
      ...args,
    });

    if (!response.ok) {
      setIsFetching(false);
      setHasFailed(true);
    } else if (
      [ON_HOLD_ACTION, RELEASE_HOLD_ACTION].includes(selectedAction) ||
      isPartialFailure
    ) {
      setSelectedAction('');
      setIsFetching(false);
    } else {
      // do nothing if a sublot card is not for a hold action or partial failure
      // because it will rerender in a different place.  updating the state for
      // the card in this scenario will cause a console error for StatefulButton
    }
  };

  const onReprint = () => {
    const reprint = true;
    onPrint(sublot.id, sublot.count, reprint);
  };

  // Logic for all actions that don't need a form to proceed
  useEffect(() => {
    if (selectedAction === COMPLETE_ACTION && equipmentAlreadyRecorded) {
      onSubmit();
    } else if (selectedAction === PRINT_ACTION) {
      onReprint();
      setSelectedAction('');
    }
  }, [selectedAction]);

  const closeForm = () => setSelectedAction('');

  const sharedFormProps = {
    closeForm,
    isFailed: hasFailed,
    isFetching: isFetching,
    onSubmit,
  };

  const renderOnHoldWarning = () => {
    return (
      <div className={classes.onHoldWarning}>
        <ReportProblemOutlinedIcon color="warning" />
        <div>Sublot On Hold</div>
      </div>
    );
  };

  const renderBins = () => {
    if (binventoryEnabled && !isEmpty(sublot.bins)) {
      return (
        <div className={classes.binContainer}>
          {pluralize(sublot.bins.length, 'bin')}
          {sublot.bins.map((bin) => (
            <BinActions bin={bin} key={bin.id} />
          ))}
        </div>
      );
    }
  };

  return (
    <Card elevation={0} className={classes.card} data-testid="sublot-card">
      <CardContent className={classes.cardContent}>
        <div className={classes.mainContent}>
          <div className={classes.leftContent}>
            {isOnHold && renderOnHoldWarning()}

            <CountInput onUpdate={onUpdate} sublot={sublot} />

            <div className={classes.secondaryInfo}>
              Started: {sublot.startedOn}
            </div>
            {sublot.isCarryover && (
              <div className={classes.carryoverInfo}>CARRYOVER</div>
            )}

            {!isActivitySlacking && (
              <div className={classes.secondaryInfo}>
                Equipment:{' '}
                {equipmentAlreadyRecorded ? (
                  <span>{sublot.equipment}</span>
                ) : (
                  <span className={classes.nilEquipment}>Not Recorded</span>
                )}
              </div>
            )}
          </div>
          <div className={classes.rightContent}>
            <ActionButtons
              selectedAction={selectedAction}
              setSelectedAction={setSelectedAction}
              isFetching={isFetching}
              hasFailed={hasFailed}
              allowPrint={allowPrint}
              isOnHold={isOnHold}
              isCompleted={isCompleted}
              allowDecanting={allowDecanting}
            />
          </div>
        </div>
        {renderBins()}
        {[ON_HOLD_ACTION, RELEASE_HOLD_ACTION].includes(selectedAction) && (
          <HoldForm {...sharedFormProps} alreadyOnHold={isOnHold} />
        )}
        {selectedAction === FAIL_ACTION && (
          <FailureForm
            {...sharedFormProps}
            showEquipment={showEquipment}
            maxCount={sublot.count}
          />
        )}
        {selectedAction === COMPLETE_ACTION && !equipmentAlreadyRecorded && (
          <EquipmentForm {...sharedFormProps} />
        )}
        {selectedAction === DECANT_ACTION && (
          <DecantForm closeForm={closeForm} sublotId={sublot.id} />
        )}
      </CardContent>
    </Card>
  );
};

SublotCard.propTypes = {
  includeDecant: PropTypes.bool,
  sublot: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  onPrint: PropTypes.func,
  showEquipment: PropTypes.bool,
};

SublotCard.defaultProps = {
  includeDecant: false,
  onUpdate: () => {},
  onPrint: null,
  showEquipment: true,
};

export default SublotCard;
