import get from 'lodash/get';

export const selectPosting = (state, palletId) =>
  get(state, `wmsFsqaReviews[${palletId}].posting`, false);

export const selectPostingFailed = (state, palletId) =>
  get(state, `wmsFsqaReviews[${palletId}].postingFailed`, false);

export const selectFetching = (state, palletId) =>
  get(state, `wmsFsqaReviews[${palletId}].fetching`, true);

export const selectFetchingFailed = (state, palletId) =>
  get(state, `wmsFsqaReviews[${palletId}].fetchingFailed`, false);

export const selectFsqaReview = (state, palletId) =>
  get(state, `wmsFsqaReviews[${palletId}].review`, null);

export const selectSavedLotCodes = (state, palletId) =>
  get(state, `wmsFsqaReviews[${palletId}].review.savedLotCodes`, []);
