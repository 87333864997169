import {
  SESSION_REQUEST,
  SESSION_ERROR,
  SESSION_SUCCESS,
  SESSION_UPDATED,
  SESSION_CREATE_REQUEST,
  SESSION_CREATE_ERROR,
  SESSION_CLEAR_SUCCESS,
  SELECTED_FACILITY_UPDATED,
  LAST_SUCCESSFUL_FETCH_AT_UPDATED,
  SESSION_CREATE_SUCCESS,
} from 'lib/constants';

export const initialState = {
  failed: false,
  posting: false,
  user_name: '',
  logged_in: null,
  user_id: null,
  selectedPackingFacilityId: null,
  selectedShipDate: null,
  last_successful_fetch_at: null,
  fetching: false,
  experiments: [],
};

export default function (state = initialState, action = { type: null }) {
  const { last_successful_fetch_at, session } = action.payload || state;
  switch (action.type) {
    case SESSION_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case SESSION_ERROR:
      return {
        ...state,
        fetching: false,
        logged_in: false,
      };
    case SESSION_CLEAR_SUCCESS:
      return {
        ...state,
        user_name: '',
        user_id: null,
        logged_in: false,
        fetching: false,
        packing_facility_id: null,
        experiments: [],
      };
    case SESSION_CREATE_REQUEST:
      return {
        ...state,
        failed: false,
        posting: true,
      };
    case SESSION_CREATE_SUCCESS:
      return {
        ...state,
        ...action.response.session,
        failed: false,
        logged_in: true,
        posting: false,
      };
    case SESSION_CREATE_ERROR:
      return {
        ...state,
        failed: true,
        logged_in: false,
        posting: false,
      };
    case SESSION_SUCCESS:
      return {
        ...state,
        ...action.response.session,
        fetching: false,
      };
    case SESSION_UPDATED:
      if (!session.logged_in) {
        return {
          ...state,
          ...session,
          selectedPackingFacilityId: session.packing_facility_id,
        };
      } else {
        return {
          ...state,
          ...session,
          ...last_successful_fetch_at,
          selectedPackingFacilityId: session.packing_facility_id,
        };
      }
    case SELECTED_FACILITY_UPDATED:
      return {
        ...state,
        ...action.payload,
      };
    case LAST_SUCCESSFUL_FETCH_AT_UPDATED:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
