import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Requires a context object created from React.createContext,
// crumbs returned from context should be an array of objects with shape:
// { text: string, link: string }
const Breadcrumbs = ({ backButton, classes, context, history }) => {
  const crumbs = useContext(context);

  const renderBackButton = () => {
    return (
      <>
        <ArrowBackIcon
          className={classes.backArrow}
          onClick={() => history.goBack()}
        />
        <span className={classes.separator}>/</span>
      </>
    );
  };

  const renderCrumbs = (crumb, index) => {
    if (index === crumbs.length - 1) {
      return (
        <span
          key={crumb.text}
          className={classNames(classes.crumb, classes.currentTab)}
        >
          {crumb.text}
        </span>
      );
    }
    return (
      <Fragment key={crumb.text}>
        <Link
          to={{
            pathname: crumb.link,
            state: crumb.location && crumb.location.state,
          }}
          className={classNames(classes.crumb, classes.link)}
        >
          {crumb.text}
        </Link>
        <span className={classes.separator}>/</span>
      </Fragment>
    );
  };
  return (
    <div>
      {backButton && renderBackButton()}
      {crumbs.length > 1 && crumbs.map(renderCrumbs)}
    </div>
  );
};

Breadcrumbs.propTypes = {
  backButton: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  history: PropTypes.object,
};

Breadcrumbs.defaultProps = {
  backButton: false,
  history: {},
};

export default Breadcrumbs;
