import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Loading } from 'components/shared';

import MenuDropDown from '../MenuDropDown';
import styles from '../styles';

const ExtendedMenuDropDown = ({ menus, onMenuSelected, classes }) => {
  const findMenuFromSelect = (e) => {
    const foundMenu = menus.results.find((menu) => menu.id === e.target.value);
    onMenuSelected(foundMenu);
  };
  const fetching = menus !== undefined ? menus.fetching : true;
  if (fetching) {
    return (
      <div className={classes.progressTrackerLoading}>
        <Loading type="circular" />
      </div>
    );
  } else {
    return (
      <MenuDropDown
        menus={menus}
        className={classes.navItem}
        classes={classes}
        value={menus.selectedMenu}
        onChange={(e) => {
          findMenuFromSelect(e);
        }}
      />
    );
  }
};

ExtendedMenuDropDown.propTypes = {
  menus: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

ExtendedMenuDropDown.defaultProps = {
  menus: undefined,
};

export default withStyles(styles)(ExtendedMenuDropDown);
