import { WHITE, MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  icon: {
    color: WHITE,
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    root: {
      paddingRight: 4,
    },
  },
};
