import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

// @TODO: Rename, move or reuse progress tracker user session
const getSession = async () => {
  const url = `${API_BASE_URI}/ops/login/user_session`;
  const response = await fetch(url, options);

  return await handleResponse(response);
};

const createSession = async ({ packingFacilityId, name, shift, role }) => {
  const url = `${API_BASE_URI}/ops/login`;

  const ops_apps_user = {
    packing_facility_id: packingFacilityId,
    shift,
    user_name: name,
    role,
  };

  const response = await fetch(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      ops_apps_user,
    }),
  });

  return await handleResponse(response);
};

const clearSession = async () => {
  const url = `${API_BASE_URI}/ops/login/new`;

  const response = await fetch(url, {
    ...options,
    method: 'GET',
  });

  return await handleResponse(response);
};

export default {
  getSession,
  createSession,
  clearSession,
};
