import { GREY_400, WHITE, GREY_650 } from 'lib/constants';
import { linkTabStyling, centeredLoading } from 'lib/styles';

export default {
  ...linkTabStyling,
  centeredLoading,
  link: {
    ...linkTabStyling.link,
    color: GREY_650,
    fontSize: '18px',
  },
  container: {
    minHeight: 'calc(100vh - 125px)',
  },
  topContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  menuSelectorContainer: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr 100px',
    alignItems: 'center',
    height: '55px',
    borderBottom: `1px solid ${GREY_400}`,
    padding: '0px',
    paddingRight: '10px',
  },
  headerRow: {
    fontWeight: 'bold',
    height: '30px',
    fontSize: '14px',
    color: GREY_650,
    paddingLeft: '10px',
  },
  menuOption: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    background: 'none',
    cursor: 'pointer',
    height: '35px',
  },
  inactiveLink: {
    borderBottom: 'none',
  },
  rowMarker: {
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    height: '100%',
    color: WHITE,
    fontSize: '20px',
  },
  mealId: {
    marginLeft: '10px',
  },
  numberLeft: {
    textAlign: 'right',
  },
  sorryMessage: {
    ...centeredLoading,
  },
};
