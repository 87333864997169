import { linkTabStyling } from 'lib/styles';
import { GREY_600, ROBOTO } from 'lib/constants';

export default {
  ...linkTabStyling,
  link: {
    ...linkTabStyling.link,
    fontSize: '14px',
    padding: '10px 20px',
    color: GREY_600,
    fontFamily: ROBOTO,
    marginRight: '16px',
  },
  linkContainer: {
    ...linkTabStyling.linkContainer,
    display: 'block',
    height: '40px',
    paddingBottom: '8px',
  },
  activeLink: {
    ...linkTabStyling.activeLink,
    borderWidth: '2px !important',
  },
  navHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '10px',
  },
};
