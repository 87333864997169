import isEmpty from 'lodash/isEmpty';

import {
  STORAGE_SLOT_DEPTHS_REQUEST,
  STORAGE_SLOT_DEPTHS_SUCCESS,
  STORAGE_SLOT_DEPTHS_ERROR,
  STORAGE_SLOTS_SUCCESS,
  STORAGE_SLOTS_REQUEST,
  STORAGE_SLOTS_ERROR,
  RESET_STORAGE_SLOTS,
  INVENTORY_PALLET_CYCLE_COUNT_REQUEST,
  INVENTORY_PALLET_CYCLE_COUNT_SUCCESS,
  INVENTORY_PALLET_CYCLE_COUNT_ERROR,
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_REQUEST,
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_SUCCESS,
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_ERROR,
  INVENTORY_PALLET_UNKNOWN_LOCATION_REQUEST,
  INVENTORY_PALLET_UNKNOWN_LOCATION_SUCCESS,
  INVENTORY_PALLET_UNKNOWN_LOCATION_ERROR,
} from 'lib/constants';
import { cycleCountPallet, removePallet } from 'lib/redux_utils';

const initialState = {
  fetching: false,
  failed: false,
  storageSlots: [],
  loading: false,
  actionResult: {},
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case STORAGE_SLOT_DEPTHS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case STORAGE_SLOT_DEPTHS_SUCCESS:
      return {
        ...state,
        fetching: false,
      };
    case STORAGE_SLOT_DEPTHS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case STORAGE_SLOTS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        storageSlots: [],
      };
    case STORAGE_SLOTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        storageSlots: action.response.storageSlots,
      };
    case STORAGE_SLOTS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case RESET_STORAGE_SLOTS:
      return initialState;
    case INVENTORY_PALLET_CYCLE_COUNT_REQUEST:
      return {
        ...state,
        storageSlots: cycleCountPallet({
          storageSlots: state.storageSlots,
          palletId: action.params.palletId,
          cycleCounted: true,
        }),
        actionResult: { fetching: true },
      };
    case INVENTORY_PALLET_CYCLE_COUNT_SUCCESS:
      if (isEmpty(state.storageSlots)) {
        return {
          ...state,
          actionResult: { fetching: false },
        };
      }
      return {
        ...state,
        storageSlots: cycleCountPallet({
          storageSlots: state.storageSlots,
          palletId: action.response.pallet.id,
          cycleCounted: action.response.pallet.cycleCounted,
        }),
        actionResult: { fetching: false },
      };
    case INVENTORY_PALLET_CYCLE_COUNT_ERROR:
      return {
        ...state,
        storageSlots: cycleCountPallet({
          storageSlots: state.storageSlots,
          palletId: action.params.palletId,
          cycleCounted: false,
        }),
        actionResult: {
          fetching: false,
          result: 'error',
          id: action.params.palletId,
        },
      };
    case INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_REQUEST:
      return {
        ...state,
        actionResult: { fetching: true },
      };
    case INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_SUCCESS:
      return {
        ...state,
        storageSlots: removePallet({
          storageSlots: state.storageSlots,
          palletId: action.response.pallet.id,
        }),
        actionResult: {
          fetching: false,
        },
      };
    case INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_ERROR:
      return {
        ...state,
        actionResult: {
          fetching: false,
          result: 'error',
          id: action.params.palletId,
        },
      };
    case INVENTORY_PALLET_UNKNOWN_LOCATION_REQUEST:
      return {
        ...state,
        actionResult: { fetching: true },
      };
    case INVENTORY_PALLET_UNKNOWN_LOCATION_SUCCESS:
      return {
        ...state,
        storageSlots: removePallet({
          storageSlots: state.storageSlots,
          palletId: action.response.pallet.id,
        }),
        actionResult: { fetching: false },
      };
    case INVENTORY_PALLET_UNKNOWN_LOCATION_ERROR:
      return {
        ...state,
        actionResult: {
          fetching: false,
          result: 'error',
          id: action.params.palletId,
        },
      };
    default:
      return state;
  }
}
