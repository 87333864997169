import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import AuditForm from '../../MoveItemForms/AuditForm';
import PodMoveForm from '../../MoveItemForms/PodMoveForm';
import PodPutbackForm from '../../MoveItemForms/PodPutbackForm/PodPutbackForm';
import PrintButton from '../../PrintButton';
import styles from '../styles';

const useStyles = makeStyles(styles);

const ActionsCell = ({ actions }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.actionButtonContainer}>
        {actions.audit.activated && (
          <AuditForm
            buttonStyles={classes.button}
            productionZoneItem={actions.productionZoneItem}
          />
        )}
      </div>
      <div className={classes.actionButtonContainer}>
        {actions.return.activated && (
          <PodPutbackForm
            buttonStyles={classes.button}
            productionZoneItem={actions.productionZoneItem}
          />
        )}
      </div>
      <div className={classes.actionButtonContainer}>
        {actions.move.activated && (
          <PodMoveForm
            buttonStyles={classes.button}
            productionZoneItem={actions.productionZoneItem}
          />
        )}
      </div>
      <div className={classes.actionButtonContainer}>
        {actions.print.activated && (
          <PrintButton productionZoneItem={actions.productionZoneItem} />
        )}
      </div>
    </div>
  );
};

ActionsCell.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ActionsCell;
