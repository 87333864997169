import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import range from 'lodash/range';
import TextField from '@mui/material/TextField';

import {
  TEMP_INPUT_DECIMAL_PLACES,
  NUMBER_TEMPS_REQUIRED,
} from 'lib/constants';

import {
  useValidator,
  hasListValues,
  onInputListUpdate,
} from '../FsqaReviewForms/formHelpers';
import styles from './styles';

const TempInputs = ({
  classes,
  temps,
  updateTemps,
  validationCallback,
  minimumTemp,
  maximumTemp,
}) => {
  useValidator(hasListValues(temps, NUMBER_TEMPS_REQUIRED), validationCallback);

  const onTempUpdate = useCallback(
    (event, index) => {
      onInputListUpdate(
        event,
        index,
        temps,
        TEMP_INPUT_DECIMAL_PLACES,
        NUMBER_TEMPS_REQUIRED,
        updateTemps,
        true
      );
    },
    [temps, updateTemps]
  );

  const numberOutOfRange = (number) =>
    (minimumTemp !== null &&
      maximumTemp !== null &&
      (number > maximumTemp || number < minimumTemp)) ||
    number === '-';

  return (
    <div>
      <span className={classes.infoLabel}>TEMPS IN °F (5 Required)</span>
      <div className={classes.tempInputsContainer}>
        {range(0, NUMBER_TEMPS_REQUIRED).map((index) => (
          <TextField
            classes={{ root: classes.tempInput }}
            key={index}
            error={numberOutOfRange(temps[index])}
            type="text"
            variant="outlined"
            label={`Temp ${index + 1}`}
            inputProps={{ className: classes.inputElement }}
            value={temps[index] || ''}
            onChange={(e) => onTempUpdate(e, index)}
          />
        ))}
      </div>
    </div>
  );
};

TempInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  temps: PropTypes.arrayOf(PropTypes.string),
  updateTemps: PropTypes.func.isRequired,
  minimumTemp: PropTypes.number,
  maximumTemp: PropTypes.number,
  validationCallback: PropTypes.func.isRequired,
};

TempInputs.defaultProps = {
  temps: [],
  minimumTemp: null,
  maximumTemp: null,
};

export default withStyles(styles)(TempInputs);
