import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import map from 'lodash/map';
import Card from '@mui/material/Card';

import { Loading } from 'components/shared';
import { numberWithCommas } from 'lib/utils';

import Row from './Row';

const IngredientAssignmentMealList = ({
  classes,
  ingredientAssignments,
  showNumberLeft,
  fetching,
}) => {
  if (fetching && !ingredientAssignments) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  } else {
    return (
      <Card>
        <div className={classes.table}>
          <div className={classNames(classes.tableRow, classes.headerRow)}>
            <span>ID</span>
            <span className={classes.mealName}>MEAL NAME</span>
            {showNumberLeft && (
              <span className={classes.numberLeft}># REQUIRED</span>
            )}
          </div>

          {map(ingredientAssignments, (ingredientAssignment, index) => (
            <Row
              key={ingredientAssignment.id}
              mealName={ingredientAssignment.mealName}
              letterAssignment={ingredientAssignment.letterAssignment}
              numberLeft={numberWithCommas(ingredientAssignment.remainingCount)}
              showNumberLeft={!fetching && showNumberLeft}
              channel={ingredientAssignment.channel}
              ingredientAssignment={ingredientAssignment}
              odd={index % 2 === 0}
              classes={classes}
            />
          ))}
        </div>
      </Card>
    );
  }
};

IngredientAssignmentMealList.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredientAssignments: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  showNumberLeft: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  selectedMenu: PropTypes.shape({
    weekOfDate: PropTypes.string,
  }),
};

export default IngredientAssignmentMealList;
