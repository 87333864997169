import { GREY_650 } from 'lib/constants';

export default {
  root: {
    fontFamily: 'Muli',
    width: '80%',
    margin: 'auto',
  },
  infoBesidesButton: {
    padding: '2%',
    margin: '15px auto',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 20,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  pencilButton: {
    height: '30px',
    display: 'flex',
    alignSelf: 'flex-end',
  },
  infoLabel: { color: GREY_650 },
  infoValue: {
    fontWeight: 'bold',
    fontSize: 20,
    margin: '5px 0px 20px',
    marginInlineStart: 0,
  },
  palletIdUnderSlot: {
    color: GREY_650,
    fontSize: '13px',
  },
  userInputs: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bodySection: {
    height: 'calc(100% - 100px)',
  },
  snackbar: {
    top: 60,
  },
};
