import {
  WHITE,
  YELLOW,
  BLUE,
  GREY_100,
  GREY_500,
  GREY_150,
  BLACK,
  MEDIA_QUERY_TABLET_OR_SMALLER,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';
import {
  centeredLoading,
  centeredLoadingContainer,
  loadingMessage,
} from 'lib/styles';
import theme from 'lib/theme';

const listItemText = {
  textAlign: 'left',
  color: BLACK,
  [theme.breakpoints.down('md')]: {
    padding: '2px 5px',
  },
};

const callout = {
  padding: '15px',
  fontSize: '2.0em',
  textAlign: 'center',
  fontWeight: 'bold',
};

const value = {
  textAlign: 'right',
  lineHeight: '1.875em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '40px',
  display: 'block',
  paddingRight: '5px',
};

export default {
  root: {
    padding: '10px',
    fontWeight: 'bold',
  },
  container: {
    width: '95%',
    margin: '0 auto',
  },
  ...loadingMessage,
  centeredLoadingContainer: {
    ...centeredLoading,
    ...centeredLoadingContainer,
    paddingTop: '20px',
  },
  list: {
    fontSize: '14px',
  },
  zebra: {
    '& li:nth-child(odd), & tr:nth-child(odd)': {
      backgroundColor: `${GREY_150}`,
    },
  },
  errorMessage: {
    margin: '30px 15px 0px 15px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  row: {
    padding: '0px 0px 0px 16px',
    height: '3em',
  },
  label: {
    fontWeight: '800',
    minWidth: '40px',
  },
  subHeader: {
    backgroundColor: GREY_500,
    zIndex: 0,
    padding: '0px 10px',
    borderBottom: `1px solid ${GREY_100}`,
    display: 'flex',
    fontSize: '1.0em',
    fontWeight: 'bold',
  },
  tableHeader: {
    backgroundColor: GREY_500,
    borderBottom: `1px solid ${GREY_100}`,
    '& th:first-child': {
      textAlign: 'left',
    },
    '& th': {
      fontSize: '1.0em',
      color: WHITE,
      textAlign: 'right',
    },
  },
  tableBody: {
    '& td:first-child': {
      textAlign: 'left',
    },
    '& td': {
      borderBottom: '0px',
      textAlign: 'right',
    },
  },
  listItemHeader: {
    ...listItemText,
    width: '5%',
  },
  listItemQuantity: {
    ...listItemText,
    textAlign: 'right',
    width: '15%',
  },
  whiteText: {
    color: WHITE,
  },
  yellowCallout: {
    ...callout,
    backgroundColor: YELLOW,
  },
  blueCallout: {
    ...callout,
    backgroundColor: BLUE,
  },
  pickStation: {
    ...listItemText,
    maxWidth: '40px',
    fontWeight: '900',
  },
  value,
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    container: {
      width: '100%',
    },
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    pickStation: {
      ...listItemText,
      minWidth: '14%',
      fontWeight: '900',
    },
    listItemQuantity: {
      ...listItemText,
      textAlign: 'right',
      width: '10%',
    },
    whiteText: {
      color: WHITE,
    },
    loadingMessage: {
      ...loadingMessage,
      maxWidth: '60%',
    },
  },
};
