import { connect } from 'react-redux';

import { clearFSQAForm } from 'redux/wmsFsqaReview/actions';
import { fetchFsqaPhotos } from 'redux/photos/actions';

import PalletDrawer from './PalletDrawer';

// note: do not use ownProps here, it will not memoize the values and cause fetchFsqaPhotos to be infinitely called
const mapDispatchToProps = (dispatch) => ({
  fetchFsqaPhotos: (palletId) => dispatch(fetchFsqaPhotos(palletId)),
  clearFSQAForm: (palletId) => dispatch(clearFSQAForm(palletId)),
});

export default connect(null, mapDispatchToProps)(PalletDrawer);
