import React from 'react';
import PropTypes from 'prop-types';

const PortioningIcon = (props) => {
  const { className = 'PortioningIcon' } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
        <g fill="#009F32" fillRule="evenodd">
          <path
            d="M45.6741189 25.3527937s-7.224-.0535158-11.712-4.5690737c-4.488-4.5155579-4.5411892-11.78391579-4.5411892-11.78391579s7.224.05351579 11.7113514 4.56907369c4.4886486 4.5155579 4.5418378 11.7839158 4.5418378 11.7839158zm18.4079352 9.8328084c4.9907027-.4326947 9.5027027 1.7934316 9.5027027 1.7934316s-2.3513514 4.6434737-6.7284325 7.2931579H12.2543784C8.14518919 41.5924863 5.94108108 37.24596 5.94108108 37.24596s4.51524322-2.2300421 9.50983782-1.7966947c-.9924324-3.0791158-1.0138378-5.711179-1.0138378-5.711179s4.6339459.0365474 8.7852973 2.3710105c.9275675-5.3365684 4.872-9.3704842 4.872-9.3704842s3.4410811 3.5235579 4.6501621 8.3386737c3.4313514-1.3150526 6.5792433-1.3392 6.5792433-1.3392s-.0181622 2.1843579-.7712433 4.9006105c1.1552433.0013053 2.3987027.1070316 3.6531892.3870106-.8633513-2.8931158-.8821621-5.2876211-.8821621-5.2876211s3.5364324.0287158 7.198054 1.5911158c1.1500541-4.9430316 4.7078919-8.5905895 4.7078919-8.5905895S56.764 26.3626758 57.9257297 31.28156c3.6220541-1.5167158 7.0871352-1.5434737 7.0871352-1.5434737s-.0201081 2.4832632-.9308108 5.4475158z"
            opacity=".39988861"
          />
          <path
            d="M31.6533838 54.0749789H23.906573c-7.7896216 0-14.20345949 6.0368422-14.86313516 13.7052632H21.8561946c.4864865-6.1725895 4.3822703-11.3792842 9.7971892-13.7052632"
            opacity=".301507"
          />
          <path
            d="M41.1919784 13.5690737c4.488 4.5155579 4.5411892 11.7839158 4.5411892 11.7839158L29.4799784 9s7.224.05351579 11.712 4.5690737zM57.9846486 31.28156c-1.6073513.6728632-3.2451891 1.6381053-4.6242162 3.0262526-.0246486.0248-.0473513.0509053-.072.0757053V22.7386126s3.5344865 3.6240632 4.6962162 8.5429474zm-24.9571459 4.8507495c.1251892.3034737.299027.6983158.5215135 1.1545052.5863784 1.2034527 1.5171892 2.8389474 2.844973 4.3974316l-7.777946 2.5876842h-3.6784864l8.0899459-8.139621zm31.1136-.9464463c4.9907027-.4326948 9.5027027 1.7927789 9.5027027 1.7927789L51.8985081 44.2718h-1.272l14.4454054-14.5334526s-.0201081 2.4832631-.9308108 5.4475158zm-48.6312649.2632715c.6577298 2.0388211 1.7403244 4.2740842 3.528 6.0727369.0473514.0476421.096.092021.1446487.1383579L6 37.2458295s4.5152432-2.2300421 9.5098378-1.7966948zm7.7714595-3.3399726c-.1232432.7107158-.1984865 1.4423158-.1984865 2.1928421 0 1.8338947.4242162 3.5614105 1.020973 5.1022737l-9.6071352-9.6661263s4.633946.0372 8.7846487 2.3710105zm18.9837405 2.9170674c.6408649 2.1484631 1.7461622 4.5710316 3.6590271 6.4956421.5844324.588021 1.2168648 1.0996842 1.872 1.5460842l-15.025946-7.6044632 1.2408649-.3210947 5.3714594-5.4044421s-.0188108 2.1850105-.7718918 4.9006105c0 0 1.8583783-.0137052 3.6544864.3876632zM28.1531027 22.7387432s3.4410811 3.5235579 4.6501622 8.3386736c-1.5833514.6076-3.2276757 1.4906106-4.6501622 2.7730316V22.7387432zm13.2295135 6.9995389s3.5364324.0280632 7.1980541 1.5911158c-.2211892.9502316-.3625946 1.9428842-.3625946 2.9727368 0 .8921474.1063784 1.7575369.2769729 2.5922527l-7.1124324-7.1561053z"
            opacity=".39665179"
          />
          <path d="M21.8561946 67.7802421H9.04343784c.65967567-7.668421 7.07351356-13.7052632 14.86313516-13.7052632H55.6190054c7.7896216 0 14.2041081 6.0368422 14.8631351 13.7052632H21.8561946zm52.3725405 0h-1.1494054c-.6661621-9.1081263-8.2404324-16.3157895-17.4603243-16.3157895H41.0600865v-4.5821263h30.7751351c.7167568 0 1.2972973-.5841052 1.2972973-1.3052631 0-.7205053-.5805405-1.3052632-1.2972973-1.3052632H7.69035676c-.71610811 0-1.2972973.5847579-1.2972973 1.3052632 0 .7211579.58118919 1.3052631 1.2972973 1.3052631H38.4654919v4.5821263H23.906573c-9.2198919 0-16.79351354 7.2076632-17.46032435 16.3157895H5.29749189c-.71675675 0-1.2972973.5847579-1.2972973 1.3052632 0 .7205052.58054055 1.3052631 1.2972973 1.3052631H74.2287351c.7161081 0 1.2972973-.5847579 1.2972973-1.3052631 0-.7205053-.5811892-1.3052632-1.2972973-1.3052632z" />
        </g>
      </svg>
    </div>
  );
};

PortioningIcon.propTypes = {
  className: PropTypes.string,
};

PortioningIcon.defaultProps = {
  className: 'PortioningIcon',
};

export default PortioningIcon;
