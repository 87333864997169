import { makeApiRequest } from 'lib/api';
import { INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS } from 'lib/constants';

import IngredientAssignments from './services';

export const getIngredientAssignmentsForInventoryCheck = (
  ingredientAssignmentIds
) =>
  makeApiRequest({
    type: INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS,
    method: IngredientAssignments.getIngredientAssignments,
    params: {
      ingredientAssignmentIds: ingredientAssignmentIds,
      showPortionedIngredients: false,
      showCompoundIngredients: true,
      showComponentIngredients: true,
    },
  });
