import {
  WHITE,
  BLACK,
  MEDIA_QUERY_TABLET,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

export default {
  button: {
    width: '47%',
    color: BLACK,
    margin: '0px auto',
    textTransform: 'none',
    fontWeight: 'bolder',
    fontSize: '14px',
    '&:hover.Mui-disabled': {
      color: BLACK,
      backgroundColor: WHITE,
    },
    '&.Mui-disabled': {
      color: BLACK,
      backgroundColor: WHITE,
    },
    border: `1px solid ${BLACK}`,
  },
  text: {
    display: 'block',
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  kraken: {
    alignSelf: 'center',
    height: '160px',
    width: '160px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    iconButton: {
      display: 'flex',
      flexDirection: 'row',
    },
    text: {
      width: '88px',
    },
    kraken: {
      alignSelf: 'center',
      height: '80px',
      width: '80px',
    },
  },
  [MEDIA_QUERY_TABLET]: {
    button: {
      fontSize: '36px',
    },
  },
};
