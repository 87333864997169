import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const Ingredient = ({ ingredient, classes, isLastOfList }) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = (event) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    setPopoverIsOpen(true);
  };

  const closePopover = (event) => {
    event.preventDefault();

    setAnchorEl(null);
    setPopoverIsOpen(false);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        aria-owns="simple-popper"
        aria-haspopup="true"
        variant="contained"
        className={classes.ingredientName}
        onClick={openPopover}
        style={{ display: 'inline-block' }}
      >
        {ingredient.name}
        {isLastOfList ? null : <span>,&nbsp;</span>}
      </button>
      <Popover
        id="simple-popper"
        open={popoverIsOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography className={classes.typography} component="span">
          <Grid container>
            <Grid item xs={12}>
              <strong>{ingredient.name}</strong>
            </Grid>
            <Grid item xs={5}>
              <strong>Size:</strong>
            </Grid>
            <Grid item xs={7}>
              <span className={classes.popoverIngredientValue}>
                {ingredient.quantity} {ingredient.unit}
              </span>
            </Grid>
            <Grid item xs={5}>
              <strong>Brand:</strong>
            </Grid>
            <Grid item xs={7}>
              {ingredient.brandName ? ingredient.brandName : 'N/A'}
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <strong>Notes: </strong>
              <span className={classes.popoverIngredientValue}>
                {ingredient.notes || 'None'}
              </span>
            </Grid>
          </Grid>
        </Typography>
      </Popover>
    </React.Fragment>
  );
};

Ingredient.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
  isLastOfList: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Ingredient);
