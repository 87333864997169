import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';

import { getIngredientAssignmentsForMenuSelection } from 'redux/ingredientAssignments/actions';

import IngredientsCard from './IngredientsCard';

const mapDispatchToProps = {
  onGetIngredientAssignments: getIngredientAssignmentsForMenuSelection,
};

const mapStateToProps = (state) => ({
  ingredientAssignments: sortBy(
    state.ingredientAssignments.productionInventory.ingredientAssignments,
    'name'
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsCard);
