import {
  WHITE,
  GREY_100,
  GREY_500,
  GREY_150,
  BLACK,
  MEDIA_QUERY_TABLET_OR_SMALLER,
  MEDIA_QUERY_PHONE_SCREEN,
  LIGHTER_GREEN,
} from 'lib/constants';
import {
  centeredLoadingContainer,
  centeredLoading,
  loadingMessage,
} from 'lib/styles';
import theme from 'lib/theme';

const listItemText = {
  textAlign: 'left',
  color: BLACK,
  [theme.breakpoints.down('md')]: {
    padding: '2px 5px',
  },
};

const value = {
  textAlign: 'right',
  lineHeight: '1.875em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: '40px',
  display: 'block',
  paddingRight: '5px',
};

export default {
  root: {
    padding: '10px',
    fontWeight: 'bold',
  },
  container: {
    width: '95%',
    margin: '0 auto',
    paddingTop: '15px',
  },
  ...loadingMessage,
  centeredLoadingContainer: {
    ...centeredLoading,
    ...centeredLoadingContainer,
    paddingTop: '40px',
  },
  list: {
    fontSize: '14px',
  },
  zebra: {
    '& li:nth-child(odd)': {
      backgroundColor: GREY_150,
    },
  },
  errorMessage: {
    margin: '30px 15px 0px 15px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  row: {
    padding: '0px 0px 0px 16px',
    height: '3em',
  },
  label: {
    fontWeight: '800',
    minWidth: '40px',
  },
  subHeader: {
    backgroundColor: GREY_500,
    zIndex: 0,
    padding: '0px 10px',
    borderBottom: `1px solid ${GREY_100}`,
    display: 'flex',
    fontSize: '1.0em',
    fontWeight: 'bold',
  },
  listItemHeader: {
    ...listItemText,
    width: '5%',
  },
  whiteText: {
    color: WHITE,
  },
  scheduledShip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  form: {
    paddingTop: '3%',
    width: '100%',
  },
  divider: {
    height: '2px',
    margin: '0% 2% 5%',
    borderColor: GREY_500,
  },
  button: {
    color: `${WHITE} !important`,
    fontSize: '3vh',
    fontWeight: 'bold',
    marginTop: '4%',
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    height: '12%',
    zIndex: '1',
  },
  shippingOption: {
    margin: '0 2% 5%',
    padding: '2%',
    border: '2px solid #008600',
    backgroundColor: LIGHTER_GREEN,
    height: '8vh',
  },
  shippingOptionLabel: {
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '2.75vh',
  },
  currentLabel: {
    color: 'white',
    textAlign: 'center',
    padding: '1.5%',
    marginRight: '1%',
    backgroundColor: '#008600',
    borderRadius: '30px',
    fontSize: '80%',
  },
  value,
  [MEDIA_QUERY_TABLET_OR_SMALLER]: {
    container: {
      width: '100%',
    },
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    whiteText: {
      color: WHITE,
    },
    loadingMessage: {
      ...loadingMessage,
      maxWidth: '60%',
    },
  },
};
