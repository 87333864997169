import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';

import { getFirstAndLastInitials, msToHrMin } from 'lib/utils';
import { ListItemLink } from 'components/shared';

import styles from '../../ItemMoveRequest/styles';

const useStyles = makeStyles(styles);

const TWENTY_MINUTES = 1200000;

const ItemPutbackRow = ({
  itemPutback,
  index,
  last,
  signedInUser,
  historical = false,
}) => {
  const classes = useStyles();

  const [elapsedTime, setElapsedTime] = useState(
    new Date() - new Date(itemPutback.createdAt)
  );

  useEffect(() => {
    const updateTime = setInterval(() => {
      setElapsedTime(new Date() - new Date(itemPutback.createdAt));
    }, 60000);
    return () => clearInterval(updateTime);
  }, [itemPutback.createdAt]);

  const expiredTime = elapsedTime >= TWENTY_MINUTES;

  const redirectTo = () => {
    return historical
      ? '#'
      : `/warehouse_moves/putbacks/in_progress/${itemPutback.id}`;
  };

  const requestDate = (dateTime) => dateTime.split(' ')[0];

  const requestTime = (dateTime) => dateTime.split(' ').slice(1).join(' ');

  return (
    <ListItemLink
      className={classes.listItemLink}
      grey={index % 2 === 0}
      white={index % 2 !== 0}
      last={last}
      showArrow={false}
      link={redirectTo()}
      onClick={(e) => historical && e.preventDefault()}
    >
      <div data-testid="item-putback" className={classes.itemMoveRequest}>
        <div className={classes.leftSide}>
          {itemPutback.putbackPalletId && (
            <div
              className={classes.lotId}
            >{`Lot ${itemPutback.putbackPalletId} - ${itemPutback.numberOfCases} Cases Putback`}</div>
          )}
          <div className={classes.bold}>
            <span className={classes.ingredientId}>
              {itemPutback.parentPalletInfo.orderableId} -{' '}
              {itemPutback.parentPalletInfo.orderableName}
            </span>
            <span />
          </div>
        </div>
        <div className={classes.lastRow}>
          <div className={classes.leftSide}>
            <div className={classes.statusRow}>
              {!itemPutback.droppedOff && !itemPutback.cancelled && (
                <span
                  className={classNames(classes.timestamp, {
                    [classes.expiredTime]: expiredTime,
                  })}
                >
                  {msToHrMin(elapsedTime)}
                </span>
              )}
            </div>
            {itemPutback.droppedOff && (
              <span className={classes.timestamp}>
                {requestDate(itemPutback.formattedDropOffTime)}{' '}
                {requestTime(itemPutback.formattedDropOffTime)}
              </span>
            )}
            {itemPutback.cancelled && (
              <span
                className={classNames(classes.timestamp, {
                  [classes.expiredTime]: itemPutback.cancelled,
                })}
              >
                Closed by{' '}
                {`${getFirstAndLastInitials(
                  itemPutback.cancelledBy
                )} - ${requestDate(itemPutback.cancelledAtTime)} ${requestTime(
                  itemPutback.cancelledAtTime
                )}
               `}
              </span>
            )}
          </div>
          <div className={classes.rightSide}>
            {itemPutback.runner && (
              <Chip
                className={classNames(classes.chip, {
                  [classes.myChip]: itemPutback.runner === signedInUser,
                })}
                label={getFirstAndLastInitials(itemPutback.runner)}
              />
            )}
          </div>
        </div>
      </div>
    </ListItemLink>
  );
};

ItemPutbackRow.propTypes = {
  itemPutback: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  last: PropTypes.bool.isRequired,
  signedInUser: PropTypes.string.isRequired,
  historical: PropTypes.bool,
};

ItemPutbackRow.defaultProps = {
  historical: false,
};

export default ItemPutbackRow;
