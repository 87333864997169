import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import withStyles from '@mui/styles/withStyles';

import { StatefulButton } from 'components/shared';
import { WAREHOUSE_AUDIT_ACTIONS } from 'lib/constants';

import styles from './styles';

const PalletDetailsAuditButton = ({
  auditing,
  auditFailed,
  onSetConfirmLocation,
  onSetIssuedToProduction,
  onSetUnknownLocation,
  fetchPallet,
  palletId,
  classes,
}) => {
  const auditButtonText = {
    DEFAULT: 'Audit',
    SUCCESS: 'Success!',
    LOADING: 'Saving',
    ERROR: 'Failed',
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAuditOption, setSelectedAuditOption] = useState({});

  const handleDialogClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      setSelectedAuditOption({});
    }, 300);
  };

  const executeAuditAction = async (auditActionType) => {
    switch (auditActionType) {
      case WAREHOUSE_AUDIT_ACTIONS[0].type:
        await onSetConfirmLocation(palletId, true);
        break;
      case WAREHOUSE_AUDIT_ACTIONS[1].type:
        await onSetIssuedToProduction(palletId);
        break;
      case WAREHOUSE_AUDIT_ACTIONS[2].type:
        await onSetUnknownLocation(palletId);
        break;
      default:
        break;
    }

    fetchPallet(palletId);

    handleDialogClose();
  };

  return (
    <Fragment>
      <StatefulButton
        type="button"
        onClick={handleDialogClick}
        buttonTextOptions={auditButtonText}
        loading={auditing}
        failed={auditFailed}
        classes={{ root: classes.button }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        classes={{ paperScrollPaper: classes.muiPaper }}
      >
        {Object.keys(selectedAuditOption).length === 0 && (
          <Fragment>
            <DialogContent classes={{ root: classes.promptContainer }}>
              <DialogContentText classes={{ root: classes.promptText }}>
                Select Action:
              </DialogContentText>
            </DialogContent>
            <DialogContent classes={{ root: classes.auditOptionsContainer }}>
              {WAREHOUSE_AUDIT_ACTIONS.map((option) => (
                <Button
                  key={option.type}
                  classes={{ root: classes.modalButtons }}
                  onClick={() => executeAuditAction(option.type)}
                  color="neutral"
                >
                  {option.type}
                </Button>
              ))}
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  );
};

PalletDetailsAuditButton.propTypes = {
  classes: PropTypes.object.isRequired,
  palletId: PropTypes.number.isRequired,
  auditing: PropTypes.bool.isRequired,
  auditFailed: PropTypes.bool.isRequired,
  onSetConfirmLocation: PropTypes.func.isRequired,
  onSetIssuedToProduction: PropTypes.func.isRequired,
  onSetUnknownLocation: PropTypes.func.isRequired,
  fetchPallet: PropTypes.func.isRequired,
};

export default withStyles(styles)(PalletDetailsAuditButton);
