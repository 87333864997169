import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { updateItemMoveRequest } from 'redux/itemMoveRequests/actions';

import styles from '../styles';
import ChangeDropDialog from './ChangeDropDialog';

const mapStateToProps = (state) => ({
  podLocations: state.podLocations.pods.filter((pod) => pod.storageSlotId),
  updating: state.itemMoveRequests.updating,
  updatingFailed: state.itemMoveRequests.updatingFailed,
});

const mapDispatchToProps = {
  onUpdateItemMoveRequest: updateItemMoveRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ChangeDropDialog)
);
