import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';

import { selectPortioningProgressByIngredient } from 'redux/meal/selectors';
import { INGREDIENT_TYPE } from 'redux/meal/reducers';
import { toggleMeal } from 'redux/meal/actions';

import IngredientPanel from './IngredientPanel';
import styles from './styles';

const PortioningByIngredient = ({
  ingredients,
  expandedItems,
  onToggleClick,
  classes,
}) => {
  return (
    <div data-testid="portioning-by-ingredient" className={classes.container}>
      {sortBy(ingredients, (set) => [
        set[0].name,
        set[0].quantity,
        set[0].unit,
      ]).map((ingredientSet) => {
        const panelIngredient = ingredientSet[0];
        const panelCompletedCount = sumBy(
          ingredientSet,
          'portioning_completed_count'
        );
        const totalGoalCount =
          panelCompletedCount + sumBy(ingredientSet, 'portioning_remaining');
        const expansionPayload = {
          type: INGREDIENT_TYPE,
          id: `${panelIngredient.ingredientId} - ${panelIngredient.quantity} ${panelIngredient.unit}`,
        };

        return (
          <IngredientPanel
            key={panelIngredient.ingredientId}
            ingredients={ingredientSet}
            expanded={Boolean(find(expandedItems, expansionPayload))}
            onToggleClick={() => onToggleClick(expansionPayload)}
            totalCompletedCount={panelCompletedCount}
            totalGoalCount={totalGoalCount}
            percentTarget={panelIngredient.end_of_shift_portioning_target}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ingredients: selectPortioningProgressByIngredient(state.meals),
  expandedItems: state.meals.expandedPanels,
});

const mapDispatchToProps = {
  onToggleClick: toggleMeal,
};

PortioningByIngredient.propTypes = {
  ingredients: PropTypes.object.isRequired,
  expandedItems: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PortioningByIngredient));
