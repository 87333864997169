export default {
  iconButton: {
    padding: '0px',
  },
  justifyHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 5px',
  },
  header: {
    fontSize: '20px',
    fontFamily: 'Muli',
  },
  contentContainer: {
    fontFamily: 'Muli',
    overflow: 'auto',
  },
  largeScreen: {
    height: '518px',
    width: '400px',
  },
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '50px',
  },
};
