import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';

import { StatefulButton } from 'components/shared';
import {
  COMPLETE_ACTION,
  FAIL_ACTION,
  ON_HOLD_ACTION,
  RELEASE_HOLD_ACTION,
  PRINT_ACTION,
  DECANT_ACTION,
} from 'lib/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

const ActionButtons = ({
  selectedAction,
  setSelectedAction,
  isFetching = false,
  hasFailed = false,
  allowPrint = false,
  isOnHold = false,
  isCompleted = false,
  allowDecanting = false,
}) => {
  const classes = useStyles();
  const [lastSelectedAction, setLastSelectedAction] = useState('');

  useEffect(() => {
    selectedAction && setLastSelectedAction(selectedAction);
  }, [selectedAction]);

  const defaultButtonText = {
    [COMPLETE_ACTION]: allowPrint ? 'Complete + Print Labels' : 'Complete',
    [ON_HOLD_ACTION]: 'Place On Hold',
    [RELEASE_HOLD_ACTION]: 'Release Hold',
    [FAIL_ACTION]: 'Record Failure',
    [DECANT_ACTION]: 'Decant',
  };

  const getButtonProps = (action) => {
    const isSelectedAction = selectedAction === action;

    const holdActions = [ON_HOLD_ACTION, RELEASE_HOLD_ACTION];
    const isUpdateAction = [
      ON_HOLD_ACTION,
      RELEASE_HOLD_ACTION,
      FAIL_ACTION,
    ].includes(action);

    const isCurrentlyClicked = holdActions.includes(action)
      ? isUpdateAction && holdActions.includes(lastSelectedAction)
      : isUpdateAction && lastSelectedAction == action;

    return {
      classes: { root: classes.actionButton },
      type: 'button',
      size: 'large',
      disabled: isFetching || isOnHold,
      buttonTextOptions: {
        DEFAULT: defaultButtonText[action],
        SUCCESS: 'Success!',
        LOADING: 'SAVING...',
        ERROR: 'ERROR - Try Again',
      },
      onClick: () => setSelectedAction(action),
      variant: isSelectedAction ? 'contained' : 'outlined',
      loading: isFetching,
      failed: hasFailed,
      currentClicked: isSelectedAction || isCurrentlyClicked,
    };
  };

  return (
    <>
      {isCompleted && allowPrint && (
        <Button
          data-testid="print-button"
          classes={{ root: classes.actionButton }}
          type="button"
          variant="outlined"
          onClick={() => setSelectedAction(PRINT_ACTION)}
        >
          Print Labels
        </Button>
      )}
      {!isCompleted && (
        <StatefulButton
          data-testid="complete-button"
          {...getButtonProps(COMPLETE_ACTION)}
        />
      )}
      {!isCompleted &&
        (!isOnHold ? (
          <StatefulButton
            data-testid="on-hold-button"
            {...getButtonProps(ON_HOLD_ACTION)}
            color="info"
          />
        ) : (
          <StatefulButton
            data-testid="release-hold-button"
            {...getButtonProps(RELEASE_HOLD_ACTION)}
            color="info"
            variant="contained"
            disabled={false}
          />
        ))}
      <StatefulButton
        data-testid="fail-button"
        {...getButtonProps(FAIL_ACTION)}
        color="warning"
      />
      {isCompleted && allowDecanting && (
        <StatefulButton
          data-testid="decant-button"
          {...getButtonProps(DECANT_ACTION)}
          color="tertiary"
        >
          Decant
        </StatefulButton>
      )}
    </>
  );
};

ActionButtons.propTypes = {
  selectedAction: PropTypes.string.isRequired,
  setSelectedAction: PropTypes.func.isRequired,
  allowPrint: PropTypes.bool,
  isOnHold: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

ActionButtons.defaultTypes = {
  isFetching: false,
  isFailed: false,
  allowPrint: false,
  isOnHold: false,
  isCompleted: false,
};

export default ActionButtons;
