import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useBins from 'lib/custom_hooks/useBins';

import styles from './styles';

const useStyles = makeStyles(styles);

const BinActions = ({ bin }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [weight, setWeight] = useState(bin.weightInLbs);

  const { error, fetching, onDeleteBin, onUpdateBin } = useBins();

  const handleEdit = () => {
    if (!bin.inUse) {
      setEditing(true);
    }
  };

  const handleUpdate = async () => {
    const response = await onUpdateBin({
      binId: bin.id,
      binParams: { weightInLbs: weight },
    });

    if (response.ok) {
      setEditing(false);
    }
  };

  const renderEditableWeight = () => {
    if (editing) {
      return (
        <>
          <TextField
            autoFocus
            error={error != ''}
            helperText={error}
            inputProps={{
              className: classes.input,
              min: '0',
              'data-testid': 'new-weight-input',
            }}
            label="lbs."
            onChange={(e) => {
              setWeight(e.target.value);
            }}
            size="small"
            type="number"
            value={weight}
          />
          <Button
            className={classes.saveButton}
            color="primary"
            disabled={weight < 0 || fetching}
            onClick={handleUpdate}
            variant="text"
          >
            Save
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Typography>{bin.weightInLbs} lbs.</Typography>
          <EditIcon
            className={classNames({
              [classes.editDisabled]: bin.inUse,
              [classes.actionIcon]: !bin.inUse,
            })}
            onClick={handleEdit}
          />
        </>
      );
    }
  };

  return (
    <Grid container className={classes.row}>
      <Grid item className={classes.gridItem} xs={4}>
        <Typography>ID {bin.id}</Typography>
      </Grid>
      <Grid item className={classes.gridItem} xs={4}>
        {renderEditableWeight()}
      </Grid>
      <Grid item className={classes.gridItem} xs={4}>
        {bin.inUse ? (
          <Typography className={classes.warning}>IN USE</Typography>
        ) : (
          <DeleteIcon
            className={classes.actionIcon}
            onClick={() => onDeleteBin(bin.id)}
          />
        )}
      </Grid>
    </Grid>
  );
};

BinActions.propTypes = {
  bin: PropTypes.object.isRequired,
};

export default BinActions;
