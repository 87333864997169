import { GREY_200, GREY_800 } from 'lib/constants';

export default {
  root: {
    position: 'absolute',
    top: '115px',
    right: '10px',
    height: '45px',
  },
  button: {
    borderRadius: '4px',
    width: '45px',
    display: 'inline-block',
    background: GREY_800,
    '&:hover': {
      backgroundColor: GREY_200,
    },
  },
};
