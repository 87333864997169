import { connect } from 'react-redux';

import { selectItemMoveRequestsBucketedForWarehouseMoves } from 'redux/itemMoveRequests/selectors';
import { fetchHistoricals } from 'redux/itemMoveRequests/actions';

import InProgressRequests from './InProgressRequests';

const mapStateToProps = (state) => {
  const {
    inProgress,
    completed,
  } = selectItemMoveRequestsBucketedForWarehouseMoves(state);

  return {
    inProgressRequests: inProgress || [],
    completedRequests: completed || [],
    fetching: state.itemMoveRequests.fetching,
    signedInUser: state.session.user_name,
    fetchingHistoricals: state.itemMoveRequests.fetchingHistoricals,
    fetchingHistoricalsFailed: state.itemMoveRequests.fetchingHistoricalsFailed,
    palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
  };
};

const mapDispatchToProps = {
  onFetchHistoricals: fetchHistoricals,
};

export default connect(mapStateToProps, mapDispatchToProps)(InProgressRequests);
