import {
  COUNT_LIST_SUCCESS,
  COUNT_LIST_REQUEST,
  COUNT_LIST_ERROR,
  COUNT_LISTS_SUCCESS,
  COUNT_LISTS_REQUEST,
  COUNT_LISTS_ERROR,
  COUNT_LIST_CREATE_REQUEST,
  COUNT_LIST_CREATE_SUCCESS,
  COUNT_LIST_CREATE_ERROR,
  COUNT_LIST_UPDATE_REQUEST,
  COUNT_LIST_UPDATE_REQUEST_ERROR,
  COUNT_LIST_UPDATE_REQUEST_SUCCESS,
  COUNT_LIST_DELETE_REQUEST,
  COUNT_LIST_DELETE_REQUEST_ERROR,
  COUNT_LIST_DELETE_REQUEST_SUCCESS,
  UPDATE_PALLET_LOCATION_REQUEST,
  UPDATE_PALLET_LOCATION_SUCCESS,
  UPDATE_PALLET_LOCATION_ERROR,
  CLEAR_CYCLE_COUNT_INFO,
  REFRESH_COUNT_LIST_REQUEST,
  REFRESH_COUNT_LIST_REQUEST_SUCCESS,
  REFRESH_COUNT_LIST_REQUEST_ERROR,
} from 'lib/constants';

const initialState = {
  fetching: false,
  failed: false,
  actionResult: {},
  countList: {
    storageSlots: [],
  },
  selectedPallet: {},
  countLists: [],
  createRequesting: false,
  createSuccess: false,
  createError: false,
  updateFetching: false,
  updateError: false,
  updateSuccess: false,
  deleteError: false,
  deleteSuccess: false,
  refreshCountListFetching: false,
  refreshCountListError: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case COUNT_LISTS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case COUNT_LISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        countLists: action.response.countLists,
      };
    case COUNT_LISTS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case COUNT_LIST_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case COUNT_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        countList: action.response.countList,
      };
    case COUNT_LIST_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case COUNT_LIST_CREATE_REQUEST:
      return {
        ...state,
        createRequesting: true,
        createError: false,
        createSuccess: false,
      };
    case COUNT_LIST_CREATE_SUCCESS:
      return {
        ...state,
        createRequesting: false,
        createError: false,
        createSuccess: true,
      };
    case COUNT_LIST_CREATE_ERROR:
      return {
        ...state,
        createRequesting: false,
        createError: true,
        createSuccess: false,
      };
    case COUNT_LIST_UPDATE_REQUEST:
      return {
        ...state,
        updateSaving: true,
        updateError: false,
        updateSuccess: false,
      };
    case COUNT_LIST_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        updateSaving: false,
        updateError: false,
        updateSuccess: true,
      };
    case COUNT_LIST_UPDATE_REQUEST_ERROR:
      return {
        ...state,
        updateSaving: false,
        updateError: true,
        updateSuccess: false,
      };
    case COUNT_LIST_DELETE_REQUEST:
      return {
        ...state,
        deleteError: false,
        deleteSuccess: false,
      };
    case COUNT_LIST_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        deleteError: false,
        deleteSuccess: true,
      };
    case COUNT_LIST_DELETE_REQUEST_ERROR:
      return {
        ...state,
        deleteError: true,
        deleteSuccess: false,
      };
    case UPDATE_PALLET_LOCATION_REQUEST:
      return {
        ...state,
        updateSaving: true,
        updateError: false,
        updateSuccess: false,
      };
    case UPDATE_PALLET_LOCATION_SUCCESS:
      return {
        ...state,
        selectedPallet: action.response.pallet,
        updateSaving: false,
        updateError: false,
        updateSuccess: true,
      };
    case UPDATE_PALLET_LOCATION_ERROR:
      return {
        ...state,
        updateSaving: false,
        updateError: true,
        updateSuccess: false,
      };
    case REFRESH_COUNT_LIST_REQUEST:
      return {
        ...state,
        refreshCountListFetching: true,
      };
    case REFRESH_COUNT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        refreshCountListFetching: false,
        refreshCountListError: false,
        countList: action.response.countList,
      };
    case REFRESH_COUNT_LIST_REQUEST_ERROR:
      return {
        ...state,
        refreshCountListFetching: false,
        refreshCountListError: true,
      };
    case CLEAR_CYCLE_COUNT_INFO:
      return initialState;
    default:
      return state;
  }
}
