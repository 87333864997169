import { HC_GREEN, WHITE, DARK_HC_GREEN, BLACK } from 'lib/constants';

export default {
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
  },
  button: {
    marginLeft: 0,
    margin: '5px !important',
    display: 'inline-block',
    fontSize: '16px',
    color: BLACK,
    width: '95px',
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
};
