import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import CycleCountingList from './CycleCountingList';
import CountListCreate from './CreateCountList';
import CountLists from './CountLists';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const CycleCounting = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/cycle-counting/new"
          component={() => <CountListCreate />}
        />
        <Route
          exact
          path="/cycle-counting/:id"
          render={() => <CycleCountingList />}
        />
        <Route exact path="/cycle-counting" component={() => <CountLists />} />
      </Switch>
    </Fragment>
  );
};

CycleCounting.propTypes = {};

export default CycleCounting;
