import React from 'react';
import PropTypes from 'prop-types';

const InventoryIcon = (props) => {
  const { className = 'InventoryIcon' } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="-5 -5 100 100"
      >
        <g fill="#009F32" fillRule="evenodd">
          <rect x="78.26" y="47.23" width="11.83" height="11.83" />
          <path d="M90,0H47.27V42.72H90ZM52.81,37.19V5.53H84.46V37.19Z" />
          <rect x="60.22" y="12.94" width="16.83" height="16.83" />
          <rect x="12.94" y="60.22" width="16.83" height="16.83" />
          <polygon points="78.26 78.01 69.43 78.01 69.43 57.35 59.1 57.35 59.1 47.23 47.27 47.23 47.27 59.06 57.6 59.06 57.6 78.01 47.27 78.01 47.27 89.83 59.1 89.83 59.1 79.51 67.93 79.51 67.93 89.83 90.09 89.83 90.09 67.68 78.26 67.68 78.26 78.01" />
          <rect x="12.94" y="12.94" width="16.83" height="16.83" />
          <path d="M0,42.72H42.72V0H0ZM37.19,5.53V37.19H5.53V5.53Z" />
          <path d="M0,90H42.72V47.27H0ZM37.19,52.81V84.46H5.53V52.81Z" />
        </g>
      </svg>
    </div>
  );
};

InventoryIcon.propTypes = {
  className: PropTypes.string,
};

InventoryIcon.defaultProps = {
  className: 'InventoryIcon',
};

export default InventoryIcon;
