import { drawerStyling } from 'lib/styles';
import { GREY_200, GREY_800 } from 'lib/constants';

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  ...drawerStyling,
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 20px',
    borderBottom: `1px solid ${GREY_200}`,
    fontWeight: 'bold',
    fontSize: 20,
    color: GREY_800,
  },
  bodySection: {
    overflowY: 'auto',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: 20,
    height: 20,
    margin: '0 auto',
  },
  noScroll: {
    // overflowY: 'hidden', //not sure if I need this yet.
  },
};
