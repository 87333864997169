import { GET_PRODUCTION_SUBMISSIONS } from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import ProductionSubmissions from './services';

export const getProductionSubmissions = (queryObject) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: GET_PRODUCTION_SUBMISSIONS,
      method: ProductionSubmissions.mostRecent,
      params: queryObject,
    })
  );
};
