import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { useDebounce } from 'lib/custom_hooks';
import { ESCAPE_KEY } from 'lib/constants';

const Search = ({ classes, onSearch }) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    if (searchBarOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchBarOpen]);

  const delayedSearch = useDebounce((searchTerm) => onSearch(searchTerm), 300);

  useEffect(() => {
    if (searchValue.length === 0) {
      delayedSearch('');
    }

    if (searchValue.length > 0) {
      delayedSearch(searchValue);
    }
  }, [searchValue, delayedSearch]);

  const handleCloseClick = () => {
    setSearchBarOpen(false);
    setSearchValue('');
    onSearch('');
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const useEscape = (onEscape) => {
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.keyCode === ESCAPE_KEY) onEscape();
      };

      window.addEventListener('keydown', handleEsc);

      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }, [onEscape]);
  };

  useEscape(() => {
    setSearchBarOpen(false);
    setSearchValue('');
    onSearch('');
  });

  return (
    <div>
      {searchBarOpen && (
        <TextField
          variant="standard"
          data-testid="text-field"
          onChange={handleSearchChange}
          className={classes.input}
          value={searchValue}
          inputRef={inputRef}
          inputProps={{ name: '', 'data-testid': 'search-input' }}
        />
      )}
      {searchBarOpen ? (
        <IconButton
          className={classes.closeButton}
          onClick={handleCloseClick}
          size="large"
        >
          <CloseIcon data-testid="close-icon" />
        </IconButton>
      ) : (
        <IconButton
          className={classes.closeButton}
          onClick={() => setSearchBarOpen(true)}
          size="large"
        >
          <SearchIcon data-testid="search-icon" />
        </IconButton>
      )}
    </div>
  );
};

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default Search;
