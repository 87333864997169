import { WHITE, GREY_650 } from 'lib/constants';

export default {
  metaDataTableHeader: {
    color: WHITE,
    background: GREY_650,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
};
