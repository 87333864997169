import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getLoginFields } from 'redux/loginField/actions';
import { createSession } from 'redux/session/actions';
import { Loading } from 'components/shared';

import Login from './Login';
import styles from './styles';

export const LoginContainer = ({
  classes,
  onSubmitLoginForm,
  onGetLoginFields,
  shifts,
  roles,
  facilities,
  sessionFailed,
  sessionPosting,
}) => {
  useEffect(() => {
    onGetLoginFields();
  }, [onGetLoginFields]);
  if (roles.length === 0 || facilities.length === 0) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  }
  return (
    <Login
      classes={classes}
      onSubmitLoginForm={onSubmitLoginForm}
      shifts={shifts}
      roles={roles}
      facilities={facilities}
      sessionFailed={sessionFailed}
      sessionPosting={sessionPosting}
    />
  );
};

LoginContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  shifts: PropTypes.array.isRequired,
  sessionFailed: PropTypes.bool.isRequired,
  sessionPosting: PropTypes.bool.isRequired,
  onSubmitLoginForm: PropTypes.func.isRequired,
  onGetLoginFields: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  facilities: state.loginFields.facilities,
  roles: state.loginFields.roles,
  shifts: state.loginFields.shifts,
  sessionFailed: state.session.failed,
  sessionPosting: state.session.posting,
});

const mapDispatchToProps = {
  onSubmitLoginForm: createSession,
  onGetLoginFields: getLoginFields,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
);
