import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import every from 'lodash/every';

import { StatefulButton } from 'components/shared';
import {
  DEFECTS,
  HAS_DEFECTS,
  NUMBER_DEFECTIVE,
  NUMBER_SAMPLED,
  REJECTED_QUANTITY,
  FINAL_QUANTITY,
  FULL_CHECK_COMPLETED,
  LOT_CODES,
} from 'lib/constants';

import DefectsQuestion from '../../DefectsQuestion';
import LotCodeQuestions from '../../LotCodeQuestions';
import QuantityQuestion from '../../QuantityQuestion';
import { validationReducer, useValidatorCallback } from '../formHelpers';

const DEFECTS_VALID = 'DEFECTS_VALID';
const LOT_CODES_VALID = 'LOT_CODES_VALID';
const REJECTED_QUANTITY_VALID = 'REJECTED_QUANTITY_VALID';

const defaultValidationState = {
  [DEFECTS_VALID]: null,
  [LOT_CODES_VALID]: null,
  [REJECTED_QUANTITY_VALID]: null,
};

const PackagingForm = ({
  closeDrawer,
  pallet,
  classes,
  formState,
  onAddToForm,
  onSubmitForm,
  submitLoading,
  submitFailed,
}) => {
  const [validationState, validationDispatch] = useReducer(
    validationReducer,
    defaultValidationState
  );

  const createValueCallback = (field) => (value) => {
    onAddToForm(field, value);
  };

  const formValid = useMemo(() => every(Object.values(validationState)), [
    validationState,
  ]);

  const showSubmitButton = useMemo(
    () =>
      !formState.finalized ||
      formState[LOT_CODES]?.length > formState.savedLotCodes?.length,
    [formState, pallet.status]
  );

  const onSubmit = () => {
    if (formValid) {
      onSubmitForm();
    }
  };

  const lotCodesValidator = useValidatorCallback(
    LOT_CODES_VALID,
    validationDispatch
  );

  const defectsValidator = useValidatorCallback(
    DEFECTS_VALID,
    validationDispatch
  );
  const rejectedQuantityValidator = useValidatorCallback(
    REJECTED_QUANTITY_VALID,
    validationDispatch
  );

  return (
    <div className={classes.bodySection}>
      <div className={classes.topInfo}>
        <dl>
          <dt className={classes.infoLabel} id="item">
            ITEM
          </dt>
          <dd className={classes.infoValue}>{pallet.item}</dd>
          <dt className={classes.infoLabel} id="vendor">
            VENDOR
          </dt>
          <dd className={classes.infoValue}>{pallet.vendorName}</dd>
        </dl>
      </div>
      <LotCodeQuestions
        palletId={pallet.id}
        updateLotCodes={createValueCallback(LOT_CODES)}
        lotCodes={formState[LOT_CODES]}
        validationCallback={lotCodesValidator}
      />
      <div
        className={classNames({
          [classes.finalized]: pallet.status === FULL_CHECK_COMPLETED,
        })}
      >
        <DefectsQuestion
          hasDefects={formState[HAS_DEFECTS]}
          defects={formState[DEFECTS]}
          numberDefective={formState[NUMBER_DEFECTIVE]}
          numberSampled={formState[NUMBER_SAMPLED]}
          palletId={pallet.id}
          categoryId={pallet.itemCategoryId}
          validationCallback={defectsValidator}
        />
        <QuantityQuestion
          initialQuantity={
            formState.status === FULL_CHECK_COMPLETED
              ? pallet.quantity + formState[REJECTED_QUANTITY]
              : pallet.quantity
          }
          unitType={pallet.measuringUnit}
          numberRejected={formState[REJECTED_QUANTITY] || 0}
          updateRejectedQuantity={createValueCallback(REJECTED_QUANTITY)}
          updateFinalQuantity={createValueCallback(FINAL_QUANTITY)}
          validationCallback={rejectedQuantityValidator}
        />
      </div>
      <div className={classes.buttonsContainer}>
        {showSubmitButton && (
          <StatefulButton
            type="submit"
            fullWidth
            disabled={!formValid}
            onClick={onSubmit}
            loading={submitLoading}
            failed={submitFailed}
            classes={{
              disabled: classes.buttonDisabled,
              root: classes.submitButton,
            }}
            successCallback={closeDrawer}
          >
            Submit
          </StatefulButton>
        )}
      </div>
    </div>
  );
};

PackagingForm.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  pallet: PropTypes.shape({
    item: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    measuringUnit: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    brand: PropTypes.string,
    id: PropTypes.number.isRequired,
    orderableType: PropTypes.string.isRequired,
    itemCategoryId: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  onAddToForm: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  netsuiteItem: PropTypes.shape({
    quantity: PropTypes.number,
    id: PropTypes.number,
    measuringUnitId: PropTypes.number,
    defaultPrinterDescription: PropTypes.string,
    overageLimit: PropTypes.number,
    needOverageApproval: PropTypes.bool,
  }),
};

PackagingForm.defaultProps = {
  netsuiteItem: {},
};

export default PackagingForm;
