import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { hasValue, useValidator } from '../FsqaReviewForms/formHelpers';
import styles from './styles';

const BrandQuestion = ({
  classes,
  adjustedBrand,
  brandCorrect,
  updateBrandCorrect,
  updateAdjustedBrand,
  palletBrand,
  validationCallback,
}) => {
  const [adjustedBrandMatch, setAdjustedBrandMatch] = useState(false);

  useEffect(() => {
    const matchingAdjustedBrand =
      palletBrand &&
      adjustedBrand &&
      palletBrand.toLowerCase().trim() === adjustedBrand.toLowerCase().trim();

    setAdjustedBrandMatch(matchingAdjustedBrand);
  }, [adjustedBrand, palletBrand]);

  useValidator(
    brandCorrect || (hasValue(adjustedBrand) && !adjustedBrandMatch),
    validationCallback
  );

  const onYesClick = () => {
    updateBrandCorrect(true);
    updateAdjustedBrand(null);
  };

  return (
    <Fragment>
      <span className={classes.infoLabel}>
        {'IS THE BRAND '}
        <strong>{`${palletBrand || 'BLANK'}`}</strong>
        {'?'}
      </span>
      <div className={classes.infoValue}>
        <Button
          className={classNames(classes.button, {
            [classes.selectedYes]: brandCorrect === true,
          })}
          variant="outlined"
          color="neutral"
          onClick={onYesClick}
        >
          Yes
        </Button>
        <Button
          className={classNames(classes.button, {
            [classes.selectedNo]: brandCorrect === false,
          })}
          variant="outlined"
          color="neutral"
          onClick={() => updateBrandCorrect(false)}
        >
          No
        </Button>
        {brandCorrect === false && (
          <TextField
            required={brandCorrect === false}
            label="Brand"
            value={adjustedBrand || ''}
            onChange={(e) => updateAdjustedBrand(e.target.value)}
            variant="outlined"
            margin="normal"
            className={classes.textField}
            InputLabelProps={{ required: false }}
          />
        )}
      </div>
      {adjustedBrandMatch && (
        <div className={classes.errorText}>
          {`Adjusted brand appears to match the above brand, ${palletBrand}. Please select "Yes" or
          enter different brand.`}
        </div>
      )}
    </Fragment>
  );
};

BrandQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  brandCorrect: PropTypes.bool,
  adjustedBrand: PropTypes.string,
  updateAdjustedBrand: PropTypes.func.isRequired,
  palletBrand: PropTypes.string,
  updateBrandCorrect: PropTypes.func.isRequired,
  validationCallback: PropTypes.func.isRequired,
};

BrandQuestion.defaultProps = {
  brandCorrect: null,
  palletBrand: '',
  adjustedBrand: '',
};

export default withStyles(styles)(BrandQuestion);
