import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

class Loading extends Component {
  classes() {
    const { type, classes, fullScreen } = this.props;

    return type === 'linear'
      ? {
          colorPrimary: classes.linearColorPrimary,
          barColorPrimary: classes.linearBarColorPrimary,
          root: fullScreen ? classes.fullScreenLinear : '',
        }
      : {
          colorPrimary: classes.circularColorPrimary,
          root: fullScreen ? classes.fullScreenCircular : '',
        };
  }

  render() {
    const { type } = this.props;

    return type === 'linear' ? (
      <LinearProgress classes={this.classes()} data-testid="loading-bar" />
    ) : (
      <CircularProgress classes={this.classes()} data-testid="loading-circle" />
    );
  }
}

Loading.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
};

Loading.defaultProps = {
  type: undefined,
  fullScreen: false,
};

export default withStyles(styles)(Loading);
