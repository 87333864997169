import { pusher } from 'services/pusher';
import { itemMoveRequestReceiver } from 'redux/itemMoveRequests/actions';

const subscribeToItemMoveRequests = () => {
  return async (dispatch, getState) => {
    const channel = pusher.subscribe(
      `itemMoveRequest_${getState().session.packing_facility_id}`
    );
    channel.bind('updateItemMove', (itemMoveRequest) => {
      dispatch(itemMoveRequestReceiver(itemMoveRequest));
    });
  };
};

const unsubscribeToItemMoveRequests = () => {
  return async (dispatch, getState) => {
    pusher.unsubscribe(
      `itemMoveRequest_${getState().session.packing_facility_id}`
    );
  };
};

export { subscribeToItemMoveRequests, unsubscribeToItemMoveRequests };
