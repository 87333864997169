import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MUISnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Snackbar = ({ open, message }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(open);
  useEffect(() => setIsSnackbarOpen(open), [open]);

  return (
    <MUISnackbar
      key={message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isSnackbarOpen}
      autoHideDuration={2000}
      onClose={() => setIsSnackbarOpen(false)}
    >
      <MuiAlert severity="error">{message}</MuiAlert>
    </MUISnackbar>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
};

Snackbar.defaultProps = {
  open: false,
  message: 'Error',
};

export default Snackbar;
