import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { QRCodeIcon } from 'components/icons';

import styles from './styles';

const ScanBanner = ({ disabled, text, classes }) => {
  return (
    <div
      className={classNames(classes.QRBanner, {
        [classes.greyBanner]: disabled,
      })}
    >
      <QRCodeIcon className={classes.icon} />
      {text}
    </div>
  );
};

ScanBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

ScanBanner.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(ScanBanner);
