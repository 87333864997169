import {
  WHITE,
  GREY_600,
  GREY_200,
  GREY_800,
  HC_GREEN,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

export default {
  centered: {
    margin: '50px auto',
    textAlign: 'center',
  },
  card: {
    margin: '5px',
  },
  header: {
    backgroundColor: GREY_800,
    color: WHITE,
    fontSize: '14px',
    padding: '1px 7px',
    marginTop: '5px',
    borderRadius: '5px 5px 0px 0px',
  },
  icon: {
    width: '25px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  scanText: {
    marginLeft: '10px',
    display: 'inline-block',
    fontFamilt: 'Muli',
    color: GREY_800,
  },
  scanCardContent: {
    padding: '10px',
    '&:last-child': {
      paddingBottom: '5px',
    },
  },
  // Above used for scan card, which will eventually be a seperate component
  ingName: {
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  lightGrey: {
    color: GREY_600,
  },
  textBold: {
    fontWeight: 'bold',
  },
  line: {
    color: GREY_200,
    opacity: '40%',
  },
  selectFields: {
    width: '45%',
    margin: '0px 5px',
  },
  smallerPadding: {
    padding: '10px 14px',
  },
  upperCard: {
    color: GREY_800,
    fontWeight: 'bold',
    padding: '5px 10px',
  },
  menuItem: {
    height: '15px',
  },
  circle: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    border: '1px black solid',
    verticalAlign: 'middle',
    margin: '0px 5px',
  },
  upDownIcon: {
    color: HC_GREEN,
  },
  qtyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '20px',
  },
  qtyBold: {
    fontWeight: 'bold',
  },
  qtyGrey: {
    marginLeft: '7px',
    color: GREY_600,
  },
  calculatorButton: {
    display: 'flex',
    marginLeft: 'auto',
    alignSelf: 'center',
  },
  actionsContainer: {
    display: 'contents',
    textAlign: 'center',
  },
  cardActionPadding: {
    padding: '5px 0px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    noStorageSlot: {
      margin: 0,
    },
    tryAgainContainer: {
      margin: 0,
      paddingTop: '10px',
    },
  },
  tryAgainButton: {
    border: `1px solid ${GREY_200}`,
    padding: '20px',
    fontSize: '16px',
    textTransform: 'none',
    borderRadius: '8px',
    backgroundColor: WHITE,
  },
};
