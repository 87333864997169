import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { RETAIL, RETAIL_CHANNEL, ECOMMERCE_CHANNEL } from 'lib/constants';
import {
  SubtractionInputs,
  PodLocationQuestion,
  InventorySubmitButton,
  FormContainer,
  MealBaggingBanner,
} from 'components/shared';
import { usePrevious } from 'lib/custom_hooks';

import {
  NumberEmployeesQuestion,
  ConfirmationDialog,
  TeamNumberQuestion,
} from '../formComponents';
import MealDataCard from './MealDataCard';
import styles from './styles';

const MealBagInventoryForm = ({
  menuSelection,
  podLocations,
  printers,
  onPostMealBagInventory,
  onGetIngredientAssignments,
  posting,
  errors,
  selectedMenu,
  history,
}) => {
  const bannerValue = menuSelection.banner;
  const [internalLocationName, setInternalLocationName] = useState('');
  const [peopleCount, setPeopleCount] = useState(undefined);
  const [teamNumber, setTeamNumber] = useState(undefined);
  const [mealBagCount, setMealBagCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  useEffect(() => {
    setFormIsValid(
      internalLocationName !== '' &&
        peopleCount > 0 &&
        peopleCount < 51 &&
        !isEmpty(teamNumber) &&
        isFinite(Number(mealBagCount))
    );
  }, [internalLocationName, peopleCount, mealBagCount, teamNumber]);
  const submissionData = {
    count: mealBagCount,
    people_count: peopleCount,
    team_number: teamNumber,
    facility_internal_location_name: internalLocationName,
    meal_id: menuSelection.mealId,
    menu_id: menuSelection.menuId,
    channel:
      menuSelection.mealPlan === RETAIL ? RETAIL_CHANNEL : ECOMMERCE_CHANNEL,
  };

  const prevPosting = usePrevious(posting);

  useEffect(() => {
    if (!posting && prevPosting && !errors) {
      history.push(
        `/production_inventory/meal_bagging/menu/${selectedMenu.id}`
      );
    }
  }, [
    errors,
    history,
    menuSelection.menuId,
    posting,
    prevPosting,
    selectedMenu.id,
  ]);

  useEffect(() => {
    if (menuSelection) {
      onGetIngredientAssignments(
        menuSelection.ingredientAssignmentIds,
        menuSelection.menuId
      );
    }
  }, [menuSelection, onGetIngredientAssignments]);

  if (menuSelection) {
    const title = `${menuSelection.letterAssignment} - ${menuSelection.title}`;

    return (
      <>
        <FormContainer>
          {bannerValue && <MealBaggingBanner text={bannerValue} />}
          <MealDataCard menuSelection={menuSelection} />
          <Card>
            <SubtractionInputs
              initialValue={menuSelection.remainingCount}
              subtractingValue={mealBagCount}
              updateSubtractingValue={(count) => setMealBagCount(count)}
              hasError={mealBagCount < 0}
              autoFocus
              inputType="text"
              text={{
                initialValueLabel: 'Initial',
                subtractingValueLabel: '# Meal Bags',
                resultLabel: 'Final',
              }}
            />
          </Card>
          <Card>
            <PodLocationQuestion
              podLocations={podLocations}
              internalLocationName={internalLocationName}
              setInternalLocationName={setInternalLocationName}
            />
          </Card>
          <Card>
            <TeamNumberQuestion
              teamNumber={teamNumber}
              setTeamNumber={setTeamNumber}
            />
          </Card>
          <Card>
            <NumberEmployeesQuestion
              peopleCount={peopleCount}
              setPeopleCount={setPeopleCount}
            />
          </Card>
          <InventorySubmitButton
            disabled={!formIsValid}
            count={mealBagCount}
            item="Meal Bag"
            setDialogOpen={setDialogOpen}
          />
          <ConfirmationDialog
            open={dialogOpen}
            activity="Meal Bagging"
            mealName={title}
            handleClose={() => setDialogOpen(false)}
            printers={printers}
            submissionData={submissionData}
            onSubmit={onPostMealBagInventory}
            posting={posting}
          />
        </FormContainer>
      </>
    );
  } else {
    return <span>No meal was selected.</span>;
  }
};

MealBagInventoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  posting: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  menuSelection: PropTypes.shape({
    remainingCount: PropTypes.number.isRequired,
    letterAssignment: PropTypes.string.isRequired,
    mealName: PropTypes.string,
  }).isRequired,
  podLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  onPostMealBagInventory: PropTypes.func.isRequired,
  onGetIngredientAssignments: PropTypes.func.isRequired,
};

MealBagInventoryForm.defaultPropts = {
  errors: null,
};

export default withRouter(withStyles(styles)(MealBagInventoryForm));
