import { makeApiRequest } from 'lib/api';
import {
  FSQA_PALLETS_GET,
  FSQA_SEARCH_PALLET,
  FSQA_RESET_SEARCH_PALLET,
  FSQA_ADD_PALLETS,
} from 'lib/constants';

import Pallets from '../services';

export const fetchQAPallets = (timeRange = null) => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: FSQA_PALLETS_GET,
        method: Pallets.qaApp.getPallets,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
          timeRange,
        },
      })
    );
  };
};

export const scanPalletId = (palletId) => ({
  type: FSQA_SEARCH_PALLET,
  palletId,
});

export const resetScannedPalletId = () => ({
  type: FSQA_RESET_SEARCH_PALLET,
  palletId: null,
});

export const addPallets = (pallets) => ({
  type: FSQA_ADD_PALLETS,
  pallets: pallets,
});
