import {
  TRUCK_POST_REQUEST,
  TRUCK_POST_SUCCESS,
  TRUCK_POST_ERROR,
  TRUCK_PUT_REQUEST,
  TRUCK_PUT_SUCCESS,
  TRUCK_PUT_ERROR,
  TRUCK_DRAWER_TOGGLE,
  SELECT_TRUCK,
  ADD_TRUCK,
  NETSUITE_ORDER_ITEMS_SUCCESS,
  PRESELECT_ORDER_FOR_TRUCK,
  CLEAR_PRESELECTED_TRUCK_ORDER,
} from 'lib/constants';

export const initialState = {
  posting: false,
  postFailed: false,
  updating: false,
  updateFailed: false,
  fetching: false,
  fetchFailed: false,
  selectedTruck: {},
  trucks: [],
  truckDrawerOpen: false,
  lastTruck: {},
  preselectedNOForForm: null,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case TRUCK_POST_REQUEST:
      return { ...state, posting: true, postFailed: false };
    case TRUCK_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        selectedTruck: action.response.truck,
        lastTruck: action.response.truck,
      };
    case TRUCK_POST_ERROR:
      return { ...state, posting: false, postFailed: true };
    case TRUCK_PUT_REQUEST:
      return {
        ...state,
        updating: true,
        updateFailed: false,
      };
    case TRUCK_PUT_SUCCESS:
      return {
        ...state,
        updating: false,
        trucks: state.trucks.map((truck) => {
          if (truck.id !== action.response.truck.id) {
            return truck;
          }
          return { ...truck, ...action.response.truck };
        }),
      };
    case TRUCK_PUT_ERROR:
      return { ...state, updating: false, updateFailed: true };
    case TRUCK_DRAWER_TOGGLE:
      return { ...state, truckDrawerOpen: action.toggleDrawer };
    case SELECT_TRUCK:
      return { ...state, selectedTruck: action.selectedTruck };
    case ADD_TRUCK:
      return {
        ...state,
        trucks: [...state.trucks, action.truck],
      };
    case NETSUITE_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        trucks: action.response.trucks,
      };
    case PRESELECT_ORDER_FOR_TRUCK:
      return {
        ...state,
        preselectedNOForForm: action.order,
      };
    case CLEAR_PRESELECTED_TRUCK_ORDER:
      return {
        ...state,
        preselectedNOForForm: null,
      };
    default:
      return state;
  }
}
