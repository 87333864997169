import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import classnames from 'classnames';

import { BooleanToggleButton } from 'components/shared';

const MealBagQuestions = ({
  isPackagingAcceptable,
  onChangePackagingErrorState,
  onChangePackagingIssues,
  packagingIssues,
  classes,
}) => {
  const PACKAGING_ISSUES = ['Zipper', 'Bag'];

  return (
    <>
      <BooleanToggleButton
        headerText="Is the Meal Bag packaging quality acceptable?"
        value={isPackagingAcceptable}
        onChange={(_, value) => onChangePackagingErrorState(value)}
        data-testid="mealBaggingQualityAcceptable"
        id="mealBaggingQualityAcceptable"
      />
      {isPackagingAcceptable === false && (
        <>
          <h3 className={classes.heading}>What are the issues?</h3>
          <ToggleButtonGroup
            className={classes.fourButtonGroup}
            size="large"
            value={packagingIssues}
            onChange={(_, value) => onChangePackagingIssues(value)}
          >
            {PACKAGING_ISSUES.map((issue) => (
              <ToggleButton
                key={issue}
                className={classnames([classes.buttonOne, classes.button])}
                value={issue}
              >
                {issue}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      )}
    </>
  );
};

MealBagQuestions.propTypes = {
  isPackagingAcceptable: PropTypes.bool,
  onChangePackagingErrorState: PropTypes.func.isRequired,
  onChangePackagingIssues: PropTypes.func.isRequired,
  packagingIssues: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

MealBagQuestions.defaultProps = {
  isPackagingAcceptable: null,
};

export default MealBagQuestions;
