import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getPalletHistories = async ({ palletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/pallet_histories`,
    options
  );

  return await handleResponse(response);
};

export default {
  getPalletHistories,
};
