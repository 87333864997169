import { ERROR_RED } from 'lib/constants';

export default {
  errorText: {
    color: ERROR_RED,
  },
  quantityLine: {
    display: 'block',
    marginTop: '10px',
  },
  bodyText: {
    fontFamily: 'Muli',
    margin: '16px 0px',
  },
  bold: {
    fontWeight: 800,
  },
  approvalFields: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: {
    fontFamily: 'Muli',
  },
  textField: {
    minWidth: '200px',
  },
};
