import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { StatefulButton } from 'components/shared';
import ReprintPalletDialog from 'components/shared/ReprintPalletDialog';

import styles from '../../styles';

const useStyles = makeStyles(styles);

const NoViablePalletsView = ({ onReprintPallet }) => {
  const classes = useStyles();

  const [showPrinterOptions, setShowPrinterOptions] = useState(false);

  let { itemPutback, parentPallet } = useSelector(
    (state) => state.guidedPutback
  );

  const reprintLicensePlateButtonText = {
    LOADING: 'Updating...',
    SUCCESS: 'Success',
    ERROR: 'There was an error submitting',
    DEFAULT: 'Reprint License Plate',
  };

  const reprintLicensePlateAction = () => {
    setShowPrinterOptions(!showPrinterOptions);
  };

  const reprintPallet = (selectedPalletIds, printer) => {
    onReprintPallet(selectedPalletIds[0], printer, itemPutback.id);
  };

  return (
    <Grid container spacing={2.5}>
      <Grid className={classes.palletIssueTitle} item xs={12}>
        Issue Reported! <Check className={classes.greenCheck} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.noViablePalletTitle}>
          <WarningAmberIcon
            className={classNames(classes.warningIcon, classes.orangeIcon)}
          />
          No Viable Pallets
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.lightText}>
          A pallet can not be found in the system for the requested item.
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.lightText}>Reprint License Plate to...</div>
      </Grid>

      <Grid item xs={12}>
        <StatefulButton
          loading={false}
          failed={false}
          buttonTextOptions={reprintLicensePlateButtonText}
          classes={{ root: classes.reprintLicensePlateButton }}
          autoFocus
          onClick={reprintLicensePlateAction}
        />
      </Grid>
      <ReprintPalletDialog
        showPrinterOptions={showPrinterOptions}
        setShowPrinterOptions={setShowPrinterOptions}
        selectedPalletId={parentPallet.id}
        onReprintPallet={reprintPallet}
      />
    </Grid>
  );
};

NoViablePalletsView.propTypes = {
  onReprintPallet: PropTypes.func.isRequired,
};

export default NoViablePalletsView;
