import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getParentPallet = async ({ childPalletId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putbacks/${childPalletId}/parent_pallet`,
    options
  );

  return await handleResponse(response);
};

const getItemPutback = async ({ id }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putbacks/${id}`,
    options
  );

  return await handleResponse(response);
};

const getItemPutbacks = async () => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putbacks`,
    options
  );

  return await handleResponse(response);
};

const createItemPutback = async ({ childPalletId, pickupLocationId }) => {
  const response = await fetch(`${API_BASE_URI}/ops/warehouse/item_putbacks`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      item_putback: {
        child_pallet_id: childPalletId,
        pick_up_location_id: pickupLocationId,
      },
    }),
  });
  return await handleResponse(response);
};

const createItemPutbackException = async ({
  itemPutbackId,
  problemPalletId,
  reasonCode,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putback_exceptions`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        item_putback_exception: {
          item_putback_id: itemPutbackId,
          problem_pallet_id: problemPalletId,
          reason_code: reasonCode,
        },
      }),
    }
  );

  return await handleResponse(response);
};

const completeItemPutback = async ({
  itemPutbackId,
  changedItemPutbackValues,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putbacks/complete/${itemPutbackId}`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        item_putback: changedItemPutbackValues,
      }),
    }
  );

  return await handleResponse(response);
};

const cancelItemPutback = async ({ itemPutbackId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putbacks/cancel/${itemPutbackId}`,
    {
      ...options,
      method: 'PUT',
    }
  );

  return await handleResponse(response);
};

const printPallet = async ({ palletId, printer, itemPutbackId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/item_putback_exceptions/print_pallet`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        pallet_id: palletId,
        printer,
        item_putback_id: itemPutbackId,
      }),
    }
  );

  return await handleResponse(response);
};

export default {
  createItemPutback,
  cancelItemPutback,
  createItemPutbackException,
  completeItemPutback,
  getParentPallet,
  getItemPutbacks,
  getItemPutback,
  printPallet,
};
