import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormHelperText from '@mui/material/FormHelperText';

import isPast from 'date-fns/isPast';
import isEqual from 'date-fns/isEqual';
import {
  TIME_INTERVALS,
  TIME_HOURS,
  TIME_MINUTES,
  TIME_MERIDIEM,
} from 'lib/constants';
import { StatefulButton } from 'components/shared';
import { useDateTimeCalculator } from 'lib/custom_hooks';

const AppointmentSubmissionForm = ({
  classes,
  appointment,
  closeDrawer,
  threeWeeksOfNetsuiteOrderItems,
  onPostDockAppointment,
  onPutDockAppointment,
  onDeleteDockAppointment,
  posting,
  updating,
  errors,
  onStoreSearchQuery,
  clearSearch,
  searchResultsNOs,
  prefilledDate,
}) => {
  const THIRTY_MINUTES = 30 * 60 * 1000;

  // Rounds time up to nearest 30 minute interval
  const [selectedDateTime, setSelectedDateTime] = useState(
    appointment?.startTime ||
      new Date(
        Math.ceil(prefilledDate.getTime() / THIRTY_MINUTES) * THIRTY_MINUTES
      )
  );
  const [selectedPO, setSelectedPO] = useState(
    appointment?.poNumbers.join() || ''
  );
  const [selectedVendor, setSelectedVendor] = useState(
    appointment?.vendorNames.join() || ''
  );
  const [selectedDuration, setSelectedDuration] = useState(
    appointment?.duration || ''
  );

  const {
    displayHours,
    displayMinutes,
    displayMeridiem,
    setDisplayHours,
    setDisplayMinutes,
    setDisplayMeridiem,
    timeDropdownsFilled,
    validDateTime,
    calculatedDate,
  } = useDateTimeCalculator(selectedDateTime, appointment);

  const [memo, setMemo] = useState(appointment?.memo || '');
  const [dialogOpen, setDialogOpen] = useState(false);

  const poWas = appointment?.poNumbers[0];

  const appointmentInPast = useMemo(() => {
    if (!appointment) return false;

    const apptDate = new Date(appointment.startTime);
    const today = new Date();

    return isPast(apptDate) && !isEqual(apptDate.getDate(), today.getDate());
  }, [appointment]);

  const formComplete = () => {
    if (appointment) {
      return true;
    } else {
      return (
        selectedDateTime &&
        validDateTime &&
        timeDropdownsFilled &&
        selectedPO &&
        selectedDuration
      );
    }
  };

  const netsuiteOrdersToDisplay = useMemo(
    () =>
      selectedPO.length >= 3
        ? searchResultsNOs
        : threeWeeksOfNetsuiteOrderItems,
    [searchResultsNOs, selectedPO, threeWeeksOfNetsuiteOrderItems]
  );

  const handlePOInputChange = (e) => {
    // Making an assumption that all PO's will be upperCase.
    let query = e.target.value.toUpperCase();
    setSelectedPO(query);
    if (query.length >= 3) {
      onStoreSearchQuery(query);
    } else {
      clearSearch();
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // this const is used to determine if this is being created manually or through a CSV
    const isManualAppointment = true;
    const data = {
      local_start_time: calculatedDate,
      purchase_order: selectedPO,
      duration: selectedDuration,
      memo: memo,
    };

    if (appointment) {
      await onPutDockAppointment(
        [{ ...data, id: appointment.id, poWas: poWas }],
        isManualAppointment
      );
    } else {
      await onPostDockAppointment([data], isManualAppointment);
    }
  };

  const handleDeleteAppointment = () => {
    onDeleteDockAppointment(appointment.id);
    setDialogOpen(false);
    closeDrawer();
  };

  const buttonText = {
    DEFAULT: appointment ? 'Save Changes' : 'Submit',
    SUCCESS: 'Success!',
    LOADING: 'Submitting...',
    ERROR: 'Failed - Try Again?',
  };

  return (
    <Fragment>
      <form autoComplete="off" className={classes.form}>
        <div className={classes.infoValue}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <header className={classes.header}>DATE</header>
            <MobileDatePicker
              disableToolbar
              disablePast
              format="EEEE - MM/dd/yyyy"
              value={selectedDateTime}
              onChange={setSelectedDateTime}
              className={classes.datePicker}
              disabled={appointmentInPast}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className={classes.infoValue}>
          <header className={classes.header}>PO</header>
          <TextField
            fullWidth
            id="searchAndSelect"
            name="POField"
            variant="outlined"
            placeholder="Search and Select a PO below..."
            value={selectedPO}
            onChange={handlePOInputChange}
            inputProps={{ className: classes.input }}
            disabled={appointmentInPast}
          />
          {netsuiteOrdersToDisplay.length > 0 && (
            <List className={classes.list} component="nav">
              {netsuiteOrdersToDisplay.map((po) => (
                <ListItem
                  key={po.id}
                  button
                  onClick={() => {
                    setSelectedPO(po.netsuiteOrderNumber);
                    setSelectedVendor(po.vendor);
                  }}
                  className={classNames(classes.listItem, {
                    [classes.selected]: po.netsuiteOrderNumber === selectedPO,
                  })}
                >
                  <ListItemText className={classes.listItemTextNetsuite}>
                    <div className={classes.text}>{po.netsuiteOrderNumber}</div>
                  </ListItemText>
                  <ListItemText className={classes.listItemTextDate}>
                    <div className={classes.text}>{po.expectedDate}</div>
                  </ListItemText>
                  <ListItemText className={classes.listItemTextVendor}>
                    <div className={classes.text}>{po.vendor}</div>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <div className={classes.infoValue}>
          <header className={classes.header}>VENDOR</header>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Select a PO to Show Vendor"
            value={selectedVendor}
            disabled
          />
        </div>

        <div className={classes.timeContainer}>
          <header
            className={classNames(
              classes.timeHeader,
              classes.arrivalTimeHeader
            )}
          >
            ARRIVAL TIME
          </header>
          <header
            className={classNames(classes.timeHeader, classes.durationHeader)}
          >
            DURATION
          </header>
        </div>
        <div />
        <div className={classes.timeContainer}>
          <TextField
            name="TimeHoursField"
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={{
              MenuProps: { className: classes.menuItem },
            }}
            className={classes.arrivalTime}
            value={displayHours}
            error={!validDateTime}
            onChange={(e) => setDisplayHours(e.target.value)}
            disabled={appointmentInPast}
          >
            {TIME_HOURS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="TimeMinutesField"
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={{
              MenuProps: { className: classes.menuItem },
            }}
            className={classes.arrivalTime}
            value={displayMinutes}
            onChange={(e) => setDisplayMinutes(e.target.value)}
            error={!validDateTime}
            disabled={appointmentInPast}
          >
            {TIME_MINUTES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            name="TimeMeridiemField"
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={{
              MenuProps: { className: classes.menuItem },
            }}
            value={displayMeridiem}
            className={classes.arrivalTime}
            error={!validDateTime}
            onChange={(e) => setDisplayMeridiem(e.target.value)}
            disabled={appointmentInPast}
          >
            {TIME_MERIDIEM.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="DurationField"
            data-testid="duration-field"
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={{
              MenuProps: { className: classes.menuItem },
            }}
            className={classes.duration}
            value={selectedDuration}
            onChange={(e) => setSelectedDuration(e.target.value)}
            disabled={appointmentInPast}
          >
            {TIME_INTERVALS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {!validDateTime && (
          <FormHelperText error>
            Please select a time in the future
          </FormHelperText>
        )}

        <div className={classes.infoValue}>
          <header className={classes.header}>MEMO</header>
          <TextField
            id="labelTextField"
            placeholder="Enter a memo..."
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            className={classes.textField}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
        </div>
        <StatefulButton
          type="submit"
          successCallback={closeDrawer}
          loading={posting || updating}
          failed={errors.length > 0}
          disabled={!formComplete()}
          classes={{
            disabled: classes.buttonDisabled,
            root: classes.submitButton,
          }}
          fullWidth
          waitTime={750}
          onClick={(e) => {
            handleFormSubmit(e);
          }}
          buttonTextOptions={buttonText}
        />
        {!appointment && (
          <Button
            fullWidth
            variant="outlined"
            onClick={closeDrawer}
            className={classes.cancelButton}
            color="neutral"
          >
            Cancel
          </Button>
        )}
        {appointment && (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setDialogOpen(true)}
            className={classes.deleteButton}
          >
            Delete Appointment
          </Button>
        )}
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <div className={classes.dialog}>
            <div className={classes.dialogText}>
              Are you sure you want to delete this appointment?
            </div>
            <div className={classes.dialogButtons}>
              <Button variant="outlined" onClick={handleDeleteAppointment}>
                Yes
              </Button>
              <Button variant="outlined" onClick={() => setDialogOpen(false)}>
                No
              </Button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  );
};

AppointmentSubmissionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  appointment: PropTypes.object,
  threeWeeksOfNetsuiteOrderItems: PropTypes.array.isRequired,
  onPostDockAppointment: PropTypes.func.isRequired,
  onPutDockAppointment: PropTypes.func.isRequired,
  onDeleteDockAppointment: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  onStoreSearchQuery: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  searchResultsNOs: PropTypes.array.isRequired,
  prefilledDate: PropTypes.instanceOf(Date).isRequired,
};

AppointmentSubmissionForm.defaultProps = {
  appointment: null,
};

export default AppointmentSubmissionForm;
