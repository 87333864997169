export default {
  isDisabled: {
    opacity: '0.3',
  },
  loadingContainer: {
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
};
