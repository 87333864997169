import {
  GREY_150,
  GREY_400,
  WHITE,
  BLACK,
  GREY_650,
  HC_GREEN,
  FRESH_EASY_BLUE,
  RETAIL_ORANGE,
} from 'lib/constants';
import { linkTabStyling, centeredLoading } from 'lib/styles';

export default {
  ...linkTabStyling,
  centeredLoading,
  link: {
    ...linkTabStyling.link,
    fontSize: '16px',
  },
  topContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  menuSelectorContainer: {
    width: '200px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableRow: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr 100px',
    alignItems: 'center',
    height: '55px',
    borderBottom: `1px solid ${GREY_400}`,
    padding: '0px',
    paddingRight: '10px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: BLACK,
  },
  headerRow: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: GREY_650,
    paddingLeft: '10px',
  },
  menuOption: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    background: 'none',
    cursor: 'pointer',
    height: '35px',
  },
  inactiveLink: {
    borderBottom: 'none',
  },
  letterAssignment: {
    fontWeight: '800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    height: '100%',
    color: WHITE,
    fontSize: '20px',
  },
  numberLeft: {
    textAlign: 'right',
  },
  sorryMessage: {
    ...centeredLoading,
  },
  green: {
    color: HC_GREEN,
  },
  blue: {
    color: FRESH_EASY_BLUE,
  },
  orange: {
    color: RETAIL_ORANGE,
  },
  darkStripe: {
    background: GREY_150,
  },
};
