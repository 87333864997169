import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import MealList from 'components/MealList';

import RequestsForm from './RequestsForm';
import PickableRequestsForm from './PickableRequestsForm';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const Requests = ({
  podLocations,
  selectedMenu,
  onGetIngredientAssignments,
  onGetPickableIngredientAssignments,
  onSubmitMoveRequests,
  ingredientAssignments,
  pickableIngredientAssignments,
  onGetPackagingItems,
  packagingItems,
  iaFetching,
  itemMoveRequests,
  piFetching,
  location,
  packingFacilityId,
}) => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path={[
            '/warehouse_requests/requests/menu/:menuId',
            '/warehouse_requests/requests/menu',
          ]}
          render={() => <MealList showNumberLeft={false} enablePickableRow />}
        />
        <Route
          exact
          path="/warehouse_requests/requests/menu/:menuId/menu_selection/:menuSelectionId"
          render={(routingProps) => (
            <RequestsForm
              {...routingProps}
              podLocations={podLocations}
              selectedMenu={selectedMenu}
              onGetIngredientAssignments={onGetIngredientAssignments}
              onSubmitMoveRequests={onSubmitMoveRequests}
              onGetPackagingItems={onGetPackagingItems}
              location={location}
              ingredientAssignments={ingredientAssignments}
              packagingItems={packagingItems}
              iaFetching={iaFetching}
              itemMoveRequests={itemMoveRequests}
              piFetching={piFetching}
              packingFacilityId={packingFacilityId}
            />
          )}
        />
        <Route
          exact
          path="/warehouse_requests/requests/menu/:menuId/pickables"
          render={(routingProps) => (
            <PickableRequestsForm
              {...routingProps}
              podLocations={podLocations}
              selectedMenu={selectedMenu}
              onGetPickableIngredientAssignments={
                onGetPickableIngredientAssignments
              }
              onSubmitMoveRequests={onSubmitMoveRequests}
              ingredientAssignments={pickableIngredientAssignments}
              iaFetching={iaFetching}
              itemMoveRequests={itemMoveRequests}
              packingFacilityId={packingFacilityId}
            />
          )}
        />
      </Switch>
    </Fragment>
  );
};

Requests.propTypes = {
  podLocations: PropTypes.array.isRequired,
  selectedMenu: PropTypes.object.isRequired,
  onGetIngredientAssignments: PropTypes.func.isRequired,
  onGetPickableIngredientAssignments: PropTypes.func.isRequired,
  onSubmitMoveRequests: PropTypes.func.isRequired,
  onGetPackagingItems: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  ingredientAssignments: PropTypes.array.isRequired,
  pickableIngredientAssignments: PropTypes.array.isRequired,
  packagingItems: PropTypes.array.isRequired,
  iaFetching: PropTypes.bool.isRequired,
  itemMoveRequests: PropTypes.array.isRequired,
  piFetching: PropTypes.bool.isRequired,
};

export default Requests;
