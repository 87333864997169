import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const createItemReceipt = async ({ netsuiteItemId, truckId }) => {
  const response = await fetch(`${API_BASE_URI}/ops/warehouse/item_receipts`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      item_receipt: {
        netsuite_order_item_id: netsuiteItemId,
        truck_id: truckId,
      },
    }),
  });

  return await handleResponse(response);
};

const updateItemReceipt = async ({ netsuiteItemId }) => {
  const response = await fetch(`${API_BASE_URI}/ops/warehouse/item_receipts`, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({
      item_receipt: { netsuite_order_item_id: netsuiteItemId },
    }),
  });

  return await handleResponse(response);
};

export default {
  createItemReceipt,
  updateItemReceipt,
};
