import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetTopBarToDefault, setTopBarContent } from 'redux/topBar/actions';

import InventoryLandingPage from './InventoryLandingPage';

const mapStateToProps = (state) => ({
  palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
});

const mapDispatchToProps = {
  onResetTopBarToDefault: resetTopBarToDefault,
  onSetTopBarContent: setTopBarContent,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InventoryLandingPage)
);
