import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import SnackbarContent from '@mui/material/SnackbarContent';

import { HC_GREEN, DARK_RED } from 'lib/constants';

import IndexNavLinks from '../IndexNavLinks';
import CountListCard from './CountListCard';
import styles from './styles';

const useStyles = makeStyles(styles);

const CountLists = ({
  countLists,
  onFetchCountLists,
  onDeleteCountList,
  timeZone,
  history,
}) => {
  let { updateSuccess, updateError, deleteError, deleteSuccess } = useSelector(
    (state) => state.cycleCounting
  );

  useEffect(() => {
    onFetchCountLists();
  }, [onFetchCountLists]);

  const deleteCountList = async (countListId) => {
    await onDeleteCountList(countListId);

    onFetchCountLists();
  };

  const classes = useStyles();

  return (
    <>
      <IndexNavLinks />
      <Grid container spacing={2} className={classes.container}>
        {countLists.map((countList) => (
          <Grid key={countList.id} item xs={12} md={6} lg={4}>
            <CountListCard
              history={history}
              key={countList.id}
              countList={countList}
              deleteCountList={deleteCountList}
              timeZone={timeZone}
            />
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={updateSuccess || updateError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        data-testid="snackbar-content-count-list"
      >
        <SnackbarContent
          style={{
            backgroundColor: updateSuccess ? HC_GREEN : DARK_RED,
          }}
          message={
            updateSuccess
              ? 'Count list was successfully completed!'
              : 'There was an issue with saving, please try again.'
          }
        />
      </Snackbar>

      <Snackbar
        open={deleteSuccess || deleteError}
        options={{
          className: classes.snackbar,
        }}
        data-testid="snackbar-content-count-list-delete"
      >
        <SnackbarContent
          style={{
            backgroundColor: deleteSuccess ? HC_GREEN : DARK_RED,
          }}
          message={
            deleteSuccess
              ? 'Count list was successfully deleted!'
              : 'This count list cannot be deleted'
          }
        />
      </Snackbar>
    </>
  );
};

CountLists.propTypes = {
  countLists: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  onFetchCountLists: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  timeZone: PropTypes.string.isRequired,
};

CountLists.defaultProps = {
  countLists: [],
};

export default CountLists;
