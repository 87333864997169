import { NEGATIVE_RED, BLACK, POSITIVE_GREEN, BACKDROP } from 'lib/constants';
import theme from 'lib/theme';

const BACKDROP_Z_INDEX = 10;
const BACKDROPPABLE_Z_INDEX = 200;

const defaultTopCardStyles = {
  width: '90%',
  borderRadius: '25px',
  height: 'auto',
  padding: '10px',
  flex: 1,
  margin: '0px 10px 25px 10px',
};

export default {
  root: {
    fontFamily: 'Muli',
    minHeight: '100%',
  },
  link: {
    fontSize: '40px',
    flex: 1,
  },
  title: {
    verticalAlign: 'top',
    textTransform: 'uppercase',
  },
  cardHeading: {
    margin: '10px',
  },
  cardLastSubmitted: {
    margin: '0px 10px 6px',
  },
  lowErrorColor: {
    color: BLACK,
  },
  highErrorColor: {
    color: NEGATIVE_RED,
  },
  staleSubmissionColor: {
    color: NEGATIVE_RED,
  },
  freshSubmissionColor: {
    color: POSITIVE_GREEN,
  },
  informationalCard: {
    ...defaultTopCardStyles,
  },
  totalQaCard: {
    ...defaultTopCardStyles,
    maxHeight: '65px',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '83px',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '100px',
    },
  },
  informationalCardNumber: {
    textAlign: 'right',
  },
  clickableInformationalCard: {
    padding: '0',
  },
  rightColInformationalCard: {
    width: 'auto',
  },
  clickableInformationalCardArea: {
    padding: '10px',
  },
  backdroppableCard: {
    zIndex: BACKDROPPABLE_Z_INDEX,
  },
  expandableArea: {
    padding: '0 10px 10px',
  },
  grid: {
    padding: '200px',
  },
  topsection: {
    display: 'flex',
  },
  hourlyProductionContainer: {
    width: '75%',
    position: 'absolute',
    top: '175px',
    zIndex: '-1',
    [theme.breakpoints.down('md')]: {
      top: '200px',
    },
  },
  backdrop: {
    zIndex: BACKDROP_Z_INDEX,
    backgroundColor: BACKDROP,
  },
};
