import {
  MEAL_BAG_INVENTORY_POST,
  MEAL_BAG_INVENTORY_PRINT,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import MealBagInventory from './services';

export const printTag = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: MEAL_BAG_INVENTORY_PRINT,
      method: MealBagInventory.printTag,
      params,
    })
  );
};

export const postMealBagInventory = (params) => async (dispatch) => {
  const response = await dispatch(
    makeApiRequest({
      type: MEAL_BAG_INVENTORY_POST,
      method: MealBagInventory.postMealBagInventory,
      params,
    })
  );

  if (response.ok && params.printer) {
    dispatch(
      printTag({
        printer_id: params.printer.id,
        qty: params.printer.qty,
        meal_bag_inventory_id: response.id,
      })
    );
  }
};
