import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getCountLists = async () => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_lists`,
    options
  );

  return await handleResponse(response);
};

const getCountList = async ({ countListId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_lists/${countListId}`,
    options
  );

  return await handleResponse(response);
};

const postCountList = async (countListVariables) => {
  const response = await fetch(`${API_BASE_URI}/ops/warehouse/count_lists`, {
    ...options,
    method: 'POST',
    body: JSON.stringify(countListVariables),
  });

  return await handleResponse(response);
};

const updateCountList = async ({ countListId, payload }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_lists/${countListId}`,

    {
      ...options,
      method: 'PUT',
      body: JSON.stringify(payload),
    }
  );

  return await handleResponse(response);
};

const updatePalletLocation = async ({ countListId, payload }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_lists/${countListId}/update_pallet_location`,

    {
      ...options,
      method: 'PATCH',
      body: JSON.stringify(payload),
    }
  );

  return await handleResponse(response);
};

const refreshCountList = async ({ countListId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_lists/${countListId}/refresh_count_list`,

    {
      ...options,
      method: 'PATCH',
    }
  );

  return await handleResponse(response);
};

const deleteCountList = async ({ countListId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/count_lists/${countListId}`,

    {
      ...options,
      method: 'DELETE',
    }
  );

  return await handleResponse(response);
};

export default {
  postCountList,
  getCountList,
  getCountLists,
  updateCountList,
  updatePalletLocation,
  refreshCountList,
  deleteCountList,
};
