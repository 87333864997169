import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles';

const ErrorMessageSnackbar = ({ showError, errorMessage, classes }) => {
  const [open, setOpen] = useState(false);
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (showError) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showError]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={2000}
      data-testid="error-message-snackbar"
      message={<span id="message-id">{errorMessage}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={handleClose}
          size="large"
        >
          <CloseIcon data-testid="close-icon" />
        </IconButton>,
      ]}
    />
  );
};

ErrorMessageSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
export default withStyles(styles)(ErrorMessageSnackbar);
