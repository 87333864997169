import {
  PALLET_HISTORIES_GET_REQUEST,
  PALLET_HISTORIES_GET_SUCCESS,
  PALLET_HISTORIES_GET_ERROR,
  PALLET_HISTORIES_RESET,
} from 'lib/constants';
import { randomNumber } from 'lib/utils';

const initialState = {
  fetching: false,
  fetchFailed: false,
  results: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case PALLET_HISTORIES_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchFailed: false,
        results: [],
      };
    case PALLET_HISTORIES_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetchFailed: false,
        results: action.response.palletHistories.map((palletHistory) => ({
          ...palletHistory,
          id: palletHistory.id ? palletHistory.id : randomNumber(1000),
        })),
      };
    case PALLET_HISTORIES_GET_ERROR:
      return {
        ...state,
        fetching: false,
        fetchFailed: true,
      };
    case PALLET_HISTORIES_RESET:
      return initialState;
    default:
      return state;
  }
}
