import { makeQueryParamURL } from 'lib/utils';
import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getStorageSlots = async ({ storageAreaId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/storage_areas/${storageAreaId}`,
    options
  );

  return await handleResponse(response);
};

const getDepths = async ({ location, packingFacilityId, palletId }) => {
  const response = await fetch(
    makeQueryParamURL(
      `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/storage_slots/${location}/location_options`,
      { pallet_id: palletId }
    ),
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

const setCycleCount = async ({ palletId, wasCounted }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/cycle_count`,
    {
      ...options,
      method: 'PATCH',
      body: JSON.stringify({
        pallet: {
          counted: wasCounted,
        },
      }),
    }
  );

  return await handleResponse(response);
};

const cycleCountDeactivation = async ({ palletId, countListId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/cycle_count_deactivation`,
    {
      ...options,
      method: 'PATCH',
      body: JSON.stringify({
        count_list_id: countListId,
      }),
    }
  );

  return await handleResponse(response);
};

const setUnknownLocation = async ({
  palletId,
  packingFacilityId,
  countListId,
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/pallets/${palletId}/unknown_location`,
    {
      ...options,
      method: 'PATCH',
      body: JSON.stringify({
        packing_facility_id: packingFacilityId,
        count_list_id: countListId,
      }),
    }
  );

  return await handleResponse(response);
};

export default {
  getStorageSlots,
  getDepths,
  setCycleCount,
  cycleCountDeactivation,
  setUnknownLocation,
};
