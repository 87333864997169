import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { CSVReader } from 'react-papaparse';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';

import { usePrevious } from 'lib/custom_hooks';

import styles from './styles';

const UploaderUserInterface = ({
  classes,
  isValidating,
  onDrop,
  posting,
  postingErrors,
  validationState,
  uploadName,
  formattedErrorList,
  layout,
}) => {
  const DISABLED = 'disabled';
  const SUCCESS = 'success';
  const ERROR = 'error';

  const prevPosting = usePrevious(posting);

  let chipStatus = { text: 'No File Selected', color: DISABLED };

  if (isValidating) {
    chipStatus = { text: 'Validating ...', color: DISABLED };
  } else if (validationState.errors && validationState.errors.length > 0) {
    chipStatus = { text: 'Validation Error', color: ERROR };
  } else if (posting) {
    chipStatus = { text: 'Submitting...', color: DISABLED };
  } else if (prevPosting && !posting && isEmpty(postingErrors)) {
    chipStatus = { text: 'File Data Submitted', color: SUCCESS };
  } else if (!isEmpty(postingErrors)) {
    chipStatus = {
      text: uniq(postingErrors.map((e) => e.statusText)).join(', '),
      color: ERROR,
    };
  }

  const postingErrorList = () => {
    if (formattedErrorList) {
      return formattedErrorList();
    } else {
      return postingErrors.length > 0 ? (
        <ul>
          {postingErrors.map((error) => {
            return Object.keys(error).map((key) => {
              return <li key={key}>{`${key}: ${error[key]}`}</li>;
            });
          })}
        </ul>
      ) : null;
    }
  };

  return (
    <Grid
      container
      spacing={0}
      className={layout.fullscreen ? 'fullscreen' : ''}
      data-testid="uploader-user-interface"
    >
      <Grid item xs={layout.vertical ? 12 : 6} className={classes.leftGrid}>
        <CSVReader
          onDrop={onDrop}
          config={{ header: true, skipEmptyLines: true }}
        >
          <h2>Upload {uploadName}</h2>
          <Button variant="contained" component="label" color="primary">
            Select Or Drag and Drop File
          </Button>
        </CSVReader>
      </Grid>
      <Grid
        item
        xs={layout.vertical ? 12 : 6}
        container
        spacing={0}
        className={classes.rightPanel}
      >
        <Card xs={3} className={classes.statusCard}>
          <CardContent>
            <Chip
              label={chipStatus.text}
              className={classes[chipStatus.color]}
            />
            {validationState.errors && validationState.errors.length > 0 ? (
              <ul>
                {validationState.errors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            ) : (
              postingErrorList()
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

UploaderUserInterface.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidating: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  postingErrors: PropTypes.array.isRequired,
  validationState: PropTypes.object.isRequired,
  formattedErrorList: PropTypes.func,
  uploadName: PropTypes.string.isRequired,
  layout: PropTypes.shape({
    horizontal: PropTypes.bool,
    fullscreen: PropTypes.bool,
  }),
};

UploaderUserInterface.defaultProps = {
  formattedErrorList: null,
  layout: {
    vertical: false,
    fullscreen: true,
  },
};

export default withStyles(styles)(UploaderUserInterface);
