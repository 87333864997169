import {
  MEAL_BAG_INGREDIENT_ASSIGNMENTS_REQUEST,
  MEAL_BAG_INGREDIENT_ASSIGNMENTS_SUCCESS,
  MEAL_BAG_INGREDIENT_ASSIGNMENTS_ERROR,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  ingredientAssignments: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case MEAL_BAG_INGREDIENT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
        ingredientAssignments: [],
      };
    case MEAL_BAG_INGREDIENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        ingredientAssignments: action.response.ingredientAssignments,
      };
    case MEAL_BAG_INGREDIENT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
        ingredientAssignments: [],
      };
    default:
      return state;
  }
}
