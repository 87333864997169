import React from 'react';
import PropTypes from 'prop-types';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { ThemeProvider } from '@mui/material/styles';

import buttonGroupTheme from 'lib/buttonGroupTheme';

import styles from './styles';

const ButtonGroup = ({
  classes,
  headerText,
  values,
  currentValue,
  setNewValue,
  groupClassName,
}) => {
  const buttons = values.map((option) => {
    return (
      <ToggleButton
        className={classes.toggleButton}
        key={option.value}
        id={option.value}
        value={option.value}
      >
        {option.label}
      </ToggleButton>
    );
  });

  return (
    <div>
      <h3 className={classes.heading}>{headerText} </h3>
      <ThemeProvider theme={buttonGroupTheme}>
        <ToggleButtonGroup
          exclusive
          value={currentValue || ''}
          onChange={(_, newValue) => setNewValue(newValue)}
          className={classNames(classes.toggleButtonGroup, groupClassName)}
          size="small"
        >
          {buttons}
        </ToggleButtonGroup>
      </ThemeProvider>
    </div>
  );
};

ButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  headerText: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setNewValue: PropTypes.func.isRequired,
  groupClassName: PropTypes.string,
};

ButtonGroup.defaultProps = {
  currentValue: '',
  groupClassName: '',
};

export default withStyles(styles)(ButtonGroup);
