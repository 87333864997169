import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getShipment = async (params) => {
  const response = await fetch(`${API_BASE_URI}/ops/scanprint_xray/`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      box_scan: { tracking_number: params.trackingNumber },
    }),
  });

  return await handleResponse(response);
};

const createReplacementLabel = async (params) => {
  const { shipment_id, shipping_region_id, ship_date } = params;
  const response = await fetch(
    `${API_BASE_URI}/ops/scanprint_replacement_label/`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        label_request: { shipment_id, shipping_region_id, ship_date },
      }),
    }
  );
  return await handleResponse(response);
};

export default {
  getShipment,
  createReplacementLabel,
};
