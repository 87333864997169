import {
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_REQUEST,
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_SUCCESS,
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_ERROR,
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_REQUEST,
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_SUCCESS,
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_ERROR,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  results: [],
  pickableResults: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        results: action.response.ingredientAssignments,
      };
    case WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        pickableResults: action.response.ingredientAssignments,
      };
    case WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    case WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    default:
      return state;
  }
}
