// TODO - just make this part of cycle counting redux

import {
  UPDATE_COUNT_LIST_ASSIGNMENT_REQUEST,
  UPDATE_COUNT_LIST_ASSIGNMENT_SUCCESS,
  UPDATE_COUNT_LIST_ASSIGNMENT_ERROR,
} from 'lib/constants';

const initialState = {
  fetching: false,
  failed: false,
  countListAssignment: {},
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case UPDATE_COUNT_LIST_ASSIGNMENT_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case UPDATE_COUNT_LIST_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
      };
    case UPDATE_COUNT_LIST_ASSIGNMENT_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    default:
      return state;
  }
};
