import {
  AMBER_YELLOW,
  SUCCESS_GREEN,
  LIGHT_GREEN,
  TRANQUIL_BLUE,
  FRESH_EASY_BLUE,
  PEACH_ORANGE,
} from 'lib/constants';

export default {
  progressBarDiv: {
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    '& :first-child': {
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
    },
    '& :last-child': {
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  },
  completed: {
    backgroundColor: SUCCESS_GREEN,
    height: '10px',
  },
  inProgress: {
    backgroundColor: LIGHT_GREEN,
    height: '10px',
  },
  onHold: {
    backgroundColor: TRANQUIL_BLUE,
    height: '10px',
  },
  notStarted: {
    backgroundColor: AMBER_YELLOW,
    height: '10px',
    backgroundImage:
      'repeating-linear-gradient(to left, transparent, transparent 12px, white 12px, white 15px)',
  },
  assembled: {
    backgroundColor: SUCCESS_GREEN,
    height: '10px',
  },
  readyForAssembly: {
    backgroundColor: FRESH_EASY_BLUE,
    height: '10px',
  },
  toGoal: {
    backgroundColor: PEACH_ORANGE,
    height: '10px',
    backgroundImage:
      'repeating-linear-gradient(to left, transparent, transparent 12px, white 12px, white 15px)',
  },
};
