import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import filter from 'lodash/filter';
import { NavLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { Search, Loading } from 'components/shared';
import { numberWithCommas } from 'lib/utils';

import MealRow from './MealRow';
import PickableRow from './PickableRow';

const MealList = ({
  classes,
  menus,
  menuSelections,
  showNumberLeft,
  fetching,
  disableRowLink,
  selectedMenu,
  enablePickableRow,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchedMenuSelections = useMemo(() => {
    return searchTerm.length > 0
      ? filter(
          menuSelections,
          (menuSelection) =>
            menuSelection.title
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            menuSelection.letterAssignment
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      : menuSelections;
  }, [menuSelections, searchTerm]);
  return (
    <Card className={classes.container}>
      <CardContent>
        <div className={classes.topContent}>
          <div className={classes.menuSelectorContainer}>
            {menus.map((menu) => (
              <NavLink
                to={String(menu.id)}
                className={classes.link}
                activeClassName={classes.activeLink}
                key={menu.id}
              >
                {DateTime.fromISO(menu.weekOfDate).toFormat('M/dd')}
              </NavLink>
            ))}
          </div>

          <Search onSearch={(term) => setSearchTerm(term)} />
        </div>
      </CardContent>
      {fetching ? (
        <div className={classes.centeredLoading}>
          <Loading />
        </div>
      ) : (
        <div className={classes.table}>
          <div className={classNames(classes.tableRow, classes.headerRow)}>
            <span className={classes.mealId}>ID</span>
            <span className={classes.mealName}>MEAL NAME</span>
            {showNumberLeft && (
              <span className={classes.numberLeft}># REQUIRED</span>
            )}
          </div>
          {searchedMenuSelections.map((menuSelection, index) => (
            <MealRow
              key={menuSelection.id}
              mealName={menuSelection.title}
              letterAssignment={menuSelection.letterAssignment}
              numberLeft={numberWithCommas(menuSelection.remainingCount)}
              showNumberLeft={!fetching && showNumberLeft}
              channel={menuSelection.channel}
              menuSelection={menuSelection}
              odd={index % 2 === 0}
              disableRowLink={disableRowLink}
            />
          ))}
          {enablePickableRow && (
            <PickableRow
              key="pickableRow"
              disableRowLink={disableRowLink}
              selectedMenu={selectedMenu}
            />
          )}
        </div>
      )}
    </Card>
  );
};

const menuShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  weekOfDate: PropTypes.string.isRequired,
});

MealList.propTypes = {
  classes: PropTypes.object.isRequired,
  menus: PropTypes.arrayOf(menuShape).isRequired,
  menuSelections: PropTypes.array.isRequired,
  showNumberLeft: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  disableRowLink: PropTypes.bool,
  selectedMenu: PropTypes.object.isRequired,
  enablePickableRow: PropTypes.bool,
};

MealList.defaultProps = {
  disableRowLink: false,
  enablePickableRow: false,
};

export default MealList;
