import React, { useState, Fragment, useRef, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CreateIcon from '@mui/icons-material/Create';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PHONE_SCREEN, ENTER_KEY_CODE } from 'lib/constants';
import { isNonNegativeInteger } from 'lib/utils';
import { useHiddenNativeKeyboard } from 'lib/custom_hooks';
import PalletDetailsButtons from 'components/WarehouseInventory/PalletDetailsButtons';

import styles from './styles';

const SUBTRACT = 'subtract';
const ADD = 'add';
const SET = 'set';
const INPUT_VALUE = 'input-value';
const RESULT = 'result';

const NUMERICAL_OPERATORS = [SUBTRACT, ADD];
const AVAILABLE_OPERATORS = [SUBTRACT, ADD, SET];
const VALUE_TYPES = [INPUT_VALUE, RESULT];

const QuantityCalculator = ({
  title,
  subtitle,
  selectedOperators,
  defaultOperator,
  submitText,
  submitValueType,
  classes,
  disabled,
  loading,
  failed,
  quantity,
  submitQuantity,
  onInputBlur,
  onInputFocus,
  isOpen,
  closeModal,
  measuringUnit,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);

  const [inputValue, setInputValue] = useState('');
  const [operator, setOperator] = useState(defaultOperator);

  const operatorsAndIcons = [
    {
      name: ADD,
      icon: <AddIcon />,
    },
    {
      name: SUBTRACT,
      icon: <RemoveIcon />,
    },
    {
      name: SET,
      icon: <CreateIcon />,
    },
  ];

  const inputRef = useRef(null);

  const hideKeyboard = useHiddenNativeKeyboard(inputRef, phoneScreen);
  const handleModifierChange = (e) => {
    const value = e.target.value;
    if (
      (operator === SET && (value >= 0 || value === '')) ||
      (NUMERICAL_OPERATORS.includes(operator) && (value > 0 || value == ''))
    ) {
      setInputValue(e.target.value);
    }
  };

  const onFocus = (e) => {
    hideKeyboard();
    onInputFocus(e);
  };

  const changeOperator = (changedOperator) => {
    setOperator(changedOperator);
    inputRef.current?.focus();
  };

  const result = useMemo(() => {
    if (operator === ADD) {
      return Number(quantity) + Number(inputValue);
    } else if (operator === SUBTRACT) {
      return Number(quantity) - Number(inputValue);
    } else {
      return Number(inputValue);
    }
  }, [operator, quantity, inputValue]);

  const invalidQuantity = useMemo(() => !isNonNegativeInteger(result), [
    result,
  ]);

  const invalidInputValue = !isNonNegativeInteger(inputValue);

  const formatSubmitText = (text, type) => {
    if (type == INPUT_VALUE) {
      return text.replace('$', inputValue);
    }
    if (type == RESULT) {
      return text.replace('$', result);
    }
  };

  const clearFields = () => {
    setOperator(defaultOperator);
    setInputValue('');
  };

  const onSubmit = () => {
    submitQuantity(inputValue, result);
    clearFields();
  };

  const onQuantityInputKeyDown = (event) => {
    if (
      event.keyCode === ENTER_KEY_CODE &&
      !invalidQuantity &&
      !invalidInputValue
    ) {
      onSubmit(result);
    }
  };

  const selectedNumericalOperators = operatorsAndIcons.filter((o) => {
    return (
      selectedOperators.includes(o.name) && NUMERICAL_OPERATORS.includes(o.name)
    );
  });

  const numericalOperatorButtons = selectedNumericalOperators.map((o) => {
    return (
      <Button
        data-testid={`${o.name}-button`}
        key={o.name}
        className={classNames(classes.button, {
          [classes.selectedOperator]: operator === o.name,
        })}
        onClick={() => changeOperator(o.name)}
      >
        {o.icon}
      </Button>
    );
  });

  const textWithMeasuringUnit = (unit, value) => {
    if (unit) {
      return <div>{`${value} at ${unit}`}</div>;
    } else {
      return <div>{`${value}`}</div>;
    }
  };

  const renderSetValueButton = () => {
    if (selectedOperators.includes(SET)) {
      return (
        <ButtonGroup className={classes.setValueButton}>
          <Button
            data-testid="set-value-button"
            className={classNames(classes.button, {
              [classes.selectedOperator]: operator === SET,
            })}
            onClick={() => changeOperator(SET)}
          >
            <CreateIcon />
          </Button>
        </ButtonGroup>
      );
    }
  };

  const renderCalculator = () => (
    <Fragment>
      <div className={classes.mathProblem}>
        {title && <div className={classes.calculatorTitle}>{title}</div>}
        {subtitle && (
          <div className={classes.calculatorSubtitle}>{subtitle}</div>
        )}
        {textWithMeasuringUnit(measuringUnit, quantity)}
        <div className={classes.inputValue}>
          <>
            <ButtonGroup
              data-testid="calculator-buttons"
              size={phoneScreen ? 'small' : 'large'}
            >
              {numericalOperatorButtons}
            </ButtonGroup>
            {renderSetValueButton()}
          </>
          <TextField
            data-testid="number-input"
            type="number"
            classes={{
              root: classes.quantityInputRoot,
            }}
            variant="outlined"
            disabled={disabled}
            value={inputValue}
            onChange={handleModifierChange}
            inputRef={inputRef}
            autoFocus
            InputProps={{
              classes: { root: classes.quantityInput },
              inputProps: {
                className: classNames(classes.input, {
                  [classes.smallerFont]: true,
                }),
              },
            }}
            error={invalidQuantity}
            onBlur={onInputBlur}
            onFocus={onFocus}
            onClick={hideKeyboard}
            onKeyDown={onQuantityInputKeyDown}
          />
        </div>
        <hr align="right" className={classes.line} />

        {textWithMeasuringUnit(measuringUnit, result)}
      </div>
      <PalletDetailsButtons
        onCancelClick={closeModal}
        onSubmitClick={onSubmit}
        disabled={invalidQuantity || !inputValue}
        buttonTextOverrides={{
          DEFAULT: formatSubmitText(submitText, submitValueType),
        }}
        failed={failed}
        loading={loading}
      />
    </Fragment>
  );

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        classes={{ paper: classes.dialog }}
      >
        <div className={classes.dialogContent}>{renderCalculator()}</div>
      </Dialog>
    </Fragment>
  );
};

QuantityCalculator.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedOperators: PropTypes.arrayOf(PropTypes.oneOf(AVAILABLE_OPERATORS))
    .isRequired,
  defaultOperator: PropTypes.oneOf(AVAILABLE_OPERATORS),
  submitText: PropTypes.string,
  submitValueType: PropTypes.oneOf(VALUE_TYPES),
  classes: PropTypes.object.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  submitQuantity: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func,
  onInputFocus: PropTypes.func,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  measuringUnit: PropTypes.string,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
};

QuantityCalculator.defaultProps = {
  title: '',
  subtitle: '',
  measuringUnit: '',
  defaultOperator: SUBTRACT,
  submitText: 'Submit $',
  submitValueType: RESULT,
  onInputBlur: () => {},
  onInputFocus: () => {},
  disabled: false,
  isOpen: true,
  quantity: 0,
  loading: false,
  failed: false,
};

export default withStyles(styles)(QuantityCalculator);
