import { useMemo, useEffect, useCallback } from 'react';

const UNSELECTED_DEPTH_REGEX = new RegExp(/(-|.)\d/);
const SELECTED_DEPTH_REGEX = new RegExp(/\d{2}/);

export const BLACK = '#000000';
export const SHAMROCK = '#009e60';
export const CYAN = '#00FFFF';
export const MAGENTA = '#ff00ff';
export const YELLOW = '#FFFF00';
export const ORANGE = '#F07B00';
export const WHITE = '#FFFFFF';

export const getHeight = (locationSubstring) => {
  if (SELECTED_DEPTH_REGEX.test(locationSubstring)) {
    return locationSubstring[0];
  } else if (UNSELECTED_DEPTH_REGEX.test(locationSubstring)) {
    return locationSubstring[1];
  }
  return null;
};

export const getHeightColor = (location) => {
  if (!location) {
    return null;
  }
  if (location == 'Unknown Slot') {
    return WHITE;
  }
  const height = getHeight(location.slice(-2));
  switch (height) {
    case '1':
      return BLACK;
    case '2':
      return SHAMROCK;
    case '3':
      return CYAN;
    case '4':
      return MAGENTA;
    case '5':
      return YELLOW;
    case '6':
      return ORANGE;
    default:
      return null;
  }
};

export const useLocationMoveName = (selectedDepth, depthOptions) => {
  return useMemo(() => {
    if (selectedDepth.name) {
      return selectedDepth.name;
    } else if (depthOptions.length) {
      return depthOptions[0].name.slice(0, -1);
    }
  }, [depthOptions, selectedDepth]);
};

export const useLocationDepths = (
  onResetDepths,
  onGetDepths,
  packingFacilityId,
  palletId
) => {
  useEffect(() => {
    return () => {
      onResetDepths();
    };
  }, [onResetDepths]);
  const getDepthsCallback = useCallback(
    (location) => {
      onGetDepths(location, packingFacilityId, palletId);
    },
    [onGetDepths, packingFacilityId, palletId]
  );

  return getDepthsCallback;
};
