import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';

import { NO_PORTION_METHOD } from 'lib/constants';

const mealsSelector = (state) => state.meals;
const retailMealsSelector = (state) => state.retailMeals;
const allMealsSelector = createSelector(
  mealsSelector,
  retailMealsSelector,
  (meals, retailMeals) => compact(meals.concat(retailMeals))
);

const ingredientIdAndUnitOfMeasure = (ingredientAssignment) => {
  return `${ingredientAssignment.ingredientId} - ${ingredientAssignment.quantity} ${ingredientAssignment.unit}`;
};

const groupPortioningProgressByIngredient = (allMeals) => {
  const allIngredientAssignments = allMeals
    .flatMap((meal) => meal.ingredient_assignments)
    .filter(
      (ingredientAssignment) =>
        ingredientAssignment.method !== NO_PORTION_METHOD
    );

  return groupBy(allIngredientAssignments, ingredientIdAndUnitOfMeasure);
};

export const selectPortioningProgressByIngredient = createSelector(
  allMealsSelector,
  groupPortioningProgressByIngredient
);
