import React from 'react';

import IngredientRow from '../IngredientRow';

const IngredientOverviewRow = (props) => {
  return (
    <IngredientRow
      {...props}
      showWIPColumn={false}
      customData={[
        { dataType: 'percent', dataKey: 'labeling_percent' },
        { dataType: 'number', dataKey: 'labeling_remaining' },
        { dataType: 'percent', dataKey: 'portioning_percent' },
        { dataType: 'number', dataKey: 'portioning_remaining' },
      ]}
    />
  );
};

export default IngredientOverviewRow;
