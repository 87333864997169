import { connect } from 'react-redux';

import { postDockAppointment } from 'redux/dockScheduler/actions';

import UploaderInterface from './UploaderInterface';

const mapDispatchToProps = {
  onPostDockAppointment: postDockAppointment,
};

const mapStateToProps = (state) => ({
  posting: state.dockScheduler.posting,
  postingErrors: state.dockScheduler.errors,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploaderInterface);
