import React from 'react';
import PropTypes from 'prop-types';

import { WHITE } from 'lib/constants';

const WarningIcon = ({ className, visible }) => {
  if (visible === true) {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
          fill={WHITE}
        />
      </svg>
    );
  } else {
    return null;
  }
};

WarningIcon.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
};

WarningIcon.defaultProps = {
  className: 'WarningIcon',
  visible: true,
};

export default WarningIcon;
