export default {
  container: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  selectControls: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
};
