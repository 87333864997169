import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import { formatDateAsMdd, dateTimeInTimeZone } from 'lib/utils';

import UploaderInterface from './UploaderInterface';
import {
  STORAGE_AREA,
  SHIFT,
  CATEGORY,
  MENUS,
  INGREDIENT_CHECKBOX,
  PACKAGING_CHECKBOX,
  GROUND_POSITIONS_CHECKBOX,
  SHIFT_OPTIONS,
  CATEGORY_OPTIONS,
  BY_MENU,
  BY_ITEM,
  NAME,
} from './formUtils';

const CreateCountList = ({
  category,
  menus,
  onFileUpload,
  onInputChange,
  storageAreas,
}) => {
  const showItemTypeField = category !== BY_ITEM;
  const showUploader = category === BY_ITEM;
  const showMenuSelector = category === BY_MENU;

  return (
    <>
      <FormControl variant="standard" fullWidth margin="normal">
        <InputLabel data-testid="storage-area-label">Storage Area</InputLabel>
        <Select
          variant="standard"
          data-testid="storage-area"
          labelId="storage_area-label"
          name={STORAGE_AREA}
          defaultValue=""
          onChange={(e) => onInputChange(STORAGE_AREA, e.target.value)}
        >
          {storageAreas.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth margin="normal">
        <InputLabel data-testid="shift-label">Shift</InputLabel>
        <Select
          variant="standard"
          data-testid="shift"
          labelId="shift-label"
          name={SHIFT}
          defaultValue=""
          onChange={(e) => onInputChange(SHIFT, e.target.value)}
        >
          {SHIFT_OPTIONS.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" fullWidth margin="normal">
        <InputLabel data-testid="category-label">Count List Scope</InputLabel>
        <Select
          variant="standard"
          id="category"
          data-testid="category"
          labelId="category-label"
          name={CATEGORY}
          defaultValue=""
          onChange={(e) => onInputChange(CATEGORY, e.target.value)}
        >
          {CATEGORY_OPTIONS.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showUploader && <UploaderInterface onFileUpload={onFileUpload} />}
      {showMenuSelector && (
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel data-testid="menus-label">Menu Selection</InputLabel>
          <Select
            variant="standard"
            data-testid="menus"
            labelId="menus-label"
            name={MENUS}
            defaultValue={[]}
            multiple
            onChange={(e) => onInputChange(MENUS, e.target.value)}
          >
            {menus.map(({ id, weekOfDate }) => (
              <MenuItem key={id} value={id}>
                {`Menu Ship: ${formatDateAsMdd(
                  dateTimeInTimeZone('etc/utc', new Date(weekOfDate))
                )}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl variant="standard" fullWidth>
        <FormLabel>Parameters</FormLabel>
        <FormControlLabel
          control={<Checkbox data-testid="ground-only" color="secondary" />}
          label="Ground Positions"
          name={GROUND_POSITIONS_CHECKBOX}
          onChange={(e) =>
            onInputChange(GROUND_POSITIONS_CHECKBOX, e.target.checked)
          }
        />
      </FormControl>
      {showItemTypeField && (
        <FormControl variant="standard" component="fieldset" margin="normal">
          <FormLabel>Item Type</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox data-testid="ingredient" color="secondary" />}
              label="Ingredients"
              name={INGREDIENT_CHECKBOX}
              onChange={(e) =>
                onInputChange(INGREDIENT_CHECKBOX, e.target.checked)
              }
            />
            <FormControlLabel
              data-testid="packaging"
              control={<Checkbox color="secondary" />}
              label="Packaging"
              name={PACKAGING_CHECKBOX}
              onChange={(e) =>
                onInputChange(PACKAGING_CHECKBOX, e.target.checked)
              }
            />
          </FormGroup>
        </FormControl>
      )}
      <FormControl variant="standard" margin="normal" fullWidth>
        <TextField
          id="name"
          label="Name"
          variant="standard"
          name={NAME}
          onChange={(e) => onInputChange(NAME, e.target.value)}
          inputProps={{
            maxLength: 40,
          }}
        />
      </FormControl>
    </>
  );
};

CreateCountList.propTypes = {
  category: PropTypes.string,
  menus: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  storageAreas: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
  ),
};

CreateCountList.defaultProps = {
  category: '',
  menus: [],
  onInputChange: () => {},
  storageAreas: [],
};

export default CreateCountList;
