import React, { useMemo, useReducer, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import every from 'lodash/every';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Switch from '@mui/material/Switch';

import { StatefulButton } from 'components/shared';
import {
  BRAND_CORRECT,
  ADJUSTED_BRAND,
  PRODUCT_DATE_CODE_CORRECT,
  ADJUSTED_PRODUCT_DATE,
  ADJUSTED_PRODUCT_DATE_TYPE,
  TEMPS,
  WEIGHT_UOM,
  WEIGHTS,
  LOT_CODES,
  DEFECTS,
  HAS_DEFECTS,
  NUMBER_DEFECTIVE,
  NUMBER_SAMPLED,
  REJECTED_QUANTITY,
  FINAL_QUANTITY,
  FULL_CHECK_COMPLETED,
  PARTIAL_CHECK_COMPLETED,
  TRUE_ALLERGENS_CORRECT,
  INTOLERANCES_CORRECT,
  ITEM_LABELED,
  ITEM_ALLERGENS_CORRECT,
  ITEM_NAME_CORRECT,
  SB_FACILITY_ID,
  NON_PROP_12,
} from 'lib/constants';
import PrinterOptions from 'components/shared/PrinterOptions';

import BrandQuestion from '../../BrandQuestion';
import ProductDateCodeQuestion from '../../ProductDateCodeQuestion';
import TempInputs from '../../TempInputs';
import WeightInputs from '../../WeightInputs';
import DefectsQuestion from '../../DefectsQuestion';
import QuantityQuestion from '../../QuantityQuestion';
import LotCodeQuestions from '../../LotCodeQuestions';
import AllergensQuestionsGrouped from '../../AllergensQuestionsGrouped';
import ItemQuestions from '../../ItemQuestions';
import Prop12Photo from '../../Prop12Photo';
import { validationReducer, useValidatorCallback } from '../formHelpers';
import SpecViewer from '../../SpecViewer';

const DEFECTS_VALID = 'DEFECTS_VALID';
const BRAND_VALID = 'BRAND_VALID';
const PDATE_VALID = 'PDATE_VALID';
const LOT_CODES_VALID = 'LOT_CODES_VALID';
const TEMPS_VALID = 'TEMPS_VALID';
const WEIGHTS_VALID = 'WEIGHTS_VALID';
const REJECTED_QUANTITY_VALID = 'REJECTED_QUANTITY_VALID';
const ITEM_QUESTIONS_VALID = 'ITEM_QUESTIONS_VALID';
const PROP_12_VALID = 'PROP_12_VALID';

const defaultValidationState = {
  [DEFECTS_VALID]: null,
  [LOT_CODES_VALID]: null,
  [REJECTED_QUANTITY_VALID]: null,
  [PROP_12_VALID]: null,
};
const foodValidationState = {
  ...defaultValidationState,
  [BRAND_VALID]: null,
  [PDATE_VALID]: null,
};

const IngredientForm = ({
  closeDrawer,
  pallet,
  classes,
  formState,
  onAddToForm,
  onSubmitForm,
  onToggleFullCheck,
  submitLoading,
  submitFailed,
  printers,
  packingFacilityId,
}) => {
  // This will be cleaned up and refactored in a later ticket, bigger changes needed in back-end
  const trueTempRequiredBool =
    (pallet.tempsRequired && !pallet.orderableMinTemp) ||
    (pallet.tempsRequired &&
      pallet.orderableMinTemp &&
      pallet.orderableMinTemp <= 45);

  const [specViewerOpen, setSpecViewerOpen] = useState(false);

  const prop12Required =
    packingFacilityId === SB_FACILITY_ID && pallet.item.includes(NON_PROP_12);

  const initialValidationState = useMemo(
    () => ({
      ...foodValidationState,
      [TEMPS_VALID]: trueTempRequiredBool ? null : true,
      [WEIGHTS_VALID]: pallet.weightsRequired ? null : true,
      [PROP_12_VALID]: prop12Required ? null : true,
    }),
    [pallet.weightsRequired, trueTempRequiredBool]
  );

  const [validationState, validationDispatch] = useReducer(
    validationReducer,
    initialValidationState
  );

  const ingredientPartialCheckValues = {
    PDATE_VALID: validationState['PDATE_VALID'],
    LOT_CODES_VALID: validationState['LOT_CODES_VALID'],
    PROP_12_VALID: validationState['PROP_12_VALID'],
  };

  const createValueCallback = (field) => (value) => {
    onAddToForm(field, value);
  };

  const formValid = useMemo(
    () =>
      formState.fullCheck
        ? every(Object.values(validationState))
        : every(Object.values(ingredientPartialCheckValues)),
    [formState.fullCheck, ingredientPartialCheckValues, validationState]
  );

  const showSubmitButton = useMemo(
    () =>
      !formState.finalized ||
      (pallet.status === PARTIAL_CHECK_COMPLETED && formState.fullCheck) ||
      formState[LOT_CODES]?.length > formState.savedLotCodes?.length,
    [formState, pallet.status]
  );

  const allergenErrorTypes = useMemo(() => {
    const allergensCorrect = formState[TRUE_ALLERGENS_CORRECT];
    const intolerancesCorrect = formState[INTOLERANCES_CORRECT];
    let types = '';
    if (!allergensCorrect && !intolerancesCorrect) {
      types = 'Allergens and Intolerance Substances';
    } else if (!allergensCorrect) {
      types = 'Allergens';
    } else if (!intolerancesCorrect) {
      types = 'Intolerance Substances';
    }
    return types;
  }, [formState[TRUE_ALLERGENS_CORRECT], formState[INTOLERANCES_CORRECT]]);

  const itemLabelError = useMemo(() => {
    const hasLabelError = formState[ITEM_LABELED] === false;
    const hasAllergenError = formState[ITEM_ALLERGENS_CORRECT] === false;
    const hasNameError = formState[ITEM_NAME_CORRECT] === false;

    return hasLabelError || hasAllergenError || hasNameError;
  });

  const selectedPrinterId = useRef();

  const setCurrentPrinterId = (printerId) => {
    selectedPrinterId.current = printerId;
  };

  const onSubmit = () => {
    if (formValid) {
      onSubmitForm(selectedPrinterId.current);
    }
  };

  const brandValidator = useValidatorCallback(BRAND_VALID, validationDispatch);
  const pDateValidator = useValidatorCallback(PDATE_VALID, validationDispatch);

  const lotCodesValidator = useValidatorCallback(
    LOT_CODES_VALID,
    validationDispatch
  );

  const prop12Validator = useValidatorCallback(
    PROP_12_VALID,
    validationDispatch
  );

  const tempsValidator = useValidatorCallback(TEMPS_VALID, validationDispatch);
  const weightsValidator = useValidatorCallback(
    WEIGHTS_VALID,
    validationDispatch
  );
  const defectsValidator = useValidatorCallback(
    DEFECTS_VALID,
    validationDispatch
  );
  const rejectedQuantityValidator = useValidatorCallback(
    REJECTED_QUANTITY_VALID,
    validationDispatch
  );
  const itemQuestionsValidator = useValidatorCallback(
    ITEM_QUESTIONS_VALID,
    validationDispatch
  );

  const checkIfNeedToReprint = useMemo(
    () =>
      (!formState.finalized &&
        formState[PRODUCT_DATE_CODE_CORRECT] === false) ||
      (pallet.status !== FULL_CHECK_COMPLETED &&
        formState[BRAND_CORRECT] === false),
    [formState, pallet.status]
  );

  return (
    <div className={classes.bodySection}>
      <div className={classes.topInfo}>
        <dl>
          <dt className={classes.infoLabel} id="item">
            ITEM
          </dt>
          <dd className={classes.infoValue}>{pallet.item}</dd>
          <dt className={classes.infoLabel} id="vendor">
            VENDOR
          </dt>
          <dd className={classes.infoValue}>{pallet.vendorName}</dd>
        </dl>
        {pallet.specUrl && (
          <Button
            className={classes.specButton}
            onClick={() => setSpecViewerOpen(true)}
            color="primary"
            variant="contained"
          >
            Review Spec
          </Button>
        )}
        <SpecViewer
          open={specViewerOpen}
          onCloseSpecViewer={() => setSpecViewerOpen(false)}
          asset={pallet.specUrl}
        />
      </div>
      <ProductDateCodeQuestion
        adjustedProductDate={formState[ADJUSTED_PRODUCT_DATE]}
        adjustedProductDateType={formState[ADJUSTED_PRODUCT_DATE_TYPE]}
        productDateCodeCorrect={formState[PRODUCT_DATE_CODE_CORRECT]}
        updateProductDateCodeCorrect={createValueCallback(
          PRODUCT_DATE_CODE_CORRECT
        )}
        updateAdjustedProductDate={createValueCallback(ADJUSTED_PRODUCT_DATE)}
        updateAdjustedProductDateType={createValueCallback(
          ADJUSTED_PRODUCT_DATE_TYPE
        )}
        initialProductDate={pallet.productDate}
        initialProductDateType={pallet.productDateType}
        validationCallback={pDateValidator}
        disabled={formState.finalized}
      />
      <LotCodeQuestions
        palletId={pallet.id}
        updateLotCodes={createValueCallback(LOT_CODES)}
        lotCodes={formState[LOT_CODES]}
        validationCallback={lotCodesValidator}
      />
      {prop12Required && (
        <Prop12Photo
          palletId={pallet.id}
          validationCallback={prop12Validator}
        />
      )}
      {pallet.status !== FULL_CHECK_COMPLETED && (
        <>
          Perform Quality Check
          <Switch
            onClick={onToggleFullCheck}
            color="default"
            classes={{ switchBase: classes.toggleOn, track: classes.toggleOn }}
            checked={formState.fullCheck || false}
            data-testid="toggle-full-check"
          />
        </>
      )}
      {formState.fullCheck ? (
        <div
          className={classNames({
            [classes.finalized]: pallet.status === FULL_CHECK_COMPLETED,
          })}
        >
          <BrandQuestion
            adjustedBrand={formState[ADJUSTED_BRAND]}
            brandCorrect={formState[BRAND_CORRECT]}
            updateAdjustedBrand={createValueCallback(ADJUSTED_BRAND)}
            updateBrandCorrect={createValueCallback(BRAND_CORRECT)}
            palletBrand={pallet.brand}
            validationCallback={brandValidator}
            disabled={formState.finalized}
          />
          <AllergensQuestionsGrouped pallet={pallet} formState={formState} />
          <ItemQuestions
            pallet={pallet}
            formState={formState}
            validationCallback={itemQuestionsValidator}
          />
          {/* Checks if pallet has ingredient minimum temp & that temp is less than 45F */}
          {trueTempRequiredBool ? (
            <TempInputs
              temps={formState[TEMPS] || []}
              minimumTemp={pallet.minimumTemp}
              maximumTemp={pallet.maximumTemp}
              updateTemps={createValueCallback(TEMPS)}
              validationCallback={tempsValidator}
            />
          ) : null}
          {pallet.weightsRequired ? (
            <WeightInputs
              weightUom={formState[WEIGHT_UOM]}
              updateWeightUom={createValueCallback(WEIGHT_UOM)}
              weights={formState[WEIGHTS] || []}
              updateWeights={createValueCallback(WEIGHTS)}
              validationCallback={weightsValidator}
            />
          ) : null}
          <DefectsQuestion
            hasDefects={formState[HAS_DEFECTS]}
            defects={formState[DEFECTS]}
            numberDefective={formState[NUMBER_DEFECTIVE]}
            numberSampled={formState[NUMBER_SAMPLED]}
            palletId={pallet.id}
            categoryId={pallet.itemCategoryId}
            validationCallback={defectsValidator}
          />
          <QuantityQuestion
            initialQuantity={
              formState.status === FULL_CHECK_COMPLETED
                ? pallet.quantity + formState[REJECTED_QUANTITY]
                : pallet.quantity
            }
            unitType={pallet.measuringUnit}
            numberRejected={formState[REJECTED_QUANTITY] || 0}
            updateRejectedQuantity={createValueCallback(REJECTED_QUANTITY)}
            updateFinalQuantity={createValueCallback(FINAL_QUANTITY)}
            validationCallback={rejectedQuantityValidator}
          />
        </div>
      ) : null}
      <div className={classes.reprint}>
        {checkIfNeedToReprint == true && (
          <Alert severity="info">
            License plate will be reprinted with corrected information. Select
            printer by tapping the 3 dots below.
          </Alert>
        )}
      </div>
      {formState.fullCheck && allergenErrorTypes && (
        <Alert severity="error">
          <AlertTitle>
            Place the item on hold and notify your supervisor
          </AlertTitle>
          The <strong>{allergenErrorTypes}</strong> on the label do not match
          admin.
        </Alert>
      )}
      {formState.fullCheck && itemLabelError && (
        <Alert severity="error">
          <AlertTitle>
            Place the item on hold and notify your supervisor
          </AlertTitle>
          The item label is missing or has incorrect information.
        </Alert>
      )}
      <div className={classes.buttonsContainer}>
        {showSubmitButton && (
          <StatefulButton
            type="submit"
            fullWidth
            disabled={!formValid}
            onClick={onSubmit}
            loading={submitLoading}
            failed={submitFailed}
            classes={{
              disabled: classes.buttonDisabled,
              root: classes.submitButton,
            }}
            successCallback={closeDrawer}
          >
            Submit
          </StatefulButton>
        )}
        {checkIfNeedToReprint && (
          <PrinterOptions
            className={
              checkIfNeedToReprint ? classes.printerOptionsButton : false
            }
            disabled={!checkIfNeedToReprint}
            setCurrentPrinterId={setCurrentPrinterId}
            printers={printers}
            defaultPrinterDescription={pallet.defaultPrinterDescription}
          />
        )}
      </div>
    </div>
  );
};

IngredientForm.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  pallet: PropTypes.shape({
    item: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    measuringUnit: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    brand: PropTypes.string,
    id: PropTypes.number.isRequired,
    orderableType: PropTypes.string.isRequired,
    productDate: PropTypes.string,
    productDateType: PropTypes.string,
    weightsRequired: PropTypes.bool,
    tempsRequired: PropTypes.bool,
    minimumTemp: PropTypes.number,
    maximumTemp: PropTypes.number,
    itemCategoryId: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  onAddToForm: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  onToggleFullCheck: PropTypes.func.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  netsuiteItem: PropTypes.shape({
    quantity: PropTypes.number,
    id: PropTypes.number,
    measuringUnitId: PropTypes.number,
    defaultPrinterDescription: PropTypes.string,
    overageLimit: PropTypes.number,
    needOverageApproval: PropTypes.bool,
  }),
  packingFacilityId: PropTypes.number.isRequired,
};

IngredientForm.defaultProps = {
  netsuiteItem: {},
};

export default IngredientForm;
