import React from 'react';
import Card from '@mui/material/Card';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

import { formatNumber, formatDateAsDDMMYY } from 'lib/utils';

const MealInformation = ({ menuSelection, classes }) => {
  const photoUrl = menuSelection.previewPhotoUrl
    ? menuSelection.previewPhotoUrl
    : menuSelection.platedImageUrl;
  return (
    <Grid container>
      <Grid item xs={6}>
        <Card className={classnames(classes.card, classes.mealInfoCard)}>
          <CardContent>
            <h3 className={classes.cardTitle}>{menuSelection.title}</h3>
          </CardContent>
          <CardMedia
            className={classes.media}
            image={photoUrl}
            title={menuSelection.title}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card className={classnames(classes.card, classes.mealInfoCard)}>
          <CardContent>
            <h3 className={classes.cardTitle}>Details</h3>
          </CardContent>

          <Grid container className={classes.mealInfoCardRow}>
            <Grid item xs={5} className={classes.boldCell}>
              Meal ID
            </Grid>
            <Grid item xs={7}>
              {menuSelection.mealId}
            </Grid>
          </Grid>

          <Grid container className={classes.mealInfoCardRow}>
            <Grid item xs={5} className={classes.boldCell}>
              Ship Week
            </Grid>
            <Grid item xs={7}>
              {formatDateAsDDMMYY(menuSelection.menuShipWeek)}
            </Grid>
          </Grid>

          <Grid container className={classes.mealInfoCardRow}>
            <Grid item xs={5} className={classes.boldCell}>
              Letter Assignment
            </Grid>
            <Grid item xs={7}>
              {menuSelection.letterAssignment}
            </Grid>
          </Grid>

          <Grid container className={classes.mealInfoCardRow}>
            <Grid item xs={5} className={classes.boldCell}>
              Current Forecast
            </Grid>
            <Grid item xs={7}>
              {formatNumber(menuSelection.goalCount)}
            </Grid>
          </Grid>

          <Grid container className={classes.mealInfoCardRow}>
            <Grid item xs={5} className={classes.boldCell}>
              Allergens
            </Grid>
            <Grid item xs={7}>
              {menuSelection.allergens.join(', ')}
            </Grid>
          </Grid>

          <Grid container className={classes.mealInfoCardRow}>
            <Grid item xs={5} className={classes.boldCell}>
              Meal Bag Size
            </Grid>
            <Grid item xs={7}>
              {menuSelection.mealBagSize}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

MealInformation.propTypes = {
  menuSelection: PropTypes.shape({
    mealId: PropTypes.number.isRequired,
    menuShipWeek: PropTypes.string.isRequired,
    letterAssignment: PropTypes.string,
    goalCount: PropTypes.number.isRequired,
    allergens: PropTypes.array,
    mealBagSize: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default MealInformation;
