import { connect } from 'react-redux';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import { getMenuSelections } from 'redux/menuSelection/actions';
import { getIngredients } from 'redux/ingredients/actions';
import { postProductionPlans } from 'redux/productionPlans/actions';

import UploaderInterface from './UploaderInterface';

const allIngredientsForProduction = (ingredients) => {
  if (isEmpty(ingredients)) return [];
  return uniq(
    Object.values(ingredients.portionableIngredients).concat(
      Object.values(ingredients.labelableIngredients)
    )
  );
};

const mapDispatchToProps = {
  onGetMenuSelections: getMenuSelections,
  onGetIngredients: getIngredients,
  onPostProductionPlans: postProductionPlans,
};

const mapStateToProps = (state) => ({
  menuSelections: state.menuSelection.menuSelections,
  ingredients: allIngredientsForProduction(state.ingredients.ingredients),
  posting: state.productionPlans.posting,
  postingErrors: state.productionPlans.errors,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploaderInterface);
