import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { useHistory } from 'react-router-dom';

import { Loading, PalletScanTextField } from 'components/shared';

import styles from './styles';
import ItemMoveRequest from '../ItemMoveRequest/ItemMoveRequest';

const OpenRequests = ({
  classes,
  toDoRequests,
  fetching,
  signedInUser,
  palletSearchDrawerOpen,
}) => {
  const history = useHistory();

  const processPalletScan = (palletId) => {
    history.push(`/warehouse_moves/pallets/${palletId}`);
  };

  if (fetching) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <PalletScanTextField
        canScan={!palletSearchDrawerOpen}
        onScan={processPalletScan}
      />
      {toDoRequests.length > 0 ? (
        toDoRequests.map((itemMoveRequest, index) => (
          <ItemMoveRequest
            itemMoveRequest={itemMoveRequest}
            index={index}
            key={itemMoveRequest.id}
            last={index === toDoRequests.length - 1}
            signedInUser={signedInUser}
          />
        ))
      ) : (
        <div className={classes.noRequestsMessage}>
          There are currently no new requests
        </div>
      )}
    </div>
  );
};

const itemMoveRequestShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
});

OpenRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  toDoRequests: PropTypes.arrayOf(itemMoveRequestShape).isRequired,
  signedInUser: PropTypes.string.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(OpenRequests);
