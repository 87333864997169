import {
  DROP_LIST_POST_REQUEST,
  DROP_LIST_POST_SUCCESS,
  DROP_LIST_POST_ERROR,
} from 'lib/constants';

export const initialState = {
  posting: false,
  errors: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case DROP_LIST_POST_REQUEST:
      return {
        ...state,
        posting: true,
      };
    case DROP_LIST_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        errors: [],
      };
    case DROP_LIST_POST_ERROR:
      return {
        ...state,
        posting: false,
        errors: action.response.error_list,
      };
    default:
      return state;
  }
}
