import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import { TabOutlineButton, StatefulButton } from 'components/shared';

import styles from './styles';

const buttonText = {
  DEFAULT: 'Save Change',
  SUCCESS: 'Success!',
  LOADING: 'Saving',
  ERROR: 'Failed',
};

const PalletDetailsButtons = ({
  classes,
  onCancelClick,
  onSubmitClick,
  loading,
  failed,
  buttonTextOverrides,
  disabled,
  successCallback,
}) => {
  const combinedButtonText = useMemo(
    () => ({ ...buttonText, ...buttonTextOverrides }),
    [buttonTextOverrides]
  );
  return (
    <div className={classes.buttonContainer}>
      <TabOutlineButton
        type="button"
        onClick={onCancelClick}
        className={classNames(classes.halfButton, classes.cancel)}
      >
        Cancel
      </TabOutlineButton>
      <StatefulButton
        disabled={disabled}
        buttonTextOptions={combinedButtonText}
        loading={loading}
        failed={failed}
        id="clickToSave"
        data-testid="pallet-details-save-button"
        type="button"
        onClick={onSubmitClick}
        resetToNormal={false}
        successCallback={successCallback}
        classes={{
          disabled: classes.buttonDisabled,
          root: classNames(classes.halfButton, classes.submit),
        }}
      />
    </div>
  );
};

PalletDetailsButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
  loading: PropTypes.bool,
  failed: PropTypes.bool,
  buttonTextOverrides: PropTypes.object,
  disabled: PropTypes.bool,
};

PalletDetailsButtons.defaultProps = {
  disabled: false,
  buttonTextOverrides: {},
  successCallback: () => {},
  loading: false,
  failed: false,
};

export default withStyles(styles)(PalletDetailsButtons);
