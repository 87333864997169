import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider } from '@mui/material/styles';

import buttonGroupTheme from 'lib/buttonGroupTheme';
import { ContentModal } from 'components/shared';
import useProductionLocations from 'lib/custom_hooks/useProductionLocation';

import styles from './styles';

const useStyles = makeStyles(styles);

const PickupLocationModal = ({
  isOpen,
  closeModal,
  onSubmit,
  parentPalletId,
}) => {
  const classes = useStyles();

  const { dropzones } = useProductionLocations({
    forceReload: true,
    withPziParentIds: true,
  });

  const [pickupLocationId, setPickupLocationId] = useState(null);

  const onSelectLocation = (_, newLocationId) => {
    setPickupLocationId(newLocationId);
  };

  const handleSubmit = () => {
    onSubmit(pickupLocationId);
  };

  const hasMatches = dropzones.some((zone) =>
    zone.pziParentIds?.includes(parentPalletId)
  );

  return (
    <>
      <ContentModal
        open={isOpen}
        onClose={closeModal}
        title="Select Pickup Location"
        dataTestId="pickupLocationModal"
      >
        <ThemeProvider theme={buttonGroupTheme}>
          <ToggleButtonGroup
            exclusive
            className={classes.buttonGroup}
            value={pickupLocationId}
            onChange={onSelectLocation}
          >
            {dropzones.map((location) => (
              <ToggleButton
                key={location.storageSlotId}
                value={location.storageSlotId}
                className={classes.button}
                {...(hasMatches && {
                  disabled: !location.pziParentIds?.includes(parentPalletId),
                })}
              >
                {location.description}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </ThemeProvider>
        <Button
          variant="contained"
          aria-label="submit-button"
          onClick={handleSubmit}
          disabled={!pickupLocationId}
          fullWidth
        >
          Start Putback
        </Button>
      </ContentModal>
    </>
  );
};

PickupLocationModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

PickupLocationModal.defaultProps = {
  isOpen: false,
};

export default PickupLocationModal;
