import {
  QA_MEAL_BAG_CHECKS_UPDATED,
  QA_MEAL_BAG_CHECKS_RESET,
  QA_MEAL_BAG_CHECKS_POST_REQUEST,
  QA_MEAL_BAG_CHECKS_POST_SUCCESS,
  QA_MEAL_BAG_CHECKS_POST_ERROR,
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST_REQUEST,
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST_SUCCESS,
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST_ERROR,
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_REQUEST,
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_SUCCESS,
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_ERROR,
} from 'lib/constants';

export const initialState = {
  checks: [],
  posting: false,
  postFailed: false,
  postErrorText: null,
  preliminaryCheckId: null,
  checksComplete: false,
  updating: false,
  updateFailed: false,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case QA_MEAL_BAG_CHECKS_UPDATED:
      return {
        ...state,
        checks: state.checks.concat(action.payload),
      };
    case QA_MEAL_BAG_CHECKS_POST_REQUEST:
      return {
        ...state,
        postErrorText: null,
        posting: true,
        postFailed: false,
      };
    case QA_MEAL_BAG_CHECKS_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        postFailed: false,
      };
    case QA_MEAL_BAG_CHECKS_POST_ERROR:
      return {
        ...state,
        postErrorText: action.response.statusText,
        posting: false,
        postFailed: true,
      };
    case QA_MEAL_BAG_CHECKS_RESET:
      return {
        ...state,
        checks: [],
        checksComplete: false,
      };
    case QA_MEAL_BAG_PRELIMINARY_CHECK_POST_REQUEST:
      return {
        ...state,
        posting: true,
        postFailed: false,
        preliminaryCheckId: null,
      };
    case QA_MEAL_BAG_PRELIMINARY_CHECK_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        postFailed: false,
        preliminaryCheckId: action.response.preliminaryCheckId,
      };
    case QA_MEAL_BAG_PRELIMINARY_CHECK_POST_ERROR:
      return {
        ...state,
        posting: false,
        postFailed: true,
      };
    case QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_REQUEST:
      return {
        ...state,
        updating: true,
        updateFailed: false,
      };
    case QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_SUCCESS:
      return {
        ...state,
        updating: false,
        preliminaryCheckId: action.response.preliminaryCheckId,
      };
    case QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_ERROR:
      return {
        ...state,
        updating: false,
        updateFailed: true,
      };
    default:
      return state;
  }
}
