import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getShipment = async (params) => {
  const response = await fetch(`${API_BASE_URI}/ops/scanprint_xray/`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      box_scan: { tracking_number: params.trackingNumber },
    }),
  });

  return await handleResponse(response);
};

export default {
  getShipment,
};
