import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';

import { StatefulButton } from 'components/shared';
import { fetchMenusWithHooks } from 'redux/menu/actions';
import { fetchStorageAreasWithHooks } from 'redux/storageArea/actions';
import { postCountList } from 'redux/cycleCounting/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { BACK_BUTTON, INVENTORY_MANAGER_ACTIONS, TEXT } from 'lib/constants';

import IndexNavLinks from '../IndexNavLinks';
import CreateCountList from './CreateCountList';
import {
  UPDATE_INPUT,
  RESET_CATEGORY_RELATED_INFO,
  SAVE_FILE_UPLOAD,
  RESET_FORM,
  CATEGORY,
  reducer,
  isFormValid,
  formatStateForPost,
} from './formUtils';
import styles from './styles';

const useStyles = makeStyles(styles);

const CreateCountListContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, dispatchState] = useReducer(reducer, {});
  const [submitState, setSubmitState] = useState();
  const menus = useSelector((reduxState) => reduxState.menus.results);
  const storageAreas = useSelector(
    (reduxState) => reduxState.storageAreas.results
  );

  const handleInputChange = (field, value) => {
    dispatchState({ type: UPDATE_INPUT, field, value });

    // reset any category specific info like menus and CSV file
    if (field === CATEGORY) {
      dispatchState({ type: RESET_CATEGORY_RELATED_INFO });
    }
  };

  const handleFileUpload = (file) => {
    dispatchState({ type: SAVE_FILE_UPLOAD, file });
  };

  const handleSubmit = async () => {
    setSubmitState('saving');
    const response = await dispatch(postCountList(formatStateForPost(state)));

    if (response?.ok) {
      setSubmitState('success');
      dispatchState({ type: RESET_FORM });
    } else {
      setSubmitState('error');
    }
  };

  useEffect(() => {
    setTopBarContent({
      leftContent: BACK_BUTTON,
      text: 'Create a Count List',
      middleContent: TEXT,
      rightContent: INVENTORY_MANAGER_ACTIONS,
    });

    dispatch(fetchStorageAreasWithHooks());
    const showExtendedMenu = true;
    dispatch(fetchMenusWithHooks(showExtendedMenu));
  }, []);

  return (
    <>
      <IndexNavLinks />
      <Card className={classes.infoBesidesButton}>
        <CreateCountList
          key={state.formId || 0}
          menus={menus}
          storageAreas={storageAreas}
          onInputChange={handleInputChange}
          onFileUpload={handleFileUpload}
          category={state[CATEGORY]}
        />
        <StatefulButton
          buttonTextOptions={{
            LOADING: 'Saving...',
            SUCCESS: 'Success',
            ERROR: 'There was an error saving',
            DEFAULT: 'Create Count List',
          }}
          type="submit"
          data-testid="submit-button"
          classes={{ root: classes.submitButton }}
          onClick={handleSubmit}
          disabled={!isFormValid(state)}
          loading={submitState === 'saving'}
          failed={submitState === 'error'}
          waitTime={3000} // allow time to see the error msg
        />
      </Card>
    </>
  );
};

export default CreateCountListContainer;
