import React from 'react';
import withStyles from '@mui/styles/withStyles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSwipeable } from 'lib/custom_hooks';

import styles from './styles';

const ListItemLink = ({
  link,
  classes,
  grey,
  white,
  last,
  children,
  className,
  showArrow,
  isSwipeable,
  swipeableBackgroundComponent,
  swipeableProps,
  onClick,
  disabled,
  forceReset,
}) => {
  const { handleMoveStart, handleMove, handleMoveEnd } = useSwipeable(
    swipeableProps
  );

  return (
    <div className={classes.itemRow}>
      {isSwipeable && (
        <div
          className={classes.swipeableBackground}
          data-testid="swipeable-background"
        >
          {swipeableBackgroundComponent}
        </div>
      )}
      <ListItem
        style={forceReset ? { transform: 'translate3d(0px, 0px, 0px)' } : {}}
        to={link}
        component={Link}
        onTouchStart={isSwipeable ? handleMoveStart : undefined}
        onTouchMove={isSwipeable ? handleMove : undefined}
        onTouchEnd={isSwipeable ? handleMoveEnd : undefined}
        onDragStart={isSwipeable ? handleMoveStart : undefined}
        onDrag={isSwipeable ? handleMove : undefined}
        onDragEnd={isSwipeable ? handleMoveEnd : undefined}
        classes={{
          root: classNames({
            [className]: className,
            [classes.grey]: grey,
            [classes.white]: white,
            [classes.last]: last,
          }),
          disabled: classes.disabled,
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
        {showArrow ? (
          <ListItemIcon classes={{ root: classes.icon }}>
            <ArrowIcon fontSize="large" />
          </ListItemIcon>
        ) : (
          ''
        )}
      </ListItem>
    </div>
  );
};

ListItemLink.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  grey: PropTypes.bool,
  white: PropTypes.bool,
  last: PropTypes.bool,
  showArrow: PropTypes.bool,
  isSwipeable: PropTypes.bool,
  swipeableProps: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  swipeableBackgroundComponent: PropTypes.node,
  forceReset: PropTypes.bool,
};

ListItemLink.defaultProps = {
  showArrow: true,
  grey: false,
  white: false,
  last: false,
  className: '',
  isSwipeable: false,
  swipeableProps: {},
  onClick: () => {},
  disabled: false,
  swipeableBackgroundComponent: null,
  forceReset: false,
};

export default withStyles(styles)(ListItemLink);
