import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
  YES_BUTTON_VALUE,
  NO_BUTTON_VALUE,
  NULL_BUTTON_VALUE,
} from 'lib/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

const QuestionButtons = ({ selectedButtonValue, onChange, nullButtonText }) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      className={classes.infoValue}
      value={selectedButtonValue}
      onChange={(_, value) => onChange(value)}
      exclusive
    >
      <ToggleButton className={classes.yesButton} value={YES_BUTTON_VALUE}>
        Yes
      </ToggleButton>
      <ToggleButton className={classes.noButton} value={NO_BUTTON_VALUE}>
        No
      </ToggleButton>
      {nullButtonText && (
        <ToggleButton className={classes.button} value={NULL_BUTTON_VALUE}>
          {nullButtonText}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

QuestionButtons.propTypes = {
  selectedButtonValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  nullButtonText: PropTypes.string,
};

QuestionButtons.defaultProps = {
  selectedButtonValue: undefined,
  nullButtonText: '',
};

export default QuestionButtons;
