import { connect } from 'react-redux';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ActivityLogIcon from '@mui/icons-material/Restore';

import {
  fetchPalletHistories,
  resetPalletHistories,
} from 'redux/palletHistory/actions';

import ActivityLog from './ActivityLog';

const ActivityLogContainer = ({
  data,
  selectedPalletId,
  onFetchPalletHistories,
  loading,
  fetchFailed,
  onResetPalletHistories,
}) => {
  const [showActivityLog, setShowActivityLog] = useState(false);
  const onButtonClick = () => {
    if (!showActivityLog) {
      onFetchPalletHistories(selectedPalletId);
      setShowActivityLog(true);
    }
  };
  const onClose = () => {
    setShowActivityLog(false);
  };
  const onExited = () => {
    onResetPalletHistories();
  };

  return (
    <Fragment>
      <IconButton
        disabled={!selectedPalletId}
        aria-label="Activity Log"
        color="inherit"
        onClick={onButtonClick}
        size="large"
      >
        <ActivityLogIcon />
      </IconButton>
      <ActivityLog
        open={showActivityLog}
        data={data}
        onClose={onClose}
        loading={loading}
        fetchFailed={fetchFailed}
        onExited={onExited}
      />
    </Fragment>
  );
};

ActivityLogContainer.propTypes = {
  data: PropTypes.array.isRequired,
  selectedPalletId: PropTypes.number,
  onFetchPalletHistories: PropTypes.func.isRequired,
  onResetPalletHistories: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
};

ActivityLogContainer.defaultProps = {
  selectedPalletId: null,
};

const mapStateToProps = (state) => ({
  data: state.palletHistories.results,
  selectedPalletId: state.pallets.inventoryApp.selectedPallet.id,
  loading: state.palletHistories.fetching,
  fetchFailed: state.palletHistories.fetchFailed,
});
const mapDispatchToProps = {
  onFetchPalletHistories: fetchPalletHistories,
  onResetPalletHistories: resetPalletHistories,
};

export { ActivityLogContainer as ActivityLogContainerTest };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLogContainer);
