import {
  FSQA_PHOTO_POST_REQUEST,
  FSQA_PHOTO_POST_SUCCESS,
  FSQA_PHOTO_POST_ERROR,
  FSQA_PHOTOS_GET_REQUEST,
  FSQA_PHOTOS_GET_SUCCESS,
  FSQA_PHOTOS_GET_ERROR,
  FSQA_PHOTO_DELETE_REQUEST,
  FSQA_PHOTO_DELETE_SUCCESS,
  FSQA_PHOTO_DELETE_ERROR,
  DEFECT_PHOTOS,
  LOT_CODE_PHOTOS,
  PROP_12_PHOTOS,
} from 'lib/constants';

export const initialPhotoState = {
  lotCodes: [],
  defects: [],
  prop12: [],
  newPhotos: [],
  posting: false,
  postingFailed: false,
  fetching: false,
  fetchingFailed: false,
  deleting: false,
  deletingFailed: false,
};

export function photoReducer(
  state = initialPhotoState,
  action = { type: null }
) {
  switch (action.type) {
    case FSQA_PHOTO_POST_REQUEST:
      return {
        ...state,
        posting: true,
        postingFailed: false,
      };
    case FSQA_PHOTO_POST_SUCCESS:
      return {
        ...state,
        posting: false,
        [LOT_CODE_PHOTOS]: action.response.lot_code_photos,
        [DEFECT_PHOTOS]: action.response.defect_photos,
        [PROP_12_PHOTOS]: action.response.prop12_photos,
        newPhotos: [...state.newPhotos, action.response.added_photo_id],
      };
    case FSQA_PHOTO_POST_ERROR:
      return {
        ...state,
        posting: false,
        postingFailed: true,
      };
    case FSQA_PHOTOS_GET_REQUEST:
      return {
        ...state,
        fetching: true,
        fetchingFailed: false,
      };
    case FSQA_PHOTOS_GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        [LOT_CODE_PHOTOS]: action.response.lot_code_photos,
        [DEFECT_PHOTOS]: action.response.defect_photos,
        [PROP_12_PHOTOS]: action.response.prop12_photos,
      };
    case FSQA_PHOTOS_GET_ERROR:
      return {
        ...state,
        fetching: false,
        fetchingFailed: true,
      };
    case FSQA_PHOTO_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
        deletingFailed: false,
      };
    case FSQA_PHOTO_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        [LOT_CODE_PHOTOS]: action.response.lot_code_photos,
        [DEFECT_PHOTOS]: action.response.defect_photos,
        [PROP_12_PHOTOS]: action.response.prop12_photos,
      };
    case FSQA_PHOTO_DELETE_ERROR:
      return {
        ...state,
        deleting: false,
        deletingFailed: true,
      };
    default:
      return state;
  }
}

export default function (state = {}, action = { type: null }) {
  switch (action.type) {
    case FSQA_PHOTO_POST_REQUEST:
    case FSQA_PHOTO_POST_SUCCESS:
    case FSQA_PHOTO_POST_ERROR:
    case FSQA_PHOTOS_GET_REQUEST:
    case FSQA_PHOTOS_GET_SUCCESS:
    case FSQA_PHOTOS_GET_ERROR:
    case FSQA_PHOTO_DELETE_REQUEST:
    case FSQA_PHOTO_DELETE_SUCCESS:
    case FSQA_PHOTO_DELETE_ERROR:
      return {
        ...state,
        [action.params.palletId]: photoReducer(
          state[action.params.palletId],
          action
        ),
      };
    default:
      return state;
  }
}
