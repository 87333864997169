import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import { resetQaMealBagChecks } from 'redux/qa/mealBagChecks/actions';
import { setTopBarContent } from 'redux/topBar/actions';
import { getMenuSelections } from 'redux/menuSelection/actions';
import {
  HOME_BUTTON,
  STANDARD_MENU_BUTTONS,
  ACCOUNT_DROPDOWN,
} from 'lib/constants';
import { MealGrid } from 'components/shared';

export const MealGridContainer = ({
  menuSelections,
  fetching,
  onSetTopBarContent,
  onGetMenuSelections,
  onResetQaMealBagChecks,
  selectedMenu,
  history,
}) => {
  const baseRoute = '/qa/meal_bagging';

  useEffect(() => {
    onResetQaMealBagChecks();
  }, [onResetQaMealBagChecks]);
  //here is where qa meal bagging component for top bar is set; when this action is called
  //component to return 3 buttons through case statement must be returned here
  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: STANDARD_MENU_BUTTONS,
      rightContent: ACCOUNT_DROPDOWN,
    });
  }, [onSetTopBarContent]);

  // the 3 buttons in the top bar will update redux with the selectedMenu
  // this will get selectedMenu from redux and update the URL and fetch corresponding menu
  useEffect(() => {
    if (!isEmpty(selectedMenu)) {
      history.push(`${baseRoute}/menu/${selectedMenu.id}`);
      onGetMenuSelections(selectedMenu.id, false);
    }
  }, [selectedMenu]);

  return (
    <MealGrid
      meals={groupBy(menuSelections, 'channel')}
      fetching={fetching}
      baseRoute={baseRoute}
    />
  );
};

MealGridContainer.propTypes = {
  menuSelections: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedMenu: PropTypes.object,
  onSetTopBarContent: PropTypes.func.isRequired,
  onGetMenuSelections: PropTypes.func.isRequired,
  onResetQaMealBagChecks: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
};

MealGridContainer.defaultProps = {
  menuSelections: {},
  selectedMenu: null,
};
const mapStateToProps = (state) => ({
  selectedMenu: state.menus.selectedMenu,
  menuSelections: state.menuSelection.menuSelections,
  fetching: state.menuSelection.fetching,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onGetMenuSelections: getMenuSelections,
  onResetQaMealBagChecks: resetQaMealBagChecks,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealGridContainer);
