import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import styles from './styles';
import ReprintPalletDialog from './ReprintPalletDialog';

const mapStateToProps = (state) => ({
  printerOptions: state.printers.printers,
});

export default withStyles(styles)(
  connect(mapStateToProps)(ReprintPalletDialog)
);
