import { combineReducers } from 'redux';

import qaApp from './qaApp/reducers';
import inventoryApp from './inventoryApp/reducers';
import receivingApp from './receivingApp/reducers';

export default combineReducers({
  receivingApp,
  qaApp,
  inventoryApp,
});
