import {
  HC_GREEN,
  WHITE,
  DARK_HC_GREEN,
  GREY_200,
  GREY_600,
} from 'lib/constants';
import { centeredLoading } from 'lib/styles';

const button = {
  height: 55,
  fontSize: 16,
  textTransform: 'none',
};

const continueActionButton = {
  ...button,
  color: WHITE,
  backgroundColor: HC_GREEN,
  '&:hover': {
    backgroundColor: DARK_HC_GREEN,
  },
};

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  header: {
    borderBottom: `1px solid ${GREY_200}`,
    marginBottom: 20,
  },
  closeButton: {
    paddingTop: 20,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: 20,
    height: 20,
    margin: '0 auto',
  },
  searchInput: {
    width: '100%',
  },
  searchButton: {
    ...continueActionButton,
    width: '100%',
  },
  searchButtonContainer: {
    paddingLeft: 18,
  },
  actionButtons: {
    display: 'flex',
    marginBottom: 12,
  },
  cancelButton: {
    display: 'flex',
    ...button,
    marginRight: 20,
    minWidth: 140,
  },
  addItemButton: {
    display: 'flex',
    ...continueActionButton,
    minWidth: 300,
  },
  searchResultsContainer: {
    maxHeight: 420,
    overflow: 'auto',
    marginTop: 20,
    borderTop: `1px solid ${GREY_200}`,
  },
  resultContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: `1px solid ${GREY_200}`,
  },
  marginTop10: {
    marginTop: 10,
  },
  selectedItem: {
    backgroundColor: `${GREY_200}`,
  },
  disabled: {
    backgroundColor: 'white',
    color: `${GREY_600}`,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'not-allowed',
      backgroundColor: 'white',
    },
  },
  noResults: {
    paddingTop: 30,
  },
  centeredLoading,
};
