import { WHITE, BLUE } from 'lib/constants';

export default {
  codeContainer: {
    padding: '14px',
    background: BLUE,
  },
  icon: {
    width: '25px',
    fill: WHITE,
    background: BLUE,
  },
};
