import { WHITE, HC_GREEN, RETAIL_ORANGE, BLUE } from 'lib/constants';

export default {
  chip: {
    justifyContent: 'flex-start',
    maxWidth: '220px',
    transition: 'none',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline',
  },
  completed: {
    color: WHITE,
    background: HC_GREEN,
  },
  inProgress: {
    color: WHITE,
    background: BLUE,
  },
  toDo: {
    color: WHITE,
    background: RETAIL_ORANGE,
  },
  acknowledged: {
    color: WHITE,
    background: BLUE,
  },
};
