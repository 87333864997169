import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { resetStorageSlots } from 'redux/storageSlot/actions';

import DepthSelection from './DepthSelection';
import styles from './styles';

export const mapStateToProps = (state) => {
  return {
    depthOptions: state.itemMoveRequests.depthOptions,
    depthsLoading: state.storageSlots.fetching,
    depthsFailed: state.storageSlots.failed,
    updating:
      state.itemMoveRequests.updating || state.pallets.inventoryApp.updating,
    updatingFailed:
      state.itemMoveRequests.updatingFailed ||
      state.pallets.inventoryApp.updateFailed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onResetDepths: () => dispatch(resetStorageSlots()),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DepthSelection)
);
