import { ROBOTO } from 'lib/constants';

export default {
  actionButton: {
    margin: '8px',
    fontWeight: '800',
    fontFamily: ROBOTO,
    fontSize: '17px',
  },
};
