import React, { useCallback, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import classNames from 'classnames';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import withStyles from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';

import { Loading, StatefulButton } from 'components/shared';
import {
  BACK_BUTTON,
  TEXT,
  ERROR_RED,
  GREY_500,
  HC_GREEN,
  SUCCESS_GREEN,
} from 'lib/constants';

const ShippingOptionsDetails = ({
  classes,
  onSetTopBarContent,
  onGetShipment,
  onCreateReplacementLabel,
  onClearShippingOptionDetails,
  printNewLabel,
  fetching,
  failed,
  creating,
  creatingFailed,
  trackingNumberFromUrl,
  history,
}) => {
  const customerName = useCallback(() => {
    if (printNewLabel.firstName && printNewLabel.lastName) {
      return `${printNewLabel.firstName.charAt(0)}. ${printNewLabel.lastName}`;
    } else if (printNewLabel.firstName) {
      return `${printNewLabel.firstName.charAt(0)}.`;
    } else if (printNewLabel.lastName) {
      return `${printNewLabel.lastName}`;
    } else {
      return '';
    }
  }, [printNewLabel]);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: TEXT,
      text: customerName() ? `${customerName()}` : 'Box-Ray',
      backLocation: '/box-ray/print-new-label',
    });
  }, [customerName, onSetTopBarContent]);

  useEffect(() => {
    onClearShippingOptionDetails();
  }, [onClearShippingOptionDetails, trackingNumberFromUrl]);

  useEffect(() => {
    if (trackingNumberFromUrl) {
      onGetShipment({
        trackingNumber: trackingNumberFromUrl,
      });
    }
  }, [onGetShipment, trackingNumberFromUrl]);

  const [currentShippingRegionId, setCurrentShippingRegionId] = useState(-1);
  const [showButton, setShowButton] = useState(false);

  const handleSubmit = () => {
    const params = {
      shipment_id: printNewLabel.shipmentId,
      shipping_region_id: currentShippingRegionId,
      ship_date: printNewLabel.shipDate,
    };

    onCreateReplacementLabel(params);
  };

  const handleSelectShippingOption = (event) => {
    if (!showButton) setShowButton(true);
    setCurrentShippingRegionId(parseInt(event.target.value));
  };

  if (fetching) {
    return (
      <div className={classes.centeredLoadingContainer}>
        <Loading />
      </div>
    );
  } else if (failed) {
    return (
      <div className={classes.centeredLoadingContainer}>
        <Loading />
        <p className={classes.loadingMessage}>
          Something went wrong. Please reload the page, and try again.
        </p>
      </div>
    );
  } else if (printNewLabel.shipmentId) {
    const { shippingOptions } = printNewLabel;

    const buttonText = {
      DEFAULT: 'Print Shipping Label',
      SUCCESS: 'Success!',
      LOADING: 'Connecting...',
      ERROR: 'Print Request Failed - Try Again',
    };

    const buttonBackgroundColors = {
      SUCCESS: SUCCESS_GREEN,
      ERROR: ERROR_RED,
      DEFAULT: HC_GREEN,
      LOADING: GREY_500,
    };

    const GreenRadio = withStyles({
      root: {
        '&$checked': {
          color: HC_GREEN,
        },
      },
      checked: {},
    })((props) => <Radio color="secondary" {...props} />);
    return (
      <div className={classes.container}>
        <List
          disablePadding
          className={classNames(classes.list, classes.zebra)}
        >
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Scheduled Ship
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              <div className={classes.scheduledShip}>
                <span>{printNewLabel.shippingRegion}</span>
                <span>{printNewLabel.shipDate}</span>
              </div>
            </ListItemText>
          </ListItem>
          <ListItem classes={{ root: classNames(classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              Scheduled Delivery
            </ListItemText>
            <ListItemText disableTypography classes={{ root: classes.value }}>
              {printNewLabel.deliveryDate}
            </ListItemText>
          </ListItem>
        </List>
        <List disablePadding>
          <ListSubheader className={classes.subHeader}>
            <ListItemText
              className={classNames(classes.listItemHeader, classes.whiteText)}
              disableTypography
            >
              SELECT CARRIER
            </ListItemText>
          </ListSubheader>
        </List>
        {shippingOptions && (
          <FormControl
            variant="standard"
            component="fieldset"
            classes={{ root: classes.form }}
          >
            <RadioGroup
              classes={{
                root: classes.radioGroup,
              }}
              aria-label="shipping options"
              name="shipping options"
              value={`${currentShippingRegionId}`}
              onChange={handleSelectShippingOption}
            >
              {shippingOptions.map((option) => {
                return (
                  <Fragment key={option.shippingRegionId}>
                    {shippingOptions.length > 1 &&
                      option.shippingRegionId === 0 && (
                        <Divider
                          data-testid="shipping-option-details-divider"
                          classes={{ root: classes.divider }}
                        />
                      )}
                    <FormControlLabel
                      classes={{
                        root: classes.shippingOption,
                        label: classes.shippingOptionLabel,
                      }}
                      value={`${option.shippingRegionId}`}
                      control={<GreenRadio />}
                      label={
                        <>
                          <span>{option.carrier}</span>
                          {option.currentLabel && (
                            <span className={classes.currentLabel}>
                              Current Label
                            </span>
                          )}
                        </>
                      }
                    />
                  </Fragment>
                );
              })}
            </RadioGroup>
          </FormControl>
        )}
        {showButton && (
          <StatefulButton
            classes={{ root: classes.button }}
            type="submit"
            fullWidth
            loading={creating}
            failed={creatingFailed}
            buttonBackgroundColors={buttonBackgroundColors}
            buttonTextOptions={buttonText}
            onClick={handleSubmit}
            successCallback={() => history.push('/box-ray/printing-label')}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.errorMessage}>Sorry, no shipment was found.</div>
    );
  }
};

ShippingOptionsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  onGetShipment: PropTypes.func.isRequired,
  printNewLabel: PropTypes.object.isRequired,
  trackingNumberFromUrl: PropTypes.string.isRequired,
};

export default ShippingOptionsDetails;
