import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchPalletsFromQuery,
  clearResultsFromQuery,
  togglePalletSearchDrawer,
  storeSearchQuery,
} from 'redux/pallets/actions';
import { selectItemMoveRequest } from 'redux/itemMoveRequests/selectors';

import PalletSearch from './PalletSearch';

const mapStateToProps = (state, ownProps) => ({
  drawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
  packingFacilityId: state.session.packing_facility_id,
  pallets: state.pallets.inventoryApp.queryResults,
  palletFetchingQuery: state.pallets.inventoryApp.palletFetchingQuery,
  queryTerm: state.pallets.inventoryApp.queryTerm,
  itemMoveRequest: selectItemMoveRequest(
    state,
    ownProps.match.params.requestId
  ),
});

const mapDispatchToProps = {
  onSearchItems: fetchPalletsFromQuery,
  onClearResults: clearResultsFromQuery,
  onToggleSearchDrawer: togglePalletSearchDrawer,
  onStoreSearchQuery: storeSearchQuery,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PalletSearch)
);
