import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';

import { formatNumber } from 'lib/utils';

const IngredientProgressRow = ({
  labelOrder,
  mealTitle,
  outstandingCount,
  completedCount,
  goalCount,
  classes,
}) => (
  <Grid container className={classes.gridContainer}>
    <Grid
      item
      xs={1}
      className={classnames(classes.cell, classes.textBold, 'zebra')}
    >
      <div>{labelOrder}</div>
    </Grid>
    <Grid item xs={5} className="zebra">
      <div className={classes.mealTitle}>{mealTitle}</div>
    </Grid>
    {outstandingCount && (
      <Grid
        item
        xs={2}
        className={classnames(classes.textRight, classes.cell, 'zebra')}
      >
        {formatNumber(outstandingCount)}
      </Grid>
    )}
    <Grid
      item
      xs={2}
      className={classnames(classes.textRight, classes.cell, 'zebra')}
    >
      <div>{formatNumber(completedCount)}</div>
    </Grid>
    <Grid
      item
      xs={2}
      className={classnames(classes.textRight, classes.cell, 'zebra')}
    >
      <div>{formatNumber(goalCount)}</div>
    </Grid>
  </Grid>
);

IngredientProgressRow.propTypes = {
  labelOrder: PropTypes.string.isRequired,
  mealTitle: PropTypes.string.isRequired,
  outstandingCount: PropTypes.number.isRequired,
  completedCount: PropTypes.number.isRequired,
  goalCount: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default IngredientProgressRow;
