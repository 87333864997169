export default {
  root: {
    clear: 'both',
  },
  '@media (max-width: 480px)': {
    root: {
      padding: '0px',
      clear: 'both',
    },
  },
};
