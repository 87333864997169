import { HC_GREEN, DARK_HC_GREEN } from 'lib/constants';

export default {
  caseQuantity: {
    margin: '36px 24px',
  },
  quantityInputRoot: {
    borderRadius: '50%',
    width: '55px',
    height: '55px',
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  numberTextField: {
    // disable up/down buttons on number field
    '&::-webkit-inner-spin-button': {
      '::-webkit-appearance': 'none',
      '::-moz-appearance': 'none',
      appearance: 'none',
    },
  },
  numberInput: {
    padding: '0 20px',
  },
  heading: {
    paddingBottom: '20px',
    fontWeight: 'bold',
  },
};
