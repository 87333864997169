import {
  SESSION_UPDATED,
  SESSION_CREATE,
  SESSION_CLEAR,
  SESSION,
  SELECTED_FACILITY_UPDATED,
  LAST_SUCCESSFUL_FETCH_AT_UPDATED,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';

import Session from './services';

export const updateSession = (session) => {
  return {
    type: SESSION_UPDATED,
    payload: { session },
  };
};

export const createSession = (params) => (dispatch) => {
  dispatch(
    makeApiRequest({
      type: SESSION_CREATE,
      method: Session.createSession,
      params,
    })
  );
};

export const clearSession = () => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: SESSION_CLEAR,
      method: Session.clearSession,
    })
  );
};

export const getSession = () => (dispatch) => {
  dispatch(
    makeApiRequest({
      type: SESSION,
      method: Session.getSession,
    })
  );
};

export const updateSelectedFacility = (selectedPackingFacilityId) => {
  return {
    type: SELECTED_FACILITY_UPDATED,
    payload: { selectedPackingFacilityId },
  };
};

export const updateLastSuccessfulFetchAt = (last_successful_fetch_at) => {
  return {
    type: LAST_SUCCESSFUL_FETCH_AT_UPDATED,
    payload: { last_successful_fetch_at },
  };
};
