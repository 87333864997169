import {
  WMS_ITEM_RECEIPT_CREATE_REQUEST,
  WMS_ITEM_RECEIPT_CREATE_SUCCESS,
  WMS_ITEM_RECEIPT_CREATE_ERROR,
  WMS_ITEM_RECEIPT_UPDATE_REQUEST,
  WMS_ITEM_RECEIPT_UPDATE_SUCCESS,
  WMS_ITEM_RECEIPT_UPDATE_ERROR,
} from 'lib/constants';

export const initialState = {
  posting: false,
  postFailed: false,
  updating: false,
  updatingFailed: false,
  itemReceipts: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case WMS_ITEM_RECEIPT_CREATE_REQUEST:
      return {
        ...state,
        posting: true,
        postFailed: false,
        itemReceipts: [],
      };
    case WMS_ITEM_RECEIPT_CREATE_SUCCESS:
      return {
        ...state,
        posting: false,
        postFailed: false,
        itemReceipts: action.response.itemReceipts,
      };
    case WMS_ITEM_RECEIPT_CREATE_ERROR:
      return {
        ...state,
        posting: false,
        postFailed: true,
      };
    case WMS_ITEM_RECEIPT_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
        updatingFailed: false,
        itemReceipts: [],
      };
    case WMS_ITEM_RECEIPT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        itemReceipts: action.response.itemReceipts,
      };
    case WMS_ITEM_RECEIPT_UPDATE_ERROR:
      return {
        ...state,
        updating: false,
        updatingFailed: true,
      };
    default:
      return state;
  }
}
