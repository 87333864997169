import { ROBOTO } from 'lib/constants';

import { formSubmitButton, formDivider, sublotStyles } from '../../styles';

export default {
  formDivider,
  ...sublotStyles,
  mixingFormSubmitButton: {
    ...formSubmitButton,
    margin: '0px',
  },
  mixingDrawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  mixingDrawerTitle: {
    fontFamily: ROBOTO,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '133.4%',
    paddingBottom: '10px',
  },
  mixingDrawerSubtitle: {
    fontFamily: ROBOTO,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },
  mixingDrawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    gap: '24px',
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  questionTitle: {
    fontFamily: ROBOTO,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
