import React from 'react';
import PropTypes from 'prop-types';

const WarehouseMovesIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
      >
        <path
          d="M70.56,60.31H57.39a1.14,1.14,0,0,1-1.13-1.13V20.61a1.31,1.31,0,1,0-2.62,0V47.54H50.8a14,14,0,0,0-.53-2.19,23.94,23.94,0,0,0-1.74-4L34.78,16.5a7.39,7.39,0,0,0-6.48-3.82H13.07A6.67,6.67,0,0,0,6.4,19.34V42.52H6.22a5.07,5.07,0,0,0-5.07,5.06v9.09a5.07,5.07,0,0,0,4.78,5,6.55,6.55,0,0,0,13.1,0H33.42a6.55,6.55,0,0,0,13.09,0,5.08,5.08,0,0,0,4.44-5v-2h2.69v4.54a3.75,3.75,0,0,0,3.75,3.75H70.56a1.31,1.31,0,1,0,0-2.62ZM9,19.34a4.05,4.05,0,0,1,4.05-4H28.3a4.77,4.77,0,0,1,4.18,2.46l8.61,15.59H37.66a1.32,1.32,0,0,0-1.18.74L36,35.16l-2.32-1.11.77-1.7A1.31,1.31,0,0,0,32,31.27L29.44,37a1.31,1.31,0,0,0,.66,1.73,1.24,1.24,0,0,0,.53.12,1.32,1.32,0,0,0,1.2-.77l.74-1.65,2.26,1.08L34,39.22a1.3,1.3,0,0,0,.17,1.41l3.74,4.44v2.75a.81.81,0,0,1-.81.81H25.21a.94.94,0,0,1-.94-.94V46.35a3.84,3.84,0,0,0-3.84-3.83H9Zm3.46,46.21a3.93,3.93,0,1,1,3.93-3.93A3.93,3.93,0,0,1,12.48,65.55Zm27.49,0a3.93,3.93,0,1,1,3.93-3.93A3.93,3.93,0,0,1,40,65.55Zm8.35-8.88A2.44,2.44,0,0,1,46,59.1a6.55,6.55,0,0,0-12.09,0H18.53a6.55,6.55,0,0,0-12.11,0l-.2,0a2.44,2.44,0,0,1-2.44-2.44V47.58a2.44,2.44,0,0,1,2.44-2.44H20.43a1.22,1.22,0,0,1,1.22,1.21v1.34a3.56,3.56,0,0,0,3.56,3.56h11.9a3.44,3.44,0,0,0,3.43-3.43V44.59a1.28,1.28,0,0,0-.31-.84l-3.49-4.16L38.48,36h4.06l3.7,6.7a20.65,20.65,0,0,1,1.54,3.51,10.39,10.39,0,0,1,.54,3.31ZM53.64,52H51V50.16h2.69Z"
          fill="#008600"
        />
        <path
          d="M77.35,39.11H59.29A1.31,1.31,0,0,0,58,40.42V56.94a1.31,1.31,0,0,0,1.31,1.31H77.35a1.3,1.3,0,0,0,1.31-1.31V40.42A1.31,1.31,0,0,0,77.35,39.11ZM76,55.63H60.6V41.74H76Z"
          fill="#008600"
        />
        <path
          d="M59.29,37.68H70.61a1.31,1.31,0,0,0,1.31-1.31V26a1.3,1.3,0,0,0-1.31-1.31H59.29A1.31,1.31,0,0,0,58,26V36.37A1.31,1.31,0,0,0,59.29,37.68ZM60.6,27.33h8.7v7.73H60.6Z"
          fill="#008600"
        />
        <path
          d="M48.32,56.67A2.44,2.44,0,0,1,46,59.1a6.55,6.55,0,0,0-12.09,0H18.53a6.55,6.55,0,0,0-12.11,0l-.2,0a2.44,2.44,0,0,1-2.44-2.44V47.58a2.44,2.44,0,0,1,2.44-2.44H20.43a1.22,1.22,0,0,1,1.22,1.21v1.34a3.56,3.56,0,0,0,3.56,3.56h11.9a3.44,3.44,0,0,0,3.43-3.43V44.59a1.28,1.28,0,0,0-.31-.84l-3.49-4.16L38.48,36h4.06l3.7,6.7a20.65,20.65,0,0,1,1.54,3.51,10.39,10.39,0,0,1,.54,3.31Z"
          fill="#99ce99"
        />
        <path d="M76,55.63H60.6V41.74H76Z" fill="#99ce99" />
        <path d="M60.6,27.33h8.7v7.73H60.6Z" fill="#99ce99" />
      </svg>
    </div>
  );
};

WarehouseMovesIcon.propTypes = {
  className: PropTypes.string,
};

WarehouseMovesIcon.defaultProps = {
  className: 'WarehouseMovesIcon',
};

export default WarehouseMovesIcon;
