import { options, handleResponse } from 'lib/homeChefApi';
import { buildIngredientAssignmentQuery } from 'lib/utils';
import { API_BASE_URI } from 'lib/constants';

const getMenuSelections = async ({ menuId, forRth = '' }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/production_inventories/menu_selections?menu_id=${menuId}&for_ready_to_heat=${forRth}`,
    {
      ...options,
    }
  );

  return await handleResponse(response);
};

const getMealsForIngredientAssignment = async ({
  ingredientAssignmentIds = [],
  activity = '',
}) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/ingredient_assignments/?activity=${activity}&${buildIngredientAssignmentQuery(
      ingredientAssignmentIds
    )}`,
    options
  );

  return await handleResponse(response);
};

export default {
  getMenuSelections,
  getMealsForIngredientAssignment,
};
