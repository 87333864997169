import {
  WAREHOUSE_REQUESTS_PACKAGING_ITEMS_REQUEST,
  WAREHOUSE_REQUESTS_PACKAGING_ITEMS_SUCCESS,
  WAREHOUSE_REQUESTS_PACKAGING_ITEMS_ERROR,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  failed: false,
  results: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case WAREHOUSE_REQUESTS_PACKAGING_ITEMS_REQUEST:
      return {
        ...state,
        fetching: true,
        failed: false,
      };
    case WAREHOUSE_REQUESTS_PACKAGING_ITEMS_SUCCESS:
      return {
        ...state,
        fetching: false,
        failed: false,
        results: action.response.packagingItems,
      };
    case WAREHOUSE_REQUESTS_PACKAGING_ITEMS_ERROR:
      return {
        ...state,
        fetching: false,
        failed: true,
      };
    default:
      return state;
  }
}
