import { makeApiRequest } from 'lib/api';
import {
  NETSUITE_ORDER_QUERY,
  NETSUITE_ORDER_STORE_SEARCH,
  NETSUITE_ORDER_CLEAR_SEARCH,
} from 'lib/constants';

import NetsuiteOrders from './services';

export const fetchPOsFromQuery = () => async (dispatch, getState) => {
  const { netsuiteOrders, session } = getState();
  return await dispatch(
    makeApiRequest({
      type: NETSUITE_ORDER_QUERY,
      method: NetsuiteOrders.getFromQuery,
      params: {
        packingFacilityId: session.packing_facility_id,
        searchQuery: netsuiteOrders.receiving.queryTerm,
      },
    })
  );
};

export const storeSearchQuery = (searchQuery) => {
  return {
    type: NETSUITE_ORDER_STORE_SEARCH,
    payload: { searchQuery },
  };
};

export const clearSearchQuery = () => {
  return {
    type: NETSUITE_ORDER_CLEAR_SEARCH,
  };
};
