import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';

import { TabOutlineButton, PalletScanTextField } from 'components/shared';
import { QRCodeIcon } from 'components/icons';
import {
  HOME_BUTTON,
  PHONE_SCREEN,
  TABLET_SCREEN,
  INVENTORY_MANAGER_ACTIONS,
} from 'lib/constants';

import styles from './styles';

const InventoryLandingPage = ({
  onSetTopBarContent,
  classes,
  history,
  palletSearchDrawerOpen,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const tabletScreen = useMediaQuery(TABLET_SCREEN);

  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: null,
      rightContent: INVENTORY_MANAGER_ACTIONS,
    });
  }, [onSetTopBarContent]);

  const onBrowseButtonClick = () => {
    history.push('/inventory-manager/storage-areas');
  };

  const onLocationInvestigationClick = () => {
    history.push('/inventory-manager/location-investigation');
  };

  const showQRIcon = phoneScreen || tabletScreen;

  const processPalletScan = (palletId) => {
    history.push(`/inventory-manager/pallets/${palletId}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.scanningArea}>
        {showQRIcon && (
          <div className={classes.iconContainer}>
            <QRCodeIcon className={classes.icon} />
            Scan Pallet to move or adjust
          </div>
        )}
        <PalletScanTextField
          canScan={!palletSearchDrawerOpen}
          onScan={processPalletScan}
        />
      </div>
      <div className={classes.buttonContainer}>
        <TabOutlineButton
          id="browseLocationsButton"
          type="button"
          className={classes.browseButton}
          onClick={onBrowseButtonClick}
        >
          Browse Locations
          <ArrowIcon />
        </TabOutlineButton>
        <TabOutlineButton
          id="locationsInvestigationButton"
          type="button"
          className={classes.browseButton}
          onClick={onLocationInvestigationClick}
        >
          Location Investigation
          <ArrowIcon />
        </TabOutlineButton>
      </div>
    </div>
  );
};

InventoryLandingPage.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(InventoryLandingPage);
