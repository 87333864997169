import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import {
  getShipment,
  clearShipmentDetails,
} from 'redux/boxRay/viewBoxDetails/actions';
import { setTopBarContent } from 'redux/topBar/actions';

import ShipmentDetails from './ShipmentDetails';
import styles from './styles';

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onGetShipment: getShipment,
  onClearShipmentDetails: clearShipmentDetails,
};

const mapStateToProps = (state, ownProps) => ({
  trackingNumberFromUrl: ownProps.match.params.trackingNumber,
  viewBoxDetails: state.viewBoxDetails.results,
  fetching: state.viewBoxDetails.fetching,
  failed: state.viewBoxDetails.failed,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShipmentDetails));
