import React, { useMemo } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const StyledLinearProgress = styled(LinearProgress)(() => styles.progressBar);

const ProgressVisuals = ({ countList }) => {
  const { completed = 0, total = 0, onHold = 0 } = countList;
  const classes = useStyles();

  const computePercentComplete = useMemo(() => {
    if (!total) return 0;

    return parseInt((completed / total) * 100);
  });

  return (
    <div className={classes.sticky}>
      <StyledLinearProgress
        variant="determinate"
        value={computePercentComplete}
      />
      <div className={classes.progressTextArea}>
        <Typography
          color="primary"
          component="strong"
        >{`${completed}/${total} pallets checked`}</Typography>
        <Typography
          color="error"
          component="strong"
        >{`${onHold} pallets on hold`}</Typography>
      </div>
    </div>
  );
};

ProgressVisuals.propTypes = {
  countList: PropTypes.shape({
    completed: PropTypes.number,
    total: PropTypes.number,
    onHold: PropTypes.number,
  }),
};

ProgressVisuals.defaultProps = {
  countList: {},
};

export default ProgressVisuals;
