import { HC_GREEN } from 'lib/constants';

export default {
  link: {
    padding: '0px 20px 5px 20px',
    textDecoration: 'none',
    color: '#000000',
  },
  active: {
    color: HC_GREEN,
    borderBottom: `4px solid ${HC_GREEN}`,
  },
  activityLinks: {
    paddingBottom: '22px',
    paddingTop: '10px',
    display: 'inline-block',
  },
};
