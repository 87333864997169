import {
  DARK_POSITIVE_GREEN,
  LIGHT_POSITIVE_GREEN,
  DARK_NEGATIVE_RED,
  LIGHT_NEGATIVE_RED,
  GREY_200,
  GREY_650,
} from 'lib/constants';

const defaultStyles = {
  width: '40px',
  borderRadius: '15px',
  fontWeight: 'bolder',
  padding: '3px',
  margin: '-3px auto',
  textAlign: 'center',
};

export default {
  aheadOfTarget: {
    ...defaultStyles,
    background: DARK_POSITIVE_GREEN,
    color: LIGHT_POSITIVE_GREEN,
  },
  belowTarget: {
    ...defaultStyles,
    background: LIGHT_NEGATIVE_RED,
    color: DARK_NEGATIVE_RED,
  },
  onTarget: {
    ...defaultStyles,
    background: GREY_200,
    color: GREY_650,
  },
};
