import React, { useReducer, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import find from 'lodash/find';
import withStyles from '@mui/styles/withStyles';

import {
  PodLocationQuestion,
  FormContainer,
  StatefulButton,
  Loading,
} from 'components/shared';
import {
  BULK,
  LABELED_PACKAGING,
  PORTIONED_INGREDIENTS,
  CHICAGO_FACILITY_ID,
} from 'lib/constants';
import { usePrevious } from 'lib/custom_hooks';
import { sortByName } from 'lib/viewHelpers';

import styles from './styles';
import {
  reducer,
  initialState,
  conversionHelperText,
  UPDATE_INTERNAL_LOCATION_NAME,
  UPDATE_SELECTED_INGREDIENTS,
  UPDATE_SELECTED_REQUESTABLE_COUNT,
  REMOVE_REQUESTED_PACKAGING,
  REMOVE_REQUESTED_INGREDIENT,
} from './formUtils';

const PickableRequestsForm = ({
  podLocations,
  classes,
  onGetPickableIngredientAssignments,
  onSubmitMoveRequests,
  ingredientAssignments,
  iaFetching,
  itemMoveRequests,
  history,
  location,
  packingFacilityId,
}) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedMenu } = location.state;
  const {
    internalLocationName,
    selectedIngredientAssignments,
    selectedIngredientPackagings,
    defaultCount,
    selectedRequestablesWithCounts,
  } = state;

  const pickableProteinIngredientAssignments = ingredientAssignments.filter(
    (ia) => ia.isProtein === true
  );

  const pickableNonProteinIngredientAssignments = ingredientAssignments.filter(
    (ia) => ia.isProtein === false
  );

  const hasBaseCountError = (countState) => {
    return (
      countState &&
      (countState.requestCount < 1 || countState.requestCount > 99999)
    );
  };

  const hasBPAddonMaxError = (countState) => {
    return (
      countState &&
      packingFacilityId === CHICAGO_FACILITY_ID &&
      !countState.isProtein &&
      (countState.requestCount < 1 || countState.requestCount > 100)
    );
  };

  const allFilledOut =
    internalLocationName !== '' &&
    (selectedIngredientAssignments.length > 0 ||
      selectedIngredientPackagings.length > 0) &&
    selectedIngredientAssignments.length ===
      selectedRequestablesWithCounts.length &&
    // Check that it is not the default value && value is inside of bounds.
    selectedRequestablesWithCounts.every(
      (req) =>
        req.requestCount && !hasBaseCountError(req) && !hasBPAddonMaxError(req)
    );

  const prevMoveRequests = usePrevious(itemMoveRequests);

  const requestsSuccessfullyCreated =
    !itemMoveRequests.posting &&
    prevMoveRequests &&
    itemMoveRequests.length > prevMoveRequests.length &&
    !itemMoveRequests.errors;

  useEffect(() => {
    onGetPickableIngredientAssignments(selectedMenu.id);
  }, [selectedMenu, onGetPickableIngredientAssignments]);

  useEffect(() => {
    if (requestsSuccessfullyCreated) {
      history.push('/warehouse_requests/queue');
    }
  }, [
    history,
    itemMoveRequests,
    prevMoveRequests,
    requestsSuccessfullyCreated,
  ]);

  const submitRequests = () => {
    setDisableSubmitButton(true);
    onSubmitMoveRequests(
      selectedRequestablesWithCounts,
      internalLocationName,
      selectedMenu.id,
      selectedMenu.menuType
    );
  };

  const determineId = (request) => {
    if (request.requestAnything) {
      return request.requestableId;
    } else if (request.isPackagingItem) {
      return request.id;
    } else {
      return request.ingredientId;
    }
  };

  const determineReqType = (requestable) => {
    if (requestable.isPackagingItem) {
      return requestable.isDisposableItem ? 'DisposableItem' : 'PackagingItem';
    } else if (requestable.requestAnything) {
      return requestable.requestableType;
    } else {
      return 'Ingredient';
    }
  };

  const requestableMatcher = (requestable) => {
    return {
      requestableId: determineId(requestable),
      requestableType: determineReqType(requestable),
      category: requestable.category || BULK, //We have this || specifically for requestAnything
      ingredientAssignmentId: requestable.ingredientAssignmentId || null,
      isProtein: requestable.isProtein,
    };
  };

  const removeRequestedItem = (requestable) => {
    return requestable.isPackagingItem ||
      requestable?.requestableType === 'PackagingItem'
      ? dispatch({
          type: REMOVE_REQUESTED_PACKAGING,
          value: {
            removedId: determineId(requestable),
            requestable: requestable,
          },
        })
      : dispatch({
          type: REMOVE_REQUESTED_INGREDIENT,
          value: {
            removedId: determineId(requestable),
            requestable: requestable,
          },
        });
  };

  const ingredientButtons = (ingredients) => {
    if (!iaFetching) {
      const baseIngredientButtons = () => {
        return sortByName(ingredients).map((ingredient) => {
          return (
            <ToggleButton
              className={classes.ingButton}
              key={ingredient.ingredientId}
              value={ingredient}
            >
              {ingredient.category === BULK && (
                <div className={classes.columns}>
                  <div className={classes.ingName}>{ingredient.name}</div>
                  <div className={classes.brandForIng}>{ingredient.brand}</div>
                </div>
              )}
              {ingredient.category === PORTIONED_INGREDIENTS && (
                <div>
                  {ingredient.name}
                  <div className="ingButton2">{`${ingredient.quantity} ${ingredient.unitOfMeasure}`}</div>
                </div>
              )}
            </ToggleButton>
          );
        });
      };
      return ingredients[0]?.category === BULK
        ? [baseIngredientButtons()]
        : [baseIngredientButtons()];
    } else {
      return (
        <div className={classes.RequestFormCenteredLoading}>
          <Loading />
        </div>
      );
    }
  };

  const requestedPortionsNaming = (requestable) => {
    if (requestable.category === LABELED_PACKAGING) {
      return `Labeled ${requestable.ingredientName} ${requestable.name}:`;
    } else if (requestable.category === PORTIONED_INGREDIENTS) {
      return `Portioned ${requestable.name} - ${requestable.quantity} ${requestable.unitOfMeasure}:`;
    } else {
      return `${requestable.name}:`;
    }
  };

  const errorText = (selectedWithRequestCount) => {
    if (hasBPAddonMaxError(selectedWithRequestCount)) {
      return 'Please enter a value greater than 0 and no more than 100';
    } else if (hasBaseCountError(selectedWithRequestCount)) {
      return 'Please enter a value greater than 0 and less than 100,000';
    } else {
      return '';
    }
  };

  return (
    <Fragment>
      <FormContainer>
        <Card>
          <PodLocationQuestion
            podLocations={podLocations}
            internalLocationName={internalLocationName}
            setInternalLocationName={(value) =>
              dispatch({
                type: UPDATE_INTERNAL_LOCATION_NAME,
                value: value,
              })
            }
            questionText="Drop off location"
          />
        </Card>
        <Card>
          <h3>Choose Pickables to Request</h3>
          <h3>Proteins</h3>
          <ToggleButtonGroup
            className={classes.buttonGroup}
            size="large"
            value={selectedIngredientAssignments}
            onChange={(e, ingredients) => {
              dispatch({
                type: UPDATE_SELECTED_INGREDIENTS,
                value: ingredients,
              });
            }}
          >
            {ingredientButtons(pickableProteinIngredientAssignments)}
          </ToggleButtonGroup>
          <h3>Add Ons</h3>
          <ToggleButtonGroup
            className={classes.buttonGroup}
            size="large"
            value={selectedIngredientAssignments}
            onChange={(e, ingredients) => {
              dispatch({
                type: UPDATE_SELECTED_INGREDIENTS,
                value: ingredients,
              });
            }}
          >
            {ingredientButtons(pickableNonProteinIngredientAssignments)}
          </ToggleButtonGroup>
        </Card>
        {selectedIngredientAssignments.length > 0 && (
          <Card>
            <h3>Enter Quantity of Portions to Request</h3>
            {selectedIngredientAssignments.map((requestable) => {
              const selectedWithRequestCount = find(
                selectedRequestablesWithCounts,
                requestableMatcher(requestable)
              );

              return (
                <Fragment
                  key={`${requestable.id}-${requestable.ingredientAssignmentId}-${requestable.requestableId}`}
                >
                  <div className={classes.gridRequestsThreeItems}>
                    <h3 className={classes.ingredientRequestPadding}>
                      {requestedPortionsNaming(requestable)}
                    </h3>
                    <TextField
                      value={
                        (selectedWithRequestCount &&
                          selectedWithRequestCount.requestCount) ||
                        defaultCount
                      }
                      onChange={(e) =>
                        dispatch({
                          type: UPDATE_SELECTED_REQUESTABLE_COUNT,
                          value: {
                            ...requestableMatcher(requestable),
                            count: e.target.value,
                          },
                        })
                      }
                      label="# TO REQUEST"
                      placeholder="0"
                      onSelect={(e) => e.preventDefault()}
                      type="number"
                      onFocus={(e) => e.target.select()}
                      variant="outlined"
                      error={
                        hasBPAddonMaxError(selectedWithRequestCount) ||
                        hasBaseCountError(selectedWithRequestCount)
                      }
                      helperText={`${errorText(selectedWithRequestCount)}`}
                      className={classes.textField}
                    />
                    <div className={classes.portionToCasesRatio}>
                      {conversionHelperText(
                        requestable,
                        selectedWithRequestCount?.requestCount
                      )}
                    </div>
                    <IconButton
                      data-testid="delete-icon"
                      key={requestable.id}
                      aria-label="Delete"
                      color="inherit"
                      className={classes.trashIcon}
                      onClick={() => removeRequestedItem(requestable)}
                      size="large"
                    >
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </div>
                </Fragment>
              );
            })}
          </Card>
        )}
        <div className={classes.buttonsContainer}>
          <StatefulButton
            loading={itemMoveRequests.posting}
            failed={itemMoveRequests.postingFailed}
            disabled={!allFilledOut || disableSubmitButton}
            fullWidth
            classes={{
              disabled: classes.buttonDisabled,
              root: classes.submitButton,
            }}
            onClick={submitRequests}
            type="submit"
          />
        </div>
      </FormContainer>
    </Fragment>
  );
};

PickableRequestsForm.propTypes = {
  podLocations: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onGetPickableIngredientAssignments: PropTypes.func.isRequired,
  onSubmitMoveRequests: PropTypes.func.isRequired,
  ingredientAssignments: PropTypes.array.isRequired,
  iaFetching: PropTypes.bool.isRequired,
  itemMoveRequests: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
};

export default withStyles(styles)(PickableRequestsForm);
