import React from 'react';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const WarehouseReceivingIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 127 69.15"
        width="100%"
        height="100%"
      >
        <title>Receiving</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Check">
            <path
              fill={HC_GREEN}
              d="M73.66,45.9,59.07,31.31A2.35,2.35,0,0,1,62.4,28L73.66,39.24,97.48,15.41a2.35,2.35,0,1,1,3.33,3.33Z"
            />
          </g>
          <g id="Delivery_Truck" data-name="Delivery Truck">
            <g>
              <polygon
                fill={HC_GREEN}
                points="13.7 36.01 27.97 21.62 33.46 21.62 33.46 36.01 13.7 36.01"
              />
            </g>
            <path
              fill={HC_GREEN}
              d="M39.69,0V11.5H22.44L0,35.33v25.2H6.15a2.5,2.5,0,1,0,0-5H5V37.31L24.6,16.5H39.69v39H34a11.12,11.12,0,1,0,0,5H89.13a2.5,2.5,0,0,0,0-5H44.69V5H122V55.53h-5.1a11.12,11.12,0,1,0,0,5H127V0ZM23.21,63.18A5.15,5.15,0,1,1,28.36,58,5.15,5.15,0,0,1,23.21,63.18Zm82.86,0A5.15,5.15,0,1,1,111.22,58,5.14,5.14,0,0,1,106.07,63.18Z"
            />
            <g>
              <circle
                fillOpacity={0.4}
                fill={HC_GREEN}
                cx="23.21"
                cy="58.03"
                r="5.15"
              />
            </g>
            <g>
              <circle
                fillOpacity={0.4}
                fill={HC_GREEN}
                cx="106.1"
                cy="58.03"
                r="5.15"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

WarehouseReceivingIcon.propTypes = {
  className: PropTypes.string,
};

WarehouseReceivingIcon.defaultProps = {
  className: 'WarehouseReceivingIcon',
};

export default WarehouseReceivingIcon;
