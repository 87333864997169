import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { setTopBarContent } from 'redux/topBar/actions';
import { selectItemMoveRequest } from 'redux/itemMoveRequests/selectors';
import {
  updateItemMoveRequest,
  fetchSuggestedPallet,
  clearSuggestedPallet,
} from 'redux/itemMoveRequests/actions';

import styles from '../styles';
import ViewOpenRequest from './ViewOpenRequest';

const mapStateToProps = (state, ownProps) => ({
  signedInUser: state.session.user_name,
  itemMoveRequest: selectItemMoveRequest(
    state,
    ownProps.match.params.requestId
  ),
  updating: state.itemMoveRequests.updating,
  updatingFailed: state.itemMoveRequests.updatingFailed,
  postingGuidedPickingOverride: state.guidedPickingOverride.posting,
  postingGuidedPickingOverrideFailed: state.guidedPickingOverride.postingFailed,
  palletSearchDrawerOpen: state.pallets.inventoryApp.searchDrawerOpen,
  suggestedPallet: state.itemMoveRequests.suggestedPallet,
  fetchingSuggestedPallet: state.itemMoveRequests.fetchingSuggestedPallet,
});

const mapDispatchToProps = {
  onUpdateItemMoveRequest: updateItemMoveRequest,
  onSetTopBarContent: setTopBarContent,
  onFetchSuggestedPallet: fetchSuggestedPallet,
  onClearSuggestedPallet: clearSuggestedPallet,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ViewOpenRequest)
);
