import { MEDIA_QUERY_PHONE_SCREEN } from 'lib/constants';

export default {
  label: {
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
    fontSize: '14px',
    color: '#333',
  },
  icon: {
    width: '80px',
    height: '80px',
    margin: '0 auto',
  },
  card: {
    width: '160px',
    height: '140px',
    position: 'relative',
    zIndex: '1',
  },
  wip: {
    width: '150px',
    background: '#51984d',
    position: 'absolute',
    top: '15px',
    right: '-50px',
    left: 'auto',
    textAlign: 'center',
    lineHeight: '24px',
    letterSpacing: '1px',
    color: '#f0f0f0',
    transform: 'rotate(45deg)',
    zIndex: '0',
  },
  cardContent: {
    paddingBottom: '23px',
    padding: '16px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    icon: {
      width: '30px',
      height: '30px',
      margin: '0 auto',
    },
    cardContent: {
      padding: '0',
      paddingBottom: '0',
    },
    card: {
      minWidth: '80px',
      width: '88px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '70px',
    },
    label: {
      maxWidth: '80px',
    },
  },
};
