export default {
  noRequestsMessage: {
    margin: '15px 10px',
    fontSize: '15px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '60px',
  },
};
