import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

import styles from './styles';

// The secondary table is the collapsible content
// isOpen and setIsOpen props are passed in from HOC CollapsibleArea
export const SecondaryTable = withStyles(styles)(
  ({ classes, isOpen, colSpan, children }) => (
    <TableRow>
      <TableCell classes={{ root: classes.secondaryTable }} colSpan={colSpan}>
        <Collapse in={isOpen} unmountOnExit>
          <Table size="small">{children}</Table>
        </Collapse>
      </TableCell>
    </TableRow>
  )
);

export const SecondaryTableHeadRow = withStyles(styles)(
  ({ classes, children }) => (
    <TableHead>
      <TableRow classes={{ head: classes.tableRowHead }}>{children}</TableRow>
    </TableHead>
  )
);

export const SecondaryTableHead = withStyles(
  styles
)(({ classes, children }) => (
  <TableCell classes={{ head: classes.secondaryTableHead }}>
    {children}
  </TableCell>
));

SecondaryTable.defaultProps = {
  colSpan: 1,
  isOpen: false,
};

SecondaryTable.propTypes = {
  isOpen: PropTypes.bool,
  colSpan: PropTypes.number,
  children: PropTypes.node.isRequired,
};

SecondaryTableHeadRow.propTypes = {
  children: PropTypes.node.isRequired,
};

SecondaryTableHead.defaultProps = {
  children: null,
};

SecondaryTableHead.propTypes = {
  children: PropTypes.node,
};
