import { HC_GREEN, WHITE, LIGHT_GREEN, DARK_NEGATIVE_RED } from 'lib/constants';

export default {
  formFooter: {
    width: '600px',
    height: '82px',
    background: WHITE,
    position: 'fixed',
    bottom: '0',
  },
  parentContainer: {
    display: 'flex',
  },
  childContainer: {
    verticalAlign: 'middle',
    flex: '1',
  },
  button: {
    backgroundColor: HC_GREEN,
    color: `${WHITE} !important`,
    fontSize: 17,
    float: 'right',
    padding: '8px 22px',
    margin: '18px 24px',
    '&:disabled': {
      color: WHITE,
      backgroundColor: LIGHT_GREEN,
    },
  },
  errorMessage: {
    color: DARK_NEGATIVE_RED,
    float: 'left',
    margin: '28px 15px',
  },
};
