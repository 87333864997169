import format from 'date-fns/format';

export function minuteDiffFromNow(date) {
  const minuteDiff = Math.abs(Date.now() - new Date(date));
  return Math.floor(minuteDiff / 1000 / 60);
}

export const msToHrMin = (ms) => {
  let minutes = Math.floor((ms / (1000 * 60)) % 60),
    hours = Math.floor(ms / (1000 * 60 * 60));

  hours = hours === 0 ? '' : `${hours} hr `;
  minutes = `${minutes} min`;

  return hours + minutes;
};

export const formatDateHyphenated = (date) => {
  const dateStringRegex = RegExp(`^\\d{4}\/{1}\\d{2}\/\\d{2}$`); //eslint-disable-line

  if (dateStringRegex.test(date)) {
    return date.replace(/\//g, '-');
  } else {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
  }
};

export const dateTimeInTimeZone = (timeZone, date = new Date()) => {
  let options = {
      timeZone: timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    formatter = new Intl.DateTimeFormat([], options);

  return new Date(formatter.format(date));
};

/**
 *
 util static method to convert date string to time
 @param {string} date
 @returns {String} The extracted time from the date/time string or an empty string
 **/
export const utcToTime = (date) => {
  if (date) {
    const convertedDate = new Date(new Date(Date.parse(date)).toUTCString());
    let minute = convertedDate.getUTCMinutes();
    minute = minute == 0 ? '00' : minute;
    const hour = convertedDate.getUTCHours();
    let time =
      `${hour}:${minute}`
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  } else {
    return '';
  }
};

export const formatTime = (time) => {
  if (time) {
    const date = new Date(`1 Jan 2000, ${time}`);
    var hour = date.getHours();
    var minutes = date.getMinutes();
    minutes = minutes == 0 ? '00' : minutes;
    var dayMode = hour < 12 ? 'AM' : 'PM';
    var hours = hour <= 12 ? (hour == 0 ? 12 : hour) : hour - 12;
    return `${hours}:${minutes} ${dayMode}`;
  } else {
    return '';
  }
};

export const getTimeFromDate = (date) => {
  var hour = date.getHours();
  var minutes = date.getMinutes();
  minutes = minutes == 0 ? '00' : ('0' + minutes).slice(-2);
  var dayMode = hour < 12 ? 'AM' : 'PM';
  var hours = hour <= 12 ? (hour == 0 ? 12 : hour) : hour - 12;

  return `${hours}:${minutes} ${dayMode}`;
};

/**
   *
   util static method to format date as DDMMYY
   @param {String} date
   Expects YYYY-MM-DD
   @returns {String}
   **/
export const formatDateAsDDMMYY = (date) => {
  if (date) {
    var splitDate = date.split('-');
    if (splitDate.count == 0) {
      return null;
    }
    const removePreceedingZeroRegex = /^0+/;
    var year = splitDate[0] ? splitDate[0].substr(-2) : null;
    var month = splitDate[1]
      ? splitDate[1].replace(removePreceedingZeroRegex, '')
      : null;
    var day = splitDate[2]
      ? splitDate[2].replace(removePreceedingZeroRegex, '')
      : null;
    if (month && day && year) {
      return month + '/' + day + '/' + year;
    } else {
      return '';
    }
  }
};

// expects a string in 'YYYY-MM-DD' format
export const monthSlashDay = (dateString) => {
  if (dateString) {
    var splitDate = dateString.split('-');
    if (splitDate.count == 0) {
      return null;
    }
    const removePreceedingZeroRegex = /^0+/;
    var month = splitDate[1]
      ? splitDate[1].replace(removePreceedingZeroRegex, '')
      : null;
    var day = splitDate[2]
      ? splitDate[2].replace(removePreceedingZeroRegex, '')
      : null;
    if (month && day) {
      return month + '/' + day;
    } else {
      return '';
    }
  }
};

export const formatDateAsDaynameMdd = (date) => {
  return format(date, 'iiii - M/dd');
};

export const formatDateAsDaynameMddyyyy = (date) => {
  return format(date, 'iiii - M/dd/yyyy');
};

export const formatDateAsMdd = (date) => {
  return format(date, 'M/dd');
};

export const formatDateAsyyyyMMdd = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatDateAsDayname = (date) => {
  return format(date, 'iiii');
};

export const filterDateAsDDMMYY = (date) => {
  return (
    (date.getMonth() + 1).toString() +
    '/' +
    date.getDate().toString() +
    '/' +
    date.getFullYear().toString().substr(-2)
  );
};

export const formatTimeAsHHMM = (date) => format(new Date(date), 'kk:mm');
