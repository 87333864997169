import { BULK, LABELED_PACKAGING, DISPOSABLE_ITEM } from 'lib/constants';

export function getMoveRequestDisplayName({
  category,
  name,
  workInProgressInfo,
}) {
  const {
    ingredientDisplayName,
    iaQuantity,
    iaMeasure,
    letterAssignment = '',
  } = workInProgressInfo || {};

  if (category === BULK || category === DISPOSABLE_ITEM) {
    return name;
  } else if (category === LABELED_PACKAGING) {
    const displayString = workInProgressInfo
      ? `Labeled ${name} - ${ingredientDisplayName} (${iaQuantity} ${iaMeasure})`
      : `Labeled ${name}`;
    return displayString;
  } else {
    const displayString = workInProgressInfo
      ? `Portions: ${letterAssignment} - ${ingredientDisplayName} (${iaQuantity} ${iaMeasure})`
      : `Portions: ${name}`;
    return displayString;
  }
}
