import {
  DARK_HC_GREEN,
  HC_GREEN,
  BLACK,
  WHITE,
  ERROR_RED,
  DARK_RED,
  BLUE,
  GREY_100,
  GREY_200,
  GREY_400,
  GREY_500,
  GREY_600,
  GREY_750,
  GREY_800,
} from 'lib/constants';

// Re-usable styles
export const qaStyles = {
  container: {
    margin: '20px 10%',
  },
  buttonGroup: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
    marginBottom: '20px',
  },
  textLabelGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  select: {
    marginBottom: '20px',
  },
  heading: {
    flexGrow: '1',
    margin: '10px 0 10px',
    fontWeight: 'normal',
  },
  headingMoreTopMargin: {
    flexGrow: '1',
    margin: '30px 0 10px',
    fontWeight: 'normal',
    fontSize: '18px',
  },
  button: {
    flex: '1 0 47%', // Ensures 2 max items per row. (flexGrow flexShrink flexBasis)
    borderRadius: '5px',
    display: 'block',
    fontSize: '16px',
    color: BLACK,
  },
  ingButton: {
    flex: '1 0 31%',
    fullWidth: 'false',
    display: 'block',
    fontSize: 'fit-width',
    overflow: 'hidden',
    color: BLACK,
    '&.Mui-selected': {
      color: BLACK,
    },
  },
  textField: {
    flex: '1 0 47%',
    borderRadius: '5px',
    margin: '5px',
    fontSize: '16px',
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${HC_GREEN} !important`,
    },
  },
  cssFocused: {
    borderColor: HC_GREEN,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${HC_GREEN} !important`,
  },

  startCheckButton: {
    marginTop: '50px',
    color: `${WHITE} !important`,
    fontSize: '16px',
    backgroundColor: HC_GREEN,
    height: '75px',
    borderRadius: '10px',
    '&.Mui-disabled': {
      backgroundColor: GREY_200,
      color: `${GREY_600} !important`,
    },
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  weightSubHeader: {
    color: BLUE,
    fontSize: '12px',
    margin: '0px 0px 5px 0px',
  },
  buttonYes: {
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
    flex: '1 0 47%',
    borderRadius: '5px',
    display: 'block',
    fontSize: '16px',
    color: BLACK,
  },
  buttonNo: {
    '&.Mui-selected': {
      backgroundColor: ERROR_RED,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_RED,
      },
    },
    flex: '1 0 47%',
    borderRadius: '5px',
    display: 'block',
    fontSize: '16px !important',
    color: BLACK,
  },

  greenBiggerText: {
    color: HC_GREEN,
    fontSize: '26px',
    margin: '-5px 0px 20px 0px',
    fontWeight: '100',
  },

  greenSmallerText: {
    color: HC_GREEN,
    fontSize: '18px',
    margin: '-5px 0px 20px 0px',
    fontWeight: '100',
  },

  redSmallerText: {
    color: DARK_RED,
    fontSize: '18px',
    margin: '-5px 0px 10px 0px',
    fontWeight: '100',
  },

  boldText: {
    fontSize: '18px',
    fontWeight: 'bolder',
  },
};

export const qaCheckFormStyles = {
  container: { paddingBottom: '70px' },
  heading: {
    flexGrow: '1',
    margin: '10px 0 10px',
    fontWeight: 'normal',
  },
  headerBold: { fontWeight: 'bold' },
  headerItalic: { fontStyle: 'italic', fontSize: '16px' },
  floatLeft: { float: 'left' },
  floatRight: { float: 'right' },
  endChecksButton: { marginTop: '20px', color: `${BLACK} !important` },
  mainIssuesButtons: { clear: 'both', paddingTop: '5px' },
  mainIssues: {
    backgroundColor: GREY_100,
    position: 'relative',
    margin: '-10px',
    padding: '12px 30px',
  },
  subQuestions: { padding: '12px 30px' },
  hidden: { display: 'none' },
  submitButton: {
    marginTop: '50px',
    color: `${WHITE} !important`,
    fontSize: '16px',
    backgroundColor: HC_GREEN,
    height: '75px',
    borderRadius: '10px',
    '&.Mui-disabled': { backgroundColor: GREY_200 },
    '&:hover': { backgroundColor: DARK_HC_GREEN },
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'top',
      marginLeft: '10px',
    },
  },
  submitBatchButtons: {
    marginTop: '50px',
    color: WHITE,
    fontSize: '16px',
    backgroundColor: GREY_400,
    height: '75px',
    borderRadius: '0px',
    '&.Mui-disabled': { backgroundColor: GREY_200 },
    '&:hover': { backgroundColor: GREY_500 },
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'top',
      marginLeft: '10px',
    },
  },
  halfWidthButtons: { width: '50%' },
  submitButtonError: {
    backgroundColor: ERROR_RED,
    '&:hover': { backgroundColor: DARK_RED },
  },
  threeButtonGroup: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
    marginBottom: '20px',
  },
  fourButtonGroup: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(22%, 1fr))',
    marginBottom: '20px',
  },
  fiveButtonGroup: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(17%, 1fr))',
    marginBottom: '20px',
  },
  buttonOne: {
    borderColor: `${ERROR_RED} !important`,
    color: `${ERROR_RED} !important`,
    '&.Mui-selected': { color: `${WHITE} !important` },
  },
  button: {
    marginLeft: 0,
    flex: '1 1 20%',
    margin: '5px !important',
    display: 'inline-block',
    fontSize: '16px',
    color: BLACK,
    '&:focus': { backgroundColor: 'none' },
    '&.Mui-selected': {
      backgroundColor: ERROR_RED,
      color: WHITE,
      '&:focus': { backgroundColor: ERROR_RED },
      '&:hover': { backgroundColor: DARK_RED },
    },
    '& svg': {
      display: 'inline-block',
      verticalAlign: 'top',
      marginLeft: '10px',
    },
  },
  thumbsDownIcon: { marginTop: '5px' },
  ingredientContainer: {
    padding: '1px 30px 40px 40px',
    margin: '0 -10px',
    backgroundColor: GREY_750,
  },
  gridContainer: { margin: '0 10px' },
  ingredientTitle: { color: WHITE },
  greyText: { color: GREY_200 },
  helperText: { color: BLUE },
  ingredientInfo: { color: WHITE, fontWeight: 'bold' },
  gridItemBackground: { backgroundColor: GREY_800 },
  gridItem: { margin: '0 15px', maxWidth: '43%' },
  capitalize: { textTransform: 'capitalize' },
  ingredientToggleButton: { minHeight: '75px' },
  submitButtonGroup: {
    width: '100vw',
    marginLeft: '-10px',
    marginTop: '-10px',
  },
  noBorderRadius: { borderRadius: '0px' },
}; // Ensures 2 max items per row. (flexGrow flexShrink flexBasis)
