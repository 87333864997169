import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import withStyles from '@mui/styles/withStyles';

import { LabelLetterBox } from 'components/shared';
import { formatNumber } from 'lib/utils';

import styles from './styles';
import { rowStyles } from '../styles';

const mergedStyles = { ...styles, ...rowStyles };

class MealBagInventoryRow extends Component {
  properColorClass(net_meal_bags) {
    const { classes } = this.props;

    if (net_meal_bags >= 0) {
      return classes.greenLettering;
    } else {
      return classes.redLettering;
    }
  }

  render() {
    const { classes, mealBag } = this.props;
    const {
      label_order,
      title,
      total_meal_bags_yet_to_be_boxed_for_ship_date,
      total_meal_bags_on_hand,
      net_meal_bags,
    } = mealBag;

    return (
      <Accordion expanded={false} key={label_order}>
        <AccordionSummary>
          <Grid
            container
            spacing={0}
            style={{ paddingRight: 0 }}
            data-testid="meal-bag-inventory-row"
          >
            <Grid item xs={1}>
              <LabelLetterBox letter={label_order} empty={!label_order} />
            </Grid>
            <Grid className={classes.titleRoot} item xs={5}>
              <span className={classes.emphasizedText}>{title}</span>
            </Grid>
            <Grid className={classes.titleRoot} item xs={2}>
              <span>
                {formatNumber(total_meal_bags_yet_to_be_boxed_for_ship_date)}
              </span>
            </Grid>
            <Grid className={classes.titleRoot} item xs={2}>
              <span>
                {total_meal_bags_on_hand &&
                  formatNumber(total_meal_bags_on_hand)}
              </span>
            </Grid>
            <Grid className={classes.titleRoot} item xs={2}>
              <span className={this.properColorClass(net_meal_bags)}>
                {net_meal_bags && formatNumber(net_meal_bags)}
              </span>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }
}

MealBagInventoryRow.propTypes = {
  classes: PropTypes.object.isRequired,
  mealBag: PropTypes.shape({
    label_order: PropTypes.string,
    title: PropTypes.string.isRequired,
    total_meal_bags_yet_to_be_boxed_for_ship_date: PropTypes.number,
    total_meal_bags_on_hand: PropTypes.number,
    net_meal_bags: PropTypes.number,
  }).isRequired,
};

export default withStyles(mergedStyles)(MealBagInventoryRow);
