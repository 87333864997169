import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import submitButtonTheme from 'lib/submitButtonTheme';
import { StatefulButton } from 'components/shared';

const DeactivatePallet = ({ deactivatePallet }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={submitButtonTheme}>
        <StatefulButton
          buttonTextOptions={{
            LOADING: 'Loading...',
            SUCCESS: 'Success',
            ERROR: 'There was an error saving',
            DEFAULT: 'Deactivate pallet',
          }}
          type="button"
          id="submitButton"
          data-testid="deactivate-pallet"
          onClick={deactivatePallet}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

DeactivatePallet.propTypes = {
  deactivatePallet: PropTypes.func.isRequired,
};

export default DeactivatePallet;
