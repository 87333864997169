import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';

import {
  LABELED_PACKAGING,
  PORTIONED_INGREDIENTS,
  DISPOSABLE_ITEM,
  INGREDIENT,
  RECIPE,
  PORTION,
} from 'lib/constants';
import { pluralize } from 'lib/utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const QuantityToZoneDisplay = ({
  itemMoveRequest,
  forceRequestToDisplay,
  sx,
}) => {
  const classes = useStyles();

  const {
    quantityRequested,
    dropOffLocation,
    requestableType,
    category,
    fulfilledCases,
    measuringUnit,
    pickedUp,
    compoundIngredientRecipeAssignmentId,
  } = itemMoveRequest;

  const requestToDisplayedQuantity = () => {
    if (category === DISPOSABLE_ITEM) {
      return `${pluralize(quantityRequested, 'Item')} `;
    } else if (
      requestableType === INGREDIENT &&
      compoundIngredientRecipeAssignmentId
    ) {
      return `${pluralize(quantityRequested, RECIPE)} `;
    } else if (requestableType === INGREDIENT) {
      return `${pluralize(quantityRequested, PORTION)} `;
    } else {
      return `${pluralize(quantityRequested, 'Packaging')} `;
    }
  };

  const displayFulfilledQuantity = () => {
    if (category === LABELED_PACKAGING) {
      return `${pluralize(quantityRequested, 'Packaging')} `;
    } else if (category === PORTIONED_INGREDIENTS) {
      return `${pluralize(quantityRequested, PORTION)} `;
    } else if (category === DISPOSABLE_ITEM) {
      return `${pluralize(quantityRequested, 'Item')} `;
    } else {
      return `${fulfilledCases} @ ${measuringUnit} `;
    }
  };

  // Before a request is picked up we want to display the quantity as what was requested.
  // After a request is picked up we want to display the actual amount that was picked up.
  // For staging move requests we will have fulfilledCases for non picked up items, so show that
  // Sometimes we want to display the requested amount after it was picked up, so need a prop to force it to show correctly
  const displayQuantity = useMemo(
    () =>
      (pickedUp || fulfilledCases) && !forceRequestToDisplay
        ? displayFulfilledQuantity()
        : requestToDisplayedQuantity(),
    []
  );

  return (
    <Box
      data-testid="quantity-to-zone-display"
      className={classes.text}
      sx={{ margin: '10px 0', ...sx }}
    >
      {displayQuantity}
      <ArrowForwardIcon className={classes.forwardArrow} />
      {dropOffLocation}
    </Box>
  );
};

QuantityToZoneDisplay.propTypes = {
  itemMoveRequest: PropTypes.shape({
    quantityRequested: PropTypes.number.isRequired,
    category: PropTypes.string,
    pickedUp: PropTypes.bool.isRequired,
    requestableType: PropTypes.string.isRequired,
    measuringUnit: PropTypes.string,
    fulfilledCases: PropTypes.number,
    dropOffLocation: PropTypes.string,
  }).isRequired,
  forceRequestToDisplay: PropTypes.bool,
  sx: PropTypes.object,
};

QuantityToZoneDisplay.defaultProps = {
  sx: {},
  forceRequestToDisplay: false,
};

export default QuantityToZoneDisplay;
