import { GREY_600, GREY_200, GREY_150, ROBOTO, ERROR_RED } from 'lib/constants';

const actionIcon = {
  color: GREY_600,
  fontSize: '20px',
  padding: '8px 16px',
};

export default {
  actionIcon,
  binId: {
    marginRight: 'auto',
  },
  editDisabled: {
    ...actionIcon,
    color: GREY_200,
  },
  gridItem: {
    alignItems: 'center',
    color: GREY_600,
    display: 'flex',
    fontFamily: ROBOTO,
    fontSize: '17px',
    flexDirection: 'row',
    lineHeight: '250%',
    justifyContent: 'flex-end',
    '&:first-of-type': {
      justifyContent: 'flex-start',
    },
  },
  input: {
    fontFamily: ROBOTO,
    fontSize: '16px',
    maxWidth: '60px',
  },
  row: {
    alignItems: 'center',
    paddingLeft: '8px',
    '&:nth-of-type(odd)': {
      background: `${GREY_150}`,
    },
  },
  saveButton: {
    fontFamily: ROBOTO,
    fontWeight: 600,
  },
  warning: {
    color: ERROR_RED,
    margin: '0px 16px',
  },
};
