import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { formatPercentage } from 'lib/utils';

import styles from './styles';

const PercentProgress = ({ percent, percentTarget, visible, classes }) => {
  const progressColor = () => {
    if (percent >= 100 || percent - percentTarget > 5) {
      return classes.aheadOfTarget;
    } else if (percentTarget - percent > 5) {
      return classes.belowTarget;
    } else {
      return classes.onTarget;
    }
  };
  if (visible) {
    return (
      <div data-testid="percent-progress-wrapper" className={progressColor()}>
        <span>{formatPercentage(percent)}</span>
      </div>
    );
  } else {
    return null;
  }
};

PercentProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  percentTarget: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};

PercentProgress.defaultProps = {
  visible: true,
};

export default withStyles(styles)(PercentProgress);
