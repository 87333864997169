/* Menu Navigation

   This component should be included for any page that needs to display items by menu
   * It fetches menus if they are not already fetched
   * It will select a default menu and append /menu/:menuId to the current url
   * It provides navigation tabs that will update the URL for the selected menu
   * It will also save the selected menuId in redux
      -> And auto select that on navigation to any new page that uses this component

   Usage in a Page/Component
   * After importing and rendering <MenuNavigation /> in YourComponent, make sure to do the following

   * The Route for the page/component should also include `<path>/menu/:menuId` (and use `exact`)
     EX:
        <Route
          exact
          path={[
            '/path/name',
            '/path/name/menu/:menuId',
          ]}
          render={() => <YourComponent />}
        />

    * The component should use react-router `useParam` to access the currently selected menuId
      EX: `const { menuId } = useParam()`

    * Test Note: this component requires a lot of test setup to get working, so it is recommended to mock it out for YourComponent unit tests
      EX:
          const mockMenuNavigationComponent = <div data-testid="menu-navigation" />;
          jest.mock('../MenuNavigation', () => () => mockMenuNavigationComponent);
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { DateTime } from 'luxon';

import { fetchMenus, selectMenu } from 'redux/menu/actions';
import { Loading } from 'components/shared';

import styles from './styles';

const useStyles = makeStyles(styles);

const MenuNavigation = ({ menusCount = 5 }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { menuId: menuIdParam } = useParams();

  const { fetching: fetchingMenus, selectedMenu } = useSelector(
    (state) => state.menus
  );

  const menus = useSelector((state) => state.menus.results);
  const candidateMenus = menus.slice(0, menusCount);

  useEffect(() => {
    // anytime the param updates in the URL or on refresh, save that menu in redux
    if (menuIdParam && candidateMenus.length) {
      const menu = candidateMenus.find((m) => m.id == menuIdParam);
      dispatch(selectMenu(menu));
    }
  }, [menuIdParam, menus]);

  useEffect(() => {
    const showExtendedProdMenus = true;
    menus.length < menusCount && dispatch(fetchMenus(showExtendedProdMenus));
  }, []);

  useEffect(() => {
    if (candidateMenus.length && !menuIdParam) {
      const hasSelectedMenu = candidateMenus.find(
        (m) => m.id === selectedMenu?.id
      );
      const defaultMenuId = hasSelectedMenu
        ? selectedMenu.id
        : candidateMenus[0].id;
      history.replace(`${location.pathname}/menu/${defaultMenuId}`);
    }
  }, [menus]);

  return fetchingMenus ? (
    <Loading />
  ) : (
    <div className={classes.navHeader} data-testid="menu-navigation">
      <div className={classes.linkContainer}>
        {candidateMenus.map((menu) => (
          <NavLink
            key={menu.id}
            to={`${menu.id}`}
            className={classes.link}
            activeClassName={classes.activeLink}
            data-testid={menu.id}
          >
            {DateTime.fromISO(menu.weekOfDate).toFormat('M/dd')}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

MenuNavigation.propTypes = {
  menusCount: PropTypes.number,
};

MenuNavigation.defaultProps = {
  menusCount: 5,
};

export default MenuNavigation;
