import {
  WHITE,
  GREY_650,
  GREY_700,
  DARK_LAVENDER,
  API_BASE_URI,
} from 'lib/constants';

const isProduction = API_BASE_URI == 'https://cs.homechef.com';
const backgroundColor = isProduction ? GREY_650 : DARK_LAVENDER;

export default {
  root: {
    alignItems: 'center',
    backgroundColor,
    borderBottom: `solid ${GREY_700} 1px`,
    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.75)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    listStylePosition: 'inside',
    listStyleType: 'none',
    height: '50px',
    position: 'relative',
    zIndex: 5,
  },
  navItem: {
    marginRight: '16px',
    fontSize: '14px',
    position: 'relative',
    display: 'inline-block',
  },
  leftButton: {
    marginLeft: '16px',
  },
  text: {
    color: WHITE,
    fontSize: '14px',
  },
  textCenter: {
    color: WHITE,
    fontSize: '20px',
    align: 'center',
  },
  textWithSpacing: {
    margin: '0 15px',
  },
  accountSwitch: {
    marginLeft: 'auto',
    color: WHITE,
    cursor: 'pointer',
  },
  facilitySelectContainer: {
    marginLeft: 'auto',
  },
  facilityOrMenuSelect: {
    fontSize: '14px',
  },
  facilitySelectMenu: {
    color: WHITE,
  },
  facilityIcon: {
    color: WHITE,
  },
  menuItem: {
    fontSize: '14px',
  },
  progressTrackerLoading: {
    marginLeft: '30px',
  },
  selectMenuDropDown: {
    width: '300px',
    marginRight: '20px',
  },
  selectMenuArrowIcon: {
    color: WHITE,
  },
  menuShipText: {
    color: WHITE,
  },
};
