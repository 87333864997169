import sortBy from 'lodash/sortBy';

import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import parseISO from 'date-fns/parseISO';

export const appointmentSorter = (appointments) => {
  // sort appointment by duration & start time in desc order (longest appt's are first)
  // iterate through each appointment and see if it overlaps with any appointments
  // if there is no overlap then push to first array
  // else if there is overlap and next row exists and appointment fits then push
  // else if there is overlap push to new array row..infinity

  const sortedAppointments = sortBy(appointments, [
    'duration',
    'startTime',
  ]).reverse();

  const sortedRows = [[]];

  const isOverlapping = (newAppointment, existingAppointment) => {
    return areIntervalsOverlapping(
      {
        start: parseISO(newAppointment.startTime),
        end: parseISO(newAppointment.endTime),
      },
      {
        start: parseISO(existingAppointment.startTime),
        end: parseISO(existingAppointment.endTime),
      }
    );
  };

  sortedAppointments.forEach((newAppointment) => {
    let rowIndex = 0;
    let foundASpot = false;

    while (rowIndex < sortedRows.length && !foundASpot) {
      let apptIndex = 0;
      let row = sortedRows[rowIndex];
      let isOverlappingAppt = false;

      while (apptIndex < row.length && !isOverlappingAppt) {
        let existingAppointment = row[apptIndex];

        if (isOverlapping(newAppointment, existingAppointment)) {
          isOverlappingAppt = true;
        }

        apptIndex++;
      }

      if (!isOverlappingAppt) {
        row.push(newAppointment);
        foundASpot = true;
      }

      rowIndex++;
    }

    if (!foundASpot) {
      sortedRows.push([newAppointment]);
    }
  });

  return sortedRows;
};

export const appointmentsWithRows = (appointments) => {
  return appointmentSorter(appointments)
    .map((row, index) => {
      return row.map((appointment) => {
        const appt = appointment;
        appt.row = index + 2;

        return appt;
      });
    })
    .flat();
};
