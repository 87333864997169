import { PRODUCTION_SMOCK, QA_SMOCK, GREY_100 } from 'lib/constants';

export default {
  circleIcon: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '-15px',
  },
  productionIconColor: {
    backgroundColor: PRODUCTION_SMOCK,
  },
  qaIconColor: {
    backgroundColor: QA_SMOCK,
  },
  grey: {
    backgroundColor: GREY_100,
  },
};
