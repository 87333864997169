import * as React from 'react';

const PrintNewLabelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 276 225"
      data-testid="PrintNewLabelIcon"
    >
      <g fill="none" fillRule="evenodd" transform="translate(4 3)">
        <path
          fill="#E6F3E6"
          stroke="#008600"
          strokeLinejoin="round"
          strokeWidth={7.194}
          d="M2.127 31.625l12.176-13.48a7.785 7.785 0 015.752-2.54h227.741c2.275 0 4.436.996 5.916 2.726l12.168 13.294"
        />
        <rect
          width={140.233}
          height={85.831}
          x={64.922}
          fill="#FFF"
          stroke="#008600"
          strokeWidth={5.194}
          rx={2.078}
        />
        <rect
          width={268}
          height={132.128}
          y={29.131}
          fill="#E6F3E6"
          stroke="#008600"
          strokeWidth={7.194}
          rx={7.791}
        />
        <rect
          width={164.124}
          height={10.404}
          x={51.938}
          y={127.967}
          fill="#008600"
          rx={5.202}
        />
        <rect
          width={140.233}
          height={85.831}
          x={64.922}
          y={133.169}
          fill="#FFF"
          stroke="#008600"
          strokeWidth={5.194}
          rx={2.078}
        />
        <rect
          width={47.783}
          height={10.404}
          x={205.155}
          y={43.176}
          fill="#008600"
          rx={5.202}
        />
        <rect
          width={47.783}
          height={10.404}
          x={205.155}
          y={60.862}
          fill="#008600"
          rx={5.202}
        />
        <path
          fill="#008600"
          d="M161.008 168.542h34.279v1.561h-34.279zM161.008 172.183h34.279v1.561h-34.279zM161.008 174.784h34.279v1.561h-34.279zM161.008 177.385h34.279v1.561h-34.279z"
        />
        <path
          fill="#008600"
          d="M161.008 175.824h34.279v1.561h-34.279zM161.008 181.026h34.279v1.561h-34.279zM161.008 184.667h34.279v1.561h-34.279zM161.008 187.268h34.279v1.561h-34.279z"
        />
        <path fill="#008600" d="M161.008 188.309h34.279v1.561h-34.279z" />
        <path
          fill="#008600"
          d="M161.008 189.869h34.279v1.561h-34.279zM161.008 193.511h34.279v1.561h-34.279zM161.008 196.112h34.279v1.561h-34.279zM161.008 199.753h34.279v1.561h-34.279zM161.008 201.834h34.279v1.561h-34.279z"
        />
        <path
          fill="#008600"
          d="M161.008 203.394h34.279v1.561h-34.279zM161.008 205.995h34.279v1.561h-34.279z"
        />
        <path
          stroke="#008600"
          strokeWidth={1.558}
          d="M75.31 168.542h75.829v39.014H75.31z"
        />
        <path
          fill="#008600"
          d="M78.946 172.183h2.597v32.252h-2.597zM88.295 172.183h2.597v32.252h-2.597zM111.667 172.183h2.597v32.252h-2.597zM118.419 172.183h2.597v32.252h-2.597zM125.171 172.183h2.597v32.252h-2.597z"
        />
      </g>
    </svg>
  );
};

export default PrintNewLabelIcon;
