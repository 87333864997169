import React, { Fragment, useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateNetsuiteItemAudit } from 'redux/netsuiteOrderItem/actions';
import { Loading, Snackbar } from 'components/shared';
import { getNetsuiteOrders } from 'redux/netsuiteOrders/audit/actions';
import { selectAuditOrdersWithTrucks } from 'redux/truck/selectors';

import styles from './styles';
import WarehouseAudit from './WarehouseAudit';
import { useSummaryAuditShared } from '../shared_hooks';

const TEN_MINUTE_INTERVAL = 600000;

const receiptSnackbarMessaging = {
  loading: 'Approving Item Receipts',
  failure: 'Item Receipts Approval Failed',
  success: 'Item Receipts Approved!',
};

export const WarehouseAuditContainer = ({
  classes,
  fetching,
  onGetNetsuiteOrders,
  netsuiteOrders,
  onUpdateNetsuiteItem,
  updating,
  updatingFailed,
  receiptUpdating,
  receiptUpdatingFailed,
}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      onGetNetsuiteOrders();
    }, TEN_MINUTE_INTERVAL);
    return () => clearInterval(interval);
  });

  const { snackbarMessaging, handleUpdateNetsuiteItem } = useSummaryAuditShared(
    onGetNetsuiteOrders,
    onUpdateNetsuiteItem
  );
  if (fetching) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  } else if (netsuiteOrders.length === 0) {
    return (
      <div className={classes.noTrucks}>
        No orders currently needing an audit.
      </div>
    );
  } else {
    return (
      <Fragment>
        <WarehouseAudit
          netsuiteOrders={netsuiteOrders}
          classes={classes}
          onUpdateNetsuiteItem={handleUpdateNetsuiteItem}
        />
        <Snackbar
          loading={updating}
          failed={updatingFailed}
          messaging={snackbarMessaging}
        />
        <Snackbar
          loading={receiptUpdating}
          failed={receiptUpdatingFailed}
          messaging={receiptSnackbarMessaging}
        />
      </Fragment>
    );
  }
};

WarehouseAuditContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  onGetNetsuiteOrders: PropTypes.func.isRequired,
  netsuiteOrders: PropTypes.array.isRequired,
  onUpdateNetsuiteItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { netsuiteOrders } = selectAuditOrdersWithTrucks(state);
  return {
    netsuiteOrders,
    fetching: state.netsuiteOrders.audit.fetching,
    updating: state.netsuiteOrders.audit.updating,
    updatingFailed: state.netsuiteOrders.audit.updateFailed,
    receiptUpdating: state.itemReceipt.updating,
    receiptUpdatingFailed: state.itemReceipt.updatingFailed,
  };
};

const mapDispatchToProps = {
  onGetNetsuiteOrders: getNetsuiteOrders,
  onUpdateNetsuiteItem: updateNetsuiteItemAudit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WarehouseAuditContainer));
