import React from 'react';
import PropTypes from 'prop-types';

const PersonnelTrackingIcon = (props) => {
  const { className = 'PersonnelTrackingIcon' } = props;
  return (
    <div className={className}>
      <svg
        viewBox="0 0 96 96"
        xmlns="http://www.w3.org/2000/svg"
        id="Icons_Team"
        overflow="hidden"
      >
        <g>
          <circle cx="73" cy="16.2" r="7" fill="#3C9A1E" />
          <circle cx="23" cy="16.2" r="7" fill="#3C9A1E" />
          <circle cx="48" cy="16.2" r="7" fill="#3C9A1E" />
          <path
            d="M65.8 48.8 61.3 32.2C61.1 31.6 60.8 31 60.4 30.6 58.5 28.6 56.1 27.1 53.5 26.2 51.8 25.6 50 25.3 48.1 25.3 46.2 25.3 44.4 25.6 42.7 26.2 40 27.1 37.7 28.6 35.8 30.6 35.4 31.1 35.1 31.6 34.9 32.2L30.4 48.8C30 50.4 30.8 52.2 32.5 52.6 32.8 52.7 33 52.7 33.3 52.7 34.6 52.7 35.8 51.8 36.2 50.5L40.2 35.9 40.2 86.8 46.2 86.8 46.2 58.2 50.2 58.2 50.2 86.7 56.2 86.7 56.2 35.9 60.2 50.5C60.6 51.8 61.8 52.7 63.1 52.7 63.4 52.7 63.6 52.7 63.9 52.6 65.3 52.2 66.2 50.4 65.8 48.8Z"
            fill="#3C9A1E"
          />
          <path
            d="M28.3 48.3 32.8 31.7C33 30.8 33.5 30 34 29.3 32.4 27.9 30.4 26.8 28.3 26.1 26.6 25.5 24.8 25.2 22.9 25.2 21 25.2 19.2 25.5 17.5 26.1 14.8 27 12.5 28.5 10.6 30.5 10.2 31 9.9 31.5 9.7 32.1L5.2 48.8C4.8 50.4 5.6 52.2 7.3 52.6 7.6 52.7 7.8 52.7 8.1 52.7 9.4 52.7 10.6 51.8 11 50.5L15 35.9 15 86.8 21 86.8 21 58.2 25 58.2 25 86.7 31 86.7 31 54.3C28.9 53.3 27.7 50.8 28.3 48.3Z"
            fill="#3C9A1E"
          />
          <path
            d="M90.8 48.8 86.2 32.2C86 31.6 85.7 31 85.3 30.6 83.4 28.6 81 27.1 78.4 26.2 76.7 25.6 74.9 25.3 73 25.3 71.1 25.3 69.3 25.6 67.6 26.2 65.5 26.9 63.6 28 61.9 29.4 62.5 30.1 62.9 30.9 63.1 31.7L67.6 48.3C68.3 50.8 67 53.3 64.9 54.3L64.9 86.8 70.9 86.8 70.9 58.2 74.9 58.2 74.9 86.7 80.9 86.7 80.9 35.9 84.9 50.5C85.3 51.8 86.5 52.7 87.8 52.7 88.1 52.7 88.3 52.7 88.6 52.6 90.3 52.2 91.2 50.4 90.8 48.8Z"
            fill="#3C9A1E"
          />
        </g>
      </svg>
    </div>
  );
};

PersonnelTrackingIcon.propTypes = {
  className: PropTypes.string,
};

PersonnelTrackingIcon.defaultProps = {
  className: 'PersonnelTrackingIcon',
};

export default PersonnelTrackingIcon;
