import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const ActivityLinks = (props) => {
  const { selectedMenu, classes } = props;

  if (!selectedMenu) {
    return null;
  }

  const activities = [
    'Overview',
    'Labeling',
    'Portioning',
    'Bagging',
    'Boxing',
  ];

  return (
    <div className={classes.activityLinks}>
      {activities.map((activity) => {
        const pathname = `/progress_tracker/menu/${
          selectedMenu.id
        }/${activity.toLowerCase()}`;

        return (
          <NavLink
            to={{ pathname: pathname }}
            key={activity}
            className={classes.link}
            activeClassName={classes.active}
          >
            {activity}
          </NavLink>
        );
      })}
    </div>
  );
};

ActivityLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedMenu: PropTypes.object,
};

ActivityLinks.defaultProps = {
  selectedMenu: undefined,
};

export default withStyles(styles)(ActivityLinks);
