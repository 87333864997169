import React, { useMemo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { datePickerInventoryManagerStyles } from 'lib/dataTableTheme';
import { useRotationTypeValidations } from 'lib/custom_hooks';
import {
  PHONE_SCREEN,
  TABLET_SCREEN,
  ACTIVE,
  WAREHOUSE_SHELF_LIFE_TYPES,
  ON_HOLD,
  TABLET_SCREEN_OR_SMALLER,
  UNKNOWN_LOCATION,
} from 'lib/constants';
import { insertBoldBeginning } from 'lib/utils';
import productDateFns from 'lib/utils/productDateFns';
import ScanBanner from 'components/WarehouseInventory/ScanBanner';
import { getHeightColor } from 'components/WarehouseInventory/helpers';
import DeactivatedBanner from 'components/WarehouseInventory/DeactivatedBanner';
import UnknownAreaHoldBanner from 'components/WarehouseInventory/UnknownAreaHoldBanner';
import PalletDetailsButtons from 'components/WarehouseInventory/PalletDetailsButtons';

import QuantityChangeModal from '../QuantityChangeModal';

const PalletDetailsTopSection = ({
  classes,
  disabled,
  onPageKeyDown,
  onSubmit,
  onProductDateChange,
  onProductDateTypeChange,
  quantity,
  productDate,
  productDateType,
  pallet,
  toLocation,
  inLocationMoveMode,
  fieldsDirty,
  calculatorOpen,
  setCalculatorOpen,
  measuringUnitsForSelectedPallet,
  passUpdatedUOMToPalletDetails,
}) => {
  const phoneScreen = useMediaQuery(PHONE_SCREEN);
  const tabletScreen = useMediaQuery(TABLET_SCREEN);
  const tabletScreenOrSmaller = useMediaQuery(TABLET_SCREEN_OR_SMALLER);

  const showQRBanner = useMemo(
    () =>
      !inLocationMoveMode &&
      (phoneScreen || tabletScreen) &&
      pallet.status === ACTIVE,
    [phoneScreen, tabletScreen, inLocationMoveMode, pallet]
  );

  const customDateFns = useMemo(() => productDateFns(pallet), [pallet]);

  const {
    disableFutureDates,
    disablePastDates,
    onRotationTypeChange,
  } = useRotationTypeValidations(
    productDate,
    productDateType,
    onProductDateChange,
    onProductDateTypeChange,
    pallet.createdAt
  );

  const toColor = useMemo(() => getHeightColor(toLocation), [toLocation]);
  const fromColor = useMemo(() => getHeightColor(pallet.location), [
    pallet.location,
  ]);

  const formattedItemName = pallet.ingredientId
    ? `${pallet.ingredientId} - ${pallet.item}`
    : pallet.item;

  const isUnknownLocation = useMemo(
    () => pallet.status == ON_HOLD && pallet.statusReason == UNKNOWN_LOCATION,
    [pallet]
  );

  const isDeactivatedOrHeld = useMemo(
    () => pallet.status !== ACTIVE && pallet.statusReason !== UNKNOWN_LOCATION,
    [pallet]
  );

  const [UOMIdForSelectedPallet, setUOMIdForSelectedPallet] = useState(
    pallet.measuringUnitId
  );

  const [UOMNameForSelectedPallet, setUOMNameForSelectedPallet] = useState(
    pallet.measuringUnit
  );

  const findCurrentMeasuringUnit = (UOMIdChange) => {
    const upToDateMeasuringUnitName = measuringUnitsForSelectedPallet.find(
      (measuringUnit) => measuringUnit.value == UOMIdChange
    ).label;

    setUOMNameForSelectedPallet(upToDateMeasuringUnitName);
  };

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const onSelectedPalletUOMChange = (e) => {
    setUOMIdForSelectedPallet(e.target.value);
    findCurrentMeasuringUnit(e.target.value);
    setConfirmationModalOpen(true);
  };

  const onCancelUOMChange = () => {
    setConfirmationModalOpen(false);
    setUOMIdForSelectedPallet(pallet.measuringUnitId);
  };

  const onSubmitUOMChange = () => {
    passUpdatedUOMToPalletDetails({
      measuring_unit_id: UOMIdForSelectedPallet,
    });
    //give the measuring unit id object to parent component to be updated

    setConfirmationModalOpen(false);
  };

  return (
    <Fragment>
      {showQRBanner && (
        <ScanBanner
          disabled={fieldsDirty}
          text="Scan New Location To Move Pallet"
        />
      )}
      {isUnknownLocation && <UnknownAreaHoldBanner pallet={pallet} />}

      {isDeactivatedOrHeld && <DeactivatedBanner pallet={pallet} />}

      {isUnknownLocation && tabletScreenOrSmaller && (
        <ScanBanner text="To use this pallet, scan its current location" />
      )}
      <List
        disablePadding
        className={classNames(classes.list, {
          [classes.inactivePalletList]: pallet.status !== ACTIVE,
        })}
      >
        <ListItem classes={{ root: classNames(classes.odd, classes.row) }}>
          <ListItemText disableTypography classes={{ root: classes.label }}>
            Item
          </ListItemText>
          <ListItemText disableTypography classes={{ root: classes.value }}>
            {formattedItemName}
          </ListItemText>
        </ListItem>
        {!inLocationMoveMode && (
          <Fragment>
            <ListItem classes={{ root: classes.row }}>
              <ListItemText disableTypography classes={{ root: classes.label }}>
                Brand
              </ListItemText>
              <ListItemText disableTypography classes={{ root: classes.value }}>
                {pallet.brand}
              </ListItemText>
            </ListItem>
            <ListItem classes={{ root: classNames(classes.odd, classes.row) }}>
              <ListItemText disableTypography classes={{ root: classes.label }}>
                Qty
              </ListItemText>

              <ListItemText
                disableTypography
                classes={{ root: classes.middleValue }}
              >
                <span>{quantity}</span>
                <QuantityChangeModal
                  onSubmit={onSubmit}
                  onPageDownCallback={onPageKeyDown}
                  calculatorOpen={calculatorOpen}
                  setCalculatorOpen={setCalculatorOpen}
                  quantity={quantity}
                  calculatorDisabled={disabled || fieldsDirty}
                  measuringUnit={pallet.measuringUnit}
                  allowSetValue
                  includeWasteCorrection={
                    pallet.wasteCorrectionIncreaseEligible
                  }
                />
              </ListItemText>
              <ListItemText
                disabled={disabled}
                id="UOM"
                data-testid="UOM"
                disableTypography
                classes={{ root: classes.value }}
              >
                <TextField
                  id="UOM"
                  data-testid="unit-of-measure"
                  name="unit_of_measure"
                  variant="outlined"
                  disabled={disabled}
                  value={UOMIdForSelectedPallet}
                  select
                  onChange={(e) => onSelectedPalletUOMChange(e)}
                  size="small"
                >
                  {measuringUnitsForSelectedPallet.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItemText>
            </ListItem>
            <Dialog
              id="confirmUOMChangeModal"
              open={confirmationModalOpen}
              onClose={onCancelUOMChange}
              classes={{ paper: classes.uomConfirmDialog }}
            >
              <div
                className={classes.uomConfirmDialogContent}
                data-testid="uom-confirm-dialog-content"
              >
                {`Are you sure you want to set the UOM to ${UOMNameForSelectedPallet}
                 with a quantity of ${quantity}?`}
                <PalletDetailsButtons
                  onCancelClick={onCancelUOMChange}
                  onSubmitClick={onSubmitUOMChange}
                  buttonTextOverrides={{
                    DEFAULT: 'Yes',
                  }}
                />
              </div>
            </Dialog>
            <ListItem classes={{ root: classes.row }}>
              <ListItemText disableTypography classes={{ root: classes.label }}>
                PDate
              </ListItemText>
              <ListItemText
                disableTypography
                classes={{ root: classes.middleValue }}
              >
                <TextField
                  data-testid="rotation-type"
                  name="rotation_type"
                  variant="outlined"
                  value={productDateType}
                  disabled={disabled}
                  select
                  SelectProps={{
                    MenuProps: {
                      className: classes.quantityInputRoot,
                    },
                  }}
                  className={classes.roationTypeRoot}
                  onChange={onRotationTypeChange}
                  size="small"
                >
                  {WAREHOUSE_SHELF_LIFE_TYPES.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItemText>
              <ListItemText
                disableTypography
                classes={{ root: classes.datePickerWrapper }}
              >
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={datePickerInventoryManagerStyles}>
                    <LocalizationProvider dateAdapter={customDateFns}>
                      <MobileDatePicker
                        inputFormat="MM/dd/yyyy"
                        className={classes.datePicker}
                        emptyLabel=""
                        value={productDate}
                        onChange={onProductDateChange}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} />
                        )}
                        disabled={disabled || !productDateType}
                        disableFuture={disableFutureDates}
                        disablePast={disablePastDates}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </ListItemText>
            </ListItem>
          </Fragment>
        )}
        <ListItem
          classes={{
            root: classNames(
              { [classes.odd]: !inLocationMoveMode },
              classes.row
            ),
          }}
        >
          <ListItemText disableTypography classes={{ root: classes.label }}>
            {inLocationMoveMode ? 'From' : 'Location'}
          </ListItemText>
          <ListItemText
            disableTypography
            classes={{ root: classes.toLocationValue }}
          >
            {fromColor && (
              <div
                style={{ backgroundColor: fromColor }}
                className={classes.locationColor}
                data-testid="location-color"
              />
            )}
            {insertBoldBeginning(pallet.location, classes.locationText)}
          </ListItemText>
        </ListItem>
        {inLocationMoveMode && (
          <ListItem classes={{ root: classNames(classes.odd, classes.row) }}>
            <ListItemText disableTypography classes={{ root: classes.label }}>
              To
            </ListItemText>
            <ListItemText
              disableTypography
              classes={{ root: classes.toLocationValue }}
            >
              {toColor && (
                <div
                  style={{ backgroundColor: toColor }}
                  className={classes.locationColor}
                />
              )}
              {insertBoldBeginning(toLocation, classes.locationText)}
            </ListItemText>
          </ListItem>
        )}
        <ListItem classes={{ root: classes.row }}>
          <ListItemText disableTypography classes={{ root: classes.label }}>
            PO Number
          </ListItemText>
          <ListItemText disableTypography classes={{ root: classes.value }}>
            {pallet.poNumber}
          </ListItemText>
        </ListItem>
      </List>
    </Fragment>
  );
};

PalletDetailsTopSection.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onPageKeyDown: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onProductDateChange: PropTypes.func.isRequired,
  onProductDateTypeChange: PropTypes.func.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  productDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  productDateType: PropTypes.string,
  pallet: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    location: PropTypes.string,
    item: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    brand: PropTypes.string.isRequired,
    measuringUnit: PropTypes.string.isRequired,
    ingredientId: PropTypes.number,
  }).isRequired,
  inLocationMoveMode: PropTypes.bool.isRequired,
  toLocation: PropTypes.string,
  fieldsDirty: PropTypes.bool.isRequired,
  calculatorOpen: PropTypes.bool.isRequired,
  setCalculatorOpen: PropTypes.func.isRequired,
  measuringUnitsForSelectedPallet: PropTypes.array,
  passUpdatedUOMToPalletDetails: PropTypes.func,
};

PalletDetailsTopSection.defaultProps = {
  toLocation: '',
  productDate: '',
  productDateType: '',
  measuringUnitsForSelectedPallet: [],
  passUpdatedUOMToPalletDetails: () => {},
};

export default PalletDetailsTopSection;
