import React, { useEffect } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import styles from './styles';

const useStyles = makeStyles(styles);

const LocationSelectors = ({ locations }) => {
  const classes = useStyles();
  const history = useHistory();
  // if the URL matches the path exactly => nothing is selected and we need to select the first location
  const { path, isExact: needsDefaultLocation } = useRouteMatch();

  useEffect(() => {
    const defaultLocation = locations[0];
    if (needsDefaultLocation && defaultLocation) {
      history.push(`${path}/${defaultLocation.id}`);
    }
  }, [needsDefaultLocation, locations]);

  return (
    <ImageList
      data-testid="location-selectors"
      className={classes.imageList}
      cols={2}
    >
      {locations.map((location) => (
        <ImageListItem key={location.id}>
          <NavLink
            to={`${path}/${location.id}`}
            className={classes.link}
            activeClassName={classes.activeLink}
            key={location.id}
            data-testid="location-selector-link"
          >
            {location.description}
          </NavLink>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

LocationSelectors.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

LocationSelectors.defaultProps = {
  locations: [],
};

export default LocationSelectors;
