import React, {
  useReducer,
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Alert from '@mui/material/Alert';
import round from 'lodash/round';
import isNil from 'lodash/isNil';

import { numberWithCommas, ellipseText } from 'lib/utils';
import { datePickerReceivingStyles } from 'lib/dataTableTheme';
import { WAREHOUSE_SHELF_LIFE_TYPES } from 'lib/constants';
import { StatefulButton, ErrorMessageSnackbar } from 'components/shared';
import { usePrevious, useRotationTypeValidations } from 'lib/custom_hooks';
import PrinterOptions from 'components/shared/PrinterOptions';

import OveragesDialog from '../OveragesDialog';
import {
  usePalletTotals,
  getInitialState,
  reducer,
  INPUT_NAMES,
  ADD_PALLET_LINE,
  DELETE_PALLET_LINE,
  RESET_PALLET_LINES,
  UPDATE_PALLET_LINE,
  UPDATE_PRODUCT_DATE,
  UPDATE_ROTATION_TYPE,
  PALLET_WARNING_THRESHOLD,
  PALLET_NOT_ALLOWED_THRESHOLD,
  UPDATE_TRUCK_SELECTION,
} from './formUtils';
import PalletSubmissionLine from '../PalletSubmissionLine';

const buttonText = {
  DEFAULT: 'Submit & Print',
  SUCCESS: 'Success! Pallet(s) Created',
  LOADING: 'Submitting...',
  ERROR: 'Failed - Try Again?',
};

export const isInvalidInteger = (value) => {
  if (!value.length) {
    return false;
  }
  value = parseFloat(value);
  return value < 0 || value % 1 != 0 || isNaN(value);
};

const PalletSubmissionForm = ({
  classes,
  unitsOptions,
  submitAndPrintPallets,
  fetching,
  failed,
  errorMessage,
  deleteFailed,
  user,
  existingPalletQuantity,
  printers,
  netsuiteItem,
}) => {
  const {
    needOverageApproval,
    overageLimit,
    defaultPrinterDescription,
    id: netsuiteOrderItemId,
    quantity: expectedQuantity,
    measuringUnitId,
    trucks,
    defaultProductDateType,
  } = netsuiteItem;

  const selectedPrinterId = useRef();

  const setCurrentPrinterId = (printerId) => {
    selectedPrinterId.current = printerId;
  };

  const [state, dispatch] = useReducer(reducer, getInitialState(netsuiteItem));
  const [dialogOpen, setDialogOpen] = useState(false);
  const prevFetching = usePrevious(fetching);
  const receivableTrucks = useMemo(() => {
    return trucks.filter((truck) => isNil(truck.dockOutTime));
  }, [trucks]);

  useEffect(() => {
    if (prevFetching && !fetching && !failed) {
      dispatch({ type: RESET_PALLET_LINES });
    }
  }, [prevFetching, fetching, failed]);

  useEffect(() => {
    if (state.truckId.length === 0 && receivableTrucks.length === 1) {
      dispatch({
        type: UPDATE_TRUCK_SELECTION,
        value: receivableTrucks[0].id,
      });
    }
  }, [state.truckId, receivableTrucks]);

  const [totalPallets, totalQuantity] = usePalletTotals(
    state.palletLines,
    existingPalletQuantity
  );

  const submitPallets = (overageReason = null, overageApprover = null) => {
    submitAndPrintPallets({
      netsuiteOrderItemId,
      pallets: state.palletLines,
      user,
      truckId: state.truckId,
      productDate: state.productDate,
      productDateType: state.productDateType,
      printer: selectedPrinterId.current,
      overageReason,
      overageApprover,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (totalQuantity > expectedQuantity) {
      setDialogOpen(true);
    } else {
      submitPallets();
    }
  };

  const handleDialogSubmit = (overageReason, overageApprover) => {
    submitPallets(overageReason, overageApprover);
    setDialogOpen(false);
  };

  const allPalletLinesValid = state.palletLines.every(
    (palletLine) =>
      palletLine[INPUT_NAMES.quantity].length &&
      !isInvalidInteger(palletLine[INPUT_NAMES.quantity]) &&
      palletLine[INPUT_NAMES.numberPallets].length &&
      !isInvalidInteger(palletLine[INPUT_NAMES.numberPallets]) &&
      palletLine[INPUT_NAMES.units]
  );

  buttonText.DEFAULT = `Submit & Print ${totalPallets} Pallets`;
  buttonText.SUCCESS = `Success! ${totalPallets} Pallet(s) Created`;

  const updateProductDate = useCallback(
    (value) => dispatch({ type: UPDATE_PRODUCT_DATE, value }),
    []
  );

  const updateProductDateType = useCallback(
    (value) => dispatch({ type: UPDATE_ROTATION_TYPE, value }),
    []
  );

  const {
    disableFutureDates,
    disablePastDates,
    onRotationTypeChange,
  } = useRotationTypeValidations(
    state.productDate,
    state.productDateType,
    updateProductDate,
    updateProductDateType
  );

  const onTruckChange = (value) => {
    dispatch({ type: UPDATE_TRUCK_SELECTION, value });
  };

  const overageError = useMemo(
    () => round((totalQuantity / expectedQuantity) * 100, 2) > overageLimit,
    [totalQuantity, expectedQuantity, overageLimit]
  );

  const productDateTypeError = useMemo(
    () =>
      defaultProductDateType &&
      state.productDateType !== defaultProductDateType,
    [defaultProductDateType, state.productDateType]
  );

  const acceptableQuantity = Math.floor(
    expectedQuantity * (overageLimit / 100)
  );

  const buttonEnabled =
    allPalletLinesValid &&
    state.productDate &&
    state.productDateType &&
    state.truckId &&
    !overageError &&
    totalPallets < PALLET_NOT_ALLOWED_THRESHOLD;

  return (
    <Fragment>
      <form
        onSubmit={handleFormSubmit}
        autoComplete="off"
        data-testid="pallet-submission-form"
      >
        {receivableTrucks.length === 1 ? (
          <Fragment>
            <div className={classes.existingTrucksLabel}>TRUCK</div>
            <div className={classes.existingTrucksContainer}>
              <div>
                {ellipseText(
                  `T${
                    receivableTrucks[0].id
                  } - ${receivableTrucks[0].vendorNames.join(', ')}`,
                  70
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <TextField
            label="TRUCK"
            variant="outlined"
            margin="normal"
            value={state.truckId}
            select
            SelectProps={{
              MenuProps: { className: classes.menuItem },
            }}
            className={classes.truckInput}
            onChange={(e) => onTruckChange(e.target.value)}
          >
            {receivableTrucks.map((truck) => (
              <MenuItem key={truck.id} value={truck.id}>
                {ellipseText(
                  `T${truck.id} - ${truck.vendorNames.join(', ')}`,
                  70
                )}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          label="ROTATION TYPE"
          name="rotation_type"
          variant="outlined"
          margin="normal"
          data-testid="rotation-type"
          value={state.productDateType}
          select
          SelectProps={{ MenuProps: { className: classes.menuItem } }}
          className={`${classes.textField} ${
            productDateTypeError && classes.textFieldWarning
          }`}
          onChange={onRotationTypeChange}
        >
          {WAREHOUSE_SHELF_LIFE_TYPES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={datePickerReceivingStyles}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                id="date"
                data-testid="product-date"
                type="string"
                format="MM/dd/YYY"
                className={classes.datePicker}
                label="PRODUCT DATE"
                emptyLabel=""
                value={state.productDate}
                onChange={(date) =>
                  dispatch({ type: UPDATE_PRODUCT_DATE, value: date })
                }
                disabled={!state.productDateType}
                disableFuture={disableFutureDates}
                disablePast={disablePastDates}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
        {state.palletLines.map((palletLine, index) => (
          <PalletSubmissionLine
            inputs={palletLine}
            key={palletLine.id}
            unitsOptions={unitsOptions}
            defaultUnits={measuringUnitId}
            handleInvalidQuantities={(value) => isInvalidInteger(value)}
            handleInputChange={(field, value) =>
              dispatch({
                type: UPDATE_PALLET_LINE,
                id: palletLine.id,
                field,
                value,
              })
            }
            handleDelete={() =>
              dispatch({
                type: DELETE_PALLET_LINE,
                id: palletLine.id,
              })
            }
            index={index}
          />
        ))}
        <Button
          classes={{ root: classes.addButton }}
          fullWidth
          type="button"
          onClick={() => dispatch({ type: ADD_PALLET_LINE })}
        >
          {'Add More'}
        </Button>
        <div className={classes.buttonsContainer}>
          <StatefulButton
            loading={fetching}
            failed={failed}
            disabled={!buttonEnabled}
            buttonTextOptions={buttonText}
            fullWidth
            classes={{
              disabled: classes.buttonDisabled,
              root: classes.submitButton,
            }}
            type="submit"
          />
          <PrinterOptions
            disabled={!buttonEnabled}
            setCurrentPrinterId={setCurrentPrinterId}
            printers={printers}
            defaultPrinterDescription={defaultPrinterDescription}
          />
        </div>

        {totalPallets > PALLET_WARNING_THRESHOLD &&
          totalPallets < PALLET_NOT_ALLOWED_THRESHOLD && (
            <Alert severity="warning" className={classes.warning}>
              {`You have selected a very large number of pallets (${totalPallets}). Are you sure you want to do this?`}
            </Alert>
          )}
        {totalPallets >= PALLET_NOT_ALLOWED_THRESHOLD && (
          <Alert severity="warning" className={classes.warning}>
            {`You have selected too many pallets (${totalPallets}). Please print fewer than ${PALLET_NOT_ALLOWED_THRESHOLD}.`}
          </Alert>
        )}
        {overageError && (
          <Alert severity="warning" className={classes.warning}>
            {`Overage cannot be received. Contact procurement to update PO quantity or receive maximum quantity of ${numberWithCommas(
              acceptableQuantity
            )}`}
          </Alert>
        )}
        {productDateTypeError && (
          <Alert severity="warning" className={classes.warning}>
            Review this item's rotation type before printing pallet tags
          </Alert>
        )}
      </form>
      <OveragesDialog
        open={dialogOpen}
        expectedQuantity={expectedQuantity}
        totalQuantity={totalQuantity}
        setDialogOpen={setDialogOpen}
        handleDialogSubmit={handleDialogSubmit}
        needApproval={needOverageApproval}
      />
      <ErrorMessageSnackbar
        showError={deleteFailed}
        errorMessage={errorMessage}
      />
    </Fragment>
  );
};

PalletSubmissionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  unitsOptions: PropTypes.array.isRequired,
  existingPalletQuantity: PropTypes.number.isRequired,
  submitAndPrintPallets: PropTypes.func.isRequired,
  deleteFailed: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  netsuiteItem: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    measuringUnitId: PropTypes.number.isRequired,
    defaultPrinterDescription: PropTypes.string.isRequired,
    overageLimit: PropTypes.number.isRequired,
    needOverageApproval: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PalletSubmissionForm;
