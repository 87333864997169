import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { toggleTruckDrawer } from 'redux/truck/actions';
import { clearSearchQuery } from 'redux/netsuiteOrders/receiving/actions';

import TruckDrawer from './TruckDrawer';
import styles from './styles';

const mapStateToProps = (state) => ({
  open: state.trucks.receiving.truckDrawerOpen,
  truck: state.trucks.receiving.selectedTruck,
  posting: state.trucks.receiving.posting,
  postFailed: state.trucks.receiving.postFailed,
  updating: state.trucks.receiving.updating,
  updateFailed: state.trucks.receiving.updateFailed,
  dockDoors: state.dockDoor.results,
});

const mapDispatchToProps = {
  toggleTruckDrawer: toggleTruckDrawer,
  clearSearchQuery: clearSearchQuery,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TruckDrawer)
);
