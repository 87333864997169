import React from 'react';
import PropTypes from 'prop-types';

import { useTabOutline } from 'lib/custom_hooks';

const TabOutlineAnchor = (props) => {
  const [handleMouseDown, handleBlur, style] = useTabOutline(props);
  return (
    <a
      {...props}
      href={props.href}
      style={style}
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
    >
      {props.children}
    </a>
  );
};

TabOutlineAnchor.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
  onBlur: PropTypes.func,
  onMouseDown: PropTypes.func,
};

TabOutlineAnchor.defaultProps = {
  style: {},
  children: null,
  onMouseDown: () => {},
  onBlur: () => {},
};

export default TabOutlineAnchor;
