import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TabOutlineButton, Loading } from 'components/shared';
import LocationInput from 'components/WarehouseInventory/LocationInput';

const LocationForm = ({
  classes,
  activePallet,
  depthsFailed,
  depthsLoading,
  resetDepths,
  getDepthsCallback,
  setLocationNumber,
  locationInputElement,
  locationNumber,
}) => {
  if (depthsFailed) {
    return (
      <Fragment>
        <div className={classNames(classes.centered, classes.noStorageSlot)}>
          Sorry, no storage slot matching that location found
        </div>
        <div
          className={classNames(classes.centered, classes.tryAgainContainer)}
        >
          <TabOutlineButton
            type="button"
            onClick={resetDepths}
            className={classes.tryAgainButton}
          >
            Try Again
          </TabOutlineButton>
        </div>
      </Fragment>
    );
  } else if (depthsLoading) {
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );
  } else {
    return (
      <LocationInput
        onGetDepths={getDepthsCallback}
        fetchFailed={false}
        setLocationNumber={setLocationNumber}
        locationInputElement={locationInputElement}
        locationNumber={locationNumber}
        showDepths={false}
        shouldRedirect={false}
        activePallet={activePallet}
      />
    );
  }
};

LocationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  activePallet: PropTypes.bool.isRequired,
  depthsFailed: PropTypes.bool.isRequired,
  depthsLoading: PropTypes.bool.isRequired,
  resetDepths: PropTypes.func.isRequired,
  getDepthsCallback: PropTypes.func.isRequired,
  setLocationNumber: PropTypes.func.isRequired,
  locationInputElement: PropTypes.object.isRequired,
  locationNumber: PropTypes.string.isRequired,
};

export default LocationForm;
