import { makeApiRequest } from 'lib/api';
import {
  PRINT_NEW_LABEL,
  CLEAR_SHIPPING_OPTION_DETAILS,
  CREATE_REPLACEMENT_LABEL,
} from 'lib/constants';

import PrintNewLabel from './services';

export const getShipment = (params) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: PRINT_NEW_LABEL,
        method: PrintNewLabel.getShipment,
        params,
      })
    );
  };
};

export const createReplacementLabel = (params) => async (dispatch) => {
  await dispatch(
    makeApiRequest({
      type: CREATE_REPLACEMENT_LABEL,
      method: PrintNewLabel.createReplacementLabel,
      params,
    })
  );
};

export const clearShippingOptionDetails = () => {
  return {
    type: CLEAR_SHIPPING_OPTION_DETAILS,
  };
};
