import { makeApiRequest } from 'lib/api';
import { BOXING_INVENTORY, SELECTED_SHIP_DATE_UPDATED } from 'lib/constants';

import BoxingInventories from './services';

export const requestBoxingInventory = (params) =>
  makeApiRequest({
    type: BOXING_INVENTORY,
    method: BoxingInventories.byMenu,
    params: params,
  });

export const updateSelectedShipDate = (shipDate) => ({
  type: SELECTED_SHIP_DATE_UPDATED,
  payload: { selectedShipDate: shipDate },
});

export const updateSelectedShipDateAndFetch = (
  menuId,
  facilityId,
  shipDate
) => {
  return (dispatch) => {
    dispatch(updateSelectedShipDate(shipDate));
    dispatch(requestBoxingInventory({ menuId, facilityId, shipDate }));
  };
};
