import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Collapse, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeft from '@mui/icons-material/ArrowLeft';

import { INGREDIENT_ITEM_TYPE } from 'lib/constants';

import styles from './styles';
import TotalProgress from '../TotalProgress';
import IngredientMealsDetails from './IngredientMealsDetails';

const useStyles = makeStyles(styles);

const IngredientForSlacking = ({ ingredient, expandAll, trigger }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(expandAll);
  }, [expandAll, trigger]);

  return (
    <>
      <TableRow
        className={classes.row}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <TableCell
          className={classes.title}
          sx={{ borderBottom: expand && 'none' }}
        >
          {ingredient.name} - {ingredient.id}
        </TableCell>
        <TableCell
          className={classes.submission}
          sx={{ borderBottom: expand && 'none' }}
        >
          {expand ? (
            <ArrowDropDownIcon className={classes.icon} />
          ) : (
            <ArrowLeft className={classes.icon} />
          )}
          <TotalProgress item={ingredient} itemType={INGREDIENT_ITEM_TYPE} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan="2" className={classes.collapseContainer}>
          <Collapse in={expand}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    padding="none"
                    className={classes.ingredientMealsDetailsContainer}
                  >
                    <IngredientMealsDetails ingredient={ingredient} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

IngredientForSlacking.propTypes = {
  ingredient: PropTypes.object.isRequired,
  expandAll: PropTypes.bool.isRequired,
  trigger: PropTypes.bool.isRequired,
};

export default IngredientForSlacking;
