import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Breadcrumbs } from 'components/shared';

import { BreadcrumbContext } from '../BreadcrumbProvider';

const WarehouseRequestsRouteLinks = ({ classes, firstMenuId }) => {
  const breadcrumbs = useContext(BreadcrumbContext);
  return (
    <Fragment>
      <div className={classes.linkContainer}>
        {breadcrumbs.length > 1 ? (
          <Breadcrumbs context={BreadcrumbContext} />
        ) : (
          <div>
            <NavLink
              to={`/warehouse_requests/requests/menu/${firstMenuId}`}
              className={classes.link}
              activeClassName={classes.activeLink}
              isActive={(_, location) =>
                location.pathname.includes('warehouse_requests/requests/menu')
              }
            >
              Requests
            </NavLink>
            <NavLink
              to="/warehouse_requests/queue"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Queue
            </NavLink>
            <NavLink
              to="/warehouse_requests/drop_list"
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Drop List Uploader
            </NavLink>
          </div>
        )}
      </div>
    </Fragment>
  );
};

WarehouseRequestsRouteLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  firstMenuId: PropTypes.string.isRequired,
};

export default WarehouseRequestsRouteLinks;
