import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const createTruck = async ({ truck, packingFacilityId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/trucks`,
    {
      ...options,
      method: 'POST',
      body: JSON.stringify({
        truck,
      }),
    }
  );

  return await handleResponse(response);
};

const updateTruck = async ({ packingFacilityId, truck, truckId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/warehouse/packing_facilities/${packingFacilityId}/trucks/${truckId}`,
    {
      ...options,
      method: 'PUT',
      body: JSON.stringify({
        truck,
      }),
    }
  );

  return await handleResponse(response);
};

export default {
  createTruck,
  updateTruck,
};
