export default {
  memoButton: {
    marginLeft: '15px',
  },
  dialogContent: {
    height: '150px',
    width: '300px',
    padding: '30px',
    paddingBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
