import React, { useState, useReducer } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import classnames from 'classnames';

import buttonGroupTheme from 'lib/buttonGroupTheme';
import { StickyBarProvider } from 'components/shared';

import IngredientQuestions from './IngredientQuestions';
import MealBagQuestions from './MealBagQuestions';
import LabelQuestions from './LabelQuestions';
import {
  reducer,
  initialState,
  UPDATE_PROBLEMATIC_INGREDIENT_IDS,
  UPDATE_PROBLEMATIC_INGREDIENT_ERRORS,
  NO_ISSUES,
} from './formUtils';

const QaCheckForm = ({
  ingredientAssignments,
  meal,
  onPostQaMealBagCheck,
  preliminaryCheckId,
  setRedirectToConfirmation,
  classes,
}) => {
  const [formState, dispatch] = useReducer(reducer, initialState);
  const { problematicIngredients, problematicIngredientErrors } = formState;

  const [isValidLabel, setIsValidLabel] = useState(null);
  const [labelingIssues, setLabelingIssues] = useState([]);
  const [labelCorrectiveAction, setLabelCorrectiveAction] = useState('');

  const [isPackagingAcceptable, setIsPackagingAcceptable] = useState(null);
  const [packagingIssues, setPackagingIssues] = useState([]);

  const labelFieldsComplete =
    isValidLabel ||
    (!isValidLabel &&
      labelingIssues.length > 0 &&
      labelCorrectiveAction.length > 0);

  const packagingFieldsComplete =
    isPackagingAcceptable ||
    (!isPackagingAcceptable && packagingIssues.length > 0);

  const ingredientErrorsComplete =
    problematicIngredients.length > 0 &&
    (problematicIngredientErrors.length === problematicIngredients.length ||
      problematicIngredients.includes(NO_ISSUES));

  const isFormValid =
    labelFieldsComplete && packagingFieldsComplete && ingredientErrorsComplete;
  const updateLabelErrorState = (value) => {
    setIsValidLabel(value);

    if (isValidLabel !== false) {
      setLabelingIssues([]);
      setLabelCorrectiveAction('');
    }
  };

  const updatePackagingErrorState = (value) => {
    setIsPackagingAcceptable(value);

    if (isPackagingAcceptable !== false) {
      setPackagingIssues([]);
    }
  };
  const submitCheck = (issues) => {
    onPostQaMealBagCheck({
      issues,
      preliminaryCheckId,
    });
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={buttonGroupTheme}>
        <div className={classes.container}>
          <LabelQuestions
            meal={meal}
            isValidLabel={isValidLabel}
            labelIssues={labelingIssues}
            labelCorrectiveAction={labelCorrectiveAction}
            onChangeLabelCorrectiveAction={(value) =>
              setLabelCorrectiveAction(value)
            }
            onChangeLabelIssueState={(value) => updateLabelErrorState(value)}
            onChangeLabelIssues={(value) => setLabelingIssues(value)}
            classes={classes}
          />
          <MealBagQuestions
            isPackagingAcceptable={isPackagingAcceptable}
            packagingIssues={packagingIssues}
            onChangePackagingErrorState={(value) =>
              updatePackagingErrorState(value)
            }
            onChangePackagingIssues={(value) => setPackagingIssues(value)}
            classes={classes}
          />
          <IngredientQuestions
            problematicIngredients={problematicIngredients}
            ingredientAssignments={ingredientAssignments}
            problematicIngredientErrors={problematicIngredientErrors}
            onChangeProblematicIngredients={(value) =>
              dispatch({
                type: UPDATE_PROBLEMATIC_INGREDIENT_IDS,
                value,
              })
            }
            onChangeIngredientErrors={(i, value) => {
              dispatch({
                type: UPDATE_PROBLEMATIC_INGREDIENT_ERRORS,
                ingredientId: i.ingredientId,
                value: value,
              });
            }}
            classes={classes}
          />
          <StickyBarProvider stickyPosition="bottom">
            <div className={classes.submitButtonGroup}>
              <Button
                className={classnames(
                  classes.noBorderRadius,
                  classes.halfWidthButtons,
                  classes.submitButton
                )}
                disabled={!isFormValid}
                value="submit"
                type="button"
                onClick={() =>
                  submitCheck({
                    labelingIssues,
                    labelCorrectiveAction,
                    packagingIssues,
                    problematicIngredientErrors,
                  })
                }
              >
                Submit & Check New Bag
              </Button>
              <Button
                disabled={!isFormValid}
                className={classnames(
                  classes.noBorderRadius,
                  classes.halfWidthButtons,
                  classes.submitBatchButtons
                )}
                value="submit"
                type="button"
                onClick={() => {
                  setRedirectToConfirmation(true);
                  submitCheck({
                    labelingIssues,
                    labelCorrectiveAction,
                    packagingIssues,
                    problematicIngredientErrors,
                  });
                }}
              >
                Save & Submit Batch
              </Button>
            </div>
          </StickyBarProvider>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

QaCheckForm.propTypes = {
  ingredientAssignments: PropTypes.array.isRequired,
  onPostQaMealBagCheck: PropTypes.func.isRequired,
  setRedirectToConfirmation: PropTypes.func.isRequired,
  meal: PropTypes.object.isRequired,
  preliminaryCheckId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default QaCheckForm;
