import {
  GREY_800,
  GREY_100,
  GREY_200,
  GREY_400,
  WHITE,
  BLACK,
  SUCCESS_GREEN,
} from 'lib/constants';

export default {
  toolbar: {
    justifyContent: 'flex-end',
    color: GREY_800,
    padding: '0 10px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  tableRowHead: {
    height: 'auto',
  },
  sortLabel: {
    color: `${WHITE} !important`,
  },
  sortLabelInactive: {
    color: `${GREY_400} !important`,
  },
  tableCell: {
    background: GREY_100,
    padding: '10px !important',
  },
  iconCell: {
    paddingLeft: '38px !important',
  },
  secondaryTable: {
    borderBottom: `1px solid ${GREY_400}`,
    padding: '0 !important',
  },
  secondaryTableHead: {
    borderBottom: 'none',
    color: BLACK,
    fontWeight: 'bold',
    cursor: 'auto',
  },
  secondaryIconCell: {
    paddingLeft: '21px ',
  },
  circleCheck: {
    fill: SUCCESS_GREEN,
    verticalAlign: 'middle',
  },
  greenText: {
    color: SUCCESS_GREEN,
  },
  todoIcon: {
    border: `2px solid ${GREY_200}`,
    height: '16px',
    width: '16px',
    borderRadius: '16px',
    margin: '2px',
  },
  tableHeader: {
    marginBottom: '-44px',
  },
};
