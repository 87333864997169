import { HC_GREEN, WHITE } from 'lib/constants';

export default {
  container: {
    backgroundColor: HC_GREEN,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '80%',
    alignItems: 'center',
    color: WHITE,
    fontSize: '10vw',
  },
  checkmark: {
    width: '150px',
  },
  scanAnotherBox: {
    fontSize: '60%',
    padding: '10px',
    border: `5px solid ${WHITE} `,
  },
  link: {
    textDecoration: 'none',
    color: WHITE,
  },
};
