export default {
  inventoryHeader: {
    marginTop: '0px',
    marginBottom: '6px',
  },
  inventoryTable: {
    borderRadius: '30px',
    marginBottom: '20px',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '1.17em',
    marginBottom: '3px',
  },
  formControl: {},
  shipDateDropdown: {
    float: 'right',
  },
};
