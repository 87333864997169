import * as Sentry from '@sentry/react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Route as ReactRouterDOMRoute,
  Switch,
  NavLink,
} from 'react-router-dom';

import ActivityLinks from './ActivityLinks';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const NavigationBar = ({ selectedMenu, classes }) => {
  const baseUrl = `/progress_tracker/menu/${selectedMenu.id}`;

  return (
    <div className={classes.controls}>
      <ActivityLinks selectedMenu={selectedMenu} />

      <Switch>
        <Route
          exact
          path={[
            '/progress_tracker/menu/:menuId/portioning',
            '/progress_tracker/menu/:menuId/portioning/by_ingredient',
          ]}
        >
          <div className={classes.subNavLinks}>
            <NavLink
              exact
              to={`${baseUrl}/portioning`}
              className={classes.link}
              activeClassName={classes.active}
            >
              By Meal
            </NavLink>
            <NavLink
              to={`${baseUrl}/portioning/by_ingredient`}
              className={classes.link}
              activeClassName={classes.active}
            >
              By Ingredient
            </NavLink>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

NavigationBar.propTypes = {
  selectedMenu: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default NavigationBar;
