import {
  GREY_200,
  HC_GREEN,
  WHITE,
  DARK_HC_GREEN,
  ERROR_RED,
} from 'lib/constants';

const buttonDisabled = {
  backgroundColor: GREY_200,
};

export const infoLabel = {
  fontSize: 14,
  margin: '10px 0',
  display: 'block',
};
export const infoValue = {
  margin: '5px 0px 20px',
  marginInlineStart: 0,
};
export const disabledField = {
  pointerEvents: 'none',
  opacity: '0.5',
};

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 20px',
    borderBottom: `1px solid ${GREY_200}`,
    fontWeight: 'bold',
    fontSize: 20,
  },
  bodySection: {
    overflowY: 'auto',
  },
  closeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  infoLabel,
  infoValue,
  icon: {
    width: 20,
    height: 20,
    margin: '0 auto',
  },
  noScroll: {
    overflowY: 'hidden',
  },
  error: {
    color: ERROR_RED,
  },
  buttonDisabled,

  submitButton: {
    height: 50,
    fontSize: 16,
    textTransform: 'none',
    marginTop: 20,

    '&$buttonDisabled': buttonDisabled,
    color: WHITE,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  buttonsContainer: {
    position: 'relative',
  },
  printerOptionsButton: {
    position: 'absolute',
    right: 0,
    height: 50,
    marginTop: 20,
  },
  finalized: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '500px',
  },
  reviewedOn: {
    fontSize: '16px',
    fontWeight: '400',
  },
  topInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  specButton: {
    marginTop: 'calc(1em + 10px)',
    textTransform: 'none',
    fontFamily: 'Muli',
  },
  toggleOn: {
    '&.Mui-checked': {
      color: HC_GREEN,
    },
    '+.Mui-checked': {
      backgroundColor: HC_GREEN,
    },
  },
  reprint: {
    paddingBottom: '20px',
  },
};
