import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';

import { stylesWithWarehouseReceiving } from 'lib/dataTableTheme';
import { Snackbar } from 'components/shared';
import { useSelectionDrawer, usePrevious } from 'lib/custom_hooks';
import { IMPOSSIBLE_UOM_ID } from 'lib/constants';

import { options } from './dataTableConfig';
import NetsuiteOrderItemDrawer from './NetsuiteOrderItemDrawer';
import TruckReceiving from './TruckReceiving';
import TruckDrawer from './TruckDrawer';

export const defaultNetsuiteOrderItem = {
  id: 0,
  name: '',
  quantity: 0,
  netsuiteOrderNumber: '',
  vendorName: '',
  measuringUnitName: '',
  measuringUnitId: IMPOSSIBLE_UOM_ID,
  potentialMeasuringUnits: [],
  netsuiteOrderType: '',
  defaultPrinterDescription: '',
  overageLimit: 200,
  needOverageApproval: true,
  trucks: [],
  netsuiteOrderId: 0,
};

const WarehouseReceiving = ({
  data,
  printing,
  printFailed,
  posting,
  postFailed,
  resetPallets,
  columns,
  trucks,
}) => {
  const {
    drawerOpen,
    selectedItem,
    optionsWithRowClick,
    onDrawerClose,
  } = useSelectionDrawer(data, options, resetPallets, defaultNetsuiteOrderItem);

  const prevPosting = usePrevious(posting);

  useEffect(() => {
    if (!posting && prevPosting && !postFailed) {
      onDrawerClose();
    }
  }, [posting, prevPosting, postFailed, onDrawerClose]);

  return (
    <div>
      <TruckReceiving trucks={trucks} />
      <ThemeProvider theme={stylesWithWarehouseReceiving}>
        <MUIDataTable
          title="Receiving"
          data={data}
          columns={columns}
          options={optionsWithRowClick}
        />
      </ThemeProvider>
      <TruckDrawer />
      <NetsuiteOrderItemDrawer
        open={drawerOpen}
        closeDrawer={onDrawerClose}
        netsuiteOrderItem={selectedItem}
      />
      <Snackbar loading={printing} failed={printFailed} />
    </div>
  );
};

WarehouseReceiving.propTypes = {
  data: PropTypes.array.isRequired,
  printing: PropTypes.bool.isRequired,
  printFailed: PropTypes.bool.isRequired,
  posting: PropTypes.bool.isRequired,
  postFailed: PropTypes.bool.isRequired,
  resetPallets: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  trucks: PropTypes.array.isRequired,
};

export default WarehouseReceiving;
