import {
  HC_GREEN,
  WHITE,
  GREY_200,
  GREY_600,
  GREY_800,
  DARK_HC_GREEN,
  NEGATIVE_RED,
} from 'lib/constants';

const button = {
  height: 50,
  fontSize: 16,
  textTransform: 'none',
  marginTop: 20,
};

const buttonDisabled = {
  backgroundColor: GREY_200,
};

const selected = {
  backgroundColor: HC_GREEN,
  color: WHITE,
  '&:hover': {
    backgroundColor: HC_GREEN,
  },
};

export default {
  root: {
    fontFamily: 'Muli',
    width: 600,
    padding: 30,
  },
  form: {
    width: '100%',
    overflowX: 'hidden',
  },
  datePicker: {
    width: '100%',
    margin: '3px 20px 10px 0px',
  },
  duration: {
    margin: '3px 6px 10px 6px',
    flex: '4 0 0',
  },
  arrivalTime: {
    margin: '3px 0px 10px 0px',
    flex: '1 0 0',
  },
  infoValue: {
    fontSize: 20,
    margin: '5px 0px 5px 0px',
    marginInlineStart: 0,
  },
  buttonDisabled,
  submitButton: {
    ...button,
    borderRadius: '4px 4px 4px 4px',
    '&$buttonDisabled': buttonDisabled,
    color: WHITE,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  cancelButton: {
    ...button,
    color: GREY_600,
  },
  deleteButton: {
    ...button,
    color: NEGATIVE_RED,
    borderColor: NEGATIVE_RED,
  },
  header: {
    float: 'left',
    margin: '15px 0px 3px 0px',
    fontSize: '16px',
    color: GREY_800,
  },
  timeHeader: {
    width: '100%',
    margin: '15px 0px 0px 0px',
    fontSize: '16px',
    color: GREY_800,
  },
  durationHeader: {
    flex: '4 0 0',
  },
  arrivalTimeHeader: {
    flex: '3 0 0',
  },
  menuItem: {
    '&$selected': selected,
    '&$selected:hover': selected,
  },
  timeContainer: {
    display: 'flex',
  },
  textField: {
    marginTop: '3px',
  },
  list: {
    paddingTop: '0px',
    maxHeight: 306,
    overflow: 'auto',
    border: `1px solid ${GREY_200}`,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  listItemTextNetsuite: {
    flexGrow: 1,
    flexBasis: '20%',
    textAlign: 'left',
  },
  listItemTextDate: {
    flexGrow: 1,
    flexBasis: '25%',
    textAlign: 'left',
  },
  listItemTextVendor: {
    flexGrow: 1,
    flexBasis: '55%',
    textAlign: 'left',
  },
  selected,
  dialog: {
    fontFamily: 'Muli',
    padding: 20,
  },
  dialogButtons: {
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'space-around',
  },
};
