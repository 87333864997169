import {
  HC_GREEN,
  WHITE,
  GREY_200,
  DARK_HC_GREEN,
  BLACK,
  GREY_400,
  GREY_600,
  DARK_RED,
} from 'lib/constants';
import { centeredLoading, addEllipsis } from 'lib/styles';

const button = {
  height: 50,
  fontSize: 16,
  textTransform: 'none',
  marginTop: 20,
};

const buttonDisabled = {
  backgroundColor: GREY_200,
  color: `${GREY_600} !important`,
};

export default {
  buttonDisabled,
  RequestFormCenteredLoading: {
    ...centeredLoading,
    paddingTop: '10px',
  },
  submitButton: {
    ...button,
    borderRadius: '4px 4px 4px 4px',
    '&$buttonDisabled': buttonDisabled,
    color: `${WHITE} !important`,
    backgroundColor: HC_GREEN,
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
      '&$buttonDisabled': buttonDisabled,
    },
  },
  buttonsContainer: {
    display: 'flex',
  },
  buttonGroup: {
    display: 'grid',
    gridGap: '1px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(20%, 0.25fr))',
    marginBottom: '20px',
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
  ingButton: {
    color: BLACK,
    minHeight: '75px',
    overflow: 'hidden',
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
      '& .ingButton2': {
        color: WHITE,
      },
    },
    '& .ingButton2': {
      fontStyle: 'italic',
      color: GREY_600,
      marginTop: '5px',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  },
  ingName: {
    ...addEllipsis,
  },

  brandForIng: {
    ...addEllipsis,
    fontStyle: 'italic',
    color: GREY_400,
  },

  ingredientRequestPadding: {
    padding: '0px 15px 0px 25px',
  },
  gridRequestsThreeItems: {
    display: 'grid',
    gridGap: '5px',
    gridTemplateColumns: '45% 33% 10% 11%',
    alignItems: 'center',
    paddingTop: '10px',
  },
  trashIcon: {
    margin: '0px 15px',
  },
  portionToCasesRatio: {
    paddingLeft: '10px',
    color: DARK_RED,
  },
};
