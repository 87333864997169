import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';

import { ItemManagerFormProvider } from '../../ItemManagerFormProvider';
import styles from './styles';

const useStyles = makeStyles(styles);

const FormDrawer = ({
  children,
  open,
  toggleOpen,
  headerText,
  productionZoneItem,
}) => {
  const classes = useStyles();

  const closeDrawer = () => {
    toggleOpen(false);
  };

  const itemText = isEmpty(productionZoneItem)
    ? ''
    : `${productionZoneItem.item.name}, ${productionZoneItem.item.id}`;

  const caseText = isEmpty(productionZoneItem)
    ? ''
    : `${productionZoneItem.quantity} Cases, ${productionZoneItem.zoneName}`;

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      data-testid="form-drawer"
    >
      <div className={classes.root}>
        <div className={classes.topSection}>
          <TabOutlineButton
            className={classes.closeButton}
            type="button"
            onClick={closeDrawer}
          >
            <CloseIcon className={classes.icon} />
          </TabOutlineButton>
        </div>
        <div className={classes.bodySection}>
          <div className={classes.dataSection}>
            <header className={classes.header}>{headerText}</header>
            <div className={classes.ingredient}>{`${itemText}`}</div>
            <div className={classes.caseText}>{caseText}</div>
          </div>
          <ItemManagerFormProvider productionZoneItem={productionZoneItem}>
            {children}
          </ItemManagerFormProvider>
        </div>
      </div>
    </Drawer>
  );
};

FormDrawer.defaultProps = {
  children: null,
  open: false,
  headerText: 'Move Item',
  productionZoneItem: {},
};

FormDrawer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  productionZoneItem: PropTypes.object,
};

export default FormDrawer;
