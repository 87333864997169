import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import Card from '@mui/material/Card';

import { Loading } from 'components/shared';
import Search from 'components/shared/Search';

const IngredientList = ({ classes, ingredients }) => {
  const formatDate = (date) => format(new Date(date), 'MM/dd');

  const [searchTerm, setSearchTerm] = useState('');
  const filteredIngredients = useMemo(() => {
    if (!ingredients) {
      return {};
    }
    return searchTerm.length > 0
      ? filter(ingredients, (ingredient) => {
          return ingredient.ingredientName
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        })
      : ingredients;
  }, [ingredients, searchTerm]);

  if (ingredients.length === 0 || isEmpty(ingredients)) {
    return (
      <div className={classes.centeredLoading} data-testid="loading">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {ingredients && (
        <Card>
          <div className={classes.topContent}>
            <Search onSearch={(term) => setSearchTerm(term)} />
          </div>
          <div className={classes.table}>
            <div className={classes.headerText}>INGREDIENTS</div>
            {map(
              orderBy(filteredIngredients, 'ingredientName', 'asc'),
              (ingredient) => {
                return !ingredient.customizeIt ? (
                  <div
                    className={classes.zebraRow}
                    key={ingredient.ingredientId}
                  >
                    <Link
                      to={{
                        pathname: `${location.pathname}/ingredient/${ingredient.ingredientId}/meals`,
                        state: {
                          ingredient: ingredient,
                        },
                      }}
                      className={classes.link}
                    >
                      <div className={classes.row}>
                        {ingredient.ingredientName} &nbsp;
                        <span className={classes.ingredientId}>
                          {' '}
                          - {ingredient.ingredientId}
                        </span>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div
                    className={classes.zebraRow}
                    key={ingredient.ingredientId}
                  >
                    <div className={classes.row}>
                      {ingredient.ingredientName} &nbsp;
                      <span className={classes.ingredientId}>
                        {' '}
                        - {ingredient.ingredientId}
                      </span>
                      <span className={classes.rightAlignShipWeeks}>
                        {map(ingredient.menuShipWeek, (shipWeek) => {
                          return (
                            <Button
                              variant="outlined"
                              key={shipWeek}
                              className={classes.shipWeekButton}
                            >
                              <Link
                                tabIndex={0}
                                key={shipWeek}
                                underline="none"
                                className={classes.shipWeekLink}
                                variant="inherit"
                                to={{
                                  pathname: `${location.pathname}/ingredient_assignments/${ingredient.ingredientAssignmentIds[0]}/ship_week/${shipWeek}`,
                                  state: {
                                    ingredientAssignment: ingredient,
                                    shipWeek: shipWeek,
                                  },
                                }}
                              >
                                {formatDate(shipWeek)}
                              </Link>
                            </Button>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

IngredientList.defaultProps = {
  ingredients: {},
};

IngredientList.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredients: PropTypes.object,
};

export default IngredientList;
