import {
  DARK_HC_GREEN,
  WHITE,
  GREY_150,
  AHEAD_OF_TARGET_GREEN,
  BELOW_TARGET_RED,
  ON_TARGET_GREY,
} from 'lib/constants';
import theme from 'lib/theme';

const channelCircle = {
  color: WHITE,
  padding: '2px',
  width: '18px',
  borderRadius: '25px',
  textAlign: 'center',
  display: 'inline-block',
  margin: '0 2px',
};

export default {
  container: {
    marginTop: '40px',
  },
  panel: {
    alignItems: 'center',
    padding: '0 12px',
  },
  ingredientItem: {
    marginBottom: '15px',
  },
  gridContainer: {
    lineHeight: '36px',
    '&:nth-child(even) .zebra': {
      backgroundColor: GREY_150,
    },
  },
  tableHeader: {
    textTransform: 'uppercase',
    fontSize: '13px',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'end',
  },
  ecommerceCircle: {
    background: DARK_HC_GREEN,
    ...channelCircle,
  },
  completedCount: {
    paddingRight: '15px',
  },
  slash: {
    display: 'inline-block',
    marginLeft: '10px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '40px',
    },
  },
  goalCount: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      minWidth: '50px',
      paddingRight: '30%',
    },
  },
  ingredientName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '130px',
    },
  },
  mealTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell: {
    padding: '0 10px',
  },
  onTarget: {
    color: ON_TARGET_GREY,
  },
  belowTarget: {
    color: BELOW_TARGET_RED,
  },
  aheadOfTarget: {
    color: AHEAD_OF_TARGET_GREEN,
  },
  panelDetailsRoot: {
    padding: '8px 12px 24px',
  },
  textBold: {
    fontWeight: '900',
  },
  ingredientPercentProgress: {
    textAlign: 'right',
    fontWeight: '900',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
};
