import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import {
  fetchItemsFromQuery,
  clearSearchResults,
} from 'redux/itemMoveRequests/actions';

import styles from './styles';
import SearchItemDrawer from './SearchItemDrawer';

export const mapStateToProps = (state) => ({
  searchResults: state.itemMoveRequests.searchResults,
  fetchingSearchResults: state.itemMoveRequests.fetchingSearchResults,
});

export const mapDispatchToProps = {
  onSearchItems: fetchItemsFromQuery,
  onClearSearchResults: clearSearchResults,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchItemDrawer));
