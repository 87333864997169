import { BORDER_GREY, ROBOTO } from 'lib/constants';

import { tableHead, loadingSpinner } from '../styles';

export default {
  tableHead,
  loadingSpinner,
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
    fontFamily: ROBOTO,
    marginTop: '20px',
  },
};
