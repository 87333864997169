import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { holdPallet } from 'redux/pallets/actions';

import PalletDetailsHoldButton from './PalletDetailsHoldButton';

const mapStateToProps = (state) => ({
  holding: state.pallets.inventoryApp.holding,
  holdFailed: state.pallets.inventoryApp.holdFailed,
});

const mapDispatchToProps = {
  onPalletHold: holdPallet,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PalletDetailsHoldButton)
);
