import { WHITE, BLACK } from 'lib/constants';

export default {
  arrow: {
    fontSize: '30px',
    position: 'absolute',
    left: '20px',
    top: '50%',
    marginTop: '-15px',
    color: WHITE,
  },
  header: {
    backgroundColor: `${BLACK} !important`,
    width: '100%',
  },
};
