import React from 'react';
import PropTypes from 'prop-types';

import { WHITE } from 'lib/constants';

const EyeIcon = ({ className, visible, color }) => {
  if (visible === true) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        className={className}
        data-testid="eye-icon"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
          fill={color}
        />
      </svg>
    );
  } else {
    return null;
  }
};

EyeIcon.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  color: PropTypes.string,
};

EyeIcon.defaultProps = {
  className: 'EyeIcon',
  visible: true,
  color: WHITE,
};

export default EyeIcon;
