import React from 'react';
import { format } from 'date-fns';

import { FormatNumberCell } from 'components/shared';
import {
  TO_DO,
  ACKNOWLEDGED,
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,
} from 'lib/constants';
import { getMoveRequestDisplayName } from 'lib/viewHelpers';

import StatusChip from '../StatusChip';

//Keys for columns. These are unique identifiers for the
//columns
const REQUEST_START_DATE = 'requestStartDate';
const REQUEST_START_TIME = 'requestStartTime';
const DROP_OFF_TIME = 'dropOffTime';
const PALLET_ID = 'palletId';
const QUANTITY_REQUESTED = 'quantityRequested';
const STATUS = 'status';
const NAME = 'name';
const SUBMITTER = 'submitter';
const DROP_OFF_LOCATION = 'dropOffLocation';
const CANCELLED_BY = 'cancelledBy';
const RUNNER = 'runner';
const PICK_UP_TIME = 'pickUpTime';
const TIME_ZONE = 'timeZone';
const CANCEL_REASON = 'cancelReason';
const WORK_IN_PROGRESS_INFO = 'workInProgressInfo';
const CATEGORY = 'category';
const REQUESTED_CASES = 'requestedCases';
const FULFILLED_CASES = 'fulfilledCases';

export const defaultTableConfiguration = [
  {
    name: 'requestStart',
    key: REQUEST_START_DATE,
    label: 'Date',
    options: {},
  },
  {
    name: 'requestStart',
    key: REQUEST_START_TIME,
    label: 'Requested Time',
    options: {},
  },
  {
    name: DROP_OFF_TIME,
    key: DROP_OFF_TIME,
    label: 'Drop Off Time',
    options: {},
  },
  {
    name: PALLET_ID,
    key: PALLET_ID,
    label: 'Lot #',
    options: {
      display: 'false',
    },
  },
  {
    name: QUANTITY_REQUESTED,
    key: QUANTITY_REQUESTED,
    label: 'Requested',
    options: {},
  },
  {
    name: STATUS,
    key: STATUS,
    label: 'Status',
    options: {},
  },
  {
    name: NAME,
    key: NAME,
    label: 'Item',
    options: {},
  },
  {
    name: SUBMITTER,
    key: SUBMITTER,
    label: 'Submitter',
    options: {},
  },
  {
    name: DROP_OFF_LOCATION,
    key: DROP_OFF_LOCATION,
    label: 'Drop Off Location',
    options: {},
  },
  {
    name: CANCELLED_BY,
    key: CANCELLED_BY,
    label: 'Canceller',
    options: {
      display: 'false',
    },
  },
  {
    name: RUNNER,
    key: RUNNER,
    label: 'Runner',
    options: {
      display: 'false',
    },
  },
  {
    name: PICK_UP_TIME,
    key: PICK_UP_TIME,
    label: 'Pick Up Time',
    options: {
      display: 'false',
    },
  },
  {
    name: TIME_ZONE,
    key: TIME_ZONE,
    label: 'Time Zone',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: CANCEL_REASON,
    key: CANCEL_REASON,
    label: 'Cancel Reason',
    options: {},
  },
  {
    name: WORK_IN_PROGRESS_INFO,
    key: WORK_IN_PROGRESS_INFO,
    label: 'Work In Progress Info',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: CATEGORY,
    key: CATEGORY,
    label: 'Category',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: REQUESTED_CASES,
    key: REQUESTED_CASES,
    label: 'Requested Cases',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: FULFILLED_CASES,
    key: FULFILLED_CASES,
    label: 'Fulfilled Cases',
    options: {
      display: 'false',
      filter: false,
    },
  },
];

const indexForColumn = (columnKey) => {
  const index = defaultTableConfiguration.findIndex((columnConfig) => {
    return columnConfig.key === columnKey;
  });

  return index != -1 ? index : null;
};

const statusOrder = (status) => {
  const STATUS_ORDER = [TO_DO, ACKNOWLEDGED, IN_PROGRESS, COMPLETED, CANCELLED];
  const indexForStatus = STATUS_ORDER.findIndex((s) => {
    return s == status;
  });

  if (indexForStatus != -1) {
    return indexForStatus;
  } else {
    return STATUS_ORDER.length + 1;
  }
};

//Ignore the warning generated by this method. defaultTableConfiguration is not "used"
//before it is called, this is a callback function executed when formatting MUI datatables
//and is called after definition.
const formatTimeValue = (value, tableMeta) => {
  const indexForTimeZone = indexForColumn(TIME_ZONE);

  const facilityTimeZone = tableMeta.rowData[indexForTimeZone];

  let options = {
      timeZone: facilityTimeZone,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    formatter = new Intl.DateTimeFormat([], options);

  return value ? formatter.format(new Date(value)) : null;
};

const formatDateValue = (value) => {
  return format(new Date(value), 'MM/dd/yy');
};

export const functionAssociations = {
  requestStartDate: {
    customBodyRender: (value) => {
      return formatDateValue(value);
    },
  },
  requestStartTime: {
    customBodyRender: (value, tableMeta) => {
      return formatTimeValue(value, tableMeta);
    },
  },
  dropOffTime: {
    customBodyRender: (value, tableMeta) => {
      return formatTimeValue(value, tableMeta);
    },
  },
  quantityRequested: {
    customBodyRender: (value) => {
      const result = <FormatNumberCell value={value} addComma />;
      return result;
    },
  },
  status: {
    customBodyRender: (value, tableMeta) => {
      const result = (
        <StatusChip
          status={value}
          runner={tableMeta.rowData[indexForColumn(RUNNER)]}
          canceller={tableMeta.rowData[indexForColumn(CANCELLED_BY)]}
        />
      );
      return result;
    },
    sortCompare: (order) => {
      return (a, b) => {
        return (
          (statusOrder(a.data) - statusOrder(b.data)) *
          (order === 'desc' ? 1 : -1)
        );
      };
    },
  },
  name: {
    customBodyRender: (value, tableMeta) =>
      getMoveRequestDisplayName({
        category: tableMeta.rowData[indexForColumn(CATEGORY)],
        name: value,
        workInProgressInfo:
          tableMeta.rowData[indexForColumn(WORK_IN_PROGRESS_INFO)],
      }),
  },
  pickUpTime: {
    customBodyRender: (value, tableMeta) => {
      return formatTimeValue(value, tableMeta);
    },
  },
};

export const options = {
  selectableRows: 'none',
  rowsPerPage: 100,
  rowsPerPageOptions: [10, 50, 100],
  print: false,
  responsive: 'standard',
  download: false,
  sortOrder: { name: 'status', direction: 'desc' },
};
