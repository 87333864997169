import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  setCycleCount,
  cycleCountDeactivation,
  setUnknownLocation,
} from 'redux/storageSlot/actions';
import { fetchPallet } from 'redux/pallets/actions';

import PalletDetailsAuditButton from './PalletDetailsAuditButton';

const mapStateToProps = (state) => ({
  auditing: state.pallets.inventoryApp.auditing,
  auditFailed: state.pallets.inventoryApp.auditFailed,
});

const mapDispatchToProps = {
  onSetConfirmLocation: setCycleCount,
  onSetIssuedToProduction: cycleCountDeactivation,
  onSetUnknownLocation: setUnknownLocation,
  fetchPallet: fetchPallet,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PalletDetailsAuditButton)
);
