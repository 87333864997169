import { BLACK, GREY_200, MEDIA_QUERY_TABLET, HC_GREEN } from 'lib/constants';

export default {
  modalButtons: {
    textTransform: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: `1px solid ${GREY_200}`,
    margin: '6px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  promptContainer: {
    paddingTop: '12px !important',
    paddingBottom: '4px',
    overflow: 'hidden',
  },
  promptText: {
    color: BLACK,
    fontFamily: 'Muli',
  },
  optionsContainer: {
    marginLeft: '-12px',
    paddingBottom: '4px',
  },
  muiPaper: {
    maxHeight: '100%',
  },
  [MEDIA_QUERY_TABLET]: {
    button: {
      fontSize: '36px',
    },
  },
  confirmationButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  confirmationButton: {
    margin: '5px',
  },
  confirmationText: {
    color: BLACK,
  },
  putbackFeatureLink: {
    marginTop: '8px',
    fontFamily: 'Muli',
  },
  openButton: {
    padding: '3px',
    color: HC_GREEN,
  },
  buttonDisabled: {
    color: GREY_200,
  },
};
