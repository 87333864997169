import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

const ProgressBar = ({
  activityCounts,
  totalCount,
  totalProgressBarLengthInPixels,
}) => {
  const classes = useStyles();

  const progressBarSections = () => {
    return activityCounts.map(
      ([activity, counts], index) =>
        counts > 0 && (
          <div
            className={classes[activity]}
            role={activity}
            style={{
              width: `${
                (counts / totalCount) * totalProgressBarLengthInPixels
              }%`,
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        )
    );
  };

  return (
    <div
      className={classes.progressBarDiv}
      style={{
        width: `${totalProgressBarLengthInPixels}px`,
      }}
      data-testid="progress-bar"
    >
      {progressBarSections()}
    </div>
  );
};

ProgressBar.propTypes = {
  activityCounts: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalProgressBarLengthInPixels: PropTypes.number.isRequired,
};

export default ProgressBar;
