import { GREY_150, GREY_400, BLACK, GREY_650, HC_GREEN } from 'lib/constants';
import { centeredLoading } from 'lib/styles';

export default {
  link: {
    textDecoration: 'none',
  },
  shipWeekButton: {
    borderColor: HC_GREEN,
    marginRight: '10px',
  },
  shipWeekLink: {
    color: HC_GREEN,
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      outline: 0,
      border: 'none',
    },
  },
  centeredLoading,
  topContent: {
    display: 'flex',
    marginBottom: '5px',
    justifyContent: 'flex-end',
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    height: '30px',
    fontSize: '14px',
    color: GREY_650,
    paddingLeft: '10px',
    borderBottom: `1px solid ${GREY_400}`,
  },
  tableRow: {
    display: 'grid',
    alignItems: 'center',
    height: '20px',
    margin: '10px 0px 10px 10px',
  },
  row: {
    padding: '10px 0px 10px 10px',
    borderBottom: `1px solid ${GREY_400}`,
    color: `${BLACK}`,
    height: '37px',
    alignItems: 'center',
    display: 'flex',
  },
  zebraRow: {
    '&:nth-child(odd)': {
      background: `${GREY_150}`,
    },
  },
  ingredientId: {
    color: `${GREY_650}`,
  },
  sorryMessage: {
    ...centeredLoading,
  },
  rightAlignShipWeeks: {
    textAlign: 'right',
    marginLeft: '15px',
    float: 'right',
  },
};
