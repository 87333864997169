import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const cleanData = (data) => {
  return Object.values(data).map((row) => {
    const dataRow = row.data;

    return {
      type: dataRow['Type'],
      meal_id: dataRow['Meal ID'],
      ingredient_id: dataRow['Ingredient ID'],
      amount_to_complete: dataRow['Amount to Complete'],
      drop_zone: dataRow['Drop Zone'],
    };
  });
};

const postDropList = async (params) => {
  const dropListParams = (({ packingFacilityId, ...param }) => param)(params);

  const response = await fetch(`${API_BASE_URI}/ops/warehouse/drop_list`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      packing_facility_id: params.packingFacilityId,
      drop_list: cleanData(dropListParams),
    }),
  });

  return await handleResponse(response);
};

export default {
  postDropList,
};
