import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import ceil from 'lodash/ceil';

import { ACTIVE, ON_HOLD, DEACTIVATED, PICK_UP } from 'lib/constants';
import { getFirstAndLastInitials } from 'lib/utils';
import { usePalletExceptionHandler } from 'lib/custom_hooks';
import { Loading, StatefulButton } from 'components/shared';

import WrongLotModal from '../WrongLotModal';
import WrongItemModal from '../WrongItemModal';
import ReactivateButton from '../ViewSearchPallet/ReactivateButton';
import QuantityToZoneDisplay from '../QuantityToZoneDisplay';

const ViewPallet = ({
  classes,
  signedInUser,
  itemMoveRequest,
  hasItemMoveRequest,
  palletId,
  pallet,
  suggestedPallet,
  onClearSuggestedPallet,
  history,
  fetchFailed,
  onFetchPallet,
  onGetCaseConversion,
  onResetSelectedPallet,
  onSubmitItemMoveRequest,
  onUpdateItemMoveRequest,
  onConfirmOverride,
  posting,
  postingFailed,
  updating,
  updatingFailed,
}) => {
  const [overrideInfo, setOverrideInfo] = useState({});

  const {
    wrongLotModalOpen,
    wrongItemModalOpen,
    closeModal,
  } = usePalletExceptionHandler(itemMoveRequest, pallet, suggestedPallet);

  useEffect(() => {
    onFetchPallet(palletId);
    return () => {
      onResetSelectedPallet();
    };
  }, [onFetchPallet, onResetSelectedPallet, palletId]);

  const fetchedConversionData = useRef(null);

  useEffect(() => {
    if (
      itemMoveRequest &&
      !itemMoveRequest.pickedUp &&
      !fetchedConversionData.current
    ) {
      onGetCaseConversion(itemMoveRequest.id, palletId);
      fetchedConversionData.current = true;
    }
  }, [onGetCaseConversion, palletId, itemMoveRequest]);

  const [numberToMove, setNumberToMove] = useState(undefined);

  const moveButtonText = {
    LOADING: 'Moving...',
    SUCCESS: 'Success',
    ERROR: 'There was an error moving',
    DEFAULT: `Move ${numberToMove || 0} at ${pallet.measuringUnit}`,
  };

  const handleInputChange = (e) => {
    setNumberToMove(parseInt(e.target.value));
  };

  const validInput = () => {
    return numberToMove
      ? numberToMove > 0 && numberToMove <= pallet.quantity
      : true;
  };

  const redirectToOpenQueue = () => {
    history.push('/warehouse_moves/open');
  };

  const redirectToInProgressQueue = () => {
    history.push('/warehouse_moves/in_progress');
  };

  const handleSubmit = async () => {
    /* Using 'newQuantity' is a holdover from the old item move manager API.
      Could be a good opportunity for refactoring to just use 'quantity' once old app is removed and we can update the API */
    const newQuantity = pallet.quantity - numberToMove;
    const isFullPallet = newQuantity === 0;

    if (itemMoveRequest) {
      await onUpdateItemMoveRequest(itemMoveRequest.id, PICK_UP, {
        quantity: newQuantity,
        pallet_id: palletId,
        requested_cases: itemMoveRequest.requestedCases,
        fulfilled_cases: numberToMove,
        full_pallet: isFullPallet,
      });

      if (!isEmpty(overrideInfo) && !updatingFailed) {
        onConfirmOverride(
          overrideInfo.itemMoveRequestId,
          overrideInfo.suggestedPalletId,
          overrideInfo.reasonCode,
          overrideInfo.approver
        );
      }

      onClearSuggestedPallet();
    } else {
      onSubmitItemMoveRequest(palletId, newQuantity, isFullPallet);
    }
  };

  const successCallback = () => {
    if (itemMoveRequest?.requestedCases > numberToMove) {
      history.push(`/warehouse_moves/requests/${itemMoveRequest.id}/follow_up`);
    } else {
      redirectToInProgressQueue();
    }
  };

  const renderBanner = () => {
    switch (pallet.status) {
      case ACTIVE:
        return null;
      case ON_HOLD:
        return (
          <div className={classes.onHoldBanner}>
            Pallet on hold - {pallet.heldAt}
          </div>
        );
      case DEACTIVATED:
        return (
          <div className={classes.deactivedBanner}>
            Pallet Deleted - {pallet.deactivatedAt}
          </div>
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (pallet.status) {
      case ACTIVE:
        return (
          <>
            <CardContent className={classes.cardContent}>
              <TextField
                className={classes.textField}
                type="number"
                variant="outlined"
                label="Number to move"
                value={numberToMove || ''}
                onChange={handleInputChange}
                inputProps={{
                  className: classes.numericInput,
                  min: 1,
                  step: 1,
                }}
                error={!validInput()}
              />
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                className={classes.cancelButton}
                onClick={redirectToOpenQueue}
              >
                Cancel
              </Button>
              <StatefulButton
                loading={posting || updating}
                failed={postingFailed || updatingFailed}
                disabled={
                  !numberToMove ||
                  numberToMove > pallet.quantity ||
                  numberToMove < 1
                }
                buttonTextOptions={moveButtonText}
                className={classes.submitButton}
                onClick={handleSubmit}
                successCallback={successCallback}
                waitTime={500}
              />
            </CardActions>
          </>
        );
      case ON_HOLD:
        return (
          <CardActions>
            <Button
              variant="outlined"
              className={classes.backButton}
              onClick={() => history.push('/warehouse_moves')}
            >
              <ArrowBackIcon />
              Back
            </Button>
          </CardActions>
        );
      case DEACTIVATED:
        return (
          <CardActions className={classes.reactivateContainer}>
            <ReactivateButton palletId={palletId} />
          </CardActions>
        );
      default:
        return null;
    }
  };

  const renderItemMoveRequest = () => {
    if (itemMoveRequest) {
      return (
        <Card className={classes.card}>
          <div
            className={classes.header}
          >{`Request - ${itemMoveRequest.name}`}</div>
          <CardContent className={classes.cardContent}>
            <QuantityToZoneDisplay itemMoveRequest={itemMoveRequest} />
            <div className={classes.rightSide}>
              {itemMoveRequest.status === 'Acknowledged' && (
                <Chip
                  className={classNames(classes.chip, {
                    [classes.myChip]: itemMoveRequest.runner === signedInUser,
                  })}
                  label={getFirstAndLastInitials(itemMoveRequest.runner)}
                />
              )}
            </div>
            <div className={classes.lightTextOneLine}>
              Brand: {itemMoveRequest.requestedBrand}
            </div>
          </CardContent>
        </Card>
      );
    } else {
      return (
        <div className={classes.centered}>
          <Loading />
        </div>
      );
    }
  };

  if (fetchFailed) {
    return (
      <div className={classes.noPalletFound}>
        Sorry, no pallet matching that ID found
      </div>
    );
  }

  const casesRequestedNotFetched =
    isEmpty(itemMoveRequest) ||
    (itemMoveRequest && !itemMoveRequest.hasOwnProperty('requestedCases'));

  const needsCaseInfo =
    hasItemMoveRequest &&
    itemMoveRequest?.pickedUp !== true &&
    casesRequestedNotFetched;

  return (
    <Fragment>
      {hasItemMoveRequest && renderItemMoveRequest()}
      {isEmpty(pallet) || needsCaseInfo ? (
        <div className={classes.centered}>
          <Loading />
        </div>
      ) : (
        <Card className={classes.card}>
          <div className={classes.header}>{`Pickup - Lot ${pallet.id}`}</div>
          {renderBanner()}
          <CardContent className={classes.cardContent}>
            <span className={classes.palletText}>{pallet.item}</span>
            {pallet.brand !== '-' && (
              <span className={classes.lightText}>{` - ${pallet.brand}`}</span>
            )}
            <hr className={classes.line} />
            {itemMoveRequest && itemMoveRequest.requestedCases ? (
              <div>
                <div className={classes.casesRequested}>
                  {ceil(itemMoveRequest.requestedCases)} required
                </div>
                <div className={classes.casesAvailable}>
                  <span className={classes.palletMeasuringUnit}>
                    @ {pallet.measuringUnit}
                  </span>
                  <span
                    className={classNames(classes.palletQuantity, {
                      [classes.insufficientQuantity]:
                        pallet.quantity < itemMoveRequest.requestedCases,
                    })}
                  >
                    {pallet.quantity} available
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <span>{pallet.measuringUnit}</span>
                <span
                  className={classes.lightText}
                >{` - ${pallet.quantity} available`}</span>
              </div>
            )}
          </CardContent>
          {renderContent()}
        </Card>
      )}
      <WrongLotModal
        isOpen={wrongLotModalOpen}
        closeModal={closeModal}
        pallet={pallet}
        suggestedPallet={suggestedPallet}
        itemMoveRequest={itemMoveRequest}
        setOverrideInfo={setOverrideInfo}
      />
      <WrongItemModal
        isOpen={wrongItemModalOpen}
        closeModal={closeModal}
        pallet={pallet}
        suggestedPallet={suggestedPallet}
        itemMoveRequest={itemMoveRequest}
        setOverrideInfo={setOverrideInfo}
      />
    </Fragment>
  );
};

ViewPallet.propTypes = {
  classes: PropTypes.object.isRequired,
  signedInUser: PropTypes.string.isRequired,
  itemMoveRequest: PropTypes.object,
  hasItemMoveRequest: PropTypes.bool.isRequired,
  palletId: PropTypes.string.isRequired,
  pallet: PropTypes.object.isRequired,
  suggestedPallet: PropTypes.object,
  history: PropTypes.object.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  onFetchPallet: PropTypes.func.isRequired,
  onResetSelectedPallet: PropTypes.func.isRequired,
  onSubmitItemMoveRequest: PropTypes.func.isRequired,
  onUpdateItemMoveRequest: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  postingFailed: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
  onGetCaseConversion: PropTypes.func.isRequired,
  onConfirmOverride: PropTypes.func.isRequired,
  onClearSuggestedPallet: PropTypes.func.isRequired,
};

ViewPallet.defaultProps = {
  itemMoveRequest: null,
  suggestedPallet: null,
};

export default ViewPallet;
