import {
  QA_PORTION_MEALS,
  QA_PORTION_MEALS_REQUEST,
  QA_PORTION_MEALS_SUCCESS,
} from 'lib/constants';

const initialState = {
  classic: [],
  freshAndEasy: [],
  retail: [],
  podNames: [],
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case QA_PORTION_MEALS:
      return {
        ...state,
        fetching: true,
      };
    case QA_PORTION_MEALS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case QA_PORTION_MEALS_SUCCESS:
      return {
        ...state,
        ecommerce: action.response.ecommerce,
        classic: action.response.classic,
        freshAndEasy: action.response.freshAndEasy,
        retail: action.response.retail,
        podNames: action.response.podNames,
        fetching: false,
      };
    default:
      return state;
  }
}
