import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import isEmpty from 'lodash/isEmpty';

import { setTopBarContent } from 'redux/topBar/actions';
import { selectMenu, updateMenus } from 'redux/menu/actions';
import { fetchMealsByMenu } from 'redux/meal/actions';
import { HOME_BUTTON, PROGRESS_TRACKER, ACCOUNT_DROPDOWN } from 'lib/constants';

import ProgressOverview from './ProgressOverview';
import { Loading } from '../../shared';
import styles from './styles';

const ProgressOverviewContainer = (props) => {
  const {
    onMenuRequest,
    onMenuUpdated,
    fetching,
    classes,
    packingFacilityId,
    menu,
    onSetTopBarContent,
    onFetchMealsByMenu,
    meals,
    customizeItIngredients,
  } = props;
  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: PROGRESS_TRACKER,
      rightContent: ACCOUNT_DROPDOWN,
    });
  }, [onSetTopBarContent]);

  useEffect(() => {
    if (menu.id) {
      onFetchMealsByMenu({
        facilityId: packingFacilityId,
        menuId: menu.id,
      });
    }
  }, [
    onMenuRequest,
    menu,
    packingFacilityId,
    onMenuUpdated,
    onFetchMealsByMenu,
  ]);

  if (fetching || isEmpty(meals)) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  } else {
    return (
      <ProgressOverview
        {...props}
        meals={meals}
        menu={menu}
        isPartOfStandardMenu={menu.isPartOfStandardMenu}
        customizeItIngredients={customizeItIngredients}
      />
    );
  }
};

ProgressOverviewContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  onFetchMealsByMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  menu: state.menus.selectedMenu,
  packingFacilityId:
    state.session.selectedPackingFacilityId ||
    state.session.packing_facility_id,
  fetching: state.meals.fetching,
  meals: state.meals.meals,
  customizeItIngredients: state.meals.customizeItIngredients,
});

const mapDispatchToProps = {
  onMenuRequest: selectMenu,
  onMenuUpdated: updateMenus,
  onSetTopBarContent: setTopBarContent,
  onFetchMealsByMenu: fetchMealsByMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProgressOverviewContainer));
