import * as Sentry from '@sentry/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import {
  BrowserRouter as Router,
  Route as ReactRouterDOMRoute,
} from 'react-router-dom';

import { logError, configureLogger } from 'services/logger';
import Login from 'components/Login';
import { Loading } from 'components/shared';
import theme from 'lib/theme';
import { getSession, clearSession } from 'redux/session/actions';
import { ACTIVE_OPS_SESSION } from 'lib/constants';

import App from './App';
import styles from './styles';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

export class AppContainer extends Component {
  componentDidMount() {
    // if the user has an active session, get those session details from the backend
    // otherwise, sign the user out and they will be redirected to login screen to sign in again
    // NOTE: an active session will get cleared out when the user closes the browsers
    window.sessionStorage.getItem(ACTIVE_OPS_SESSION)
      ? this.props.onGetSession()
      : this.props.onClearSession();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.logged_in && this.props.session.logged_in) {
      window.sessionStorage.setItem(ACTIVE_OPS_SESSION, true);

      configureLogger({
        payload: {
          person: {
            id: this.props.session.user_id,
            username: this.props.session.user_name,
            packing_facility_id: this.props.session.packing_facility_id,
          },
          context: '[ops_apps]',
        },
      });
    }
  }

  componentDidCatch(error, info) {
    logError(error, info);
  }
  renderContent() {
    if (this.props.session.logged_in === false) {
      return (
        <Router>
          <Route exact path="/" component={Login} />
        </Router>
      );
    } else if (this.props.session.logged_in === true) {
      return <App classes={this.props.classes} />;
    }
    return (
      <div className={this.props.classes.centeredLoading}>
        <Loading />
      </div>
    );
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{this.renderContent()}</ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

AppContainer.propTypes = {
  onGetSession: PropTypes.func.isRequired,
  onClearSession: PropTypes.func.isRequired,
  session: PropTypes.shape({
    logged_in: PropTypes.bool,
    user_id: PropTypes.number,
    user_name: PropTypes.string,
    packing_facility_id: PropTypes.number,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = {
  onGetSession: getSession,
  onClearSession: clearSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AppContainer));
