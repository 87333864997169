import { HC_GREEN } from 'lib/constants';

export default {
  linearColorPrimary: {
    backgroundColor: '#ACD0AA',
  },

  linearBarColorPrimary: {
    backgroundColor: HC_GREEN,
  },

  circularColorPrimary: {
    color: HC_GREEN,
  },
  fullScreenCircular: {
    position: 'absolute',
    top: '35%',
    left: 'calc(50% - 50px)',
    height: '100px !important',
    width: '100px !important',
  },
  fullScreenLinear: {
    height: '10px',
  },
};
