import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { UploaderUserInterface } from 'components/shared';

import {
  headerError,
  requiredFieldErrors,
  requiredNumColumnsError,
  nonNumericErrors,
  checkAgainstListErrors,
  fileDataLengthError,
} from './csvValidationHelper';
import { reducer, initialState, ADD_ERROR, RESET_ERRORS } from './formUtils';

const REQUIRED_NUM_COLUMNS = 2;
const REQUIRED_HEADERS = ['Item ID', 'Item Type'];
const ITEM_TYPE_LIST = ['Ingredient', 'PackagingItem'];

const UploaderInterface = ({ postingErrors, onFileUpload }) => {
  const [validationState, dispatch] = useReducer(reducer, initialState);
  const [isValidating, setIsValidating] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const addError = (message) => {
    dispatch({ type: ADD_ERROR, message: message });
  };

  // eslint-disable-next-line no-unused-vars
  const validateAndSaveData = (fileData) => {
    dispatch({ type: RESET_ERRORS });
    setIsValidating(true);
    const errors = [];
    //Check if the total number of rows is less than the minimum
    const lessThanTwoError = fileDataLengthError(fileData, 1);

    if (lessThanTwoError) {
      errors.push(lessThanTwoError.message);
    }

    //Check if the total number of columns is equal the required number
    const notEqualsNumColumnsError = requiredNumColumnsError(
      fileData,
      REQUIRED_NUM_COLUMNS
    );

    if (notEqualsNumColumnsError) {
      errors.push(notEqualsNumColumnsError.message);
    }

    //Check if we're missing any required headers
    const headerMissingError = headerError(fileData, REQUIRED_HEADERS);

    if (headerMissingError) {
      errors.push(headerMissingError.message);
    }

    //Check if any field has blank values
    const fieldErrors = requiredFieldErrors(fileData, REQUIRED_HEADERS);

    if (!isEmpty(fieldErrors)) {
      errors.push(fieldErrors.map((err) => err.message));
    }

    //Check if Item ID has any non numeric characters
    const nanErrors = nonNumericErrors(fileData, REQUIRED_HEADERS[0]);

    if (!isEmpty(nanErrors)) {
      errors.push(nanErrors.map((err) => err.message));
    }

    //Check if Item Type has any values besides the allowed ones
    const isInListErrors = checkAgainstListErrors(
      fileData,
      REQUIRED_HEADERS[1],
      ITEM_TYPE_LIST
    );

    if (!isEmpty(isInListErrors)) {
      errors.push(isInListErrors.map((err) => err.message));
    }

    //Only display the first ten errors
    const firstTenErrors = errors.flat().slice(0, 10);
    firstTenErrors.forEach((err) => {
      addError(err);
    });

    if (errors.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onDrop = (fileData) => {
    //Clear the previous state if present
    onFileUpload();
    let success = validateAndSaveData(fileData);
    setIsValidating(false);

    if (success) {
      setIsPosting(true);
      onFileUpload(fileData);
      setIsPosting(false);
    }
  };

  const postingErrorList = () => {
    return postingErrors.length > 0 ? (
      <ul>
        {postingErrors.map((error) => {
          return Object.keys(error).map((key) => {
            return <li key={key}>{`${key}: ${error[key]}`}</li>;
          });
        })}
      </ul>
    ) : null;
  };

  return (
    <>
      <p>
        Include headers "Item ID" (netsuite id) and "Item Type" ("Ingredient" or
        "PackagingItem")
      </p>
      <UploaderUserInterface
        isValidating={isValidating}
        onDrop={onDrop}
        posting={isPosting}
        postingErrors={postingErrors}
        postingErrorList={postingErrorList}
        validationState={validationState}
        uploadName="Count List Creation - List Upload"
        layout={{
          vertical: true,
          fullscreen: false,
        }}
      />
    </>
  );
};

UploaderInterface.propTypes = {
  onFileUpload: PropTypes.func,
  postingErrors: PropTypes.array,
};

UploaderInterface.defaultProps = {
  onFileUpload: () => {},
  postingErrors: [],
};
export default UploaderInterface;
