import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

/**
 * Creates a new Realtime Dashboard icon for use on the ops home screen.
 * @class
 */
const RealtimeIcon = (props) => {
  const { className = 'RealtimeIcon', classes } = props;

  return (
    <div className={className}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 126.84 110.99"
      >
        <title>Real Time Dashboard</title>
        <path
          className={classes.cls1}
          d="M1230.5,1733.46H1103.66v-91H1230.5Zm-121.84-5H1225.5v-81H1108.66Z"
          transform="translate(-1103.66 -1642.46)"
        />
        <g className={classes.cls2}>
          <rect
            className={classes.cls1}
            x="49.15"
            y="13.31"
            width="28.7"
            height="22.66"
          />
        </g>
        <g className={classes.cls2}>
          <rect
            className={classes.cls1}
            x="12.7"
            y="13.25"
            width="28.7"
            height="22.66"
          />
        </g>
        <g className={classes.cls2}>
          <rect
            className={classes.cls1}
            x="49.15"
            y="48.22"
            width="28.7"
            height="22.66"
          />
        </g>
        <g className={classes.cls2}>
          <rect
            className={classes.cls1}
            x="12.7"
            y="48.22"
            width="28.7"
            height="22.66"
          />
        </g>
        <g className={classes.cls2}>
          <rect
            className={classes.cls1}
            x="85.6"
            y="13.25"
            width="28.7"
            height="22.66"
          />
        </g>
        <path
          className={classes.cls1}
          d="M1194.36,1693.29a2.55,2.55,0,1,1-2.55-2.55A2.55,2.55,0,0,1,1194.36,1693.29Zm23.61-1.4h-21v2.8h21Z"
          transform="translate(-1103.66 -1642.46)"
        />
        <path
          className={classes.cls1}
          d="M1194.36,1702a2.55,2.55,0,1,1-2.55-2.54A2.55,2.55,0,0,1,1194.36,1702Zm23.61-1.4h-21v2.8h21Z"
          transform="translate(-1103.66 -1642.46)"
        />
        <path
          className={classes.cls1}
          d="M1194.36,1710.74a2.55,2.55,0,1,1-2.55-2.55A2.55,2.55,0,0,1,1194.36,1710.74Zm23.61-1.4h-21v2.8h21Z"
          transform="translate(-1103.66 -1642.46)"
        />
      </svg>
    </div>
  );
};

RealtimeIcon.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

RealtimeIcon.defaultProps = {
  className: 'RealtimeIcon',
};

export default withStyles(styles)(RealtimeIcon);
