export default {
  timePicker: {
    '& .MuiDialogActions-root': {
      display: 'none !important',
    },
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'end',
    padding: '25px',
  },
};
