import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Breadcrumbs } from 'components/shared';

import { BreadcrumbContext } from '../BreadcrumbProvider';

const ProductionInventoryRouteLinks = ({
  classes,
  firstMenuId,
  'data-testid': testid,
}) => {
  const breadcrumbs = useContext(BreadcrumbContext);
  return (
    <div className={classes.linkContainer}>
      {breadcrumbs.length > 1 ? (
        <Breadcrumbs context={BreadcrumbContext} />
      ) : (
        <div data-testid={testid}>
          <NavLink
            to="/production_inventory/labeling"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Labeling
          </NavLink>
          <NavLink
            to="/production_inventory/portioning"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Portioning
          </NavLink>
          <NavLink
            to={`/production_inventory/meal_bagging/menu/${firstMenuId}`}
            className={classes.link}
            activeClassName={classes.activeLink}
            isActive={(_, location) =>
              location.pathname.includes(
                'production_inventory/meal_bagging/menu'
              )
            }
          >
            Meal Bagging
          </NavLink>
        </div>
      )}
    </div>
  );
};

ProductionInventoryRouteLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  firstMenuId: PropTypes.string.isRequired,
  'data-testid': PropTypes.string.isRequired,
};

export default ProductionInventoryRouteLinks;
