import { createSelector } from 'reselect';

const itemCategorySelector = (state, props) =>
  state.defectsByItemCategory.defectsByItemCategory.find(
    (category) => String(category.id) === String(props.categoryId)
  );

export const defectOptionsSelector = createSelector(
  itemCategorySelector,
  (categoryForPallet) => (categoryForPallet ? categoryForPallet.defects : [])
);
