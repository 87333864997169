const button = {
  width: '45%',
};

export default {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  reprintButton: {
    ...button,
    marginRight: '10px',
  },
  overrideButton: {
    ...button,
    marginLeft: '10px',
  },
};
