import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRouterDOMRoute, Switch } from 'react-router-dom';

import {
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
  TEXT,
  SNACK_BAR_DURATION,
} from 'lib/constants';
import { Snackbar } from 'components/shared';

import DropListUploader from './DropListUploader';
import Requests from './Requests';
import Queue from './Queue';
import BreadcrumbProvider from './BreadcrumbProvider';
import WarehouseRequestsRouteLinks from './WarehouseRequestsRouteLinks';

const Route = Sentry.withSentryRouting(ReactRouterDOMRoute);

const WarehouseRequests = ({
  session,
  onSetTopBarContent,
  onFetchMenus,
  onRequestPodLocations,
  menus,
  posting,
  failed,
}) => {
  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: TEXT,
      rightContent: ACCOUNT_DROPDOWN,
      text: 'Warehouse Requests',
    });
  }, [onSetTopBarContent]);

  let showExtendedProdMenus = true;

  useEffect(() => {
    onFetchMenus(showExtendedProdMenus);
  }, [onFetchMenus]);

  useEffect(() => {
    onRequestPodLocations({
      facilityId: session.packing_facility_id,
    });
  }, [onRequestPodLocations, session]);

  const firstMenuId = menus?.length > 0 ? String(menus[0].id) : '';

  return (
    <React.Fragment>
      <BreadcrumbProvider>
        <WarehouseRequestsRouteLinks firstMenuId={firstMenuId} />
        <Switch>
          <Route
            path={[
              '/warehouse_requests/requests/menu/:menuId',
              '/warehouse_requests/requests/menu',
            ]}
            render={(routingProps) => <Requests {...routingProps} />}
          />
          <Route path="/warehouse_requests/queue" render={() => <Queue />} />
          <Route
            path="/warehouse_requests/drop_list"
            render={() => <DropListUploader />}
          />
        </Switch>
      </BreadcrumbProvider>
      <Snackbar
        loading={posting}
        failed={failed}
        messaging={{
          loading: 'Saving Request...',
          failure: 'Error Submitting Request',
          success: 'Request Success!',
        }}
        autoHideDuration={SNACK_BAR_DURATION}
      />
    </React.Fragment>
  );
};

WarehouseRequests.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  onFetchMenus: PropTypes.func.isRequired,
  onRequestPodLocations: PropTypes.func.isRequired,
  session: PropTypes.shape({
    packing_facility_id: PropTypes.number.isRequired,
  }).isRequired,
  menus: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired }))
    .isRequired,
};

export default WarehouseRequests;
