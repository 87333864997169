import React, { useRef, Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import withStyles from '@mui/styles/withStyles';

import { CloseIcon } from 'components/icons';
import { TabOutlineButton } from 'components/shared';
import { usePrevious } from 'lib/custom_hooks';

import { LotCodesPhotoViewerTaker } from '../PhotoViewerTaker';
import styles from './styles';
import { useValidator } from '../FsqaReviewForms/formHelpers';

const initialInput = { id: 0, value: '' };

const LotCodeQuestions = ({
  palletId,
  classes,
  lotCodes,
  updateLotCodes,
  validationCallback,
  lotCodePhotosLength,
  savedLotCodes,
  groupLotCodes,
}) => {
  useValidator(
    lotCodes.length > 0 &&
      lotCodes.every((input) => input.value.length > 0) &&
      lotCodePhotosLength > 0,
    validationCallback
  );

  const nextInputId = useRef(lotCodes.length);
  const onAddClick = () => {
    updateLotCodes(concat(lotCodes, { id: nextInputId.current, value: '' }));
    nextInputId.current += 1;
  };
  const onDeleteClick = (idToDelete) => {
    updateLotCodes(filter(lotCodes, (input) => input.id !== idToDelete));
  };
  const onInputChange = (id, value) => {
    const inputIndex = findIndex(lotCodes, (input) => input.id === id);
    updateLotCodes([
      ...lotCodes.slice(0, inputIndex),
      { id, value },
      ...lotCodes.slice(inputIndex + 1),
    ]);
  };
  const newInput = useRef();
  const prevLotCodes = usePrevious(lotCodes);
  useEffect(() => {
    if (lotCodes && prevLotCodes && lotCodes.length > prevLotCodes.length) {
      newInput.current.focus();
    }
  }, [prevLotCodes, lotCodes]);

  const isSavedLotCode = useCallback(
    (lotCode) =>
      !!savedLotCodes.find((savedCode) => savedCode.id === lotCode.id),
    [savedLotCodes]
  );
  return (
    <Fragment>
      <span className={classes.infoLabel}>
        VENDOR LOT CODE / PACK DATE PHOTOS
      </span>
      <div className={classes.infoValue}>
        {lotCodes.map((lotCodeInput, index) => (
          <div key={lotCodeInput.id} className={classes.inputContainer}>
            {lotCodes.length > 1 && !isSavedLotCode(lotCodeInput) && (
              <TabOutlineButton
                onClick={() => onDeleteClick(lotCodeInput.id)}
                type="button"
                className={classes.closeButton}
              >
                <CloseIcon className={classes.closeIcon} />
              </TabOutlineButton>
            )}
            <Autocomplete
              className={classes.autoComplete}
              options={groupLotCodes}
              getOptionLabel={(option) => option.value || option}
              onChange={(event, newValue) =>
                onInputChange(lotCodeInput.id, newValue || '')
              }
              inputValue={lotCodeInput.value}
              onInputChange={(event, newValue) =>
                event && onInputChange(lotCodeInput.id, newValue)
              }
              freeSolo
              disabled={isSavedLotCode(lotCodeInput)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Lot Code"
                  value={lotCodeInput.value}
                  variant="outlined"
                  margin="normal"
                  className={classes.textField}
                  InputLabelProps={{ required: false }}
                  inputRef={index === lotCodes.length - 1 ? newInput : null}
                  disabled={isSavedLotCode(lotCodeInput)}
                />
              )}
            />
            {index === lotCodes.length - 1 && (
              <Button onClick={onAddClick} className={classes.addButton}>
                <AddIcon className={classes.addIcon} />
              </Button>
            )}
          </div>
        ))}
        <LotCodesPhotoViewerTaker palletId={palletId} />
      </div>
    </Fragment>
  );
};

const inputShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
});

LotCodeQuestions.propTypes = {
  palletId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  lotCodes: PropTypes.arrayOf(inputShape),
  savedLotCodes: PropTypes.arrayOf(inputShape),
  updateLotCodes: PropTypes.func.isRequired,
  validationCallback: PropTypes.func.isRequired,
  lotCodePhotosLength: PropTypes.number.isRequired,
  groupLotCodes: PropTypes.array,
};

LotCodeQuestions.defaultProps = {
  lotCodes: [initialInput],
  savedLotCodes: [],
  groupLotCodes: [],
};

export default withStyles(styles)(LotCodeQuestions);
