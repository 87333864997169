import { createSelector } from 'reselect';
import partition from 'lodash/partition';
import map from 'lodash/map';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import { produce } from 'immer';

const selectSummaryTrucks = (state) => state.trucks.summary.trucks;
const selectSummaryOrders = (state) =>
  state.netsuiteOrders.summary.netsuiteOrders;

const selectAuditOrders = (state) => state.netsuiteOrders.audit.netsuiteOrders;
const selectAuditTrucks = (state) => state.trucks.audit.trucks;

const combineTrucksAndOrders = (trucks, netsuiteOrders) => {
  const zippedOrders = produce(netsuiteOrders, (formattedOrders) => {
    formattedOrders.forEach((order) => {
      order.netsuiteItems.forEach((item) => {
        // pallets is in shape { [truckId]: pallets[] }
        item.trucks = map(item.pallets, (pallets, truckId) => {
          const truck = trucks.find((t) => String(t.id) === String(truckId));
          if (truck) {
            return { ...truck, pallets: pallets };
          }
          return null;
        }).filter((el) => el !== null);
      });
    });
  });

  const [orders, homelessNOs] = partition(
    zippedOrders,
    (order) => order.truckIds.length !== 0
  );

  return { netsuiteOrders: orders, homelessNOs };
};

export const selectPalletsByTrucksAndItem = (
  state,
  truckIds,
  netsuiteOrderItemId,
  isAudit
) => {
  const orders = isAudit
    ? selectAuditOrders(state)
    : selectSummaryOrders(state);

  const pallets = uniqBy(
    orders.flatMap((order) =>
      order.netsuiteItems.flatMap((item) => item.ungroupedPallets)
    ),
    'id'
  );

  return pallets.filter(
    (pallet) =>
      truckIds.includes(pallet.truckId) &&
      pallet.netsuiteItemId === netsuiteOrderItemId
  );
};

export const selectTrucksByNetsuiteOrderItemId = (
  state,
  netsuiteOrderItemId,
  isAudit
) => {
  const trucks = isAudit
    ? selectAuditTrucks(state)
    : selectSummaryTrucks(state);

  return trucks.filter((truck) =>
    truck.netsuiteOrderItemIds.includes(netsuiteOrderItemId)
  );
};

const ordersForTruck = (truck, netsuiteOrders) => {
  const truckId = truck.id;

  const ordersAssignedOnTruck = netsuiteOrders.filter((order) =>
    order.truckIds.includes(truckId)
  );

  const ordersWithAssignedPallets = ordersAssignedOnTruck.map((order) => {
    return {
      ...order,
      truckId: truckId,
      netsuiteItems: order.netsuiteItems.filter((item) => {
        return get(item.pallets, truckId, null);
      }),
    };
  });

  return ordersWithAssignedPallets.filter(
    (order) => order.netsuiteItems.length > 0
  );
};

const groupTrucksByOrder = (todayTrucks, netsuiteOrders) => {
  const ordersForTrucks = flatMap(todayTrucks, (truck) =>
    ordersForTruck(truck, netsuiteOrders)
  );

  return groupBy(ordersForTrucks, 'truckId');
};

export const selectSummaryOrdersWithTrucks = createSelector(
  selectSummaryTrucks,
  selectSummaryOrders,
  combineTrucksAndOrders
);

export const selectAuditOrdersWithTrucks = createSelector(
  selectAuditTrucks,
  selectAuditOrders,
  combineTrucksAndOrders
);

export const selectSummaryOrdersGroupedByTruck = createSelector(
  selectSummaryTrucks,
  selectSummaryOrders,
  groupTrucksByOrder
);
