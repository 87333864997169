import { infoLabel, infoValue } from '../FsqaReviewForms/styles';

export default {
  infoLabel,
  infoValue,
  tempInputsContainer: {
    display: 'flex !important',
    flex: 1,
    margin: '5px 0 20px 0',
  },
  tempInput: {
    margin: '0 5px',
    '&:first-of-type': {
      margin: '0 5px 0 0',
    },
    '&:last-of-type': {
      margin: '0 0 0 5px',
    },
  },
  inputElement: {
    // disable up/down buttons on number field
    '&::-webkit-inner-spin-button': {
      '::-webkit-appearance': 'none',
      '::-moz-appearance': 'none',
      appearance: 'none',
    },
  },
};
