export const rowStyles = {
  emphasizedText: {
    color: '#595959',
    fontWeight: 'bolder',
  },
  titleRoot: {
    overflow: 'hidden',
    padding: '14px 0 0 10px',
    textOverflow: 'ellipsis',
  },
};

export const sharedStyles = {
  errorMessage: {
    marginTop: '10px',
    marginLeft: '30px',
    fontWeight: 'bold',
  },
};
