import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { selectItemMoveRequest } from 'redux/itemMoveRequests/selectors';
import { getDepths, resetStorageSlots } from 'redux/storageSlot/actions';
import {
  updateItemMoveRequest,
  printChildLicensePlate,
} from 'redux/itemMoveRequests/actions';

import styles from '../styles';
import ViewInProgressRequest from './ViewInProgressRequest';

const mapStateToProps = (state, ownProps) => ({
  itemMoveRequest: selectItemMoveRequest(
    state,
    ownProps.match.params.requestId
  ),
  packingFacilityId: state.session.packing_facility_id,
  depthOptions: state.itemMoveRequests.depthOptions,
  depthsLoading: state.storageSlots.fetching,
  depthsFailed: state.storageSlots.failed,
  signedInUser: state.session.user_name,
  autoprintEnabled: state.session.experiments?.warehouse_moves_auto_print,
  itemMoveRequestUpdating: state.updating,
  itemMoveRequestUpdatingFailed: state.updateFailed,
  postingPrintChildLicensePlate: state.postingPrintChildLicensePlate,
  postingPrintChildLicensePlateFailed:
    state.postingPrintChildLicensePlateFailed,
});

const mapDispatchToProps = {
  onGetDepths: getDepths,
  onResetDepths: resetStorageSlots,
  onUpdate: updateItemMoveRequest,
  onPrintMoveLicensePlate: printChildLicensePlate,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ViewInProgressRequest)
);
