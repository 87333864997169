const BORDER_RADIUS = 4;

export default {
  labelRoot: {
    backgroundColor: '#4b4d4f',
    paddingTop: '4px',
    height: '100%',
    color: '#fff',
    textAlign: 'center',
    borderTopLeftRadius: BORDER_RADIUS,
  },
  empty: {
    backgroundColor: 'transparent',
  },
  expanded: {},
};
