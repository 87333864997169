import { connect } from 'react-redux';

import { printPallets } from 'redux/pallets/actions';

import PalletDetailsPrint from './PalletDetailsPrint';

const mapStateToProps = (state) => ({
  selectedPalletId: state.pallets.inventoryApp.selectedPallet.id,
  fetching: state.printers.fetching,
  fetchFailed: state.printers.failed,
  printerOptions: state.printers.printers,
});

const mapDispatchToProps = {
  onReprintPallet: printPallets,
};

export default connect(mapStateToProps, mapDispatchToProps)(PalletDetailsPrint);
