import React, { Fragment, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import withStyles from '@mui/styles/withStyles';

import { Loading, ReprintPalletDialog } from 'components/shared';

import styles from './styles';

const PalletDetailsPrint = ({
  classes,
  onReprintPallet,
  printerOptions,
  selectedPalletId,
  fetching,
  fetchFailed,
}) => {
  const [showPrinterOptions, setShowPrinterOptions] = useState(false);

  const printPallet = useCallback(
    (printer = null) => onReprintPallet([selectedPalletId], printer),
    [onReprintPallet, selectedPalletId]
  );

  const onPrintClick = useCallback(() => {
    if (printerOptions.length > 1) {
      setShowPrinterOptions(true);
    } else printPallet();
  }, [printerOptions, printPallet]);

  const buttonDisabled = useMemo(
    () => !selectedPalletId || fetchFailed || printerOptions.length < 1,
    [selectedPalletId, fetchFailed, printerOptions]
  );

  if (fetching) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  }
  return (
    <Fragment>
      <IconButton
        disabled={buttonDisabled}
        aria-label="Reprint"
        color="inherit"
        onClick={onPrintClick}
        classes={{ root: classes.printIcon }}
        size="large"
      >
        <PrintIcon />
      </IconButton>
      <ReprintPalletDialog
        showPrinterOptions={showPrinterOptions}
        selectedPalletId={selectedPalletId}
        setShowPrinterOptions={setShowPrinterOptions}
      />
    </Fragment>
  );
};

const printerShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

PalletDetailsPrint.propTypes = {
  classes: PropTypes.object.isRequired,
  onReprintPallet: PropTypes.func.isRequired,
  printerOptions: PropTypes.arrayOf(printerShape).isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchFailed: PropTypes.bool.isRequired,
  selectedPalletId: PropTypes.number,
};

PalletDetailsPrint.defaultProps = {
  selectedPalletId: null,
};

export default withStyles(styles)(PalletDetailsPrint);
