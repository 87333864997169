import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BACK_BUTTON, QA_APP } from 'lib/constants';
import { setTopBarContent } from 'redux/topBar/actions';
import { updatePreliminaryCheck } from 'redux/qa/mealBagChecks/actions';

import Confirmation from './Confirmation';

const ConfirmationContainer = ({
  onSetTopBarContent,
  onUpdatePreliminaryCheck,
  checks,
  updating,
  updateFailed,
  location,
  history,
}) => {
  const { meal, preliminaryCheckId } = location.state;

  const totalCheckCount = checks.length;
  const labelErrorCount = checks.flatMap((check) =>
    check.labelingIssues.filter(String)
  ).length;
  const packagingErrorCount = checks.flatMap((check) =>
    check.packagingIssues.filter(String)
  ).length;
  const ingredientErrorCount = checks.flatMap((check) =>
    check.ingredientErrors.filter(String)
  ).length;

  useEffect(() => {
    onSetTopBarContent({
      leftContent: BACK_BUTTON,
      middleContent: QA_APP,
      text: `${meal.letterAssignment}: ${meal.title}`,
    });
  }, [meal.letterAssignment, meal.title, onSetTopBarContent]);
  return (
    <Confirmation
      totalCheckCount={totalCheckCount}
      labelErrorCount={labelErrorCount}
      packagingErrorCount={packagingErrorCount}
      ingredientErrorCount={ingredientErrorCount}
      preliminaryCheckId={preliminaryCheckId}
      history={history}
      onUpdatePreliminaryCheck={onUpdatePreliminaryCheck}
      updating={updating}
      updateFailed={updateFailed}
    />
  );
};

ConfirmationContainer.propTypes = {
  onSetTopBarContent: PropTypes.func.isRequired,
  onUpdatePreliminaryCheck: PropTypes.func.isRequired,
  checks: PropTypes.array.isRequired,
  updating: PropTypes.bool.isRequired,
  updateFailed: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  checks: state.qaMealBagChecks.checks,
  updating: state.qaMealBagChecks.updating,
  updateFailed: state.qaMealBagChecks.updateFailed,
});

const mapDispatchToProps = {
  onSetTopBarContent: setTopBarContent,
  onUpdatePreliminaryCheck: updatePreliminaryCheck,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationContainer);
