import { makeApiRequest } from 'lib/api';
import { UPDATE_COUNT_LIST_ASSIGNMENT } from 'lib/constants';

import countListAssignment from './services';

export const updateCountListAssignment = (countListAssignmentId, cla) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: UPDATE_COUNT_LIST_ASSIGNMENT,
        method: countListAssignment.updateCountListAssignment,
        params: {
          countListAssignmentId,
          cla,
        },
      })
    );
  };
};
