import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const getPodStatuses = async ({ facilityId }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/packing_facilities/${facilityId || 1}/pod_statuses`,
    options
  );
  return await handleResponse(response);
};

const getPodStatus = async ({ facilityId, podSlug }) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/packing_facilities/${
      facilityId || 1
    }/pod_statuses/${podSlug}`,
    options
  );
  return await handleResponse(response);
};

export default {
  getPodStatuses,
  getPodStatus,
};
