import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { ArrowBackIcon } from 'components/icons';
import { GREY_400 } from 'lib/constants';

import TotalProductionCard from './TotalProductionCard';
import TotalQaCard from './TotalQaCard';
import QaErrorCard from './QaErrorCard';
import TeamMembersCard from './TeamMembersCard';
import HourlyProductionChart from './HourlyProductionChart';

const PodDetails = (props) => {
  const { classes, pod, fetchFailed } = props;

  const productionActivities = useMemo(() => {
    if (pod.activities !== undefined) {
      return pod.activities.filter(
        (activity) => activity.lastProductionSubmittedAt
      );
    }
  }, [pod.activities]);

  if (!fetchFailed && pod.description) {
    return (
      <div className={classes.root}>
        <Grid container padding={20}>
          <Grid item xs={9}>
            <div className={classes.topsection}>
              <div className={classes.link}>
                <Link to="/realtime_dashboard">
                  <ArrowBackIcon
                    fillColor={GREY_400}
                    width="50px"
                    height="50px"
                  />
                </Link>
                <span className={classes.title}>{pod.description}</span>
              </div>
              <TotalQaCard
                lastQaCheckSubmittedAt={pod.lastQaCheckSubmittedAt}
                totalQaCheckCount={pod.totalQaCheckCount}
              />
              <QaErrorCard
                lastQaCheckSubmittedAt={pod.lastQaCheckSubmittedAt}
                highErrorRate={pod.highErrorRate}
                errors={pod.errors}
              />
            </div>

            <HourlyProductionChart activities={productionActivities} />
          </Grid>
          <Grid item xs={3}>
            <TotalProductionCard
              lastProductionSubmittedAt={pod.lastProductionSubmittedAt}
              totalProductionCount={pod.totalProductionCount}
              activities={productionActivities}
            />
            <TeamMembersCard teamMembers={pod.members} />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};

PodDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  pod: PropTypes.object,
  fetchFailed: PropTypes.bool,
};

PodDetails.defaultProps = {
  pod: {},
  fetchFailed: false,
};

export default PodDetails;
