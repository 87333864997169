import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { setTopBarContent } from 'redux/topBar/actions';
import { resetQaPortionChecks } from 'redux/qa/portionChecks/actions';
import { fetchMealsByFacilityAndMenuId } from 'redux/qa/portionMeals/actions';
import {
  HOME_BUTTON,
  ACCOUNT_DROPDOWN,
  EXTENDED_MENU_DISPLAY,
} from 'lib/constants';
import { MealGrid } from 'components/shared';

import QaSnackBar from '../QaSnackBar';

export const MealGridContainer = ({
  qaPortionMeals,
  fetching,
  onSetTopBarContent,
  onResetQaPortionChecks,
  onFetchMealsByFacilityAndMenuId,
  location,
  menus,
  session,
  history,
}) => {
  const baseRoute = '/qa/portioning';
  useEffect(() => {
    onResetQaPortionChecks();
  }, [onResetQaPortionChecks]);
  //where MEAL_GRID was set, is now set to extended_menu_display which Topbar will read
  //and return the new extended menu drop down component
  useEffect(() => {
    onSetTopBarContent({
      leftContent: HOME_BUTTON,
      middleContent: EXTENDED_MENU_DISPLAY,
      rightContent: ACCOUNT_DROPDOWN,
    });
  }, [onSetTopBarContent]);

  useEffect(() => {
    if (!isEmpty(menus.selectedMenu)) {
      history.push(`${baseRoute}/menu/${menus.selectedMenu.id}`);
    }
  }, [history, menus.selectedMenu]);

  useEffect(() => {
    if (menus.results.length > 0 && !isEmpty(menus.selectedMenu)) {
      onFetchMealsByFacilityAndMenuId(
        session.packing_facility_id,
        menus.selectedMenu
      );
    }
  }, [
    menus,
    onFetchMealsByFacilityAndMenuId,
    session.packing_facility_id,
    session.selectedPackingFacilityId,
  ]);

  if (location.state && location.state['snackBarActivated']) {
    return (
      <Fragment>
        <MealGrid meals={qaPortionMeals} baseRoute={baseRoute} />
        <QaSnackBar
          snackBarActivated={location.state['snackBarActivated']}
          checkCount={location.state['checkCount']}
        />
      </Fragment>
    );
  } else {
    return (
      <MealGrid
        meals={qaPortionMeals}
        fetching={fetching}
        baseRoute={baseRoute}
      />
    );
  }
};

MealGridContainer.propTypes = {
  onResetQaPortionChecks: PropTypes.func.isRequired,
  qaPortionMeals: PropTypes.object.isRequired,
  onSetTopBarContent: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
};

MealGrid.defaultProps = {
  fetching: false,
};

const mapStateToProps = (state) => ({
  qaPortionMeals: state.qaPortionMeals,
  menus: state.menus,
  session: state.session,
  fetching: state.qaPortionMeals.fetching,
});

const mapDispatchToProps = {
  onResetQaPortionChecks: resetQaPortionChecks,
  onSetTopBarContent: setTopBarContent,
  onFetchMealsByFacilityAndMenuId: fetchMealsByFacilityAndMenuId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MealGridContainer);
