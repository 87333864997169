import {
  ITEM_PUTBACK_PARENT_PALLET_REQUEST_SUCCESS,
  ITEM_PUTBACK_PARENT_PALLET_REQUEST,
  ITEM_PUTBACK_PARENT_PALLET_REQUEST_ERROR,
  ITEM_PUTBACK_EXCEPTION_CREATE_REQUEST,
  ITEM_PUTBACK_EXCEPTION_CREATE_SUCCESS,
  ITEM_PUTBACK_EXCEPTION_CREATE_ERROR,
  ITEM_PUTBACK_CREATE_REQUEST,
  ITEM_PUTBACK_CREATE_SUCCESS,
  ITEM_PUTBACK_CREATE_ERROR,
  ITEM_PUTBACK_CANCEL_REQUEST,
  ITEM_PUTBACK_CANCEL_SUCCESS,
  ITEM_PUTBACK_CANCEL_ERROR,
  ITEM_PUTBACK_COMPLETE_REQUEST,
  ITEM_PUTBACK_COMPLETE_SUCCESS,
  ITEM_PUTBACK_COMPLETE_ERROR,
  ITEM_PUTBACKS_REQUEST,
  ITEM_PUTBACKS_SUCCESS,
  ITEM_PUTBACKS_ERROR,
  ITEM_PUTBACK_REQUEST,
  ITEM_PUTBACK_SUCCESS,
  ITEM_PUTBACK_ERROR,
  RESET_PARENT_PALLET,
  PALLETS_PRINT_REQUEST,
  PALLETS_PRINT_SUCCESS,
  PALLETS_PRINT_ERROR,
} from 'lib/constants';

const initialState = {
  updating: false,
  updatingFailed: false,
  itemPutback: {},
  suggestedPallet: {},
  itemPutbacks: [],
  parentPalletRequestStatus: {
    fetching: false,
    failed: false,
  },
  putbackCreateRequestStatus: {
    fetching: false,
    failed: false,
  },
  exceptionCreateRequestStatus: {
    fetching: false,
    failed: false,
  },
  putbackCancelRequestStatus: {
    fetching: false,
    failed: false,
  },
  putbackCompleteRequestStatus: {
    fetching: false,
    failed: false,
  },
  itemPutbacksRequestStatus: {
    fetching: false,
    failed: false,
  },
  itemPutbackRequestStatus: {
    fetching: false,
    failed: false,
  },
  palletsPrintRequestStatus: {
    fetching: false,
    failed: false,
  },
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case ITEM_PUTBACK_REQUEST:
      return {
        ...state,
        itemPutbackRequestStatus: {
          fetching: true,
          failed: false,
        },
        itemPutback: {},
      };
    case ITEM_PUTBACK_SUCCESS:
      return {
        ...state,
        itemPutbackRequestStatus: {
          fetching: false,
          failed: false,
        },
        itemPutback: action.response.itemPutback,
        suggestedPallet: action.response.suggestedPallet,
        parentPallet: action.response.itemPutback.parentPalletInfo,
      };
    case ITEM_PUTBACK_ERROR:
      return {
        ...state,
        itemPutbackRequestStatus: {
          fetching: false,
          failed: true,
        },
        itemPutback: {},
      };
    case ITEM_PUTBACKS_REQUEST:
      return {
        ...state,
        itemPutbacksRequestStatus: {
          fetching: true,
          failed: false,
        },
      };
    case ITEM_PUTBACKS_SUCCESS:
      return {
        ...state,
        itemPutbacksRequestStatus: {
          fetching: false,
          failed: false,
        },
        itemPutback: {},
        suggestedPallet: {},
        itemPutbacks: action.response.itemPutbacks,
      };
    case ITEM_PUTBACKS_ERROR:
      return {
        ...state,
        itemPutbacksRequestStatus: {
          fetching: false,
          failed: true,
        },
      };
    case ITEM_PUTBACK_PARENT_PALLET_REQUEST:
      return {
        ...state,
        parentPalletRequestStatus: {
          fetching: true,
          failed: false,
        },
        itemPutback: {},
        parentPallet: {},
      };
    case ITEM_PUTBACK_PARENT_PALLET_REQUEST_SUCCESS:
      return {
        ...state,
        parentPalletRequestStatus: {
          fetching: false,
          failed: false,
        },
        parentPallet: action.response.parentPallet,
        suggestedPallet: action.response.parentPallet,
      };
    case ITEM_PUTBACK_PARENT_PALLET_REQUEST_ERROR:
      return {
        ...state,
        parentPalletRequestStatus: {
          fetching: false,
          failed: true,
        },
        parentPallet: {},
        suggestedPallet: {},
      };
    case RESET_PARENT_PALLET:
      return {
        ...state,
        parentPalletRequestStatus: {
          fetching: false,
          failed: false,
        },
        parentPallet: {},
      };
    case ITEM_PUTBACK_CREATE_REQUEST:
      return {
        ...state,
        putbackCreateRequestStatus: {
          fetching: true,
          failed: false,
        },
        itemPutback: {},
      };
    case ITEM_PUTBACK_CREATE_SUCCESS:
      return {
        ...state,
        putbackCreateRequestStatus: {
          fetching: false,
          failed: false,
        },
        itemPutback: action.response.itemPutback,
      };
    case ITEM_PUTBACK_CREATE_ERROR:
      return {
        ...state,
        putbackCreateRequestStatus: {
          fetching: false,
          failed: true,
        },
      };
    case ITEM_PUTBACK_EXCEPTION_CREATE_REQUEST:
      return {
        ...state,
        exceptionCreateRequestStatus: {
          fetching: true,
          failed: false,
        },
      };
    case ITEM_PUTBACK_EXCEPTION_CREATE_SUCCESS:
      return {
        ...state,
        exceptionCreateRequestStatus: {
          fetching: false,
          failed: false,
        },
        suggestedPallet: action.response.suggestedPallet,
      };
    case ITEM_PUTBACK_EXCEPTION_CREATE_ERROR:
      return {
        ...state,
        exceptionCreateRequestStatus: {
          fetching: false,
          failed: true,
        },
      };
    case ITEM_PUTBACK_COMPLETE_REQUEST:
      return {
        ...state,
        updating: true,
        putbackCompleteRequestStatus: {
          fetching: true,
          failed: false,
        },
      };
    case ITEM_PUTBACK_COMPLETE_SUCCESS:
      return {
        ...state,
        updating: false,
        putbackCompleteRequestStatus: {
          fetching: false,
          failed: false,
        },
        itemPutback: {},
        suggestedPallet: {},
      };
    case ITEM_PUTBACK_COMPLETE_ERROR:
      return {
        ...state,
        updating: false,
        updatingFailed: true,
        putbackCompleteRequestStatus: {
          fetching: false,
          failed: true,
        },
      };
    case ITEM_PUTBACK_CANCEL_REQUEST:
      return {
        ...state,
        putbackCancelRequestStatus: {
          fetching: true,
          failed: false,
        },
      };
    case ITEM_PUTBACK_CANCEL_SUCCESS:
      return {
        ...state,
        putbackCancelRequestStatus: {
          fetching: false,
          failed: false,
        },
        itemPutback: action.response.itemPutback,
      };
    case ITEM_PUTBACK_CANCEL_ERROR:
      return {
        ...state,
        putbackCancelRequestStatus: {
          fetching: false,
          failed: true,
        },
      };
    case PALLETS_PRINT_REQUEST:
      return {
        ...state,
        palletsPrintRequestStatus: {
          fetching: true,
          failed: false,
        },
      };
    case PALLETS_PRINT_ERROR:
      return {
        ...state,
        palletsPrintRequestStatus: {
          fetching: false,
          failed: true,
        },
      };
    case PALLETS_PRINT_SUCCESS:
      return {
        ...state,
        palletsPrintRequestStatus: {
          fetching: false,
          failed: false,
        },
        itemPutback: action.response.itemPutback,
        suggestedPallet: action.response.suggestedPallet,
      };
    default:
      return state;
  }
}
