import { linkTabStyling } from 'lib/styles';

export default {
  ...linkTabStyling,
  imageList: {
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill,minmax(100px,1fr)) !important',
    gridAutoColumns: 'minmax(100px, 1fr)',
  },
};
