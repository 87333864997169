import { makeApiRequest } from 'lib/api';
import { SUMMARY_AUDIT_ORDERS_TRUCKS_GET } from 'lib/constants';

import NetsuiteOrders from './services';

export const getNetsuiteOrders = () => {
  return async (dispatch, getState) => {
    await dispatch(
      makeApiRequest({
        type: SUMMARY_AUDIT_ORDERS_TRUCKS_GET,
        method: NetsuiteOrders.getNetsuiteOrders,
        params: {
          packingFacilityId: getState().session.packing_facility_id,
        },
      })
    );
  };
};
