import { WHITE, GREY_200, HC_GREEN } from 'lib/constants';

import { loadingContainer, auditContainer, noTrucks } from '../styles';

export const truckLine = {
  padding: '15px 1.5% 0px 1.5%',
  marginBottom: '5px',
  background: WHITE,
  minHeight: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:last-child': { borderRadius: '0px 0px 10px 10px' },
  '&:first-child': { borderRadius: '10px 10px 0px 0px' },
  boxShadow:
    '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
};

export const truckText = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
};
export const truckId = {
  fontWeight: 'bold',
  whiteSpace: 'nowrap ',
};
export const separator = {
  margin: '0px 1%',
  color: GREY_200,
};

export default {
  loadingContainer,
  auditContainer,
  truckLine,
  header: {
    padding: '10px 1.5%',
    fontSize: '18px',
  },
  trucksHeader: {
    padding: '0px 1.5% 10px 1.5%',
  },
  greenLink: {
    color: HC_GREEN,
  },
  truckText,
  truckId,
  separator,
  noTrucks,
  noTrucksMessage: {
    ...truckLine,
    borderRadius: '10px 10px 0px 0px',
    marginTop: '10px',
  },
  noTrucksContainer: {
    ...truckLine,
    borderRadius: '10px',
    justifyContent: 'center',
  },
};
