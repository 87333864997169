import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import classNames from 'classnames';

import {
  CANCELLED,
  COMPLETED,
  PARTIALLY_COMPLETED,
  IN_PROGRESS,
  TO_DO,
  ACKNOWLEDGED,
} from 'lib/constants';

const StatusChip = ({ status, runner, canceller, classes }) => {
  let statusText = 'Request Made';
  const isCompleted = status === COMPLETED || status === PARTIALLY_COMPLETED;

  if (isCompleted) {
    statusText = status;
  } else if (status === IN_PROGRESS) {
    statusText =
      runner !== null && runner.length > 0
        ? `${runner} Picked Up`
        : 'Picked Up';
  } else if (status === CANCELLED) {
    statusText = `${canceller} Cancelled`;
  } else if (status === ACKNOWLEDGED) {
    statusText = `Acknowledged By ${runner}`;
  }

  return (
    <Chip
      data-testid="chip"
      label={statusText}
      classes={{
        label: classes.label,
        root: classNames(classes.chip, {
          [classes.completed]: isCompleted,
          [classes.inProgress]: status === IN_PROGRESS,
          [classes.toDo]: status === TO_DO,
          [classes.acknowledged]: status === ACKNOWLEDGED,
        }),
      }}
    />
  );
};

StatusChip.propTypes = {
  classes: PropTypes.object.isRequired,
  canceller: PropTypes.string,
  runner: PropTypes.string,
  status: PropTypes.string.isRequired,
};

StatusChip.defaultProps = {
  canceller: null,
  runner: null,
};

export default StatusChip;
