import { infoLabel, infoValue } from '../FsqaReviewForms/styles';

export default {
  infoLabel,
  infoValue,
  weightInputsContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  weightInput: {
    width: '30%',
    margin: '5px',
  },
  inputElement: {
    // disable up/down buttons on number field
    '&::-webkit-inner-spin-button': {
      '::-webkit-appearance': 'none',
      '::-moz-appearance': 'none',
      appearance: 'none',
    },
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
  },
  radioButton: {
    fontSize: '14px',
  },
};
