import { GREY_800, GREY_400 } from 'lib/constants';

export default {
  text: {
    color: GREY_800,
  },
  forwardArrow: {
    verticalAlign: 'sub',
    color: GREY_400,
    fontSize: '20px',
  },
};
