import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import { formatTime, dateTimeInTimeZone } from 'lib/utils';

import { appointmentsWithRows } from './appointmentSorter';
import TimeIncrements from './TimeIncrements';
import Appointment from './Appointment';

const AppointmentViewer = ({
  appointments,
  onClickAppointment,
  onSetSelectedAppt,
  timeZone,
  classes,
}) => {
  const currentTime = dateTimeInTimeZone(timeZone);

  useEffect(() => {
    const nearestHalfHour = currentTime.getMinutes() < 15 ? '00' : '30';
    const scrollToTime = formatTime(
      currentTime.getHours() - 1 + ':' + nearestHalfHour
    );

    const column = document.getElementById(scrollToTime);
    if (column) {
      column.scrollIntoView({
        inline: 'start',
        behavior: 'auto',
      });
    }
  });

  const sortedApptsWithRows = useMemo(() => {
    return appointmentsWithRows(appointments);
  }, [appointments]);

  return (
    <div className={classes.root}>
      <TimeIncrements timeZone={timeZone} classes={classes} />

      {map(sortedApptsWithRows, (appointment) => (
        <Appointment
          currentTime={currentTime}
          key={appointment.id}
          apptId={appointment.id}
          startTime={appointment.startTime}
          duration={appointment.duration}
          timeZone={timeZone}
          row={appointment.row}
          poNumbers={appointment.poNumbers}
          vendorNames={appointment.vendorNames}
          memo={appointment.memo}
          status={appointment.status}
          classes={classes}
          onClickAppointment={onClickAppointment}
          onSetSelectedAppt={onSetSelectedAppt}
        />
      ))}
    </div>
  );
};

export default AppointmentViewer;

AppointmentViewer.propTypes = {
  appointments: PropTypes.array.isRequired,
  timeZone: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClickAppointment: PropTypes.func.isRequired,
  onSetSelectedAppt: PropTypes.func.isRequired,
};
