import React from 'react';
import PropTypes from 'prop-types';

const CycleCountingIcon = (props) => {
  const { className = 'CycleCountingIcon' } = props;
  return (
    <div className={className}>
      <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="80" height="80" fill="white" />
        <rect
          x="5.65"
          y="12.65"
          width="44.7"
          height="57.7"
          rx="2.35"
          fill="white"
          stroke="#008600"
          strokeWidth="3.3"
          strokeLinejoin="round"
        />
        <mask id="path-2-inside-1_608_23366" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3529 7.45097C23.8919 5.4664 25.7928 4 28.0551 4C30.3175 4 32.2184 5.4664 32.7574 7.45097H40C42.2091 7.45097 44 9.24183 44 11.451V16C44 18.2091 42.2091 20 40 20H16C13.7909 20 12 18.2091 12 16V11.451C12 9.24183 13.7909 7.45097 16 7.45097H23.3529Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3529 7.45097C23.8919 5.4664 25.7928 4 28.0551 4C30.3175 4 32.2184 5.4664 32.7574 7.45097H40C42.2091 7.45097 44 9.24183 44 11.451V16C44 18.2091 42.2091 20 40 20H16C13.7909 20 12 18.2091 12 16V11.451C12 9.24183 13.7909 7.45097 16 7.45097H23.3529Z"
          fill="white"
        />
        <path
          d="M23.3529 7.45097V10.751C24.8424 10.751 26.1472 9.75325 26.5376 8.31587L23.3529 7.45097ZM32.7574 7.45097L29.5727 8.31587C29.9631 9.75325 31.2679 10.751 32.7574 10.751V7.45097ZM26.5376 8.31587C26.6679 7.836 27.2113 7.3 28.0551 7.3V0.7C24.3743 0.7 21.1159 3.0968 20.1683 6.58607L26.5376 8.31587ZM28.0551 7.3C28.8989 7.3 29.4424 7.836 29.5727 8.31587L35.942 6.58607C34.9944 3.0968 31.736 0.7 28.0551 0.7V7.3ZM32.7574 10.751H40V4.15097H32.7574V10.751ZM40 10.751C40.3866 10.751 40.7 11.0644 40.7 11.451H47.3C47.3 7.41929 44.0317 4.15097 40 4.15097V10.751ZM40.7 11.451V16H47.3V11.451H40.7ZM40.7 16C40.7 16.3866 40.3866 16.7 40 16.7V23.3C44.0317 23.3 47.3 20.0317 47.3 16H40.7ZM40 16.7H16V23.3H40V16.7ZM16 16.7C15.6134 16.7 15.3 16.3866 15.3 16H8.7C8.7 20.0317 11.9683 23.3 16 23.3V16.7ZM15.3 16V11.451H8.7V16H15.3ZM15.3 11.451C15.3 11.0644 15.6134 10.751 16 10.751V4.15097C11.9683 4.15097 8.7 7.41928 8.7 11.451H15.3ZM16 10.751H23.3529V4.15097H16V10.751Z"
          fill="#008600"
          mask="url(#path-2-inside-1_608_23366)"
        />
        <path
          d="M12 27L14.5 29.5L20 24"
          stroke="#99CE99"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="25" y="24" width="19" height="6" rx="3" fill="#99CE99" />
        <path
          d="M12 41L14.5 43.5L20 38"
          stroke="#99CE99"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="25" y="38" width="19" height="6" rx="3" fill="#99CE99" />
        <path
          d="M12 55L14.5 57.5L20 52"
          stroke="#99CE99"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="25" y="52" width="19" height="6" rx="3" fill="#99CE99" />
        <rect
          x="32.65"
          y="53.65"
          width="20.7"
          height="20.7"
          rx="2.35"
          fill="white"
          stroke="#008600"
          strokeWidth="3.3"
          strokeLinejoin="round"
        />
        <rect
          x="53.65"
          y="53.65"
          width="20.7"
          height="20.7"
          rx="2.35"
          fill="white"
          stroke="#008600"
          strokeWidth="3.3"
          strokeLinejoin="round"
        />
        <rect
          x="43.65"
          y="32.65"
          width="20.7"
          height="20.7"
          rx="2.35"
          fill="white"
          stroke="#008600"
          strokeWidth="3.3"
          strokeLinejoin="round"
        />
        <mask id="path-13-inside-2_608_23366" fill="white">
          <rect x="60" y="53" width="8" height="10" rx="2" />
        </mask>
        <rect
          x="60"
          y="53"
          width="8"
          height="10"
          rx="2"
          fill="#99CE99"
          stroke="#008600"
          strokeWidth="6.6"
          strokeLinejoin="round"
          mask="url(#path-13-inside-2_608_23366)"
        />
        <mask id="path-14-inside-3_608_23366" fill="white">
          <rect x="50" y="31" width="8" height="10" rx="2" />
        </mask>
        <rect
          x="50"
          y="31"
          width="8"
          height="10"
          rx="2"
          fill="#99CE99"
          stroke="#008600"
          strokeWidth="6.6"
          strokeLinejoin="round"
          mask="url(#path-14-inside-3_608_23366)"
        />
        <mask id="path-15-inside-4_608_23366" fill="white">
          <rect x="39" y="52" width="8" height="10" rx="2" />
        </mask>
        <rect
          x="39"
          y="52"
          width="8"
          height="10"
          rx="2"
          fill="#99CE99"
          stroke="#008600"
          strokeWidth="6.6"
          strokeLinejoin="round"
          mask="url(#path-15-inside-4_608_23366)"
        />
      </svg>
    </div>
  );
};

CycleCountingIcon.propTypes = {
  className: PropTypes.string,
};

CycleCountingIcon.defaultProps = {
  className: 'CycleCountingIcon',
};

export default CycleCountingIcon;
