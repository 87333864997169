import {
  BLACK,
  WHITE,
  GREY_200,
  GREY_650,
  DARK_HC_GREEN,
  HC_GREEN,
} from 'lib/constants';
import { centeredLoading } from 'lib/styles';

export default {
  root: {},
  ...centeredLoading,
  mealDataCard: {
    margin: '10px 0',
    position: 'relative',
  },
  media: {
    width: '375px',
    height: '250px',
    margin: '20px auto',
  },
  metaDataTableHeader: {
    color: WHITE,
    background: GREY_650,
    fontWeight: 'bolder',
    textTransform: 'uppercase',
  },
  table: {
    marginTop: '40px',
  },
  menuIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
  textField: {
    flex: '1 0 47%',
    borderRadius: '5px',
    marginBottom: '25px',
    fontSize: '16px',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: 0,
    flex: '1 1 5%', // Ensures 2 max items per row. (flexGrow flexShrink flexBasis)
    margin: '5px !important',
    display: 'inline-block',
    fontSize: '16px',
    color: BLACK,
    '&:focus': {
      backgroundColor: 'none',
    },
    '&.Mui-selected': {
      backgroundColor: HC_GREEN,
      color: WHITE,
      '&:focus': {
        backgroundColor: HC_GREEN,
      },
      '&:hover': {
        backgroundColor: DARK_HC_GREEN,
      },
    },
  },
  submitButton: {
    color: WHITE,
    margin: 'auto',
    width: '100%',
    height: '50px',
    backgroundColor: HC_GREEN,
    flex: 1,
    '&:disabled': {
      color: BLACK,
      backgroundColor: GREY_200,
    },
    '&:hover': {
      backgroundColor: DARK_HC_GREEN,
    },
  },
  hidden: {
    display: 'none',
  },
};
