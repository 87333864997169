import {
  INGREDIENT_INVENTORY_POST_REQUEST,
  INGREDIENT_INVENTORY_POST_SUCCESS,
  INGREDIENT_INVENTORY_POST_ERROR,
  INGREDIENT_INVENTORY_PRINT_REQUEST,
  INGREDIENT_INVENTORY_PRINT_SUCCESS,
  INGREDIENT_INVENTORY_PRINT_ERROR,
} from 'lib/constants';

const initialState = {
  results: undefined,
  posting: false,
  errors: null,
  printing: false,
  printError: undefined,
};

export default function (state = initialState, action = { type: null }) {
  switch (action.type) {
    case INGREDIENT_INVENTORY_POST_REQUEST:
      return {
        ...state,
        posting: true,
      };
    case INGREDIENT_INVENTORY_POST_SUCCESS:
      return {
        ...state,
        results: action.response,
        errors: null,
        posting: false,
      };
    case INGREDIENT_INVENTORY_POST_ERROR:
      return {
        ...state,
        errors: action.response,
        posting: false,
      };
    case INGREDIENT_INVENTORY_PRINT_REQUEST:
      return {
        ...state,
        printing: true,
      };
    case INGREDIENT_INVENTORY_PRINT_SUCCESS:
      return {
        ...state,
        printing: false,
      };
    case INGREDIENT_INVENTORY_PRINT_ERROR:
      return {
        ...state,
        printing: false,
        printError: action.response,
      };
    default:
      return state;
  }
}
