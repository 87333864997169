import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const MenuButton = ({
  onMenuSelected,
  id,
  weekOfDate,
  classes,
  selectedMenu,
  location,
}) => {
  const handleClick = () => {
    return onMenuSelected(id);
  };

  const buttonText = () => {
    return DateTime.fromISO(weekOfDate).toFormat('M/dd');
  };

  return (
    <NavLink
      to={location}
      className={classes.button}
      activeClassName={classes.active}
      isActive={() => id == selectedMenu.id}
      onClick={handleClick}
    >
      {buttonText()}
    </NavLink>
  );
};

MenuButton.propTypes = {
  weekOfDate: PropTypes.string.isRequired,
  onMenuSelected: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedMenu: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MenuButton));
