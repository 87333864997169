import React, { Fragment } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const QtyTagsQuestion = ({
  sequenceStart,
  sequenceEnd,
  qtyTags,
  setQtyTags,
  visible,
  classes,
}) => {
  const range = Array.from(
    { length: sequenceEnd - sequenceStart + 1 },
    (_, i) => sequenceStart + i
  );
  if (visible) {
    return (
      <Fragment>
        <h3 className={classes.question}>
          How many tags would you like to print?
        </h3>
        <ToggleButtonGroup
          data-testid="qty-tags-question"
          exclusive
          className={classes.buttonGroup}
          size="large"
          value={qtyTags}
          onChange={(_, newQtyTags) => setQtyTags(newQtyTags)}
        >
          {range.map((num) => {
            return (
              <ToggleButton
                className={classes.button}
                data-testid={`qty-tags-${num}`}
                key={num}
                value={num}
              >
                {num}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(QtyTagsQuestion);

QtyTagsQuestion.propTypes = {
  sequenceStart: PropTypes.number.isRequired,
  sequenceEnd: PropTypes.number.isRequired,
  qtyTags: PropTypes.number.isRequired,
  setQtyTags: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

QtyTagsQuestion.defaultProps = {
  visible: true,
};
