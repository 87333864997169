import {
  GREY_700,
  CLICKABLE_BLUE,
  DARK_RED,
  ORANGE_YELLOW,
  GREY_100,
  GREY_200,
} from 'lib/constants';

import {
  expander,
  grid,
  cell,
  spanOne,
  spanTwo,
  approvedContainer,
  approvedChip,
  approvedIcon,
} from '../styles';

export default {
  buttonsSummary: {
    ...cell,
    gridColumn: 'buttons-start / buttons-end',
    textAlign: 'right',
    justifyContent: 'space-between',
  },
  buttonsAudit: {
    ...cell,
    gridColumn: 'buttons-start / buttons-end',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  approvedContainer,
  approvedChip,
  approvedIcon,
  noPallets: {
    paddingLeft: '1.5%',
    paddingBottom: '20px',
  },
  blueText: {
    color: CLICKABLE_BLUE,
  },
  warningIcon: {
    color: GREY_700,
  },
  itemExpander: {
    ...expander,
  },
  itemSummary: grid,
  itemSummaryRoot: {
    padding: '0px 1.5%',
  },
  warningButton: {
    minWidth: '36px',
    height: '36px',
    padding: 0,
    backgroundColor: GREY_200,
  },
  spanOne,
  spanTwo,
  approveButton: {
    lineHeight: '1',
    height: '36px',
    fontSize: '13px',
  },
  higherFinal: {
    color: DARK_RED,
  },
  flagged: {
    color: DARK_RED,
  },
  yellow: {
    color: ORANGE_YELLOW,
  },
  grey: {
    background: GREY_100,
  },
  auditButton: {
    width: '118px',
  },
  rounded: {
    '&:last-child': {
      borderRadius: '0px 0px 10px 10px',
    },
  },
};
