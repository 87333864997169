import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import netsuiteOrderItemsModels from 'redux/netsuiteOrderItem/services';
import { makeApiRequest } from 'lib/api';
import { resetPallets as resetPalletsAction } from 'redux/pallets/actions';
import { Loading } from 'components/shared';
import { NETSUITE_ORDER_ITEMS, SB_FACILITY_ID } from 'lib/constants';

import { columns } from './dataTableConfig';
import WarehouseReceiving from './WarehouseReceiving';
import styles from './styles';

export const WarehouseReceivingContainer = ({
  onMakeApiRequest,
  fetching,
  netsuiteOrderItems,
  packingFacilityId,
  classes,
  printing,
  printFailed,
  posting,
  postFailed,
  resetPallets,
  trucks,
  lastTruck,
}) => {
  useEffect(() => {
    onMakeApiRequest({
      type: NETSUITE_ORDER_ITEMS,
      method: netsuiteOrderItemsModels.byOpenNetsuiteOrders,
      params: { facilityId: packingFacilityId },
    });
  }, [packingFacilityId, onMakeApiRequest]);

  const hasOffsiteFacility = packingFacilityId !== SB_FACILITY_ID;
  const uniqueNetsuiteOrderTruckIds = useMemo(() => {
    const netsuiteOrderTrucks = flatten(
      netsuiteOrderItems.map((item) => item.trucks)
    );
    return uniq(netsuiteOrderTrucks.map((truck) => truck.id));
  }, [netsuiteOrderItems]);

  if (fetching) {
    return (
      <div className={classes.loadingContainer}>
        <Loading />
      </div>
    );
  } else {
    return (
      <WarehouseReceiving
        data={netsuiteOrderItems}
        printing={printing}
        printFailed={printFailed}
        posting={posting}
        postFailed={postFailed}
        resetPallets={resetPallets}
        trucks={trucks}
        columns={columns(
          lastTruck,
          uniqueNetsuiteOrderTruckIds,
          hasOffsiteFacility
        )}
      />
    );
  }
};

WarehouseReceivingContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  netsuiteOrderItems: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onMakeApiRequest: PropTypes.func.isRequired,
  packingFacilityId: PropTypes.number.isRequired,
  resetPallets: PropTypes.func.isRequired,
  posting: PropTypes.bool.isRequired,
  postFailed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  netsuiteOrderItems: state.netsuiteOrderItems.results,
  fetching: state.netsuiteOrderItems.fetching,
  packingFacilityId: state.session.packing_facility_id,
  printing: state.printers.palletsPrinting,
  printFailed: state.printers.palletsPrintFailed,
  trucks: state.trucks.receiving.trucks,
  lastTruck: state.trucks.receiving.lastTruck,
  posting: state.pallets.receivingApp.posting,
  postFailed: state.pallets.receivingApp.postFailed,
});

const mapDispatchToProps = {
  onMakeApiRequest: makeApiRequest,
  resetPallets: resetPalletsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WarehouseReceivingContainer));
