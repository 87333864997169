import { ROBOTO } from 'lib/constants';

export default {
  container: { padding: '12px' },
  text: {
    fontWeight: '500',
    paddingBottom: '12px',
    fontFamily: ROBOTO,
  },
};
