import { alpha } from '@mui/material/styles';

import { HC_GREEN, GREY_400 } from 'lib/constants';

export default {
  button: {
    color: HC_GREEN,
    height: '60px',
    fontWeight: 600,
    width: '100%',
    marginBottom: '10px',
    border: `solid 0.5px ${GREY_400}`,
    '&.selected': {
      border: `solid 1px ${HC_GREEN}`,
      backgroundColor: alpha(HC_GREEN, 0.1),
    },
  },
};
