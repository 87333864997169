import { BORDER_GREY, HC_GREEN } from 'lib/constants';

import { cell, cellLast, header, zebraRow, clickableRows } from '../styles';

export default {
  cell,
  cellLast,
  container: {
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  header,
  mealLetter: {
    ...cell,
    color: HC_GREEN,
    fontWeight: '500',
  },
  zebraRow,
  clickableRows,
};
