import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isEqual } from 'date-fns';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { smallerDatePickerForHoneywell } from 'lib/dataTableTheme';
import { useScanner } from 'lib/custom_hooks';
import {
  getHeightColor,
  useLocationDepths,
} from 'components/WarehouseInventory/helpers'; // May want to move into this folder.
import {
  WAREHOUSE_SHELF_LIFE_TYPES,
  ACTIVE,
  SLOT_REGEX,
  ON_HOLD,
  DEACTIVATED,
} from 'lib/constants';
import { Kraken } from 'components/icons';
import { Loading, ActionCard } from 'components/shared';

import LocationForm from '../LocationForm';
import QuantityModals from './QuantityModals';
import PalletDetailsHoldButton from './PalletDetailsHoldButton';
import PalletDetailsDeleteButton from './PalletDetailsDeleteButton';
import PalletDetailsButtons from './PalletDetailsButtons';
import ReleaseButton from './ReleaseButton';
import ReactivateButton from './ReactivateButton';
import DepthSelection from '../DepthSelection';

const ViewSearchPallet = ({
  classes,
  pallet,
  palletId,
  onFetchPallet,
  onResetSelectedPallet,
  onPalletUpdate,
  palletSearchDrawerOpen,
  fetchFailed,
  updating,
  updatingFailed,
  onResetDepths,
  onGetDepths,
  packingFacilityId,
  itemMoveRequest = null,
  depthsFailed,
  depthsLoading,
  depthOptions,
  history,
}) => {
  const [pDateType, setPDateType] = useState(null);
  const [pDate, setPDate] = useState(null);
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [fieldsDirty, setFieldsDirty] = useState(false);
  const [locationNumber, setLocationNumber] = useState('');
  const [selectedDepth, setSelectedDepth] = useState({});

  useEffect(() => {
    if (pallet.productDate) {
      setPDate(pallet.productDate);
    }
  }, [pallet.productDate]);

  useEffect(() => {
    if (pallet.productDateType) {
      setPDateType(pallet.productDateType);
    }
  }, [pallet.productDateType]);

  useEffect(() => {
    if (
      pDateType !== pallet.productDateType ||
      !isEqual(new Date(pDate), new Date(pallet.productDate))
    ) {
      setFieldsDirty(true);
    } else {
      setFieldsDirty(false);
    }
  }, [
    setFieldsDirty,
    pallet.productDateType,
    pallet.productDate,
    pDateType,
    pDate,
  ]);

  const invalidData = useMemo(() => !pDateType || !pDate, [pDateType, pDate]);

  const onFieldsCancel = () => {
    setFieldsDirty(false);
    setPDate(pallet.productDate);
    setPDateType(pallet.productDateType);
  };

  const submitPalletUpdate = (values) => {
    onPalletUpdate(pallet.id, values);
  };

  useEffect(() => {
    if (palletId) {
      onFetchPallet(palletId);
    }
    return () => onResetSelectedPallet();
  }, [onFetchPallet, onResetSelectedPallet, palletId]);

  const handlePDateTypeChange = (event) => {
    setPDateType(event.target.value);
    setPDate(null);
  };

  const locationColor = useMemo(() => getHeightColor(pallet.location), [
    pallet.location,
  ]);

  const locationInputElement = useRef(null);

  const processScan = (scan) => {
    if (scan.match(SLOT_REGEX)) {
      onGetDepths(scan);
    } else {
      history.push(`/warehouse_moves/pallets/${scan}`);
    }
  };

  const canScan = useMemo(
    () => !fieldsDirty && !palletSearchDrawerOpen && !calculatorOpen,
    [fieldsDirty, palletSearchDrawerOpen, calculatorOpen]
  );

  const onPageKeyDown = useScanner(locationInputElement, processScan, canScan);

  const quantityInputDisabled = useMemo(
    () => updating || pallet.status !== ACTIVE,
    [pallet.status, updating]
  );

  const getDepthsCallback = useLocationDepths(
    onResetDepths,
    onGetDepths,
    packingFacilityId,
    itemMoveRequest ? itemMoveRequest.palletId : null
  );

  const locationReset = useCallback(() => {
    setLocationNumber('');
  }, [setLocationNumber]);

  const resetDepths = useCallback(() => {
    locationReset();
    setSelectedDepth({});
    onResetDepths();
  }, [locationReset, setSelectedDepth, onResetDepths]);

  const submitPalletLocation = (value) => {
    onPalletUpdate(pallet.id, { storage_slot_id: value });
  };

  // Rendering
  const activePalletActions = () => {
    return (
      <div className={classes.actionsContainer}>
        <PalletDetailsHoldButton palletId={pallet.id} />
        <PalletDetailsDeleteButton palletId={pallet.id} />
      </div>
    );
  };

  const heldPalletActions = () => {
    return (
      <>
        <div className={classes.kraken}>
          <Kraken />
        </div>
        <div className={classes.actionsContainer}>
          <ReleaseButton palletId={pallet.id} />
          <PalletDetailsDeleteButton palletId={pallet.id} />
        </div>
      </>
    );
  };

  const deactivatedPalletActions = () => {
    return (
      <ReactivateButton
        palletId={pallet.id}
        includeWasteCorrection={pallet.wasteCorrectionReactivationEligible}
      />
    );
  };

  const renderPalletActions = () => {
    switch (pallet.status) {
      case ACTIVE:
        return activePalletActions();
      case ON_HOLD:
        return heldPalletActions();
      case DEACTIVATED:
        return deactivatedPalletActions();
      default:
        return '';
    }
  };

  const renderBottomContent = () => {
    if (fieldsDirty) {
      return (
        <PalletDetailsButtons
          onCancelClick={onFieldsCancel}
          onSubmitClick={() =>
            submitPalletUpdate({
              product_date_type: pDateType,
              product_date: new Date(pDate),
            })
          }
          loading={updating}
          failed={updatingFailed}
          disabled={invalidData}
        />
      );
    }
  };

  if (depthOptions.length > 0) {
    return (
      <DepthSelection
        itemMoveRequest={itemMoveRequest}
        selectedDepthId={selectedDepth.id}
        setSelectedDepth={setSelectedDepth}
        locationBaseName={locationNumber}
        history={history}
        onSubmit={submitPalletLocation}
        setLocationNumber={setLocationNumber}
        callbackLocation="/warehouse_moves/open"
      />
    );
  }

  if (fetchFailed) {
    return (
      <Card className={classes.card}>
        <p>Sorry, no pallet matching that id found</p>
      </Card>
    );
  }

  // Initial pallet is an empty object, '{}', when page is refreshed. This check
  // stops it from trying to pass in things like pallet.quantity below
  if (Object.keys(pallet).length === 0) {
    return (
      <div className={classes.centeredLoading}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Card data-testid="view-search-pallet" className={classes.card}>
        <div className={classes.header}>Lot {pallet.id}</div>
        <CardContent className={classes.upperCard}>
          <div className={classes.ingName}>
            {pallet.ingredientId && <span>{pallet.ingredientId} - </span>}
            <span>{pallet.item}</span>
          </div>
          {pallet.brand !== '-' && (
            <div className={classes.lightGrey}>{pallet.brand}</div>
          )}
          <hr className={classes.line} />
          <div className={classes.qtyContainer}>
            <div className={classes.qtyBold}>QTY: {pallet.quantity}</div>
            <div className={classes.qtyGrey}> @ {pallet.measuringUnit}</div>
            <div className={classes.calculatorButton}>
              <QuantityModals
                onSubmit={submitPalletUpdate}
                onPageDownCallback={onPageKeyDown}
                calculatorOpen={calculatorOpen}
                setCalculatorOpen={setCalculatorOpen}
                quantity={pallet.quantity}
                calculatorDisabled={quantityInputDisabled || fieldsDirty}
                measuringUnit={pallet.measuringUnit}
                includeWasteCorrection={pallet.wasteCorrectionIncreaseEligible}
              />
            </div>
          </div>
          <hr className={classes.line} />
          <TextField
            label="PDate Type"
            variant="outlined"
            value={pDateType || pallet.productDateType}
            select
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              classes: { input: classes.smallerPadding },
            }}
            className={classes.selectFields}
            onChange={handlePDateTypeChange}
          >
            {WAREHOUSE_SHELF_LIFE_TYPES.map((option) => (
              <MenuItem
                key={option}
                value={option}
                className={classes.menuItem}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={smallerDatePickerForHoneywell}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="PDate"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  value={pDate}
                  onChange={setPDate}
                  InputProps={{
                    classes: {
                      input: classes.smallerPadding,
                    },
                  }}
                  className={classes.selectFields}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                />
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          <hr className={classes.line} />
          <span className={classes.textBold}>Location: </span>
          <div
            style={{ backgroundColor: locationColor }}
            className={classes.circle}
          />
          <span className={classes.lightGrey}>{pallet.location}</span>
        </CardContent>
        <CardActions className={classes.cardActionPadding}>
          {fieldsDirty ? renderBottomContent() : renderPalletActions()}
        </CardActions>
      </Card>

      <ActionCard header="Move" message="Scan Location to Move Pallet" />
      <LocationForm
        resetDepths={resetDepths}
        getDepthsCallback={getDepthsCallback}
        setLocationNumber={setLocationNumber}
        locationInputElement={locationInputElement}
        locationNumber={locationNumber}
        classes={classes}
        depthsFailed={depthsFailed}
        depthsLoading={depthsLoading}
        activePallet={pallet.status === ACTIVE}
      />
    </>
  );
};

ViewSearchPallet.propTypes = {
  classes: PropTypes.object.isRequired,
  depthsLoading: PropTypes.bool.isRequired,
  pallet: PropTypes.object,
  palletId: PropTypes.string,
  onFetchPallet: PropTypes.func.isRequired,
  onResetSelectedPallet: PropTypes.func.isRequired,
  onPalletUpdate: PropTypes.func.isRequired,
  palletSearchDrawerOpen: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  updatingFailed: PropTypes.bool.isRequired,
};

ViewSearchPallet.defaultProps = {
  pallet: {},
  palletId: null,
};

export default ViewSearchPallet;
