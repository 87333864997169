import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import { updateItemMoveRequest } from 'redux/itemMoveRequests/actions';

import styles from '../styles';
import CancelDialog from './CancelDialog';

const mapStateToProps = (state) => ({
  updating: state.itemMoveRequests.updating,
  updatingFailed: state.itemMoveRequests.updatingFailed,
});

const mapDispatchToProps = {
  onUpdateItemMoveRequest: updateItemMoveRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CancelDialog)
);
