import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import isEmpty from 'lodash/isEmpty';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import theme from 'lib/dataTableTheme';
import { Loading } from 'components/shared';

import { columns, options } from './dataTableConfig';

const IngredientsCard = ({
  menuSelection,
  ingredientAssignments,
  onGetIngredientAssignments,
  classes,
}) => {
  useEffect(() => {
    if (menuSelection) {
      onGetIngredientAssignments(
        menuSelection.ingredientAssignmentIds,
        menuSelection.menuId
      );
    }
  }, [classes, menuSelection, onGetIngredientAssignments]);

  const mergeIngredientAssignmentsWithMenuSelection = () => {
    const menuSelectionCounts = pick(menuSelection, [
      'goalCount',
      'remainingCount',
    ]);
    return ingredientAssignments.map((ia) => {
      return merge(ia, menuSelectionCounts);
    });
  };

  return (
    <Card className={classes.card}>
      {isEmpty(ingredientAssignments) ? (
        <div className={classes.centeredLoading}>
          <Loading />
        </div>
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title="Ingredient Assignments"
              data={mergeIngredientAssignmentsWithMenuSelection()}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </Card>
  );
};

IngredientsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  menuSelection: PropTypes.shape({
    ingredientAssignmentIds: PropTypes.array.isRequired,
    menuId: PropTypes.number.isRequired,
  }).isRequired,
  ingredientAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGetIngredientAssignments: PropTypes.func.isRequired,
};

export default IngredientsCard;
