import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI } from 'lib/constants';

const fetchingLimitedMenus = async (showExtendedProdMenus) => {
  const response = await fetch(
    `${API_BASE_URI}/ops/menus?show_extended_prod_menus=${showExtendedProdMenus}`,
    options
  );
  return await handleResponse(response);
};

const fetchingAllOrderableMenus = async () => {
  const response = await fetch(
    `${API_BASE_URI}/ops/menus/?get_all_orderable_menus=true`,
    options
  );
  return await handleResponse(response);
};

export { fetchingLimitedMenus, fetchingAllOrderableMenus };
