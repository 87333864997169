import { BLACK, ERROR_RED, GREY_500 } from 'lib/constants';

export default {
  root: {
    marginBottom: '10px',
  },
  header: {
    padding: '10px 20px',
    display: 'flex',
  },
  th: {
    fontWeight: 500,
    color: BLACK,
    fontSize: '.81rem',
  },
  title: {
    fontSize: '1.25rem',
    fontFamily: 'Muli',
    fontWeight: 'bold',
  },
  noTruckMessage: {
    textAlign: 'center',
    padding: '10px',
  },
  createButton: {
    marginLeft: 'auto !important',
  },
  rejectedText: {
    fontFamily: 'Muli',
    fontWeight: 500,
    fontSize: '0.8125rem',
    lineHeight: 1.75,
    textTransform: 'uppercase',
    color: ERROR_RED,
  },
  confirmRejectText: {
    textAlign: 'center',
  },
  cancelButton: {
    color: GREY_500,
    marginRight: '20px',
  },
  modalButtonRow: {
    display: 'flex',
    justifyContent: 'center',
  },
};
