import React from 'react';
import Grid from '@mui/material/Grid';
import PropType from 'prop-types';

import { Loading, MealCard } from 'components/shared';
import { sortByLetterAssignment } from 'lib/viewHelpers';
import { ECOMMERCE_CHANNEL } from 'lib/constants';

const MealGrid = ({ meals, fetching, baseRoute }) => {
  const renderMeals = () => {
    if (fetching) {
      return <Loading type="linear" />;
    } else {
      return (
        <div data-testid="meal-grid">
          <h2>Ecommerce</h2>
          <Grid container>
            {sortByLetterAssignment(meals[ECOMMERCE_CHANNEL]).map((meal) => (
              <Grid
                item
                lg={2}
                md={2}
                sm={2}
                xs={6}
                key={`${meal.mealId || meal.id}`}
              >
                <MealCard
                  channel={ECOMMERCE_CHANNEL}
                  meal={meal}
                  linkTo={`${baseRoute}/preliminary-checks/${
                    meal.mealId || meal.id
                  }`}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
  };

  return <div>{meals && renderMeals()}</div>;
};

MealGrid.propTypes = {
  fetching: PropType.bool,
  baseRoute: PropType.string.isRequired,
  meals: PropType.shape({
    ecommerce: PropType.array,
    retail: PropType.array,
  }).isRequired,
};

MealGrid.defaultProps = {
  fetching: false,
};

export default MealGrid;
