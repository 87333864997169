import {
  WHITE,
  GREY_200,
  GREY_150,
  GREY_500,
  CLICKABLE_BLUE,
  MEDIA_QUERY_PHONE_SCREEN,
} from 'lib/constants';

const resultsChild = {
  minHeight: '50px',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 5px',
};

export default {
  input: {
    margin: '20px',
    width: 'calc(100% - 40px)',
  },
  popper: {
    zIndex: 2,
  },
  popoverContent: {
    background: WHITE,
    border: `1px solid ${GREY_200}`,
    boxSizing: 'border-box',
  },
  resultsContainer: {
    maxHeight: '230px',
    overflow: ' scroll',
  },
  resultsChild,
  createNew: {
    ...resultsChild,
    cursor: 'pointer',
    borderTop: `1px solid ${GREY_200}`,
  },
  noClickResult: {
    ...resultsChild,
    background: GREY_150,
  },
  nameContainer: {
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  staticName: {
    fontSize: '18px',
  },
  staticNameContainer: {
    padding: '0px 20px',
  },
  disabledCreate: {
    background: GREY_150,
    cursor: 'not-allowed',
  },
  userDetails: {
    fontSize: '14px',
    color: GREY_500,
  },
  result: {
    ...resultsChild,
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-around',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${GREY_200}`,
    },
  },
  detailItem: {
    marginRight: '10px',
  },
  clearButton: {
    border: 'none',
    background: 'none',
    color: CLICKABLE_BLUE,
    cursor: 'pointer',
    fontSize: '100%',
  },
  addIcon: {
    marginRight: '5px',
    marginLeft: '-5px',
  },
  [MEDIA_QUERY_PHONE_SCREEN]: {
    resultsContainer: {
      maxHeight: '130px',
    },
  },
};
